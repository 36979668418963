import { lazy } from 'react'
import suspenseComponent from '../../../../../../util/suspenseComponent'


const PaymentMethodsPage = lazy(() => import('client/containers/dashboard/Settings/pages/Business/PaymentsPage'))
const BankAccountsList = lazy(() => import('client/containers/dashboard/Settings/pages/Business/BankAccounts/BankAccountsList'))
const SystemSettingPage = lazy(() => import('client/containers/dashboard/Settings/pages/Business/SystemSettingPage'))
const AdminRoleGroupsSettingPage = lazy(() => import('client/containers/dashboard/Settings/pages/Business/AdminRoleGroupsSettingPage'))

const RunningNumbers = lazy(() => import('../../../../../../containers/dashboard/Settings/pages/Business/RunningNumbers'))
const Logo = lazy(() => import('../../../../../../containers/dashboard/Settings/pages/Business/Logo'))
const Stamp = lazy(() => import('../../../../../../containers/dashboard/Settings/pages/Business/Stamp'))
const ElectronicSignature = lazy(() => import('../../../../../../containers/dashboard/Settings/pages/Business/ElectronicSignature'))

const BillShortPage = lazy(() => import('../../../../../../containers/dashboard/Settings/pages/Business/BillShortPage'))
const BillFullPage = lazy(() => import('../../../../../../containers/dashboard/Settings/pages/Business/BillFullPage'))

const ShippingFeesPage = lazy(() => import('../../../../../../containers/dashboard/Settings/pages/Business/ShippingFeesPage'))

const SystemsAllPage = lazy(() => import('../../../../../../containers/dashboard/Settings/pages/Business/SystemsAllPage'))
const SystemsPage = lazy(() => import('../../../../../../containers/dashboard/Settings/pages/Business/SystemsPage'))

const LanguagesPage = lazy(() => import('../../../../../../containers/dashboard/Settings/pages/Business/LanguagesPage'))

const AlbumsListPage = lazy(() => import('../../../../../../containers/dashboard/Settings/pages/Business/Albums/AlbumsListPage'))

const NotFound = lazy(() => import('../../../../../../common/Pages/NotFound'))

const NotificationsListPage = lazy(() => import('../../../../../../containers/dashboard/Settings/pages/Business/NotificationsListPage'))


const MessagingEmail = lazy(() => import('../../../../../../containers/dashboard/Settings/pages/Business/MessagingEmail'))
const MessagingLineNotify = lazy(() => import('../../../../../../containers/dashboard/Settings/pages/Business/MessagingLineNotify'))
const MessagingFacebook = lazy(() => import('../../../../../../containers/dashboard/Settings/pages/Business/MessagingFacebook'))


const SettingsMenuRoutes = ({ path }) => [
  /*{
    path: `${path}/messaging/email`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(MessagingEmail)
  },
  {
    path: `${path}/messaging/line-notify`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(MessagingLineNotify)
  },
  {
    path: `${path}/messaging/facebook`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(MessagingFacebook)
  },*/

  {
    path: `${path}/payment-methods`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(PaymentMethodsPage)
  },
  {
    path: `${path}/bank-accounts`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(BankAccountsList)
  },
  {
    path: `${path}/admin-role-groups`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(AdminRoleGroupsSettingPage)
  },
  {
    path: `${path}/systems`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(SystemSettingPage)
  },

  /*{
    path: `/:username/settings/running-numbers`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(RunningNumbers)
  },
  {
    path: `/:username/settings/logo`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(Logo)
  },
  {
    path: `/:username/settings/stamp`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(Stamp)
  },
  {
    path: `/:username/settings/electronic-signature`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(ElectronicSignature)
  },
  {
    path: `/:username/settings/bill-short`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(BillShortPage)
  },
  {
    path: `/:username/settings/bill-full`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(BillFullPage)
  },
  {
    path: `/:username/settings/shipping-fees`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(ShippingFeesPage)
  },
  {
    path: `/:username/settings/systems`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(SystemsAllPage)
  },
  {
    path: `/:username/settings/languages`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(LanguagesPage)
  },
  {
    path: `/:username/settings/notifications/:typeNotification(email|facebook|line-notify)`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(NotificationsListPage)
  },
  {
    path: `/:username/settings/system/:system_name(products|folders|customers|articles|attributes|orders|bills)`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(SystemsPage)
  },*/

  {
    path: `${path}/albums/:system_name(products|product-items|folders|customers|articles|attributes)`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(AlbumsListPage)
  },
  {
    component: suspenseComponent(NotFound)
  }
]





export default SettingsMenuRoutes

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import cuid from 'cuid'

// Component Global
import BarcodeScannerModal from '../BarcodeScannerModal'

// Js
import { onSearchData } from './js/InputSearchFunction'

// Redux Actions
import { addLoad, openModal } from '../../redux/App/AppActions'

// css
import css from './css/searchItems.css'


const idRandom = cuid()

class SearchItems extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.timer = null
  }

  componentDidMount() {
    this.getSearchValue()
  }

  /*componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.getSearchValue(nextProps.location.search)
    }
  }*/

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.getSearchValue(this.props.location.search)
    }
  }

  onChange = (e) => {
    this.setState({ value: e.target.value })
  }

  onKeyUp = (e) => {
    clearTimeout(this.timer)

    const value = e.target.value
    const fetch = this.props.fetchAction
    const loadId = this.getLoadId()

    if (!this.props.load) {
      this.props.dispatch(addLoad(loadId))
    }

    this.timer = setTimeout(() => {
      this.setState({ valueSearch: false }, () => {
        onSearchData(this, value, fetch, { loadId })
      })
    }, 1000)

  }

  getSearchValue = (search) => {
    if (!search) search = this.props.location.search

    let urlParams = new URLSearchParams(search)
    let value = urlParams.get('search')

    if (value !== null && this.props.getValueFromUrl) {
      document.getElementById("input-search").value = value
    }
  }

  getLoadId = () => {
    const loadId = this.props.loadId || this.props.id

    return loadId
  }

  getBarcode = (value) => {
    const fetch = this.props.fetchAction
    const loadId = this.getLoadId()

    this.setState({ value }, () => {
      onSearchData(this, value, fetch, { loadId })
    })
  }

  render() {
    const barcodeId = `barcode-scanner-${idRandom}`
    const loadId = this.getLoadId()

    return (
      <div className={`${css.boxSearch} height-45`}>
        { !this.props.hiddenSearchCamera &&
          <BarcodeScannerModal
            id={barcodeId}
            onChange={this.getBarcode}
          />
        }

        <div className={`${css.boxInput} height-45 border-1 border-radius-3`}>
          <table>
            <tbody>
              <tr>
                <td className="pd-0 width-40">
                  { loadId && this.props.load ?
                    <div className="la-ball-clip-rotate la-sm mg-left-10 width-20 height-20">
                      <div className="width-20 height-20 border-2" style={{ color: '#7cb342' }}></div>
                    </div>
                  :
                    <i className="material-icons font-1-5 left i-middle mg-left-8 mg-right-0" style={{ color: '#757575' }}>search</i>
                  }
                </td>
                <td className="pd-0 vertical-top">
                  <input
                    id="input-search"
                    value={this.state.value}
                    onChange={this.onChange}
                    onKeyUp={this.onKeyUp}
                    className={`${css.inputSearch} height-45 line-height-45 pd-left-10 pd-right-10 font-0-9`}
                    type="text"
                    placeholder={this.props.placeholder ? this.props.placeholder : 'ค้นหา'}
                  />
                </td>

                { !this.props.hiddenSearchCamera &&
                  <td className="pd-top-2 pd-bottom-2 pd-left-2 pd-right-1 width-40 vertical-top">
                    <a
                      onClick={() => this.props.dispatch(openModal(barcodeId))}
                      className="btn btn-mini height-40 line-height-40 btn-shadow-none border-radius-3">

                      <i className="fas fa-barcode"></i>
                    </a>
                  </td>
                }
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store, props) => {
  return {
    load: store.app.loads.filter(load => load === props.id)[0]
  }
}

export default withRouter(connect(mapStateToProps)(SearchItems))

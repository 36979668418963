import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../../components/Modals/Modal'
import { Row, Column } from '../../../../../../components/Columns'

// Components forms
import Form from '../../../../../../components/Form'
import BtnSubmit from '../../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../../util/getFormValue'

// Components Rubber Sales
import SaleDetailsForms from '../../../RubberSales/components/RubberSaleForms/SaleDetailsForms'
import InventoryForms from '../../../RubberSales/components/RubberSaleForms/InventoryForms'
import RubberItemSale from '../../../RubberSales/components/RubberSaleForms/RubberItemSale'

// Actions Redux
import { createDeposit, updateDeposit } from '../../../../../../redux/Deposits/DepositsActions'

// Values
import RubberCustomerCreateAndUpdateModal from '../../../RubberCustomerBuyers/components/RubberCustomerCreateAndUpdateModal'
import { getRubberSettingTypeValue } from '../../../../../../redux/RubberSettings/RubberSettingsValue'
import { SaleTotalPriceAll } from '../../../RubberSales/components/RubberSaleForms'



const RubberDepositCreateAndUpdateModal = (props) => {
  const [data, setData] = useState('')
  const [itemSale, setItemSale] = useState()
  const [cost, setCost] = useState()
  const [customer, setCustomer] = useState()
  const [rubberType, setRubberType] = useState()
  const [rubberType2, setRubberType2] = useState()

  const formRef = useRef()
  const { id, event, match, business, setting, dispatch } = props
  const { username } = match.params

  const productType = 'rubber-sheet'
  const type = getRubberSettingTypeValue({ setting, code: productType })

  let title

  if (event === 'create') {
    title = `เพิ่มบันทึกการฝากยาง`
  } else if (event === 'update') {
    title = `แก้ไขบันทึกการฝากยาง`
  }


  const handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(formRef.current)

    if (params) {
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id
      }

      params.customer = customer
      params.customers_id = customer && customer._id
      params.cost = cost
      params.itemSale = itemSale

      params.productType = productType
      params.rubberType = rubberType
      params.rubberType2 = rubberType2

      params.profit = {
        netWeight: itemSale.netWeight - cost.netWeightSale,
        latexPercent: itemSale.latexPercent - cost.latexPercentAvg,
        dryLatexWeight: itemSale.dryLatexWeight - cost.dryLatexWeight,
        netPrice: itemSale.netPrice - cost.netPriceAvg,
        totalPrice: itemSale.totalPrice - cost.totalPrice,
        totalIncomes: itemSale.totalIncomes,
        totalPriceAll: itemSale.totalPriceAll - cost.totalPrice
      }

      if (event === 'create') {
        dispatch(createDeposit(query, {
          body: params,
          loadId: formName,
          modalId: formName,
          toastMsg: title,
          props,
          dataRedirect: {
            url: `/${username}/rubber-deposits/all/:_id`,
            resName: 'data'
          }
        }))

      } else if (event === 'update') {
        query.deposits_id = props.deposit._id

        dispatch(updateDeposit(query, {
          body: params,
          loadId: formName,
          modalId: formName,
          toastMsg: title
        }))
      }
    }
  }


  useEffect(() => {
    if (productType === 'field-latex') {
      setRubberType('field-latex')
    }

    if (props.fixed) {
      setCustomer(props.fixed.customer)
      setRubberType(props.fixed.rubberType)

      if (props.fixed.rubberType === 'rubber-sheet') {
        setRubberType2('rs-good')
      } else if (props.fixed.rubberType === 'ribbed-smoked-sheet') {
        setRubberType2('rss-3')
      }
    }

  }, [props.fixed])


  useEffect(() => {
    setData({ itemSale, cost, customer })
  }, [itemSale, cost, customer ])


  useEffect(() => {
    if (props.deposit) {
      setCost(props.deposit.cost)
      setItemSale(props.deposit.itemSale)
      setCustomer(props.deposit.customer)
      setRubberType(props.deposit.rubberType)
      setRubberType2(props.deposit.rubberType2)
    }
  }, [props.deposit])


  const handleSelectCustomer = (customer) => {
    if (customer) {
      setCustomer(customer)
    } else {
      setCustomer(undefined)
    }
  }


  const handleChangeCost = (cost) => {
    setCost(cost)
  }


  const handleChangeSale = (itemSale) => {
    setItemSale(itemSale)
  }

  const handleSelectRubberType = (value) => {
    setRubberType(value)

    if (value === 'rubber-sheet') {
      setRubberType2('rs-good')
    } else if (value === 'ribbed-smoked-sheet') {
      setRubberType2('rss-3')
    }
  }

  const col_1 = "column col-xxs-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 pd-right-20-lg pd-right-20-xlg pd-bottom-20"
  const col_2 = "column col-xxs-12 col-xs-12 col-sm-12 col-md-12 col-lg-6  pd-left-20-lg  pd-left-20-xlg pd-bottom-20"

  return (
    <Modal
      id={id}
      headerTitle={title}
      headerLeft
      blockBgModal
      width="1100px"
      padding="0">

      <div className="pd-20">
        <RubberCustomerCreateAndUpdateModal
          {...props}
          id="customerCreateModal"
          event="create"
          match={props.match}
          business={props.business}
          onSelect={handleSelectCustomer}
        />

        <Form
          recheckForm
          ref={formRef}
          name={id}
          dataChange={JSON.stringify(data)}
          submit={(e) => handleSubmit(e, id)}>

          <Row>
            <Column col={col_1}>
              <div className="font-1-1 pd-bottom-20">
                รายละเอียดการฝากยางแผ่น
              </div>

              <SaleDetailsForms
                {...props}
                data={props.deposit}
                type={type}
                productType={productType}
                rubberType={rubberType}
                rubberType2={rubberType2}
                customer={customer}
                handleSelectCustomer={handleSelectCustomer}
                handleSelectRubberType={handleSelectRubberType}
              />
            </Column>

            <Column col={col_2}>
              <div className="font-1-1 pd-bottom-20">ยางแผ่นในสต็อก</div>

              <InventoryForms
                {...props}
                element="cost"
                cost={props.deposit && props.deposit.cost}
                type={type}
                inventoryType="inventory"
                rubberType={rubberType}
                productType={productType}
                onChange={handleChangeCost}
              />
            </Column>
          </Row>

          <RubberItemSale
            {...props}
            type={type}
            itemSale={props.deposit && props.deposit.itemSale}
            cost={cost}
            rubberType={rubberType}
            onChange={handleChangeSale}
            forDeposit
          />

          <Row>
            <Column col="column col-xxs-12 mg-top--15">
              <SaleTotalPriceAll type={type} value={itemSale && itemSale.totalPriceAll} />
            </Column>

            <Column col="column col-xxs-12">
              <BtnSubmit
                loadingId={id}
                icon="send"
                iconAlign="left"
                text={title}
                className="btn btn-submit btn-shadow-none height-45 line-height-45 font-0-9 width-full"
                btnDisabled
              />
            </Column>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}


export default connect()(RubberDepositCreateAndUpdateModal)

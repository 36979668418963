import React, { Fragment } from 'react'
import css from './css/window.css'
import { Link } from 'react-router-dom'


// components local
import WindowDefault from '../WindowDefault'
import ProfileAvatar from '../../ProfileAvatar'
import LangSetting from '../../LangSetting'


const Window = (props) => {
  const { avatar, windowModal, closeWindowModal, history } = props

  const closeWindow = () => {
    closeWindowModal()

    setTimeout(() => {
      history.push(props.linkClose)
    }, 800)
  }


  return (
    <Fragment>
      <WindowDefault windowModal={windowModal} className={`${props.className || ''}`} style={props.style}>
        <div className={`${css.header} height-50 line-height-50 border-bottom-1 border-bottom-eee`} style={props.style}>
          <div className="height-50 line-height-50">
            <div className={`${css.titleIcon} width-60 height-50 pd-4`}>
              { avatar ?
                <ProfileAvatar
                  images={avatar.images}
                  imageSize="o"
                  color={avatar.color}
                  texts={avatar.texts}
                  textSize="1.1rem"
                />
              :
                <img src={props.titleIcon} className="width-40" />
              }
            </div>
            <div className={`${css.title} left-60 right-110 height-50 line-height-50 pd-left-10 pd-right-10`}>
              <span className="font-1-1">{props.title}</span>
            </div>

            { !props.hideBtnClose &&
              <div className={`${css.close} width-130 height-50`} style={{ display: 'flex' }}>

                { props.linkHelp &&
                  <div className="width-30">
                    <a href={props.linkHelp} target="_blank">
                      <i className="material-icons font-1-5 line-height-50 mg-right-20">help_outline</i>
                    </a>
                  </div>
                }

                { props.langSetting &&
                  <div className="pd-top-4 width-40">
                    <LangSetting left />
                  </div>
                }

                <div className="width-full text-right">
                  { closeWindowModal ?
                    <a onClick={closeWindow} className={`btn red darken-1 ${css.btnClose} mg-5`}>
                      <i className="material-icons">close</i>
                    </a>
                  :
                    <Link to={props.linkClose} className={`btn red darken-1 ${css.btnClose} mg-5`}>
                      <i className="material-icons">close</i>
                    </Link>
                  }
                </div>
              </div>
            }

          </div>
        </div>

        <div className={`${css.children} top-50`}>
          {props.children}
        </div>
      </WindowDefault>

      {/* props.langSetting &&
        <div className='top-5 right-50' style={{ position: 'fixed', zIndex: '999999' }}>
          <LangSetting />
        </div>
      */}
    </Fragment>
  )
}

export default Window

import React from 'react'
import { connect } from 'react-redux'

import renderRoutes from '../../../../../routes/renderRoutes'
import WindowDefault from '../../../../../components/Windows/WindowDefault'


const RubberReceivablesContainer = (props) => {
  let { route, business, setting, location, windowModal, closeWindowModal } = props


  return (
    <WindowDefault windowModal={windowModal}>
      { renderRoutes({
        routes: route.routes,
        extraProps: { business, setting, windowModal, closeWindowModal },
        location
      }) }
    </WindowDefault>
  )
}

export default connect()(RubberReceivablesContainer)

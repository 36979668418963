import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { removeDataAll } from 'client/redux/Datas/DatasActions'

// Utils
import { getURLSearchParams, updateURLSearchParams } from 'utils/getURLSearchParams'


const UseSelector = (props) => {
  const { location, match } = props
  const [useSelector, setUseSelector] = useState(false)

  useEffect(() => {
    const params = getURLSearchParams(location.search)

    if (params && params.useSelector === "true") {
      setUseSelector(true)
    } else {
      setUseSelector(false)
    }
  }, [location.search])


  const setUse = (use) => {
    let params
    let link = location.pathname

    if (props.useSelector.urlMain) {
      link = props.useSelector.urlMain
    }

    if (use) {
      params = updateURLSearchParams({ search: location.search, name: 'useSelector', value: true })
    } else {
      params = updateURLSearchParams({ search: location.search, name: 'useSelector', value: undefined })
    }

    // remove datas all
    props.dispatch(removeDataAll({ datas: [], groupId: props.useSelector.groupId }))

    if (params) {
      link = `${link}?${params}`
    }

    props.history.push(link)
  }

  const handleChangeUse = () => {
    setUseSelector(!useSelector)
    setUse(!useSelector)
  }

  return (
    <Fragment>
      <a
        onClick={handleChangeUse}
        className={`btn pd-0 ${useSelector ? 'btn-delete' : 'btn-white'} width-40 height-40 line-height-40 btn-shadow-none border-radius-3`}>

        <i className="material-icons font-1-5">{ useSelector ? 'disabled_by_default' : 'check_box_outline_blank' }</i>
      </a>
    </Fragment>
  )
}

const mapStateToProps = (state, props) => {
  const { useSelector } = props
  const { groupId } = useSelector
  const lists = state.datas.data.filter(data => data.groupId === groupId) || []

  return {
    lists
  }
}

export default connect(mapStateToProps)(UseSelector)

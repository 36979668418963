import React from 'react'

// Components Global
import { Row, Column } from 'components/Columns'
import TextInput from 'components/Inputs/TextInput'
import InputPhoneNumber from 'components/Inputs/InputPhoneNumber'
import InputCheckDuplicate from 'components/Inputs/InputCheckDuplicate'
import { checkUserDuplicate, fetchQueryUserOne } from 'core/redux/Users/UsersActions'


export const UserFirstName = (props) => {
  const lang = props.lang || 'local'
  const id = `firstName.${lang}`
  const value = props.value ? props.value[lang] : ''

  return (
    <TextInput
      label={props.label || 'ชื่อ'}
      placeholder="ชื่อ"
      id={id}
      value={value}
      type="text"
      required
    />
  )
}

export const UserLastName = (props) => {
  const lang = props.lang || 'local'
  const id = `lastName.${lang}`
  const value = props.value ? props.value[lang] : ''

  return (
    <TextInput
      label={props.label || 'นามสกุล'}
      placeholder="นามสกุล"
      id={id}
      value={value}
      type="text"
      required
    />
  )
}

export const UserName = (props) => {
  return (
    <Row>
      <Column col="column col-xs-12 col-md-6 pd-right-10-md pd-right-10-lg pd-right-10-xlg">
        <UserFirstName {...props} value={props.firstName} />
      </Column>
      <Column col="column col-xs-12 col-md-6 pd-left-10-md pd-left-10-lg pd-left-10-xlg">
        <UserLastName {...props} value={props.lastName}  />
      </Column>
    </Row>
  )
}

export const UserMobile = (props) => {
  return (
    <InputPhoneNumber
     label="มือถือ"
     id="mobile"
     hiddenCode
     value={props.value}
     required={props.required}
     iconValid />
  )
}

export const UserPassword = (props) => {
  const id = `password`
  const value = props.value

  return (
    <TextInput
      label="รหัสผ่าน"
      placeholder="รหัสผ่าน"
      id={id}
      value={value}
      type="password"
      inputType="password"
      required
    />
  )
}


export const UserEmail = (props) => {
  const { business, value, user, onChange, authName } = props
  const fnQuery = (value, params) => {
    const query = {
      authName:  authName || 'admin',
      businesses_id: business._id
    }

    const match = { email: value }

    if (user && user._id) {
      match['_id'] = { $ne: `ObjectId(${user._id})` }
    }

    return fetchQueryUserOne(query, {
      body: {
        notUseParams: 'businesses_id',
        match,
      },
      notSaveStore: true,
      notShowErrorAlert: true
    })
  }

  return (
    <InputCheckDuplicate
      id="email"
      label="อีเมล"
      type="email"
      value={value}
      fnQuery={fnQuery}
      onChange={onChange}
      required
    />
  )
}

export const UserUsername = (props) => {
  const { business, value, user, onChange, authName } = props

  const fnQuery = (value, params) => {
    const query = {
      authName:  authName || 'admin',
      businesses_id: business._id
    }

    const match = { username: value }

    if (user && user._id) {
      match['_id'] = { $ne: `ObjectId(${user._id})` }
    }

    return fetchQueryUserOne(query, {
      body: {
        notUseParams: 'businesses_id',
        match,
      },
      notSaveStore: true,
      notShowErrorAlert: true
    })
  }

  return (
    <InputCheckDuplicate
      id="username"
      label="username"
      type="username"
      value={value}
      fnQuery={fnQuery}
      onChange={onChange}
      required
    />
  )
}

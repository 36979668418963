import React from 'react'
import { connect } from 'react-redux'

// Components Global
//import { PageManager } from '../../../../../components/SiteManager'


// Actions Redux
import { fetchPlace, updatePlace, uploadImagesPlace } from '../../../../../redux/Places/PlacesActions'


const PlaceSiteManagerPage = (props) => {
  const { match, place, business } = props

  /*return (
    <PageManager
      {...props}
      singlePage
      action={{
        fetch: fetchPlace,
        update: updatePlace,
        uploadImage: uploadImagesPlace,
        params: {
          business,
          data: place,
          _id: place._id,
          imagePrefix: 'places'
        }
      }}
      linkBack={`/${match.params.username}/places/${place.status}/${match.params.places_id}/update/site`}
      linkDisplay={`/${match.params.username}/places/${place.status}/${match.params.places_id}/update/site-manage-display`}
    />
  )*/

  return (
    <div></div>
  )

  /*return (
    <PageManager
      {...props}
      action={{
        update: updatePlace,
        uploadImage: uploadImagesPlace,
        params: {
          business: business,
          data: place,
          _id: place._id,
          imagePrefix: 'places'
        }
      }}
      linkBack={`/${match.params.username}/places/${place.status}/${match.params.places_id}/update/site`}
      linkDisplay={`/${match.params.username}/places/${place.status}/${match.params.places_id}/update/site-manage-display`}
    />
  )*/
}

export default connect()(PlaceSiteManagerPage)

import React, { Fragment, useRef, useState } from 'react'
import { connect } from 'react-redux'

import { Row, Column } from '../../../../../components/Columns'
import Modal from '../../../../../components/Modals/Modal'
import Form from '../../../../../components/Form'
import BtnSubmit from '../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../util/getFormValue'

import {
  CustomerName,
  CustomerCode,
  CustomerCorporate,
  CustomerTaxId,
  CustomerTel,
  CustomerMobile,
  CustomerEmail,
  CustomerAddress,
  CustomerLineId,
  CustomerGroup
} from '../CustomerForms/CustomerForms'

// Actions Redux
import { updateCustomer } from '../../../../../redux/Customers/CustomersActions'

// Values
import { customerValue } from '../../../../../redux/Customers/CustomersValues'


const CustomerUpdateModal = (props) => {
  const fromRef = useRef()
  const [data, setData] = useState('')
  const { id, forUser, dispatch, business } = props
  const customer = customerValue({ customer: props.customer })
  const { customersSettings } = business

  const onSelect = (customer) => {
    if (props.onSelect) {
      props.onSelect(customer, false)
    }
  }

  const handleCodeChange = (data) => {
    setData(data)
  }

  const handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(fromRef.current)

    let authName

    if (forUser) {
      authName = "user"
    } else {
      authName = "admin"
    }

    if (params) {
      const query = {
        authName,
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id,
        customers_id: customer._id
      }

      dispatch(updateCustomer(query,  {
        body: params,
        loadId: formName,
        modalId: formName,
        toastMsg: 'แก้ไขรายชื่อลูกค้าแล้ว'
      })).then(res => {
        if (res.success && res.data.length !== 0) {
          onSelect(res.data)
        }
      })
    }
  }

  return (
    <Fragment>
      { id && customer &&
        <Modal
          id={id}
          headerTitle="แก้ไขรายชื่อลูกค้า"
          headerLeft
          width="550px">

          <div className="pd-20">
            <Form
              recheckForm
              ref={fromRef}
              name={id}
              dataChange={JSON.stringify(data)}
              submit={(e) => handleSubmit(e, id)}>

              <Row>
                { business.type !== 'rubber' ?
                  <Fragment>
                    <Column col="column col-xs-12 mg-top-10">
                      <CustomerCorporate value={customer} />
                    </Column>

                    {!forUser && customersSettings.code.use &&
                      <Column col="column col-xs-12">
                        <CustomerCode
                          business={business}
                          value={customer.code}
                          customer={customer}
                          onChange={handleCodeChange}
                        />
                      </Column>
                    }

                    { !forUser &&
                      <Column col="column col-xs-12">
                        <CustomerGroup business={business} value={customer.group} />
                      </Column>
                    }

                    <Column col="column col-xs-12">
                      <CustomerTaxId value={customer.taxId} required={props.required} />
                    </Column>
                    <Column col="column col-xs-12">
                      <CustomerAddress value={customer.address} required={props.required} />
                    </Column>
                    <Column col="column col-xs-12">
                      <CustomerMobile value={customer.mobile} />
                    </Column>
                    <Column col="column col-xs-12">
                      <CustomerTel value={customer.tel}  />
                    </Column>
                    <Column col="column col-xs-12">
                      <CustomerEmail value={customer.email} />
                    </Column>
                    <Column col="column col-xs-12">
                      <CustomerLineId value={customer.lineId} />
                    </Column>
                  </Fragment>
                :
                  <Fragment>
                    <Column col="column col-xs-12 mg-top-20">
                      <CustomerName firstName={customer.firstName} lastName={customer.lastName} />
                    </Column>
                    <Column col="column col-xs-12">
                      <CustomerTaxId value={customer.taxId} />
                    </Column>
                    <Column col="column col-xs-12">
                      <CustomerAddress value={customer.address} />
                    </Column>
                    <Column col="column col-xs-12">
                      <CustomerMobile value={customer.mobile} />
                    </Column>
                  </Fragment>
                }

                <Column col="column col-xs-12 text-align-right">
                  <BtnSubmit
                    loadingId={id}
                    icon="send"
                    iconAlign="left"
                    text="บันทึกชื่อลูกค้า"
                    className="btn btn-success btn-shadow-none"
                    btnDisabled
                  />
                </Column>
              </Row>
            </Form>
          </div>
        </Modal>
      }
    </Fragment>
  )

}

export default connect()(CustomerUpdateModal)

import { lazy } from 'react'
import suspenseComponent from '../../../../../../../util/suspenseComponent'

// Pages
const RubberDescriptionsPage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberSettings/pages/RubberDescriptionsPage'))
const RubberTypesPage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberSettings/pages/RubberTypesPage'))
const RubberTypeManagePage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberSettings/pages/RubberTypeManagePage'))
const RubberWarehouseManagePage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberSettings/pages/RubberWarehouseManagePage'))

// Container
const RubberTypesListContainer = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberSettings/containers/RubberTypesListContainer'))
const RubberWarehousesListContainer = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberSettings/containers/RubberWarehousesListContainer'))


const RubberSettingsMenuRoutes = ({ path }) => [
  {
    path: `${path}/rubber`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(RubberDescriptionsPage)
  },
  {
    path: `${path}/rubber/products`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(RubberTypesPage)
  },
  {
    path: `${path}/rubber/types/:settings_types_id`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(RubberTypesListContainer),
    routes: [
      {
        path: `${path}/rubber/types/:settings_types_id`,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(RubberTypeManagePage)
      },
    ]
  },

  {
    path: `${path}/rubber/warehouses/:settings_types_id/:settings_warehouses_id`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    component: suspenseComponent(RubberWarehousesListContainer),
    routes: [
      {
        path: `${path}/rubber/warehouses/:settings_types_id/:settings_warehouses_id`,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(RubberWarehouseManagePage)
      },
    ]
  },
]


export default RubberSettingsMenuRoutes

import React, {  Component } from 'react'
import cuid from 'cuid'
import Autosize from 'autosize'
import Required from '../Required'
import { setStyleValue, getStyle } from '../../TextEditor/components/ElementStyles/components/js/setDivStyle'


class Textarea extends Component {
  constructor(props) {
    super(props)

    const id = this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()

    this.state = {
      id: randomId+id,
      value: this.props.value || '',
    }
  }

  componentDidMount() {
    Autosize(this.textarea)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      Autosize(this.textarea)
    }
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value || '' })

    if (this.props.onChange) {
      this.props.onChange(e)
    }
  }

  render() {
    const { styleName, styleJsx, inputForSite, styleGlobal } = this.props
    const name = styleName
    const setEvent = (event, important=false) => {
      return (
        `
          ${getStyle('box-shadow', styleJsx[`${name}-${event}-box-shadow`], important)}
          ${getStyle('border-style', styleJsx[`${name}-${event}-border-style`], important)}
          ${getStyle('border-width', styleJsx[`${name}-${event}-border-width`], important)}
          ${getStyle('border-color', styleJsx[`${name}-${event}-border-color`], important)}
        `
      )
    }

    let className = ""

    if (this.props.requiredClassName) {
      className = this.props.requiredClassName
    } else if (this.props.className) {
      className = this.props.className
    }

    return (
      <div className={`${this.props.name || ''} ${styleGlobal ? 'box-input-global-jsx' : ''}`}>
        <Required
          id={this.state.id}
          label={this.props.label}
          unitName={this.props.unitName}
          labelHidden={this.props.labelHidden}
          type="text"
          value={this.state.value}
          styleName={styleName}
          styleJsx={styleJsx}
          required={this.props.required}
          className={className}
          labelClassName={this.props.labelClassName}
          notColorValid={this.props.notColorValid}
          iconValid={this.props.iconValid}>

          <textarea
            ref={tag => this.textarea = tag}
            autoFocus={this.props.autoFocus}
            id={this.state.id}
            data-id={this.props.id}
            data-not-save={this.props.notSave}
            data-label={this.props.useLabel && this.props.label}
            data-use-attr={this.props.useAttr}
            data-type={this.props.inputType}
            placeholder={this.props.placeholder}
            onKeyUp={this.handleChange}
            className={`${this.props.inputClassName || ''} ${this.props.inputClassNameModify || 'min-height-45 pd-top-10 pd-bottom-10'}  ${inputForSite ? 'input-site' : 'input-no-site'} ${styleGlobal ? ' input-global-jsx' : ''} input-jsx ${this.props.required ? 'required' : ''} `}
            defaultValue={this.props.value}
            style={{ margin: '0', overflow: 'hidden', ...this.props.inputStyle || {} }}
            //maxLength="3"
          />
        </Required>

        <style jsx>{`
          .input-no-site {
            border-top: 0;
            border-left: 0;
            border-right: 0;
            outline: 0;
            border-bottom: 1px solid #9e9e9e;
          }
          .input-no-site:focus {
            border-bottom: 1px solid #26a69a;
            box-shadow: 0 1px 0 0 #26a69a;
          }
          .input-no-site.valid {
            border-bottom: 1px solid #4CAF50;
            box-shadow: 0 1px 0 0 #4caf50;
          }
          .input-no-site.invalid {
            border-bottom: 1px solid #F44336;
            box-shadow: 0 1px 0 0 #f44336;
          }
          .input-jsx {
            ${styleJsx && setStyleValue({ name, style: styleJsx })}
            outline: none;
            resize: none;
            -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
                    box-sizing: border-box;
          }
          .input-jsx:focus:not([readonly]) {
            ${styleJsx && setEvent('focus')}
          }
          .input-jsx.valid,
          .input-jsx:focus.valid {
            ${styleJsx && setEvent('valid', true)}
          }
          .input-jsx.invalid,
          .input-jsx:focus.invalid {
            ${styleJsx && setEvent('invalid', true)}
          }

          @media (min-width: 1500px) {
            .input-jsx {
              ${styleJsx && setStyleValue({ name, style: styleJsx, vw: true })}
            }
            .input-jsx:focus:not([readonly]) {
              ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-focus-box-shadow-vw`])}
              ${styleJsx && getStyle('border-width', styleJsx[`${name}-focus-border-width-vw`])}
            }
            .input-jsx.valid {
              ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-valid-box-shadow-vw`], true)}
              ${styleJsx && getStyle('border-width', styleJsx[`${name}-valid-border-width-vw`], true)}
            }
            .input-jsx.invalid {
              ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-invalid-box-shadow-vw`], true)}
              ${styleJsx && getStyle('border-width', styleJsx[`${name}-invalid-border-width-vw`], true)}
            }
          }

          @media (min-width: 601px) and (max-width: 1024px) {
            .input-jsx {
              ${styleJsx && setStyleValue({ device: 'tablet', name, style: styleJsx })}
            }
          }

          @media (min-width: 50px) and (max-width: 600px) {
            .input-jsx {
              ${styleJsx && setStyleValue({ device: 'mobile', name, style: styleJsx })}
            }
          }
        `}</style>
      </div>
    )
  }
}

export default Textarea

import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Components Local
import ItemsList from './components/ItemsList'
import PageItems from './components/PageItems'


const FetchDataListItems = (props) => {
  const {
    business,
    setting,
    id,
    title,
    icon,
    match,
    fetchAction,
    foldersManage,
    componentTitle,
    componentHeader,
    componentHeaderList,
    componentList,
    layoutFull,
    tableClassName,
    attribute,
    groupId,
    columnTitle,
    openSidebar,
    openModal,
    fnConfigItems,
  } = props


  return (
    <Fragment>
      { props.children }

      <PageItems
        paginationRef={props.paginationRef}
        id={id}
        match={match}
        className={`${props.className || 'pd-15'}`}
        foldersManage={foldersManage}
        location={props.location}
        history={props.history}
        title={title}
        icon={icon}
        fetchAction={fetchAction}
        items={props.datas}
        searchData={props.datasSearch}
        componentTitle={componentTitle}
        addItemModal={props.addItemModal}
        btnAddMore={props.btnAddMore}
        layoutFull={layoutFull}
        hiddenTitle={props.hiddenTitle}
        hiddenSearch={props.hiddenSearch}
        hiddenSearchCamera={props.hiddenSearchCamera}
        useFilter={props.useFilter}
        useSelector={props.useSelector}
        useReorderNo={props.useReorderNo}
        hiddenHeader={props.hiddenHeader}
        hiddenPaginationNumber={props.hiddenPaginationNumber}
        notReloadForUrlChange={props.notReloadForUrlChange}
        notReloadForUrlSearchChange={props.notReloadForUrlSearchChange}
        dataReloadForUrlChange={props.dataReloadForUrlChange}
        columnTitle={columnTitle}
        componentHeader={componentHeader}
        componentItemsNone={props.componentItemsNone}
        currentPageName={props.currentPageName}>

        <ItemsList
          getMapIdKey={props.getMapIdKey}
          unwind={props.unwind}
          unwindNewField={props.unwindNewField}
          location={props.location}
          history={props.history}
          match={match}
          tableClassName={tableClassName}
          dispatch={props.dispatch}
          //itemId={state.itemId}
          componentHeaderList={componentHeaderList}
          component={componentList}
          attribute={attribute}
          business={business}
          setting={setting}
          groupId={groupId}
          openSidebar={openSidebar}
          openModal={openModal}
          fnConfigItems={fnConfigItems}
          useSelector={props.useSelector}
          notItemListTable={props.notItemListTable}
          itemParams={props.itemParams}
        />
      </PageItems>
    </Fragment>
  )
}


const mapStateToProps = (store, props) => {
  if (props.storeName) {
    let datas = store[props.storeName].data
    let datasSearch = store[props.storeName].search

    if (props.datasFilter) {
      datas = props.datasFilter(datas) || []

      if (datasSearch && datasSearch.status === true) {
        datasSearch.data = props.datasFilter(datasSearch.data)
      }

      if (props.foldersManage) {
        const folders_id = props.match.params.folders_id || null

        datas = datas.filter(folder => folder.folders_id === folders_id)
      }
    }

    return {
      datas,
      datasSearch,
      folders: store.folders && store.folders.data
    }
  } else {
    return {}
  }
}

FetchDataListItems.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  //storeName: PropTypes.string.isRequired
}

export default connect(mapStateToProps)(FetchDataListItems)

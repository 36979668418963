import React, { useMemo } from 'react'

// Components Global
import TextInput from 'components/Inputs/TextInput'
import TextInputFetch from 'components/Inputs/TextInputFetch/TextInputFetch'
import { checkValueDuplicate } from 'core/redux/ConversationChannels/ConversationChannelsActions'
import InputDisabled from 'components/Inputs/InputDisabled/InputDisabled'


export const LineChannelIdDisabled = (props) => {
  const id = `lineChannelId`
  const value = props.value || ''

  return (
    <InputDisabled
      label="Channel ID"
      value={value}
    />
  )
}



export const LineChannelId = (props) => {
  const { business } = props
  const id = `lineChannelId`

  const fetchAction = (value, params) => {
    const query = {
      authName: 'admin',
      businesses_id: business._id
    }

    return checkValueDuplicate(query, {
      body: {
        match: {
          lineChannelId: value
        },
      },
      ...params
    })
  }

  return useMemo(() => <TextInputFetch
    label="Channel ID"
    id={id}
    name={id}
    fetchAction={fetchAction}
    getFetchData={props.getFetchData}
    value={props.value}
    active
    placeholder="xxxxx-xxxx-xx"
    inputType="text"
    type="code"
    fetchMsg={{
      success: "มี Channel ID นี้ อยู่ในระบบแล้ว",
    }}
    required
  />, [
    props.value
  ])
}

export const LineChannelSecret= (props) => {
  const id = `lineChannelSecret`
  const value = props.value || ''

  return (
    <TextInput
      label="Channel Secret"
      id={id}
      name={id}
      value={value}
      placeholder="xxxxxxxx-xxxxxxxx-xxxxxx"
      type="text"
      required
    />
  )
}

export const LineChannelAccessToken = (props) => {
  const id = `lineChannelAccessToken`
  const value = props.value || ''

  return (
    <TextInput
      label="Channel access token"
      id={id}
      name={id}
      value={value}
      placeholder="Channel access token"
      type="text"
      required
    />
  )
}









import React, { Fragment, useEffect, useState } from 'react'
import cuid from 'cuid'
import InputDisabled from '../InputDisabled'
import Required from '../Required'


const SelectDefault = (props) => {
  const [id, setId] = useState('')
  const [value, setValue] = useState('')

  useEffect(() => {
    const id = props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()

    setId(id+randomId)
    setValue(props.value !== undefined ? props.value : '')
  }, [])

  useEffect(() => {
    setValue(props.value)
  }, [props.value])


  const handleChange = (e) => {
    setValue(e.target.value)

    if (props.onChange) {
      props.onChange(e.target.value)
    }
  }

  return (
    <Fragment>
      { !props.disabled ?
        <Required
          id={id}
          label={props.label}
          unitName={props.unitName}
          type="text"
          value={value}
          required={props.required}
          className="mg-top-2"
          iconValid={props.iconValid}>

          <Fragment>
            <input
              data-id={props.id}
              data-not-save={props.notSave}
              data-child={props.child}
              defaultValue={value}
              type="hidden"
            />

            <select
              className={`browser-default font-0-9 select ${props.className || ''} ${props.required ? 'required' : ''}`}
              style={{ ...props.style, outline: 'none' }}
              disabled={props.disabled}
              id={`${id}`}
              name="select"
              onChange={handleChange}
              value={value}>

              { props.placeholder &&
                <option
                  value=""
                  style={{ color: '#2f2f2f' }}
                  disabled={props.placeholderDisabled}>
                  { props.placeholder }
                </option>
              }

              { props.options.map((option, key) => {
                  return (
                    <option key={key} value={option.value}>{option.name}</option>
                  )
              }) }

            </select>
          </Fragment>
        </Required>
      :
        <InputDisabled {...props} />
      }
    </Fragment>
  )
}


export default SelectDefault

import React from 'react'

// Components Global
import ProfileAvatar from '../../../../../../components/ProfileAvatar'

// Values
import { attributeChoiceValue } from '../../../../../../redux/Attributes/AttributesValues'


const AttributeChoiceProfileAvater = (props) => {
  let { attribute, choice, fontSize, imageSize } = props
  choice = attributeChoiceValue(attribute, choice)

  return (
    <ProfileAvatar
      circle={props.circle}
      images={choice.images}
      imageSize={imageSize || 'm'}
      color={choice.avatarColor}
      texts={choice.avatarName}
      textSize={fontSize || '1.1rem'}
    />
  )
}

export default AttributeChoiceProfileAvater

import React from 'react'

// Components Global
import ModalTabs from '../../../../../components/Modals/ModalTabs'

// Components Local
import Descriptions from './components/Descriptions'
import Seo from './components/Seo'

// Redux Actions
import { updatePage } from '../../../../../redux/Pages/PagesActions'


const PageUpdateModal = (props) => {
  const lang = props.lang || 'local'
  const { id, pages, pages_id } = props
  const page = pages.filter(page => page._id === pages_id)[0]

  const handleSubmit = (params, formName) => {
    const { pages_id, business } = props

    if (params) {
      props.dispatch(updatePage(business._id, pages_id, {
        body: params,
        loadId: formName,
        fieldId: formName,
        toastMsg: 'แก้ไขหน้าเพจแล้ว'
      }))
    }
  }

  if (page) {
    return (
      <div style={{ color: '#333'}}>
        <ModalTabs
          id={id}
          activeId={1}
          title={`แก้ไขหน้า : ${page.name[lang]}`}
          width="1000px"
          items={[
            { name: 'ข้อมูลหน้า', icon: 'description' },
            { name: 'SEO (Google)', icon: 'find_in_page' }
          ]}>

          <div id="1">
            <Descriptions {...props} page={page} name="descriptions" submit={handleSubmit} />
          </div>
          <div id="2">
            <Seo {...props} page={page} name="seo" submit={handleSubmit} />
          </div>
        </ModalTabs>
      </div>
    )
  } else {
    return (
      <div />
    )
  }
}

export default PageUpdateModal

import React from 'react'

// Components Places
import { PlaceProfileAvater } from '../PlacesUserInterface'

// Values
import { placeValue } from '../../../../../redux/Places/PlacesValues'


export const PlaceHeaderList = (props) => {
  return (
    <thead>
      <tr>
        <th colSpan="2">ชื่อสถานที่</th>
        <th className="width-60">สถานะ</th>
        <th className="pd-5"></th>
      </tr>
    </thead>
  )
}


export const PlaceList = (props) => {
  const place = placeValue(props.data)

  return (
    <tr
      onClick={() => props.onSelect(place._id)}
      className={props.selectedId === place._id  ? 'trActive' : ''}>

      <td className="width-65">
        <div className="width-45 height-45 mg-center">
          <PlaceProfileAvater place={place} fontSize="1.1rem" />
        </div>
      </td>
      <td className="line-height-10">
        <p className="font-0-9">{ place.nameLang }</p>
      </td>
      <td>
        { place.statusName }
      </td>
      <td className="pd-5"></td>
    </tr>
  )
}

const AttributesMenu = ({ params, typePath, hidden, label="" }) => {
  if (!hidden) {
    return [
      {
        name: 'คุณลักษณะ',
        title: true
      },
      {
        name: 'คุณลักษณะ' + label,
        nameShort: 'คุณลักษณะ',
        icon: 'group_work',
        link: `/${params.username}/${typePath}/attributes`
      }
    ]
  } else {
    return []
  }
}

export default AttributesMenu

import React, { Component, Fragment } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

// Components GLobal
import { BtnModalOpen } from '../Modals/BtnModal'
import BtnManager from '../BtnManager'
import Tooltip from '../TooltipNew'

// Fuctions
import { onDelete } from '../../redux/Pages/PagesFunctions'

// redux
import { openModal } from '../../redux/App/AppActions'

import {
  selectItems,
  countItemsSelected,
  generateItems1,
  onDragEnd,
  updateItemForDelete
} from './functions/dragDropMenuFunctions'

// css
import css from './css/dragDropMenuMange.css'


class DragDropMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      itemsMaxLength: 4,
    }
  }

  componentDidMount() {
    this.generateItems1()
  }

  componentDidUpdate(nextProps) {
    if (nextProps.pages.length !== this.props.pages.length) {
      this.generateItems1()
    }
  }

  generateItems1 = () => {
    const pages = this.props.pages
    const items1 = generateItems1({ items: pages })

    this.setState({ items1 })
  }

  onDragEnd = (result) => {
    onDragEnd(this, result)
  }

  openModal = (id) => {
    this.props.dispatch(openModal(id))
  }

  render() {
    const Container = (props) => {

      const lang = props.lang || 'local'
      const getItemStyle = (isDragging, draggableStyle, active) => {
        return ({
          userSelect: 'none',
          margin: `5px`,
          background: isDragging ? (active ? '#039be5' : 'lightgreen') : (active ? '#039be5' : '#fff' ),
          color: active ? '#fff' : '#333',
          ...draggableStyle
        })
      }

      const getListStyle = isDraggingOver => {
        return ({
          background: isDraggingOver ? 'lightblue' : '#fff',
          padding: '10px',
          height: 'calc(100% - 40px)'
        })
      }

      const ItemComponent = ({ item, active, lang }) => {
        const page = this.props.pages.filter(page => page._id === item._id)[0]

        if (page) {
          return (
            <div className={css.list}>
              <div className={css.selected} onClick={() => this.openModal('pageUpdateModal')}>
                <Tooltip
                  top
                  msg={page.navbarShow ? 'แสดงในเมนูบาร์' : 'ซ่อนในเมนูบาร์' }
                  width={page.navbarShow ? 90 : 82 }
                  style={{ fontSize: '0.8rem' }}
                  unit="px"
                  childHeight="45px">

                  <div className={css.content}>
                    { page.navbarShow ?
                      <i className="material-icons i-middle color-success">check_box</i>
                    :
                      <i className="material-icons i-middle">check_box_outline_blank</i>
                    }
                  </div>
                </Tooltip>
              </div>

              <div className={css.status}>
                <Tooltip
                  top
                  msg={page.status === 'open' ? 'แสดงหน้าเพจ' : 'ซ่อนหน้าเพจ' }
                  width={page.navbarShow ? 80 : 75 }
                  style={{ fontSize: '0.8rem' }}
                  unit="px"
                  childHeight="35px">

                  <div className={css.content}>
                    { page.status === 'close' &&
                      <div className={`${css.dot} bg-close`} />
                    }

                    { page.status === 'open' &&
                      <div className={`${css.dot} bg-success`} />
                    }
                  </div>
                </Tooltip>
              </div>

              <div className={css.icon}>
                { page.icon && page.icon.name ?
                  <Fragment>
                    { page.icon.type === 'material-icons' &&
                      <i className="material-icons i-middle font-1-3">{page.icon.name}</i>
                    }
                  </Fragment>
                :
                  <Fragment>
                    <i className="material-icons i-middle font-1-3">
                      { page.path === 'index' && 'home' }
                      { page.path !== 'index' && 'insert_drive_file' }
                    </i>
                  </Fragment>
                }
              </div>
              <div
                className={`${css.name} text-ellipsis`}
                style={{
                  right: active && (page.lists && page.lists.length !== 0) ? '120px' : active && '80px'
                }}>

                { page.status === 'close' &&
                  <s>{ page.name[lang] }</s>
                }

                { page.status === 'open' && page.name[lang] }
              </div>

              <div className={css.childAmount} style={{ right: !active && '0' }}>
                { page.lists && page.lists.length !== 0 && `(${page.lists.length})`}
              </div>

              { active &&
                <div className={css.manage}>
                  <table>
                    <tbody>
                      <tr>
                        <td width="40px" className="pd-0 text-right pd-right-5">
                          <BtnModalOpen id="pageUpdateModal">
                            <a className="btn btn-xxs btn-edit btn-shadow-none">
                              <i className="material-icons">edit</i>
                            </a>
                          </BtnModalOpen>
                        </td>
                        <td width="30px" className="pd-0 text-right">
                          { page.path === 'index' ?
                            <a className="btn btn-xxs btn-shadow-none dp-block disabled">
                              <i className="material-icons">delete</i>
                            </a>
                          :
                            <BtnManager
                              iconOnly
                              id={page._id}
                              btnSize="btn-xxs"
                              manager={[
                                {
                                  type: 'delete',
                                  show: true,
                                  label: 'ลบ',
                                  title: `ลบ "หน้า${page.name[lang]}" อย่างถาวร`,
                                  msg: `กดปุ่ม "ลบหน้านี้" ถ้าคุณต้องการลบหน้า "หน้า${page.name[lang]}" อย่างถาวร`,
                                  labelConfirm: 'ลบหน้านี้',
                                  handle: onDelete(this.props, {
                                    updateItem: () => updateItemForDelete(this, props.id)
                                  }),
                                }
                              ]}
                            />
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              }
            </div>
          )
        } else {
          return <div />
        }
      }

      return (
        <Droppable droppableId={props.id}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >

              { props.items && props.items.map((item, index) => {
                const selected = props.selected
                const active = selected && selected._id === item._id

                return (
                  <Draggable key={item._id} draggableId={item._id} index={index}>
                    {(provided, snapshot) => (
                      <div>
                        <div
                          onClick={() => selectItems(this, props.number, item)}
                          ref={provided.innerRef}
                          className={css.item}
                          {...provided.draggableProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                            active
                          )}
                        >
                          { item.path === 'index' ?
                            <div>
                              <div {...provided.dragHandleProps}></div>
                              <ItemComponent lang={lang} item={item} active={active} />
                            </div>
                          :
                            <div {...provided.dragHandleProps}>
                              <ItemComponent lang={lang} item={item} active={active} />
                            </div>
                          }
                        </div>
                      </div>
                    )}
                  </Draggable>
                )
              }) }

              { provided.placeholder }
            </div>
          ) }
        </Droppable>
      )
    }

    const { itemsMaxLength } = this.state
    const ItemsSelectedLength = countItemsSelected(this) + 1
    let width

    if (ItemsSelectedLength <= itemsMaxLength) {
      width = 100/ItemsSelectedLength + '%'
    } else {
      width = 100/itemsMaxLength + '%'
    }

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div className={css.dragDropMenu}>

          { Array.apply(1, Array(itemsMaxLength+1)).map((v, i) => {
            if (i > 0) {
              const selected = this.state[`items${i}_selected`]
              const selectedBefore = this.state[`items${i-1}_selected`]
              const name = i === 1 ? 'เมนูหลัก' : 'เมนูย่อย'

              const pages = this.props.pages
              const items = this.state[`items${i}`] || []

              items.map((list, i) => {
                const checkItem = pages.filter(item => item._id === list._id)[0]

                if (!checkItem) {
                  items.splice(i, 1)
                }
              })

              if (i === 1 || selectedBefore) {
                return (
                  <div key={i} style={{ width, float: 'left', borderRight: '1px solid #eee', minWidth: '350px' }}>
                    <div style={{ height: '40px', fontSize: '0.8rem', background: '#fafafa', lineHeight: '40px', paddingLeft: '15px', borderBottom: '1px solid #eee' }}>
                      { name }
                    </div>

                    <Container
                      id={`items${i}`}
                      items={items}
                      number={i}
                      selected={selected}
                    />
                  </div>
                )
              }
            }
          }) }

          <div style={{ clear: 'both' }} />
        </div>
      </DragDropContext>
    )
  }
}

export default DragDropMenu

import React, { useEffect, useState, useRef, Fragment } from 'react'
import { connect } from 'react-redux'
import cuid from 'cuid'

// Components Inputs
import Required from '../Required'
import Chip from '../../Chip'
import DropdownDefault from '../../DropdownDefaultV2'
import InputDisabled from '../InputDisabled'

//css
import css from './css/dropdown.css'


const InputSelectDropdown = (props) => {
  const dropdownRef = useRef()
  const [id, setId] = useState('')
  const [value, setValue] = useState('')
  const [dataSeleted, setDataSeleted] = useState('')

  useEffect(() => {
    const newId = props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()

    setId(randomId+newId)
  }, [])

  useEffect(() => {
    setValue(props.value || '')

    if (props.value) {
      const dataSeleted = datas.filter(data => data._id === props.value)[0]

      if (dataSeleted) {
        setDataSeleted(dataSeleted)
      }
    }

  }, [props.value])

  useEffect(() => {
    if (props.dataSeleted) {
      setDataSeleted(props.dataSeleted)
    }
  }, [props.dataSeleted])

  useEffect(() => {
    /*if (props.select) {
      props.select(dataSeleted)
    }*/
  }, [dataSeleted])

  const select = (data) => {
    setDataSeleted(data)
    dropdownRef.current.close()

    if (props.select) {
      props.select(data)
    }
  }

  const unSelect = () => {
    setDataSeleted(undefined)
    setValue('')

    if (props.remove) {
      props.remove()
    }

    if (props.select) {
      props.select(undefined)
    }
  }

  const { hiddenInputBorderForSeleted, useSubData, datas } = props

  return (
    <Fragment>
      { !props.disabled ?
        <Required
          id={id}
          value={value}
          label={props.label}
          labelHidden={props.labelHidden}
          unitName={props.unitName}
          className={props.className}
          labelClassName={props.labelClassName}
          type="text"
          required={props.required}
          iconValid={props.iconValid}>

          <input
            type="hidden"
            data-id={`${props.id}`}
            value={dataSeleted && dataSeleted._id || ''}
          />

          { dataSeleted ?
            <div
              className="pd-top-7"
              id={id}
              style={!hiddenInputBorderForSeleted ? { borderBottom: '1px solid #4CAF50', boxShadow: '0 1px 0 0 #4CAF50' } : {}}>

              { props.chipComp ?
                props.chipComp({ data: dataSeleted, remove: unSelect })
              :
                <Chip
                  id={props.id}
                  value={props.setChipValue ? props.setChipValue(dataSeleted) : props.chipValue}
                  remove={unSelect}
                  avatar={props.avatar}
                  avatarIcon={props.avatarIcon}
                  chipInput={props.chipInput}
                  size={props.chipSize}
                  className={props.chipClassName}
                />
              }
            </div>
          :
            <DropdownDefault
              targetNotClose
              left={props.left}
              right={props.right}
              ref={dropdownRef}
              className="width-full"
              contentClassName="min-width-250 mg-bottom-50">

              <input
                type="text"
                id={id}
                data-not-save={true}
                placeholder={props.placeholder}
                className={`${props.inputClassName || ''} ${props.required ? `required` : ''}`}
              />

              <div className={css.boxDropdown}>
                <ul className={`${css.dropdown} mg-bottom-30`}>
                  <div id={props.id} className={css.content}>
                    { !useSubData && datas.map(data => {
                      return (
                        <li key={data._id}>
                          <a onClick={() => select(data)} className={props.linkClassName || ''}>
                            { props.item && props.item(data) }
                            { props.itemComp && props.itemComp(data) }
                          </a>
                        </li>
                      )
                    }) }

                    { useSubData && datas.map(data => {
                      return data.items.map(item => {
                        return (
                          <li key={item._id}>
                            <a onClick={() => select({ data, item })}>
                              { props.item && props.item(data, item) }
                              { props.itemComp && props.itemComp(data) }
                            </a>
                          </li>
                        )
                      })
                    }) }
                  </div>
                </ul>
              </div>
            </DropdownDefault>
          }
        </Required>
      :
        <InputDisabled {...props} value="" />
      }
    </Fragment>
  )
}

export default InputSelectDropdown

import React from 'react'

// Components Global
import ModalFixed from '../../../../../components/Modals/ModalFixed'
import { BtnModalClose } from '../../../../../components/Modals/BtnModal'

// Components Products
import ProductsListTabs from '../ProductsListTabs'


const ProductsListModal = (props) => {
  const id = "productsListModal"

  return (
    <ModalFixed
      blockBgModal
      id={id}
      title="เลือกรายการสินค้า"
      width="1200px"
      headerClassName="height-50 line-height-30"
      bodyClassName="top-50 bottom-10 pd-5 bg-efefef"
      footerClassName="height-10 pd-0">

      <ProductsListTabs {...props} modal />

      <div></div>
    </ModalFixed>
  )
}

export default ProductsListModal

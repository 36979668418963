import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import { Row, Column } from '../../../../../../../components/Columns'
import EditData from '../../../../../../../components/EditData'
import DatasList from '../../../../../../../components/DatasList'
import Section from '../../../../../../../components/Section'

// Components Local
import {
  PageSeoTitle,
  PageSeoDescription,
  PageSeoKeywords,
  PageSeoImageUrl
} from '../../../PageForms'


const Seo = (props) => {
  const lang = props.lang || 'local'
  const { page, name, submit } = props

  return (
    <div>
      <div className="box">
        <Section title="ข้อมูล SEO (Google)">
          <EditData
            recheckForm
            name={name}
            submit={submit}>

            <div>
              <DatasList
                style={{ border: '0' }}
                items={[
                  {
                    labelTop: true,
                    label: 'หัวข้อ',
                    value: page.seo && page.seo.title[lang] || '-',
                    icon: 'title',
                    className: 'border-top-eee'
                  },
                  {
                    labelTop: true,
                    label: 'รายละเอียด',
                    value: page.seo && page.seo.description[lang] || '-',
                    icon: 'notes'
                  },
                  {
                    labelTop: true,
                    label: 'คีย์เวิร์ด',
                    value: page.seo && page.seo.keywords[lang] || '-',
                    icon: 'list_alt',
                    className: 'border-bottom-eee'
                  },
                  {
                    labelTop: true,
                    label: 'รูปภาพ',
                    value: page.seo && page.seo.imageUrl || '-',
                    icon: 'list_alt',
                    className: 'border-bottom-eee'
                  }
                ]}
              />
            </div>

            <div className="pd-top-15">
              <Row>
                <Column col="column col-xs-12">
                  <PageSeoTitle lang={lang} value={page.seo && page.seo.title} />
                  <PageSeoDescription lang={lang} value={page.seo && page.seo.description} />
                  <PageSeoKeywords lang={lang} value={page.seo && page.seo.keywords} />
                  <PageSeoImageUrl value={page.seo && page.seo.imageUrl} />
                </Column>
              </Row>
            </div>
          </EditData>
        </Section>
      </div>
    </div>
  )
}


export default connect()(Seo)

import { fetchDatas, fetchData, createData, updateData, removeData } from '../reduxActions'
import { fetchDatasMany } from '../reduxActionsV2'
import { generateTypeName, generateTypeFunctions } from '../reduxTypesV2'

import { checkAppActions } from '../App/AppActions'
import uploadsForS3 from 'client/core/utils/uploadsForS3'

// Export Constants
const NAME_TYPE = ['BUSINESSES', 'BUSINESS']
const name = "businesses"

// Type Names
export const {
  ADDS, ADDS_MORE, ADD, UPDATE, REMOVE,
  ADDS_MANY, ADDS_MANY_MORE,
} = generateTypeName(NAME_TYPE)

// Type Functions
export const {
  adds, adds_more, add, update, remove,
  adds_many, adds_many_more,
} = generateTypeFunctions(NAME_TYPE)

// Actions
let setting

if (process.env.APP_PROJECT === 'dashboard' || process.env.APP_PROJECT === 'store' || process.env.APP_PROJECT === 'rubber' || process.env.APP_PROJECT === 'websites') {
  setting = require(`../../../redux/Settings/SettingsActions`)
} else {
  setting = require(`../../../projects/${process.env.APP_PROJECT}/redux/Settings/SettingsActions`)
}


export const fetchBusinesses = (params={}) => dispatch => {
  return fetchDatas({
    dispatch,
    params,
    api: `${name}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchQueryBusinessOne = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return fetchData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}`,
    add: add
  })
}

export const fetchQueryBusinessesMany = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return fetchDatasMany({
    dispatch,
    params,
    api: `${name}-many/${authName}/${businesses_id}`,
    adds_many,
    adds_many_more
  })
}

export const fetchBusinessesByAdmin = (params={}) => dispatch => {
  return fetchDatas({
    dispatch,
    params,
    api: `${name}-admin`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchBusinessByUsernameAndAttributes = (username, params) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `${name}-by-username-and-attributes/${username}`,
    addMultiple: [
      { storeName: 'businesses', add }
    ]
  })
}

export const fetchBusinessStart = (username, params) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `${name}-start/${username}`,
    addMultiple: [
      { storeName: 'businesses', add },
      { storeName: 'settings', add: setting.adds },
    ]
  })
}

export const fetchBusinessByUsername = (username, params) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `${name}-by-username/${username}`,
    add: add
  })
}

export const fetchBusiness = (_id, params) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `${name}/${_id}`,
    add: add
  })
}

export const createBusiness = (params) => dispatch => {
  return createData({
    dispatch,
    params,
    api: `${name}/create`,
    add: add
  })
}

export const updateBusiness = (_id, params) => dispatch => {
  return updateData({
    dispatch,
    params,
    api: `${name}/${_id}`,
    update: update,
    updateId: _id
  })
}

export const daleteBusiness = ( _id, params) => dispatch => {
  return removeData({
    dispatch,
    params,
    api: `${name}/${_id}`,
    remove: remove,
    deleteId: _id
  })
}


export const uploadImagesBusiness = (businesses_id, files, prefix, params) => dispatch => {
  checkAppActions(dispatch, params, 'start')
  uploadsForS3({
    files,
    prefix,
    progressId: params.progressId,
    dispatch,
    businesses_id
  }).then(val => {
    val.map(paths => {
      params.body.paths = paths
      dispatch(updateBusiness(businesses_id, params))
    })
  })
}

import moment from 'moment'

const daysFullEn = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const daysEn = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const daysFullTh = ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์']
const daysTh = ['อ', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส']
const monthsFullEn = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const monthsEn = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const monthsFullTh = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม']
const monthsTh = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค']


export const generateCalendar = (_this) => {
  let currentDate = getCurrentDate(_this)
  let loopDate = getCurrentDate(_this)

  loopDate.setMonth(loopDate.getMonth() + 1)
  loopDate.setDate(loopDate.getDate() - 1)

  let maxDate = loopDate.getDate()
  let firstDay = currentDate.getDay()
  let lastDay = 6 - loopDate.getDay()

  getDatesOfMonthFirst(_this, currentDate, firstDay)
  getDatesOfMonth(_this, currentDate, loopDate, maxDate)
  getDatesOfMonthLast(_this, currentDate, lastDay)
}

export const getLang = (_this) => {
  if (_this.props.lang === 'en') {
    _this.setState({
      lang: {
        monthsFull: monthsFullEn,
        months: monthsEn,
        daysFull: daysFullEn,
        days: daysEn
      }
    })
  } else {
    _this.setState({
      lang: {
        monthsFull: monthsFullTh,
        months: monthsTh,
        daysFull: daysFullTh,
        days: daysTh
      }
    })
  }
}

export const getYearsAll = (_this) => {
  let yearStart = 1916
  let yearEnd = 2117
  let yearsAll = []

  for (var year = yearStart; year <= yearEnd; year++) {
    yearsAll.push(year)
  }

  _this.setState({ yearsAll: yearsAll })
}

export const getConvertFullDate = (year, month, date) => {
  month = ('0' + month).slice(-2)
  date = ('0' + date).slice(-2)

  return year + '-' + month + '-' + date
}

export const getCurrentDate = (_this) => {
  let currentDate = new Date(_this.state.currentDate.year, _this.state.currentDate.month, _this.state.currentDate.date, 0, 0, 0, 0)
  currentDate.setDate(1)

  return currentDate
}

export const getDatesOfMonthFirst = (_this, currentDate, firstDay) => {
  let loopDate = new Date(_this.state.currentDate.year, _this.state.currentDate.month - 1, 1, 0, 0, 0, 0)

  loopDate.setMonth(loopDate.getMonth() + 1)
  loopDate.setDate(loopDate.getDate() - 1)

  let dateMonthLater = loopDate.getDate()
  let dateValue = []

  loopDate.setDate(dateMonthLater - (firstDay - 1))

  for (var i = dateMonthLater - firstDay; i < dateMonthLater; i++) {
    dateValue.push({
      year: currentDate.getFullYear(),
      month: currentDate.getMonth(),
      date: loopDate.getDate(),
      day: loopDate.getDay()
    })

    loopDate.setDate(loopDate.getDate() + 1)
  }

  _this.setState({ datesOfMonthFirst: dateValue })
}

export const getDatesOfMonth = (_this, currentDate, loopDate, maxDate) => {
  let dateValue = []

  loopDate.setDate(1)

  Array(maxDate).fill(1).map((val, key) => {
    dateValue.push({
      fullDate: getConvertFullDate(currentDate.getFullYear(), currentDate.getMonth() + 1, loopDate.getDate()),
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      date: loopDate.getDate(),
      day: loopDate.getDay()
    })

    loopDate.setDate(loopDate.getDate() + 1)
  })

  _this.setState({ datesOfMonth: dateValue })
}

export const getDatesOfMonthLast = (_this, currentDate, lastDay) => {
  let loopDate = new Date(_this.state.currentDate.year, _this.state.currentDate.month + 1, 1, 0, 0, 0, 0)

  loopDate.setMonth(loopDate.getMonth() + 1)
  loopDate.setDate(loopDate.getDate() - 1)

  let dateLater = loopDate.getDate()
  let dateValue = []

  loopDate.setDate(1)

  for (var i = 0; i < lastDay; i++) {
    dateValue.push({
      year: currentDate.getFullYear(),
      month: currentDate.getMonth(),
      date: loopDate.getDate(),
      day: loopDate.getDay()
    })

    loopDate.setDate(loopDate.getDate() + 1)
  }

  _this.setState({ datesOfMonthLast: dateValue })
}


// pan events
export const handlePanEnd = (_this) => {
  if (_this.props.mutipleSelect) {
    _this.setState({
      pan: false,
      panLeft: false,
      panRight: false,
      panLeftAdd: false,
      panRightAdd: false,
      panLeftOff: true,
      panRightOff: true
    })
  }
}

export const handlePanLeft = (_this, date) => {
  if (!_this.state.panLeft && !_this.state.panRight && !_this.state.panLeftAdd && !_this.state.panRightAdd && !_this.state.panLeftOff && _this.props.mutipleSelect) {
    _this.setState({ panLeft: true, panRight: false }, () => {
      let fullDate = getConvertFullDate(_this.state.currentDate.year, _this.state.currentDate.month + 1, date.date)
      _this.state.datesSelect.push(fullDate)
    })
  } else {
    if (_this.state.panLeftOff && _this.props.mutipleSelect) {
      _this.setState({ panLeftOff: false })
    }
  }
}

export const handlePanRight = (_this, date) => {
  if (!_this.state.panLeft && !_this.state.panRight && !_this.state.panRightAdd && !_this.state.panRightOff && _this.props.mutipleSelect) {
    _this.setState({ panLeft: false, panRight: true }, () => {
      let fullDate = getConvertFullDate(_this.state.currentDate.year, _this.state.currentDate.month + 1, date.date)
      _this.state.datesSelect.unshift(fullDate)
    })
  } else {
    if (_this.state.panRightOff && _this.props.mutipleSelect) {
      _this.setState({ panRightOff: false })
    }
  }
}

export const handlePanLeftAdd = (_this, date) => {
  if (!_this.state.panLeftAdd && _this.props.mutipleSelect) {
    _this.setState({ panLeftAdd: true, panRightAdd: false, panLeft: false, panRight: false })
  }
}

export const handlePanRightAdd = (_this, date) => {
  if (!_this.state.panLeftAdd && _this.props.mutipleSelect) {
    _this.setState({ panLeftAdd: false, panRightAdd: true, panLeft: false, panRight: false })
  }
}

export const getDatesBetween = (_this, startDate, endDate) => {
  let datesSelectNew = []
  let diffDate = Math.abs(endDate.diff(startDate, 'days'))

  for (var i = 0; i <= diffDate; i++) {
    startDate = moment(_this.state.datesSelect[0])
    let betweenDates = startDate.add(i, 'days').format('YYYY-MM-DD')
    datesSelectNew.push(betweenDates)
  }

  _this.setState({ datesSelect: datesSelectNew }, () => {
    getDatesCalendar(_this)
  })
}

export const handleSelectMutiDates = (_this, date) => {
  if (_this.props.mutipleSelect) {
    if (_this.state.panLeft || _this.state.panRight || _this.state.panLeftAdd || _this.state.panRightAdd) {
      let datesSelect = _this.state.datesSelect

      if (_this.state.panLeft && !_this.state.panRight && !_this.state.panLeftAdd && !_this.state.panRightAdd) {
        //console.log('panLeft')
        datesSelect.unshift(date.fullDate)
      }

      if (!_this.state.panLeft && _this.state.panRight && !_this.state.panLeftAdd && !_this.state.panRightAdd) {
        //console.log('panRight')
        datesSelect.push(date.fullDate)
      }

      if (_this.state.panLeftAdd && !_this.state.panRightAdd) {
        //console.log('panLeftAdd')
        datesSelect.unshift(date.fullDate)
      }

      if (!_this.state.panLeftAdd && _this.state.panRightAdd) {
        //console.log('panRightAdd')
        datesSelect.push(date.fullDate)
      }

      let startDate = moment(datesSelect[0])
      let endDate = moment(datesSelect[datesSelect.length - 1])

      getDatesBetween(_this, startDate, endDate)
    }
  }
}

export const handleSelectDate = (_this, date) => {
  let datesSelect = []
  datesSelect.push(date.fullDate)

  _this.setState({ datesSelect: datesSelect }, () => {
    getDatesCalendar(_this)
  })
}

export const nextMonthAndYear = (_this, month, year) => {
  const currentMonth = _this.state.currentDate.month
  const currentYear = _this.state.currentDate.year
  const currentDate = getCurrentDate(_this)

  month = month - currentMonth
  year = year - currentYear

  currentDate.setMonth(currentDate.getMonth() + month)
  currentDate.setFullYear(currentDate.getFullYear() + year)

  if (_this.props.type !== 'datePicker') {
    var datesSelect = {
      datesSelect: [],
      currentDate: {
        year: currentDate.getFullYear(),
        month: currentDate.getMonth(),
        date: currentDate.getDate()
      }
    }
  } else {
    var datesSelect = {
      currentDate: {
        year: currentDate.getFullYear(),
        month: currentDate.getMonth(),
        date: currentDate.getDate()
      }
    }
  }

  _this.setState(datesSelect, () => {
    generateCalendar(_this)
    getDatesCalendar(_this, false)
  })
}

export const nextMonth = (_this, month) => {
  let currentDate = getCurrentDate(_this)
  currentDate.setMonth(currentDate.getMonth() + month)

  if (_this.props.type !== 'datePicker') {
    var datesSelect = {
      datesSelect: [],
      currentDate: {
        year: currentDate.getFullYear(),
        month: currentDate.getMonth(),
        date: currentDate.getDate()
      }
    }
  } else {
    var datesSelect = {
      currentDate: {
        year: currentDate.getFullYear(),
        month: currentDate.getMonth(),
        date: currentDate.getDate()
      }
    }
  }

  _this.setState(datesSelect, () => {
    generateCalendar(_this)
    getDatesCalendar(_this, false)
  })
}

export const nextYear = (_this, year) => {
  let currentDate = getCurrentDate(_this)
  currentDate.setFullYear(currentDate.getFullYear() + year)

  if (_this.props.type !== 'datePicker') {
    var datesSelect = {
      datesSelect: [],
      currentDate: {
        year: currentDate.getFullYear(),
        month: currentDate.getMonth(),
        date: currentDate.getDate()
      }
    }
  } else {
    var datesSelect = {
      currentDate: {
        year: currentDate.getFullYear(),
        month: currentDate.getMonth(),
        date: currentDate.getDate()
      }
    }
  }

  _this.setState(datesSelect, () => {
    generateCalendar(_this)
    getDatesCalendar(_this, false)
  })
}

export const dateNow = (_this) => {
  let dateNow = new Date()

  _this.setState({
    currentDate: {
      year: dateNow.getFullYear(),
      month: dateNow.getMonth(),
      date: dateNow.getDate()
    }
  }, () => {
    generateCalendar(_this)
    getDatesCalendar(_this, false)
  })
}

export const monthChange = (e, _this) => {
  let monthCurrent = _this.state.currentDate.month
  let monthNew = e.target.value

  nextMonth(_this, monthNew - monthCurrent)
}

export const yearChange = (e, _this) => {
  let currentYear = _this.state.currentDate.year
  let newYear = e.target.value

  nextYear(_this, newYear - currentYear)
}

export const selectDateFilter = (_this, date) => {
  let select = _this.state.datesSelect.filter(dateSelect => dateSelect === date.fullDate)[0]

  if (select) return true
  else return false
}

export const getDatesCalendar = (_this, close=true) => {
  if (_this.props.getDatesCalendar) {
    let note

    if (_this.props.datesNotes)
      note = _this.props.datesNotes.filter(note => note.date === _this.state.datesSelect[0])[0]

    let dates = {
      startDate: _this.state.datesSelect[0],
      endDate: _this.state.datesSelect[_this.state.datesSelect.length -1],
      note: note
    }

    _this.props.getDatesCalendar(dates, close)
  }
}

// Containers
import RubberPurchasesContainer from '../../../../../../containers/dashboard/SystemRubber/RubberPurchases/containers/RubberPurchasesContainer'

// Routes
import RubberPurchasesMenuRoutes from './routes/RubberPurchasesMenuRoutes'



const RubberPurchasesRoutes = [
  {
    path: '/:username/rubber-purchases',
    component: RubberPurchasesContainer,
    routes: [
      ...RubberPurchasesMenuRoutes,
    ]
  }
]

export default RubberPurchasesRoutes

import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../components/Modals/Modal'
import { Row, Column } from '../../../../../components/Columns'

// Components forms
import FormValidation from '../../../../../components/FormValidation'
import BtnSubmit from '../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../util/getFormValue'

// Components Services
import { PageName, PagePath } from '../PageForms'

// Actions Redux
import { createPage } from '../../../../../redux/Pages/PagesActions'


class PageCreateModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: ''
    }
  }

  handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(this.refs[formName])
    const { business, site } = this.props

    if (params) {

      /*const redirect = !this.props.onSelect && {
        username: `/${this.props.business.username}/services`,
      }*/

      this.props.dispatch(createPage(business._id, site._id, {
        body: params,
        loadId: formName,
        modalId: formName,
        props: this.props,
        //redirect,
        toastMsg: 'เพิ่มหน้าเว็บใหม่แล้ว'
      }))
    }
  }

  handleChange = (data) => {
    this.setState({ data })
  }

  render() {
    const id = "pageCreateModal"
    const { data } = this.state

    let { pages, site } = this.props
    pages = pages || []

    const pathValueAuto = 'page-' + (pages.length + 1)


    return (
      <Modal
        id={id}
        headerTitle="เพิ่มหน้าเว็บใหม่"
        headerLeft
        blockBgModal
        width="450px">

        <Row className="pd-20 pd-top-30">
          <FormValidation
            recheckForm
            ref={id}
            name={id}
            dataChange={JSON.stringify(data)}
            submit={(e) => this.handleSubmit(e, id)}>

            <Column col="column col-xs-12">
              <input type="hidden" data-id="sites_id" defaultValue={site._id} />

              <PageName />
              <PagePath {...this.props} onChange={this.handleChange} value={pathValueAuto} />
            </Column>

            <Column col="column col-xs-12 text-right pd-top-20">
              <BtnSubmit
                loadingId={id}
                icon="send"
                iconAlign="left"
                text="เพิ่มหน้าเว็บใหม่"
                className="waves-effect waves-light btn btn-submit btn-shadow-none"
                btnDisabled
              />
            </Column>
          </FormValidation>
        </Row>
      </Modal>
    )
  }
}

export default connect()(PageCreateModal)

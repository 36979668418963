
// Containers
import AdminsContainer from 'core/modules/Admins/containers/AdminsContainer'

// Routes
import AdminsMenuRoutes from './routes/AdminsMenuRoutes'


const CustomersRoutes = [
  {
    path: '/:username/admins',
    component: AdminsContainer,
    routes: [
      ...AdminsMenuRoutes
    ]
  }
]

export default CustomersRoutes

import React, { Fragment } from 'react'

// Components Global
import { Column, Row } from '../../../../../../components/Columns'

// Actions
import { openModal } from '../../../../../../redux/App/AppActions'
import { getNumeral } from '../../../../../../util/getNumeral'

// Components RubberSaleForms
import {
  RubberTypeSelect,
  RubberType2Select,
  SaleCustomerBuyer,
  SaleDate,
  SaleNote,
  TitleShow
} from './RubberSaleForms'


const SaleDetailsForms = (props) => {
  const { type, customer, rubberType, rubberType2 } = props

  return (
    <Row className="row-dp-flex mg-left--10 mg-right--10">

      { props.forContract &&
        <Fragment>
          <Column col="column col-xxs-12 col-xs-6 pd-left-10 pd-right-10">
            <TitleShow
              value={props.forContract.code}
              type="text"
              label="เลขที่สัญญาขายล่วงหน้า"
              textAlign="left"
              required
            />
          </Column>
          <Column col="column col-xxs-12 col-xs-6 pd-left-10 pd-right-10">
            <TitleShow
              value={`${getNumeral(props.forContract.netWeightDiff, 'auto')} กก.`}
              type="text"
              label="นน.ยางสด ที่ต้องส่งเพิ่ม"
              textAlign="left"
              required
            />
          </Column>
        </Fragment>
      }

      <Column col="column col-xxs-12 col-md-4 pd-left-10 pd-right-10">
        <SaleDate label={props.saleDateLabel} value={props.data && props.data.date} />
      </Column>
      <Column col="column col-xxs-12 col-md-8 pd-left-10 pd-right-10">
        { type.code === 'rubber-sheet-make' ?
          <TitleShow textAlign="left" value="ซื้อเพื่อทำยางแผ่น (ซื้อเอง)" type="text" label="โรงงาน/ผู้ซื้อ" />
        :
          <table>
            <tbody>
              <tr>
                <td className="pd-0">
                  <SaleCustomerBuyer
                    {...props}
                    type={type}
                    customer={customer}
                    onChange={props.handleSelectCustomer}
                    required
                    disabled={props.forContract || props.fixed}
                  />
                </td>

                { (!props.forContract && !props.fixed) &&
                  <td className="pd-0 pd-top-8 width-50 text-right vertical-top">
                    <a onClick={() => props.dispatch(openModal('customerCreateModal'))} className="btn btn-shadow-none btn-white border-ddd width-40 height-40 line-height-40  pd-0 center">
                      <i className="material-icons">add</i>
                    </a>
                  </td>
                }
              </tr>
            </tbody>
          </table>
        }
      </Column>


      <Column col={`column col-xxs-12 col-sm-6 pd-left-10 pd-right-10`}>
        <RubberTypeSelect
          fixed={props.fixed}
          event={props.event}
          forDeposit={props.forDeposit}
          forContract={props.forContract}
          productType={props.productType}
          disabled={props.event === 'update'}
          value={props.data && props.data.rubberType}
          onChange={props.handleSelectRubberType}
        />
      </Column>


      <Column col="column col-xxs-12 col-sm-6 pd-left-10 pd-right-10">
        <RubberType2Select
          fixed={props.fixed}
          event={props.event}
          forDeposit={props.forDeposit}
          forContract={props.forContract}
          productType={props.productType}
          rubberType={props.rubberType}
          rubberType2={props.rubberType2}
          disabled={props.event === 'update'}
          value={props.data && props.data.rubberType2}
          onChange={props.handleSelectRubberType2}
          saleType={props.saleType}
        />
      </Column>


      <Column col="column col-xxs-12 pd-left-10 pd-right-10">
        <SaleNote value={props.data && props.data.note}  />
      </Column>
    </Row>
  )
}

export default SaleDetailsForms

import React from 'react'
import renderRoutes from '../../../../routes/renderRoutes'

// Components Global
import WindowBack from '../../../../components/Windows/WindowBack'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../components/MenuSidebarResponsive'


const menuItems = (params, place) => {
  return [
    {
      name: 'รายการแก้ไข',
      title: true
    },
    {
      name: 'รายละเอียด',
      nameShort: 'รายละเอียด',
      icon: 'description',
      link: `/${params.username}/places/${place.status}/${params.places_id}/update`,
      exact: true
    },
    {
      name: 'รูปภาพ',
      nameShort: 'รูปภาพ',
      icon: 'photo_library',
      link: `/${params.username}/places/${place.status}/${params.places_id}/update/photos`
    },
    {
      name: 'หน้าเว็บไซต์',
      nameShort: 'เว็บไซต์',
      icon: 'web',
      link: `/${params.username}/places/${place.status}/${params.places_id}/update/site`
    }
  ]
}


const PlaceUpdateContainer = (props) => {
  const { route, match, place, business, location } = props
  const { params } = match
  const { username, places_id } = params

  let listName

  if (place.status === 'open') listName = 'เปิดใช้งาน'
  else if (place.status === 'close') listName = 'ปิดใช้งาน'
  else if (place.status === 'trash') listName = 'ที่ถูกลบ'

  return (
    <WindowBack
      title={`แก้ไข : ${place.nameLang}`}
      linkBack={`/${params.username}/places/${place.status}`}
      breadcrumbs={[
        { label: 'สถานที่ท่องเที่ยว', url: `/${username}/places` },
        { label: listName, url: `/${username}/places/${place.status}` },
        { label: place.nameLang, url: `/${username}/places/${place.status}/${places_id}/update` },
      ]}>

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive items={menuItems(params, place)} />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, place },
            location
          }) }
        </Layouts2>

      </LayoutsTwo>
    </WindowBack>
  )
}

export default PlaceUpdateContainer

import React from 'react'
import renderRoutes from '../../../../routes/renderRoutes'
import { connect } from 'react-redux'

// Components Global
import Window from '../../../../components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../components/MenuSidebarResponsive'

// imgs
import titleIcon from '../../Businesses/resources/icons/articles.png'


const menuItems = (params) => {
  const { username, attributes_path } = params

  return [
    {
      name: 'รายการ',
      title: true
    },
    {
      name: 'เปิดใช้งาน',
      nameShort: 'เปิดใช้งาน',
      icon: 'public',
      link: `/${username}/notes/${attributes_path}`,
      exact: true
    },
    {
      name: 'ปิดใช้งาน',
      nameShort: 'ปิดใช้งาน',
      icon: 'lock',
      link: `/${username}/notes/${attributes_path}/close`
    },
    {
      name: 'ที่ถูกลบ',
      nameShort: 'ลบแล้ว',
      icon: 'delete',
      link: `/${username}/notes/${attributes_path}/trash`
    },
  ]
}

const NotesMenuContainer = (props) => {
  let { attribute, business, match } = props
  const { username } = match.params

  return (
    <div>
      { attribute ?
        <Window
          title={attribute.nameLang}
          avatar={{
            images: attribute.images,
            color: attribute.avatarColor,
            texts: attribute.avatarName
          }}
          titleIcon={titleIcon}
          linkClose={`/${username}`}
          linkHelp="">

          <LayoutsTwo>
            <Layouts1>
              <MenuSidebarResponsive
                btnAddModal={{
                  modalId: 'noteCreateModal',
                  text: `เพิ่ม${attribute.nameLang}`,
                }}
                items={menuItems(match.params)}
              />
            </Layouts1>

            <Layouts2 hideScrollbars>
              { renderRoutes({
                routes: props.route.routes,
                extraProps: { business, attribute },
                location: props.location,
              }) }
            </Layouts2>
          </LayoutsTwo>
        </Window>
      :
        <div className="center pd-top-100">
          <h1>ไม่เจอข้อมูล</h1>
        </div>
      }
    </div>
  )
}

export default connect()(NotesMenuContainer)

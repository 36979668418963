import React, { Component, Fragment } from 'react'

// Components Global
import TextInput from '../../../../../components/Inputs/TextInput'
import InputFile from '../../../../../components/Inputs/InputFile'
import InputCheckDuplicate from '../../../../../components/Inputs/InputCheckDuplicate'
import Textarea from '../../../../../components/Inputs/Textarea'
import RadioGroup from '../../../../../components/Inputs/RadioGroup'
import InputSelectFetchData from '../../../../../components/Inputs/InputSelectFetchData'
import SelectData from '../../../../../components/Inputs/SelectData'
import Select from '../../../../../components/Inputs/Select'
import InputAddressGeo from '../../../../../components/Inputs/InputAddressGeo'


// Values
import { attributeChoiceValue } from '../../../../../redux/Attributes/AttributesValues'


const getPrefix = (type) => {
  let prefix

  if (type === 'note') {
    prefix = `notes`
  } else if (type === 'article') {
    prefix = `articles`
  }  else if (type === 'product') {
    prefix = `products`
  }

  return prefix
}


export const AttributeInputs = (props) => {
  const { attribute, data, update } = props
  const lang = props.label || 'local'
  let attributeItems

  if (update) {
    attributeItems = data.attributeItems
  } else {
    attributeItems = attribute.items
  }

  return (
    <Fragment>
      { attributeItems && attributeItems.map(item => {
        let input = item

        if (update) {
          input = item.item
        }

        return (
          <Fragment key={input._id}>
            { input.inputType === 'choice' &&
              <AttributeInputChoice item={input} attribute={attribute} value={item.value} />
            }

            { input.inputType === 'boolean' &&
              <AttributeInputBoolean item={input} attribute={attribute} value={item.value} />
            }

            { (input.inputType === 'string-lang' || input.inputType === 'string') &&
              <AttributeInputString
                item={input}
                lang={input.inputType === 'string-lang' && lang}
                attribute={attribute}
                value={item.value}
              />
            }

            { (input.inputType === 'text-lang' || input.inputType === 'text-lang' || input.inputType === 'number') &&
              <AttributeInputTextarea
                item={input}
                lang={input.inputType === 'text-lang' && lang}
                attribute={attribute}
                value={item.value}
              />
            }

            { (!update && input.inputType === 'img-file') &&
              <AttrInputImgFile
                item={input}
                attribute={attribute}
                value={item.value}
              />
            }

            { input.inputType === 'geo' &&
              <AttrInputAddressGeo
                lang={lang}
                item={input}
                attribute={attribute}
                value={item.value}
              />
            }

            {/* input.inputType === 'number' && <div>number</div> */}
          </Fragment>
        )
      }) }
    </Fragment>
  )
}

export const AttrInputAddressGeo = (props) => {
  const { attribute, item, value, lang } = props
  const label = item.label.local
  const id = `attributes.${item._id}`

  return (
    <InputAddressGeo
      id={id}
      lang={lang}
      value={value}
      label={label}
      column="2"
      required={item.inputRequired}
    />
  )
}

const AttrInputImgFile = (props) => {
  const { attribute, item, value } = props
  const { album } = item
  const label = item.label.local
  const prefix = getPrefix(attribute.type)

  let crop

  if (album.crop) {
    crop = {
      width: album.width,
      height: album.height
    }
  }

  return (
    <InputFile
      label={label}
      id={album.code}
      crop={crop}
      value={value}
      placeholder={label}
      prefix={prefix}
      required={item.inputRequired}
    />
  )
}

const AttributeInputString = (props) => {
  const { item, value, lang } = props
  const label = item.label.local

  let id

  if (lang) {
    id = `attributes.${item._id}.${lang}`
  } else {
    id = `attributes.${item._id}`
  }

  return (
    <TextInput
      label={label}
      id={id}
      value={value}
      placeholder={label}
      inputType="text"
      type="text"
      iconValid
      required={item.inputRequired}
    />
  )
}

const AttributeInputTextarea = (props) => {
  const { item, value, lang } = props
  const label = item.label.local

  let id

  if (lang) {
    id = `attributes.${item._id}.${lang}`
  } else {
    id = `attributes.${item._id}`
  }

  return (
    <Textarea
      label={label}
      placeholder={label}
      id={id}
      value={value}
      required={item.inputRequired}
      iconValid
    />
  )
}

const AttributeInputBoolean = (props) => {
  const { attribute, value, item } = props
  const label = item.label.local
  const id = `attributes.${item._id}`

  return (
    <Select
      active
      id={id}
      label={label}
      placeholder={label}
      placeholderDisabled
      value={value}
      required={item.inputRequired}
      options={[
        { value: 'false', name: 'ไม่ (เท็จ)' },
        { value: 'true', name: 'ใช่ (จริง)' },
      ]}
    />
  )
}

const AttributeInputChoice = (props) => {
  const { item, attribute } = props

  //const productAttribute = product.attributes_id

  //const choices = productAttribute && productAttribute.choices.filter(choice => choice.items_id === item._id)

  const choices = attribute.choices && attribute.choices.filter(choice => choice.items_id === item._id)
  const datas = []

  choices.map(choice => {
    choice = attributeChoiceValue(attribute, choice)
    name = choice.labelLang || choice.nameLang

    datas.push({
      label: name,
      value: choice._id,
      profileAvatar: {
        images: choice.images,
        color: choice.avatarColor,
        texts: [name]
      },
      obj: choice
    })
  })

  return (
    <SelectData
      avatar
      showLabel
      id={item._id}
      label={item.label.local}
      placeholder={item.label.local}
      value={attribute.valueId}
      datas={datas}
      required={item.inputRequired}
    />
  )
}





import React, { Component } from 'react'

// Components Global
import ModalTabs from '../../../../../components/Modals/ModalTabs'

// Components Local
import TemplateLists from './components/TemplateLists'

// Redux Actions
import { updateSite } from '../../../../../redux/Sites/SitesActions'


class TemplateManageModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {

  }

  handleSubmit = () => {

  }

  submit = () => {
    const formName = 'site-update-loading'
    const { business, site } = this.props
    const params = {}

    params.design = {
      style: JSON.stringify(this.state.style),
      class: JSON.stringify(this.state.class),
    }

    if (params) {
      this.props.dispatch(updateSite(business._id, site._id, {
        body: params,
        loadId: formName
      }))
    }
  }

  render() {
    /*let { pages } = this.props
    pages = pages.filter(page => page.type === 'template' && page.templateType === 'product-details')*/

    return (
      <ModalTabs
        blockBgModal
        id="pageTemplateManageModal"
        activeId={1}
        title="รูปแบบเทมเพลต"
        width="850px"
        items={[
          { name: 'สินค้า' },
          { name: 'หมวดหมู่สินค้า' },
          { name: 'หน้าปิดการขาย (Sale Page)' },

          { name: 'บทความ' },
          { name: 'หมวดหมู่บทความ' },
          { name: 'แท็กบทความ' },

          { name: 'จัดการสินค้า' }
        ]}>

        <div id="1">
          <TemplateLists {...this.props} title="รูปแบบสินค้า" templateType="product-details" />
        </div>

        <div id="2">
          <TemplateLists {...this.props} title="รูปแบบหมวดหมู่สินค้า" templateType="product-folder-details" />
        </div>

        <div id="3">
          <TemplateLists {...this.props} title="รูปแบบหน้าปิดการขาย (Sale Page)" templateType="product-sale-page-details" />
        </div>

        <div id="4">
          <TemplateLists {...this.props} title="รูปแบบบทความ" templateType="article-details" />
        </div>

        <div id="5">
          <TemplateLists {...this.props} title="รูปแบบหมวดหมู่บทความ" templateType="article-folder-details" />
        </div>

        <div id="6">
          <TemplateLists {...this.props} title="รูปแบบแท็กบทความ" templateType="article-tag-details" />
        </div>

        <div id="7">
          <TemplateLists {...this.props} title="รูปแบบจัดการสินค้า" templateType="product-manage" />
        </div>
      </ModalTabs>
    )
  }
}

export default TemplateManageModal

import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../components/Modals/Modal'
import { Row, Column } from '../../../../../components/Columns'

// Components forms
import FormValidation from '../../../../../components/FormValidation'
import BtnSubmit from '../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../util/getFormValue'

// Components Services
import {
  PlaceName,
  PlaceDescriptions,
  PlaceLocation
} from '../PlaceForms'

// Actions Redux
import { createPlace } from '../../../../../redux/Places/PlacesActions'


class PlaceCreateModal extends Component {

  handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(this.refs[formName])

    if (params) {

      /*const redirect = !this.props.onSelect && {
        url: `/${this.props.business.username}/services`,
      }*/

      this.props.dispatch(createPlace(this.props.business._id, {
        body: params,
        loadId: formName,
        modalId: formName,
        props: this.props,
        //redirect,
        toastMsg: 'เพิ่มสถานที่ท่องเที่ยวแล้ว'
      }))
    }
  }

  render() {
    const id = "placeCreateModal"

    return (
      <Modal
        id={id}
        headerTitle="เพิ่มสถานที่ท่องเที่ยว"
        headerDescription="กรอกรายละเอียดเกี่ยวกับเพิ่มสถานที่ท่องเที่ยว"
        width="600px"
        padding="30px">

        <Row className="pd-top-20">
          <FormValidation
            recheckForm
            ref={id}
            name={id}
            submit={(e) => this.handleSubmit(e, id)}>

            <Column col="column col-xs-12">
              <PlaceName />
              <PlaceDescriptions />
              <PlaceLocation />
            </Column>

            <Column col="column col-xs-12 text-align-right">
              <BtnSubmit
                loadingId={id}
                icon="send"
                iconAlign="left"
                text="บันทึก"
                className="waves-effect waves-light btn btn-submit btn-shadow-none"
                btnDisabled
              />
            </Column>
          </FormValidation>
        </Row>
      </Modal>
    )
  }
}

export default connect()(PlaceCreateModal)

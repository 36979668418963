import React, { useEffect } from 'react'
import { connect } from 'react-redux'

// Components Global
import SelectMultiDatas from '../../../../../components/Inputs/SelectMultiDatas'
import EditData from '../../../../../components/EditData'
import DatasList from '../../../../../components/DatasList'

// Actions
import { fetchTags } from '../../../../../redux/Tags/TagsActions'

// Values
import { tagValue } from '../../../../../redux/Tags/TagsValues'


const SelectTagIds = (props) => {
  const { dispatch, tags, business, type, attributes_id, value, tagsText, required } = props
  const datas = []

  useEffect(() => {
    dispatch(fetchTags('admin', business._id, {
      body: {
        sort: 'sort',
        where: `type=${type},attributes_id=${attributes_id || 'null'}`
      },
      loadId: 'pageLoading'
    }))
  }, [value, tagsText])

  tags.map(tag => {
    tag = tagValue({ tag })

    datas.push({
      label: tag.nameLang,
      value: tag._id,
      useValue: {
        tags_id: tag._id,
        path: tag.path,
        name: tag.name
      },
      profileAvatar: {
        images: tag.images,
        color: tag.avatarColor,
        texts: [tag.nameLang]
      },
      obj: tag
    })
  })

  const selectTags = (
    <SelectMultiDatas
      label="เลือกแท็ก"
      id="tags"
      avatar
      showLabel
      placeholder="เลือกแท็ก"
      required={required}
      elem="tags_id"
      value={value}
      datas={datas}
    />
  )

  if (props.submit) {
    return (
      <EditData recheckForm name="tags" submit={props.submit}>
        <DatasList
          style={{ border: '0' }}
          items={[
            {
              label: 'แท็ก',
              value: tagsText || '-',
              icon: 'local_offer'
            }
          ]}
        />

        <div>
          { selectTags }
        </div>
      </EditData>
    )
  } else {
    return selectTags
  }
}

const mapStateToProps = (state) => {
  return {
    tags: state.tags.data
  }
}

export default connect(mapStateToProps)(SelectTagIds)

import React from 'react'
import renderRoutes from '../../../../routes/renderRoutes'

// Components Global
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../components/MenuSidebarResponsive'
import LangSetting from '../../../../components/LangSetting'


const menuItems = (params, product, productsSettings) => {
  const { username, status, products_id } = params

  return [
    {
      name: 'ข้อมูล',
      title: true
    },
    {
      name: 'รายละเอียด',
      nameShort: 'รายละเอียด',
      icon: 'description',
      link: `/${username}/products/${status}/${products_id}/update`,
      exact: true
    },
    {
      name: 'ตัวเลือกสินค้า',
      nameShort: 'ตัวเลือก',
      icon: 'grid_on',
      link: `/${username}/products/${status}/${products_id}/update/items`,
      exact: true
    },

    {
      name: 'ค่าจัดส่งสินค้า',
      nameShort: 'ค่าจัดส่ง',
      icon: 'local_shipping',
      link: `/${username}/products/${status}/${products_id}/update/shipping-fees`,
      hidden: !productsSettings.shippingFee.use,
      exact: true
    },
    {
      name: 'ข้อมูลคุณลักษณะ',
      nameShort: 'คุณลักษณะ',
      icon: 'ballot',
      link: `/${username}/products/${status}/${products_id}/update/attributes`,
      hidden: !productsSettings.attribute.use,
      exact: true
    },
    {
      name: 'เนื้อหา',
      nameShort: 'เนื้อหา',
      icon: 'create',
      link: `/${username}/products/${status}/${products_id}/update/content`,
      exact: true
    },

    {
      name: 'เซลเพจ',
      title: true
    },
    {
      name: 'ออกแบบเซลเพจ',
      nameShort: 'ออกแบบ',
      icon: 'palette',
      link: `/${params.username}/products/${status}/${products_id}/update/salepage-design`
    }

    /*{
      name: 'หน้าปิดการขาย',
      title: true
    },
    {
      name: 'รายละเอียดหน้าปิดการขาย',
      nameShort: 'รายละเอียด',
      icon: 'description',
      link: `/${params.username}/products/${product.status}/${params.products_id}/update/salepage`
    },
    {
      name: 'ออกแบบหน้าปิดการขาย',
      nameShort: 'ออกแบบ',
      icon: 'palette',
      link: `/${params.username}/products/${product.status}/${params.products_id}/update/salepage-design`
    },*/
  ]
}


const ProductUpdateContainer = (props) => {
  const { route, match, product, attribute, business, location, lang } = props
  const { params } = match
  const { username, products_id } = params
  const { productsSettings } = business


  return (
    <div className="pst-absolute top-0 bottom-0 left-0 right-0">
      <div className="height-50 line-height-50 bg-fff pd-left-15 pd-right-8 border-bottom-1 border-bottom-efefef">
        <table>
          <tbody>
            <tr>
              <td className="pd-0 height-50">แก้ไขสินค้า</td>
              <td className="pd-0 width-50">
                <LangSetting />
              </td>
              <td className="pd-0 width-50 text-right">
                <a onClick={props.closeWindowModal} className="btn red darken-1 btn-shadow-none">
                  <i className="material-icons">close</i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="pst-absolute top-50 bottom-0 left-0 right-0">
        <LayoutsTwo>
          <Layouts1>
            <MenuSidebarResponsive items={menuItems(params, product, productsSettings)} />
          </Layouts1>

          <Layouts2 hideScrollbars>
            { renderRoutes({
              routes: route.routes,
              extraProps: { business, product, attribute, lang },
              location
            }) }
          </Layouts2>
        </LayoutsTwo>
      </div>
    </div>
  )
}

export default ProductUpdateContainer

import React from 'react'

// Components
import SectionTitle from '../SectionTitle'
import ContentGroupPageFullManage from '../ContentGroupPageFullManage'

import { ContentGroupPageDisplay } from '../../../../../ContentGroupPage'

// js
import { getTitle } from './js/contentGroupPage'


const ContentPageGroup = (props) => {
  const {
    match,
    params,
    urlMainManage,
    business,
    setting,
    site,
    pages,
    page,
    contentGroupPage,
    actionSite,
    actionSinglePage,
    singlePageData,
    singlePageType
  } = props

  const { contentGroupPageHeader, contentGroupPageHeaderFixed, contentGroupPageFooter } = params
  const name = contentGroupPage.name.local

  let title = name
  let groupId, titleFull

  if (!contentGroupPageHeader && !contentGroupPageHeaderFixed && !contentGroupPageFooter) {
    titleFull = name
    title = "ส่วนเนื้อหา (Content)"
    groupId = contentGroupPage._id
  }

  if (contentGroupPageHeader) {
    titleFull = `ส่วนหัวของหน้า ${name} (Header)`
    title = `ส่วนหัว (Header)`
    groupId = contentGroupPage._id + '-header'
  }

  if (contentGroupPageHeaderFixed) {
    titleFull = `ส่วนหัวแบบคงที่ของหน้า ${name} (Header Fixed)`
    title = `ส่วนหัวแบบคงที่ (Header Fixed)`
    groupId = contentGroupPage._id + '-header-fixed'
  }

  if (contentGroupPageFooter) {
    titleFull = `ส่วนท้ายของหน้า ${name} (Footer)`
    title = `ส่วนท้าย (Footer)`
    groupId = contentGroupPage._id + '-footer'
  }

  return (
    <div>
      <div className="text-center font-2-0 pd-top-50">
        <div>หน้าเพจ</div>
        <div className="font-1-5">ชื่อ : {titleFull}</div>
      </div>

      { contentGroupPage.headerShow && !contentGroupPageHeader && !contentGroupPageHeaderFixed && !contentGroupPageFooter &&
        <SectionTitle
          title="ส่วนหัว (Header)"
          mode="view"
          style={{ marginTop: '20px' }}
          editLink={{
            url: `${urlMainManage}/content-group-page/${groupId}/header`,
            label: 'จัดการส่วนหัว',
            icon: 'settings'
          }}>

          <ContentGroupPageDisplay
            {...props}
            mode="view"
            groupId={`${groupId}-header`}
            datas={{
              business,
              setting,
              site,
              pages,
              pageCurrent: page,
              singlePageData,
              singlePageType
            }}
          />
        </SectionTitle>
      }

      { contentGroupPage.headerShow && contentGroupPage.headerFixedShow && !contentGroupPageHeader && !contentGroupPageHeaderFixed && !contentGroupPageFooter &&
        <SectionTitle
          title="ส่วนหัวแบบคงที่ (Header Fixed)"
          mode="view"
          style={{ marginTop: '20px' }}
          editLink={{
            url: `${urlMainManage}/content-group-page/${groupId}/header-fixed`,
            label: 'จัดการส่วนหัวแบบคงที่',
            icon: 'settings'
          }}>

          <ContentGroupPageDisplay
            {...props}
            mode="view"
            groupId={`${groupId}-header-fixed`}
            datas={{
              business,
              setting,
              site,
              pages,
              pageCurrent: page,
              singlePageData,
              singlePageType
            }}
          />
        </SectionTitle>
      }

      {
        <ContentGroupPageFullManage
          {...props}
          title={title}
          groupId={groupId}
          pageCurrent={page}
          singlePageData={singlePageData}
          singlePageType={singlePageType}
          actionSite={actionSite}
          actionSinglePage={actionSinglePage}
        />
      }


      { contentGroupPage.footerShow && !contentGroupPageHeader && !contentGroupPageHeaderFixed && !contentGroupPageFooter &&
        <SectionTitle
          title="ส่วนท้าย (Footer)"
          mode="view"
          style={{ marginTop: '20px' }}
          editLink={{
            url: `${urlMainManage}/content-group-page/${groupId}/footer`,
            label: 'จัดการส่วนท้าย',
            icon: 'settings'
          }}>

          <ContentGroupPageDisplay
            {...props}
            mode="view"
            groupId={`${groupId}-footer`}
            datas={{
              business,
              setting,
              site,
              pages,
              pageCurrent: page,
              singlePageData,
              singlePageType
            }}
          />
        </SectionTitle>
      }
    </div>
  )
}

export default ContentPageGroup

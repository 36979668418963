import React, { Component } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../routes/renderRoutes'

// Actions Redux
import { fetchCustomer } from '../../../../redux/Customers/CustomersActions'

// Values
import { customerValue } from '../../../../redux/Customers/CustomersValues'


class CustomerContainer extends Component {
  componentDidMount() {
    const { business, dispatch, match } = this.props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businessType: business.type,
      businesses_id: business._id,
      customers_id: match.params.customers_id
    }

    dispatch(fetchCustomer(query))
  }

  render() {
    const { route, customer, business, location } = this.props

    return (
      <div>
        { customer ?
          <div>
            { renderRoutes({
              routes: route.routes,
              extraProps: {
                business,
                customer: customerValue({ customer })
              },
              location
            }) }
          </div>
        :
          <h1>404 Not Found</h1>
        }
      </div>
    )
  }
}


const mapStateToProps = (store, props) => {
  return {
    customer: store.customers.data.filter((customer => customer._id === props.match.params.customers_id))[0]
  }
}

export default connect(mapStateToProps)(CustomerContainer)

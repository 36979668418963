import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../routes/renderRoutes'

// Actions Redux
import { fetchVoucher } from '../../../../redux/Vouchers/VouchersActions'

// Values
import { voucherValue } from '../../../../redux/Vouchers/VouchersValues'


class VoucherContainer extends Component {
  componentDidMount() {
    const { business, match } = this.props

    this.props.dispatch(fetchVoucher(business._id, match.params.vouchers_id))
  }

  render() {
    const { route, voucher, business, location } = this.props

    return (
      <Fragment>
        { voucher ?
          <Fragment>
            { renderRoutes({
              routes: route.routes,
              extraProps: { business, voucher: voucherValue({ voucher }) },
              location
            }) }
          </Fragment>
        :
          <h1>404 Not Found</h1>
        }
      </Fragment>
    )
  }
}


const mapStateToProps = (store, props) => {
  return {
    voucher: store.vouchers.data.filter((voucher => voucher._id === props.match.params.vouchers_id))[0]
  }
}

export default connect(mapStateToProps)(VoucherContainer)

// Containers
import RubberSalesContainer from '../../../../../../containers/dashboard/SystemRubber/RubberSales/containers/RubberSalesContainer'

// Routes
import RubberSalesMenuRoutes from './routes/RubberSalesMenuRoutes'



const RubberSalesRoutes = [
  {
    path: '/:username/rubber-sales',
    component: RubberSalesContainer,
    routes: [
      ...RubberSalesMenuRoutes,
    ]
  }
]

export default RubberSalesRoutes

import React from 'react'

// Components Global
import TextInput from '../../../../../../components/Inputs/TextInput'
import InputPhoneNumber from '../../../../../../components/Inputs/InputPhoneNumber'
import InputCheckDuplicate from '../../../../../../components/Inputs/InputCheckDuplicate'

// Actions
import { checkCustomerDuplicate } from '../../../../../../redux/Customers/CustomersActions'


export const CustomerName = (props) => {
  const { business, customer, onChange } = props
  const lang = props.lang || 'local'
  const label = props.label || 'ชื่อโรงาน/ผู้ซื้อ'
  const query =`businesses_id=${business._id}&type=buyer`

  let unselectId

  if (customer && customer._id) {
    unselectId = customer._id
  }

  let id = `name.${lang}`
  let value = props.value ? props.value[lang] : ''

  if (props.event === 'create') {
    value = undefined
  }

  return (
    <InputCheckDuplicate
      id={id}
      element="name.local"
      label={label}
      type="text"
      value={value}
      params_business_id={business._id}
      action={checkCustomerDuplicate}
      useQuery={{
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id
      }}
      query={query}
      unselectId={unselectId}
      onChange={onChange}
      required
    />
  )
}


export const CustomerMobile = (props) => {
  const id = `mobile`

  return (
    <InputPhoneNumber
     label="มือถือ"
     id={id}
     hiddenCode
     value={props.value}
     iconValid
    />
  )
}


export const CustomerAddress = (props) => {
  const lang = props.lang || 'local'
  const value = props.value ? props.value[lang] : ''
  const id = `address.${lang}`

  return (
    <TextInput
      label="ที่อยู่"
      placeholder="ที่อยู่"
      id={id}
      name={id}
      value={value}
      required={props.required}
      iconValid
    />
  )
}

export const CustomerTaxId = (props) => {
  const id = `taxId`

  return (
    <TextInput
      label="เลขประจำตัวผู้เสียภาษี"
      placeholder="เลขประจำตัวผู้เสียภาษี"
      id={id}
      name={id}
      value={props.value}
      iconValid
    />
  )
}

export const CustomerNote = (props) => {
  const lang = props.lang || 'local'
  const value = props.value ? props.value[lang] : ''
  const id = `note.${lang}`

  return (
    <TextInput
      label="หมายเหตุ"
      placeholder="หมายเหตุ"
      id={id}
      name={id}
      value={value}
      required={props.required}
      iconValid
    />
  )
}

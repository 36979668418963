import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../routes/renderRoutes'

// Components Global
import Window from '../../../../components/WindowsV2/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../components/MenuSidebarResponsive'

// Functions
import {
  createOrderForSellOnline,
  sellOnlineCountOrders,
  sellOnlineOrdersGroupMenu
} from '../../../../redux/Orders/OrdersFunctions'

// imgs
import titleIcon from '../../Businesses/resources/icons/sell-online.png'


const menuItems = (params, count) => {
  return [
    {
      name: 'รายการขาย',
      title: true
    },
    ...sellOnlineOrdersGroupMenu({ pathStart:  `/${params.username}/sell-online`, count }),

    {
      name: 'รายงาน',
      title: true
    },
    {
      name: 'การขายสินค้า',
      nameShort: 'การขาย',
      icon: 'picture_as_pdf',
      link: `/${params.username}/sell-online/reports`
    }

    /*{
      name: 'อื่นๆ',
      title: true
    },
    {
      name: 'ส่งออกรายการขาย Excel',
      nameShort: 'ส่งออกไฟล์ Excel',
      icon: 'import_export',
      link: `/${params.username}/sell-online/export`
    },*/
  ]
}


const SellOnlineMenuContainer = (props) => {
  const [count, setCount] = useState()

  useEffect(() => {
    countOrder()
  }, [JSON.stringify(props.location)])

  const countOrder = () => {
    const { business } = props
    sellOnlineCountOrders({ props, business, setCount })
  }

  const { route, match, business, setting, location, windowModal, closeWindowModal, history } = props

  return (
    <Window
      title="ขายออนไลน์"
      titleIcon={titleIcon}
      linkClose={`/${match.params.username}`}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkHelp="https://www.youtube.com/playlist?list=PLHuj62tDAxzNdPnfzc418tAo3phAP62if">

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive
            btnAddSubmit={{
              onClick: () => createOrderForSellOnline(props),
              loadingId: 'createOrderDraft',
              text: 'สร้างบิลใหม่'
            }}
            items={menuItems(match.params, count)}
          />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, setting },
            location,
          }) }
        </Layouts2>
      </LayoutsTwo>
    </Window>
  )

}

export default connect()(SellOnlineMenuContainer)

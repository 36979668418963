import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import renderRoutes from 'client/routes/renderRoutes'

// Components Global
import LayoutSidebarManage from 'components/LayoutSidebarManage'

// Component Local
import ConversationChannelsListItems from './components/ConversationChannelsListItems'

// Actions Redux
import { fetchQueryConversationChannelOne, fetchReplaceQueryConversationChannelOne } from 'core/redux/ConversationChannels/ConversationChannelsActions'
import { conversationChannelValue } from 'core/redux/ConversationChannels/ConversationChannelsValues'


const ConversationChannelsListContainer = (props) => {
  const layoutSidebar = useRef()
  const { match } = props
  const { conversation_channels_id } = match.params
  const { business, location, route, conversationChannel, dispatch } = props
  const openSidebar = () => layoutSidebar.current.open()

  useEffect(() => {
    if (!conversationChannel) {
      layoutSidebar.current.close()
    } else {
      layoutSidebar.current.open()
    }
  }, [conversationChannel && JSON.stringify(conversationChannel)])


  useEffect(() => {
    if (!conversationChannel && conversation_channels_id) {
      if (conversation_channels_id !== 'all') {
        setTimeout(() => {
          const query = {
            authName: 'admin',
            businesses_id: business._id,
            conversation_channels_id
          }

          dispatch(fetchQueryConversationChannelOne(query))
        }, 500)
      }
    }
  }, [])

  const refreshConversationChannel = () => {
    const query = {
      authName: 'admin',
      businesses_id: business._id,
      conversation_channels_id
    }

    dispatch(fetchReplaceQueryConversationChannelOne(query, {
      loadId: 'fetchConversationChannelLoading'
    }))
  }

  return (
    <LayoutSidebarManage
      {...props}
      urlMain={`/${business.username}/conversations/settings/channels/all`}
      ref={layoutSidebar}
      dataId={conversation_channels_id}>

      <div>
        <ConversationChannelsListItems {...props} openSidebar={openSidebar} />
      </div>

      <div>
        { renderRoutes({
          routes: route.routes,
          extraProps: { ...props, refreshConversationChannel },
          location
        }) }
      </div>
    </LayoutSidebarManage>
  )
}

const mapStateToProps = (state, props) => {
  const { match } = props
  const { conversation_channels_id, status } = match.params
  const { conversationChannels } = state
  const conversationChannel = conversationChannels.data.filter(channel => channel._id === conversation_channels_id)[0]

  return {
    conversationChannel: conversationChannelValue({ conversationChannel })
  }
}

export default connect(mapStateToProps)(ConversationChannelsListContainer)

import React from 'react'

// Components Global
import ProfileAvatar from '../../../../../../components/ProfileAvatar'

// Values
import { attributeValue } from '../../../../../../redux/Attributes/AttributesValues'


const AttributeProfileAvater = (props) => {
  let { attribute, fontSize } = props

  if (!attribute.avatarName) {
    attribute = attributeValue({ attribute })
  }

  return (
    <ProfileAvatar
      circle={props.circle}
      images={attribute.images}
      imgStyle={{ width: '100%', height: '100%', }}
      imageSize="o"
      color={attribute.avatarColor}
      texts={attribute.avatarName}
      textSize={fontSize || '1.1rem'}
    />
  )
}

export default AttributeProfileAvater

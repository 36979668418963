import React from 'react'
import { connect } from 'react-redux'
import Scrollbar from 'react-perfect-scrollbar'

// Components Global
import FetchDataListItems from 'components/FetchDataListItems'

// Component Local
import { ConversationChannelItem } from './ConversationChannelItem'

// Actions Redux
import { fetchQueryConversationChannelsMany } from 'core/redux/ConversationChannels/ConversationChannelsActions'
import ConversationChannelCreateModal from 'core/modules/ConversationChannels/components/ConversationChannelCreateModal'
import { openModal } from 'core/redux/App/AppActions'


const ConversationChannelsListItems = (props) => {
  const { business, openSidebar, match } = props
  const icon = "archive"

  const { status } = match.params
  let matchValue = {}, title = "ช่องทางการเชื่อมต่อ"


  const fetchAction = {
    action: fetchQueryConversationChannelsMany,
    query: {
      authName: 'admin',
      businesses_id: business._id
    },
    body: {
      match: matchValue,
      sort: { 'no': -1, '_id': -1 },
      pageNumber: 1,
      limit: 25,
    },
    id: business._id,
  }

  const modalId = 'conversationChannelsCreateModal'

  return (
    <div className="pst-absolute pst-0">
      <ConversationChannelCreateModal {...props} business={business} id={modalId} />

      <Scrollbar>
        <div className="pd-15">
          <div className="flx-row border-bottom-1 border-bottom-ddd pd-bottom-15 items-center">
            <div className="width-full">
              <i className="material-icons left font-1-6">integration_instructions</i>
              <span className="font-1-3">ช่องทางการเชื่อมต่อ</span>
            </div>
            <div className="width-180 flx-column flex-wrap jtf-end">
              <a onClick={() => props.dispatch(openModal(modalId))} className="btn btn-shadow-none height-40 line-height-40">
                <i className="material-icons left">add</i>เพิ่มช่องทาง
              </a>
            </div>
          </div>
        </div>

        <div className="pst-relative">
          <FetchDataListItems
            {...props}
            id="conversationChannelsList"
            storeName="conversationChannels"
            tableClassName=""
            title={title}
            icon={icon}
            fetchAction={fetchAction}
            componentList={ConversationChannelItem}
            hiddenTitle
            hiddenSearchCamera
            notReloadForUrlChange
            openSidebar={openSidebar}
            columnTitle={{
              column1: 'column col-xxs-12',
              column2: 'column col-xxs-12'
            }}
          />
        </div>
      </Scrollbar>
    </div>
  )
}

export default connect()(ConversationChannelsListItems)

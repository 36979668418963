import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

// Components Global
import PreLoading from '../../../components/PreLoading'

// Components Local
import Header from './components/Header/Header'

// Utils
import IndexedDB from '../../../IndexedDB'

// files
import logoReloading from './resources/imgs/logo-reloading.svg'


const AppDashboardContainer = (props) => {
  const [preloading, setPreloading] = useState(true)
  const { auth, children } = props

  useEffect(() => {
    setTimeout(() => {
      setPreloading(false)
    }, 800)

    IndexedDB.init()
  }, [])


  return (
    <div>
      <PreLoading id="getAuthLoading">
        { auth.user !== 'unauthorized' ?
          <div className="app-dashboard-container">
            <Header auth={auth} />

            <div className="app-dashboard-content top-60 pd-20 mg-top--2" style={{ background: '#1a1d1f' }}>
              { children }
            </div>
          </div>
        :
          <div>
            { children }
          </div>
        }
      </PreLoading>

      <div className={`preloading ${preloading && 'showPreloading'}`} style={{ background: '#1a1d1f' }}>
        <div>
          <img className="logo-loading" width="100" src={logoReloading} />
          <div style={{ color: '#fff' }}>กำลังโหลดข้อมูล . . .</div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    app: state.app,
    auth: state.auth
  }
}

export default connect(mapStateToProps)(AppDashboardContainer)

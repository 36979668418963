import React, { Component } from 'react'
import { connect } from 'react-redux'

// actions redux
import { removeLoad } from '../../redux/App/AppActions'


class BtnLoading extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    if (this.props.loadingId) {
      this.props.dispatch(removeLoad(this.props.loadingId))
    }
  }

  render() {
    let iconAlign = (this.props.iconAlign ? this.props.iconAlign : 'left')

    let boxLoading, iconLoading

    if (this.props.loadingStyle) {
      boxLoading = this.props.loadingStyle
      iconLoading = { ...boxLoading, borderWidth: '2px' }
    } else {
      boxLoading = { width: '20px', height: '20px' }
      iconLoading = { ...boxLoading, borderWidth: '2px' }
    }

    if (this.props.btnDisabled) {
      return (
        <div style={{ display: 'inline' }}>
          <button disabled className={this.props.className} >
            { this.props.icon ?
              <i className={`material-icons ${iconAlign}`}>{this.props.icon}</i>
            :
              <i className={`${this.props.iconFa} ${iconAlign}`}></i>
            }

            {this.props.text}
          </button>
        </div>
      )
    } else {
      return(
        <div style={{ display: 'inline' }}>
          { (this.props.loadingId && this.props.load || this.props.loadTest)  ?
            <div className={this.props.className} style={{boxShadow: 'none', cursor: 'progress', opacity: '0.7'}}>
              <div style={{ width: '30px', float: 'left', paddingTop: '8px'}}>
                <div className="la-ball-clip-rotate la-sm" style={boxLoading}>
                  <div style={iconLoading}></div>
                </div>
              </div> {this.props.text}...
            </div>
          :
            <a onClick={this.props.onClick} data-disabled={this.props.btnDisabled} type={this.props.type} className={this.props.className} >
              { this.props.icon ?
                <i className={`material-icons ${iconAlign}`}>{this.props.icon}</i>
              :
                <i className={`${this.props.iconFa} ${iconAlign}`}></i>
              }

              {this.props.text}
            </a>
          }
        </div>
      )
    }
  }
}

const mapStateToProps = (store, props) => {
  return {
    load: store.app.loads.filter(load => load === props.loadingId)[0]
  }
}

export default connect(mapStateToProps)(BtnLoading)

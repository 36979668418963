import React, { Component, Fragment } from 'react'

// Components Global
import PreLoading from '../../../../../components/PreLoading'
import EditFieldDefault from '../../../../../components/EditFieldDefault'
import RadioGroup from '../../../../../components/Inputs/RadioGroup'
import BtnSubmitJsx from '../../../../../components/button/BtnSubmitJsx'

// Components Customers
import CustomerAddressCreateModal from '../CustomerAddressCreateModal'
import CustomerAddressUpdateModal from '../CustomerAddressUpdateModal'

// Actions
import { openModal, openField, closeField } from '../../../../../redux/App/AppActions'

// Actions Redux
import { fetchCustomer } from '../../../../../redux/Customers/CustomersActions'
import { updateOrder } from '../../../../../redux/Orders/OrdersActions'

// Values
import { customerAddressValue } from '../../../../../redux/Customers/CustomersValues'

// Functions
import { deleteAddress } from '../../../../../redux/Customers/CustomersFunctions'



class CustomerAddressSelected extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { order, business, dispatch } = this.props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businessType: business.type,
      businesses_id: business._id,
      customers_id: order.customers_id
    }

    dispatch(fetchCustomer(query, {
      loadId: 'fetchCustomerLoading',
    }))
  }

  openField = (id) => {
    this.props.dispatch(openField(id))
  }

  closeField = (id) => {
    this.props.dispatch(closeField(id))
  }

  handleSubmit = (id) => {
    const { customer } = this.props
    const { addressId } = this.state
    const address = customer.addresses.filter(address => address._id === addressId)[0]

    if (address) {
      this.submitSelectAddress({
        address,
        formName: id
      })
    } else {
      alert('กรุณาเลือกที่อยู่ !!!')
    }
  }

  submitSelectAddress = ({ address, formName }) => {
    const { business, order } = this.props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id,
      orders_id: order._id
    }

    if (address) {
      this.props.dispatch(updateOrder(query, {
        body: {
          shipping: true,
          shippingAddress: address
        },
        loadId: formName,
        fieldId: formName,
        modalId: 'customer-address-create-modal',
        toastMsg: 'เลือกที่อยู่จัดส่งแล้ว'
      }))
    }
  }

  selectAddress = (addressId) => {
    this.setState({ addressId })
  }

  render() {
    const id = 'address-shipping'
    const lang = this.props.lang || 'local'
    const { order, customer, disabled } = this.props
    const radios = []
    let addresses

    if (customer) {
      addresses = customer.addresses

      addresses.map(address => {
        address = customerAddressValue({ customer, address, lang })

        if (address) {
          radios.push({
            value: address._id,
            label:  <div className="pd-bottom-10 dp-inline-block width-full" style={{ width: '100%', borderBottom: '1px solid #efefef' }}>

                      <CustomerAddressUpdateModal
                        customer={customer}
                        address={address}
                        id={address._id}
                      />

                      <table>
                        <tbody>
                          <tr>
                            <td className="pd-0">
                              <b>{address.nameLang}<br />{address.telFull}</b><br />{address.addressShort}
                            </td>
                            <td width="100" className="pd-0 text-right">
                              <a onClick={() => this.props.dispatch(openModal(address._id))} className="btn btn-mini btn-white btn-shadow-none">
                                แก้ไข
                              </a>

                              { address._id !== order.shippingAddress ?
                                <a onClick={() => deleteAddress(this.props, address._id)} className="btn btn-mini btn-white btn-shadow-none mg-left-10">
                                  ลบ
                                </a>
                              :
                                <a disabled className="btn btn-mini btn-white btn-shadow-none mg-left-10">
                                  ลบ
                                </a>
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
          })
        }
      })
    }

    return (
      <PreLoading
        id="fetchCustomerLoading"
        size="30px"
        border="2px"
        textSize="1rem"
        style={{ position: 'relative' }}>

        <CustomerAddressCreateModal
          id="customer-address-create-modal"
          formName={id}
          customer={customer}
          selectAddress={this.submitSelectAddress}
        />

        <EditFieldDefault name="address-shipping" submit={this.handleSubmit}>
          <div>
            <div className="pd-5 bg-fafafa border-bottom-efefef border-bottom-1">
              <table>
                <tbody>
                  <tr>
                    <td className="pd-0">ที่อยู่จัดส่ง</td>
                    <td width="50" className="pd-0 text-right">
                      { order.shippingAddress && !disabled &&
                        <a onClick={() => this.openField(id)} className="btn-floating btn-sm btn-edit btn-shadow-none waves-effect waves-light">
                          <i className="material-icons i-middle">edit</i>
                        </a>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="pd-10">
              { order.shippingAddress ?
                <div className="font-15 pd-5">
                  <b>{order.address.nameLang} {order.address.telFull}</b><br />{order.address.addressShort}
                </div>
              :
                <div className="center">
                  { addresses && addresses.length !== 0 ?
                    <a
                      onClick={() => this.openField(id)}
                      className="btn btn-white btn-shadow-none mg-top-10">

                      เลือกที่อยู่จัดส่ง
                    </a>
                  :
                    <a
                      onClick={() => this.props.dispatch(openModal('customer-address-create-modal'))}
                      className="btn btn-white btn-shadow-none mg-top-10">

                      เพิ่มที่อยู่จัดส่ง
                    </a>
                  }
                </div>
              }
            </div>
          </div>

          <div>
            <div className="pd-5 bg-fafafa border-bottom-efefef border-bottom-1">
              <table>
                <tbody>
                  <tr>
                    <td className="pd-5">ที่อยู่จัดส่ง</td>
                    <td className="pd-5" width="100" style={{ textAlign: 'right' }}>
                      { addresses && addresses.length !== 0 &&
                        <a style={{ cursor: 'pointer' }} onClick={() => this.props.dispatch(openModal('customer-address-create-modal'))}>เพิ่มที่อยู่ใหม่</a>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="pd-10">
              <div>
                { addresses && addresses.length !== 0 ?
                  <RadioGroup
                    id="customers_addresses_id"
                    notSave
                    value={order.shippingAddress && order.shippingAddress._id}
                    radios={radios}
                    getValue={this.selectAddress}
                    styleLabel={{ width: '100%' }}
                  />
                :
                  <div className="center pd-top-10 pd-bottom-10">
                    <a
                      onClick={() => this.props.dispatch(openModal('customer-address-create-modal'))}
                      className="btn btn-shadow-none mg-top-10">

                      เพิ่มที่อยู่จัดส่ง
                    </a>
                  </div>
                }
              </div>


              <div className="pd-top-10 text-right">
                <a
                  onClick={() => this.closeField(id)}
                  className="btn btn-white btn-shadow-none">
                    ยกเลิก
                </a>
                { addresses && addresses.length !== 0 &&
                  <BtnSubmitJsx
                    loadingId={id}
                    onClick={() => this.handleSubmit(id)}
                    icon="send"
                    iconAlign="left"
                    text="บันทึก"
                    className="btn btn-submit btn-shadow-none mg-left-10"
                    btnDisabled
                  />
                }
              </div>

            </div>
          </div>

        </EditFieldDefault>
      </PreLoading>
    )
  }
}

export default CustomerAddressSelected

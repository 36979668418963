import React, { Component } from 'react'
import { connect } from 'react-redux'
import config from '../../../config'

// css
import css from './css/inputPhoneNumber.css'

// Components Global
import InputSelect from '../InputSelect'
import Required from '../../Inputs/Required'

// Redux Actions
import { fetchCountries } from '../../../redux/Geo/GeoActions'

const CDN_URL = config.app.cdnUrl


class InputPhoneNumber extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, ''),
      code: '',
      number: ''
    }
  }

  componentDidMount() {
    this.setState({
      code: this.props.value ? this.props.value.code : '+66',
      number: this.props.value ? this.props.value.number : ''
    })

    if (this.props.countries.length === 0) {
      this.props.dispatch(fetchCountries())
    }
  }

  onChangePhoneNumber = (e) => {
    let value = e.target.value.replace(/\(/g, '').replace(/\)/g, '').replace(/\-/g, '')
    let pattern = /^[0-9]+$/

    if (value === "") {
      this.setState({ number: "" })

    } else if (pattern.test(value)) {
      this.setState({ number: value })
    }
  }

  render() {
    const datas = []
    const countries = this.props.countries.filter(country => country.dialingCode)

    countries.map(country => {
      datas.push({
        img: `${CDN_URL}/common/imgs/icons/flags/${country.countryCode}.png`,
        label: country.countryNameTh,
        value: country.dialingCode
      })
    })

    return (
      <div className={css.inputPhoneNumber}>
        <Required
          id={this.state.id}
          label={this.props.label}
          type="phoneNumber"
          value={this.state.number}
          required={this.props.required}
          iconValid={this.props.iconValid}>

          <table>
            <tbody>
              <tr>
                <td className={css.code} >
                  <InputSelect
                    avatar
                    id={`${this.props.id}.code`}
                    placeholder="รหัสโทรศัพท์"
                    value={this.state.code}
                    datas={datas} />
                </td>
                <td className={css.number}>
                  <input
                    id={this.state.id}
                    data-id={`${this.props.id}.number`}
                    type="text"
                    placeholder="เบอร์โทร"
                    className={this.props.required ? 'required' : ''}
                    onChange={this.onChangePhoneNumber}
                    value={this.state.number}
                    style={{ marginBottom: '0' }} />
                </td>
              </tr>
            </tbody>
          </table>
        </Required>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    countries: state.geo && state.geo.countries || []
  }
}

export default connect(mapStateToProps)(InputPhoneNumber)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import cuid from 'cuid'

// css
import css from './css/selectProvince.css'

// Components Global
import InputSelectData from '../InputSelectData'
import Required from '../Required'
import InputDisabled from '../InputDisabled'

// Redux Actions
import { fetchProvinces } from '../../../redux/Geo/GeoActions'


class SelectProvince extends Component {
  constructor(props) {
    super(props)

    const id = this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()

    this.state = {
      id: randomId+id,
      value: '',
      nameEn: '',
      nameTh: '',
      regionNameEn: '',
      regionNameTh: ''
    }
  }

  componentDidMount() {
    this.props.dispatch(fetchProvinces())

    if (this.props.value) {
      this.setValue(this.props.value)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setValue(this.props.value)
    }
  }

  setValue = (value) => {
    const provinceSelected = this.props.provinces.filter(province => province.id === value)[0]

    this.setState({
      value,
      nameEn: provinceSelected ? provinceSelected.nameEn : '',
      nameTh: provinceSelected ? provinceSelected.nameTh : '',
      regionNameEn: provinceSelected ? provinceSelected.regionNameEn : '',
      regionNameTh: provinceSelected ? provinceSelected.regionNameTh : ''
    })

    if (this.props.getValue) {
      this.props.getValue(value)
    }
  }

  getValue = (value) => {
    this.setValue(value)
  }

  render() {
    const datas = []
    const provinces = this.props.provinces

    provinces.map(province => {
      datas.push({
        label: province.nameTh,
        value: province.id,
        obj: province
      })
    })

    return (
      <div className={css.inputPhoneNumber}>
        { datas.length !== 0 ?
          <Required
            id={this.state.id}
            classId={this.state.id}
            label={this.props.label}
            type="text"
            value={this.state.value}
            required={this.props.required}
            iconValid={this.props.iconValid}>

            <div>
              <InputSelectData
                avatar
                showLabel
                id={`${this.props.id}.id`}
                classId={this.state.id}
                placeholder={this.props.placeholder}
                value={this.state.value}
                datas={datas}
                getValue={this.getValue}
                required={this.props.required}
                search={[
                  { name: 'nameEn' },
                  { name: 'nameTh' },
                ]}
              />

              <input type="hidden" data-id={`${this.props.id}.nameEn`} value={this.state.nameEn} />
              <input type="hidden" data-id={`${this.props.id}.nameTh`} value={this.state.nameTh} />
              <input type="hidden" data-id={`${this.props.id}.regionNameEn`} value={this.state.regionNameEn} />
              <input type="hidden" data-id={`${this.props.id}.regionNameTh`} value={this.state.regionNameTh} />
            </div>
          </Required>
        :
          <InputDisabled {...this.props} value="ไม่มีตัวเลือก" />
        }
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const geo = state.geo.provinces.filter(province => province.countryCode === props.countryCode)[0]

  return {
    provinces: geo ? geo.provinces : []
  }
}

export default connect(mapStateToProps)(SelectProvince)

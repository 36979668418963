import React, { Fragment, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { getBillCalculateVales } from '../../../../../redux/Bills/BillsCommonValues'
import { updateOrder } from '../../../../../redux/OrdersDrafts/OrdersDraftsActions'

// util
import { getNumeral } from '../../../../../util/getNumeral'

// Components POS
import KeyboardManage from '../../components/KeyboardManage'


const PaymentPage = (props) => {
  const keyboard = useRef()
  const { business, order } = props
  const [keyboardValues, setKeyboardValues] = useState([])
  const [amountReceived, setAmountReceived] = useState()
  const [paymentMethod, setPaymentMethod] = useState('cash')
  const [useDiscount, setUseDiscount] = useState(false)

  const [disType, setDisType] = useState()
  const [disPercent, setDisPercent] = useState()
  const [disAmount, setDisAmount] = useState()
  const [discount, setDiscount] = useState()

  const { event_name } = props.params

  useEffect(() => {
    const { discount } = order

    if (discount) {
      setTimeout(() => {
        keyboard.current.setInputValue('discount-percent', discount.percent || '')
        keyboard.current.setInputValue('discount-amount', discount.amount || '')
      }, 0)
    }
  }, [])


  useEffect(() => {
    if (keyboardValues) {
      if (keyboard && keyboard.current) {
        const amountReceived = keyboard.current.getValues('amount-received')
        const disPercent = keyboard.current.getValues('discount-percent')
        const disAmount = keyboard.current.getValues('discount-amount')
        const focus = keyboardValues.filter(input => input.focus === true)[0]

        if (focus) {
          if (focus.name === 'discount-percent') setDisType('percent')
          else if (focus.name === 'discount-amount') setDisType('amount')
        }

        setAmountReceived(amountReceived)
        setDisPercent(disPercent)
        setDisAmount(disAmount)
      }
    }
  }, [JSON.stringify(keyboardValues)])


  useEffect(() => {
    if (order && disPercent !== undefined && disAmount !== undefined && disType !== undefined) {
      const percent = disPercent.value

      if (disType === 'percent') {
        if (percent !== '') {
          keyboard.current.setInputValue('discount-amount', order.totalAmountProduct * percent / 100)
        } else if (percent === '') {
          keyboard.current.setInputValue('discount-amount', '')
        }
      } else if (disType === 'amount') {
        keyboard.current.setInputValue('discount-percent', '')
      }
    }
  }, [JSON.stringify(disPercent), JSON.stringify(disAmount)])


  useEffect(() => {
    if (disPercent !== undefined && disAmount !== undefined) {
      const percent = disPercent.value
      const amount = disAmount.value

      if (percent && amount) {
        setDiscount({ type: 'percent', percent, amount })
      } else if (!percent && amount) {
        setDiscount({ type: 'amount', percent: 0, amount })
      } else {
        setDiscount({ type: 'percent', percent: 0, amount: 0 })
      }
    }
  }, [JSON.stringify(disPercent), JSON.stringify(disAmount)])


  useEffect(() => {
    if (discount !== undefined) {
      const {
        totalNetAmountProduct,
        vat,
        totalNetAmount,
        paymentNetAmount
      } = getBillCalculateVales({ data: order, discount })

      update({
        discount,
        totalNetAmountProduct,
        vat,
        totalNetAmount,
        paymentNetAmount
      })
    }
  }, [
    discount && discount.percent,
    discount && discount.amount,
  ])


  const update = (params={}, callback) => {
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id,
      orders_id: order._id
    }

    props.dispatch(updateOrder(query, {
      body: params,
      loadId: 'formName',
      useDB: 'client'
    })).then(res => {
      if (res.success === true) {
        if (callback) {
          callback(res.data)
        }
      }
    })
  }

  const handleKeyboardChange = (keyboardValues) => {
    setKeyboardValues(keyboardValues)
  }

  const goBack = () => {
    props.history.push(`/${business.username}/pos/sales/${order._id}`)
  }

  const handleSelectPayment = (paymentMethod) => {
    setPaymentMethod(paymentMethod)
  }

  const handlePaidFull = () => {
    keyboard.current.setInputValue('amount-received', order.totalNetAmount)
  }

  const handlePayment = () => {
    if (amountReceived && amountReceived.value !== undefined) {
      const installments = [{
        no: 1,
        multiple: false,
        price: order.totalNetAmount,
        payment: {
          type: 'cash', // 'cash', 'check', 'bankTransfer', 'creditCard', 'cashOnDelivery', 'inStorePayment'
          status: 'paid',
          paidAmount: amountReceived.value,
          paidDate: moment().toISOString()
        }
      }]

      update({ installments }, gotoPageSuccessed)
    }
  }

  const gotoPageSuccessed = (order) => {
    props.history.push(`/${business.username}/pos/sales/${order._id}/payment/successed`)
  }

  let change

  if (order && amountReceived && amountReceived.value) {
    change = amountReceived.value - order.totalNetAmount
  }

  const paymentMethods = [
    { id: 'cash', name: 'เงินสด' },
    { id: 'bank', name: 'ธนาคาร' },
    { id: 'online', name: 'ออนไลน์' },
    { id: 'card', name: 'ใช้บัตร' },
    { id: 'credit', name: 'ค้างชำระ' },
    { id: 'other', name: 'อื่นๆ' }
  ]

  const amountFocus = keyboard && keyboard.current && keyboard.current.focus('amount-received')
  const disPercentFocus = keyboard && keyboard.current && keyboard.current.focus('discount-percent')
  const disAmountFocus = keyboard && keyboard.current && keyboard.current.focus('discount-amount')

  return (
    <Fragment>
      { order &&
        <div className="width-full height-full" style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="width-full height-50 bg-fff border-bottom-ddd" style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="width-full pd-left-15" style={{ display: 'flex', alignItems: 'center' }}>การชำระเงิน</div>
            <div className="width-55" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <a onClick={goBack} className="btn btn-white btn-shadow-none border-ddd border-radius-circle width-40 height-40 center line-height-40 pd-0">
                <i className="material-icons font-1-5 color-333">close</i>
              </a>
            </div>
          </div>

          <div className="width-full" style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="bg-fff border-right-ddd pst-relative" style={{ width: '67%' }}>
              <div className="pd-10 pst-absolute top-0 left-0 right-0 bottom-80">
                <div className="height-40 mg-right--5" style={{ display: 'flex', flexDirection: 'row' }}>
                  { paymentMethods.map(method => {
                    return (
                      <div
                        onClick={() => handleSelectPayment(method.id)}
                        key={method.id}
                        className="height-full border-ddd mg-right-5 border-radius-5 font-0-8"
                        style={{
                          display: 'flex',
                          flex: 1,
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          background: paymentMethod === method.id && '#2196f3',
                          color: paymentMethod === method.id && '#fff',
                        }}>

                        <div>{method.name}</div>
                      </div>
                    )
                  })}
                </div>


                <div className="mg-top-10 pd-10 bg-000 border-radius-5">
                  <table>
                    <tbody>
                      <tr>
                        <td className="pd-0 color-fff">ส่วนลดท้ายบิล</td>
                        <td className="pd-0 width-120 pd-right-10">
                          <div
                            onClick={() => keyboard.current.handleFocus('discount-percent')}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              borderColor: disPercentFocus ? 'orangered' : '#ddd',
                              background: disPercentFocus ? '#fff9bf' : '#fff'
                            }}
                            className="height-40 width-full text-right border-1 border-radius-5">

                            <div
                              className="width-full center pd-8"
                              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                              <span>{disPercent && disPercent.valueFormat}</span>
                              <CursorFocus focus={disPercentFocus} className="width-1 border-left-000" />
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderColor: disPercentFocus ? 'orangered' : '#ddd'
                              }}
                              className="width-50 border-left-1 center">%</div>
                          </div>
                        </td>

                        <td className="pd-0 width-150">
                          <div
                            onClick={() => keyboard.current.handleFocus('discount-amount')}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              borderColor: disAmountFocus ? 'orangered' : '#ddd',
                              background: disAmountFocus ? '#fff9bf' : '#fff'
                            }}
                            className="height-40 width-full text-right border-1 border-radius-5">

                            <div
                              className="width-full center pd-8"
                              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                              <span>{disAmount && disAmount.valueFormat}</span>
                              <CursorFocus focus={disAmountFocus} className="width-1 border-left-000" />
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderColor: disAmountFocus ? 'orangered' : '#ddd'
                              }}
                              className="width-50 border-left-1 center">฿</div>
                          </div>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>

                <div className="bg-000 border-radius-5">
                  <div className="font-0-9 color-fff mg-10 pd-top-10">
                    <table>
                      <tbody>
                        <tr>
                          <td className="pd-0">ยอดรวม</td>
                          <td className="pd-0 text-right">{getNumeral(order.totalAmountProduct, 2)}</td>
                        </tr>

                        { (order.discount && order.discount.amount) ?
                          <Fragment>
                            <tr>
                              <td className="pd-0">
                                ส่วนลด {(order.discount.type === 'percent' && order.discount.percent) ? `${order.discount.percent}%` : '' }
                              </td>
                              <td className="pd-0 text-right">{ getNumeral(order.discount.amount, 2) }</td>
                            </tr>

                            <tr>
                              <td className="pd-0">ยอดรวมหลังหักส่วนลด</td>
                              <td className="pd-0 text-right">{ getNumeral(order.totalNetAmountProduct, 2) }</td>
                            </tr>
                          </Fragment>
                        : null }
                      </tbody>
                    </table>

                    { (order.vat && order.vat.status) &&
                      <div className="">
                        <table>
                          <tbody>
                            <Fragment>
                              {/* order.vat.include === true ?
                                <tr>
                                  <td className="pd-0">ยอดรวมก่อนภาษี VAT</td>
                                  <td className="pd-0 text-right">{ getNumeral(order.totalNetAmountProduct - order.vat.amount, 2) }</td>
                                </tr>
                              : null */}

                              { order.vat.include === false ?
                                <tr>
                                  <td className="pd-0">ภาษี VAT {order.vat.rate}%</td>
                                  <td className="pd-0 text-right">{ getNumeral(order.vat.amount, 2) }</td>
                                </tr>
                              : null }
                            </Fragment>
                          </tbody>
                        </table>
                      </div>
                    }

                    <div className="border-bottom-ddd mg-top--5">
                      <table>
                        <tbody>
                          <tr style={{ color: '#00ff0a' }}>
                            <td className="pd-0 font-1-0">ยอดสุทธิ</td>
                            <td className="pd-0 text-right font-1-9">
                              <span>{getNumeral(order.totalNetAmount, 2)}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div
                    onClick={() => keyboard.current.handleFocus('amount-received')}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    className="color-fff font-4-0 height-100 pd-15 pd-top-30 text-center bg-000 mg-bottom-10 pst-relative border-radius-5">

                    <div className="font-1-2 pst-absolute top-5 left-10">ยอดเงินรับ</div>
                    <span>{ amountReceived && amountReceived.valueFormat }</span>
                    <CursorFocus focus={amountFocus} className="width-1 border-left-fff" />
                  </div>
                </div>


                <div className="pd-bottom-25">
                  <div className="height-70 pd-15 pd-top-20 text-center bg-eee pst-relative border-radius-5">
                    <div className="font-1-2 pst-absolute top-5 left-10">เงินทอน</div>
                    <span className="color-error font-2-0">
                      { (change !== undefined && change >= 0) ? getNumeral(change, 'auto') : 0 }
                    </span>
                  </div>
                </div>
              </div>

              <div className="pst-absolute bottom-0 height-80 left-0 right-0 pd-10">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <a
                    onClick={goBack}
                    className="btn btn-white border-ddd btn-shadow-none width-200 height-60 line-height-60 pd-left-20 pd-right-20">

                    <i className="material-icons-outlined left font-2-0">cancel</i><span className="font-1-3">ยกเลิก</span>
                  </a>

                  { (change !== undefined && change >= 0) ?
                    <a onClick={handlePayment} className="btn btn-success btn-shadow-none width-full height-60 line-height-60 pd-left-20 pd-right-20 mg-left-10">
                      <i className="material-icons-outlined left font-2-0">payments</i><span className="font-1-3">รับชำระเงิน</span>
                    </a>
                  :
                    <a disabled className="btn btn-success btn-shadow-none width-full height-60 line-height-60 pd-left-20 pd-right-20 mg-left-10">
                      <i className="material-icons-outlined left font-2-0">payments</i><span className="font-1-3">รับชำระเงิน</span>
                    </a>
                  }
                </div>
              </div>

            </div>

            <div style={{ width: '33%' }}>
              <div className="pd-10">
                <div>
                  <KeyboardManage
                    use={(event_name === 'payment')}
                    ref={keyboard}
                    handleKeyboardChange={handleKeyboardChange}
                    values={keyboardValues}
                    useCash
                    startFocus="amount-received"
                    inputs={[
                      { name: 'amount-received', focus: true, type: 'number', minValue: 0, maxDigit: 7, useSpace: true },
                      { name: 'discount-percent', type: 'number', minValue: 0, maxValue: 100, useSpace: true },
                      { name: 'discount-amount', type: 'number', minValue: 0, maxValue: order.totalAmountProduct, useSpace: true },
                    ]}
                  />

                  <div className="pd-1 mg-bottom-70">
                    <a onClick={handlePaidFull} className="btn btn-white btn-shadow-none width-full height-50 line-height-50 border-ddd">
                      จ่ายเต็ม {getNumeral(order.totalNetAmount, 'auto')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </Fragment>
  )
}

export default connect()(PaymentPage)


const CursorFocus = (props) => {
  return (
    <Fragment>
      { props.focus &&
        <div className={`cursor dp-inline-block height-full ${props.className}`}></div>
      }

      <style jsx>{`
        .cursor {
          animation-name: blink;
          animation-duration: 1000ms;
          animation-iteration-count: infinite;
        }

        @keyframes blink {
          from { opacity: 1; }
          to { opacity: 0; }
        }
      `}</style>
    </Fragment>
  )
}

import React from 'react'
import { connect } from 'react-redux'


const Loading = (props) => {
  const style = {
    width: '100%', 
    height: '100%',
    ...props.style
  }
  
  return (
    <div className={props.className} style={style}>
      { !props.preLoading && !props.loadTest ? props.children[0] : props.children[1] }
    </div>
  )
}

function mapStateToProps(store, props) {
  return {
    preLoading: store.app.loads.filter(load => load === props.id)[0],
  }
}

export default connect(mapStateToProps)(Loading)

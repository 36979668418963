import { lazy } from 'react'
import suspenseComponent from '../../../../../../util/suspenseComponent'

// Containers
const FolderUpdateContainer = lazy(() => import('../../../../../../containers/dashboard/Folders/containers/FolderUpdateContainer'))

// Components Pages
const DescriptionsPage = lazy(() => import('../../../../../../containers/dashboard/Folders/pages/FolderUpdate/DescriptionsPage'))
const PhotosPage = lazy(() => import('../../../../../../containers/dashboard/Folders/pages/FolderUpdate/PhotosPage'))
const ShippingFeesPage = lazy(() => import('../../../../../../containers/dashboard/Folders/pages/FolderUpdate/ShippingFeesPage'))


const FolderUpdateRoutes = ({ path, typePath }) => [
  {
    path: `${path}/folders/:folders_id/update`,
    component: suspenseComponent(FolderUpdateContainer),
    params: { typePath },
    routes: [
      {
        path: `${path}/folders/:folders_id/update`,
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(DescriptionsPage)
      },
      {
        path: `${path}/folders/:folders_id/update/photos`,
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(PhotosPage)
      },
      {
        path: `${path}/folders/:folders_id/update/shipping-fees`,
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ShippingFeesPage)
      }
    ]
  }
]

export default FolderUpdateRoutes

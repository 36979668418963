import React, { Component } from 'react'
import { connect } from 'react-redux'

export class AppAuthContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div>
        { this.props.children }
      </div>
    )
  }
}

export default connect()(AppAuthContainer)

import React from 'react'
import renderRoutes from '../../../../../routes/renderRoutes'
import { connect } from 'react-redux'

// Components Global
import Window from '../../../../../components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../../components/MenuSidebarResponsive'

// Components Purchases
import RubberDepositCreateAndUpdateModal from '../components/RubberDepositCreateAndUpdateModal'




const menuItems = (params) => {
  const { username } = params
  const path = `/${username}/rubber-deposits`
  const itemMain = [
    {
      name: 'รายการฝากยาง',
      title: true
    },
    {
      name: 'ทั้งหมด',
      nameShort: 'ทั้งหมด',
      icon: 'assignment',
      link: `${path}/all/all`,
      searchLinkActive: `${path}/all/`
    },
    {
      name: 'ยางแผ่นดิบ',
      nameShort: 'ยางแผ่นดิบ',
      iconFa: 'fas fa-layer-group',
      link: `${path}/rubber-sheet/all`,
      searchLinkActive: `${path}/rubber-sheet/`
    },
    {
      name: 'ยางแผ่นรวมควัน',
      nameShort: 'ยางแผ่นรวมควัน',
      iconFa: 'fas fa-dumpster-fire',
      link: `${path}/ribbed-smoked-sheet/all`,
      searchLinkActive: `${path}/ribbed-smoked-sheet/`
    },


    {
      name: 'สต็อกฝากยาง',
      title: true
    },
    {
      name: 'ทั้งหมด',
      nameShort: 'ทั้งหมด',
      icon: 'assignment',
      link: `${path}/inventories/all/all`,
      searchLinkActive: `${path}/inventories/all/`
    },
    {
      name: 'ยางแผ่นดิบ',
      nameShort: 'ยางแผ่นดิบ',
      iconFa: 'fas fa-layer-group',
      link: `${path}/inventories/rubber-sheet/all`,
      searchLinkActive: `${path}/inventories/rubber-sheet/`
    },
    {
      name: 'ยางแผ่นรวมควัน',
      nameShort: 'ยางแผ่นรวมควัน',
      iconFa: 'fas fa-dumpster-fire',
      link: `${path}/inventories/ribbed-smoked-sheet/all`,
      searchLinkActive: `${path}/inventories/ribbed-smoked-sheet/`
    },


    {
      name: 'รายการขายฝากยาง',
      title: true
    },
    {
      name: 'ทั้งหมด',
      nameShort: 'ทั้งหมด',
      icon: 'assignment',
      link: `${path}/sales/rubber-sheet/all/deposit/sales/all`,
      searchLinkActive: `${path}/sales/rubber-sheet/all/deposit/sales/`
    },
    {
      name: 'ยางแผ่นดิบ',
      nameShort: 'ยางแผ่นดิบ',
      iconFa: 'fas fa-layer-group',
      link: `${path}/sales/rubber-sheet/rubber-sheet/deposit/sales/all`,
      searchLinkActive: `${path}/sales/rubber-sheet/rubber-sheet/deposit/sales/`
    },
    {
      name: 'ยางแผ่นรวมควัน',
      nameShort: 'ยางแผ่นรวมควัน',
      iconFa: 'fas fa-dumpster-fire',
      link: `${path}/sales/rubber-sheet/ribbed-smoked-sheet/deposit/sales/all`,
      searchLinkActive: `${path}/sales/rubber-sheet/ribbed-smoked-sheet/deposit/sales/`
    }
  ]

  return itemMain
}

const RubberDepositsMenuContainer = (props) => {
  const { business, setting, location, route, match, windowModal, closeWindowModal, history } = props
  const title = "ขายยางแผ่น (ฝากยาง)"
  const titleIcon = require(`../../../Businesses/resources/icons/rubber-sheet-deposit.png`)

  return (
    <Window
      title={title}
      titleIcon={titleIcon}
      linkClose={`/${match.params.username}`}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkHelp="">

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive
            btnAddModal={{
              modalId: 'rubberDepositCreateAndUpdateModal',
              text: `เพิ่มการฝากยาง`,
            }}
            items={menuItems(match.params)}
          />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, setting },
            location
          }) }
        </Layouts2>
      </LayoutsTwo>

      { props.modal &&
        <RubberDepositCreateAndUpdateModal {...props} event="create" id="rubberDepositCreateAndUpdateModal" business={business} />
      }
    </Window>
  )
}

function mapStateToProps(store, props) {
  return {
    modal: store.app.modals.filter(modal => modal.id === 'rubberDepositCreateAndUpdateModal')[0]
  }
}

export default connect(mapStateToProps)(RubberDepositsMenuContainer)

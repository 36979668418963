import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

// Components Global
import { Row, Column } from '../../../../../../../components/Columns'
import EditData from '../../../../../../../components/EditData'
import DatasList from '../../../../../../../components/DatasList'
import Section from '../../../../../../../components/Section'
import BtnLoading from '../../../../../../../components/button/BtnLoading'
import { BtnModalOpen } from '../../../../../../../components/Modals/BtnModal'
import IconsListsModal from '../../../../../../../components/TextEditor/components/EditorIcon/components/IconsListsModal'

// Components Local
import {
  BoxStatus,
  PageName,
  PagePath,
  PageStatusSwitch,
  PageGroupPageType,
  PageFetchType,
  PageFetchId
} from '../../../PageForms'

// Values
import { pageValue } from '../../../../../../../redux/Pages/PagesValues'


class Descriptions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: '',
      path: '',
      status: ''
    }
  }

  handlePathChange = (data) => {
    this.setState({
      data,
      path: data.value,
      status: data.status
    })
  }

  handleSelectIcon = (icon, loadingId) => {
    loadingId = loadingId || 'select-icon-loading'

    this.props.submit({ icon }, loadingId)
  }

  render() {
    const lang = this.props.lang || 'local'
    let { page, name, submit } = this.props
    page = pageValue({ page })

    return (
      <div>
        <div className="box mg-bottom-20">
          <Section title="การแสดงผลเว็บไซต์">
            <div>
              <BoxStatus title="แสดงหน้าเว็บไซต์" status={page.status === 'open' ? true : false }>
                <PageStatusSwitch {...this.props} title="หน้าเว็บไซต์ " name="status" />
              </BoxStatus>

              <BoxStatus title="แสดงในเมนูบาร์" status={page.navbarShow}>
                <PageStatusSwitch {...this.props} title="ในเมนูบาร์ " name="navbarShow" />
              </BoxStatus>
            </div>
          </Section>

          <Section title="การแสดงผลส่วนหัว (Header)">
            <BoxStatus title="แสดงส่วนหัว (Header)" status={page.headerShow}>
              <PageStatusSwitch {...this.props} title="ส่วนหัว (Header) " name="headerShow" />
            </BoxStatus>

            <BoxStatus title="ส่วนหัวแบบปกติ (Header Default)" status={page.headerDefaultShow}>
              <PageStatusSwitch {...this.props} title="ส่วนหัวแบบปกติ" name="headerDefaultShow" />
            </BoxStatus>

            <BoxStatus title="ส่วนหัวแบบคงที่ (Header Fixed)" status={page.headerFixedShow}>
              <PageStatusSwitch {...this.props} title="ส่วนหัวแบบคงที่" name="headerFixedShow" />
            </BoxStatus>
          </Section>

          <Section title="การแสดงผลส่วนท้าย (Footer)">
            <BoxStatus title="แสดงส่วนท้าย (Footer)" status={page.footerShow}>
              <PageStatusSwitch {...this.props} title="ส่วนท้าย (Footer) " name="footerShow" />
            </BoxStatus>
          </Section>

          <Section title="ข้อมูลหน้าเพจ">
            <EditData
              recheckForm
              name={name}
              dataChange={JSON.stringify(this.state.data)}
              submit={submit}>

              <div>
                <DatasList
                  style={{ border: '0' }}
                  items={[
                    {
                      labelTop: true,
                      label: 'ชื่อหน้า',
                      value: page.name[lang],
                      icon: 'account_box',
                      className: 'border-top-eee'
                    },
                    {
                      labelTop: true,
                      label: 'ประเภทกลุ่มหน้าเพจ',
                      value: page.groupPageTypeName || 'หน้าหลัก (main)',
                      icon: 'link',
                      className: 'border-bottom-eee'
                    },
                    {
                      labelTop: true,
                      label: 'ชื่อ URL',
                      value: page.path,
                      icon: 'link',
                      className: 'border-bottom-eee'
                    },
                    {
                      labelTop: true,
                      label: 'ดึงข้อมูล API',
                      value: page.fetchType,
                      icon: 'api',
                      className: 'border-bottom-eee'
                    },
                    {
                      labelTop: true,
                      label: 'ไอดี (ดึงข้อมูล API)',
                      value: page.fetchId,
                      icon: 'api',
                      className: 'border-bottom-eee'
                    }
                  ]}
                />
              </div>

              <div className="pd-top-15">
                <Row>
                  <Column col="column col-xs-12">
                    <PageName lang={lang} value={page.name} />
                    <PageGroupPageType value={page.groupPageType || 'main'} />
                    <PagePath
                      {...this.props}
                      update
                      onChange={this.handlePathChange}
                      value={page.path}
                      disabled={ page.path === 'index' }
                    />
                    <PageFetchType value={page.fetchType || ''} />
                    <PageFetchId value={page.fetchId} />
                  </Column>
                </Row>
              </div>
            </EditData>
          </Section>

          <Section title="ไอคอนหน้าเพจ">

            <IconsListsModal
              id={`${page._id}-modal-icons`}
              getIcon={this.handleSelectIcon}
              selectLoadingId="select-icon-loading"
            />

            <div className="text-center">
              <div className="pd-top-20">
                { page.icon && page.icon.name ?
                  <Fragment>
                    { page.icon.type === 'material-icons' &&
                      <i className="material-icons font-5-0">{page.icon.name}</i>
                    }
                  </Fragment>
                :
                  <Fragment>
                    <i className="material-icons font-5-0">
                      { page.path === 'index' && 'home' }
                      { page.path !== 'index' && 'insert_drive_file' }
                    </i>
                  </Fragment>
                }
              </div>

              <div className="pd-top-20">
                <BtnLoading
                  onClick={() => this.handleSelectIcon({ type: '', name: ''}, 'reset-icon-loading')}
                  loadingId="reset-icon-loading"
                  icon="refresh"
                  text="รีเซ็ต"
                  className="btn btn-xs btn-edit btn-shadow-none mg-right-10"
                  loadingStyle={{ width: '18px', height: '18px', marginTop: '-1px' }}
                />

                <BtnModalOpen id={`${page._id}-modal-icons`}>
                  <BtnLoading
                    loadingId="select-icon-loading"
                    icon="edit"
                    text="เปลี่ยน"
                    className="btn btn-xs btn-shadow-none"
                    loadingStyle={{ width: '18px', height: '18px', marginTop: '-1px' }}
                  />
                </BtnModalOpen>
              </div>
            </div>
          </Section>
        </div>
      </div>
    )
  }
}


export default connect()(Descriptions)

import React, { Component } from 'react'

// Components Customers
import CustomerSelected from '../../../Customers/components/CustomerSelected'

// css
import css from './css/saleOrderCustomerManager.css'

// Redux Actions
import { updateOrder } from '../../../../../redux/Orders/OrdersActions'


class OrderCustomerManager extends Component {
  selectCustomer = (customer) => {
    if (customer) {
      const { business, order } = this.props
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businesses_id: business._id,
        orders_id: order._id
      }

      this.props.dispatch(updateOrder(query, {
        body: { customers_id: customer._id, customer: customer },
        loadId: 'selectCustomer',
        toastMsg: 'เพิ่มรายชื่อลูกค้าเรียบร้อยแล้ว'
      }))
    }
  }

  deleteCustomer = () => {
    const { business, order } = this.props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id,
      orders_id: order._id
    }

    this.props.dispatch(updateOrder(query, {
      body: { customers_id: null, customer: null },
      loadId: 'deleteCustomer',
      confirmId: 'deleteCustomer',
      toastMsg: 'ลบรายชื่อลูกค้าเรียบร้อยแล้ว'
    }))
  }

  render() {
    const { order, business } = this.props
    const customer = order.customer

    return (
      <div className={css.customer}>
        <CustomerSelected
          {...this.props}
          id="selectCustomer"
          select={this.selectCustomer}
          delete={this.deleteCustomer}
          customer={customer}
          business={business}
        />
      </div>
    )
  }
}

export default OrderCustomerManager

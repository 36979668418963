import AppContainer from '../../common/AppContainer'

// routes
import BusinessesRoutes from './Businesses/BusinessesRoutes'

import BusinessesList from '../../containers/dashboard/Businesses/pages/BusinessesList'
import SignIn from '../../modules/Auth/pages/SignIn'
import SignUp from '../../modules/Auth/pages/SignUp'
import SignOut from '../../modules/Auth/pages/SignOut'

import ConfirmAccount from '../../modules/Auth/pages/ConfirmAccount'
import ConfirmAccountToken from '../../modules/Auth/pages/ConfirmAccountToken'
import ForgotPassword from '../../modules/Auth/pages/ForgotPassword'
import ResetPassword from '../../modules/Auth/pages/ResetPassword'
import NotFound from '../../common/Pages/NotFound'
import TestDemo from '../../common/Pages/TestDemo'

import PrivacyPolicy from '../../containers/dashboard/Info/PrivacyPolicy'


const DashboardRoutes = [
  {
    component: AppContainer,
    routes: [
      {
        path: '/',
        exact: true,
        auth: { reqAuth: true },
        component: BusinessesList
      },
      {
        path: '/test-demo',
        component: TestDemo
      },
      {
        path: '/privacy-policy',
        component: PrivacyPolicy
      },
      {
        path: '/signin',
        auth: { unAuth: true },
        component: SignIn
      },
      {
        path: '/signup',
        auth: { unAuth: true },
        component: SignUp
      },
      {
        path: '/signout',
        component: SignOut
      },
      {
        path: '/confirm-account',
        auth: { reqAuth: true, active: false },
        component: ConfirmAccount
      },
      {
        path: '/confirm-account-token/:token',
        component: ConfirmAccountToken
      },
      {
        path: '/forgot-password',
        component: ForgotPassword
      },
      {
        path: '/reset-password/:token',
        component: ResetPassword
      },
      ...BusinessesRoutes,
      {
        component: NotFound
      }
    ]
  }
]

export default DashboardRoutes

import React from 'react'
import { connect } from 'react-redux'

// Components Global
import { PageManage } from '../../../../../components/SitesManage/Pages'


const PageManagePage = (props) => {
  const { site, business, setting, page, params, singlePageType, singlePageData, actionPage, actionSinglePage } = props

  let useQuery, fetch, update, uploadImage, imagePrefix, data

  if (params.siteManage) {
    useQuery = actionPage.useQuery
    fetch = actionPage.fetch
    update = actionPage.update
    uploadImage = actionPage.uploadImage
    imagePrefix = actionPage.imagePrefix
    data = actionPage.data

  } else if (params.singlePageManage) {
    useQuery = actionSinglePage.useQuery
    fetch = actionSinglePage.fetch
    update = actionSinglePage.update
    uploadImage = actionSinglePage.uploadImage
    imagePrefix = actionSinglePage.imagePrefix
    data = actionSinglePage.data
  }

  if (useQuery && data && data._id) {
    useQuery._id = data._id
  }

  return (
    <PageManage
      {...props}
      action={{
        useQuery,
        fetch,
        update,
        uploadImage,
      }}
      datas={{
        business,
        setting,
        site,
        page: data,
        imagePrefix,
        templateData: data
      }}
    />
  )
}

export default connect()(PageManagePage)

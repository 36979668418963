import React from 'react'
import renderRoutes from '../../../../routes/renderRoutes'

// Components Global
import Window from '../../../../components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../components/MenuSidebarResponsive'

// imgs
import titleIcon from '../resources/imgs/common-settings.png'


const menuItems = ({ params, business, setting }) => {
  let rubberMenu = [], storeMenu = [], warehouses = []

  // rubber
  if (business.type === 'rubber') {
    const { types } = setting
    const lataxTypes = types.filter(v => v.code === 'field-latex' && v.multiWarehouses === true)

    if (lataxTypes.length > 0) {
      warehouses.push({
        name: 'คลังยาง',
        title: true
      })

      lataxTypes.map(t => {
        warehouses.push({
          name: t.name.local,
          icon: 'warehouse',
          link: `/${params.username}/common-settings/rubber/warehouses/${t._id}/all`,
          searchLinkActive: `/${params.username}/common-settings/rubber/warehouses/`
        })
      })
    }

    rubberMenu = [
      {
        name: 'ระบบซื้อขายน้ำยาง',
        title: true
      },
      {
        name: 'ตั้งค่าพื้นฐาน',
        icon: 'settings',
        link: `/${params.username}/common-settings/rubber`,
        exact: true,
      },
      {
        name: 'ประเภทซื้อขาย',
        icon: 'dashboard_customize',
        link: `/${params.username}/common-settings/rubber/types/all`,
        searchLinkActive: `/${params.username}/common-settings/rubber/types/`
      },

      ...warehouses
    ]
  }


  // other
  if (business.type !== 'rubber') {
    storeMenu = [
      {
        name: 'ตั้งค่าการชำระเงิน',
        title: true
      },
      {
        name: 'วิธีการชำระเงิน',
        icon: 'account_balance_wallet',
        link: `/${params.username}/common-settings/payment-methods`
      },
      {
        name: 'บัญชีธนาคาร',
        icon: 'account_balance',
        link: `/${params.username}/common-settings/bank-accounts`
      },


      {
        name: 'ตั้งค่าสิทธิการใช้งาน',
        title: true
      },
      {
        name: 'กลุ่มสิทธิ',
        icon: 'groups',
        link: `/${params.username}/common-settings/admin-role-groups`
      },
      {
        name: 'ปิด/เปิด ระบบ',
        icon: 'manage_accounts',
        link: `/${params.username}/common-settings/systems`
      },


      {
        name: 'ตั้งค่าอัลบัม',
        title: true
      },
      {
        name: 'อัลบัมสินค้า',
        icon: 'photo_library',
        link: `/${params.username}/common-settings/albums/products`
      },
      {
        name: 'อัลบัมตัวเลือกสินค้า',
        icon: 'photo_library',
        link: `/${params.username}/common-settings/albums/product-items`
      },
      {
        name: 'อัลบัมลูกค้า',
        icon: 'photo_library',
        link: `/${params.username}/common-settings/albums/customers`
      },
      {
        name: 'อัลบัมบทความ',
        icon: 'photo_library',
        link: `/${params.username}/common-settings/albums/articles`
      }
    ]
  }

  return [
    {
      name: 'ข้อมูลกิจการ',
      title: true
    },
    {
      name: 'ข้อมูลทั่วไป',
      icon: 'perm_data_setting',
      link: `/${params.username}/common-settings`,
      exact: true,
    },
    {
      name: 'ตั้งค่าภาษา',
      icon: 'language',
      link: `/${params.username}/common-settings/languages`
    },
    {
      name: 'ข้อมูลบริษัท',
      icon: 'business',
      link: `/${params.username}/common-settings/company`,
      exact: true,
    },
    {
      name: 'ข้อมูลภาษี',
      icon: 'receipt',
      link: `/${params.username}/common-settings/tax`
    },
    {
      name: 'ภาพโปรไฟล์',
      icon: 'photo_size_select_large',
      link: `/${params.username}/common-settings/pictures`
    },

    ...rubberMenu,
    ...storeMenu
  ]
}

const CommonSettingsMenuContainer = (props) => {
  const { business, setting, location, route, match, windowModal, closeWindowModal, history } = props
  const params = match.params

  return (
    <Window
      title="จัดการข้อมูลกิจการ"
      titleIcon={titleIcon}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkClose={`/${params.username}`}>

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive items={menuItems({ params, business, setting })} />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, setting },
            location
          }) }
        </Layouts2>

      </LayoutsTwo>
    </Window>
  )
}

export default CommonSettingsMenuContainer

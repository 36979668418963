import React, { Component } from 'react'
import { connect } from 'react-redux'
import cuid from 'cuid'

import {
  onSearchData
} from 'components/FetchDataListItems/components/InputSearchItems/js/inputSearchFunction'

class InfiniteScrollV2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      pageSearch: 1,
      load: false,
      loadSearch: false,
      id: cuid()
    }
  }

  cleanDatas() {
    const { cleanAction } = this.props

    if (cleanAction) {
      const { store, action } = cleanAction

      this.props.dispatch(action({
        store,
      }))
    }
  }

  componentWillUnmount() {
    this.cleanDatas()
  }

  componentDidMount() {
    if (this.props.startLoad === undefined) {
      this.loadFirst()
      this.addEventscroll()
    }
  }

  reset = () => {
    this.setState({
      page: 1,
      pageSearch: 1,
      load: false,
      loadSearch: false
    })
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.searchData !== this.props.searchData) {
      if (nextProps.searchData.value !== this.props.searchData.value) {
        this.setState({ searchData: nextProps.searchData, hasMoreSearch: true })
      }
    }

    if (nextProps.startLoad !== this.props.startLoad) {
      if (nextProps.startLoad) {
        this.loadFirst()
        this.addEventscroll()
      } else {
        this.reset()
      }
    }
  }

  loadFirst = () => {
    this.setState({ load: true }, () => {
      const fetch = this.props.fetchAction
      const fecthParams = {
        body: {
          ...fetch.body,
          pageNumber: 1
        },
        store: fetch.store,
        loadId: `preload-${this.props.id}`
      }

      this.props.dispatch(fetch.action(fetch.query, fecthParams)).then(res => {
        if (res.success) {
          let data = res.data

          if (data.length >= fetch.body.limit) {
            this.setState({ hasMore: true, load: false, page: this.state.page + 1 })
            this.checkHeightLoadMore()
          } else {
            this.setState({ hasMore: false, load: false })
          }
        }
      })
    })
  }

  loadMore = () => {
    if (this.state.hasMore) {
      this.setState({ load: true }, () => {
        const fetch = this.props.fetchAction
        const fecthParams = {
          body: {
            ...fetch.body,
            pageNumber: this.state.page
          },
          store: fetch.store,
          loadId: `more-${this.props.id}`,
          addsManyMore: true,
        }

        this.props.dispatch(fetch.action(fetch.query, fecthParams)).then(res => {
          if (res.success) {
            let data = res.data

            if (data.length >= fetch.body.limit) {
              this.setState({ hasMore: true, load: false, page: this.state.page + 1 })
              this.checkHeightLoadMore()
            } else {
              this.setState({ hasMore: false, load: false })
            }
          }
        })
      })
    }
  }

  loadMoreSearch = (searchData) => {
    if (this.state.hasMoreSearch) {

      this.setState({ loadSearch: true }, () => {
        let fetch = this.props.fetchAction

        onSearchData(this, searchData.value, fetch, {
          pageNumber: this.state.pageSearch + 1,
          loadId: `more-${this.props.id}`,
          addsManyMore: true
        }).then(res => {
          if (res.success) {
            let data = res.data

            if (data.length >= fetch.body.limit) {
              this.setState({ hasMoreSearch: true, loadSearch: false, pageSearch: this.state.pageSearch + 1 })
              this.checkHeightLoadMore()
            } else {
              this.setState({ hasMoreSearch: false, loadSearch: false, pageSearch: 1 })
            }
          }
        })
      })
    }
  }

  checkHeightLoadMore = () => {
    setTimeout(() => {
      let contentElm = document.getElementById(this.state.id)
      let listItemElm = document.getElementById(`list-item-${this.state.id}`)

      if (listItemElm.clientHeight < contentElm.clientHeight) {
        // normal
        if (this.state.load === false) {
          let searchData = this.state.searchData

          if (searchData && !searchData.status || !searchData) {
            this.loadMore()
          }
        }

        // for search
        if (this.state.loadSearch === false) {
          let searchData = this.state.searchData

          if (searchData && searchData.status) {
            this.loadMoreSearch(searchData)
          }
        }
      }
    }, 100)
  }

  addEventscroll = () => {
    setTimeout(() => {
      let listElm = document.getElementById(this.state.id)

      if (listElm) {
        let bottom = listElm.scrollHeight / 100 * 5

        listElm.addEventListener('scroll', () => {
          if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - bottom) {
            // normal
            if (this.state.load === false) {
              let searchData = this.state.searchData

              if (searchData && !searchData.status || !searchData) {
                this.loadMore()
              }
            }

            // for search
            if (this.state.loadSearch === false) {
              let searchData = this.state.searchData

              if (searchData && searchData.status) {
                this.loadMoreSearch(searchData)
              }
            }
          }
        })
      } else {
        console.log(`listElm is undefined`)
      }
    }, 0)
  }

  render() {
    const { boxHeight } = this.props

    return (
      <div style={{ position: 'absolute', left: '0', right: '0', top: '0', bottom: '0' }}>
        <div id={this.state.id} className="content-jsx" style={{ height: !boxHeight && '100%', overflow: 'auto' }}>
          <div id={`list-item-${this.state.id}`}>
            { this.props.children }
          </div>

          { this.props.load &&
            <div className="center">
              <table style={{ width: '170px', margin: '0 auto' }}>
                <tbody>
                  <tr>
                    <td width="30">
                      <div className="la-ball-clip-rotate la-sm" style={{width: '20px', height: '20px', color: '#7cb342'}}>
                        <div className="width-20 height-20" style={{ borderWidth: '2px'}}></div>
                      </div>
                    </td>
                    <td width="140">กำลังโหลดข้อมูล...</td>
                  </tr>
                </tbody>
              </table>
            </div>
          }

          <style jsx>{`
            .content-jsx {
              max-height: ${boxHeight ? `${boxHeight}px` : 'unset'};
            }

            @media (min-width: 1500px) {
              .content-jsx {
                max-height: ${boxHeight ? `${boxHeight/15}vw` : 'unset'};
              }
            }
          `}</style>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store, props) => {
  return {
    load: store.app.loads.filter(load => load === 'more-' + props.id)[0]
  }
}

export default connect(mapStateToProps)(InfiniteScrollV2)

import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import renderRoutes from '../../../../../../../routes/renderRoutes'

// Components Orders
import OrderShippingManageModal from '../../../../../Orders/components/OrderShippingManageModal'
import OrderInstallmentsCreateModal from '../../../../../Orders/components/OrderInstallmentsCreateModal'


// Components Sales
import OrderManager from '../../../../components/OrderManager'

// Components Products
import ProductsListTabs from '../../../../../Products/components/ProductsListTabs'


const PosOnlinePage = (props) => {
  const { route, order, match, business, location, history, dispatch } = props
  const { username, pageName } = match.params
  const [checkout, setCheckout] = useState(false)
  const [success, setSuccess] = useState(false)

  const gotoPageMain = () => {
    setCheckout(false)
    setSuccess(false)
  }

  const gotoPageCheckout = () => {
    setCheckout(true)
    setSuccess(false)
  }

  const gotoPageSuccess = () => {
    setCheckout(false)
    setSuccess(true)
  }

  const goBackToMain = () => {
    gotoPageMain()

    setTimeout(() => {
      history.push(`/${username}/sales/pos-online/${order._id}/main`)
    }, 1000)
  }

  useEffect(() => {
    if (pageName === 'main') {
      gotoPageMain()
    } else if (pageName === 'checkout') {
      gotoPageCheckout()
    } else if (pageName === 'success') {
      gotoPageSuccess()
    }
  }, [pageName])

  /*useEffect(() => {
    if (pageName !== 'success' && order) {
      if (order.status !== 'draft' && order.status !== 'open') {
        history.push(`/${username}/sales`)
      }
    }
  }, [success, checkout])*/

  return (
    <Fragment>
      <div className={`pos-container-jsx`}>
        <div className={`pos-product-jsx`}>
          <ProductsListTabs {...props} />
        </div>

        <div className={`pos-manage-jsx`}>
          <OrderManager {...props} goBackToMain={goBackToMain} pos checkoutPage={pageName === 'checkout' ? true : false} />
        </div>

        <div className={`pos-page-jsx`}>
          <OrderShippingManageModal {...props} order={order} />
          <OrderInstallmentsCreateModal {...props} order={order} />

          { renderRoutes({
            routes: route.routes,
            extraProps: { business, order },
            location
          }) }
        </div>
        <div style={{ clear: 'both' }}></div>
      </div>

      <style jsx>{`
        .pos-container-jsx {
          position: absolute;
          top: 0;
          bottom: 0;
          left: ${checkout ? 'calc(-100% + 500px)' : (success ? '-100%' : '0')};
          right: 0;
          transition: all 1s;
          width: 200%;
        }
        .pos-product-jsx {
          width: calc(50% - 500px);
          float: left;
          height: 100%;
          position: relative;
        }
        .pos-manage-jsx {
          width: 500px;
          float: left;
          height: 100%;
          position: relative;
        }
        .pos-page-jsx {
          width: ${success ? '50%' : 'calc(50% - 500px)'};
          float: left;
          height: 100%;
          position: relative;
        }

        @media (max-width: 999px) {
          .pos-container-jsx {
            left: ${checkout && '-100%'};
          }
          .pos-product-jsx {
            width: 0;
          }
          .pos-manage-jsx {
            width: 50%;
          }
          .pos-page-jsx {
            width: 50%;
          }
        }
      `}</style>
    </Fragment>
  )
}

export default connect()(PosOnlinePage)

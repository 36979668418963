import React, { Fragment, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

// Componets Global
import { Row, Column } from '../../../../../components/Columns'
import ModalFixed from '../../../../../components/Modals/ModalFixed'
import Form from '../../../../../components/Form'
import BtnSubmit from '../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../util/getFormValue'

// Components Local
import {
  CustomerBranchName,
  CustomerBranchNo,
  CustomerAddressName,
  CustomerAddressTel,
  CustomerAddressNo,
  CustomerAddressGeo,
  CustomerAddreesLocation
} from '../CustomerForms/CustomerForms'

// Actions Redux
import { updateCustomer } from '../../../../../redux/Customers/CustomersActions'
import { updateAuthUserCustomer } from '../../../../../redux/Auth/AuthActions'

// Functions
import { updateOrderCustomer } from '../../../../../redux/Orders/OrdersFunctions'
import { closeModal, openModal } from '../../../../../redux/App/AppActions'



const CustomerAddressUpdateModal = (props) => {
  const formRef = useRef()
  const [geo, setGeo] = useState()

  useEffect(() => {
    if (props.address && props.address.geo) {
      setGeo(props.address.geo)
    }
  }, [])

  const handleGeoChange = (geo) => {
    setGeo(geo)
  }

  const handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(formRef.current)
    const { customer, order, business, title, address, addForCart, addForOrder, updateCart, updateAuth } = props
    const msg =  title || 'แก้ไขที่อยู่จัดส่ง'

    if (addForCart) {
      addForCart({ params, formName })

    } else if (addForOrder) {
      addForOrder({ params, formName })

    } else {
      if (params) {
        params.updateType = 'set'
        params.updateElement = 'addresses'
        params.updateId = address._id

        let updateFn, authName

        if (order || props.forAdmin) {
          authName = 'admin'
        } else {
          authName = 'user'
        }

        const actionParams = {
          body: params,
          loadId: formName,
          modalId: formName,
          toastMsg: !order && !updateCart && `${msg}แล้ว`
        }

        if (updateAuth && !order) {
          updateFn = () => updateAuthUserCustomer(authName, business._id, customer._id, actionParams)
        } else {
          const query = {
            authName,
            dbNo: business.dbNo,
            businessType: business.type,
            businesses_id: business._id,
            customers_id: customer._id
          }

          updateFn = () => updateCustomer(query, actionParams)
        }

        props.dispatch(updateFn()).then(res => {
          if (res.success) {
            if (order) {
              res.data.customers_addresses_id = address._id
              updateOrderCustomer(props, res.data, formName)
            }

            if (!order && updateCart) {
              props.dispatch(closeModal(props.modalId))
              updateCart({ customers_addresses_id: address._id, formName: 'selectAddressSubmit' })
            }
          }
        })
      }
    }
  }

  const { address, title, delivery, useBranch } = props
  const headerTitle =  title || 'แก้ไขที่อยู่จัดส่ง'

  return (
    <Fragment>
      <Form
        recheckForm
        ref={formRef}
        formRef={formRef}
        name={props.id}
        submit={(e) => handleSubmit(e, props.id)}>

        <ModalFixed
          id={props.id}
          title={headerTitle}
          headerClassName="height-50"
          bodyClassName="pd-20 top-50 bottom-60"
          footerClassName="height-60 pd-10"

          width="650px">

          <Row>
            { delivery &&
              <Column col="column col-xs-12">
                <CustomerAddreesLocation
                  {...props}
                  value={address.location}
                  onChange={handleGeoChange}
                />
              </Column>
            }

            <Column col="column col-xs-12">
              <CustomerAddressName value={address.name} />
            </Column>
            <Column col="column col-xs-12">
              <CustomerAddressTel value={address.tel} />
            </Column>

            { useBranch &&
              <Fragment>
                <Column col="column col-xs-12">
                  <CustomerBranchName required value={address.branchName} />
                </Column>
                <Column col="column col-xs-12">
                  <CustomerBranchNo required value={address.branchNo} />
                </Column>
              </Fragment>
            }

            <Column col="column col-xs-12">
              <CustomerAddressNo value={address.address} />
            </Column>
            <Column col="column col-xs-12">
              <CustomerAddressGeo value={geo} />
            </Column>
          </Row>

          <div>
            <BtnSubmit
              loadingId={props.id}
              icon="send"
              iconAlign="left"
              text="บันทึก"
              className="font-1-0 btn-shadow-none height-40 line-height-40 width-full btn btn-submit"
              btnDisabled
            />
          </div>
        </ModalFixed>
      </Form>
    </Fragment>
  )
}

export default connect()(CustomerAddressUpdateModal)

import React, { Fragment, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../../components/Modals/Modal'
import { Row, Column } from '../../../../../../components/Columns'
import Loading from '../../../../../../components/Loading'
import IconLoading from '../../../../../../components/IconLoading/index'

// Components forms
import Form from '../../../../../../components/Form'
import BtnSubmit from '../../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../../util/getFormValue'

// Components Purchases
import PurchaseIncomes from '../PurchaseIncomes'

// Components Local
import DeductCosts from '../DeductCosts'
import {
  PurchaseDate,
  PurchaseCustomerSeller,
  PurchaseEmployee,
  PurchaseNote,
  PurchaseLatexAndBucketWeightsList,
  PurchaseLatexAndBucketWeight,
  PurchaseBucketWeight,
  PurchaseNetWeight,
  PurchaseLatexPercent,
  PurchaseDryLatexWeight,
  PurchasePrice,
  PurchaseAddPrice,
  PurchaseAddPricePercent,
  PurchaseNetPrice,
  PurchaseTotalPrice,
  PurchaseWarehouse,
  PurchaseReserve
} from '../RubberPurchaseForms'

// Actions Redux
import { createPurchase, updatePurchase } from '../../../../../../redux/Purchases/PurchasesActions'
import { fetchProducts } from '../../../../../../redux/Products/ProductsActions'

// Values
import { convertDecimal } from '../../../../../../redux/RubberSettings/RubberSettingsValue'
import RubberCustomerCreateAndUpdateModal from '../../../RubberCustomerSellers/components/RubberCustomerCreateAndUpdateModal'
import { openModal } from '../../../../../../redux/App/AppActions'



const RubberPurchaseCreateAndUpdateModal = (props) => {
  const [data, setData] = useState('')
  const [customer, setCustomer] = useState()
  const [employee, setEmployee] = useState()
  const [latexAndBucketWeightsList, setLatexAndBucketWeightsList] = useState([])
  const [latexAndBucketWeight, setLatexAndBucketWeight] = useState(0)
  const [latexPercent, setLatexPercent] = useState(0)
  const [price, setPrice] = useState(0)
  const [addPrice, setAddPrice] = useState(0)
  const [addPricePercent, setAddPricePercent] = useState(0)
  const [bucketWeight, setBucketWeight] = useState(0)
  const [deductCosts, setDeductCosts] = useState([])

  const formRef = useRef()
  const { id, event, match, business, setting, dispatch, purchase } = props
  const type = setting.types[0] // fixed
  const title = event === 'create' ? 'เพิ่มรายการสั่งซื้อยาง' : 'แก้ไขรายการสั่งซื้อยาง'
  const productType = "field-latex"
  const rubberType = "field-latex"

  const handleSelectDate = (date) => {
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businessType: business.type,
      businesses_id: business._id,
    }

    if (date && event === 'create') {
      dispatch(fetchProducts(query, {
        body: {
          where: `date=${date}`,
        },
        notSaveStore: true,
        loadId: 'input-price-loading',
      })).then(res => {
        if (res.success && res.data.length !== 0) {
          const product = res.data[0]
          const item = product && product.items.filter(item => item.code === 'field-latex')[0]
          const price = item && item.price

          if (price) {
            setPrice(price)
          } else {
            setPrice(0)
          }
        } else {
          setPrice(0)
        }
      })
    }
  }

  const handleSelectCustomer = (data) => {
    if (data) {
      const customer = data
      setCustomer(customer)

      const employees = customer.members.filter(member => member.type === 'employee')

      if (employees.length === 1) {
        setEmployee(employees[0])
      } else {
        setEmployee(undefined)
      }
    } else {
      setCustomer(undefined)
    }
  }

  const handleSelectEmployee = (data) => {
    if (data) {
      setEmployee(data)
    } else {
      setEmployee(undefined)
    }
  }

  const handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(formRef.current)

    if (params) {
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id
      }

      const totalDeductCosts = getTotalDeductCosts()
      const totalPrice = parseFloat(params.totalPrice)
      const { incomes, totalPricePaid } = getIncomes(customer, employee, totalPrice)

      params.customer = customer
      params.employee = employee
      params.incomes = incomes
      params.deductCosts = deductCosts
      params.totalDeductCosts = totalDeductCosts
      params.totalPricePaid = totalPricePaid

      if (params.customers_employees_id === '') {
        delete params.customers_employees_id
      }

      if (params.latexAndBucketWeightsList) {
        params.latexAndBucketWeightsList = JSON.parse(params.latexAndBucketWeightsList)
      }

      if (params.settings_warehouses_id) {
        params.warehouse = JSON.parse(params.warehouse)
      }

      if (params.reserve === 'true') {
        params.incomes[0].reserveAmount = params.reserveAmount
      }

      if (event === 'create') {
        dispatch(createPurchase(query, {
          body: params,
          loadId: formName,
          modalId: formName,
          props,
          dataRedirect: {
            url: `/${match.params.username}/rubber-purchases/all/all/:_id`,
            resName: 'data'
          },
          toastMsg: 'เพิ่มรายการสั่งซื้อแล้ว'
        }))

      } else if (event === 'update') {
        query.purchases_id = purchase._id

        dispatch(updatePurchase(query, {
          body: params,
          loadId: formName,
          modalId: formName,
          toastMsg: 'แก้ไขรายการสั่งซื้อแล้ว'
        }))
      }
    }
  }

  const handleChangeLatexAndBucketWeightsList = (value, sum) => {
    setLatexAndBucketWeight(sum)
  }

  const handleChangeBucketWeight = (value) => {
    setBucketWeight(parseFloat(value || 0))
  }

  const handleChangeLatexPercent = (value) => {
    setLatexPercent(parseFloat(value || 0))
  }

  const handleChangePrice = (value) => {
    if (value) {
      setPrice(parseFloat(value || 0))
    }
  }

  const handleChangeAddPrice = (value) => {
    setAddPrice(parseFloat(value || 0))
  }

  const handleChangeAddPricePercent = (value) => {
    setAddPricePercent(parseFloat(value || 0))
  }

  const handleDeductCostsChange = (value) => {
    setDeductCosts(value)
  }

  useEffect(() => {
    setData({ customer, employee })
  }, [customer, employee])

  useEffect(() => {
    const { purchase } = props

    if (purchase) {
      const customer = purchase.customer
      const employee = purchase.employee
      setCustomer(customer)
      setEmployee(employee)
      setLatexAndBucketWeightsList(purchase.latexAndBucketWeightsList)
      setLatexAndBucketWeight(purchase.latexAndBucketWeight)
      setBucketWeight(purchase.bucketWeight)
      setLatexPercent(purchase.latexPercent)
      setPrice(purchase.price)
      setAddPrice(purchase.addPrice)
      setAddPricePercent(purchase.addPricePercent)
      setDeductCosts(purchase.deductCosts)
    }

  }, [props.purchase])

  const getIncomes = (customer, employee, totalPrice) => {
    let ownerIncome, employeeIncome, incomes = [], employeeIncomeData, totalPricePaid = 0

    if (customer) {
      if (customer.useEmployee && employee) {
        ownerIncome = totalPrice * employee.percentOwner / 100
        employeeIncome = totalPrice * employee.percentEmployee / 100

        ownerIncome = convertDecimal({ number: ownerIncome, name: 'buyIncomeDCM', type })
        employeeIncome = convertDecimal({ number: employeeIncome, name: 'buyIncomeDCM', type })

        employeeIncomeData = {
          data: employee,
          customers_members_id: employee._id,
          customers_id: customer._id,
          type: 'employee',
          paymentStatus: 'unpaid',
          percent: employee.percentEmployee,
          amount: employeeIncome
        }
      } else {
        ownerIncome = totalPrice
        employeeIncome = 0
      }

      if (customer.usePartner) {
        const partners = customer.members.filter(member => member.type === 'partner')

        partners.map(partner => {
          let amount = ownerIncome * partner.percentPartner / 100
          amount = convertDecimal({ number: amount, name: 'buyIncomeDCM', type })

          incomes.push({
            data: partner,
            customers_members_id: partner._id,
            customers_id: customer._id,
            type: 'partner',
            paymentStatus: 'unpaid',
            percent: partner.percentPartner,
            amount
          })
        })
      } else {
        const owner =  customer.members.filter(member => member.owner === true)[0]

        if (owner) {
          incomes.unshift({
            data: owner,
            customers_members_id: owner._id,
            customers_id: customer._id,
            type: 'owner',
            paymentStatus: 'unpaid',
            percent: employee ? employee.percentOwner : 100,
            amount: ownerIncome
          })
        }
      }

      if (employeeIncomeData) {
        incomes.push(employeeIncomeData)
      }
    }

    incomes.map(income => {
      totalPricePaid += income.amount
    })

    return {
      ownerIncome,
      employeeIncome,
      totalPricePaid,
      incomes
    }
  }

  const getTotalDeductCosts = () => {
    let totalDeductCosts = 0

    deductCosts.map(v => totalDeductCosts += v.price)

    return totalDeductCosts
  }

  let netWeight = latexAndBucketWeight - bucketWeight
  netWeight = convertDecimal({ number: netWeight, name: 'buyWeightDCM', type })

  let dryLatexWeight = netWeight * latexPercent / 100
  dryLatexWeight = convertDecimal({ number: dryLatexWeight, name: 'buyDryLatexWeightDCM', type })

  let netPrice = price + addPrice + addPricePercent
  netPrice = convertDecimal({ number: netPrice, name: 'buyPriceDCM', type })

  let totalDeductCosts = getTotalDeductCosts()

  let totalPrice = netPrice * dryLatexWeight
  totalPrice = convertDecimal({ number: totalPrice, name: 'buyTotalPriceDCM', type })

  const { ownerIncome, employeeIncome, incomes } = getIncomes(customer, employee, totalPrice+totalDeductCosts)

  return (
    <Modal
      id={id}
      headerTitle={title}
      headerLeft
      blockBgModal
      width="700px"
      padding="0">

      <div className="pd-20">
        <RubberCustomerCreateAndUpdateModal
          {...props}
          id="customerCreateModal"
          event="create"
          match={match}
          business={business}
          onSelect={handleSelectCustomer}
        />

        <Form
          recheckForm
          ref={formRef}
          name={id}
          dataChange={JSON.stringify(data)}
          submit={(e) => handleSubmit(e, id)}>

          <input type="hidden" data-id="ownerIncome" value={ownerIncome || ''} />
          <input type="hidden" data-id="employeeIncome" value={employeeIncome || ''} />

          <Row className="row-dp-flex mg-left--10 mg-right--10">
            <Column col="column col-xxs-12 col-md-3 pd-left-10 pd-right-10">
              <PurchaseDate value={purchase && purchase.date} onChange={handleSelectDate} />
            </Column>
            <Column col="column col-xxs-12 col-xs-12 col-sm-7 col-md-5 pd-left-10 pd-right-10">
              <table>
                <tbody>
                  <tr>
                    <td className="pd-0">
                      <PurchaseCustomerSeller
                        {...props}
                        customer={customer}
                        onChange={handleSelectCustomer}
                        //notUpdateDataSelected
                        required
                      />
                    </td>
                    <td className="pd-0 width-40 text-right vertical-top">
                      <a onClick={() => props.dispatch(openModal('customerCreateModal'))} className="btn mg-top-10 btn-shadow-none btn-white border-ddd width-35 height-35 line-height-35  pd-0 center">
                        <i className="material-icons">add</i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Column>
            <Column col="column col-xxs-12 col-xs-12 col-sm-5 col-md-4 pd-left-10 pd-right-10">
              <PurchaseEmployee
                {...props}
                employee={employee}
                customer={customer}
                onChange={handleSelectEmployee}
                notUpdateDataSelected
              />
            </Column>
          </Row>

          <Row>
            <Column col="column col-xxs-12">
              <PurchaseNote value={purchase && purchase.note} />
            </Column>
          </Row>

          <Row>
            <Column col="column col-xxs-12 col-xs-9 pd-right-20 pd-bottom-20">
              <PurchaseLatexAndBucketWeightsList type={type} value={latexAndBucketWeightsList} onChange={handleChangeLatexAndBucketWeightsList} />
            </Column>

            <Column col="column col-xxs-12 col-xs-3">
              <PurchaseLatexAndBucketWeight type={type} value={latexAndBucketWeight} />
            </Column>
          </Row>

          {/* weight */}
          <Row>
            <Column col="column col-xxs-12 col-xs-6 col-md-3">
              <div className="width-5 height-5"></div>
            </Column>

            <Column col="column col-xxs-12 col-xs-6 col-md-3">
              <div className="width-5 height-5"></div>
            </Column>

            <Column col="column col-xxs-12 col-xs-6 col-md-3 pd-right-20-xs pd-right-20-sm pd-right-20-md pd-right-20-lg pd-right-20-xlg">
              <PurchaseBucketWeight type={type} value={bucketWeight} onChange={handleChangeBucketWeight} />
            </Column>

            <Column col="column col-xxs-12 col-xs-6 col-md-3">
              <PurchaseNetWeight type={type} value={netWeight} />
            </Column>
          </Row>

          {/* percent */}
          <Row>
            <Column col="column col-xxs-12 col-xs-6 col-md-3">
              <div className="width-5 height-5"></div>
            </Column>

            <Column col="column col-xxs-12 col-xs-6 col-md-3">
              <div className="width-5 height-5"></div>
            </Column>

            <Column col="column col-xxs-12 col-xs-6 col-md-3 pd-right-20-xs pd-right-20-sm pd-right-20-md pd-right-20-lg pd-right-20-xlg">
              <PurchaseLatexPercent type={type} value={latexPercent || ''} onChange={handleChangeLatexPercent} />
            </Column>

            <Column col="column col-xxs-12 col-xs-6 col-md-3">
              <PurchaseDryLatexWeight type={type} value={dryLatexWeight} />
            </Column>
          </Row>

          {/* price */}
          <Row>
            <Column col="column col-xxs-12 col-xs-6 col-md-3 pd-right-20-xs pd-right-20-sm pd-right-20-md pd-right-20-lg pd-right-20-xlg">
              <Loading id="input-price-loading">
                <PurchasePrice type={type} value={price || ''} onChange={handleChangePrice} />
                <Fragment>
                  <div className="font-0-8" style={{ lineHeight: '1' }}>ราคา</div>
                  <div className="height-45 border-bottom-c0c0c0">
                    <IconLoading className="mg-center pd-top-10" size={20} />
                  </div>
                </Fragment>
              </Loading>
            </Column>

            <Column col="column col-xxs-12 col-xs-6 col-md-3 pd-right-0-xs pd-right-0-sm pd-right-20-md pd-right-20-lg pd-right-20-xlg">
              <PurchaseAddPricePercent
                event={event}
                type={type}
                latexPercent={latexPercent}
                value={addPricePercent}
                onChange={handleChangeAddPricePercent}
              />
            </Column>

            <Column col="column col-xxs-12 col-xs-6 col-md-3 pd-right-20-xs pd-right-20-sm pd-right-20-md pd-right-20-lg pd-right-20-xlg">
              <PurchaseAddPrice type={type} value={addPrice} onChange={handleChangeAddPrice} />
            </Column>

            <Column col="column col-xxs-12 col-xs-6 col-md-3">
              <PurchaseNetPrice type={type} value={netPrice} />
            </Column>


            { type.buyUseDeductCost &&
              <Column col="column col-xxs-12">
                <DeductCosts
                  {...props}
                  type={type}
                  rubberType={rubberType}
                  items={props.purchase && props.purchase.deductCosts}
                  netWeight={dryLatexWeight}
                  onChange={handleDeductCostsChange}
                  totalBeforDeduct={totalPrice}
                />
              </Column>
            }

            { type.code === 'field-latex' && type.multiWarehouses &&
              <Column col="column col-xxs-12">
                <PurchaseWarehouse
                  type={type}
                  setting={setting}
                  warehouse={props.purchase && props.purchase.warehouse}
                />
              </Column>
            }
          </Row>

          <Row>
            <Column col="column col-xxs-12">
              <PurchaseTotalPrice type={type} value={totalPrice+totalDeductCosts} />
            </Column>

            { ((totalPrice+totalDeductCosts) > 0 && customer && customer.carTank) &&
              <Column col="column col-xxs-12">
                <PurchaseReserve
                  use={props.purchase && props.purchase.reserve}
                  reserveAmount={props.purchase && props.purchase.reserveAmount}
                  latexPercent={latexPercent}
                  maxAmount={totalPrice+totalDeductCosts} />
              </Column>
            }

            { customer &&
              <Column col="column col-xxs-12 mg-bottom-20 border-radius-5 pd-left-10 pd-right-10 border-ddd">
                <PurchaseIncomes
                  type={type}
                  incomes={incomes}
                  customer={customer}
                />
              </Column>
            }

            { customer && customer.usePartner &&
              <Column col="column col-xxs-12 mg-bottom-20 border-radius-5 pd-left-10 pd-right-10 border-ddd">
                <PurchaseIncomes
                  type={type}
                  incomes={incomes}
                  customer={customer}
                  partner
                />
              </Column>
            }

            <Column col="column col-xxs-12">
              <BtnSubmit
                loadingId={id}
                icon="send"
                iconAlign="left"
                text="บันทึกรายการสั่งซื้อ"
                className="btn btn-submit btn-shadow-none height-45 line-height-45 font-0-9 width-full"
                btnDisabled
              />
            </Column>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}


export default connect()(RubberPurchaseCreateAndUpdateModal)

import React from 'react'
import renderRoutes from '../../../../routes/renderRoutes'

// Components Global
import WindowBack from '../../../../components/Windows/WindowBack'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../components/MenuSidebarResponsive'


const menuItems = (params, site) => {
  return [
    {
      name: 'รายการ',
      title: true
    },
    {
      name: 'หน้าเว็บไซต์',
      nameShort: 'เว็บไซต์',
      icon: 'web',
      link: `/${params.username}/sites/${params.sites_id}/update`,
      exact: true
    },
    {
      name: 'เชื่อมต่อโดเมน',
      nameShort: 'โดเมน',
      icon: 'public',
      link: `/${params.username}/sites/${params.sites_id}/update/domain`,
      exact: true
    },
    {
      name: 'วิเคราะห์การเข้าชมเว็บไซต์',
      nameShort: 'วิเคราะห์เว็บ',
      icon: 'trending_up',
      link: `/${params.username}/sites/${params.sites_id}/update/analytics`,
      exact: true
    },
    {
      name: 'ตั้งค่าแพลตฟอร์ม',
      nameShort: 'แพลตฟอร์ม',
      icon: 'developer_mode',
      link: `/${params.username}/sites/${params.sites_id}/update/platforms`,
      exact: true
    },
    {
      name: 'คลังรูปภาพ',
      nameShort: 'รูปภาพ',
      icon: 'photo_library',
      link: `/${params.username}/sites/${params.sites_id}/update/photos`
    }
  ]
}


const SiteUpdateContainer = (props) => {
  const { route, match, site, business, setting, location } = props
  const { params } = match
  const { username, sites_id } = params

  return (
    <WindowBack
      title={`แก้ไข : ${site.nameLang}`}
      linkBack={`/${params.username}/sites`}
      breadcrumbs={[
        { label: 'จัดการเว็บไซต์', url: `/${username}/sites` },
        { label: site.username, url: `/${username}/sites/${sites_id}/update` },
      ]}>

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive items={menuItems(params, site)} />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, setting, site },
            location
          }) }
        </Layouts2>

      </LayoutsTwo>
    </WindowBack>
  )
}

export default SiteUpdateContainer

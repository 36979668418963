import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components Sales
import ProductDescriptions from '../../../ProductDescriptions'
import ProductItemQtyForm from '../../../ProductItemQtyForm'


class ProductItemCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleSubmit = (params, formName) => {
    params.products_id = this.props.productId
    params.type = 'product'
    params.updateType = 'push'
    params.updateElement = 'items'
    params.scenario = 'addOrderItem'

    this.props.submit(params, formName)
  }

  render() {
    const {product, order} = this.props

    return (
      <div>
        { product &&
          <div>
            <div className="pd-10">
              <ProductDescriptions product={product} order={order} />
            </div>

            <div>
              { product.items && product.items.map((productItem, i) => {
                if (productItem.itemChoice === "single") {
                  return (
                    <div key={productItem._id} className="pd-10 border-top-efefef">
                      <ProductItemQtyForm
                        product={product}
                        order={order}
                        productItem={productItem}
                        submit={this.handleSubmit}
                      />
                    </div>
                  )
                }
              }) }
            </div>
          </div>
        }
      </div>
    )
  }
}

ProductItemCreate.propTypes = {
  productId: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired
}

export default ProductItemCreate

import React, { Fragment, useEffect, useState } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'


import { productItemValue, productValue } from '../../../../../../../../../redux/Products/ProductsValues'
import { getNumeral } from '../../../../../../../../../util/getNumeral'


const OrderItemsList = (props) => {
  const { itemSelected, setItemSelected } = props

  useEffect(() => {
    setItemSelected(props.itemSelected)

    return () => {
      setItemSelected()
    }
  }, [JSON.stringify(props.itemSelected)])


  const handleSelectItem = (item) => {
    setItemSelected(item)
  }

  const { orderDraft } = props
  let totalQty = 0, totalNetAmountProduct = 0

  return (
    <div
      className="pst-absolute top-0 left-0 right-0 bottom-0 pd-15"
      style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>

      <div className="border-ddd" style={{ overflowX: 'auto'}}>
        <table className="table-jsx">
          <thead>
            <tr className="bg-fff font-0-9">
              <th className="pd-8 width-50 min-width-50 border-left-0 center">#</th>
              <th className="pd-8 width-100 min-width-100 border-left-0">รหัส</th>
              <th className="pd-8 min-width-100" >รายการ</th>
              <th className="pd-8 min-width-50 center" >จน.</th>
              <th className="pd-8 min-width-60 center" >ราคา</th>
              <th className="pd-8 min-width-60 center" >ส่วนลด</th>
              <th className="pd-8 min-width-70 text-right border-right-0" >ยอดสุทธิ</th>
            </tr>
          </thead>

          <tbody>
            { orderDraft.items && orderDraft.items.map((item, i) => {

              const product = productValue({ product: item.product })
              const productItem = productItemValue({ product, item: item.productItem })
              const active = (itemSelected && itemSelected.product._id === product._id && itemSelected.productItem._id === productItem._id)
              const netAmountProduct = (item.qty * item.price) - item.discount.amount

              totalQty += item.qty
              totalNetAmountProduct += netAmountProduct

              return (
                <tr
                  key={i}
                  onClick={() => handleSelectItem(item)}
                  style={{
                    background: active ? '#74c0fc' : '#fff',
                    color: active ? '#fff' : '#000',
                    cursor: 'pointer'
                  }}>
                  <td className="pd-0 border-left-0 center">
                    { active &&
                      <i className="material-icons i-middle" style={{ color: '#fff' }}>check_box</i>
                    }
                  </td>
                  <td className="pd-8">{productItem.barcode || '-'}</td>

                  <td className="pd-8">
                    <div className="line-height-20 height-20 text-ellipsis-1">
                      { product.nameLang } { productItem.choiceFullNameLang && <span>| { productItem.choiceFullNameLang }</span> }
                    </div>
                  </td>
                  <td className="pd-8 center">{item.qty}</td>
                  <td className="pd-8 center">{getNumeral(item.price, 'auto')}</td>
                  <td className="pd-8 center">{getNumeral(item.discount.amount, 'auto')}</td>
                  <td className="pd-8 text-right border-right-0">{getNumeral(netAmountProduct, 'auto')}</td>
                </tr>
              )
            }) }
          </tbody>
        </table>
      </div>

      <div
        className="border-left-ddd border-right-ddd border-bottom-ddd"
        style={{ background: '#ececec', display: 'flex', flexDirection: 'row' }}>

        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }} className="pd-5 text-right">
          <div className="height-30 line-height-30 pd-right-10">จำนวนรวม</div>
          <div className="width-80 bg-fff height-30 line-height-30 center border-ddd">
            { getNumeral(totalQty, 'auto') }
          </div>
        </div>

        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }} className="pd-5 text-right">
          <div className="height-30 line-height-30 pd-right-10">ยอดรวม</div>
          <div className="width-120 bg-fff height-30 line-height-30 text-right border-ddd pd-right-10">
            { getNumeral(totalNetAmountProduct, 'auto') }
          </div>
        </div>
      </div>


      <style jsx>{`
        .table-jsx th {
          position: sticky;
          top: -1px;
          background: #ececec;
          border-left: 0.5px solid #ddd;
        }
        .table-jsx td {
          border: 0.5px solid #ddd;
        }
        .table-jsx tr:last-child td {
          border-bottom: 0;
        }
      `}</style>
    </div>
  )
}

export default OrderItemsList

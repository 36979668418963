import React from 'react'


const IconLoading = (props) => {
  const width = props.width || props.size

  let style = {
    width: width || '20px',
    height: width ||'20px'
  }

  let style2 = {
    width: width || '20px',
    height: width ||'20px',
    color: props.color || '#7cb342',
    borderWidth: '2px'
  }

  let joinStyle = Object.assign(style, props.style || {})


  return (
    <div
      className={`la-ball-clip-rotate la-sm ${props.className}`}
      style={joinStyle}>

      <div style={style2}></div>
    </div>
  )
}

export default IconLoading

import React from 'react';
import { Link } from 'react-router-dom'

export function ContainerFull(props) {
  const style = {
    minHeight: '100%',
    width: '100%',
    zIndex: '999999',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    overflow: 'auto',
    background: '#7cb342',
    padding: props.padding,
  }

  return (
    <div style={style}>
      {props.children}
    </div>
  );
}

export function Box(props) {
  const style = {
    textAlign: (props.textAlign ? props.textAlign : 'left'),
    padding: props.padding,
    maxWidth: props.maxWidth,
    background: props.background,
    borderRadius: '5px',
    margin: (props.center ? '0 auto' : ''),
    position: 'relative'
  }

  let joinStyle = Object.assign(style, props.style);

  return (
    <div className="z-depth-1" style={joinStyle}>
      {props.children}
    </div>
  );
}

export function Logo(props) {
  return (
    <div>
      <img src={props.src} className="z-depth-1" width={props.width} style={{ borderRadius: '50%', border: '2px solid #fff' }} />
    </div>
  );
}

export function Item(props) {
  const style = {
    textAlign: (props.align ? props.align : 'left'),
    padding: props.padding,
    background: props.background,
    borderRadius: props.radius,
    margin: (props.center ? '0 auto' : ''),
    position: 'relative'
  }

  return (
    <div style={{ padding: props.margin }}>
      <div className="z-depth-1" style={style}>
        {props.children}
      </div>
    </div>
  );
}

export function TextOverflow(props) {
  const style = {
    fontSize: (props.fontSize ? props.fontSize : '1.2rem'),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }

  return (
    <div style={style}>
      {props.children}
    </div>
  );
}

export function Name(props) {
  const style = {
    fontSize: (props.fontSize ? props.fontSize : '1rem'),
    height: '25px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: props.color
  }

  return (
    <div style={style}>
      {props.children}
    </div>
  );
}

export function ButtonLink (props) {
  const style = {
    margin: props.margin
  };
  return (
    <Link to={props.to} className={props.className} style={style}>
      {props.icon ?
        <i className={`material-icons ${props.iconAlign}`}>{props.icon}</i>
      : null }
      {props.text}
    </Link>
  );
}

export function ButtonClose (props) {
  const style = {
    position: 'absolute',
    right: '0',
    top: '0',
    margin: '10px'
  };
  return (
    <div>
      { props.to ?
        <Link to={props.to} onClick={props.click} style={style} className="btn-floating btn-flat btn-large waves-effect waves-light">
          <i className="material-icons black-text">close</i>
        </Link>
      :
        <buttone onClick={props.click} style={style} className="btn-floating btn-flat btn-large waves-effect waves-light">
          <i className="material-icons black-text">close</i>
        </buttone>
      }
    </div>
  );
}

export function Row (props) {
  const style = {
    marginTop: '0px',
    marginBottom: '0',
    textAlign: props.align
  }

  let joinStyle = Object.assign(style, props.style)
  let className = (props.className ? props.className : '')

  return (
      <div className={`row ${className}`} style={joinStyle}>
        {props.children}
      </div>
  )
}

export function Column(props) {
  const style = {
    height: props.height,
    lineHeight: props.height,
    padding: '0',
  }

  let joinStyle = Object.assign(style, props.style);
  let center = (props.align ? props.align : '')

  return (
      <div className={`${props.col} ${center}`} style={joinStyle}>
        {props.children}
      </div>
  )
}


export function Title (props) {
  const style = {
    textAlign: props.align,
    marginBottom: '30px'
  };
  return (
      <div style={style}>
        {props.img ? <img src={props.img} /> : null }
        <h1 style={{ margin: '0', fontSize: (props.size ? props.size : '2rem') }}>{props.title}</h1>
        <p>{props.message}</p>
      </div>
  );
}

export function Or (props) {
  const style = {
    textAlign: 'center',
    margin: '40px -30px'
  }
  const styleOr = {
    fontWeight: 'bold',
    border: '1px solid #efefef',
    borderRadius: '30px',
    textDecoration: 'none',
    textAlign: 'center',
    color: '#333',
    padding: '10px 20px',
    background: '#fff',
    fontSize: '1rem'
  }
  return (
      <div style={style}>
        <a style={styleOr}>{props.text}</a>
        <hr style={{ marginTop: '-10px', border: '0', borderTop: '1px solid #efefef' }} />
      </div>
  )
}

export function IconTitle (props) {
  const style = {
    textAlign: props.align,
    marginBottom: '30px'
  };
  return (
      <div style={style}>
        {props.img ? <img src={props.img} /> : null }
        <p style={{ margin: '10px 0' }}><b className="font-1-1" style={{ color: props.titleColor }}>{props.title}</b></p>
        <p>{props.message}</p>
      </div>
  );
}

import React from 'react'
import renderRoutes from '../../../../../routes/renderRoutes'
import { connect } from 'react-redux'

// Components Global
import Window from '../../../../../components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../../components/MenuSidebarResponsive'

// img
import titleIcon from '../../../Businesses/resources/icons/rubber-sheet-stock.png'

const menuItems = (params, setting) => {
  const { username } = params
  const path = `/${username}/rubber-inventories`

  let { types, warehouses } = setting
  const lataxType = types.filter(v => v.code === 'field-latex' && v.multiWarehouses === true)[0]


  const warehoseItems = []

  if (lataxType) {
    warehouses = warehouses.filter(v => v.types_id === lataxType._id)

    if (warehouses.length > 0) {
      warehoseItems.push({
        name: 'สต็อกน้ำยางแยกบ่อ',
        title: true
      })

      warehouses.map(warehouse => {
        warehoseItems.push({
          name: warehouse.name.local,
          iconFa: 'fas fas fa-glass-whiskey',
          link: `${path}/warehouses/inventory/field-latex/${warehouse._id}/all`,
          searchLinkActive: `${path}/warehouses/inventory/field-latex/${warehouse._id}/`
        })
      })
    }
  }



  const items = [
    {
      name: 'สต็อกยาง',
      title: true
    },
    {
      name: 'น้ำยางสด',
      nameShort: 'น้ำยางสด',
      iconFa: 'fas fas fa-glass-whiskey',
      link: `${path}/inventory/field-latex/all`,
      searchLinkActive: `${path}/inventory/field-latex/`
    },
    {
      name: 'ยางแผ่นดิบ',
      nameShort: 'ยางแผ่นดิบ',
      iconFa: 'fas fa-warehouse',
      link: `${path}/inventory/rubber-sheet/all`,
      searchLinkActive: `${path}/inventory/rubber-sheet/`
    },
    {
      name: 'ยางแผ่นรมควัน',
      nameShort: 'ยางแผ่นรมควัน',
      iconFa: 'fas fas fa-dumpster-fire',
      link: `${path}/inventory/ribbed-smoked-sheet/all`,
      searchLinkActive: `${path}/inventory/ribbed-smoked-sheet/`
    },

    ...warehoseItems,

    {
      name: 'สต็อกล่วงหน้า',
      title: true
    },
    {
      name: 'ยางแผ่นดิบ',
      nameShort: 'ยางแผ่นดิบ',
      iconFa: 'fas fa-warehouse',
      link: `${path}/contract/rubber-sheet/all`,
      searchLinkActive: `${path}/contract/rubber-sheet/`
    },
    {
      name: 'ยางแผ่นรมควัน',
      nameShort: 'ยางแผ่นรมควัน',
      iconFa: 'fas fas fa-dumpster-fire',
      link: `${path}/contract/ribbed-smoked-sheet/all`,
      searchLinkActive: `${path}/contract/ribbed-smoked-sheet/`
    },

    {
      name: 'สต็อกฝากยาง',
      title: true
    },
    {
      name: 'ยางแผ่นดิบ',
      nameShort: 'ยางแผ่นดิบ',
      iconFa: 'fas fa-warehouse',
      link: `${path}/deposit/rubber-sheet/all`,
      searchLinkActive: `${path}/deposit/rubber-sheet/`
    },
    {
      name: 'ยางแผ่นรมควัน',
      nameShort: 'ยางแผ่นรมควัน',
      iconFa: 'fas fas fa-dumpster-fire',
      link: `${path}/deposit/ribbed-smoked-sheet/all`,
      searchLinkActive: `${path}/deposit/ribbed-smoked-sheet/`
    }
  ]

  return items
}

const RubberInventoriesMenuContainer = (props) => {
  const { business, setting, location, route, match, windowModal, closeWindowModal, history } = props

  return (
    <Window
      title="สต็อกยาง"
      titleIcon={titleIcon}
      linkClose={`/${match.params.username}`}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkHelp="">

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive
            items={menuItems(match.params, setting)}
          />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, setting },
            location
          }) }
        </Layouts2>
      </LayoutsTwo>
    </Window>
  )
}

export default connect()(RubberInventoriesMenuContainer)

import React, { Component } from 'react'

// Components Global
import ModalTabs from '../../../../../../Modals/ModalTabs'


// Components Local
import CharacterStyles from './components/CharacterStyles'
import ImageNotFound from './components/ImageNotFound'
import InputStyle from './components/InputStyle'

// Redux Actions
import { updateSite } from '../../../../../../../redux/Sites/SitesActions'
import { addLoad } from '../../../../../../../redux/App/AppActions'


class SiteStylesManageModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.timer = null
  }

  componentDidMount() {
    const design = this.getValue()

    this.setState(design)
  }

  getValue = () => {
    const { site } = this.props
    const { design } = site

    return {
      style: design && design.style ? JSON.parse(design.style) : {},
      class: design && design.class ? JSON.parse(design.class) : []
    }
  }

  handleSubmit = ({ timeout }) => {
    clearTimeout(this.timer)

    this.props.dispatch(addLoad('site-update-loading'))

    if (timeout) {
      this.timer = setTimeout(() => this.submit(), 3000)
    } else {
      this.submit()
    }
  }

  submit = () => {
    const formName = 'site-update-loading'
    const { business, site } = this.props
    const params = {}

    params.design = {
      style: JSON.stringify(this.state.style),
      class: JSON.stringify(this.state.class),
    }

    if (params) {
      this.props.dispatch(updateSite(business._id, site._id, {
        body: params,
        loadId: formName
      }))
    }
  }

  render() {
    const style = typeof this.state.style === 'string' ? {} : this.state.style
    const className = this.state.class || []

    return (
      <ModalTabs
        blockBgModal
        id="siteStylesManageModal"
        activeId={1}
        title="สไตล์ค่าเริ่มต้นของเว็บไซต์"
        width="100%"
        items={[
          { name: 'ไสต์ตัวอักษร', icon: 'palette' },
          { name: 'ไสต์อินพุต', icon: 'input' }
        ]}>

        <div id="1">
          <CharacterStyles _this={this} style={style} className={className} />
        </div>

        <div id="2">
          <InputStyle _this={this} style={style} />
        </div>
      </ModalTabs>
    )
  }
}

export default SiteStylesManageModal

// Values
import { getItemPriceTotal, getPaymentGetway, getShippingAddressData } from '../Orders/OrdersValues'
import { productValue, productItemValue } from '../Products/ProductsValues'

// Config
import config from '../../config'

// Functions
import { getItemImage } from '../Products/ProductsFunctions'

// Utils
import { imgUrlToBase64, barcodeToBase64 } from '../../util/imgUrlToBase64'
import { getBank } from '../../../databases/banks'


export const billValue = ({ bill, lang="local" }) => {
  if (bill) {
    const projectNameLang = bill.projectName ? bill.projectName[lang] : ''
    const order = bill.orders_id && bill.orders_id._id ? bill.orders_id : bill.order
    const installment = getIntallment({ bill, order })
    const billType = getBillType(bill.type, lang)

    const { shipping, shippingAddress, payment } = bill
    const shippingAddressData = getShippingAddressData({ shipping, shippingAddress, lang})
    const paymentData = getPaymentData({ payment, lang })

    return {
      ...bill,
      ...billType,
      order,
      projectNameLang,
      installment,
      shippingAddressData,
      paymentData
    }
  }
}

export const getPaymentData = ({ payment, lang }) => {
  if (payment) {
    const typeName = getPaymentGetway(payment).name
    const bankAccount = payment.bankAccount

    if (payment.type === 'bankTransfer' && payment.bankAccount && payment.bankAccount.code) {
      const bankDetail = getBank(payment.bankAccount.code)
      bankAccount.bankDetail = bankDetail
    }

    return {
      ...payment,
      typeName,
      bankAccount,
    }
  }
}

export const getIntallment = ({ bill, order }) => {
  const { installments } = order || {}
  const installment = installments && installments.filter(installment => installment._id === bill.installments_id)[0]

  return installment
}

export const getBillType = (type, lang="local") => {
  let billTypeName, billTypeColor

  if (type === 'tax-invoice') {
    if (lang === 'local') billTypeName = "ใบเสร็จรับเงิน/ใบกำกับภาษี"
    billTypeColor = "#689f38"

  } else if (type === 'receipt') {
    if (lang === 'local') billTypeName = "ใบเสร็จรับเงิน"
    billTypeColor = "#4da500"

  } else if (type === 'invoice') {
    if (lang === 'local') billTypeName = "ใบวางบิล/ใบแจ้งหนี้"
    billTypeColor = "#d84315"

  } else if (type === 'quotation') {
    if (lang === 'local') billTypeName = "ใบเสนอราคา"
    billTypeColor = "#6a1b9a"

  } else if (type === 'order') {
    if (lang === 'local') billTypeName = "ใบออร์เดอร์"
    billTypeColor = "#05afca"

  } else if (type === 'parcel') {
    if (lang === 'local') billTypeName = "ใบปะหน้าพัสดุ"
    billTypeColor = "#cc38b4"

  } else {
    if (lang === 'local') billTypeName = "ยังไม่ระบุ"
    billTypeColor = ""
  }

  /*.color-receipt { color:#4da500;  }
  .color-invoice { color: #d84315 }
  .color-quotation { color: #6a1b9a }
  .color-order { color: #05afca }
  .color-parcel { color: #cc38b4 }*/

  return { billTypeName, billTypeColor }
}

export const getBillProductItems = async ({ bill, business, lang }) => {
  let { type, order, orders_id } = bill

  if (type === 'quotation' || type === 'order') {
    order = orders_id._id ? orders_id : order
  }

  const { billsSettings } = business
  const settings = billsSettings[type]
  const orderItems = order.items
  let items = []

  await Promise.all(orderItems.map(async (orderItem, i) => {
    const s3Url = config.app.s3.url
    const product = productValue({ product: orderItem.product, lang })
    const productItem = productItemValue({ product, item: orderItem.productItem, lang })
    const images = getItemImage({ product, item: productItem })
    let image = images.filter(image => image.album === 'profile')[0]
    image = image && image.paths.s
    image = image && `${s3Url}/${image}`

    if (orderItem.type !== 'payment-fee' && orderItem.type !== 'shipping-fee') {

      let name = '', imageBase64, barcodeBase64

      if (settings) {
        if (settings.showBarcodeItem.use && productItem.barcode) {
          name = `บาร์โค้ด: ${productItem.barcode || '-'}\n`
        }

        if (settings.showSkuItem.use && productItem.sku) {
          name = `SKU: ${productItem.sku || '-'}\n`
        }

        if (settings.showImgItem.use) {
          if (image) {
            const data = await imgUrlToBase64(image)
            imageBase64 = data && data.imgBase64
          }
        } else {
          image = undefined
        }

        if (settings.showImgBarcodeItem.use && productItem.barcode) {
          barcodeBase64 = await barcodeToBase64(productItem.barcode, {
            width: 1,
            height: '70px',
            margin: 0,
            displayValue: false,
            fontSize: '25px'
          })
        }
      }

      name = `${name}${productItem.itemFullNameLang}`

      items.push({
        name,
        image,
        imageBase64,
        barcodeBase64,
        qty: orderItem.qty,
        price: orderItem.price,
        priceTotal: getItemPriceTotal(orderItem)
      })
    }
  }))

  return items
}






export const checkBillDataRequired = (billSize, bill) => {
  if (bill) {
    const order = bill.order
    const customer = order && order.customer

    if (bill.type === 'tax-invoice') {
        if (billSize === 'full' && customer && customer.taxId && customer.address || billSize === 'short') {
          return true
        } else {
          return false
        }

    } else if (bill.type === 'receipt' || bill.type === 'invoice' || bill.type === 'quotation') {
      if ((billSize === 'full' && customer) || billSize === 'short') {
        return true
      } else {
        return false
      }

    } else if (bill.type === 'order' || bill.type === 'parcel') {
      return true
    }
  }
}

export const checkUseBillCopy = (bill) => {
  if (bill) {
    if (bill.type === 'tax-invoice' || bill.type === 'receipt' || bill.type === 'invoice') {
      return true
    } else {
      return false
    }
  }
}

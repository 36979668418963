import React from 'react'
import renderRoutes from '../../../../../routes/renderRoutes'

// Components Global
import Window from '../../../../../components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../../components/MenuSidebarResponsive'

// img
import titleIcon from '../../../Businesses/resources/icons/rubber-reports.png'


const menuItems = (params) => {
  const { username } = params
  const path = `/${username}/rubber-reports`

  return [
    {
      name: 'ซื้อน้ำยาง',
      title: true
    },
    {
      name: 'ซื้อน้ำยาง',
      nameShort: 'ซื้อน้ำยาง',
      icon: 'local_drink',
      link: `${path}/purchases`,
    },
    {
      name: 'ค้างชำระ',
      nameShort: 'ค้างชำระ',
      icon: 'credit_card_off',
      link: `${path}/purchase-payments/unpaid`,
    },
    {
      name: 'การชำระเงิน',
      nameShort: 'การชำระเงิน',
      icon: 'credit_card',
      link: `${path}/purchase-payments/paid`,
    },
    {
      name: 'ใบสำคัญจ่าย',
      nameShort: 'ใบสำคัญจ่าย',
      icon: 'receipt',
      link: `${path}/bills`,
    },
    {
      name: 'ประกาศราคายาง',
      nameShort: 'ราคายาง',
      iconFa: 'fas fa-money-bill-alt',
      link: `${path}/products`,
    },

    {
      name: 'บัญชีลูกหนี้',
      title: true
    },
    {
      name: 'เบิกล่วงหน้า',
      nameShort: 'ซื้อน้ำยาง',
      icon: 'payments',
      link: `${path}/receivables`,
    },
    {
      name: 'ใบสำคัญรับเงิน',
      nameShort: 'ใบสำคัญรับเงิน',
      icon: 'receipt',
      link: `${path}/receivable-payments`,
    },

    {
      name: 'การขายยาง',
      title: true
    },
    {
      name: 'การขายยาง',
      nameShort: 'การขายยาง',
      iconFa: 'fas fa-dolly',
      link: `${path}/sales`,
    },
    {
      name: 'สัญญาล่วงหน้า',
      nameShort: 'ล่วงหน้า',
      iconFa: 'fas fa-file-signature',
      link: `${path}/contracts`,
    },
    {
      name: 'การฝากยาง',
      nameShort: 'การฝากยาง',
      iconFa: 'fas fa-truck-loading',
      link: `${path}/deposits`,
    },

    {
      name: 'ใบสำคัญรับเงิน',
      title: true
    },
    {
      name: 'ใบสำคัญรับเงิน',
      nameShort: 'ใบสำคัญรับเงิน',
      icon: 'receipt',
      link: `${path}/receipts`,
    },

    {
      name: 'สต็อกยาง',
      title: true
    },
    {
      name: 'น้ำยาง',
      nameShort: 'น้ำยาง',
      iconFa: 'fas fas fa-glass-whiskey',
      link: `${path}/inventories/inventory/field-latex`,
    },
    {
      name: 'ยางแผ่นดิบ',
      nameShort: 'ยางแผ่นดิบ',
      iconFa: 'fas fa-warehouse',
      link: `${path}/inventories/inventory/rubber-sheet`,
    },
    {
      name: 'ยางแผ่นรมควัน',
      nameShort: 'ยางแผ่นรมควัน',
      iconFa: 'fas fas fa-dumpster-fire',
      link: `${path}/inventories/inventory/ribbed-smoked-sheet`,
    },

    {
      name: 'สต็อกล่วงหน้า',
      title: true
    },
    {
      name: 'ยางแผ่นดิบ',
      nameShort: 'ยางแผ่นดิบ',
      iconFa: 'fas fa-warehouse',
      link: `${path}/inventories/contract/rubber-sheet`,
    },
    {
      name: 'ยางแผ่นรมควัน',
      nameShort: 'ยางแผ่นรมควัน',
      iconFa: 'fas fas fa-dumpster-fire',
      link: `${path}/inventories/contract/ribbed-smoked-sheet`,
    },

    {
      name: 'สต็อกฝากยาง',
      title: true
    },
    {
      name: 'ยางแผ่นดิบ',
      nameShort: 'ยางแผ่นดิบ',
      iconFa: 'fas fa-warehouse',
      link: `${path}/inventories/deposit/rubber-sheet`,
    },
    {
      name: 'ยางแผ่นรมควัน',
      nameShort: 'ยางแผ่นรมควัน',
      iconFa: 'fas fas fa-dumpster-fire',
      link: `${path}/inventories/deposit/ribbed-smoked-sheet`,
    },

    {
      name: 'สมาชิก',
      title: true
    },
    {
      name: 'เจ้าของสวน',
      nameShort: 'เจ้าของสวน',
      icon: 'account_circle',
      link: `${path}/customers-sellers`,
    },
    {
      name: 'โรงงาน/ผู้ซื้อ',
      nameShort: 'โรงงาน',
      icon: 'location_city',
      link: `${path}/customers-buyers`,
    },
  ]
}



const RubberReportsMenuContainer = (props) => {
  const { business, setting, location, route, match, windowModal, closeWindowModal, history } = props

  return (
    <Window
      title="รายงาน"
      titleIcon={titleIcon}
      linkClose={`/${match.params.username}`}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkHelp="">

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive items={menuItems(match.params)} />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, setting },
            location
          }) }
        </Layouts2>
      </LayoutsTwo>
    </Window>
  )
}

export default RubberReportsMenuContainer

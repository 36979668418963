import moment from 'moment'
import { fetchOrder } from '../Orders/OrdersActions'
import { getTotalPriceAll } from '../Orders/OrdersValues'
import { createBill, updateBill, removeBill } from './BillsActions'

const title = "ใบเสร็จรับเงิน"

const handleChangeStatus = (props, status, toastMsg) => {
  return (id) => {
    const { business } = props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id,
      businessType: business.type,
      bills_id: id
    }

    props.dispatch(updateBill(query, {
      body: { status: status },
      toastMsg: toastMsg,
      loadId: id,
      confirmId: id
    }))
  }
}

export const onDeleteBill = ({ props, bill, id }) => {
  const { business } = props
  const query = {
    authName: 'admin',
    dbNo: business.dbNo,
    businesses_id: business._id,
    businessType: business.type,
    bills_id: bill._id
  }

  props.dispatch(removeBill(query, {
    toastMsg: 'ลบเอกสารนี้แล้ว',
    loadId: id
  })).then(res => {
    console.log(res)
  })
}

export const onEditModal = (id) => {
  return {
    modal: { id: id }
  }
}

export const onEdit = (url) => {
  return { url: url }
}

export const onView = (url) => {
  return { url: url }
}

export const onTrash = (props, id) => {
  return {
    label: `ลบ${title}`,
    onConfirm: handleChangeStatus(props, 'trash', `ลบ${title}นี้แล้ว`)
  }
}

export const onRestore = (props) => {
  return {
    label: `กู้คืน${title}`,
    onConfirm: handleChangeStatus(props, 'open', `กู้คืน${title}แล้ว`)
  }
}

export const billSelectCustomer = (props, customer) => {
  if (customer) {
    const { business, bill } = props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id,
      businessType: business.type,
      bills_id: bill._id
    }

    props.dispatch(updateBill(query, {
      body: {
        customers_id: customer._id,
        customer: customer,
        scenario: 'addCustomer'
      },
      loadId: 'selectCustomer',
      toastMsg: 'เพิ่มรายชื่อลูกค้าแล้ว'
    }))
  }
}

export const billDeleteCustomer = (props) => {
  const { business, bill } = props
  const query = {
    authName: 'admin',
    dbNo: business.dbNo,
    businesses_id: business._id,
    businessType: business.type,
    bills_id: bill._id
  }

  props.dispatch(updateBill(query, {
    body: {
      customers_id: null,
      customer: null,
      scenario: 'deleteCustomer'
    },
    loadId: 'deleteCustomer',
    confirmId: 'deleteCustomer',
    toastMsg: 'ลบรายชื่อลูกค้าแล้ว'
  }))
}



export const createNewBill = ({ data, props }) => {
  const id = `create-bill-${data.type}`
  const { business, match, dispatch } = props
  const query = {
    authName: 'admin',
    businesses_id: business._id,
    businessType: business.type,
    dbNo: business.dbNo
  }

  const start = moment(new Date())
  const startDate = start.format('YYYY-MM-DD')
  const params = {
    createSystemBy: 'bill',
    type: data.type,
    startDate,
    dueDate: startDate
  }

  dispatch(createBill(query, {
    body: params,
    loadId: id,
    modalId: 'create-type-bill-modal',
    props,
    dataRedirect: {
      url: `/${match.params.username}/bills/${data.type}/:_id`,
      resName: 'data'
    },
    toastMsg: `สร้าง${data.name}แล้ว`
  }))
}


export const setParamsBillFromOrder = ({ bill, order, installmentNo, billType }) => {
  if (!installmentNo) {
    installmentNo = 1
  }

  const installment = order.installments.filter(v => v.no === installmentNo)[0]
  const params = {
    refNo: order.code,
    customer: order.customer,
    customers_id: order.customer && order.customer._id,
    discount: order.discount,
    vat: order.vat,
    wht: order.wht,
    src: order.src,
    items: order.items,
    createSystemBy: order.createSystemBy,
    startDate: installment.startDate,
    dueDate: installment.dueDate,
    payment: installment.payment,
    installmentNo,
    businesses_id: order.businesses_id,
    orders_id: order._id,
		orders_installments_id: installment._id,

    shipping: order.shipping,
    shippingAddress: order.shippingAddress,
    note: order.note,
    noteCustomer: order.noteCustomer,
  }

  const totalPriceAll = getTotalPriceAll(order)

  params.totalAmountProduct = order.totalAmountProduct ? order.totalAmountProduct : totalPriceAll
  params.totalNetAmountProduct = order.totalNetAmountProduct ? order.totalNetAmountProduct : totalPriceAll
  params.totalNetAmount = order.totalNetAmount ? order.totalNetAmount : totalPriceAll
  params.paymentNetAmount = order.paymentNetAmount ? order.paymentNetAmount : totalPriceAll

  if (!bill) {
    params.type = billType
  }

  return params
}

export const syncOrderToBill = ({ bill, order, props, billType, id, pdf }) => {
  const { business, dispatch } = props
  let action, toastMsg, orders_id

  if (bill) {
    orders_id = bill.orders_id
    action = updateBill
    toastMsg = 'อัปเดตเอกสารแล้ว'
  } else {
    orders_id = order._id
    action = createBill
    toastMsg = 'สร้างเอกสารแล้ว'
  }

  if (action && orders_id) {
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id,
      orders_id
    }

    dispatch(fetchOrder(query, {
      loadId: id
    })).then(res => {
      if (res.success) {
        const order = res.data
        const params = setParamsBillFromOrder({ order, bill, billType })
        const query = {
          authName: 'admin',
          dbNo: business.dbNo,
          businesses_id: business._id,
          businessType: business.type,
          bills_id: bill._id
        }

        dispatch(action(query, {
          body: params,
          loadId: id,
          toastMsg,
        })).then(res => {
          if (pdf) {
            pdf.current.refreshPdf()
          }
        })
      }
    })
  }
}

import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

// css
import css from './css/tabs.css'

export const Tabs = (props) => {
  return (
    <PerfectScrollbar>
      <ul className={css.menu}>
        { props.items.map((item, i) => {
            return (
              <li key={i}>
                <a onClick={() => props.getActiveId(i+1)} className={`${props.activeId === i+1 && css.active} waves-effect`}>
                  <i className="material-icons left i-middle mg-right-20">{item.icon}</i>
                  <span>{item.name}</span>
                </a>
              </li>
            )
          })
        }
      </ul>
    </PerfectScrollbar>
  )
}

export const TabsContent = (props) => {
  return (
    <PerfectScrollbar>
      <div className={css.content} style={props.style}>
        { props.children.length ?
          props.children.map((children, i) => {
            return (
              <div key={i}>
                { parseInt(children.props.id) === props.activeId && children }
              </div>
            )
          })
        :
          props.children
        }
      </div>
    </PerfectScrollbar>
  )
}

import React, { Fragment, useEffect, useState, forwardRef, useImperativeHandle } from 'react'


const LayoutModalManage = forwardRef((props, ref) => {
  const { children, dataId, history, location, sidebarStyle, fullscreen, urlMain } = props
  const [open, setOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    open() {
      setOpen(true)
    },

    close() {
      gotoMain()
    },

    isOpen() {
      return open
    }
  }))

  useEffect(() => {
    if (dataId === 'all') {
      gotoMain()
    }
  }, [dataId])


  const close = () => {
    setOpen(false)

    if (props.setData) {
      props.setData('')
    }
  }

  const gotoMain = () => {
    close()

    setTimeout(() => {
      history.push(`${urlMain}${location.search}`)
    }, 300)
  }

  let margin = 20

  return (
    <Fragment>
      <div className="content-jsx">
        { children[0] }
      </div>

      <div className="bg-jsx" onClick={gotoMain}></div>

      <div className={`modal-jsx ${props.modalClassName || ''}`}>
        { children[1] }
      </div>

      { children[2] }

      <style jsx>{`
        .content-jsx {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          background: #f5f5f5;
          z-index: 1;
          transition: all 0.5s;
        }

        .modal-jsx {
          position: fixed;
          top: ${open ? `${margin}px` : '100%'};
          visibility: ${open ? 'visible' : 'hidden'};
          opacity: ${open ? '1' : '0'};
          bottom: ${open ? `${margin}px` : '-100%'};
          left: ${margin}px;
          right: ${margin}px;
          z-index: 3;
          background: #f5f5f5;
          transition: all 0.5s;
        }

        .bg-jsx {
          visibility: ${open ? 'visible' : 'hidden'};
          z-index: 2;
          opacity: ${open ? '0.7' : '0'};
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: #000;
          transition: all 0.5s;
        }

        @media (min-width: 1500px) {
          .modal-jsx {
            top: ${open ? `${margin/15}vw` : '100%'};
            bottom: ${open ? `${margin/15}vw` : '-100%'};
            left: ${margin/15}vw;
            right: ${margin/15}vw;
          }
        }

        @media (max-width: 600px) {
          .modal-jsx {
            top: ${open ? `0` : '100%'};
            bottom: ${open ? `0` : '-100%'};
            left: 0;
            right: 0;
          }
        }
      `}</style>
    </Fragment>
  )
})

export default LayoutModalManage

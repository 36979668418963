import React from 'react'
import _JSXStyle from 'styled-jsx/style'

// Components Global
import { Row, Column } from '../../../../../../../../Columns'

// Components Text Editor
import {
  FontSizeSetting,
  ColorSetting,
  MarginSetting,
  FontWeightSetting,
  LinkColorSetting
} from '../../../../../../../../TextEditor/components/StyleSettings'

// Components Local
import BoxContent from '../BoxContent'

const CharacterStyles = (props) => {
  let { _this, style } = props
  style = style || {}

  return (
    <div className="pd-bottom-20">
      <Row className="row-dp-flex">
        <Column col="column col-xxs-12 col-lg-4 pd-5">
          <BoxContent>
            <div>
              <span>ตัวอักษร</span>
            </div>

            <div>
              <div className="pd-10">
                <FontSizeSetting _this={_this} minLevel="8" maxLevel="30" styleName="body-font-size" />
              </div>
              <div className="pd-10 pd-top-0">
                <ColorSetting label="สีตัวอักษร" _this={_this} styleName="body-font-color" defaultValue="#000" />
              </div>
            </div>
          </BoxContent>
        </Column>

        <Column col="column col-xxs-12 col-lg-4 pd-5">
          <BoxContent>
            <div className="center">
              <div><a>ลิงก์</a></div>
            </div>

            <div>
              <div className="pd-10">
                <LinkColorSetting _this={_this} styleName="body-a" />
              </div>
            </div>
          </BoxContent>
        </Column>

        <Column col="column col-xxs-12 col-lg-4 pd-5">
          <BoxContent>
            <div>
              <div className="mg-10" style={{ border: '1px dashed #9e9e9e' }}>
                <h1>หัวข้อ (H1)</h1>
              </div>
            </div>

            <div>
              <div className="pd-10">
                <FontSizeSetting _this={_this} minLevel="20" maxLevel="90" styleName="body-h1-font-size" />
              </div>
              <div className="pd-10 pd-top-0">
                <FontWeightSetting _this={_this} styleName="body-h1-font-weight" />
              </div>
              <div className="pd-10 pd-top-0">
                <ColorSetting label="สีตัวอักษร" _this={_this} styleName="body-h1-font-color" defaultValue="#000" />
              </div>
              <div className="pd-10 pd-top-0">
                <MarginSetting _this={_this} styleName="body-h1-margin" defaultValue="0px 0px 0px 0px" />
              </div>
            </div>
          </BoxContent>
        </Column>

        <Column col="column col-xxs-12 col-lg-4 pd-5">
          <BoxContent>
            <div>
              <div className="mg-10" style={{ border: '1px dashed #9e9e9e' }}>
                <h2>หัวข้อ (H2)</h2>
              </div>
            </div>

            <div>
              <div className="pd-10">
                <FontSizeSetting _this={_this} minLevel="20" maxLevel="80" styleName="body-h2-font-size" />
              </div>
              <div className="pd-10 pd-top-0">
                <FontWeightSetting _this={_this} styleName="body-h2-font-weight" />
              </div>
              <div className="pd-10 pd-top-0">
                <ColorSetting label="สีตัวอักษร" _this={_this} styleName="body-h2-font-color" defaultValue="#000" />
              </div>
              <div className="pd-10 pd-top-0">
                <MarginSetting _this={_this} styleName="body-h2-margin" defaultValue="0px 0px 0px 0px" />
              </div>
            </div>
          </BoxContent>
        </Column>


        <Column col="column col-xxs-12 col-lg-4 pd-5">
          <BoxContent>
            <div>
              <div className="mg-10" style={{ border: '1px dashed #9e9e9e' }}>
                <h3>หัวข้อ (H3)</h3>
              </div>
            </div>

            <div>
              <div className="pd-10">
                <FontSizeSetting _this={_this} minLevel="20" maxLevel="70" styleName="body-h3-font-size" />
              </div>
              <div className="pd-10 pd-top-0">
                <FontWeightSetting _this={_this} styleName="body-h3-font-weight" />
              </div>
              <div className="pd-10 pd-top-0">
                <ColorSetting label="สีตัวอักษร" _this={_this} styleName="body-h3-font-color" defaultValue="#000" />
              </div>
              <div className="pd-10 pd-top-0">
                <MarginSetting _this={_this} styleName="body-h3-margin" defaultValue="0px 0px 0px 0px" />
              </div>
            </div>
          </BoxContent>
        </Column>

        <Column col="column col-xxs-12 col-lg-4 pd-5">
          <BoxContent>
            <div>
              <div className="mg-10" style={{ border: '1px dashed #9e9e9e' }}>
                <h4>หัวข้อ (H4)</h4>
              </div>
            </div>

            <div>
              <div className="pd-10">
                <FontSizeSetting _this={_this} minLevel="20" maxLevel="50" styleName="body-h4-font-size" />
              </div>
              <div className="pd-10 pd-top-0">
                <FontWeightSetting _this={_this} styleName="body-h4-font-weight" />
              </div>
              <div className="pd-10 pd-top-0">
                <ColorSetting label="สีตัวอักษร" _this={_this} styleName="body-h4-font-color" defaultValue="#000" />
              </div>
              <div className="pd-10 pd-top-0">
                <MarginSetting _this={_this} styleName="body-h4-margin" defaultValue="0px 0px 0px 0px" />
              </div>
            </div>
          </BoxContent>
        </Column>

        <Column col="column col-xxs-12 col-lg-4 pd-5">
          <BoxContent>
            <div>
              <div className="mg-10" style={{ border: '1px dashed #9e9e9e' }}>
                <h5>หัวข้อ (H5)</h5>
              </div>
            </div>

            <div>
              <div className="pd-10">
                <FontSizeSetting _this={_this} minLevel="10" maxLevel="40" styleName="body-h5-font-size" />
              </div>
              <div className="pd-10 pd-top-0">
                <FontWeightSetting _this={_this} styleName="body-h5-font-weight" />
              </div>
              <div className="pd-10 pd-top-0">
                <ColorSetting label="สีตัวอักษร" _this={_this} styleName="body-h5-font-color" defaultValue="#000" />
              </div>
              <div className="pd-10 pd-top-0">
                <MarginSetting _this={_this} styleName="body-h5-margin" defaultValue="0px 0px 0px 0px" />
              </div>
            </div>
          </BoxContent>
        </Column>

        <Column col="column col-xxs-12 col-lg-4 pd-5">
          <BoxContent>
            <div>
              <div className="mg-10" style={{ border: '1px dashed #9e9e9e' }}>
                <h6>หัวข้อ (H6)</h6>
              </div>
            </div>

            <div>
              <div className="pd-10">
                <FontSizeSetting _this={_this} minLevel="10" maxLevel="40" styleName="body-h6-font-size" />
              </div>
              <div className="pd-10 pd-top-0">
                <FontWeightSetting _this={_this} styleName="body-h6-font-weight" />
              </div>
              <div className="pd-10 pd-top-0">
                <ColorSetting label="สีตัวอักษร" _this={_this} styleName="body-h6-font-color" defaultValue="#000" />
              </div>
              <div className="pd-10 pd-top-0">
                <MarginSetting _this={_this} styleName="body-h6-margin" defaultValue="0px 0px 0px 0px" />
              </div>
            </div>
          </BoxContent>
        </Column>
      </Row>

      <style jsx>{`
        span {
          color: ${style['body-font-color']};
          font-size: ${style['body-font-size']};
        }
        a {
          font-size: ${style['body-font-size']};
          color: ${style['body-a-color']};
        }
        a:hover {
          color: ${style['body-a-hover-color']};
          text-decoration: ${style['body-a-hover-text-decoration']};
        }
        a:active {
          color: ${style['body-a-active-color']};
        }


        h1 {
          font-size: ${style['body-h1-font-size']};
          color: ${style['body-h1-font-color']};
          font-weight: ${style['body-h1-font-weight']};
          margin: ${style['body-h1-margin']};
        }
        h2 {
          font-size: ${style['body-h2-font-size']};
          color: ${style['body-h2-font-color']};
          font-weight: ${style['body-h2-font-weight']};
          margin: ${style['body-h2-margin']};
        }
        h3 {
          font-size: ${style['body-h3-font-size']};
          color: ${style['body-h3-font-color']};
          font-weight: ${style['body-h3-font-weight']};
          margin: ${style['body-h3-margin']};
        }
        h4 {
          font-size: ${style['body-h4-font-size']};
          color: ${style['body-h4-font-color']};
          font-weight: ${style['body-h4-font-weight']};
          margin: ${style['body-h4-margin']};
        }
        h5 {
          font-size: ${style['body-h5-font-size']};
          color: ${style['body-h5-font-color']};
          font-weight: ${style['body-h5-font-weight']};
          margin: ${style['body-h5-margin']};
        }
        h6 {
          font-size: ${style['body-h6-font-size']};
          color: ${style['body-h6-font-color']};
          font-weight: ${style['body-h6-font-weight']};
          margin: ${style['body-h6-margin']};
        }

        @media (min-width: 1500px) {
          span, a {
            font-size: ${style['body-font-size-vw']};
          }
          h1 {
            font-size: ${style['body-h1-font-size-vw']};
            margin: ${style['body-h1-margin-vw']};
          }
          h2 {
            font-size: ${style['body-h2-font-size-vw']};
            margin: ${style['body-h2-margin-vw']};
          }
          h3 {
            font-size: ${style['body-h3-font-size-vw']};
            margin: ${style['body-h3-margin-vw']};
          }
          h4 {
            font-size: ${style['body-h4-font-size-vw']};
            margin: ${style['body-h4-margin-vw']};
          }
          h5 {
            font-size: ${style['body-h5-font-size-vw']};
            margin: ${style['body-h5-margin-vw']};
          }
          h6 {
            font-size: ${style['body-h6-font-size-vw']};
            margin: ${style['body-h6-margin-vw']};
          }
        }
      `}</style>
    </div>
  )
}


export default CharacterStyles

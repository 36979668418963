import React from 'react'

// Components Global
import TextInput from '../../../../../components/Inputs/TextInput'
import InputCheckDuplicate from '../../../../../components/Inputs/InputCheckDuplicate'
import Textarea from '../../../../../components/Inputs/Textarea'
import CheckBox from '../../../../../components/Inputs/Checkbox'

// Actions
import { checkValueDuplicate } from '../../../../../redux/Articles/ArticlesActions'


export const ArticleHighlight = (props) => {
  return (
    <CheckBox
      id="highlight"
      label="ไฮไลท์"
      size="sm"
      value={props.value}
      className="mg-bottom-20"
    />
  )
}

export const ArticleTitle = (props) => {
  const lang = props.lang || 'local'

  return (
    <TextInput
      labelHide={props.update}
      label="หัวข้อบทความ"
      id={`title.${lang}`}
      value={props.value}
      placeholder="หัวข้อบทความ"
      inputType="text"
      type="text"
      required
    />
  )
}

export const ArticlePath = (props) => {
  const { business, value, article, onChange } = props
  const query =`businesses_id=${business._id}`

  let unselectId

  if (article && article._id) {
    unselectId = article._id
  }

  return (
    <InputCheckDuplicate
      id="path"
      label="url"
      type="path"
      value={value}
      params_business_id={business._id}
      action={checkValueDuplicate}
      useQuery={{
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id
      }}
      query={query}
      unselectId={unselectId}
      onChange={onChange}
    />
  )
}

export const ArticleDescriptions = (props) => {
  const lang = props.lang || 'local'

  return (
    <Textarea
      labelHide={props.update}
      label="รายละเอียดสั้นๆ"
      placeholder="รายละเอียดสั้นๆ"
      id={`descriptions.${lang}`}
      value={props.value}
    />
  )
}

import React, {  Component } from 'react';
//import jquery from 'jquery';
import { Tooltip, TooltipIcon } from '../tooltip/Tooltip'
import numeral from 'numeral'

class TextInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: (this.props.value ? this.props.value : '' ),
      count: (this.props.value ? this.props.value.length : 0 ),
      id: this.props.id.replace('[', '').replace(']', '').replace('.', ''),
      marginTop: (this.props.marginTop ? this.props.marginTop : '15px')
    }
  }


  // Function set state is valid and invalid of in class input
  setValid = (id, valid) => {
    let newState = {};
    newState['valid_'+id] = valid;
    this.setState(newState);
  }

  // Function validate email
  validatePattern = (required, type, value, id, min, max, digit, matchFirstId, matchLastId, patternValue) => {
    if (type === 'email') {
      var pattern = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

    } else if (type === 'pattern') {
      var pattern = new RegExp(patternValue);

    } else if (type === 'number-digit') {
      var pattern = new RegExp("^[0-9]{"+digit+"}$")

    } else if (type === 'number') {
      var pattern = /^[0-9]+$/

    } else if (type === 'number-min') {
      var pattern = new RegExp("^[0-9]{" +min+ ",}$")

    } else if (type === 'number-max') {
      var pattern = new RegExp("^[0-9]{0,"+ max +"}$")

    } else if (type === 'number-min-max') {
      var pattern = new RegExp("^[0-9]{" + min + ","+ max +"}$")

    } else if (type === 'text-min') {
      var pattern = new RegExp("^.{" +min+ ",}$")

    } else if (type === 'text-max') {
      var pattern = new RegExp("^.{0,"+ max +"}$")

    } else if (type === 'text-min-max') {
      var pattern = new RegExp("^.{" + min + ","+ max +"}$")

    } else if (type === 'price') {
      document.getElementById(id).addEventListener('input', function (e) {
        var value = numeral(e.target.value).format('0,0')
        e.target.value = value
      });

      var pattern = /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/;

    } else if (type === 'phone') {
      document.getElementById(id).addEventListener('input', function (e) {
        var x = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        e.target.value = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
      });
      var pattern = /^\(?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;

    } else {
      var pattern = /^./;
    }

    if (pattern.test(value)) {

      if (matchLastId) {
        var matchLastValue = document.getElementById(matchLastId).value

        if (matchLastValue.length !== 0) {
          if (matchLastValue === value) {
            /*
            jquery('input[id='+matchLastId+']').addClass('valid')
            jquery('input[id='+matchLastId+']').removeClass('invalid')
            jquery('span[for='+matchLastId+'-required-pattern]').addClass('hide')*/
          } else {
            /*jquery('input[id='+matchLastId+']').addClass('invalid')
            jquery('span[for='+matchLastId+'-required-pattern]').removeClass('hide')*/
          }
        }

        this.setState({ msgPattern: false })
        this.setValid(id, 'valid')

      } else if (matchFirstId) {
        var matchFirstValue = document.getElementById(matchFirstId).value

        if (matchFirstValue === value) {
          this.setState({ msgPattern: false })
          this.setValid(id, 'valid')
        } else {
          this.setState({ msgPattern: true })
          this.setValid(id, 'invalid')
        }

      } else {
        this.setState({ msgPattern: false })
        this.setValid(id, 'valid')
      }

    } else {
      this.setState({ msgPattern: true });
      this.setValid(id, 'invalid');
    }
  }

  // Function input change
  handleChange = (e) => {

    let value = e.target.value
    let required = this.props.required
    let type = this.props.type
    let min = this.props.min
    let max = this.props.max
    let digit = this.props.digit
    let matchFirstId = this.props.matchFirstId
    let matchLastId = this.props.matchLastId
    let pattern = this.props.pattern
    let id = this.state.id

    this.setState({ value: e.target.value, count: e.target.value.length })

    // Check input required
    if (required === 'true') {
      if (value.length === 0) {
        this.setValid(id, 'invalid')
        this.setState({ msgValid: true, msgPattern: false, msgMatch: false })
      } else {
        this.setState({ msgValid: false })

        this.validatePattern(required, type, value, id, min, max, digit, matchFirstId, matchLastId, pattern)
      }

    } else {
      if (required) {
        this.validateEmail(required, value, id)
      } else {
        if (value === '') {
          this.setState({ msgPattern: false })
          this.setValid(id, 'valid')
        } else {
          this.validatePattern(required, type, value, id, min, max, digit, matchFirstId, matchLastId, pattern)
        }
      }
    }
  }

  componentDidMount() {
    this.setValid(this.state.id, '');

    if (this.props.value) {
      let value = this.props.value;
      let required = this.props.required
      let type = this.props.type
      let min = this.props.min
      let max = this.props.max
      let digit = this.props.digit
      let matchFirstId = this.props.matchFirstId
      let matchLastId = this.props.matchLastId
      let pattern = this.props.pattern
      let id = this.state.id

      // Check input required
      if (required === 'true') {
        if (value.length === 0) {
          this.setValid(id, 'invalid')
          this.setState({ msgValid: true, msgPattern: false, msgMatch: false })
        } else {
          this.validatePattern(required, type, value, id, min, max, digit, matchFirstId, matchLastId, pattern)
        }

      } else {
        if (required) {
          this.validateEmail(required, value, id)
        } else {
          if (value === '') {
            this.setState({ msgPattern: false })
            this.setValid(id, 'valid')
          } else {
            this.validatePattern(required, type, value, id, min, max, digit, matchFirstId, matchLastId, pattern)
          }
        }
      }
    }
  }

  render() {
    const validStatus = this.state['valid_' + this.state.id]
    const styleInput = {
      paddingRight: (this.props.iconValid || this.props.tooltip ? '30px' : ''),
      boxSizing: (this.props.iconValid || this.props.tooltip ? 'inherit' : ''),
      height: this.props.height
    }

    const styleContainer = {
      marginTop: this.state.marginTop,
      marginBottom: validStatus === 'invalid' ? '25px' : '0'
    }

    return (
      <div className="input-field" style={styleContainer}>


        { !this.props.labelHide &&
          <label htmlFor={this.state.id} className={`${this.props.value || this.props.active ? 'active' : null}`} style={{ position: (!this.props.style ? 'absolute' : 'static'), left: '0', width: '100%' }}>
            <span className={`${this.state['valid_' + this.state.id] === 'invalid' && 'red-text'} ${this.state['valid_' + this.state.id] === 'valid' && 'green-text'}`}>
              {this.props.label} {this.props.unitName && this.props.unitName}
            </span>

            { this.props.required === 'true' &&
              <span className="star-required"> *</span>
            }

            <div className={`count-value font-0-9 ${this.state['valid_' + this.state.id] === 'invalid' && 'red-text'} ${this.state['valid_' + this.state.id] === 'valid' && 'green-text'}`}>
              {this.props.iconValid && this.state['valid_' + this.state.id] === 'valid' && this.props.style &&
                <i className="material-icons right" style={{ color: '#4CAF50', marginLeft: '5px', marginTop: '-3px' }}>check_circle</i>
              }
            </div>
          </label>
        }


        {this.props.icon ? <i className="material-icons prefix">{this.props.icon}</i> : null }

        <input
          data-value-array-name={this.props.valueArrayName}
          data-parent-name={this.props.parentName}
          name={this.props.type}
          ref={this.state.id}
          id={this.state.id}
          data-id={this.props.id}
          type={this.props.inputType}
          onKeyUp={this.handleChange}
          defaultValue={this.props.value}
          className={`${this.props.style && 'input-new'} ${this.props.required === 'true' ? 'required' : ''} ${this.state['valid_' + this.state.id]}`}
          autoComplete="off"
          autoFocus={this.props.autoFocus ? true : false}
          placeholder={this.props.placeholder}
          style={styleInput}
        />


        { this.props.iconValid && this.props.tooltip ?
          <div style={{ position: 'absolute', right: '0', top: '10px' }}>
            { this.state['valid_' + this.state.id] !== 'valid' ?
              <TooltipIcon icon="info_outline" msg={this.props.tooltip} />
            : null }

            { this.state['valid_' + this.state.id] === 'valid' ?
              <i className="material-icons" style={{ color: '#4CAF50' }}>check_circle</i>
            : null }
          </div>
        : null }

        { !this.props.iconValid && this.props.tooltip && !this.props.style ?
          <div style={{ position: 'absolute', right: '0', top: '10px' }}>
            <TooltipIcon icon="info_outline" msg={this.props.tooltip} />
          </div>
        : null }

        { this.props.iconValid && !this.props.tooltip && !this.props.style ?
          <div style={{ position: 'absolute', right: '0', top: '10px' }}>
            { this.state['valid_' + this.state.id] === 'valid' ?
              <i className="material-icons" style={{ color: '#4CAF50' }}>check_circle</i>
            : null }
          </div>
        : null }

        {/* !this.props.style &&
          <label htmlFor={this.state.id} className={`${this.props.value ? 'active' : null}`} style={{ left: '0', width: '100%' }}>
            <span className={`${this.state['valid_' + this.state.id] === 'invalid' && 'red-text'} ${this.state['valid_' + this.state.id] === 'valid' && 'green-text'}`}>
              {this.props.label}
            </span>

            {this.props.required === 'true' && this.props.starRequired ? <b className="star-required"> *</b> : null }

            {this.props.max &&
              <div className={`count-value ${this.state.count > this.props.max && 'red-text'}`}>
                {this.state.count}/{this.props.max}
              </div>
            }
          </label>
        */}


        <div>
          <div style={{ width: this.props.max ? '50%' : '100%', float: 'left' }}>
            <span className="error-input">
              { this.props.showError !== 'false' &&
                <div>
                  <span style={{ marginLeft: `${!this.props.icon ? '0px' : ''}`}} htmlFor={this.state.id + '-required-valid'} className={`error-message ${!this.state.msgValid ? 'hide' : ''}`}>
                    {this.props.label + ' ต้องไม่ว่าง '}
                  </span>
                  <span style={{ marginLeft: `${!this.props.icon ? '0px' : ''}`}} htmlFor={this.state.id + '-required-pattern'} className={`error-message ${!this.state.msgPattern ? 'hide' : ''}`}>
                    {this.props.label + ' '+ this.props.msgError }
                  </span>
                </div>
              }
            </span>
          </div>

          <div style={{ width: '50%', float: 'left' }}>
            <div className={`count-input font-0-8 mg-top--15 ${this.state['valid_' + this.state.id] === 'invalid' && 'red-text'} ${this.state['valid_' + this.state.id] === 'valid' && 'green-text'}`}>
              {this.props.max &&
                <span>{this.state.count}/{this.props.max}</span>
              }

              {this.props.iconValid && this.state['valid_' + this.state.id] === 'valid' && this.props.style &&
                <i className="material-icons right" style={{ color: '#4CAF50', marginLeft: '5px', marginTop: '-3px' }}>check_circle</i>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}



export default TextInput;

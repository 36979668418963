import React from 'react'
import { Footer } from './Ui'

const ConnectPlatform = (props) => {
  const { platform, footerComp, step } = props

  return (
    <div>
      <div className="flx-column items-center">
        <div className="overflow-hidden border-ddd border-1 height-100 width-100 bg-fff border-radius-5 dp-flex flex-direction-column justify-content-center align-items-center">
          <i className={`${platform.icon} font-5-0`} style={{ color: platform.color }}></i>
        </div>
      </div>

      <div className="mg-top-50">
        <div className="mg-bottom-15">
          <b className="font-1-0">โปรดอ่านก่อนทำการเชื่อมต่อ</b>
        </div>

        <div className="mg-bottom-30">
          { platform.id === 'line-oa' &&
            <div>
              <div>1. ผู้ดำเนินการเชื่อมต่อจำเป็นต้องมีบทบาทใน <b>LINE OA</b> ที่จะนำมาเชื่อมต่อดังนี้</div>
              <div className="pd-10 bg-eee border-radius-5 mg-top-5"><b>แอดมิน (Administrator)</b></div>
            </div>
          }

          { platform.id === 'facebook-page' &&
            <div>
              <div>1. ผู้ดำเนินการจะต้องมีบทบาทใน <b>Facebook Page</b> ที่จะนำมาเชื่อมต่อดังนี้</div>
              <div className="pd-10 bg-eee border-radius-5 mg-top-5"><b>ผู้ดูแล (Admin), ผู้แก้ไข (Editor) หรือผู้ควบคุม (Moderator)</b></div>
            </div>
          }

          { platform.id === 'instargram' &&
            <div>
              <div>1. ผู้ใช้งานจำเป็นต้องนำบัญชีของ <b>Instagram ผูกเข้ากับ Facebook Page</b> ก่อนทำการเชื่อมต่อกับ Addner Chat (ตามข้อกำหนดของ Facebook)</div>
              <div className="mg-top-10">2. ผู้ดำเนินการจะต้องมีบทบาทใน <b>Facebook Page</b> ที่จะนำมาเชื่อมต่อดังนี้</div>
              <div className="pd-10 bg-eee border-radius-5 mg-top-5"><b>ผู้ดูแล (Admin), ผู้แก้ไข (Editor) หรือผู้ควบคุม (Moderator)</b></div>
            </div>
          }
        </div>

        <div className="mg-bottom-15">
          <b className="font-1-0">หลังการเชื่อมต่อสำเร็จผู้ใช้งานจะสามารถ</b>
        </div>

        <div>
          { platform.id === 'line-oa' &&
            <div>
              <List check msg="ตอบกลับข้อความลูกค้าใน LINE OA ผ่านทาง Addner Chat" />
              <List check msg="ใช้งานแชทบอทเพื่อช่วยตอบกลับลูกค้าในแชทได้ 24 ชั่วโมง" />
              <List close msg="ไม่สามารถตอบลูกค้าผ่าน LINE OA Manager และแอพพลิเคชัน LINE OA ให้ใช้แอป Addner Chat แทน" />
            </div>
          }

          { platform.id === 'facebook-page' &&
            <div>
              <List check msg="ตอบกลับข้อความลูกค้าใน Messenger ผ่านทาง Addner Chat" />
              <List check msg="อ่านข้อความและบทสนทนาจากลูกค้าที่ติดต่อเข้ามาใน 30 วันย้อนหลัง" />
              <List check msg="สร้างรายชื่อและดึงข้อมูลประวัติของลูกค้าที่ติดต่อเข้ามาใน 30 วันย้อนหลัง" />
              <List check msg="ใช้งานแชทบอทเพื่อช่วยตอบกลับลูกค้าในแชทและใต้คอมเมนต์ได้ 24 ชั่วโมง" />
            </div>
          }

          { platform.id === 'instargram' &&
            <div>
              <List check msg="ตอบกลับข้อความลูกค้าใน Instagram ผ่านทาง Addner Chat" />
              <List check msg="อ่านข้อความและบทสนทนาจากลูกค้าที่ติดต่อเข้ามาใน 30 วันย้อนหลัง" />
              <List check msg="สร้างรายชื่อและดึงข้อมูลประวัติของลูกค้าที่ติดต่อเข้ามาใน 30 วันย้อนหลัง" />
              <List check msg="ใช้งานแชทบอทเพื่อช่วยตอบกลับลูกค้าในแชทและใต้คอมเมนต์ได้ 24 ชั่วโมง" />
            </div>
          }
        </div>
      </div>

      <div>
        { props.useFooter &&
          <Footer {...props}>
            <a onClick={props.onConnect} className='btn btn-shadow-none height-40 line-height-40 width-full' style={{ background: platform.color }}>
              <i className={`${platform.icon} font-1-5 color-fff left`}></i> เชื่อมต่อกับ {platform.name}
            </a>
          </Footer>
        }
      </div>
    </div>
  )
}

const List = (props) => {
  return (
    <div className="flx-row">
      <div className="width-40">
        { props.check && <i className="material-icons color-success">check</i> }
        { props.close && <i className="material-icons color-error">close</i> }
      </div>
      <div className="width-full">
        <div>{props.msg}</div>
      </div>
    </div>
  )
}

export default ConnectPlatform

import React from 'react'

// Components Global
import ModalDefault from '../ModalDefault'

// css files
import css from './css/modal.css'


const ModalFixedDefault = (props) => {
  return (
    <ModalDefault fixed {...props}>
      <div className={css.fullLayout}>
        { props.children }
      </div>
    </ModalDefault>
  )
}

export default ModalFixedDefault

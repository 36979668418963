import React, { Fragment } from 'react'
import { NavLink, Link, withRouter } from 'react-router-dom'

// Components Global
import BtnSubmit from '../button/BtnSubmit'
import { BtnModalOpen } from '../Modals/BtnModal'

// Utils
import { checkObjectId } from '../../util/checkObjectId'

// css
import css from './css/menuSidebarResponsive.css'

// Components Global
import { LinkTransition } from '../Link'

const LinkCondition = (props) => {
  let item = props.item


  if (item.transition) {
    let active = props.location.pathname === item.link ? css.active : ''

    return (
      <LinkTransition transition={item.transition} to={item.link}>
        <a className={`waves-effect ${active}`}>
          { props.children }
        </a>
      </LinkTransition>
    )
  } else {
    const { location } = props

    if (item.searchLinkActive) {
      const getNavLinkClass = () => {
        if (location.pathname.search(item.searchLinkActive) !== -1) {
          return css.active
        } else {
          return ''
        }
      }

      return (
        <NavLink exact={item.exact} to={item.link} className={getNavLinkClass()}>
          { props.children }
        </NavLink>
      )

    } else if (item.linksActive) {
      const linksActive = item.linksActive || []

      linksActive.map(link => {
        if (link.url.search(':objectId') !== -1) {
          const url = link.url.replace(':objectId', '')
          const params = location.pathname.replace(url, '')

          if (checkObjectId(params)) {
            link.url = link.url.replace(':objectId', params)
          }
        }
      })

      const active = linksActive.filter(link => link.url === location.pathname)[0]

      return (
        <NavLink exact={item.exact} to={item.link} className={active ? css.active : ''}>
          { props.children }
        </NavLink>
      )

    } else {
      return (
        <NavLink exact={item.exact} to={item.link} activeClassName={css.active} className="waves-effect">
          { props.children }
        </NavLink>
      )
    }
  }
}

const MenuSidebarResponsive = (props) => {
  const items = props.items && props.items.filter(item => item.hidden !== true)



  return (
    <div>

      { props.btnAddSubmit &&
        <div>
          <div className="pd-10 mg-10 border-radius-3 hidden-xxs hidden-xs hidden-sm hidden-md bg-eee">
            <BtnSubmit
              onClick={props.btnAddSubmit.onClick}
              loadingId={props.btnAddSubmit.loadingId}
              icon="add_circle_outline"
              iconClassName="left font-1-7"
              text={props.btnAddSubmit.text}
              className="btn btn-shadow-none width-full pd-0-md center font-0-9"
            />
          </div>

          <div className="pd-10 pd-top-5 hidden-lg hidden-xlg">
            <BtnSubmit
              responsive
              onClick={props.btnAddSubmit.onClick}
              loadingId={props.btnAddSubmit.loadingId}
              icon="add_circle_outline"
              iconClassName="font-1-7"
              className="btn btn-shadow-none width-full pd-0-md center font-0-9"
            />
          </div>
        </div>
      }

      { props.btnAddModal &&
        <div>
          <div className="pd-10 mg-10 border-radius-3 hidden-xxs hidden-xs hidden-sm hidden-md bg-eee">
            <BtnModalOpen id={props.btnAddModal.modalId}>
              <button className="btn-shadow-none waves-light btn width-full pd-0-md center-md font-0-9">
                <i className="material-icons left-lg left-xlg font-1-7">add_circle_outline</i> {props.btnAddModal.text}
              </button>
            </BtnModalOpen>
          </div>

          <div className="pd-10 pd-top-5 hidden-lg hidden-xlg">
            <BtnModalOpen id={props.btnAddModal.modalId}>
              <button className="btn-shadow-none waves-light btn width-full pd-0-md center-md font-0-9">
                <i className="material-icons font-1-7">add_circle_outline</i>
              </button>
            </BtnModalOpen>
          </div>
        </div>
      }

      <ul className={css.menu}>
        { items && items.map((item, key) => {
            return (
              <div key={key}>
                { item.title ?
                  <li className={css.title}>
                    <span className={css.nameFull}>{item.name}</span>
                    <span className={css.nameShort}>{item.nameShort || item.name}</span>
                  </li>
                :
                  <li>
                    <LinkCondition item={item} location={props.location} match={props.match}>

                      { item.iconCircle ?
                        <div className="height-full">
                          <div className={css.iconCircleFull}>
                            <div className="box-middle height-full">
                              <div>
                                <table>
                                  <tbody>
                                    <tr>
                                      <td className="pd-0" width="32">
                                        <div className="icon-color-32 circle" style={{ background: item.iconCircle }}>
                                          <i className="material-icons i-middle font-1-3 mg-right-0 mg-top-0">{item.icon}</i>
                                        </div>
                                      </td>
                                      <td className="pd-0 pd-left-10">
                                        <span className={css.nameFull}>{item.name}</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>

                          <div className={css.iconCircleShort}>
                            <div className="box-middle center height-full">
                              <div>
                                <div className="icon-color-30 mg-center circle" style={{ background: item.iconCircle }}>
                                  <i className="material-icons i-middle font-1-3 mg-right-0 mg-top-1">{item.icon}</i>
                                </div>

                                <span className={`${css.nameShort} mg-top-0`}>{item.nameShort || item.name}</span>
                              </div>
                            </div>
                          </div>

                        </div>
                      :
                        <div className={css.boxListMenu}>

                          { item.badge && item.badge.amount > 0 &&
                            <div className="hidden-lg hidden-xlg center pd-left-25">
                              <b className="badge-bg font-13" style={{ lineHeight: 'initial', background: item.badge.bg }}>
                                {item.badge.amount}
                              </b>
                            </div>
                          }

                          { item.iconFa ?
                            <i className={`${item.iconFa} font-1-3 left i-middle mg-right-20`}></i>
                          :
                            <i className="material-icons left i-middle mg-right-20">{item.icon}</i>
                          }

                          <span className={css.nameFull}>{item.name}</span>
                          <span className={css.nameShort}>{item.nameShort || item.name}</span>

                          { item.badge && item.badge.amount > 0 &&
                            <div className="hidden-xxs hidden-xs hidden-sm hidden-md height-45 line-height-45 right pd-right-10">
                              <b className="badge-bg font-13" style={{ lineHeight: 'initial', background: item.badge.bg }}>
                                {item.badge.amount}
                              </b>
                            </div>
                          }

                          { item.iconStatus === 'success' &&
                            <i title="เพื่มข้อมูลแล้ว" className={`material-icons i-middle right`} style={{ color: '#7cb342', fontSize: '1.4rem',  position: 'absolute', right: '-5px' }}>check_circle</i>
                          }

                          { item.iconStatus === 'warning' &&
                            <i title="ไม่บังคับเพื่มข้อมูล" className={`material-icons i-middle right`} style={{ color: '#ff9800', fontSize: '1.4rem', position: 'absolute', right: '-5px' }}>info_outline</i>
                          }

                          { item.iconStatus === 'error' &&
                            <i title="จำเป็นต้องเพิ่มข้อมูล" className={`material-icons i-middle right`} style={{ color: '#F44336', fontSize: '1.4rem', position: 'absolute', right: '-5px' }}>cancel</i>
                          }
                        </div>
                      }

                    </LinkCondition>
                  </li>
                }
              </div>
            )
          })
        }
      </ul>
    </div>
  )
}

export default withRouter(MenuSidebarResponsive)

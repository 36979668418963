import React, { Fragment } from 'react'

// Components Global
import AvatarIcon from '../../../../../../../components/AvatarIcon'


const CustomerItemProfileAvater = (props) => {
  const { item } = props

  return (
    <Fragment>
      { item &&
        <AvatarIcon
          bgColor="#efefef"
          circle
          iconClassName={`${props.fontClassName || 'font-1-5'} color-333`}
          iconFa={item.typeIcon}
        />
      }
    </Fragment>
  )
}

export default CustomerItemProfileAvater

import React from 'react'

// Components Customers
import { CustomerShort} from '../../../../Customers/components/CustomersUserInterface'

// Redux
import { getTotalPriceAll } from '../../../../../../redux/Orders/OrdersValues'

// utity
import { getDate } from '../../../../../../util/getDateTime/getDateTime'
import { getNumeral } from '../../../../../../util/getNumeral'

// Values
import { billValue } from '../../../../../../redux/Bills/BillsValues'



export const BillItem = (props) => {
  const { business, history, location, match, openSidebar } = props
  const { bills_id, type } = match.params

  let bill = props.data
  bill = billValue({ bill })

  const goto = () => {
    let path = `/${business.username}/bills/${type}/${bill._id}`

    if (location.search) {
      path = `${path}${location.search}`
    }

    openSidebar()
    history.push(path)
  }

  return (
    <tr
      style={{ userSelect: 'none' }}
      onClick={() => goto()}
      className={bills_id === bill._id ? 'trActive' : ''}>

      <td width="5" className="pd-0" style={{ background: bill.billTypeColor }}></td>
      <td className="font-0-9 pd-10 hidden-xlg">
        <div>เลขที่ : { bill.code }</div>
        <div>วันที่ : { getDate(bill.startDate, 'DD/MM/YYYY', 'th') }</div>
        <div>ยอดชำระ : ฿{ getNumeral(bill.totalNetAmount, 'auto') }</div>
      </td>

      <td className="font-0-9 pd-10  hidden-lg hidden-md hidden-sm hidden-xs hidden-xxs">
        <div>#{bill.code}</div>
        <div className="font-0-8">วันที่ {getDate(bill.startDate, 'DD/MM/YYYY', 'th')} </div>

        {/* bill.order && bill.order.code &&
          <div className="font-0-6">ref {bill.order.code}</div>
        */}
      </td>

      <td className="pd-0">
        <span className={`badge-border`} style={{ color: bill.billTypeColor, border: `1px solid ${bill.billTypeColor}` }}>
          { bill.billTypeName }
        </span>
      </td>

      <td className="pd-5 center hidden-xxs hidden-xs">
        <CustomerShort customer={bill.customer} />
      </td>

      <td className="text-right font-0-9 pd-10 hidden-lg hidden-md hidden-sm hidden-xs hidden-xxs">
        <div className="color-price font-1-1">฿{ getNumeral(bill.totalNetAmount, 'auto') }</div>
        <div className="font-0-8">ยอดรวม</div>
      </td>
      <td className="pd-5"></td>
    </tr>
  )
}

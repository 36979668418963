import React, { Component, Fragment } from 'react'

// Components Global
import TextInput from '../../../../../components/Inputs/TextInput'
import InputFile from '../../../../../components/Inputs/InputFile'
import InputCheckDuplicate from '../../../../../components/Inputs/InputCheckDuplicate'
import Textarea from '../../../../../components/Inputs/Textarea'
import RadioGroup from '../../../../../components/Inputs/RadioGroup'
import InputSelectFetchData from '../../../../../components/Inputs/InputSelectFetchData'
import SelectData from '../../../../../components/Inputs/SelectData'
import Select from '../../../../../components/Inputs/Select'

import {
  AlbumLabel,
  AlbumCode,
  AlbumMultiple,
  AlbumCrop
} from '../../../Settings/components/AlbumForms'

// Components Actions
import { fetchAttributes, checkValueDuplicate } from '../../../../../redux/Attributes/AttributesActions'

// Values
import { attributeChoiceValue } from '../../../../../redux/Attributes/AttributesValues'


export const AttributeName = (props) => {
  return (
    <TextInput
      labelHide={props.update}
      label="ชื่อคุณลักษณะ"
      id="name.local"
      value={props.value}
      placeholder="ชื่อคุณลักษณะ"
      inputType="text"
      type="text"
      required
      iconValid
    />
  )
}

export const AttributeType = (props) => {
  return (
    <Select
      required
      id="type"
      label="ประเภทคุณลักษณะ"
      placeholder="ประเภทคุณลักษณะ"
      value={props.value}
      options={[
        { value: '', name: 'เลือกประเภท' },
        { value: 'product', name: 'สินค้า' },
        { value: 'article', name: 'บทความ' },
        { value: 'note', name: 'บันทึก' },
      ]}
    />
  )
}

export const AttributePath = (props) => {
  const { business, value, attribute, onChange } = props
  const query =`businesses_id=${business._id}`

  let unselectId

  if (attribute && attribute._id) {
    unselectId = attribute._id
  }

  return (
    <InputCheckDuplicate
      id="path"
      label="URL คุณลักษณะ"
      type="path"
      value={value}
      action={checkValueDuplicate}
      query={query}
      unselectId={unselectId}
      onChange={onChange}
      required
      iconValid
    />
  )
}

export const AttributeShowMain = (props) => {
  return (
    <RadioGroup
      required
      label="แสดงในหน้าแดชบอร์ด"
      id="showMain"
      className="mg-bottom-20"
      value={props.value !== undefined ? props.value.toString() : 'false'}
      radios={[
        {
          value: 'false',
          label: 'ไม่แสดงในหน้าแดชบอร์ด'
        },
        {
          value: 'true',
          label: 'แสดงในหน้าแดชบอร์ด'
        }
      ]}
    />
  )
}

export const AttributeItemName = (props) => {
  return (
    <TextInput
      labelHide={props.update}
      label="ชื่ออินพุต"
      id="name.local"
      value={props.value}
      placeholder="ชื่ออินพุต"
      inputType="text"
      type="text"
      iconValid
    />
  )
}

export const AttributeItemLabel = (props) => {
  return (
    <TextInput
      labelHide={props.update}
      label="ชื่ออินพุต"
      id="label.local"
      value={props.value}
      placeholder="ชื่ออินพุต"
      inputType="text"
      type="text"
      required
      iconValid
    />
  )
}

export const AttributeItemCode = (props) => {
  return (
    <TextInput
      labelHide={props.update}
      label="รหัสอินพุต"
      id="code"
      value={props.value}
      placeholder="รหัสอินพุต"
      inputType="text"
      type="text"
      required
      iconValid
    />
  )
}

export const AttributeItemInputRequired = (props) => {
  let value

  if (props.value !== undefined) {
    value = props.value.toString()
  } else {
    value = 'false'
  }

  return (
    <RadioGroup
      required
      label="บังคับกรอกข้อมูล"
      id="inputRequired"
      className="mg-bottom-20"
      value={value}
      radios={[
        {
          value: 'true',
          label: 'บังคับกรอกข้อมูล'
        },
        {
          value: 'false',
          label: 'ไม่บังคับกรอกข้อมูล'
        }
      ]}
    />
  )
}

export const AttributeItemInputType = (props) => {
  return (
    <RadioGroup
      required
      label="ประเภทอินพุต"
      id="inputType"
      className="mg-bottom-20"
      value={props.value || ''}
      radios={[
        {
          value: 'string',
          label: 'ตัวอักษร'
        },
        {
          value: 'number',
          label: 'ตัวเลข'
        },
        {
          value: 'string-lang',
          label: 'ตัวอักษร (รองรับหลายภาษา)'
        },
        {
          value: 'text-lang',
          label: 'ข้อความหลายตัวอักษร (รองรับหลายภาษา)'
        },
        {
          value: 'choice',
          label: 'ตัวเลือก (choice)'
        },
        {
          value: 'boolean',
          label: 'ตัวเลือกจริงเท็จ (boolean)'
        },
        {
          value: 'img-file',
          label: 'รูปภาพ',
          child: <AttributeItemAlbum />
        },
        {
          value: 'geo',
          label: 'ที่อยู่ (geo)'
        },
      ]}
    />
  )
}

export const AttributeItemAlbum = (props) => {
  return (
    <div className="pd-left-30">
      <AlbumLabel id="album" />
      <AlbumCode id="album" />
      <AlbumMultiple id="album" />
      <AlbumCrop id="album" />
    </div>
  )
}

export const AttributeItemSearch = (props) => {
  const { type } = props

  let value

  if (props.value !== undefined) {
    value = props.value.toString()
  } else {
    value = 'false'
  }

  let id = 'useSearch'

  return (
    <RadioGroup
      required
      label={`กำหนดค่าให้มีการค้นหา`}
      id={id}
      className="mg-bottom-20"
      value={value}
      radios={[
        {
          value: 'false',
          label: `ไม่กำหนดค่าให้มีการค้นหา`
        },
        {
          value: 'true',
          label: `กำหนดค่าให้มีการค้นหา`
        }
      ]}
    />
  )
}

export const AttributeItemShow = (props) => {
  const { type } = props

  let value

  if (props.value !== undefined) {
    value = props.value.toString()
  } else {
    value = 'false'
  }

  let typeName = '', id

  if (type === 'title') {
    typeName = 'หัวข้อ'
    id = "showTitle"
  } else if (type === 'descriptions') {
    typeName = 'รายละเอียด'
    id = "showDescriptions"
  }

  return (
    <RadioGroup
      required
      label={`แสดงเป็น${typeName}ในหน้าจัดการ`}
      id={id}
      className="mg-bottom-20"
      value={value}
      radios={[
        {
          value: 'false',
          label: `ไม่แสดงเป็น${typeName}`
        },
        {
          value: 'true',
          label: `แสดงเป็น${typeName}`
        }
      ]}
    />
  )
}

export const AttributeChoiceName = (props) => {
  return (
    <TextInput
      labelHide={props.update}
      label="ชื่อตัวเลือก"
      id="label.local"
      value={props.value}
      placeholder="ชื่อตัวเลือก"
      inputType="text"
      type="text"
      required
      iconValid
    />
  )
}

export const AttributeChoiceCode = (props) => {
  return (
    <TextInput
      labelHide={props.update}
      label="รหัสตัวเลือก"
      id="code"
      value={props.value}
      placeholder="รหัสตัวเลือก"
      inputType="text"
      type="text"
      required
      iconValid
    />
  )
}

export const AttributesId = (props) => {
  const fetchAction = {
    authName: 'admin',
    action: fetchAttributes,
    id: props.business._id,
    where: `type=${props.type}`,
    sort: '-_id'
  }

  return (
    <InputSelectFetchData
      required={props.required}
      label="ประเภทข้อมูลเพิ่มเติม"
      id="attributes_id"
      value={props.value}
      fetchAction={fetchAction}
      onChange={props.onChange}
      storeName="attributes"
      attribute={{
        labelLang: 'local',
        label: 'name',
        value: '_id'
      }}
      search={[
        { name: 'name.local' },
        { name: 'name.en' }
      ]}
    />
  )
}

export const AttributeInputs = (props) => {
  const { data } = props
  const attributeItems = data.attributeItems

  return (
    <Fragment>
      { attributeItems.map(attribute => {
        return (
          <Fragment key={attribute.item._id}>
            { attribute.item.inputType === 'choice' &&
              <AttributeInputChoice data={data} attribute={attribute} />
            }

            { attribute.item.inputType === 'boolean' &&
              <AttributeInputBoolean data={data} attribute={attribute} />
            }

            { attribute.item.inputType === 'string' &&
              <AttributeInputString data={data} attribute={attribute} />
            }

            { attribute.item.inputType === 'img-file' &&
              <AttrInputImgFile data={data} attribute={attribute} />
            }

            { attribute.item.inputType === 'number' && <div>number</div> }
          </Fragment>
        )
      }) }
    </Fragment>
  )
}

const AttrInputImgFile = (props) => {
  const { attribute } = props
  const { item } = attribute

  return (
    <InputFile
      label={attribute.label}
      id={item._id}
      value={attribute.value}
      placeholder={attribute.label}
    />
  )
}

const AttributeInputString = (props) => {
  const { attribute } = props
  const { item } = attribute

  return (
    <TextInput
      label={attribute.label}
      id={item._id}
      value={attribute.value}
      placeholder={attribute.label}
      inputType="text"
      type="text"
      iconValid
    />
  )
}

const AttributeInputBoolean = (props) => {
  const { attribute } = props
  const { item } = attribute

  return (
    <Select
      active
      id={item._id}
      label={attribute.label}
      placeholder={attribute.label}
      placeholderDisabled
      value={attribute.value}
      options={[
        { value: 'false', name: 'ไม่ (เท็จ)' },
        { value: 'true', name: 'ใช่ (จริง)' },
      ]}
    />
  )
}

const AttributeInputChoice = (props) => {
  const { data, attribute } = props
  const { item } = attribute
  const dataAttribute = data.attributes_id
  const choices = dataAttribute && dataAttribute.choices.filter(choice => choice.items_id === item._id)
  const datas = []

  choices.map(choice => {
    choice = attributeChoiceValue(dataAttribute, choice)

    datas.push({
      label: choice.nameLang,
      value: choice._id,
      profileAvatar: {
        images: choice.images,
        color: choice.avatarColor,
        texts: [choice.nameLang]
      },
      obj: choice
    })
  })

  return (
    <SelectData
      avatar
      showLabel
      id={item._id}
      label={attribute.label}
      placeholder={attribute.label}
      value={attribute.valueId}
      datas={datas}
    />
  )
}





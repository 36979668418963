import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// Components Global
import Switch from '../../../../../components/Inputs/Switch'
import Section from '../../../../../components/Section'

// Functions
import { handleSwitchChange } from '../../../../../redux/Folders/FoldersFunctions'



const FolderDescriptions = (props) => {
  const lang = props.lang || 'local'
  const { data, foldersManage } = props

  return (
    <div className="bg-fff">
      <div className="pd-top-20 center">
        <i className="material-icons font-7-0 i-middle" style={{ color: '#ffca00' }}>folder</i>
      </div>

      <div className="pd-10 pd-top-0 center mg-bottom-20">
        <div className="font-1-3">
          { data.name[lang] }
        </div>
        <div className="font-0-8 color-9e9e9e">หมวดหมู่</div>

        <div className="mg-top-30">
          <Link to={`${foldersManage.startUrl}/${data._id}`} className="waves-effect btn-shadow-none waves-light btn btn-edit btn-md pd-right-10">
            <i className="material-icons left">folder</i> เปิดโฟล์เดอร์
          </Link>
        </div>
      </div>

      <Section mini title="การตั้งค่า" bodyClassName="pd-10 font-0-8">
        <table>
          <tbody>
            <tr>
              <td className="pd-5">แสดงบนเว็บไซต์</td>
              <td className="pd-5 width-150 text-right">
                <Switch
                  id={data._id}
                  value={data.showOnWeb ? true : false}
                  confirm={{
                    label: data.showOnWeb ? 'ซ่อนหมวดหมู่ในเว็บไซต์' : 'แสดงหมวดหมู่ในเว็บไซต์',
                    type: data.showOnWeb ? 'warning' : 'success',
                    icon: data.showOnWeb ? 'lock' : 'public',
                    onConfirm: handleSwitchChange({
                      props,
                      params: {
                        showOnWeb: data.showOnWeb ? false : true
                      },
                      type: 'product',
                      toastMsg: data.showOnWeb ? 'ซ่อนหมวดหมู่ในเว็บไซต์แล้ว' : 'แสดงหมวดหมู่ในเว็บไซต์แล้ว'
                    })
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Section>
    </div>
  )
}

export default connect()(FolderDescriptions)

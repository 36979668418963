import React from 'react'
import { connect } from 'react-redux'
import Scrollbar from 'react-perfect-scrollbar'
import renderRoutes from '../../../../routes/renderRoutes'

// Components Global
import Window from '../../../../components/WindowsV2/Window'

// imgs
import titleIcon from '../resources/imgs/pos-96.png'
import { LayoutSingle } from '../../../../components/LayoutsTwoResponsive'


const POSMainContainer = (props) => {
  const { route, match, business, setting, location, windowModal, closeWindowModal, history } = props

  return (
    <Window
      title="ขายหน้าร้าน"
      titleIcon={titleIcon}
      linkClose={`/${match.params.username}`}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkHelp="https://www.youtube.com/playlist?list=PLHuj62tDAxzNdPnfzc418tAo3phAP62if">

      <LayoutSingle>
        { renderRoutes({
          routes: route.routes,
          extraProps: { business, setting },
          location,
        }) }
      </LayoutSingle>
    </Window>
  )
}

export default connect()(POSMainContainer)

import React, { Fragment } from 'react'

// Components Global
import AvatarIcon from '../../../../../../../components/AvatarIcon'

// Values
import { customerValue } from '../../../../../../../redux/Customers/CustomersValues'


const CustomerProfileAvater = (props) => {
  let { customer } = props

  if (customer && !customer.nameLang) {
    customer = customerValue({ customer })
  }

  return (
    <Fragment>
      { customer &&
        <AvatarIcon
          bgColor="#efefef"
          circle={props.circle}
          iconClassName={`${props.fontClassName || 'font-1-5'} color-333`}
          iconFa={customer.typeIcon}
        />
      }
    </Fragment>
  )
}

export default CustomerProfileAvater

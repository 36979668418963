import React from 'react'
import { connect } from 'react-redux'

import renderRoutes from '../../../../routes/renderRoutes'
import WindowDefault from '../../../../components/Windows/WindowDefault'

// Values
import { attributeValue } from '../../../../redux/Attributes/AttributesValues'


const NotesContainer = (props) => {
  let { attribute, route, business, location, windowModal, closeWindowModal } = props
  attribute = attributeValue({ attribute })

  return (
    <WindowDefault windowModal={windowModal}>
      { renderRoutes({
        routes: route.routes,
        extraProps: { business, attribute, windowModal, closeWindowModal },
        location
      }) }
    </WindowDefault>
  )
}

const mapStateToProps = (store, props) => {
  const { business, match } = props
  const { attributes_path } = match.params

  return {
    attribute: store.attributes.data.filter((attribute => attribute.businesses_id === business._id && attribute.path === attributes_path))[0]
  }
}

export default connect(mapStateToProps)(NotesContainer)

// utils
import { getDataByKey } from '../../../../../util/getDataByKey'
import { getURLSearchParams } from '../../../../../util/getURLSearchParams'

export const convertLink = (inlink, apiData) => {
  if (inlink) {
    const split = inlink.split('/')

    split.map(val => {
      const n = val.search(':')

      if (n !== -1) {
        const element = val.replace(':', '')
        const value = apiData[element]

        if (value) {
          inlink = inlink.replace(val, value)
        }
      }
    })
  }

  return inlink
}

export const convertLinkNew = ({ link, apiData, templateData, dataStore, match, location }) => {
  let http, https

  const regexHttp = /^http:\/\//g
  const regexHttps = /^https:\/\//g

  if (link.search(regexHttp) !== -1) {
    http = true
  } else if (link.search(regexHttps) !== -1) {
    https = true
  }

  const split = link.split('/')

  if (apiData || templateData || (match && match.params)) {
    split.map(val => {
      val = val.replace('tel:', '')

      const split2 = val.split('[+]')

      split2.map(val2 => {
        let value

        if (val2.search('@current') !== -1) {
          const { pathname, search } = location
          const { path } = match

          let pathnameNew = pathname
          let langs = path.split('/:lang(')

          if (langs.length === 2 && langs[1]) {
            langs = langs[1].split(')?/')

            if (langs[0]) {
              langs = langs[0].split('|')
            }

            if (langs.length > 0) {
              langs.map(lang => {
                pathnameNew = pathnameNew.replace(`/${lang}`, '')
              })
            }
          }

          value = pathnameNew

          if (search) {
            value = `${value}${search}`
          }

          value = value.replace('/', '')

        } else if (val2.search('@lang') !== -1) {
          const { lang } = match.params

          if (lang) {
            value = lang
          } else {
            value = ''
          }

        } else if (val2.search('@search') !== -1) {
          let search

          if (location && location.search) {
            search = location.search
          }

          if (search) {
            value = search
          } else {
            value = ''
          }

        } else if (val2.search('store-customer:') !== -1 && dataStore) {
          const key = val2.replace('store-customer:', '')
          const data = dataStore.customer
          value = getDataByKey({ key, data })

        // templateData
        } else if (val2.search('tmp:') !== -1) {
          const key = val2.replace('tmp:', '')
          const data = templateData
          value = getDataByKey({ key, data })

        } else {

          // apiData
          if (val2.search('api:') !== -1) {
            const key = val2.replace('api:', '')
            const data = apiData
            value = getDataByKey({ key, data })

          } else {

            // search params
            if (val2.search('sprm:') !== -1) {
              const vSplit = val2.split('||')
              const defaultValue = vSplit[1]

              let key
              //type = 'ddd'

              if (defaultValue) {
                key = vSplit[0].replace('sprm:', '')
              } else {
                key = val2.replace('sprm:', '')
              }

              let search

              // if (typeof window !== 'undefined') {
              //   search = window.location.search
              // }

              if (location && location.search) {
                search = location.search
              }

              const data = getURLSearchParams(search)
              value = getDataByKey({ key, data })

              if (!value && defaultValue) {
                value = defaultValue
              }

            } else {

              // match params
              if (val2.search('prm:') !== -1 && match && match.params) {
                const key = val2.replace('prm:', '')
                const data = match.params
                value = getDataByKey({ key, data })

                if (!value && key === 'path_1') {
                  value = 'index'
                }

                if (key === 'path_1' && value === 'm') {
                  value = ''
                }

                if (key === 'path_2' && value === 'm') {
                  value = ''
                }

              } else {

                // other
                if (val2.search(':') !== -1) {
                  const key = val2.replace(':', '')

                  if (apiData) {
                    const data = apiData
                    value = getDataByKey({ key, data })
                  }

                  if (!value && match && match.params) {
                    const data = match.params
                    value = getDataByKey({ key, data })
                  }
                }
              }
            }
          }
        }

        if (value !== undefined) {
          link = link.replace(val2, value)

        } else {
          if (val2 && val2.search('prm:path_2') !== -1) {
            link = link.replace(`/prm:path_2`, '')
          }
        }

        if (val.search('[+]') !== -1) {
          link = link.replace('[+]', '')
        }
      })
    })
  }


  if (http == true) {
    link = `http:${link}`
  } else if (https == true) {
    link = `https:${link}`
  }

  if (link && link.replaceAll) {
    if (link.replaceAll) {
      link = link.replaceAll('://', '#h#t#t#p#')
      link = link.replaceAll('//', '/')
      link = link.replaceAll('#h#t#t#p#', '://')
    }

    if (link.replace) {
      link = link.replace('://', '#h#t#t#p#')
      link = link.replace(/\/$/, '')
      link = link.replace('#h#t#t#p#', '://')
    }
  }

  return link
}

import React, { Fragment } from 'react'
import { NavLink, Link, withRouter, RouteComponentProps } from 'react-router-dom'

// Components Global
import BtnSubmit from 'components/button/BtnSubmit'
import { BtnModalOpen } from 'components/Modals/BtnModal'

// Utils
import { checkObjectId } from 'utils/checkObjectId'

// css
import css from './css/menu.css'


export type LinksActive = {
  url: string
}

export type Item = {
  name: string
  nameShort?: string
  title?: boolean
  icon?: string
  iconFa?: string
  link?: string
  searchLinkActive?: string
  linksActive?: LinksActive[]
  onClick?: () => void
  exact?: boolean
}

type Props = RouteComponentProps & {
  items: Item[]
}

export const checkMenuActive = (location: any, item: Item, css: any) => {
  if (item.searchLinkActive) {
    if (location.pathname.search(item.searchLinkActive) !== -1) {
      return css.active
    } else {
      return ''
    }

  } else if (item.linksActive) {
    const linksActive = item.linksActive || []

    linksActive.map(link => {
      if (link.url.search(':objectId') !== -1) {
        const url = link.url.replace(':objectId', '')
        const params = location.pathname.replace(url, '')

        if (checkObjectId(params)) {
          link.url = link.url.replace(':objectId', params)
        }
      }
    })

    const active = linksActive.filter(link => link.url === location.pathname)[0]
    return active ? css.active : ''


  } else {
    return location.pathname === item.link ? css.active : ''
  }
}

const Menu: React.FC<Props> = ({ items, match, location, history }) => {

  const handleOnClick = (item: Item) => {
    if (item.link) {
      history.push(item.link)
    } else if (item.onClick) {
      item.onClick()
    }
  }

  return (
    <div>
      { items.map((item, i) => {
        return (
          <Fragment key={i}>
            { item.title ?
              <div className="height-30 mg-bottom-10 border-bottom-1 border-bottom-ddd dp-flex flex-direction-column justify-content-center">
                <span className="font-0-8">{item.name}</span>
              </div>
            :
              <div onClick={() => handleOnClick(item)} className={`${css.list} ${checkMenuActive(location, item, css)} height-40 border-radius-10 cursor-pointer`}>
                <div className={`${css.icon} width-40`}>
                  { item.iconFa ?
                    <i className={`${item.iconFa} font-1-3`}></i>
                  :
                    <i className="material-icons">{item.icon}</i>
                  }
                </div>
                <div className={`${css.name}`}>
                  <span className="font-0-8">{item.name}</span>
                </div>
              </div>
            }
          </Fragment>
        )
      }) }
    </div>
  )
}

export default withRouter(Menu)

import React from 'react'
import renderRoutes from '../../../../routes/renderRoutes'

// Components Global
import Window from '../../../../components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../components/MenuSidebarResponsive'

// Components Places
import PlaceCreateModal from '../components/PlaceCreateModal'

// imgs
import titleIcon from '../resources/imgs/places-96.png'

const menuItems = (params) => {
  return [
    {
      name: 'สถานที่ท่องเที่ยว',
      title: true
    },
    {
      name: 'เปิดใช้งาน',
      icon: 'public',
      link: `/${params.username}/places`,
      exact: true
    },
    {
      name: 'ปิดใช้งาน',
      icon: 'lock',
      link: `/${params.username}/places/close`
    },
    {
      name: 'ที่ถูกลบ',
      icon: 'delete',
      link: `/${params.username}/places/trash`
    }
  ]
}

const PlacesMenuContainer = (props) => {
  return (
    <Window title="สถานที่ท่องเที่ยว" titleIcon={titleIcon} linkClose={`/${props.match.params.username}`}>
      <PlaceCreateModal {...props} />

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive
            btnAddModal={{
              modalId: 'placeCreateModal',
              text: 'เพิ่มสถานที่',
            }}
            items={menuItems(props.match.params)}
          />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: props.route.routes,
            extraProps: { business: props.business },
            location: props.location,
          }) }
        </Layouts2>
      </LayoutsTwo>
    </Window>
  )
}

export default PlacesMenuContainer

import React, { Component } from 'react'

// Components Global
import ModalFixed from '../../../../../components/Modals/ModalFixed'

// Components Local
import ContentGroupPageLists from './components/ContentGroupPageLists'

// Redux Actions
import { updateSite } from '../../../../../redux/Sites/SitesActions'


class ContentGroupPagesManageModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <ModalFixed
        id="contentGroupPagesManageModal"
        title="จัดการหน้าเพจ"
        width="650px"
        headerClassName="height-50"
        bodyClassName="top-50 bottom-5 bg-f5f5f5 pd-0"
        footerClassName="pd-0 height-5"
        padding="0">

        <div className="pd-10">
          <ContentGroupPageLists {...this.props} />
        </div>
        <div></div>
      </ModalFixed>
    )
  }
}

export default ContentGroupPagesManageModal

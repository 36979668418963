import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// Components Global
import Section from '../../../../../components/Section'
import { LayoutsDesign } from '../../../../../components/LayoutsDesign'


// Actions Redux
import { updatePlace, uploadImagesPlace } from '../../../../../redux/Places/PlacesActions'


const PlaceSitePage = (props) => {
  const { match, place, business } = props

  return (
    <div className="container-full-scroll">
      <div className="box border-eee">

        <Section title="ออกแบบเนื้อหา">
          <LayoutsDesign {...props}
            display
            action={{
              params: {
                business: business,
                data: place,
                _id: place._id,
                imagePrefix: 'places'
              }
            }}
          />
        </Section>

        <Link
          to={`/${match.params.username}/places/${place.status}/${match.params.places_id}/update/site-manage`}>
            จักการไซต์
        </Link>

      </div>
    </div>
  )
}

export default connect()(PlaceSitePage)

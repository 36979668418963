import React, { useEffect, useState } from 'react'
import Cleave from 'cleave.js/react'
import cuid from 'cuid'

// Components Global
import InputDisabled from '../InputDisabled'
import Required from '../Required'


const InputDecimal = (props) => {
  const [id, setId] = useState()
  const [value, setValue] = useState('')
  const [reload, setReload] = useState(false)
  const [rawValue, setRawValue] = useState('')
  const [cleave, setCleave] = useState()
  const [digit, setDigit] = useState()
  const [decimal, setDecimal] = useState()
  const [isMounted, setIsMounted] = useState()


  useEffect(() => {
    const id = props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()
    const digit = props.digit && parseFloat(props.digit)
    const decimal = props.decimal && parseFloat(props.decimal)

    setId(randomId+id)
    setDigit(digit)
    setDecimal(decimal)

    setTimeout(() => {
      setIsMounted(true)
    }, 0)

    return () => {
      setIsMounted(false)
    }
  }, [])

  useEffect(() => {
    let value = props.value

    if (!value) {
      value = ''
    }

    setValue(value)
    setRawValue(value)

    if (value) {
      setCleaveFn(value)
    }
  }, [props.value])

  useEffect(() => {
    /*let value
    let maxValue = parseInt(props.maxValue)

    if (maxValue && rawValue) {
      value = rawValue <= maxValue ? rawValue : maxValue
    } else {
      value = rawValue !== '' ? rawValue : ''
    }

    setValue(value)
    setRawValue(value)*/

  }, [rawValue])

  useEffect(() => {
    valueCallback(value)
  }, [value])


  const onChange = (e) => {
    let value = parseFloat(e.target.rawValue)
    let maxValue = parseFloat(props.maxValue)

    if (isNaN(value) || value === undefined) {
      value = ''
    }

    if (value || value === '' || value === 0) {
      if (maxValue && value) {
        value = value <= maxValue ? value : maxValue
      }

      value = (value !== undefined && !isNaN(value)) ? value : ""

      setValue(value)
      setRawValue(value)
      setReload(!reload)
      valueCallback(value)
    }
  }

  const valueCallback = (value) => {
    if (isMounted) {
      value = (value !== undefined && !isNaN(value)) ? value : ''

      if (props.onChange) {
        if (props.i) {
          props.onChange(props.i, value)
        } else {
          props.onChange(value)
        }
      }
    }
  }

  const setCleaveFn = (value) => {
    if (cleave && value !== undefined && !isNaN(value)) {
      cleave.setRawValue(value)
    } else {
      cleave && cleave.setRawValue('')
    }
  }

  const onInit = (cleave) => {
    setCleave(cleave)
  }

  return (
    <div>
      {!props.disabled ?
        <Required
          {...props}
          id={id}
          classId={id}
          className={props.requiredClassName}
          label={props.label}
          unitName={props.unitName}
          type="text"
          value={value}
          required={props.required}
          iconValid={props.iconValid}>

          <div className="mg-top--2">
            <Cleave
              autoFocus={props.autoFocus}
              id={id}
              className={`input ${props.required ? 'required ' : ''} ${props.className} ${props.inputClassName}`}
              placeholder={props.placeholder}
              value={value}
              options={{
                numeral: true,
                numeralIntegerScale: digit,
                numeralDecimalScale: decimal,
                numeralPositiveOnly: true
              }}
              onInit={onInit}
              onChange={onChange}
            />

            <input
              type="hidden"
              value={value}
              data-id={props.id}
              data-not-save={props.notSave}
            />
          </div>
        </Required>
      :
        <InputDisabled {...props} />
      }
    </div>
  )
}

/*
class InputDecimal extends Component {
  constructor(props) {
    super(props)

    const id = this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()

    let value = this.props.value
    value = (value !== undefined && !isNaN(value)) ? value : ''

    this.state = {
      id: randomId+id,
      value: value,
      rawValue: value,
      cleave: null,
      digit: this.props.digit && parseFloat(this.props.digit),
      decimal: this.props.decimal && parseFloat(this.props.decimal)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      let value = this.props.value
      value = (value !== undefined && !isNaN(value)) ? value : ''

      this.setState({ value: value, rawValue: value })
      this.setCleave(value)
    }
  }

  onChange = (e) => {
    let value = e.target.value
    let rawValue = parseFloat(e.target.rawValue)
    let maxValue = parseFloat(this.props.maxValue)

    if (value || value === '') {
      if (this.props.autoChange && maxValue) {
        value = rawValue <= maxValue ? rawValue : maxValue
        rawValue = rawValue <= maxValue ? rawValue : maxValue
      }

      this.setState({
        value: value,
        rawValue: (rawValue !== undefined && !isNaN(rawValue)) ? rawValue : ""
      }, () => {
        this.valueCallback(rawValue)
      })
    }
  }

  valueCallback = (value) => {
    value = (value !== undefined && !isNaN(value)) ? value : ''

    if (this.props.onChange) {
      if (this.props.i) {
        this.props.onChange(this.props.i, value)
      } else {
        this.props.onChange(value)
      }
    }
  }

  setCleave = (value) => {
    if (this.state.cleave && value !== undefined && !isNaN(value)) {
      this.state.cleave.setRawValue(value)
    } else {
      this.state.cleave.setRawValue('')
    }
  }

  onInit = (cleave) => {
    this.setState({ cleave: cleave })
  }

  render() {
    let value
    let maxValue = parseInt(this.props.maxValue)

    if (maxValue && this.state.rawValue) {
      value = this.state.rawValue <= maxValue ? this.state.rawValue : maxValue
    } else {
      value = this.state.rawValue !== '' ? this.state.rawValue : ''
    }

    console.log(this.state.rawValue, this.state.value)

    return (
      <div>
        {!this.props.disabled ?
          <Required
            {...this.props}
            id={this.state.id}
            classId={this.state.id}
            className={this.props.requiredClassName}
            label={this.props.label}
            unitName={this.props.unitName}
            type="text"
            value={value}
            required={this.props.required}
            iconValid={this.props.iconValid}>

            <div className="mg-top--2">
              <Cleave
                autoFocus={this.props.autoFocus}
                id={this.state.id}
                className={`input ${this.props.required ? 'required ' : ''} ${this.props.className} ${this.props.inputClassName}`}
                placeholder={this.props.placeholder}
                value={value}
                options={{
                  numeral: true,
                  numeralIntegerScale: this.state.digit,
                  numeralDecimalScale: this.state.decimal,
                  numeralPositiveOnly: true
                }}
                onInit={this.onInit}
                onChange={this.onChange}
              />

              <input
                type="hidden"
                value={value}
                data-id={this.props.id}
                data-not-save={this.props.notSave}
              />
            </div>
          </Required>
        :
          <InputDisabled {...this.props} />
        }
      </div>
    )
  }
}*/

export default InputDecimal

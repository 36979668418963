import React from 'react'

// Components Global
import ProfileAvatar from '../../../../../../components/ProfileAvatar'

// Values
import { attributeItemValue } from '../../../../../../redux/Attributes/AttributesValues'


const AttributeItemProfileAvater = (props) => {
  let { attribute, item, fontSize, imageSize } = props
  item = attributeItemValue({ attribute, item })

  return (
    <ProfileAvatar
      circle={props.circle}
      images={item.images}
      imageSize={imageSize || 'm'}
      color={item.avatarColor}
      texts={item.avatarName}
      textSize={fontSize || '1.1rem'}
    />
  )
}

export default AttributeItemProfileAvater

// Containers
import UsersContainer from 'core/modules/Users/containers/UsersContainer'

// Routes
import UsersMenuRoutes from './routes/UsersRoutesMenuRoutes'


const UsersRoutes = [
  {
    path: '/:username/users',
    component: UsersContainer,
    routes: [
      ...UsersMenuRoutes
    ]
  }
]

export default UsersRoutes

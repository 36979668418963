import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../../../components/Modals/Modal'
import FormValidation from '../../../../../../../components/FormValidation'
import BtnSubmit from '../../../../../../../components/button/BtnSubmit'
import InputNumber from '../../../../../../../components/Inputs/InputNumber'

// Actions
import { updateOrder } from '../../../../../../../redux/Orders/OrdersActions'

// Values
import { getPriceProductsAll } from '../../../../../../../redux/Orders/OrdersValues'


class DiscountEditModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  fnStartModal() {
    let order = this.props.order

    if (order.discount) {
      this.mounted && this.setState({
        type: order.discount.type,
        value: order.discount.value
      })
    }
  }

  handleSubmit = (e, formName) => {
    e.preventDefault()
    let params = {}

    params.discount = {
      type: this.state.type || 'currency',
      value: this.state.value || 0
    }

    const { business, order } = this.props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id,
      orders_id: order._id
    }

    this.props.dispatch(updateOrder(query, {
      body: params,
      loadId: 'discountEditModal',
      modalId: 'discountEditModal',
      toastMsg: 'แก้ไขส่วนลดแล้ว'
    })).then(res => {
      if (res.success && this.props.fetch) {
        this.props.fetch()
      }
    })
  }

  percentChange = (value) => {
    this.mounted && this.setState({ type: 'percent', value: value })
  }

  currencyChange = (value) => {
    this.mounted && this.setState({ type: 'currency', value: value })
  }

  render() {
    let order = this.props.order

    return (
      <Modal
        id="discountEditModal"
        headerTitle="ส่วนลดท้ายบิล"
        fnStartModal={() => this.fnStartModal()}
        headerLeft
        width="450px"
        padding="0">

        <div className="pd-20 pd-top-30">
          <FormValidation ref="editDiscount" name="editDiscount" submit={(e) => this.handleSubmit(e, 'editDiscount')} >
            <InputNumber
              onChange={this.percentChange}
              label="ส่วนลด"
              unitName="(เปอร์เซ็น)"
              placeholder="0.00"
              active
              id="percent"
              digit="3"
              decimal="2"
              maxValue="100"
              value={ this.state.type === 'percent' ? this.state.value : '' }
            />

            <InputNumber
              onChange={this.currencyChange}
              label="ส่วนลด"
              unitName="(บาท)"
              placeholder="0.00"
              active
              id="currency"
              digit="7"
              decimal="2"
              maxValue={getPriceProductsAll(order)}
              value={ this.state.type === 'currency' ? this.state.value : '' }
            />

            <div className="text-right">
              <BtnSubmit
                icon="send"
                iconAlign="left"
                text="บันทึก"
                className="waves-effect waves-light btn light-green darken-1"
                loadingId="discountEditModal"
                btnDisabled
              />
            </div>
          </FormValidation>
        </div>
      </Modal>
    )
  }
}

export default connect()(DiscountEditModal)

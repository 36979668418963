import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../components/Modals/Modal'
import { Row, Column } from '../../../../../components/Columns'

// Components forms
import FormValidation from '../../../../../components/FormValidation'
import BtnSubmit from '../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../util/getFormValue'

// Components Local
import {
  FolderName,
  FolderPath
} from '../FolderForms'

// Actions Redux
import { updateFolder } from '../../../../../redux/Folders/FoldersActions'


class FolderUpdateModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: '',
      path: '',
      status: ''
    }
  }

  handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(this.refs[formName])
    const { business, folderId } = this.props

    if (params) {
      this.props.dispatch(updateFolder(business._id, folderId, {
        body: params,
        loadId: formName,
        modalId: formName,
        toastMsg: 'แก้ไขหมวดหมู่แล้ว'
      }))
    }
  }

  handleChange = (data) => {
    this.setState({
      data,
      path: data.value,
      status: data.status
    })
  }

  render() {
    const id = "folderUpdateModal"
    const { data, path, status } = this.state

    let { business, folders, selectedData } = this.props
    folders = folders || []

    let type = `${selectedData.type}s`


    return (
      <Modal
        id={id}
        headerTitle="แก้ไขหมวดหมู่"
        headerLeft
        settimeout
        width="450px">

        <Row className="pd-20 pd-top-25">
          <FormValidation
            recheckForm
            ref={id}
            name={id}
            dataChange={JSON.stringify(data)}
            submit={(e) => this.handleSubmit(e, id)}>

            <Column col="column col-xs-12">
              <FolderName value={selectedData.name} />
            </Column>

            <Column col="column col-xxs-12">
              <FolderPath
                {...this.props}
                onChange={this.handleChange}
                folder={selectedData}
                value={selectedData.path}
              />
            </Column>

            <Column col="column col-xxs-6 pd-top-20">
              <Link to={`/${business.username}/${type}/folders/${selectedData._id}/update`} className="waves-effect waves-light btn btn-white btn-shadow-none">
                แก้ไขเพิ่มเติม
              </Link>
            </Column>

            <Column col="column col-xxs-6 pd-top-20 text-align-right">
              <BtnSubmit
                loadingId={id}
                icon="send"
                iconAlign="left"
                text="บันทึก"
                className="waves-effect waves-light btn btn-submit btn-shadow-none"
                btnDisabled
              />
            </Column>
          </FormValidation>
        </Row>
      </Modal>
    )
  }
}

export default connect()(FolderUpdateModal)

import { lazy } from 'react'
import suspenseComponent from 'utils/suspenseComponent'

// Containers
import AdminsMenuContainer from 'core/modules/Admins/containers/AdminsMenuContainer'

// Components
const AdminsListContainer = lazy(() => import('core/modules/Admins/containers/AdminsListContainer'))
const AdminManagePage = lazy(() => import('core/modules/Admins/pages/AdminManagePage'))


// Components
//const AdminsListPage = lazy(() => import('core/modules/Admins/pages/AdminsListPage'))


const AdminsMenuRoutes = [
  {
    path: '/:username/admins',
    component: AdminsMenuContainer,
    routes: [
      // {
      //   path: '/:username/admins/trash',
      //   auth: { reqAuthBiz: true },
      //   params: { status: 'trash', title: 'ผู้ดูแลระบบ', icon: 'assignment_ind' },
      //   component: suspenseComponent(AdminsListPage)
      // },
      {
        path: '/:username/admins/:status(all|trash)/:admins_id',
        component: suspenseComponent(AdminsListContainer),
        routes: [
          {
            path: '/:username/admins/:status(all|trash)/:admins_id',
            auth: { reqAuthBiz: true },
            component: suspenseComponent(AdminManagePage),
          }
        ]
      },
    ]
  }
]

export default AdminsMenuRoutes

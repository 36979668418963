import React from 'react'

// Components Global
import RatingStars from '../../../../../components/input/RatingStars'

// Components Products
import {
  ProductProfileAvater,
  ProductItemProfileAvater
} from '../../../Products/components/ProductsUserInterface'

// Values
import { productValue } from '../../../../../redux/Products/ProductsValues'

// Utils
import { getNumeral } from '../../../../../util/getNumeral'


const ProductDescriptions = (props) => {
  let { product, order } = props
  let { customer } = order

  product = product && productValue({ product, customer })



  if (product) {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td className="vertical-top pd-0 width-50-xxs height-50-xxs width-100-xs height-100-xs width-100-sm height-120-sm width-150-md height-150-md width-150-lg height-150-lg width-150-xlg height-150-xlg">
                <div className="vertical-top pd-0 width-50-xxs height-50-xxs width-100-xs height-100-xs width-100-sm height-120-sm width-150-md height-150-md width-150-lg height-150-lg width-150-xlg height-150-xlg">
                  <ProductProfileAvater product={product} fontSize="2.5rem" />
                </div>
              </td>
              <td className="vertical-top pd-0 pd-left-15">
                <p className="f4 f-title">{product.nameLang}</p>

                <div className="mg-top-8">
                  <div>
                    <div className={`font-0-8 mg-right-8 badge-border ${product.statusColor}`}>
                      สถานะ{ product.statusName }
                    </div>

                    <div className={`font-0-8 mg-right-8 badge-border ${product.stockAdjustColor}`}>
                      { product.stockAdjustName }
                    </div>

                    <div className={`font-0-8 badge-border ${product.itemChoiceColor}`}>
                      { product.itemChoiceName }
                    </div>
                  </div>

                  { product.items[0] && product.itemChoice === 'single' &&
                    <div className="mg-top-8">
                      <span className="font-1-5 mg-top-10 color-price">
                        ฿{ getNumeral(product.item.priceCondition, 'auto') }
                      </span>
                    </div>
                  }
                </div>

                <RatingStars
                  name="stars"
                  value="0"
                  unit="ดาว"
                  maxStars="5"
                  size="1.2rem"
                  hideScore
                  style={{ marginTop: '15px', marginBottom: '10px', height: '10px', lineHeight: '10px' }}
                  readOnly
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

export default ProductDescriptions

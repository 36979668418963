import React, { Fragment } from 'react'

// Components Products
import {
  ProductProfileAvater,
  ProductItemProfileAvater
} from '../../../Products/components/ProductsUserInterface'

// Values
import { productValue, productItemValue } from '../../../../../redux/Products/ProductsValues'

// Utils
import { getNumeral } from '../../../../../util/getNumeral'


const ProductItemDescriptions = (props) => {
  let { orderItem, product, order, productItem } = props
  let { customer } = order

  product = product && productValue({ product, customer })
  productItem = productItemValue({ product, customer, item: productItem })

  if (productItem && productItem) {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td className="vertical-top pd-0 width-60">
                <div className="width-60 height-60">
                  <ProductItemProfileAvater product={product} item={productItem} />
                </div>
              </td>
              <td className="pd-0 pd-left-15 font-0-9">
                <div className="font-1-0">{ product.nameLang }</div>
                { productItem.choiceFullNameLang &&
                  <div className="font-0-8">
                    { productItem.choiceFullNameLang }
                  </div>
                }
              </td>
              <td className="pd-0 text-right">
                { orderItem && orderItem.qty ?
                  <div>
                    { product.stockAdjust ?
                      <span className={`font-0-8 badge-border success mg-right-10`}>
                        { getNumeral(orderItem.qty + productItem.qty, 'auto') }
                      </span>
                    :
                      <span className={`font-0-8 badge-border info mg-right-10`}>
                        ไม่นับสต็อก
                      </span>
                    }
                  </div>
                :
                  <span className={`font-0-8 badge-border ${productItem.stock.color} mg-right-10`}>
                    { productItem.stock.name }
                  </span>
                }
              </td>
              <td className="pd-0 text-right">
                <span className="color-price font-1-5">
                  ฿{ getNumeral(productItem.priceCondition, 'auto') }
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

export default ProductItemDescriptions

import React, { Fragment } from 'react'


// util
import { getNumeral } from '../../../../../../../util/getNumeral'

const OrderSummaryDisplay = (props) => {
  const { order } = props
  const { items, discount, vat, wht, totalAmountProduct, totalNetAmountProduct, totalNetAmount, paymentNetAmount } = order
  const shippingFee = items && items.filter(item => item.type === 'shipping-fee')[0]
  const paymentFee = items && items.filter(item => item.type === 'payment-fee')[0]

  return (
    <div className="bg-fff mg-20 mg-top-0 font-0-8">
      <table>
        <tbody>
          { (shippingFee && shippingFee.price) ?
            <tr>
              <td className="pd-5">ค่าจัดส่ง</td>
              <td className="pd-5 text-right width-200 font-bold">{ getNumeral(shippingFee.price, 2) }</td>
            </tr>
          : null }

          { (paymentFee && paymentFee.price) ?
            <tr>
              <td className="pd-5">ค่าธรรมเนียม (ชำระเงินปลายทาง)</td>
              <td className="pd-5 text-right width-200 font-bold">{ getNumeral(paymentFee.price, 2) }</td>
            </tr>
          : null }

          <tr>
            <td className="pd-5">รวมเป็นเงิน</td>
            <td className="pd-5 text-right width-200 font-bold">{ getNumeral(totalAmountProduct, 2) }</td>
          </tr>

          { (discount && discount.amount) ?
            <Fragment>
              <tr>
                <td className="pd-5">
                  ส่วนลด {(discount.type === 'percent' && discount.percent) ? `${discount.percent}%` : '' }
                </td>
                <td className="pd-5 text-right width-200 font-bold">{ getNumeral(discount.amount, 2) }</td>
              </tr>

              <tr>
                <td className="pd-5">ราคาหลังหักส่วนลด</td>
                <td className="pd-5 text-right width-200 font-bold">{ getNumeral(totalNetAmountProduct, 2) }</td>
              </tr>
            </Fragment>
          : null }

          { (vat && vat.status) &&
            <tr>
              <td className="pd-5">ภาษีมูลค่าเพิ่ม {vat.rate}%</td>
              <td className="pd-5 text-right width-200 font-bold">{ getNumeral(vat.amount, 2) }</td>
            </tr>
          }

          <tr>
            <td className="pd-top-5 pd-left-5 pd-right-5 pd-bottom-15">จำนวนเงินรวมทั้งสิ้น</td>
            <td className="pd-5 text-right font-bold">{ getNumeral(totalNetAmount, '2') }</td>
          </tr>

          { (wht && wht.status) &&
            <Fragment>
              <tr className="border-top-1 border-top-ddd">
                <td className="pd-5 pd-top-10">หักภาษี ณ ที่จ่าย {wht.rate}%</td>
                <td className="pd-5 text-right width-200 font-bold">{ getNumeral(wht.amount, 2) }</td>
              </tr>

              <tr>
                <td className="pd-5">ยอดชำระ</td>
                <td className="pd-5 text-right font-bold">{ getNumeral(paymentNetAmount, '2') }</td>
              </tr>
            </Fragment>
          }
        </tbody>
      </table>
    </div>
  )
}

export default OrderSummaryDisplay

import React from 'react'
import renderRoutes from '../../../../../routes/renderRoutes'
import { connect } from 'react-redux'

// Components Global
import Window from '../../../../../components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../../components/MenuSidebarResponsive'

// Components Purchases
import RubberSaleCreateAndUpdateModal from '../components/RubberSaleCreateAndUpdateModal'

// Values
import { getProductTypeValue, getRubberSystemValue } from '../../../../../redux/RubberSettings/RubberSettingsValue'


const menuItems = (params) => {
  const { username, productType, rubberType, saleType } = params
  const path = `/${username}/rubber-sales/${productType}`
  const items = [
    {
      name: 'รายการขาย',
      title: true
    },
    {
      name: 'ทั้งหมด',
      nameShort: 'ทั้งหมด',
      icon: 'assignment',
      link: `${path}/all/${saleType}/sales/all`,
      searchLinkActive: `${path}/all/${saleType}/sales/`
    },
    {
      name: 'ยางแผ่นดิบ',
      nameShort: 'ยางแผ่นดิบ',
      iconFa: 'fas fa-layer-group',
      link: `${path}/rubber-sheet/${saleType}/sales/all`,
      searchLinkActive: `${path}/rubber-sheet/${saleType}/sales/`,
      hidden: productType === "field-latex"
    },
    {
      name: 'ยางแผ่นรวมควัน',
      nameShort: 'ยางแผ่นรวมควัน',
      iconFa: 'fas fa-dumpster-fire',
      link: `${path}/ribbed-smoked-sheet/${saleType}/sales/all`,
      searchLinkActive: `${path}/ribbed-smoked-sheet/${saleType}/sales/`,
      hidden: productType === "field-latex"
    }
  ]

  return items
}

const RubberSalesMenuContainer = (props) => {
  const { business, setting, location, route, match, windowModal, closeWindowModal, history } = props
  const { productType, rubberType, saleType } = match.params
  const type = getProductTypeValue(productType)
  const system = getRubberSystemValue({ productType, rubberType, saleType })
  const titleIcon = require(`../../../Businesses/resources/icons/${system.id}.png`)

  return (
    <Window
      title={`${system.name}`}
      titleIcon={titleIcon}
      linkClose={`/${match.params.username}`}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkHelp="">

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive
            btnAddModal={{
              modalId: 'rubberSaleCreateAndUpdateModal',
              text: `เพิ่มการ${type.saleName}`,
            }}
            items={menuItems(match.params)}
          />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, setting },
            location
          }) }
        </Layouts2>
      </LayoutsTwo>

      { props.modal &&
        <RubberSaleCreateAndUpdateModal
          {...props}
          event="create"
          id="rubberSaleCreateAndUpdateModal"
          business={business}
        />
      }
    </Window>
  )
}

function mapStateToProps(store, props) {
  return {
    modal: store.app.modals.filter(modal => modal.id === 'rubberSaleCreateAndUpdateModal')[0]
  }
}

export default connect(mapStateToProps)(RubberSalesMenuContainer)

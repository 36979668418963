import cuid from 'cuid'
import React, { useEffect, useState } from 'react'

// Components Forms
import {
  Price,
  PriceType,
  PricePerUnit,
  Title,
  TotalBeforeDeductCosts,
  TotalDeductCosts,
} from './components/DeductCostsForms'


const DeductCosts = (props) => {
  const [incomes, setIncomes] = useState([])
  const [netWeight, setNetWeight] = useState(0)
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (props.items) {
      if (props.items.length !== 0) {
        setIncomes(props.items)
      }
    }
  }, [])

  useEffect(() => {
    if (props.rubberType && props.event === 'create') {
      let cloneIncomes = []
      const costs = getSettingTypeCosts()
      const incomeFixed = []

      costs.map(item => {
        const checkDuplicate = cloneIncomes.filter(income => income.title === item.title && income.useSetting === true)[0]

        if (!checkDuplicate) {
          const newItem = {
            key: cuid(),
            title: item.title,
            useSetting: true,
            priceType: item.type
          }

          incomeFixed.push(newItem)
        }
      })

      cloneIncomes = [...cloneIncomes, ...incomeFixed]
      cloneIncomes = [...cloneIncomes.filter(i => i.useSetting === true), ...cloneIncomes.filter(i => i.useSetting !== true)]

      setIncomes(cloneIncomes)
    }

  }, [props.rubberType])


  useEffect(() => {
    let cloneIncomes = [...incomes]

    if (cloneIncomes.length !== 0) {
      cloneIncomes.map(item => {
        if (item.pricePerUnit !== undefined) {
          let price = parseFloat(item.pricePerUnit) * netWeight
          item.price = -price
        }
      })

      setIncomes(cloneIncomes)
    }
  }, [netWeight])

  useEffect(() => {
    setNetWeight(props.netWeight || 0)
  }, [props.netWeight])


  useEffect(() => {
    props.onChange(incomes)
  }, [incomes])


  const add = () => {
    const array = [...incomes]
    array.push({ key: cuid(), title: '', priceType: 'amount', price: '' })
    setIncomes(array)
  }

  const remove = (i) => {
    const array = [...incomes]
    array.splice(i, 1)
    setIncomes(array)
  }

  const update = (v, i, element) => {
    if (v.target) v = v.target.value
    const array = [...incomes]

    if (element === 'priceType') {
      array[i][element] = v

      if (v === 'amount') {
        array[i].pricePerUnit = ""
      } else if (v === 'per-unit') {
        array[i].price = ""
      }

    } else if (element === 'price') {
      array[i][element] = parseFloat(v)
    } else if (element === 'pricePerUnit') {
      array[i][element] = parseFloat(v || 0)
      array[i].price = -parseFloat(v || 0) * netWeight
    } else {
      array[i][element] = v
    }

    setIncomes(array)
  }

  const getTotalIncomes = () => {
    let totalIncomes = 0
    incomes.map(income => totalIncomes += income.price || 0)

    return totalIncomes
  }

  const totalIncomes = getTotalIncomes()

  const getSettingTypeCosts = () => {
    let costs

    if (props.type.code) {
      const data = props.setting.types.filter(type => type.code === props.type.code)[0]
      costs = data && data.buyDeductCosts
      costs = costs && JSON.parse(costs)
    }

    return costs || []
  }

  return (
    <div className="pd-top-20">
      { incomes.map((income, i) => {
        return (
          <div key={income.key} className="">
            <table>
              <tbody>
                <tr>
                  <td className="pd-0 pd-right-15 vertical-top">
                    <Title
                      type={props.type}
                      value={income.title}
                      onChange={(v) => update(v, i, 'title')}
                      disabled={income.useSetting}
                    />
                  </td>

                  <td className="pd-0 width-90 pd-right-15 vertical-top">
                    <PriceType
                      type={props.type}
                      value={income.priceType}
                      onChange={(v) => update(v, i, 'priceType')}
                      disabled={income.useSetting}
                    />
                  </td>

                  { income.priceType === 'per-unit' &&
                    <td className="pd-0 width-90 pd-right-15 vertical-top">
                      <PricePerUnit
                        type={props.type}
                        value={income.pricePerUnit}
                        onChange={(v) => update(v, i, 'pricePerUnit')}
                        //disabled={!edit}
                      />
                    </td>
                  }

                  <td className="pd-0 width-100 vertical-top">
                    <Price
                      type={props.type}
                      value={income.price}
                      onChange={(v) => update(v, i, 'price')}
                      disabled={income.priceType === 'per-unit'}
                    />
                  </td>


                  <td className="pd-0 width-40 text-right vertical-top">
                    { income.useSetting ?
                      <a disabled className="btn btn-shadow-none btn-white width-30 height-30 line-height-30 mg-top-15 pd-0 center">
                        <i className="material-icons color-c0c0c0">close</i>
                      </a>
                    :
                      <a onClick={() => remove(i)} className="btn btn-shadow-none btn-white width-30 height-30 line-height-30 mg-top-15 border-c0c0c0 pd-0 center">
                        <i className="material-icons">close</i>
                      </a>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      }) }

      {/*
      <div className="height-45 mg-top-1 mg-bottom-10 center">
        <a onClick={add} className="btn btn-shadow-none btn-white height-30 line-height-30 pd-left-8 pd-right-10 font-0-8 border-c0c0c0 pd-0 center">
          <i className="material-icons left">add</i> เพิ่มรายการหัก
        </a>
      </div>*/}

      {/* incomes.length !== 0 &&
        <div>
          <table>
            <tbody>
              <tr>
                <td className="pd-0 pd-right-5">
                  <TotalBeforeDeductCosts value={props.totalBeforDeduct} />
                </td>
                <td className="pd-0 pd-left-5">
                  <TotalDeductCosts type={props.type} value={totalIncomes} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      */}
    </div>
  )
}

export default DeductCosts

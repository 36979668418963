import { lazy } from 'react'
import suspenseComponent from '../../../../../../../util/suspenseComponent'

// Containers
import RubberReceivablesMenuContainer from '../../../../../../../containers/dashboard/SystemRubber/RubberReceivables/containers/RubberReceivablesMenuContainer'


// receivables
const RubberReceivablesListContainer = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberReceivables/containers/RubberReceivablesListContainer'))
const RubberReceivableManagePage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberReceivables/pages/RubberReceivableManagePage'))

// members
const RubberReceivableMembersListContainer = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberReceivables/containers/RubberReceivableMembersListContainer'))
const RubberReceivableMemberManagePage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberReceivables/pages/RubberReceivableMemberManagePage'))
const RubberReceivableMemberReceivablesListPage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberReceivables/pages/RubberReceivableMemberReceivablesListPage'))



// payments
const RubberReceivablePaymentsListContainer = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberReceivables/containers/RubberReceivablePaymentsListContainer'))
const RubberReceivablePaymentManagePage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberReceivables/pages/RubberReceivablePaymentManagePage'))


// Types
const memberGroup = ":memberGroup(all|paid|unpaid)"
const paymentStatus = ":paymentStatus(all|paid|unpaid)"


const RubberReceivablesMenuRoutes = [
  {
    path: `/:username/rubber-receivables`,
    component: RubberReceivablesMenuContainer,
    routes: [
      {
        path: `/:username/rubber-receivables/members/${memberGroup}/:customers_members_id/${paymentStatus}`,
        component: suspenseComponent(RubberReceivableMembersListContainer),
        routes: [
          {
            path: `/:username/rubber-receivables/members/${memberGroup}/:customers_members_id/${paymentStatus}`,
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(RubberReceivableMemberManagePage),
            routes: [
              {
                path: `/:username/rubber-receivables/members/${memberGroup}/:customers_members_id/${paymentStatus}`,
                auth: { reqAuthBiz: true, reqExpDate: true },
                component: suspenseComponent(RubberReceivableMemberReceivablesListPage),
              }
            ]
          },
        ]
      },

      {
        path: `/:username/rubber-receivables/payments/:receivable_payments_id`,
        component: suspenseComponent(RubberReceivablePaymentsListContainer),
        routes: [
          {
            path: `/:username/rubber-receivables/payments/:receivable_payments_id`,
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(RubberReceivablePaymentManagePage)
          },
        ]
      },

      {
        path: `/:username/rubber-receivables/${paymentStatus}/:receivables_id`,
        component: suspenseComponent(RubberReceivablesListContainer),
        routes: [
          {
            path: `/:username/rubber-receivables/${paymentStatus}/:receivables_id`,
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(RubberReceivableManagePage)
          },
        ]
      },


    ]
  },
]

export default RubberReceivablesMenuRoutes

import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'

import { addData, removeDataAll } from '../../../redux/Datas/DatasActions'

// Utils
import { Column, Row } from '../../Columns'

const Selector = (props) => {
  const { listSelectors, items, useSelector, pageNo } = props
  const CompListManage = useSelector.compListManage
  const { groupId } = useSelector

  const handleSelectAll = () => {
    items.map(item => {
      let _id

      if (typeof item._id === 'object') {
        for (let key in item._id) {
          _id = `${_id !== undefined ? `${_id}-` : ''}${item._id[key]}`
        }
      } else {
        _id = item._id
      }

      props.dispatch(addData({ _id, pageNo, groupId, value: item }))
    })
  }

  const handleRemoveAll = () => {
    props.dispatch(removeDataAll({ datas: [], groupId }))
  }

  let icon, onClick

  const listSelectorsByPageNo = listSelectors.filter(list => list.pageNo === pageNo)

  if (listSelectorsByPageNo.length !== 0 && listSelectorsByPageNo.length < items.length) {
    icon = 'indeterminate_check_box'
    onClick = handleRemoveAll
  } else if (listSelectorsByPageNo.length === items.length) {
    icon = 'check_box'
    onClick = handleRemoveAll
  } else {
    icon = 'check_box_outline_blank'
    onClick = handleSelectAll
  }

  useEffect(() => {
    setTimeout(() => {
      if (props.useSelect) {
        const el = document.querySelector(".myElement")

        if (el) {
          const observer = new IntersectionObserver(([e]) => {
            e.target.classList.toggle("box-shadow-3", e.intersectionRatio < 1)
            e.target.classList.toggle("border-0", e.intersectionRatio < 1)
          }, { threshold: [1] })

          observer.observe(el)
        }
      }
    }, 0)
  }, [props.useSelect])

  return (
    <Fragment>
      <div className="user-select-none border-eee bg-fff mg-bottom-5 border-radius-3 top--1 pst-sticky myElement" style={{ zIndex: 2 }}>
        <Row>
          <Column col="column col-xxs-12 col-xs-12 col-sm-5 col-md-4 col-lg-4 col-xlg-4">
            <div className="height-45 line-height-45 pd-left-10">
              <i style={{ cursor: 'pointer '}} onClick={onClick} className="material-icons font-1-8 i-middle left">{icon}</i>
              <span className="font-0-9" style={{ cursor: 'pointer '}} onClick={onClick}> เลือกอยู่ {listSelectors.length}</span>
            </div>
          </Column>
          <Column col="column col-xxs-12 col-xs-12 col-sm-7 col-md-8 col-lg-8 col-xlg-8 mg-top-7-sm mg-top-7-md mg-top-7-lg mg-top-7-xlg">
            <div className="height-35 pd-left-10 pd-right-8 text-right mg-top-1">
              <CompListManage {...props} pageNo={pageNo} />
            </div>
          </Column>
        </Row>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state, props) => {
  const { useSelector } = props
  const { groupId } = useSelector
  const listSelectors = state.datas.data.filter(data => data.groupId === groupId) || []

  return {
    listSelectors
  }
}

export default connect(mapStateToProps)(Selector)

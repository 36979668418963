import React, { Fragment } from 'react'
import Scrollbar from 'react-perfect-scrollbar'

// Components Pages
import ProductsList from './components/ProductsList'


const ProductsListTabs = (props) => {
  const { business } = props

  return (
    <Fragment>
      { business &&
        <Scrollbar>
          <ProductsList {...props} title="สินค้า" type="product" subtype="product" />
        </Scrollbar>
      }
    </Fragment>
  )
}

export default ProductsListTabs

import React from 'react'
import renderRoutes from 'client/routes/renderRoutes'

// Components Global
import Window from 'components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from 'components/LayoutsTwoResponsive'
import MenuSidebarResponsive from 'components/MenuSidebarResponsive'

// Components Users
import UserCreateModal from '../components/UserCreateModal'


// imgs
import titleIcon from '../resources/imgs/user-title-icon.png'


const menuItems = (params) => {
  const { username } = params
  const path = `/${username}/users`

  return [
    {
      name: 'รายการ',
      title: true
    },
    {
      name: 'ผู้ใช้งาน',
      icon: 'public',
      link: `${path}/all/all`,
      searchLinkActive: `${path}/all/`,
    },
    {
      name: 'ถังขยะ',
      icon: 'delete',
      link: `${path}/trash/all`,
      searchLinkActive: `${path}/trash/`,
    }
  ]
}

const UsersMenuContainer = (props) => {
  const { business, location, route, match, windowModal, closeWindowModal, history } = props

  return (
    <Window
      title="ผู้ใช้งาน"
      titleIcon={titleIcon}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkClose={`/${match.params.username}`}>

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive
            btnAddModal={{
              modalId: 'userCreateModal',
              text: 'เพิ่มผู้ใช้งาน'
            }}
            items={menuItems(match.params)}
          />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business },
            location
          }) }
        </Layouts2>
      </LayoutsTwo>

      <UserCreateModal id="userCreateModal" match={match} business={business} />
    </Window>
  )
}

export default UsersMenuContainer

import { lazy } from 'react'
import suspenseComponent from '../../../../../../util/suspenseComponent'

// Containers
import CustomerUpdateContainer from '../../../../../../containers/dashboard/Customers/containers/CustomerUpdateContainer'

// Component Pages
const CustomerAddressesListPage = lazy(() => import('../../../../../../containers/dashboard/Customers/pages/CustomerAddressesListPage'))


const CustomerUpdateRoutes = [
  {
    path: '/:username/customers/advanced/:customers_id/update',
    component: CustomerUpdateContainer,
    routes: [
      {
        path: '/:username/customers/advanced/:customers_id/update',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(CustomerAddressesListPage)
      }
    ]
  }
]

export default CustomerUpdateRoutes


export const fetchRemoteApi = ({ value, checkDuplicate, dispatch, setRemoteStatus }) => {
  let { useQuery, action, query, unselectId, element } = checkDuplicate

  if (element) {
    query = `${query}&element=${element}`
  }

  if (unselectId) {
    query = `${query}&unselectId=${unselectId}`
  }

  query = `${query}&${element}=`

  const fetch = () => action(useQuery, {
    query: query + value,
    notStore: true
  })

  setRemoteStatus('loading')

  try {
    dispatch(fetch()).then(res => {
      const { success, data } = res
      let status

      if (success) {
        if (data) {
          status = 'res-duplicate'
        } else {
          status = 'res-success'
        }
      } else {
        status = 'error'
      }

      setTimeout(() => {
        setRemoteStatus(status)
      }, 500)
    })
  } catch (error) {
    setRemoteStatus('catch')
  }
}

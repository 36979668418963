import React, { Fragment, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import cuid from 'cuid'

// components glogal
import Calendar from '../../Calendar'
import Required from '../Required'
import DropdownDefault from '../../DropdownDefaultV2'

// utils
import { fetchRemoteApi } from '../../../util/remote'

// css
import css from './css/inputDate.css'
import { toBBYear } from '../../../util/getDateTime'
import InputDisabled from '../InputDisabled'



const InputDatePicker = (props) => {
  const dropdownRef = useRef()
  const [value, setValue] = useState('')
  const [id, setId] = useState('')
  const [remoteStatus, setRemoteStatus] = useState('')
  const { dispatch, checkDuplicate } = props

  useEffect(() => {
    const id = props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()
    setId(id+randomId)
    initDate(props.value)
  }, [])

  useEffect(() => {
    if (props.value) {
      initDate(props.value)
    }
  }, [props.value])

  useEffect(() => {
    if (checkDuplicate) {
      const date = checkDate(value)

      if (date) {
        fetchRemoteApi({
          dispatch,
          value: date,
          checkDuplicate,
          setRemoteStatus
        })
      }
    }

    if (props.onChange) {
      const date = checkDate(value)

      if (date || value === '') {
        props.onChange(date)
      }
    }
  }, [value])


  const initDate = (value) => {
    let date

    if (value) {
      date = moment(value).format('DD/MM/YYYY')
    } else {
      if (props.dateNow) {
        date = moment(new Date()).format('DD/MM/YYYY')
      }
    }

    if (date !== 'Invalid date') {
      setValue(date)
    } else {
      setValue(undefined)
    }
  }

  const handleResetDate = () => {
    setValue('')
    dropdownRef.current.close()
  }

  const getDatesCalendar = (dates, close=true) => {
    const dateFormat = moment(dates.startDate).format('DD/MM/YYYY')
    setValue(dateFormat)

    if (props.getStartDate) {
      props.getStartDate(dates)
    }

    if (props.getEndDate) {
      props.getEndDate(dates)
    }

    // return value dates use is selected for calendar
    if (props.getStartDatePicker) {
      props.getStartDatePicker(dates)
    }

    // return value dates use is selected for calendar
    if (props.getEndDatePicker) {
      props.getEndDatePicker(dates)
    }

    // return value dates use is active for calendar
    if (props.getDatesCalendarActive) {
      props.getDatesCalendarActive(dates)
    }

    // close date picker value true is close and value false is open
    if (close) {
      dropdownRef.current.close()
    }

    if (props.openPickerEndDate) {
      props.openPickerEndDate()
    }
  }

  const handleChange  = (e) => {
    if (!props.bbYear) {
      let valueInput = e.target.value
      const reAll = /(\d)/g

      if (valueInput.length <= 10) {
        let eventType

        if (valueInput.length < value.length) {
          eventType = 'delete'
        } else {
          eventType = 'add'
        }

        if (eventType === 'add') {
          const reD = /(\d{1,2})$/g
          const reDM = /(\d{1,2})(\d{1,2})$/g

          if (reD.test(valueInput)) {
            if (valueInput.length === 2) {
              valueInput = `${valueInput}/`
            }
          }

          if (reDM.test(valueInput)) {
            if (valueInput.length === 5) {
              valueInput = `${valueInput}/`
            }
          }
        }

        setValue(valueInput)
      }
    }
  }

  const checkDate = (value) => {
    const pattern = /^(\d{2})\/(\d{2})\/(\d{4})$/g

    if (pattern.test(value)) {
      const split = value.split('/')
      value = `${split[2]}-${split[1]}-${split[0]}`

      const date = moment(value).format('YYYY-MM-DD')

      if (date === 'Invalid date') {
        return ''
      } else {
        return date
      }
    } else {
      return ''
    }
  }


  let remote

  if (checkDuplicate) {
    remote = {
      msgLoading: 'กำลังตรวจสอบ...',
      msgDuplicate: `วันที่นี้ คุณใช้แล้ว ไม่สามารถใช้ซ้ำได้`,
    }
  }

  let valueDisplay = ''

  if (props.bbYear) {
    if (checkDate(value)) {
      valueDisplay = checkDate(value)
      valueDisplay = toBBYear(moment(valueDisplay), 'DD/MM/YYYY')
    }
  } else {
    valueDisplay = value || ''
  }

  let size = props.iconSize || 40

  return (
    <Fragment>
      { !props.disabled ?
        <Required
          id={id}
          classId={id}
          label={props.label}
          unitName={props.unitName}
          type="date"
          value={value}
          remoteStatus={remoteStatus}
          remote={remote}
          className={props.requiredClassName}
          labelClassName={props.labelClassName}
          required={props.required}>

          <DropdownDefault
            targetNotClose
            left={props.left}
            right={props.right}
            ref={dropdownRef}
            className="width-full"
            contentClassName="min-width-250 mg-bottom-50">

            <table>
              <tbody>
                <tr>
                  { checkDuplicate &&
                    <td className={`pd-0 width-${size+5} vertical-bottom text-left`}>
                      <a className={`btn btn-shadow-none btn-white width-${size} height-${size} line-height-${size} border-c0c0c0 pd-0 center`}>
                        <i className="material-icons">event</i>
                      </a>
                    </td>
                  }

                  <td className="pd-0">
                    <input
                      id={id}
                      type="text"
                      value={valueDisplay || ''}
                      className={`${props.inputForSite ? 'input-site' : 'input'} ${props.styleGlobal ? ' input-global-jsx' : ''} input-jsx ${props.required ? 'required' : ''} ${props.inputClassName || ''}`}
                      placeholder={"DD/MM/YYYY"}
                      onChange={handleChange}
                      data-not-save={true}
                    />
                  </td>

                  { !checkDuplicate && !props.hiddenIcon &&
                    <td className={`pd-0 width-${size+5} vertical-bottom text-right`}>
                      <a className={`btn btn-shadow-none btn-white width-${size} height-${size} line-height-${size} border-c0c0c0 pd-0 center`}>
                        <i className="material-icons">event</i>
                      </a>
                    </td>
                  }
                </tr>
              </tbody>
            </table>

            <div>
              <Calendar
                type="datePicker"
                datesNotes={props.datesNotes}
                datesSelect={{
                  startDate: checkDate(value),
                  endDate: checkDate(value)
                }}
                //format="DD/MM/YYYY"
                bbYear={props.bbYear}
                startDate={props.startDate}
                getDatesCalendar={getDatesCalendar}
              />

              <div className={`${css.boxBtn}`}>
                <div className={`${css.boxBtnRemove} pd-5`}>
                  <a onClick={handleResetDate} className={`font-0-8 btn btn-white btn-shadow-none height-30 line-height-30`}>ลบวันที่</a>
                </div>
                <div className={`${css.boxBtnClose} pd-5`}>
                  <a onClick={() => dropdownRef.current.close()} className={`font-0-8 btn btn-white btn-shadow-none height-30 line-height-30`}>ออกจากปฎิทิน</a>
                </div>

                <div style={{ clear: 'both' }} />
              </div>
            </div>
          </DropdownDefault>

          <input
            data-id={props.id}
            data-not-save={props.notSave}
            value={checkDate(value)}
            type="hidden"
          />
        </Required>
      :
        <InputDisabled {...props} />
      }
    </Fragment>
  )
}

export default connect()(InputDatePicker)

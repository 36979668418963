
export const tagValue = ({ tag, lang="local" }) => {
  let nameLang, typePath, avatarName, descriptionsLang

  if (tag) {
    nameLang = tag.name ? tag.name[lang] : null
    avatarName = nameLang ? [nameLang] : null
    descriptionsLang = tag.descriptions ? tag.descriptions[lang] : null

    typePath = `${tag.type}s`

    return {
      ...tag,
      nameLang,
      typePath,
      avatarName,
      descriptionsLang
    }
  } else {
    return {}
  }
}

import React from 'react'

// Components Global
import ListsExpand from '../../../../../../../../ListsExpand'
import { generateItems1 } from '../../../../../../../../DragDropMenu/functions/dragDropMenuFunctions'

// fuctions
import { getPathActive } from '../../../../../../functions/siteManageFunctions'


const PagesLists = (props) => {
  let { pages, site, business, onClick } = props
  pages = pages.filter(page => page.type === 'page')

  const pathActive = getPathActive(props)
  const lists = generateItems1({ items: pages })

  return (
    <ListsExpand
      model="data-tree"
      clickParent={true}
      activeValue={pathActive}
      onClick={onClick}
      lists={lists}
      listsAll={pages}
      pathType="_id"
      linkStart={`/${business.username}/sites/${site._id}/update/site-manage`}
    />
  )
}

export default PagesLists

import React, { Fragment, useRef } from 'react'
import { connect } from 'react-redux'

// Componets Global
import { Row, Column } from '../../../../../components/Columns'
import Modal from '../../../../../components/Modals/Modal'
import FormValidation from '../../../../../components/Form'
import BtnSubmit from '../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../util/getFormValue'

// Components Local
import {
  CustomerName,
  CustomerCorporate,
  CustomerTaxId,
  CustomerTel,
  CustomerMobile,
  CustomerEmail,
  CustomerAddress,
  CustomerLineId
} from '../CustomerForms/CustomerForms'

// Actions Redux
import { createCustomer } from '../../../../../redux/Customers/CustomersActions'


const CustomerCreateModal = (props) => {
  const formRef = useRef()
  const { id, business, dispatch } = props

  const handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(formRef.current)

    if (params) {
      if (props.onSubmit) {
        props.onSubmit(params, formName)
      } else {
        const query = {
          authName: 'admin',
          dbNo: business.dbNo,
          businessType: business.type,
          businesses_id: business._id
        }

        dispatch(createCustomer(query, {
          body: params,
          loadId: formName,
          modalId: formName,
          toastMsg: 'เพิ่มรายชื่อลูกค้าแล้ว'
        }))
      }
    }
  }

  return (
    <Modal
      id={id}
      headerTitle="เพิ่มรายชื่อลูกค้า"
      headerLeft
      width="550px">

      <div className="pd-20">
        <FormValidation ref={formRef} name={id} submit={(e) => handleSubmit(e, id)} >
          <Row>

            { business && business.type !== 'rubber' ?
              <Fragment>
                <Column col="column col-xs-12 mg-top-20">
                  <CustomerCorporate />
                </Column>
                <Column col="column col-xs-12">
                  <CustomerTaxId required={props.required} />
                </Column>
                <Column col="column col-xs-12">
                  <CustomerAddress required={props.required} />
                </Column>
                <Column col="column col-xs-12">
                  <CustomerMobile />
                </Column>
                <Column col="column col-xs-12">
                  <CustomerTel />
                </Column>
                <Column col="column col-xs-12">
                  <CustomerEmail />
                </Column>
                <Column col="column col-xs-12">
                  <CustomerLineId />
                </Column>
              </Fragment>
            :
              <Fragment>
                <Column col="column col-xs-12 mg-top-20">
                  <CustomerName />
                </Column>
                <Column col="column col-xs-12">
                  <CustomerTaxId />
                </Column>
                <Column col="column col-xs-12">
                  <CustomerAddress />
                </Column>
                <Column col="column col-xs-12">
                  <CustomerMobile />
                </Column>
              </Fragment>
            }

            <Column col="column col-xs-12 text-align-right">
              <BtnSubmit
                loadingId={id}
                icon="send"
                iconAlign="left"
                text="เพิ่มรายชื่อลูกค้า"
                className="btn btn-success btn-shadow-none height-35 line-height-35"
                btnDisabled
              />
            </Column>
          </Row>
        </FormValidation>
      </div>
    </Modal>
  )
}

export default connect()(CustomerCreateModal)

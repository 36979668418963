import React, { Fragment } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { connect } from 'react-redux'
import { getLang } from '../../../util/getLang'

// utils
import { getURLSearchParams } from '../../../util/getURLSearchParams'
import Selector from 'components/PageItems/components/Selector'


const ItemsList = (props) => {
  let { items, getMapIdKey, unwind, component, componentHeaderList, tableClassName, fnConfigItems, lang } = props
  const CompHeaderList = componentHeaderList
  const CompList = component
  const urlParams = new URLSearchParams(props.location.search)

  let pageNo = urlParams.get('current_page') || 1
  pageNo = parseInt(pageNo)

  const useSelector = getURLSearchParams(location.search)
  const useSelect = useSelector && useSelector.useSelector && useSelector.useSelector === "true"

  if (fnConfigItems) {
    items = fnConfigItems(items)
  }

  const getMapKey = (_id) => {
    let keyValue = ''

    for (let key in _id) {
      keyValue = `${keyValue}${_id[key]}`
    }

    return keyValue
  }

  return (
    <Fragment>
      { useSelect &&
        <Selector {...props} pageNo={pageNo} useSelect={useSelect} />
      }

      { !props.notItemListTable ?
        <table className={`table-list ${tableClassName || ''}`}>

          { items.lenght !== 0 && CompHeaderList && <CompHeaderList /> }

          <ReactCSSTransitionGroup
            id="blockUnSelectItems"
            component="tbody"
            transitionName="example"
            transitionEnterTimeout={1000}
            transitionLeaveTimeout={1000}>

            { items && items.map((data, i) => {
              let key

              if (getMapIdKey) {
                key = getMapKey(data._id)
              } else if (unwind) {
                key = data[unwind]._id
              } else {
                key = data._id
              }

              return (
                <CompList
                  {...props}
                  useSelect={useSelect}
                  pageNo={pageNo}
                  key={key}
                  data={data}
                />
              )
            }) }

          </ReactCSSTransitionGroup>
        </table>
      :
        <ReactCSSTransitionGroup
          id="blockUnSelectItems"
          component="div"
          transitionName="example"
          transitionEnterTimeout={1000}
          transitionLeaveTimeout={1000}>

          { items && items.map((data, i) => {
            let key

            if (getMapIdKey) {
              key = getMapKey(data._id)
            } else if (unwind) {
              key = data[unwind]._id
            } else {
              key = data._id
            }

            return (
              <CompList
                {...props}
                useSelect={useSelect}
                pageNo={pageNo}
                key={key}
                data={data}
              />
            )
          }) }
        </ReactCSSTransitionGroup>
      }
    </Fragment>
  )
}

const mapStateToProps = (state, props) => {
  return {
    lang: getLang({ state, props }).lang
  }
}

export default connect(mapStateToProps)(ItemsList)

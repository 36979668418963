import React, { Component } from 'react'
import moment from 'moment'

// components global
import DatePicker from '../DatePicker'
import { Row, Column } from '../../Columns'


class DatePickerBetween extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, ''),
      openPickerEndDate: false,
      valueStart: this.props.valueStart,
      valueEnd: this.props.valueEnd,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.valueStart !== nextProps.valueStart) {
      this.setState({ valueStart: nextProps.valueStart })
    }

    if (this.props.valueEnd !== nextProps.valueEnd) {
      this.setState({ valueEnd: nextProps.valueEnd })
    }
  }


  plusDayStart = (startDate) => {
    if (this.props.plusDay) {
      let dayPlus = moment(startDate, "YYYY-MM-DD").add(this.props.plusDay, 'days')
      return dayPlus.format("YYYY-MM-DD")
    }

    return startDate
  }


  onChangeStartDate = (date) => {
    this.setState({ valueStart: date, valueEnd: date })

    if (this.props.onChange) {
      this.props.onChange(date, date)
    }
  }

  onChangeEndDate = (date) => {
    this.setState({ valueEnd: date })

    if (this.props.onChange) {
      this.props.onChange(this.state.valueStart, date)
    }
  }

  render() {
    return (
      <Row>
        <Column col={this.props.colStart ? this.props.colStart : 'column col-xs-12'}>
          <DatePicker
            required
            notSave
            id={this.props.startId}
            name="dateStart"
            value={this.state.valueStart}
            label={this.props.labelStart || 'วันที่เริ่มต้น'}
            datesNotes={this.props.datesNotes}
            getStartDatePicker={this.props.getStartDatePicker}
            getDatesCalendarActive={this.props.getDatesCalendarActive}
            onChange={this.onChangeStartDate}
            dateFormat={this.props.dateFormat}
            startDate={moment().format('YYYY-MM-DD')}
            inputClassName={this.props.inputClassName}
          />
        </Column>

        <Column col={this.props.colEnd ? this.props.colEnd : 'column col-xs-12'}>
          <DatePicker
            required
            notSave
            right={this.props.colEndRight}
            id={this.props.endId}
            name="endDate"
            value={this.state.valueEnd}
            label={this.props.labelEnd || 'วันที่สิ้นสุด'}
            startDate={this.plusDayStart(this.state.valueStart)}
            onChange={this.onChangeEndDate}
            dateFormat={this.props.dateFormat}
            inputClassName={this.props.inputClassName}
          />

          <input
            data-id={this.props.startId}
            value={this.state.valueStart}
            type="hidden"
          />

          <input
            data-id={this.props.endId}
            value={this.state.valueEnd}
            type="hidden"
          />

        </Column>
      </Row>
    )
  }
}

export default DatePickerBetween

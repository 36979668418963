import React, { useEffect, useState } from 'react'

// Components Global
import TextInput from '../../../../../../components/Inputs/TextInput'
import InputNumeral from '../../../../../../components/Inputs/InputNumeral'
import InputPhoneNumber from '../../../../../../components/Inputs/InputPhoneNumber'
import SelectDefault from '../../../../../../components/Inputs/SelectDefault'


export const MemberMobile = (props) => {
  let id = "mobile"

  if (props.index) {
    id = `mobile_${props.index}`
  }

  return (
    <InputPhoneNumber
      label="มือถือ"
      id={id}
      hiddenCode
      value={props.value}
      onChange={props.onChange}
      notSave={props.notSave}
    />
  )
}

export const MemberName = (props) => {
  let id = `name.${lang}`

  if (props.index) {
    id = `name_${props.index}.${lang}`
  }

  const lang = props.lang || 'local'
  const value = props.value ? props.value[lang] : ''
  const label = props.label || 'ชื่อ - นามสกุล'

  return (
    <TextInput
      label={label}
      placeholder={label}
      id={id}
      name={id}
      value={value}
      required={props.required}
      onChange={props.onChange}
      notSave={props.notSave}
    />
  )
}

export const PlantationName = (props) => {
  let id = `plantationName`

  if (props.index) {
    id = `plantationName_${props.index}`
  }

  const lang = props.lang || 'local'
  const value = props.value ? props.value[lang] : ''

  let label = 'ชื่อแปลง'

  if (!props.required) {
    label = `${label} (ถ้ามี)`
  }

  return (
    <TextInput
      label={label}
      placeholder={label}
      id={id}
      name={id}
      value={value}
      onChange={props.onChange}
      notSave={props.notSave}
      required={props.required}
    />
  )
}

export const PercentPartner = (props) => {
  let id = "percentPartner"

  if (props.index) {
    id = `percentPartner_${props.index}`
  }

  return (
    <InputNumeral
      required
      label="% ส่วนแบ่ง"
      placeholder="% ส่วนแบ่ง"
      id={id}
      value={props.value}
      onChange={props.onChange}
      notSave={props.notSave}
      maxValue="100"
      notMinus
    />
  )
}

export const PercentOwner = (props) => {
  let id = "percentOwner"

  if (props.index) {
    id = `percentOwner_${props.index}`
  }

  return (
    <InputNumeral
      required
      label="% เจ้าของสวน"
      placeholder="% เจ้าของสวน"
      id={id}
      value={props.value}
      onChange={props.onChange}
      notSave={props.notSave}
      maxValue="100"
      notMinus
    />
  )
}

export const PercentEmployee = (props) => {
  let id = "percentEmployee"

  if (props.index) {
    id = `percentEmployee_${props.index}`
  }

  return (
    <InputNumeral
      disabled
      required
      label="% คนกรีดยาง"
      placeholder="% คนกรีดยาง"
      id={id}
      value={props.value}
      inputClassName="color-000 font-1-1"
      notSave={props.notSave}
    />
  )
}


export const WithdrawType = (props) => {
  let id = "withdrawType"

  if (props.index) {
    id = `percentOwner_${props.index}`
  }

  return (
    <SelectDefault
      id={id}
      label="การเบิกเงิน"
      value={props.value}
      onChange={props.onChange}
      notSave={props.notSave}
      options={[
        { name: 'เลือกประเภท', value: '' },
        { name: 'รายวัน', value: 'day' },
        { name: 'รายสัปดาห์', value: 'week' },
        { name: 'รายเดือน', value: 'month' },
        { name: 'รายปี', value: 'year' },
      ]}
    />
  )
}


export const WithdrawMoney = (props) => {
  const [type, setType] = useState('')
  const [day, setDay] = useState('')
  const [date, setDate] = useState('')

  let id = "withdrawMoney"

  if (props.index) {
    id = `withdraw_${props.index}`
  }

  if (props.event === 'create') {
    id = `members[0].${id}`
  }

  useEffect(() => {
    let type = props.value && props.value.type
    let day = props.value && props.value.day
    let date = props.value && props.value.date

    setType(type)
    setDay(day)
    setDate(date)
  }, [])

  const onChangeType = (type) => {
    setType(type)

    if (props.onChange) {
      props.onChange({ type, day, date })
    }
  }

  const onChangeDay = (day) => {
    setDay(day)

    if (props.onChange) {
      props.onChange({ type, day, date })
    }
  }

  const onChangeDate = (day) => {
    setDate(day)

    if (props.onChange) {
      props.onChange({ type, day, date })
    }
  }

  let options

  if (type === 'week') {
    options = [
      { name: 'เลือกวัน', value: '' },
      { name: 'จันทร์', value: 'MON' },
      { name: 'อังคาร', value: 'TUE' },
      { name: 'พุธ', value: 'WED' },
      { name: 'พฤหัสบดี', value: 'THU' },
      { name: 'ศุกร์', value: 'FRI' },
      { name: 'เสาร์', value: 'SAT' },
      { name: 'อาทิตย์', value: 'SUN' }
    ]
  }

  return (
    <table>
      <tbody>
        <tr>
          <td className="pd-0 vertical-top">
            <SelectDefault
              id={`${id}.type`}
              label={`ประเภทการเบิกเงิน (${props.labelType})`}
              value={type}
              onChange={onChangeType}
              notSave={props.notSave}
              options={[
                { name: 'เลือกประเภท', value: '' },
                { name: 'รายวัน', value: 'day' },
                { name: 'รายสัปดาห์', value: 'week' },
                { name: 'รายเดือน', value: 'month' },
                { name: 'รายปี', value: 'year' },
              ]}
            />
          </td>

          { type === 'week' &&
            <td className="pd-0 pd-left-20 vertical-top">
              <SelectDefault
                id={`${id}.day`}
                label={`วันเบิกเงิน (${props.labelType})`}
                value={day}
                onChange={onChangeDay}
                notSave={props.notSave}
                options={options}
              />
            </td>
          }
        </tr>
      </tbody>
    </table>
  )
}


import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Sales
import OrderCustomerManager from '../OrderCustomerManager'
import OrderItemsListManager from '../OrderItemsListManager'
import OrderButtonsManager from '../OrderButtonsManager'

// Components Sales
import OrderItemCreateModal from '../../../Sales/components/OrderItemCreateModal'

// Values
import { getHeightSummery } from '../../../../../redux/Orders/OrdersValues'

// Actions
import { updateOrder } from '../../../../../redux/Orders/OrdersActions'
import { openModal, closeModal } from '../../../../../redux/App/AppActions'

// css
import css from './css/saleOrderManager.css'


class OrderManager extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  addToItems = (params, formName) => {
    if (params) {
      const { business, order } = this.props
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businesses_id: business._id,
        orders_id: order._id
      }

      this.props.dispatch(updateOrder(query, {
        body: params,
        loadId: formName,
        modalId: 'productModal',
        toastMsg: 'เพิ่มสินค้าในตะกร้าแล้ว'
      })).then(res => {
        if (res.success) {
          this.props.dispatch(closeModal('productsListModal'))
        }
      })
    }
  }

  render() {
    let { order, modal, business } = this.props
    let height = getHeightSummery(order)

    height = height === 0 ? 5 : height

    return (
      <div className={css.cartManager}>
        <OrderCustomerManager {...this.props} id="selectCustomer" />

        { modal && modal.params.products_id &&
          <OrderItemCreateModal
            {...this.props}
            addToItems={this.addToItems}
            productId={modal.params.products_id}
          />
        }

        <div className={css.bill}>
          <div className={css.itemsList} style={{ bottom: 165 + height }}>
            <OrderItemsListManager {...this.props} />
          </div>

          <div className={css.itemsTotal} style={{ height: 165 + height }}>
            <OrderButtonsManager {...this.props} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    modal: state.app.modals.filter(modal => modal.id === 'productModal')[0]
  }
}

export default connect(mapStateToProps)(OrderManager)

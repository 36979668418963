import React from 'react'
import styles from './css/modal.css'


export function Modal (props) {
  const handleStay = (e) => e.stopPropagation()
  const centered = {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: '0 auto',
    width: '100%',
    maxWidth: (props.width ? props.width : '300px'),
    position: 'relative',
  }

  const style = {
     background: props.bgOverlay,
     zIndex: '99999'
  }

  let joinStyle = Object.assign(style, props.style);

  if (props.open || props.showOnly) {
    //document.body.classList.add('boby-overflow-hidden')
  } else {
    //document.body.classList.remove('boby-overflow-hidden')
  }

  return (
    <div>
      { props.open || props.showOnly ?
        <div className={styles.block} onClick={props.close} style={joinStyle}>
          <div style={centered} onClick={handleStay}>
              {props.children}
          </div>
        </div>
      : null }
    </div>
  )
}

export default Modal;

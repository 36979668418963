import React, { Component } from 'react'

// Components Global
import ModalFixed from '../../../../../components/Modals/ModalFixed'

// Components Local
import TemplateLists from './components/TemplateLists'

// Redux Actions
import { updateSite } from '../../../../../redux/Sites/SitesActions'


class TemplatesManageModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {

  }

  handleSubmit = () => {

  }

  submit = () => {
    const formName = 'site-update-loading'
    const { business, site } = this.props
    const params = {}

    params.design = {
      style: JSON.stringify(this.state.style),
      class: JSON.stringify(this.state.class),
    }

    if (params) {
      this.props.dispatch(updateSite(business._id, site._id, {
        body: params,
        loadId: formName
      }))
    }
  }

  render() {
    const { site } = this.props
    const templates = site.templates || []

    return (
      <ModalFixed
        id="templatesManageModal"
        title="จัดการเทมเพลต"
        width="650px"
        headerClassName="height-50"
        bodyClassName="top-50 bottom-5 pd-0"
        footerClassName="height-5 pd-0"
        bodyStyle={{ background: '#f5f5f5' }}
        padding="0">

        <div className="pd-10">
          <TemplateLists {...this.props} />
        </div>
        <div></div>
      </ModalFixed>
    )
  }
}

export default TemplatesManageModal

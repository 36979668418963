import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

class EditFieldDefault extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div>
        { !this.props.field ?
          <div>
            { this.props.children[0] }
          </div>
        :
          <div>
            { this.props.children[1] }
          </div>
        }
      </div>
    )
  }
}

EditFieldDefault.propTypes = {
  children: PropTypes.array.isRequired
}

const mapStateToProps = (store, props) => {
  return {
    field: store.app.fields.filter(field => field === props.name)[0]
  }
}

export default connect(mapStateToProps)(EditFieldDefault)
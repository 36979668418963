import React from 'react'

export const BtnBack = (props) => {
  return (
    <a onClick={props.goBack} className="btn btn-white width-full border-1 border-ddd btn-shadow-none height-40 line-height-40 pd-left-15 pd-right-20">
      <i className="material-icons left">arrow_back_ios</i> กลับ
    </a>
  )
}

export const BtnNext = (props) => {
  return (
    <a onClick={props.goNext} className="btn btn-success btn-shadow-none height-40 line-height-40 pd-left-20 pd-right-15">
      <i className="material-icons right">arrow_forward_ios</i> ต่อไป
    </a>
  )
}

export const BtnConfrimConnect = (props) => {
  return (
    <a onClick={props.goBack} className="btn btn-success width-full btn-shadow-none height-40 line-height-40 pd-left-15 pd-right-20">
      <i className="material-icons left">send</i> ยืนยันการเชื่อมต่อ
    </a>
  )
}

export const Footer = (props) => {
  return (
    <div className="pd-15 border-top-1 border-top-eee flx-row mg-top-15 mg-left--15 mg-right--15 mg-bottom--15">
      <div className="width-140 pd-right-15">
        <BtnBack goBack={props.goBack} />
      </div>
      <div className="width-full text-right">
        { props.next ? <BtnNext goNext={props.goNext} /> : props.children }
      </div>
    </div>
  )
}

export const ListNo = (props) => {
  return (
    <div className="flx-row mg-bottom-10">
      <div className="width-30">
        <div className="width-20 height-20 border-radius-circle bg-000 color-fff flx-column jtf-center items-center">
          <b>{props.no}</b>
        </div>
      </div>
      <div className="width-full">
        <div className="font-0-9">{props.children}</div>
      </div>
    </div>
  )
}

import React, { Fragment } from 'react'


const WindowContainer = (props) => {
  const { minusTotop } = props

  let top

  if (minusTotop) {
    top = -50
  } else {
    top = 0
  }

  return (
    <Fragment>
      <div
        id={props.id}
        className={`${props.className || ''} window-container-jsx`}
        style={{
          ...props.style || {}
        }}>

        { props.children }
      </div>

      <style jsx>{`
        .window-container-jsx {
          position: absolute;
          z-index: 99999;
          top: ${top}px;
          left: 0;
          bottom: 0;
          width: 100%;
          background: #f5f5f5;
          transition: all 1s;
        }
      `}</style>
    </Fragment>
  )
}

export default WindowContainer

import blobUtil from 'blob-util'
import callApi from './apiCaller'
import "regenerator-runtime/runtime"

// Redux Actions
import { updateFileProgress } from '../redux/Progresses/ProgressesActions'

const signS3 = ({ files, prefix, businesses_id }) => {
  const params = []

  files.map(file => {
    params.push({ prefix: prefix, name: file.data.name, type: file.data.type })
  })

  return new Promise((resolve, reject) => {
    callApi(`aws/sign-s3/${businesses_id}`, 'put', params).then(res => {
      resolve(res)
    })
  })
}

const uploadFile = (files, res, progressId, dispatch) => {
  const filesFilter = files.filter(file => file.status === null)
  const index = files.length - filesFilter.length

  const promises = filesFilter.map((file, i)=> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      const uploadProgress = (e) => {
        if (e.lengthComputable) {
          let percentComplete = e.loaded * 100 / e.total
          dispatch(updateFileProgress(progressId, i+index, { progress: percentComplete, status: 'load' }))
        }
      }

      xhr.upload.onprogress = uploadProgress
      xhr.open('PUT', res.data[i].signedRequest)
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            dispatch(updateFileProgress(progressId, i+index, { progress: 100, status: 'success' }))
            resolve(res.data[i].paths)
          } else {
            dispatch(updateFileProgress(progressId, i+index, { progress: null, status: 'error' }))
          }
        }
      }

      xhr.setRequestHeader('Cache-Control', 'max-age=2592000')

      if (file.base64) {
        blobUtil.dataURLToBlob(file.base64).then((blob) => {
          xhr.send(blob)
        }).catch(function (err) {
          alert('เกิดข้อผิดพลาด !!!')
        })
      } else {
        xhr.send(file.data)
      }
    })
  })

  return Promise.all(promises).then(res => res)
}

const waitCreateThumbnail = (res) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(res)
    }, 2000)
  })
}

const uploadsForS3 = ({ files, prefix, progressId, dispatch, businesses_id }) => {
  const filesFilter = files.filter(file => file.status === null)

  if (filesFilter.length !== 0) {
    return signS3({
      files: filesFilter,
      prefix,
      businesses_id
    }).then(res => {
      if (res.success) {
        return uploadFile(files, res, progressId, dispatch).then(res => {
          return waitCreateThumbnail(res).then(res => res)
        })
      } else {
        return false
      }
    })
  } else {
    return false
  }
}

export default uploadsForS3

import React, { Fragment } from 'react'

// Components Customers
import { CustomerShort } from '../../../../../Customers/components/CustomersUserInterface'

// Redux
import { orderValue, getTotalPriceAll } from '../../../../../../../redux/Orders/OrdersValues'

// Utils
import { getDate } from '../../../../../../../util/getDateTime/getDateTime'
import { getNumeral } from '../../../../../../../util/getNumeral'


export const OrderItem = (props) => {
  const { business, data, history, location, match, openSidebar } = props
  const { orders_drafts_id, orders_id } = match.params

  let order = data
  order = orderValue({ order })

  const goto = () => {
    let path = `/${business.username}/pos/sales/${orders_drafts_id}/histories/${order._id}`

    if (location.search) {
      path = `${path}${location.search}`
    }

    openSidebar()
    history.push(path)
  }

  return (
    <tr
      style={{ userSelect: 'none' }}
      onClick={() => goto()}
      className={orders_id === order._id ? 'trActive' : ''}>

      <td width="5" height="65" className="pd-0" style={{ background: order.fullStatusColor }}></td>

      <td className="font-0-9 pd-10 hidden-xlg">
        <div>#{ order.code }</div>
        <div>วันที่ : { getDate(order.created, 'DD/MM/YYYY', 'th') }</div>
        <div>ยอดชำระ : ฿{ getNumeral(getTotalPriceAll(order), 'auto') }</div>
      </td>

      <td className="font-0-9 pd-10 hidden-lg hidden-md hidden-sm hidden-xs hidden-xxs">
        <div>#{ order.code }</div>
        <div className="font-0-8 color-sub">วันที่ { getDate(order.created, 'DD/MM/YYYY', 'th') }</div>
      </td>

      <td className="pd-0">
        <span className={`badge-border`} style={{ color: order.fullStatusColor, border: `1px solid ${order.fullStatusColor}` }}>
          { order.fullStatusName }
        </span>
      </td>

      { orders_id === 'all' &&
        <td className="pd-5 center hidden-xxs hidden-xs hidden-sm">
          <CustomerShort customer={order.customer} order={order} />
        </td>
      }

      <td className="text-right pd-0 hidden-lg hidden-md hidden-sm hidden-xs hidden-xxs">
        <span className="color-price font-1-1">฿{ getNumeral(order.totalNetAmount, 'auto') }</span>
        <div className="font-0-8 color-sub">ราคา</div>
      </td>
      <td className="pd-3"></td>
    </tr>
  )
}

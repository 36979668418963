import React, { useState } from 'react'
import { connect } from 'react-redux'
import Scrollbar from 'react-perfect-scrollbar'

// Components Global
import FetchListItems from '../../../../../../components/FetchListItems'

// Component Local
import { OrderItem } from './OrderItem'

// Functions
import { getQueryEcommerceParams } from '../../../../../../redux/Orders/OrdersFunctions'

// Actions Redux
import { fetchOrders } from '../../../../../../redux/Orders/OrdersActions'


const OrdersListItems = (props) => {
  const { business, openSidebar, match, customer } = props
  const forUser = props.params.forUser
  const { status } = match.params
  let { customers_id } = match.params

  if (customer) {
    customers_id = customer._id
  }

  const getParams = () => {
    let params = {}

    if (status === 'all') {
      params = { forUser, name: 'all', title: 'ทั้งหมด', icon: 'library_books' }
    } else if (status === 'draft') {
      params = { forUser, name: 'draft', title: 'ร่าง', icon: 'chat' }
    } else if (status === 'unpaid') {
      params = { forUser, name: 'unpaid', title: 'ยังไม่ชำระเงิน', icon: 'assignment_late' }
    } else if (status === 'prepare') {
      params = { forUser, name: 'prepare', title: 'เตรียมส่ง', icon: 'local_shipping' }
    } else if (status === 'successed') {
      params = { forUser, name: 'completed', title: 'ส่งแล้ว', icon: 'assignment_turned_in' }
    } else if (status === 'refunded') {
      params = { forUser, name: 'refunded', title: 'คืนเงิน', icon: 'restore_page' }
    } else if (status === 'canceled') {
      params = { forUser, name: 'canceled', title: 'ยกเลิกแล้ว', icon: 'cancel' }
    }

    return params
  }

  const params = getParams()
  const { where, or } = getQueryEcommerceParams({ params, customers_id })
  const { title, icon } = params

  const fetchAction = {
    action: fetchOrders,
    actionQuery: {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id
    },
    id: business._id,
    where,
    countWhere: where,
    or,
    countOr: or,
    limit: 25,
    sort: '-_id',
    searchAttributes: [
      { name: 'code', options: 'i' },
      /*{ name: 'customer.firstName.local', options: 'i' },
      { name: 'customer.lastName.local', options: 'i' },
      { name: 'customer.firstName.local', join: 'customer.lastName.local', options: 'i' },*/
    ]
  }

  return (
    <div style={{ position: 'absolute', top: '0', left: '0', bottom: '0', right: '0' }}>
      <Scrollbar>
        <FetchListItems
          {...props}
          id="ordersList"
          storeName="orders"
          tableClassName=""
          title={title}
          icon={icon}
          fetchAction={fetchAction}
          componentList={OrderItem}
          columnTitle={{
            column1: 'column col-xxs-12',
            column2: 'column col-xxs-12 pd-top-10'
          }}
          notReloadForUrlChange
          dataReloadForUrlChange={status}
          openSidebar={openSidebar}
        />
      </Scrollbar>
    </div>
  )
}

export default connect()(OrdersListItems)


import React from 'react'

// Components Global
import { TextMiddle } from '../Text'

// css
import css from './css/error.css'

const PageError = (props) => {
  return (
    <div>
      <div className={css.box}>
        <TextMiddle height="100%" width="100%">
          <h3 style={{ color: '#fff' }}>
            { props.code === '401' && `401 Unauthorized`}
            { props.code === '403' && `403 Forbidden` }
            { props.code === '404' && `404 Not Found` }
          </h3>
        </TextMiddle>
      </div>
    </div>
  )
}

export default PageError

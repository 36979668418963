import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../../routes/renderRoutes'

// Components Global
import WindowDefault from '../../../../Windows/WindowDefault'
import FetchData from '../../../../FetchData'
import PreLoading from '../../../../PreLoading'

// Compoents Local
import Header from './components/Header'

import SiteStylesManageModal from './components/SiteStylesManageModal'
import SectionTitle from './components/SectionTitle'

// Components Pages
import PagesManageModal from '../../../../../containers/dashboard/Pages/components/PagesManageModal'
import PageCreateModal from '../../../../../containers/dashboard/Pages/components/PageCreateModal'
import PageTemplateManageModal from '../../../../../containers/dashboard/Pages/components/PageTemplateManageModal'
import TemplatesManageModal from '../../../../../containers/dashboard/Sites/components/TemplatesManageModal'
import ContentGroupPagesManageModal from '../../../../../containers/dashboard/Sites/components/ContentGroupPagesManageModal'



import HeaderFullManage from './components/HeaderFullManage'
import FooterFullManage from './components/FooterFullManage'
import WidgetFullManage from './components/WidgetFullManage'
import TemplatesFullManage from './components/TemplatesFullManage'
import ContentGroupPage from './components/ContentGroupPage'

import { HeaderDisplay } from '../../../Header'
import { FooterDisplay } from '../../../Footer'

import StylesGlobal from '../StylesGlobal'

// Redux Actions
import { fetchSites, fetchSite, updateSite, uploadImagesSite } from '../../../../../redux/Sites/SitesActions'
import { fetchPages, fetchPage, updatePage, uploadImagesPage } from '../../../../../redux/Pages/PagesActions'
import { fetchProduct, updateProduct, uploadImagesProduct } from '../../../../../redux/Products/ProductsActions'

import { openModal } from '../../../../../redux/App/AppActions'

// fuctions
import { startWeb } from '../../../../../redux/Pages/PagesFunctions'

// js
import { getPathsParams } from '../../functions/siteManageFunctions'

// css
import css from './css/siteManageContainer.css'
import PagePartsFullManage from './components/PageTemplatesFullManage/PagePartsFullManage'


class SiteManageContainer extends Component {
  state = {
    loggedIn: false
  }

  componentDidMount() {
    const { business, match, params } = this.props

    if (params.siteManage) {
      this.props.dispatch(fetchPages(business._id, match.params.sites_id, {
        body: { sort: 'sort' },
        loadId: 'pagesAllLoading'
      }))
    }

    if (params.singlePageManage) {
      this.props.dispatch(fetchSites(business._id, {
        body: { sort: 'sort' },
        loadId: 'pagesAllLoading'
      }))
    }
  }

  handleCreatePageIndex = () => {
    const { business, site, lang } = this.props

    startWeb(this.props, business, site, lang)
  }

  linkBack = () => {
    const { match } = this.props
    const url = match.url
    const linkBack = url.split('/site-manage')[0]

    return linkBack
  }

  urlMainManage = () => {
    const { match } = this.props
    const url = match.url

    return url.split('/site-manage')[0] + '/site-manage'
  }

  getLoggedIn = (loggedIn) => {
    this.setState({ loggedIn })
  }

  render() {
    const lang = this.props.lang || 'local'
    const { business, setting, site, singlePageData, singlePageType, match, params, dispatch } = this.props
    const pages = this.props.pages || []
    const pageIndex = pages.filter(page => page.path === 'index')[0]
    const paths = getPathsParams(match.params)
    const urlMainManage = this.urlMainManage()
    const { path_1, templates_id, content_group_page_id } = match.params

    let page, pageTemplate, templateTypeName

    // for site manage
    if (params.siteManage) {
      if (paths.length === 0) {
        page = pageIndex
      } else {
        page = pages.filter(page => page._id === paths[paths.length - 1])[0]
      }

      pageTemplate = pages.filter(page => page._id === path_1 && page.type === 'template')[0]

      if (pageTemplate) {
        if (pageTemplate.templateType === 'product-details') templateTypeName = 'สินค้า'
        else if (pageTemplate.templateType === 'product-folder-details') templateTypeName = 'หมวดหมู่สินค้า'
        else if (pageTemplate.templateType === 'product-sale-page-details') templateTypeName = 'หน้าปิดการขาย (Sale Page)'
        else if (pageTemplate.templateType === 'blog-details') templateTypeName = 'บทความ'
        else if (pageTemplate.templateType === 'blog-folder-details') templateTypeName = 'หมวดหมู่บทความ'
      }
    }

    let template, contentGroupPage

    if (params.template && templates_id) {
      template = site.templates.filter(template => template._id === templates_id)[0]
    }

    if (params.contentGroupPage && content_group_page_id && singlePageData) {
      contentGroupPage = singlePageData.contentGroupPages.filter(contentGroupPage => contentGroupPage._id === content_group_page_id)[0]
    }

    const actionSite = {
      fetch: fetchSite,
      update: updateSite,
      uploadImage: uploadImagesSite,
      imagePrefix: "sites",
      data: site
    }

    const actionPage = {
      fetch: fetchPage,
      update: updatePage,
      uploadImage: uploadImagesPage,
      imagePrefix: "pages",
      data: page
    }

    let actionSinglePage

    if (params.singlePageManage) {
      if (singlePageType === "product") {
        actionSinglePage = {
          useQuery: {
            authName: 'admin',
            dbNo: business.dbNo,
            businessType: business.type,
            businesses_id: business._id
          },
          fetch: fetchProduct,
          update: updateProduct,
          uploadImage: uploadImagesProduct,
          imagePrefix: "products",
          data: singlePageData
        }
      }
    }

    return (
      <WindowDefault>
        <Header
          {...this.props}
          title={singlePageData && singlePageData.name}
          template={template}
          contentGroupPage={contentGroupPage}
          urlMainManage={urlMainManage}
          linkBack={this.linkBack()}
        />

        { params.siteManage &&
          <Fragment>
            <PagesManageModal {...this.props} />
            <PageCreateModal {...this.props} />
            <PageTemplateManageModal  {...this.props} urlMainManage={urlMainManage} />
          </Fragment>
        }

        { site && (params.siteManage || params.singlePageManage) &&
          <TemplatesManageModal {...this.props} urlMainManage={urlMainManage} />
        }

        { site && params.singlePageManage &&
          <ContentGroupPagesManageModal
            {...this.props}
            urlMainManage={urlMainManage}
            actionSinglePage={actionSinglePage}
          />
        }

        { site &&
          <SiteStylesManageModal {...this.props} />
        }

        <div className={css.container}>

          { params.siteManage &&
            <a onClick={() => dispatch(openModal("pagesManageModal"))} className={css.boxTools} style={{ bottom: '210px', background: '#e65100' }}>
              <i className="material-icons">description</i>
              <div>หน้าเว็บ</div>
            </a>
          }

          { params.siteManage &&
            <a onClick={() => dispatch(openModal("pageTemplateManageModal"))} className={css.boxTools} style={{ bottom: '145px', background: '#6a1b9a' }}>
              <i className="material-icons">palette</i>
              <div>รูปแบบ</div>
            </a>
          }

          { params.singlePageManage &&
            <a onClick={() => dispatch(openModal("contentGroupPagesManageModal"))} className={css.boxTools} style={{ bottom: '145px', background: '#6a1b9a' }}>
              <i className="material-icons">description</i>
              <div>หน้าเว็บ</div>
            </a>
          }

          <a onClick={() => dispatch(openModal("siteStylesManageModal"))} className={css.boxTools} style={{ bottom: '80px', background: '#fdd835' }}>
            <i className="material-icons">palette</i>
            <div>สไตล์</div>
          </a>

          <a onClick={() => dispatch(openModal("templatesManageModal"))} className={css.boxTools} style={{ background: '#7cb342' }}>
            <i className="material-icons">palette</i>
            <div>เทมเพลต</div>
          </a>

          <div id="site-manage-container" className={css.siteContainer}>

            { site &&
              <StylesGlobal site={site} />
            }

            <PreLoading id="pagesAllLoading">
              <FetchData
                length={params.siteManage ? pages.length : 1}
                icon="web"
                msg="ยังไม่มีหน้าแรกของเว็บไซต์"
                btnSubmit={{
                  icon: 'trending_up',
                  loadingId: 'startWeb',
                  text: 'สร้างหน้าแรกของเว็บไซต์',
                  iconClassName: 'left',
                  className: 'pd-right-20',
                  onClick: this.handleCreatePageIndex
                }}>

                { contentGroupPage &&
                  <ContentGroupPage
                    {...this.props}
                    contentGroupPage={contentGroupPage}
                    urlMainManage={urlMainManage}
                    business={business}
                    setting={setting}
                    site={site}
                    pages={pages}
                    pageCurrent={page}
                    actionSite={actionSite}
                    actionSinglePage={actionSinglePage}
                    singlePageData={singlePageData}
                    singlePageType={singlePageType}
                  />
                }

                { pageTemplate &&
                  <div className="text-center font-2-0 pd-top-50">
                    <div>รูปแบบเทมเพลต</div>
                    <div className="font-1-5">ประเภท : { templateTypeName }</div>
                    <div className="font-1-5">ชื่อ : { pageTemplate.name[lang] }</div>
                  </div>
                }

                { params.template &&
                  <div className="text-center font-2-0 pd-top-50">
                    <div>เทมเพลต</div>
                    <div className="font-1-5">ชื่อ : { template.name[lang] }</div>
                  </div>
                }

                { params.template &&
                  <TemplatesFullManage {...this.props} template={template} />
                }

                { params.header &&
                  <HeaderFullManage
                    {...this.props}
                    pageCurrent={page}
                    singlePageData={singlePageData}
                    singlePageType={singlePageType}
                    actionSite={actionSite}
                    actionSinglePage={actionSinglePage}
                  />
                }

                { params.widget &&
                  <WidgetFullManage
                    {...this.props}
                    pageCurrent={page}
                    singlePageData={singlePageData}
                    singlePageType={singlePageType}
                    actionSite={actionSite}
                    actionSinglePage={actionSinglePage}
                  />
                }

                { (
                    (params.page && page && page.headerShow === true) ||
                    (params.singlePageManage && !params.header && !params.widget && !params.footer && !params.template && !params.contentGroupPage)
                  ) &&

                  <SectionTitle
                    title="ส่วนหัว (Header)"
                    mode="view"
                    style={{ marginTop: '20px' }}
                    editLink={{
                      url: `${urlMainManage}/header-manage`,
                      label: 'จัดการส่วนหัว',
                      icon: 'settings'
                    }}>

                    <HeaderDisplay
                      {...this.props}
                      mode="view"
                      datas={{
                        business,
                        setting,
                        site,
                        pages,
                        pageCurrent: page,
                        singlePageData,
                        singlePageType
                      }}
                    />
                  </SectionTitle>
                }

                { (params.page || params.pageTemplate)  &&
                  <PagePartsFullManage
                    {...this.props}
                    page={page}
                    title="ส่วนหัว (Page Modal Header)"
                    groupId="page-modal-header"
                    getLoggedIn={this.getLoggedIn}
                  />
                }

                { (params.page || params.pageTemplate)  &&
                  <SectionTitle title="ส่วนเนื้อหา (Content)" getLoggedIn={this.getLoggedIn}>

                    <Fragment>
                      { renderRoutes({
                        routes: this.props.route.routes,
                        extraProps: {
                          business,
                          setting,
                          site,
                          siteManage: params.siteManage,
                          page,
                          singlePageData,
                          singlePageType,
                          actionSinglePage,
                          actionPage,
                          mode: 'view',
                          loggedIn: this.state.loggedIn
                        },
                        location: this.props.location,
                      }) }
                    </Fragment>

                    <Fragment>
                      { renderRoutes({
                        routes: this.props.route.routes,
                        extraProps: {
                          business,
                          setting,
                          site,
                          siteManage: params.siteManage,
                          page,
                          singlePageData,
                          singlePageType,
                          actionSinglePage,
                          actionPage,
                          mode: 'edit',
                          loggedIn: this.state.loggedIn
                        },
                        location: this.props.location,
                      }) }
                    </Fragment>
                  </SectionTitle>
                }

                { params.footer &&
                  <FooterFullManage
                    {...this.props}
                    pageCurrent={page}
                    actionSite={actionSite}
                    actionSinglePage={actionSinglePage}
                  />
                }

                { (
                    (params.page && page && page.footerShow === true) ||
                    (params.singlePageManage && !params.widget && !params.footer && !params.header && !params.template && !params.contentGroupPage)
                  ) &&

                  <SectionTitle
                    title="ส่วนท้าย (Footer)"
                    mode="view"
                    editLink={{
                      url: `${urlMainManage}/footer-manage`,
                      label: 'จัดการส่วนท้าย',
                      icon: 'settings'
                    }}>

                    <FooterDisplay
                      {...this.props}
                      mode="view"
                      datas={{
                        business,
                        setting,
                        site,
                        pageCurrent: page,
                        singlePageData,
                        singlePageType
                      }}
                    />
                  </SectionTitle>
                }

                { (
                    (params.page && page) ||
                    (params.singlePageManage && !params.footer && !params.widget && !params.header && !params.template && !params.contentGroupPage)
                  ) &&

                  <SectionTitle
                    title="วิดเจ็ต"
                    mode="view"
                    editLink={{
                      url: `${urlMainManage}/widget-manage`,
                      label: 'จัดการวิดเจ็ต',
                      icon: 'settings'
                    }}>

                    <div></div>
                  </SectionTitle>
                }

              </FetchData>
            </PreLoading>
          </div>
        </div>
      </WindowDefault>
    )
  }
}

const mapStateToProps = (store, props) => {
  const { site, params, match } = props

  if (params.siteManage) {
    return {
      pages: store.pages.data.filter((page => page.sites_id === site._id))
    }

  } else if (params.singlePageManage) {
    const { products_id } = match.params

    let product, singlePageType, singlePageData

    if (products_id) {
      product = store.products.data.filter((product => product._id === products_id))[0]
      singlePageData = product
      singlePageType = 'product'
    }

    return {
      site: store.sites.data[0],
      singlePageType,
      singlePageData,
      product,
    }
  }
}

export default connect(mapStateToProps)(SiteManageContainer)

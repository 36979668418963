import React, { Fragment } from 'react'

// Components Global
import ProfileAvatar from '../../../../../../components/ProfileAvatar'
import AlbumManager from '../../../../../../components/AlbumManager'
import { Tooltip } from '../../../../../../components/tooltip/Tooltip'

// Funtions Customer
import { getItemImage } from '../../../../../../redux/Customers/CustomersFunctions'

// Values
import { customerValue } from '../../../../../../redux/Customers/CustomersValues'

// Redux Actions
import { openConfirm } from '../../../../../../redux/App/AppActions'
import {
  updateCustomer,
  uploadImagesCustomer
} from '../../../../../../redux/Customers/CustomersActions'


const CustomerProfileAvaterManage = (props) => {
  let { customer, business, fontSize } = props

  if (!customer.avatarName) {
    customer = customerValue({customer})
  }

  const images = customer.images
  const profiles = images && images.filter(img => img.album === 'profile')
  const profile = profiles && profiles[0]
  const { customersSettings } = business

  const handleDelete = (_id) => {
    props.dispatch(openConfirm(_id, {
      title: `ลบภาพนี้`,
      message: `กดปุ่ม "ลบภาพ" ถ้าคุณต้องการลบภาพนี้`,
      type: 'delete',
      icon: 'delete_forever',
      confirmLabel: 'ลบภาพ',
      cancelLabel: 'ยกเลิก',
      onConfirm: onDeleteImage(),
    }))
  }

  const onDeleteImage = () => {
    return (id) => {
      const actionParams = {
        body: {
          updateType: 'pull-image',
          updateElement: 'images',
          deleteId: id
        },
        toastMsg: `ลบภาพนี้แล้ว`,
        loadId: id,
        confirmId: id,
        props
      }

      const { business, dispatch } = props
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id,
        customers_id: customer._id
      }

      dispatch(updateCustomer(query, actionParams))
    }
  }

  return (
    <Fragment>
      { profile ?
        <div className="boxDiv-jsx">
          <ProfileAvatar
            circle={props.circle}
            images={images}
            imageSize="m"
            color={customer.avatarColor}
            texts={customer.avatarName}
            textSize={fontSize || '1.1rem'}
          />

          <div className="boxBtnDelete-jsx line-height-15">
            <Tooltip hiddenLine msg="ลบภาพนี้" width="70px" left="-35px" style={{ float: 'right' }}>
              <a onClick={() => handleDelete(profile._id)}  className={`btn-floating btn-xs-floating waves-light btn-delete`}>
                <i className="material-icons i-middle">delete</i>
              </a>
            </Tooltip>
          </div>

          <style jsx>{`
            .boxDiv-jsx {
              cursor: pointer;
              line-height: 0;
              position:  relative;
              width: 100%;
              height: 100%;
            }
            .boxBtnDelete-jsx {
              visibility: hidden;
              position: absolute;
              top: 0;
              right: 0;
              padding: 3px;
              z-index: 4;
            }
            .boxDiv-jsx:hover .boxBtnDelete-jsx {
              visibility: visible;
            }
          `}</style>
        </div>
      :
        <div className="width-full height-full">
          <AlbumManager
            {...props}
            id="profile"
            thumbnail="s"
            images={customer.images}
            btnAdd={{ text: 'เพิ่มภาพ', icon: 'add', maxFile: 1 }}
            boxImgStyle={{
              width: '100%',
              height: '100%'
            }}
            upload={{
              album: 'profile',
              multiple: false,
              maxFile: 1,
              crop: {
                title: 'รูปโปรไฟล์',
                circle: false,
                style: { width: '100%', height: 'auto' },
                width: customersSettings.profile.width || 980,
                height: customersSettings.profile.height || 980
              }
            }}
            delete
            profile
            action={{
              update: updateCustomer,
              uploadImage: uploadImagesCustomer,
              useQuery: {
                authName: 'admin',
                dbNo: business.dbNo,
                businessType: business.type,
                businesses_id: business._id,
                customers_id: customer._id
              },
              params: {
                business,
                _id: customer._id,
                imagePrefix: 'customers'
              }
            }}
          />
        </div>
      }
    </Fragment>
  )
}

export default CustomerProfileAvaterManage

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import TextInputV2 from '../TextInputV2'


const TextInputFetch = (props) => {
  const [code, setCode] = useState()
  const [data, setData] = useState()
  const [errorMsg, setErrorMsg] = useState()
  const [value, setValue] = useState()

  const getValue = (value) => {
    setValue(value)
  }

  useEffect(() => {
    if (value) {
      fetchApi(value)
    }
  }, [value])

  // useEffect(() => {
  //   getFetchData(data)
  // }, [data && JSON.stringify(data)])


  const fetchApi = (value, cancel) => {
    setCode('loading')

    const params = {
      notSaveStore: true,
      notShowErrorAlert: true
    }

    props.dispatch(props.fetchAction(value, params)).then(res => {
      if (cancel) return

      if (res.success) {
        if (res.data) {
          setCode('success')
          getFetchData(res)
        } else {
          setCode('null')
          getFetchData(res)
        }

      } else {
        if (res.data === null) {
          setCode('null')
          getFetchData(null)
        } else {
          setCode('error')
          getFetchData(null)
        }
      }
    }).catch(error => {
      if (cancel) return
      setCode('catch')
      getFetchData(null)
    })
  }

  const getFetchData = (value) => {
    if (props.getFetchData) {
      props.getFetchData(value)
    }
  }

  const getErrorMsg = () => {
    let errorMsg

    const fetchMsg = props.fetchMsg || {}

    if (code === 'catch') errorMsg = fetchMsg.catch  || 'ไม่สามารถเชื่อมต่อไปยังเซิร์ฟเวอร์ได้'
    else if(code === 'error') errorMsg = fetchMsg.catch  || 'เกิดข้อผิดพลาด กรุณาลองใหม่'
    else if(code === 'null') errorMsg = fetchMsg.null == undefined ? undefined : `ไม่สามารถใช้ ${props.label || ''} นี้ได้`
    else if(code === 'success') errorMsg = fetchMsg.success  || undefined
    else if(code === 'loading') errorMsg = fetchMsg.loading  || 'กำลังโหลดข้อมูล...'

    return errorMsg
  }

  useEffect(() => {
    if (code) {
      let errorMsg = getErrorMsg()
      setErrorMsg(errorMsg)
    }
  }, [code])


  return (
    <TextInputV2
      {...props}
      errorMsg={errorMsg}
      getValue={getValue}
    />
  )
}

export default connect()(TextInputFetch)









import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../../routes/renderRoutes'

// Components Global
import Window from '../../../../../components/WindowsV2/Window'
import PreLoading from '../../../../../components/PreLoading'
import PageError from '../../../../../components/PageError'

// Components Orders
import OrderShippingManageModal from '../../../Orders/components/OrderShippingManageModal'
import OrderInstallmentsCreateModal from '../../../Orders/components/OrderInstallmentsCreateModal'

// Components Local
import PosOnline from './components/PosOnline'

// Actions Redux
import { fetchOrder } from '../../../../../redux/Orders/OrdersActions'

// Value
import { orderValue } from '../../../../../redux/Orders/OrdersValues'


// imgs
import titleIcon from '../../resources/imgs/pos-96.png'


const PosOnlineContainer = (props) => {
  let { order, match, business, history, dispatch } = props
  order = orderValue({ order })

  useEffect(() => {
    if (!order) {
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businesses_id: business._id,
        orders_id: match.params.orders_id
      }

      dispatch(fetchOrder(query, {
        body: {
          //or: JSON.stringify(['status=draft', 'status=open'])
        },
        notShowErrorAlert: true,
        loadId: 'fetchOrderLoading'
      })).then(res => {
        /*if (res.success === false) {
          history.push(`/${business.username}/sales`)
        }*/
      })
    }
  }, [])

  let linkBack

  /*if (match.params.pageName === 'main') {
    linkBack = `/${match.params.username}/sales`
  } else if (match.params.pageName === 'checkout') {
    linkBack = `/${match.params.username}/sales/pos-online/${order._id}/main`
  }*/

  return (
    <Window
      margin={30}
      title={`ขายสินค้า #${order && order.code || ''}`}
      titleIcon={titleIcon}
      linkClose={`/${match.params.username}/sales`}
      windowModal={props.windowModal}
      closeWindowModal={props.closeWindowModal}
      history={history}>

      <PreLoading id="fetchOrderLoading">
        { order ?
          <PosOnline {...props} order={order} />
        :
          <PageError code="404" />
        }
      </PreLoading>
    </Window>
  )
}

const mapStateToProps = (state, props) => {
  const { match } = props
  const { orders_id } = match.params
  const { orders } = state

  return {
    order: orders.data.filter(order => order._id === orders_id)[0]
  }
}

export default connect(mapStateToProps)(PosOnlineContainer)

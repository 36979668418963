import React, { Component } from 'react'
//import jquery from 'jquery'

// css
import css from './css/select.css'

class Select extends Component {
  constructor(props) {
    super(props)

    this.state = {
      msgInvalid: false,
      msgValid: false,
      id: this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, ''),
      value: this.props.value !== undefined ? this.props.value : '',
      required: this.props.required ? true : false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value })
    }
  }

  componentDidMount() {
    if (this.state.value === "") this.checkValid(false)
    else this.checkValid(true)
  }

  /*componentDidMount() {
    //$(`select[id=${this.state.id}_select]`).on('change', () => this.handleChange() ).material_select()
  }*/

  componentDidUpdate() {
    setTimeout(() => {
      if (document.getElementById(this.state.id))
        document.getElementById(this.state.id).value = this.state.value
    }, 0)
  }

  handleChange = () => {
    let value = document.getElementById(`${this.state.id}_select`).value

    if (this.props.onChange)
      this.props.onChange(value)

    this.setState({ value: value }, () => {
      this.checkValid(true)
    })
  }

  checkValid = (checkMsq) => {
    if (this.state.value === '' && this.props.required) {
      this.setState({ valid: false, msgInvalid: checkMsq, msgValid: false })
    } else {
      this.setState({ valid: true, msgInvalid: false, msgValid: checkMsq })
    }
  }

  render() {
    return (
      <div className={`${css.select} ${this.state.msgInvalid && css.selectInvalid} ${this.state.msgValid && css.selectValid}`}>

        { this.props.disabled ?
          <div>
            { this.props.disabledValue &&
              <input type="hidden" data-id={this.props.id} value={this.props.disabledValue} />
            }
          </div>
        :
          <input
            id={this.state.id}
            data-id={this.props.id}
            ref={this.state.id}
            data-value-array-name={this.props.valueArrayName}
            data-not-save={this.props.notSave}
            data-child={this.props.child}
            value={this.state.value}
            className={ this.state.valid === false ? 'required invalid' : 'required valid'}
            type="hidden" />
        }

        <label className={`active ${this.state.msgInvalid && css.invalid} ${this.state.msgValid && css.valid}`}>
          {this.props.label} {this.props.unitName && this.props.unitName}
          {this.state.required && <span style={{ color: 'red', fontSize: '1.2rem' }}> *</span>}
        </label>

        { this.props.disabled ?
          <div className={css.disabled}>
            <span className="caret font-0-7">▼</span>
            { this.props.disabledPlaceholder && this.props.disabledPlaceholder }
          </div>
        :
          <select
            className={`${this.props.default && 'browser-default select'} ${this.props.className}`}
            style={this.props.style}
            disabled={this.props.disabled}
            id={`${this.state.id}_select`}
            name="select"
            onChange={this.handleChange}
            defaultValue={this.props.value}
          >
            { this.props.placeholder &&
              <option
                value=""
                style={{ color: '#2f2f2f' }}
                disabled={this.props.placeholderDisabled}>
                { this.props.placeholder }
              </option>
            }

            { this.props.options.map((option, key) => {
                return (
                  <option key={key} value={option.value}>{option.name}</option>
                )
            }) }

          </select>
        }

        { this.state.msgInvalid && <span className={css.msgInvalid}>{this.props.label + ' ต้องไม่ว่าง '}</span> }
      </div>
    )
  }
}


export default Select;

import React, { Component } from 'react'

// Compoents Local
import SectionTitle from '../SectionTitle'


import { TemplatesManage, TemplatesDisplay } from '../../../../../Templates'



class TemplatesFullManage extends Component {
  render() {
    const { business, setting, site, template } = this.props
    let width = template.width

    if (width && width < 600) {
      width = 600
    }

    return (
      <div style={{ maxWidth: width && `${width}px`, margin: '0 auto' }}>
        <SectionTitle title="เทมเพลต" mode="view">
          <TemplatesDisplay
            {...this.props}
            mode="view"
            datas={{
              business,
              setting,
              site
            }}
          />

          <TemplatesManage
            {...this.props}
            mode="edit"
            datas={{
              business,
              setting,
              site
            }}
          />
        </SectionTitle>
      </div>
    )
  }
}

export default TemplatesFullManage

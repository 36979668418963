import React from 'react'

// Components Global
import ProfileAvatar from '../../../../../../components/ProfileAvatar'

// Values
import { placeValue } from '../../../../../../redux/Places/PlacesValues'


const PlaceProfileAvater = (props) => {
  let { place, fontSize } = props

  if (!place.avatarName) {
    place = placeValue(place)
  }

  return (
    <ProfileAvatar
      circle={props.circle}
      images={place.images}
      imageSize="m"
      color={place.avatarColor}
      texts={place.avatarName}
      textSize={fontSize || '1.1rem'}
    />
  )
}

export default PlaceProfileAvater

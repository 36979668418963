import React, { Fragment, useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// JS
import { onSearchDataHook } from '../InputSearchItems/js/inputSearchFunction'

// Actions
import { addLoad } from 'core/redux/App/AppActions'

// util
import { getNumeral } from 'utils/getNumeral'
import { getURLSearchParams } from 'utils/getURLSearchParams'

// CSS
import css from './css/pagination.css'



const Pagination = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    refresh() {
      refresh()
    },
  }))

  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSearch, setCurrentPageSearch] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [totalItemsSearch, setTotalItemsSearch] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(props.itemsPerPage)
  const [showPage, setShowPage] = useState(props.showPage)

  const [searchData, setSearchData] = useState()
  const [totalPage, setTotalPage] = useState()
  const [totalPageSearch, setTotalPageSearch] = useState()

  const [lastMore, setLastMore] = useState()
  const [firstMore, setFirstMore] = useState()
  const [startNumber, setStartNumber] = useState()
  const [endNumber, setEndNumber] = useState()
  const [searchValue, setSearchValue] = useState()

  const [timer, setTimer] = useState(null)

  useEffect(() => {
    return () => {
      setTimer(null)
    }
  }, [])

  useEffect(() => {
    setItemsPerPage(props.itemsPerPage)
    setShowPage(props.showPage)
  }, [
    props.itemsPerPage,
    props.showPage
  ])


  const getCurrentPageName = (props) => {
    let currentPageName = 'current_page'
    let currentPageSearchName = 'current_page_search'

    if (props.currentPageName) {
      currentPageName = props.currentPageName
      currentPageSearchName = `${props.currentPageName}_search`
    }

    return {
      currentPageName,
      currentPageSearchName
    }
  }


  const getCurrentParams = (type) => {
    const { currentPageName, currentPageSearchName } = getCurrentPageName(props)
    const params = getURLSearchParams(props.location.search)

    if (type == 'currentPageName') {
      return params[currentPageName]
    } else if (type == 'currentPageSearchName') {
      return params[currentPageSearchName]
    } else if (type == 'search') {
      return params['search'] && JSON.stringify(params['search'])
    }
  }


  // page change
  useEffect(() => {
    getUrlParams(props.location.search)
  }, [
    props.location.search && JSON.stringify(props.location.search),
    props.location.search && getCurrentParams('currentPageName'),
    props.location.search && getCurrentParams('currentPageSearchName'),
    props.location.search && getCurrentParams('search'),
  ])


  // notReloadForUrlChange
  useEffect(() => {
    if (!props.notReloadForUrlChange) {
      getUrlParams()
    }
  }, [
    props.match.url,
  ])

  // dataReloadForUrlChange
  useEffect(() => {
    if (props.dataReloadForUrlChange) {
      getUrlParams(null, true)
    }
  }, [
    props.dataReloadForUrlChange && JSON.stringify(props.dataReloadForUrlChange)
  ])

  // start
  useEffect(() => {
    getUrlParams()
  }, [
    props.fetchAction.body && props.fetchAction.body.match && JSON.stringify(props.fetchAction.body.match)
  ])

  useEffect(() => {
    const count = props.searchData && props.searchData.count

    setSearchData(props.searchData)
    setTotalItemsSearch(count)
    setTotalItems(count)
    setTotalPageSearch(count && Math.ceil(count / itemsPerPage))

  }, [
    props.searchData && JSON.stringify(props.searchData)
  ])



  const getTotalPage = () => {
    let total

    // for search
    if (searchData && searchData.status) {
      total = totalPageSearch || 0
    } else {
      total = totalPage
    }

    return total
  }

  const getCurrentPage = () => {
    let current

    // for search
    if (searchData && searchData.status) {
      current = currentPageSearch || 1
    } else {
      current = currentPage
    }

    return current
  }

  const getListNumer = () => {
    let currentPage = getCurrentPage()
    let totalPage = getTotalPage()

    // จำนวนหน้าที่แสดง >= หน้าทั้งหมด (ขวา)
    if (showPage >= totalPage) {
        setLastMore(false)
        setFirstMore(false)
        setStartNumber(2)
        setEndNumber(totalPage - 1)

    // จำนวนหน้าที่แสดง < หน้าทั้งหมด
    } else {

      // หน้าปัจจุบัน > จำนวนที่แสดง (ขวา)
      if (currentPage < showPage) {
        setLastMore(true)
        setFirstMore(false)
        setStartNumber(2)
        setEndNumber(showPage)

        // หน้าปัจจุบัน > จำนวนที่แสดง
      } else {

        // (ซ้าย)
        if (currentPage + 3 >= totalPage) {
          setLastMore(false)
          setFirstMore(true)
          setStartNumber(totalPage - showPage + 1)
          setEndNumber(totalPage - 1)

          // (กลาง)
        } else {
          let num = Math.floor((showPage - 2) / 2)

          setLastMore(true)
          setFirstMore(true)
          setStartNumber(currentPage - num)
          setEndNumber(currentPage + num)
        }
      }
    }
  }

  useEffect(() => {
    if (props.foldersManage) {
      getUrlParams()
    }
  }, [
    props.match.params && props.match.params.folders_id
  ])


  const fetchItems = () => {
    getUrlParams()
  }

  const refresh = () => {
    getUrlParams()
  }

  const fecthDatas = ({ searchValue, currentPage, currentPageSearch }) => {
    const { fetchAction } = props

    if (searchValue === null) {
      let pageNumber = currentPage

      let fecthParams = {
        body: {
          ...fetchAction.body,
          pageNumber
        },
        loadId: !props.stateLoading && props.id,
        stateLoading: props.stateLoading,
        addGroupId: fetchAction.addGroupId,
        addGroupIdReplace: true,
      }

      props.dispatch(fetchAction.action(fetchAction.query, fecthParams)).then(res => {
        resSetTotalPage(res)
      })

    } else {
      search({ searchValue, currentPageSearch })
    }
  }

  const search = ({ searchValue, currentPageSearch }) => {
    clearTimeout(timer)

    const fetch = props.fetchAction
    const loadId = !props.stateLoading && props.id
    const pageNumber = currentPageSearch
    const value = searchValue

    if (!props.load) {
      props.dispatch(addLoad(props.id))
    }

    const newTimer = setTimeout(() => {
      onSearchDataHook(props.dispatch, value, fetch, {
        pageNumber,
        loadId
      }).then(res => resSetTotalPage(res))
    }, 1000)

    setTimer(newTimer)
  }

  useEffect(() => {
    getListNumer()
  }, [
    totalItems,
    searchData && searchData.status,
    totalPage,
    totalPageSearch,
    currentPage,
    currentPageSearch
  ])

  const resSetTotalPage = (res) => {
    if (res.success) {
      const totalItems = res.count || 0
      const totalPage = Math.ceil(totalItems / itemsPerPage)

      setTotalItems(totalItems)
      setTotalPage(totalPage)
    }
  }



  const getUrlParams = (search, reload) => {
    let currentPage, currentPageSearch, searchValue

    if (search) {
      const urlParams = new URLSearchParams(search)
      const { currentPageName, currentPageSearchName } = getCurrentPageName(props)

      currentPage = urlParams.get(currentPageName) || 1
      currentPageSearch = urlParams.get(currentPageSearchName) || 1
      searchValue = urlParams.get('search')

    } else if (reload) {
      const urlParams = new URLSearchParams(props.location.search)
      const { currentPageName, currentPageSearchName } = getCurrentPageName(props)

      currentPage = urlParams.get(currentPageName) || 1
      currentPageSearch = urlParams.get(currentPageSearchName) || 1
      searchValue = null

    } else {
      currentPage = 1
      currentPageSearch =  1
      searchValue = null
    }

    currentPage = parseInt(currentPage)
    currentPageSearch = parseInt(currentPageSearch)

    // set state
    setCurrentPage(currentPage)
    setCurrentPageSearch(currentPageSearch)
    setSearchValue(searchValue)
    setTotalPage(0)

    // fecthDatas
    fecthDatas({ searchValue, currentPage, currentPageSearch })
  }


  const previousPage = () => {
    const currentPage = getCurrentPage()
    const url = getUrl(currentPage-1)

    if (currentPage > 1) {
      goto(url)
    }
  }

  const nextPage = () => {
    const currentPage = getCurrentPage()
    const totalPage = getTotalPage()
    const url = getUrl(currentPage+1)

    if (currentPage < totalPage) {
      goto(url)
    }
  }

  const getUrl = (pageNumber) => {
    const { pathname, search } = props.location
    let url

    if (pageNumber) {
      const searchParams = new URLSearchParams(search)
      const { currentPageName, currentPageSearchName } = getCurrentPageName(props)

      // for searchData
      if (searchData && searchData.status) {
        if (searchParams.get(currentPageSearchName)) {
          searchParams.set(currentPageSearchName, pageNumber)
        } else {
          searchParams.append(currentPageSearchName, pageNumber)
        }

        if (searchParams.get('search')) {
          searchParams.set('search', searchData.value)
        } else {
          searchParams.append('search', searchData.value)
        }

      // for normal
      } else {
        if (searchParams.get(currentPageName)) {
          searchParams.set(currentPageName, pageNumber)
        } else {
          searchParams.append(currentPageName, pageNumber)
        }
      }

      url = `${pathname}?${searchParams.toString()}`
    } else {
      url = `${pathname}`
    }

    return url
  }

  const goto = (url) => {
    const { history } = props

    history.push(url)

    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }

  const showPagination = () => {
    let totalPage = getTotalPage()
    let showPagination

    if (totalPage > 1) {
      if (searchData && searchData.status) {
        if (searchData.data.length > 0 && !props.preLoading) showPagination = true
        else showPagination = false
      } else {
        if (totalItems > 0 && !props.preLoading) showPagination = true
        else showPagination = false
      }
    } else {
      showPagination = false
    }

    return showPagination
  }

  const getPageNumber = () => {
    let totalPage = getTotalPage()
    let numberArray = Array.apply(1, Array(totalPage))
    let listNumber = []

    numberArray.map((button, i) => {
      let number = i+1

      if (number >= startNumber && number <= endNumber && number < totalPage) {
        listNumber.push(pageNumberComponent(number))
      }
    })

    return listNumber
  }

  const pageNumberComponent = (number) => {
    const url = getUrl(number)
    const currentPage = getCurrentPage()

    return (
      <li key={number} className={`${currentPage === number && css.active}`}>
        <a onClick={() => goto(url)}>
          {number}
        </a>
      </li>
    )
  }


  const items = props.itemsData
  const currentPageValue = getCurrentPage()
  const totalPageValue = getTotalPage()

  let totalItemsValue = searchData && searchData.status ? totalItemsSearch : totalItems
  let firstItem = (currentPageValue-1) * props.itemsPerPage+1
  let lastItem = currentPageValue * props.itemsPerPage <= totalItemsValue ? currentPageValue * props.itemsPerPage : totalItemsValue

  lastItem = lastItem <= totalItemsValue ? lastItem : totalItemsValue

  let itemsAmount = searchData && searchData.status ? searchData.data.length : items.length
  let currentItemsAmount = lastItem - firstItem + 1
  let itemDifference = currentItemsAmount - itemsAmount

  currentItemsAmount = currentItemsAmount - itemDifference
  totalItemsValue = totalItemsValue - itemDifference
  lastItem = firstItem + currentItemsAmount - 1

  return (
    <div>
      { searchData && searchData.status &&
        <div className="font-1-2 pd-left-3">
          { searchData.data.length === 0 ?
            <span>ไม่มีผลการค้นหาของ "{ searchData.value }" </span>
          :
            <span>ผลการค้นหาของ "{ searchData.value }"</span>
          }
        </div>
      }

      { !props.hiddenPaginationNumber &&
        <Fragment>
          { totalItemsValue > 0 && !props.preLoading &&
            <div className="font-0-8 mg-bottom-10 pd-left-3">
              <span>หน้าที่ { currentPageValue } </span>
              { currentItemsAmount > 0 ?
                <span>แสดง {firstItem}-{lastItem} </span>
              :
                <span>แสดง 0 </span>
              }
              <span>จาก {getNumeral(totalItemsValue, 0)} รายการ</span>
            </div>
          }
        </Fragment>
      }

      <div>
        { props.children }
      </div>

      { showPagination() &&
        <div className={css.box}>
          <ul className={css.pagination}>

            <li className={currentPageValue > 1 ? 'waves-effect' : css.disabled}>
              <a onClick={previousPage}>
                <i className="material-icons i-middle">chevron_left</i>
              </a>
            </li>

            { pageNumberComponent(1) /* first number */ }

            { firstMore &&
              <li className={css.more}>
                <i className="material-icons i-middle">more_horiz</i>
              </li>
            }

            { getPageNumber() /* start number - end number */ }

            { lastMore &&
              <li className={css.more}>
                <i className="material-icons i-middle">more_horiz</i>
              </li>
            }

            { pageNumberComponent(totalPageValue) /* last number */ }


            <li className={currentPageValue < totalPageValue ? 'waves-effect' : css.disabled}>
              <a onClick={nextPage}>
                <i className="material-icons i-middle">chevron_right</i>
              </a>
            </li>
          </ul>
        </div>
      }
    </div>
  )
})

// function mapStateToProps(store, props) {
//   return {
//     preLoading: store.app.loads.filter(load => load === props.id)[0],
//   }
// }

Pagination.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  searchData: PropTypes.object.isRequired,
  itemsData: PropTypes.array.isRequired,
}

export default Pagination

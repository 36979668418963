import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Components Global
import PageItems from '../PageItems'
import { LayoutsDescriptions, Layouts1, Layouts2 } from '../LayoutsDescriptions'
import ItemSelected from '../ItemSelected'

// Components Folders
import FoldersManage from '../../containers/dashboard/Folders/components/FoldersManage'
import FolderDescriptions from '../../containers/dashboard/Folders/components/FolderDescriptions'
import FolderCreateModal from '../../containers/dashboard/Folders/components/FolderCreateModal'
import FolderUpdateModal from '../../containers/dashboard/Folders/components/FolderUpdateModal'
import FolderMoveModal from '../../containers/dashboard/Folders/components/FolderMoveModal'


// Components Local
import ItemsList from './components/ItemsList'

// Funtions
import { onEditModal, onMoveModal, onDelete } from '../../redux/Folders/FoldersFunctions'


class PageItemsDescriptions extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  selectItem = (itemId) => {
    this.mounted && this.setState({ itemId, folderId: null })

    const { datasSearch } = this.props

    if (this.props.getItem) {
      let item = this.props.datas.filter(data => data._id === itemId)[0]

      if (datasSearch && datasSearch.status) {
        item = datasSearch.data.filter(data => data._id === itemId)[0]
      }

      this.props.getItem(item)
    }
  }

  selectFolder = (folderId) => {
    this.mounted && this.setState({ folderId, itemId: null })
  }

  unSelectFolder = () => {
    this.mounted && this.setState({ folderId: null })
  }

  unSelectItem = () => {
    this.mounted && this.setState({ itemId: null })
  }

  render() {
    const {
      business,
      id,
      title,
      icon,
      match,
      manager,
      fetchAction,
      foldersManage,
      componentTitle,
      componentHeaderList,
      componentList,
      componentDescriptions,
      layoutFull,
      layoutStyle,
      tableClassName,
      attribute,
      datasSearch
    } = this.props

    let selectedData, CompDes

    CompDes = componentDescriptions

    if (this.state.itemId) {
      selectedData = this.props.datas.filter(data => data._id === this.state.itemId)[0]

      if (datasSearch && datasSearch.status) {
        selectedData = datasSearch.data.filter(data => data._id === this.state.itemId)[0]
      }
    }

    if (this.state.folderId) {
      selectedData = this.props.folders.filter(data => data._id === this.state.folderId)[0]
      CompDes = FolderDescriptions
    }


    const folderManager = {
      modalEdit: onEditModal,
      modalMove: true,
      onDelete: onDelete(this.props)
    }

    return (
      <LayoutsDescriptions>

        { foldersManage &&
          <FolderCreateModal match={match} business={business} foldersManage={foldersManage} />
        }

        { foldersManage && this.state.folderId && selectedData &&
          <FolderUpdateModal
            match={match}
            business={business}
            foldersManage={foldersManage}
            selectedData={selectedData}
            folderId={this.state.folderId}
          />
        }

        { foldersManage && selectedData &&
          <FolderMoveModal
            match={match}
            business={business}
            foldersManage={foldersManage}
            selectedData={selectedData}
            folderId={this.state.folderId}
            itemId={this.state.itemId}
          />
        }

        { this.props.children }

        <Layouts1
          style={{
            right: !componentDescriptions && '0',
            ...layoutStyle || {}
          }}>

          { foldersManage &&
            <ItemSelected location={this.props.location} unSelect={this.unSelectFolder}>
              <FoldersManage
                match={match}
                business={business}
                foldersManage={foldersManage}
                folderId={this.state.folderId}
                selectFolder={this.selectFolder}
              />
            </ItemSelected>
          }

          <PageItems
            id={id}
            match={match}
            className="pd-15"
            foldersManage={foldersManage}
            location={this.props.location}
            history={this.props.history}
            title={title}
            icon={icon}
            fetchAction={fetchAction}
            items={this.props.datas}
            searchData={this.props.datasSearch}
            unSelect={this.unSelectItem}
            componentTitle={componentTitle}
            addItemModal={this.props.addItemModal}
            btnAddMore={this.props.btnAddMore}
            layoutFull={layoutFull}>

            <ItemsList
              affiliate_customers_id={this.props.affiliate_customers_id}
              tableClassName={tableClassName}
              dispatch={this.props.dispatch}
              itemId={this.state.itemId}
              selectItem={this.selectItem}
              componentHeaderList={componentHeaderList}
              component={componentList}
              attribute={attribute}
              business={business}
              history={this.props.history}
            />
          </PageItems>
        </Layouts1>

        { componentDescriptions &&
          <Layouts2
            id="blockUnSelect"
            selectedData={selectedData}
            unSelect={this.unSelectItem}
            unSelectFolder={this.unSelectFolder}
            folderId={this.state.folderId}
            foldersManage={foldersManage}
            manager={this.state.folderId ? folderManager : manager}
            layoutFull={layoutFull}>

            <CompDes
              data={selectedData}
              business={business}
              attribute={attribute}
              foldersManage={foldersManage}
            />
          </Layouts2>
        }
      </LayoutsDescriptions>
    )
  }
}


const mapStateToProps = (state, props) => {
  let datas = state[props.storeName].data
  let datasSearch = state[props.storeName].search

  if (props.datasFilter) {
    datas = props.datasFilter(datas) || []

    if (datasSearch && datasSearch.status === true) {
      datasSearch.data = props.datasFilter(datasSearch.data)
    }

    if (props.foldersManage) {
      const folders_id = props.match.params.folders_id || null

      datas = datas.filter(folder => folder.folders_id === folders_id)
    }
  }

  return {
    datas,
    datasSearch,
    folders: state.folders.data
  }
}

PageItemsDescriptions.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  manager: PropTypes.object.isRequired,
  storeName: PropTypes.string.isRequired
}

export default connect(mapStateToProps)(PageItemsDescriptions)

import React, { Fragment, useEffect, useState } from 'react'

// Components Global
import InputDatePicker from '../../../../../../components/Inputs/InputDatePicker'
import DatePickerBetween from '../../../../../../components/Inputs/DatePickerBetweenV2'
import InputNumeral from '../../../../../../components/Inputs/InputNumeral'
import InputMultiNumberals from '../../../../../../components/Inputs/InputMultiNumberals'
import Textarea from '../../../../../../components/Inputs/Textarea'
import InputSelectDropdown from '../../../../../../components/Inputs/InputSelectDropdown'
import Checkbox from '../../../../../../components/Inputs/Checkbox'

// Components Rubber Customers
import RubberCustomerInputSelect from '../../../RubberCustomerSellers/components/RubberCustomerInputSelect'
import { CustomerItemProfileAvater } from '../../../RubberCustomerBuyers/components/CustomersUserInterface'

// Actions
import { customerMemberValue } from '../../../../../../redux/RubberCustomers/RubberCustomersValue'

// Values
import { getUseRubberProductTypes, getRubberProductItems } from '../../../../../../redux/RubberProducts/RubberProductsValues'
import { settingWarehouseValue } from '../../../../../../redux/RubberSettings/RubberSettingsValue'
import SelectDefault from '../../../../../../components/Inputs/SelectDefault'


export const DateSearch = (props) => {
  return (
    <div>
      <DatePickerBetween
        id="date-search"
        onChange={props.onChange}
        bbYear
        dateFormat="D MMM YYYY"
        timeFormat="เวลา H:mm น."
        start={{
          id: 'startDate',
          label: 'วันที่เริ่มต้น',
          value: props.dateStart,
          col: 'column col-xxs-12 col-xs-12 col-sm-6 col-md-6 pd-right-10-sm pd-right-10-md pd-right-10-lg pd-right-10-xlg'
        }}
        end={{
          id: 'dueDate',
          label: 'วันที่สิ้นสุด',
          value: props.dateEnd,
          col: 'column col-xxs-12 col-xs-12 col-sm-6 col-md-6 pd-left-10-sm pd-left-10-md pd-left-10-lg pd-left-10-xlg'
        }}
      />
    </div>
  )
}

export const PurchaseDate = (props) => {
  return (
    <InputDatePicker
      id="date"
      required
      label="วันที่ (วัน/เดือน/พ.ศ.)"
      dateNow
      value={props.value}
      onChange={props.onChange}
      iconSize={35}
      inputClassName="height-35 line-height-35 font-0-8"
      labelClassName="font-0-8"
      bbYear
    />
  )
}


export const PurchaseCustomerSeller = (props) => {
  return (
    <RubberCustomerInputSelect
      {...props}
      label="เจ้าของสวน"
      onChange={props.onChange}
      customer={props.customer}
      where="status=open,type=seller"
      inputClassName="height-35 line-height-35 font-0-8"
      labelClassName="font-0-8"
      chipSize={25}
      required={props.required}
      notUpdateDataSelected={props.notUpdateDataSelected}
    />
  )
}

export const PurchaseEmployee = (props) => {
  const lang = props.lang || 'local'
  const id = `customers_employees_id`
  const { customer } = props
  const employee = customerMemberValue({ customer, member: props.employee, lang })
  const employees = customer && customer.members.filter(member => member.type === 'employee')

  const item = (employee) => {
    employee = customerMemberValue({ customer, member: employee, lang })

    return (
      <div className="pd-10 border-bottom-efefef border-bottom-1">
        <table>
          <tbody>
            <tr>
              <td width="40" className="pd-0 text-left">
                <div className="width-30 height-30 line-height-30">
                  <CustomerItemProfileAvater circle item={employee} />
                </div>
              </td>
              <td className="pd-5 line-height-15">
                <div className="font-0-8">{ employee.employeeFullNameLang }</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  let avatarIcon, value

  if (employee) {
    value = employee.employeeFullNameLang
    avatarIcon = {
      iconFa: employee.typeIcon,
      color: '#333',
      bgColor: '#fff',
    }
  }

  let disabled

  if (!customer || (customer && customer.useEmployee == false)) {
    disabled = true
  }

  let label = 'ชื่อแปลง/คนกรีดยาง'

  return (
    <InputSelectDropdown
      item={item}
      datas={employees}
      select={props.onChange}
      label={label}
      placeholder={label}
      id={id}
      disabled={disabled}
      dataSeleted={props.employee}
      value={value}
      type="text"
      chipValue={value}
      avatarIcon={avatarIcon}
      selectId="customers_employees_id"
      chipSize={25}
      inputClassName="height-35 line-height-35 font-0-8"
      labelClassName="font-0-8"
      linkClassName="pd-0"
      required={(customer && customer.useEmployee)}
    />
  )
}

export const PurchaseNote = (props) => {
  return (
    <Textarea
      id="note"
      label="หมายเหตุ"
      placeholder="หมายเหตุ"
      inputClassName="height-35 line-height-35 font-0-8"
      inputClassNameModify="min-height-35 pd-top-0 pd-bottom-0"
      labelClassName="font-0-8"
      value={props.value}
    />
  )
}

export const PurchaseLatexAndBucketWeightsList = (props) => {
  const { digit } = props.type.buyWeightDCM

  return (
    <InputMultiNumberals
      id="latexAndBucketWeightsList"
      label="รายการ นน.รวมถัง"
      placeholder="รายการ นน.รวมถัง"
      iconSize={30}
      inputClassName="height-35 line-height-35 font-0-8"
      labelClassName="font-0-8"
      onChange={props.onChange}
      value={props.value}
      decimal={digit}
      responsive={{
        xxs: 1,
        xs: 2,
        sm: 2,
        md: 3,
        lg: 4,
        xlg: 4
      }}
      required
    />
  )
}

export const PurchaseLatexAndBucketWeight = (props) => {
  const { digit } = props.type.buyWeightDCM

  return (
    <InputNumeral
      id="latexAndBucketWeight"
      label="สรุป นน.รวมถัง"
      placeholder="สรุป นน.รวมถัง"
      inputClassName="color-000 bg-eee height-30 line-height-30 text-center"
      labelClassName="color-000 mg-bottom-4 font-0-9"
      inputStyle={{
        border: '1px dotted rgba(0, 0, 0, 0.26)'
      }}
      value={props.value}
      decimal={digit}
      test
      disabled
    />
  )
}

export const PurchaseDisable = (props) => {
  let digit

  if (props.CDM) {
    digit = props.type[props.CDM].digit
  }

  return (
    <InputNumeral
      id="purchaseDisable"
      label={props.label}
      inputClassName="color-000 bg-eee height-30 line-height-30 text-center font-0-8"
      labelClassName="color-000 mg-bottom-4 font-0-8"
      inputStyle={{
        border: '1px dotted rgba(0, 0, 0, 0.26)'
      }}
      decimal={digit}
      value={props.value}
      disabled
    />
  )
}

export const PurchaseBucketWeight = (props) => {
  const { digit } = props.type.buyWeightDCM

  return (
    <InputNumeral
      id="bucketWeight"
      label="นน.ถัง"
      placeholder="นน.ถัง"
      inputClassName="text-center height-35 line-height-35 font-0-8"
      labelClassName="font-0-8"
      onChange={props.onChange}
      value={props.value}
      valueForSplace="0"
      decimal={digit}
    />
  )
}

export const PurchaseNetWeight = (props) => {
  const { digit } = props.type.buyWeightDCM

  return (
    <InputNumeral
      id="netWeight"
      label="นน.สด"
      placeholder="นน.สด"
      inputClassName="color-000 bg-eee height-30 line-height-30 text-center font-0-8"
      labelClassName="color-000 mg-bottom-4 font-0-8"
      inputStyle={{
        border: '1px dotted rgba(0, 0, 0, 0.26)'
      }}
      disabled
      value={props.value}
      valueForSplace="0"
      decimal={digit}
    />
  )
}

export const PurchaseLatexPercent = (props) => {
  const { digit } = props.type.buyPercentDCM
  const label = props.label || '% น้ำยาง'

  return (
    <InputNumeral
      id="latexPercent"
      label={label}
      placeholder={label}
      onChange={props.onChange}
      value={props.value}
      maxValue="100"
      required
      inputClassName="text-center height-35 line-height-35 font-0-8"
      labelClassName="font-0-8"
      decimal={digit}
    />
  )
}

export const PurchaseDryLatexWeight = (props) => {
  const { digit } = props.type.buyDryLatexWeightDCM

  return (
    <InputNumeral
      id="dryLatexWeight"
      label="นน.ยางแห้ง"
      placeholder="นน.ยางแห้ง"
      inputClassName="color-000 bg-eee height-30 line-height-30 text-center font-0-8"
      labelClassName="color-000 mg-bottom-4 font-0-8"
      inputStyle={{
        border: '1px dotted rgba(0, 0, 0, 0.26)'
      }}
      disabled
      value={props.value}
      valueForSplace="0"
      decimal={digit}
    />
  )
}

export const PurchasePrice = (props) => {
  const { digit } = props.type.buyPriceDCM

  return (
    <InputNumeral
      id="price"
      label="ราคา"
      placeholder="ราคา"
      inputClassName="text-center height-35 line-height-35 font-0-8"
      labelClassName="font-0-8"
      onChange={props.onChange}
      value={props.value}
      decimal={digit}
      required
    />
  )
}

export const PurchaseAddPrice = (props) => {
  const { digit } = props.type.buyPriceDCM

  return (
    <InputNumeral
      id="addPrice"
      label="บวกเพิ่ม"
      placeholder="บวกเพิ่ม"
      inputClassName="text-center height-35 line-height-35 font-0-8"
      labelClassName="font-0-8"
      value={props.value || 0}
      onChange={props.onChange}
      decimal={digit}
      valueForSplace="0"
    />
  )
}

export const PurchaseAddPricePercent = (props) => {
  const [use, setUse] = useState()
  const [price, setPrice] = useState()

  const { digit } = props.type.buyPriceDCM
  let buyPricePercent = props.type && props.type.buyPricePercent
  buyPricePercent = buyPricePercent && JSON.parse(buyPricePercent) || []

  useEffect(() => {
    let check

    if (props.event === 'create') {
      if (props.type.buyUsePricePercent) {
        check = true
      }
    } else {
      if (props.value) {
        check = true
      }
    }

    setUse(check)
  }, [])

  useEffect(() => {
    if (use) {
      const price = getAddPricePercent(props.latexPercent)
      setPrice(price)
    } else {
      setPrice(0)
    }
  }, [props.latexPercent])

  useEffect(() => {
    if (use) {
      const price = getAddPricePercent(props.latexPercent)
      setPrice(price)
    } else {
      setPrice(0)
    }
  }, [use])

  useEffect(() => {
    props.onChange(price)
  }, [price])


  const getAddPricePercent = (latexPercent) => {
    if (latexPercent) {
      buyPricePercent.map((percent, i) => {
        const start = parseFloat(percent.start)
        const end = parseFloat(percent.end)
        const price = parseFloat(percent.price)

        if (latexPercent >= start && latexPercent <= end) {
          percent.selected = {
            start, end, price
          }
        }
      })

      let selected = buyPricePercent.filter(v => v.selected)[0]
      selected = selected && selected.selected

      if (selected) {
        return selected.price
      }
    }
  }

  const handleChangeUse = (check) => {
    setUse(check)
  }

  return (
    <div className="pst-relative">
      <div className="pst-absolute bottom--2 left-5" style={{ zIndex: '1' }}>
        <Checkbox
          id="addPricePercentStatus"
          size="sm"
          notSave
          value={use}
          onChange={handleChangeUse}
        />
      </div>

      <InputNumeral
        id="addPricePercent"
        label="บวกเพิ่มตาม % น้ำยาง"
        placeholder="บวกเพิ่มตาม % น้ำยาง"
        inputClassName="color-000 bg-eee height-30 line-height-30 text-center font-0-8"
        labelClassName="color-000 mg-bottom-4 font-0-8"
        inputStyle={{
          border: '1px dotted rgba(0, 0, 0, 0.26)'
        }}
        value={price}
        decimal={digit}
        valueForSplace="0"
        disabled
      />
    </div>
  )
}

export const PurchaseNetPrice = (props) => {
  const { digit } = props.type.buyPriceDCM

  return (
    <InputNumeral
      id="netPrice"
      label="ราคาสุทธิ"
      placeholder="ราคาสุทธิ"
      inputClassName="color-000 bg-eee height-30 line-height-30 font-0-8 text-center"
      labelClassName="color-000 mg-bottom-4 font-0-8"
      inputStyle={{
        border: '1px dotted rgba(0, 0, 0, 0.26)'
      }}
      disabled
      decimal={digit}
      value={props.value || 0}
      valueForSplace="0"
    />
  )
}

export const PurchaseTotalPrice = (props) => {
  const { digit } = props.type.buyTotalPriceDCM

  return (
    <InputNumeral
      id="totalPrice"
      label="รวมเป็นเงินทั้งหมด"
      labelClassName="mg-bottom-4 color-000 font-0-8"
      inputClassName="color-fff bg-000 height-45 line-height-45 text-right pd-right-10 font-1-8"
      inputStyle={{
        border: '1px dotted rgba(0, 0, 0, 0.26)',
        boxSizing: 'border-box'
      }}
      disabled
      decimal={digit}
      value={props.value || 0}
      valueForSplace="0"
    />
  )
}



export const ProductItems = (props) => {
  const types = getUseRubberProductTypes()
  const [items, setItems] = useState([])
  const { product } = props
  const priceItems = product && product.priceItems

  useEffect(() => {
    let newItems

    if (!priceItems) {
      newItems = getRubberProductItems({ types })
    } else {
      newItems = priceItems
    }

    setItems(newItems)
  }, [])

  const handlePriceChange = (i, value) => {
    const newItems = [...items]

    if (newItems[i]) {
      newItems[i].price = value
      setItems(newItems)
    }
  }

  let useItems

  if (priceItems) {
    useItems = priceItems
  } else {
    useItems = types
  }

  return (
    <Fragment>
      { useItems.map((item, i) => {
        return (
          <div key={i}>
            <InputNumeral
              required
              i={i}
              label={`ราคา${item.name}`}
              placeholder={`ราคา${item.name}`}
              id="price"
              value={item.price}
              notSave
              maxValue="1000"
              notMinus
              decimal="2"
              decimalFull
              onChange={handlePriceChange}
            />
          </div>
        )
      }) }

      <input type="hidden" data-id="items" value={JSON.stringify(items)} />
    </Fragment>
  )
}


export const PurchaseWarehouse = (props) => {
  const [warehouseValue, setWarehouseValue] = useState()

  useEffect(() => {
    const { warehouse } = props
    if (warehouse) {
      setWarehouseValue(warehouse)
    }
  }, [props.warehouse])

  const handleWarehouseChange = (value) => {
    setWarehouseValue(value)
  }

  const lang = props.lang || 'local'
  const id = `settings_warehouses_id`
  const label = "เลือกบ่อน้ำยาง"
  const { setting, type } = props
  const warehouses = setting.warehouses.filter(warehouse => warehouse.types_id === type._id)

  const item = (warehouse) => {
    warehouse = settingWarehouseValue({ warehouse, lang })

    return (
      <div className="pd-10 border-bottom-efefef border-bottom-1">
        <table>
          <tbody>
            <tr>
              <td width="40" className="pd-0 center">
                <div className="width-30 height-30 line-height-30">
                  <i className="fas fa-warehouse"></i>
                </div>
              </td>
              <td className="pd-5 line-height-15">
                <div className="font-0-8">{ warehouse.nameLang }</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  let warehouse

  if (warehouseValue) {
    const warehouseForSetting = warehouses.filter(v => v._id === warehouseValue._id)[0]

    if (warehouseForSetting) {
      warehouse = warehouseForSetting
    }
  }

  let avatarIcon, value

  if (warehouse) {
    warehouse = settingWarehouseValue({ warehouse, lang })
    value = warehouse.nameLang
    avatarIcon = {
      iconFa: "fas fa-warehouse",
      color: '#333',
      bgColor: '#fff',
    }
  }

  return (
    <Fragment>
      <InputSelectDropdown
        item={item}
        datas={warehouses}
        select={handleWarehouseChange}
        label={label}
        placeholder={label}
        id={id}
        dataSeleted={warehouse}
        value={value}
        type="text"
        chipValue={value}
        avatarIcon={avatarIcon}
        selectId={id}
        chipSize={25}
        inputClassName="height-35 line-height-35 font-0-8"
        labelClassName="font-0-8"
        linkClassName="pd-0"
        required
      />

      <input data-id="warehouse" type="hidden" value={warehouse && JSON.stringify(warehouse) } />
    </Fragment>
  )
}


export const PurchaseReserve = (props) => {
  const { maxAmount, latexPercent } = props
  const [use, setUse] = useState()
  const [reserveAmount, setReserveAmount] = useState()

  const handleChangeUse = (check) => {
    setUse(check)
  }

  const handleChangeReserveAmount = (value) => {
    setReserveAmount(parseFloat(value))
  }

  useEffect(() => {
    if (props.use !== undefined) {
      setUse(props.use)
    }

    if (props.reserveAmount !== undefined) {
      setReserveAmount(props.reserveAmount)
    }
  }, [props.use, props.reserveAmount])

  useEffect(() => {
    if (use !== undefined) {
      if (!use) {
        setReserveAmount(0)
      } else {
        setReserveAmount(props.reserveAmount)
      }
    }
  }, [use])

  useEffect(() => {
    if (reserveAmount > maxAmount) {
      setReserveAmount(maxAmount)
    }
  }, [reserveAmount])

  const disabled = !use


  return (
    <div className="pst-relative">
      <table>
        <tbody>
          <td className="pd-0 width-50">
            <div className="pst-absolute top-20 left-7" style={{ zIndex: '1' }}>
              { props.use !== undefined ?
                <i className="material-icons font-30 color-success">check_box</i>
              :
                <Checkbox
                  id="addPricePercentStatus"
                  size="sm"
                  notSave
                  value={use}
                  onChange={handleChangeUse}
                  disabled
                />
              }
            </div>
          </td>
          <td className="pd-0">
            <InputNumeral
              id="reserveAmount"
              label="จำนวนเงินสำรองจ่าย"
              placeholder="จำนวนเงินสำรองจ่าย"
              inputClassName={`"color-000 ${disabled && 'bg-eee'} height-35 line-height-35 font-0-8"`}
              labelClassName="color-000 mg-bottom-4 font-0-8"
              inputStyle={{
                border: disabled && '1px dotted rgba(0, 0, 0, 0.26)'
              }}
              onChange={handleChangeReserveAmount}
              required={use}
              value={reserveAmount}
              decimal={0}
              maxValue={maxAmount || 0}
              disabled={disabled}
            />

            { use &&
              <Fragment>
                <input data-id="reserve" type="hidden" value={use} />
                <input data-id="reserveStatus" type="hidden" value="estimate" />
                <input data-id="latexPercentEstimate" type="hidden" value={latexPercent} />
              </Fragment>
            }
          </td>
        </tbody>
      </table>
    </div>
  )
}



export const PurchaseReserveStatus = (props) => {
  return (
    <SelectDefault
      active
      id="reserveStatus"
      label="กรอกเปอร์เซ็นต์ยางจริง"
      value="finish"
      required
      options={[
        { value: 'finish', name: 'กรอกแล้ว' },
        { value: 'estimate', name: 'ยังไม่กรอก' },
      ]}
    />
  )
}

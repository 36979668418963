import React, { Component, Fragment, useRef, useState } from 'react'
import { connect } from 'react-redux'

// Componets Global
import { Row, Column } from '../../../../../components/Columns'
import ModalFixed from '../../../../../components/Modals/ModalFixed'
import Form from '../../../../../components/Form'
import BtnSubmit from '../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../util/getFormValue'

// Components Local
import {
  CustomerBranchName,
  CustomerBranchNo,
  CustomerAddressName,
  CustomerAddressTel,
  CustomerAddressNo,
  CustomerAddressGeo,
  CustomerAddreesLocation
} from '../CustomerForms/CustomerForms'

// Actions Redux
import { updateCustomer } from '../../../../../redux/Customers/CustomersActions'
import { updateAuthUserCustomer } from '../../../../../redux/Auth/AuthActions'

// Functions
import { updateOrderCustomer } from '../../../../../redux/Orders/OrdersFunctions'


const CustomerAddressCreateModal = (props) => {
  const formRef = useRef()
  const [geo, setGeo] = useState()

  const handleGeoChange = (geo) => {
    setGeo(geo)
  }

  const handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(formRef.current)
    const { customer, order, business, title, updateCart, updateAuth, selectAddress, forUser, addForCart, addForOrder } = props
    const msg =  title || 'ที่อยู่จัดส่ง'

    if (addForCart) {
      addForCart({ params, formName })

    } else if (addForOrder) {
      addForOrder({ params, formName })

    } else {
      let authName = "user"

      if (forUser && !order) {
        authName = "user"
      } else {
        authName = "admin"
      }

      if (params) {
        params.updateType = 'push'
        params.updateElement = 'addresses'

        let updateFn

        const actionParams = {
          body: params,
          loadId: formName,
          modalId: formName,
          toastMsg: (!updateCart && !selectAddress) && `เพิ่ม${msg}แล้ว`
        }

        if (updateAuth && !order) {
          //console.log('updateAuth')
          updateFn = () => updateAuthUserCustomer(authName, business._id, customer._id, actionParams)
        } else {
          //console.log(authName)

          const query = {
            authName,
            dbNo: business.dbNo,
            businessType: business.type,
            businesses_id: business._id,
            customers_id: customer._id
          }

          updateFn = () => updateCustomer(query, actionParams)
        }

        props.dispatch(updateFn()).then(res => {
          if (res.success) {
            const customer = res.data
            const address = customer.addresses[customer.addresses.length - 1]

            if (order) {
              res.data.customers_addresses_id = address._id
              updateOrderCustomer(props, customer, formName)
            } else {
              if (address) {
                if (updateCart) {
                  updateCart({
                    customers_addresses_id: address._id,
                    formName: 'selectAddressSubmit'
                  })
                }

                if (selectAddress) {
                  selectAddress({
                    address,
                    formName: props.formName
                  })
                }
              }
            }
          }
        })
      }
    }
  }


  const { title, useBranch, delivery } = props
  const headerTitle =  title || 'ที่อยู่จัดส่ง'

  return (
    <Form
      recheckForm
      ref={formRef}
      name={props.id}
      submit={(e) => handleSubmit(e, props.id)}>

      <ModalFixed
        id={props.id}
        title={`เพิ่ม${headerTitle}`}
        headerClassName="height-50"
        bodyClassName="top-50 bottom-60 pd-20"
        footerClassName="height-60 pd-10"
        width="650px">

        <Row>
          { delivery &&
            <Column col="column col-xs-12">
              <CustomerAddreesLocation
                {...props}
                onChange={handleGeoChange}
              />
            </Column>
          }

          <Column col="column col-xs-12">
            <CustomerAddressName />
          </Column>
          <Column col="column col-xs-12">
            <CustomerAddressTel />
          </Column>

          { useBranch &&
            <Fragment>
              <Column col="column col-xs-12">
                <CustomerBranchName required />
              </Column>

              <Column col="column col-xs-12">
                <CustomerBranchNo required />
              </Column>
            </Fragment>
          }

          <Column col="column col-xs-12">
            <CustomerAddressNo />
          </Column>
          <Column col="column col-xs-12">
            <CustomerAddressGeo value={geo} />
          </Column>
        </Row>

        <div>
          <BtnSubmit
            loadingId={props.id}
            icon="send"
            iconAlign="left"
            text={`เพิ่ม${headerTitle}`}
            className="font-1-0 height-40 line-height-40 width-full btn btn-submit"
            btnDisabled
          />
        </div>
      </ModalFixed>
    </Form>
  )
}

export default connect()(CustomerAddressCreateModal)

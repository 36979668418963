import React from 'react'

// Components Global
import { Column, Row } from '../../../../../../components/Columns'

// Actions
import { openModal } from '../../../../../../redux/App/AppActions'

// Components RubberSaleForms

// Components Local
import {
  ReceivableDate,
  ReceivableAmount,
  ReceivableNote,
  ReceivableCustomerSeller,
  ReceivableEmployee,
  ReceivableCustomerMember
} from '../ReceivableFroms'


const ReceivableDetailsForm = (props) => {
  const { customer, employee, member } = props

  return (
    <Row className="row-dp-flex mg-left--10 mg-right--10">
      <Column col="column col-xxs-12 col-xs-6 col-md-4 pd-left-10 pd-right-10">
        <ReceivableDate value={props.data && props.data.date} />
      </Column>
      <Column col="column col-xxs-12 col-xs-6 col-md-8 pd-left-10 pd-right-10">
        <ReceivableCustomerSeller
          {...props}
          onChange={props.handleSelectCustomer}
          notUpdateDataSelected
          required
        />
      </Column>

      <Column col="column col-xxs-12 col-xs-6 pd-left-10 pd-right-10">
        <ReceivableEmployee
          {...props}
          onChange={props.handleSelectEmployee}
          notUpdateDataSelected
        />
      </Column>

      <Column col="column col-xxs-12 col-xs-6 pd-left-10 pd-right-10">
        <ReceivableCustomerMember
          {...props}
          onChange={props.handleSelectMember}
          notUpdateDataSelected
        />
      </Column>

      <Column col="column col-xxs-12 pd-left-10 pd-right-10">
        <ReceivableAmount value={props.data && props.data.amount} />
      </Column>

      <Column col="column col-xxs-12 pd-left-10 pd-right-10">
        <ReceivableNote value={props.data && props.data.note}  />
      </Column>
    </Row>
  )
}

export default ReceivableDetailsForm

// Redux Actions
import {
  createPage,
  updatePage,
  removePage,
  uploadImagesPage
} from './PagesActions'

const title = "หน้า"

// Main
export const startWeb = (props, business, site, lang='local') => {
  let name

  if (lang === 'local') name = 'หน้าแรก'
  else if (lang === 'en') name = 'home'

  const params = {
    businesses_id: business._id,
    sites_id: site._id,
    status: 'open',
    path: 'index',
    'name.local': name,
    'title.local': name,
  }

  props.dispatch(createPage(business._id, site._id, {
    body: params,
    loadId: 'startWeb',
    toastMsg: 'สร้างหน้าใหม่แล้ว'
  }))
}

const handleChangeStatus = ({ props, updateParams, toastMsg }) => {
  return (id) => {
    props.dispatch(updatePage(props.business._id, id, {
      body: updateParams,
      toastMsg: toastMsg,
      loadId: id,
      confirmId: id
    }))
  }
}

const handleChangeSinglePageStatus = ({ props, updateParams, toastMsg }) => {
  const { business, main, contentGroupPage, actionSinglePage } = props
  const action = actionSinglePage

  return (id) => {
    if (!main) {
      updateParams.updateType = 'set'
      updateParams.updateElement = 'contentGroupPages'
      updateParams.updateId = contentGroupPage._id
    }

    if (action.useQuery) {
      action.useQuery._id = action.data._id
      props.dispatch(action.update(action.useQuery, {
        body: updateParams,
        toastMsg: toastMsg,
        loadId: id,
        confirmId: id
      }))

    } else if (action.authName) {
      props.dispatch(action.update(action.authName, business._id, action.data._id, {
        body: updateParams,
        toastMsg: toastMsg,
        loadId: id,
        confirmId: id
      }))

    } else {
      props.dispatch(action.update(business._id, action.data._id, {
        body: updateParams,
        toastMsg: toastMsg,
        loadId: id,
        confirmId: id
      }))
    }

    /*props.dispatch(update(business._id, data._id, {
      body: updateParams,
      toastMsg: toastMsg,
      loadId: id,
      confirmId: id
    }))*/
  }
}

const handleDelete = (props, toastMsg, params={}) => {
  return (id) => {
    props.dispatch(removePage(props.business._id, id, {
      toastMsg: toastMsg,
      loadId: id,
      confirmId: id
    })).then(res => {
      if (res.success && params.updateItem) {
        params.updateItem()
      }
    })
  }
}

export const onEditModal = (id) => {
  return {
    modal: { id }
  }
}

export const onEdit = (url) => {
  return { url }
}

export const onView = (url) => {
  return { url }
}

export const onDelete = (props, params) => {
  return {
    label: `ลบ${title}`,
    onConfirm: handleDelete(props, `ลบ${title}นี้ อย่างถาวรแล้ว`, params)
  }
}

export const onTrash = (props) => {
  return {
    label: `ลบ${title}`,
    onConfirm: handleChangeStatus({
      props,
      updateParams: {
        status: 'trash'
      },
      toastMsg: `ลบ${title}นี้แล้ว`
    })
  }
}

export const onRestore = (props) => {
  return {
    label: `กู้คืน${title}`,
    onConfirm: handleChangeStatus({
      props,
      updateParams: {
        status: 'open'
      },
      toastMsg: `กู้คืน${title}แล้ว`
    })
  }
}

export const handleSwitch = ({ props, title, updateParams }) => {
  return {
    label: `${title}นี้`,
    onConfirm: handleChangeStatus({
      props,
      updateParams,
      toastMsg: `${title}นี้แล้ว`
    })
  }
}

export const handleSwitchSinglePage = ({ props, title, updateParams }) => {
  return {
    label: `${title}นี้`,
    onConfirm: handleChangeSinglePageStatus({
      props,
      updateParams,
      toastMsg: `${title}นี้แล้ว`
    })
  }
}


// Images Main
export const uploadImage = (props) => {
  return (id, files, album) => {
    if (files) {
      props.dispatch(uploadImagesPage(props.business._id, props.page._id, files, 'pages', {
        body: {
          updateType: 'push',
          updateElement: 'images',
          album: album
        },
        loadId: id,
        modalId: id,
        progressId: id
      }))
    }
  }
}

export const onDeleteImage = (props) => {
  return (id) => {
    props.dispatch(updatePage(props.business._id, props.page._id, {
      body: {
        updateType: 'pull-image',
        updateElement: 'images',
        deleteId: id
      },
      toastMsg: `ลบภาพนี้แล้ว`,
      loadId: id,
      confirmId: id,
      props: props
    }))
  }
}


export const onDeleteItemImage = (props) => {
  return (id) => {
    props.dispatch(updatePlace(props.business._id, props.place._id, {
      body: {
        updateType: 'pull-sub-image',
        updateElement: 'items',
        updateElementSub: 'items.images',
        updateElementSubPull: 'items.$.images',
        updateId: props.item._id,
        deleteId: id
      },
      toastMsg: `ลบภาพนี้แล้ว`,
      loadId: id,
      confirmId: id,
      props: props
    }))
  }
}


const TagsMenu = ({ path, label="", hidden }) => {
  return [
    {
      name: 'แท็ก',
      title: true,
      hidden
    },
    {
      name: 'แท็ก' + label,
      nameShort: 'แท็ก',
      icon: 'local_offer',
      link: `${path}/tags`,
      hidden
    }
  ]
}

export default TagsMenu

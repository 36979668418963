import React from "react"

// Components Global
import { LayoutsDesignDisplay } from '../../../LayoutsDesign'

const TemplatesDisplay = (props) => {
  const { template } = props
  const { business, setting, site } = props.datas

  return (
    <LayoutsDesignDisplay
      {...props}
      display
      groupId={template._id}
      action={{
        params: {
          business,
          setting,
          site,
          data: site,
          _id: site._id
        }
      }}
    />
  )
}

export default TemplatesDisplay

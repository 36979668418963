import React, { Fragment } from 'react'
import { connect } from 'react-redux'

const AppWebsitesContainer = (props) => {
  return (
    <Fragment>
      { props.children }
    </Fragment>
  )
}

export default connect()(AppWebsitesContainer)

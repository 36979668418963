import React from 'react'
import { connect } from 'react-redux'

// Components Global
import { Row, Column } from '../../../../../components/Columns'
import EditData from '../../../../../components/EditData'
import Section from '../../../../../components/Section'
import DatasList from '../../../../../components/DatasList'

// Components Local
import {
  PlaceName,
  PlaceLocation,
  PlaceDescriptions
} from '../../components/PlaceForms'

// Actions Redux
import { updatePlace } from '../../../../../redux/Places/PlacesActions'


const PlaceDescriptionsPage = (props) => {
  const { place, business } = props

  const handleSubmit = (params, formName) => {
    if (params) {
      props.dispatch(updatePlace(business._id, place._id, {
        body: params,
        loadId: formName,
        fieldId: formName,
        toastMsg: 'แก้ไขข้อมูลแล้ว'
      }))
    }
  }

  return (
    <div className="container-full-scroll">
      <div className="box border-eee">

        <Section title="ข้อมูลสถานที่ท่องเที่ยว">
          <EditData recheckForm name="descriptions" submit={handleSubmit}>
            <div>
              <div className="border-bottom-eee pd-bottom-10">
                <div className="font-0-8 mg-bottom-5">ชื่อสถานที่ท่องเที่ยว</div>
                <p>{place.nameLang}</p>
              </div>

              <div className="border-bottom-eee pd-top-15 pd-bottom-10">
                <div className="font-0-8 mg-bottom-5">ประเทศ</div>
                <p className="pre-line">{place.country.countryNameTh}</p>
              </div>

              <div className="border-bottom-eee pd-top-15 pd-bottom-10">
                <div className="font-0-8 mg-bottom-5">จังหวัด/รัฐ</div>
                <p className="pre-line">{place.province.nameTh}</p>
              </div>

              <div className="border-bottom-eee pd-top-15 pd-bottom-10">
                <div className="font-0-8 mg-bottom-5">รายละเอียดสถานที่ท่องเที่ยว</div>
                <p className="pre-line">{place.descriptionsLang}</p>
              </div>


            </div>

            <div className="pd-top-15">
              <Row>
                <Column col="column col-xs-12">
                  <PlaceName value={place.name} />
                  <PlaceLocation
                    countryCode={place.country && place.country.countryCode}
                    provinceId={place.province && place.province.id}
                  />
                  <PlaceDescriptions value={place.descriptions} />
                </Column>
              </Row>
            </div>
          </EditData>
        </Section>

      </div>
    </div>
  )
}

export default connect()(PlaceDescriptionsPage)

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import cuid from 'cuid'
import Required from '../Required'

import { setStyleValue, getStyle } from '../../TextEditor/components/ElementStyles/components/js/setDivStyle'


import FolderSelectModal from '../../../containers/dashboard/Folders/components/FolderSelectModal'

// redux
import { openModal } from '../../../redux/App/AppActions'

const InputFolder = (props) => {
  const { styleName, styleJsx, styleGlobal, inputForSite, required } = props
  const randomId = cuid() + props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
  const [ id ] = useState(randomId)
  const defaultValue = {
    folders_id: '',
    folders_text: '',
    folders: []
  }

  const [ forder, setForder ] = useState(defaultValue)

  useEffect(() => {
    if (props.value) {
      setForder(props.value)
    }
  }, [])

  const remove = () => {
    setForder(defaultValue)
  }

  const select = () => {
    props.dispatch(openModal(`${id}-folder`))
  }

  const selectFolder = (value) => {
    setForder(value)
  }

  const name = styleName
  const setEvent = (event, important=false) => {
    return (
      `
        ${getStyle('box-shadow', styleJsx[`${name}-${event}-box-shadow`], important)}
        ${getStyle('border-style', styleJsx[`${name}-${event}-border-style`], important)}
        ${getStyle('border-width', styleJsx[`${name}-${event}-border-width`], important)}
        ${getStyle('border-color', styleJsx[`${name}-${event}-border-color`], important)}
      `
    )
  }

  const { folders_id, folders_text, folders } = forder

  return (
    <div className={`${props.styleGlobal ? 'box-input-global-jsx' : ''}`}>

      <FolderSelectModal
        id={`${id}-folder`}
        folders_id={folders_id}
        type={props.folderType}
        label={props.label}
        match={props.match}
        business={props.business}
        onSelect={selectFolder}
      />

      <Required
        id={id}
        classId={id}
        label={props.label}
        type="text"
        value={folders_id}
        required={required}>

        <div>
          <table>
            <tbody>
              <tr>
                <td className="pd-0">
                  <input
                    id={id}
                    data-not-save={true}
                    className={`${inputForSite ? 'input-site' : 'input'} ${styleGlobal ? ' input-global-jsx' : ''} input-jsx ${required ? 'required' : ''}`}
                    placeholder={props.placeholder}
                    onClick={select}
                    value={folders_text || ''}
                  />
                </td>

                <td className="pd-0 width-40">
                  { folders_id ?
                    <a className="btn-floating btn-flat" onClick={remove}>
                      <i className="material-icons black-text">close</i>
                    </a>
                  :
                    <a className="btn-floating btn-flat" onClick={select}>
                      <i className="material-icons black-text">folder</i>
                    </a>
                  }
                </td>
              </tr>
            </tbody>
          </table>

          <input
            type="hidden"
            value={folders_id || ''}
            data-id="folders_id"
            data-label={props.useLabel && props.label}
            data-use-attr={props.useAttr}
          />

          <input
            type="hidden"
            name="array"
            value={folders ? JSON.stringify(folders) : ''}
            data-id="folders"
            data-label={props.useLabel && props.label}
            data-use-attr={props.useAttr}
          />

          <style jsx>{`
            .input-jsx {
              ${styleJsx && setStyleValue({ name, style: styleJsx })}

              outline: none;
              -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                      box-sizing: border-box;
            }
            .input-jsx:focus:not([readonly]) {
              ${styleJsx && setEvent('focus')}
            }
            .input-jsx.valid,
            .input-jsx:focus.valid {
              ${styleJsx && setEvent('valid', true)}
            }
            .input-jsx.invalid,
            .input-jsx:focus.invalid {
              ${styleJsx && setEvent('invalid', true)}
            }

            @media (min-width: 1500px) {
              .input-jsx {
                ${styleJsx && setStyleValue({ name, style: styleJsx, vw: true })}
              }
              .input-jsx:focus:not([readonly]) {
                ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-focus-box-shadow-vw`])}
                ${styleJsx && getStyle('border-width', styleJsx[`${name}-focus-border-width-vw`])}
              }
              .input-jsx.valid {
                ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-valid-box-shadow-vw`], true)}
                ${styleJsx && getStyle('border-width', styleJsx[`${name}-valid-border-width-vw`], true)}
              }
              .input-jsx.invalid {
                ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-invalid-box-shadow-vw`], true)}
                ${styleJsx && getStyle('border-width', styleJsx[`${name}-invalid-border-width-vw`], true)}
              }
            }

            @media (min-width: 601px) and (max-width: 1024px) {
              .input-jsx {
                ${styleJsx && setStyleValue({ device: 'tablet', name, style: styleJsx })}
              }
            }

            @media (min-width: 50px) and (max-width: 600px) {
              .input-jsx {
                ${styleJsx && setStyleValue({ device: 'mobile', name, style: styleJsx })}
              }
            }
          `}</style>
        </div>
      </Required>
    </div>
  )
}


export default connect()(InputFolder)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../routes/renderRoutes'

// Components Global
import Window from '../../../../components/WindowsV2/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../components/MenuSidebarResponsive'

// Functions
import {
  ordersGroupMenu,
  createOrderDraft,
  countOrdersGroup,
  countOrdersGroupDefault
} from '../../../../redux/Orders/OrdersFunctions'

// imgs
import titleIcon from '../resources/imgs/pos-96.png'


const menuItems = (params, state) => {
  return [
    {
      name: 'รายการบิล',
      title: true
    },
    {
      name: 'รายการพักบิล',
      nameShort: 'พักบิล',
      icon: 'content_paste',
      link: `/${params.username}/sales`,
      exact: true,
    },
    {
      name: 'รายการขาย',
      title: true
    },
    ...ordersGroupMenu({ pathStart:  `/${params.username}/sales/orders`, v2: true, state }),
    {
      name: 'อื่นๆ',
      title: true
    },
    {
      name: 'ส่งออกรายการขาย Excel',
      nameShort: 'ส่งออกไฟล์ Excel',
      icon: 'import_export',
      link: `/${params.username}/sales/export`
    },
  ]
}


class SalesMenuContainer extends Component {
  constructor(props) {
    super(props)
    this.state = countOrdersGroupDefault()
  }

  componentDidMount() {
    this.mounted = true

    this.countOrder()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.mounted && this.countOrder()
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  countOrder = () => {
    const { business } = this.props

    countOrdersGroup(this, { business })
  }


  render() {
    const { route, match, business, setting, location, windowModal, closeWindowModal, history } = this.props

    return (
      <Window
        title="ระบบขาย"
        titleIcon={titleIcon}
        linkClose={`/${match.params.username}`}
        windowModal={windowModal}
        closeWindowModal={closeWindowModal}
        history={history}
        linkHelp="https://www.youtube.com/playlist?list=PLHuj62tDAxzNdPnfzc418tAo3phAP62if">

        <LayoutsTwo>
          <Layouts1>
            <MenuSidebarResponsive
              btnAddSubmit={{
                onClick: () => createOrderDraft(this.props),
                loadingId: 'createOrderDraft',
                text: 'สร้างบิลใหม่'
              }}
              items={menuItems(match.params, this.state)}
            />
          </Layouts1>

          <Layouts2 hideScrollbars>
            { renderRoutes({
              routes: route.routes,
              extraProps: { business, setting },
              location,
            }) }
          </Layouts2>
        </LayoutsTwo>
      </Window>
    )
  }
}

export default connect()(SalesMenuContainer)

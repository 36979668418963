import React from 'react'
import renderRoutes from '../../../../routes/renderRoutes'

// Components Global
import Window from '../../../../components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../components/MenuSidebarResponsive'

// Components Customers
import CustomerCreateModal from '../components/CustomerCreateModal'


// imgs
import titleIcon from '../resources/imgs/customer-title-icon.png'


const menuItems = (params) => {
  const { username, attributes_path } = params

  let path = `/${username}/customers`

  if (attributes_path) {
    path = `/${username}/customers/${attributes_path}`
  }

  return [
    {
      name: 'รายการ',
      title: true
    },
    {
      name: 'รายชื่อลูกค้า',
      icon: 'public',
      link: `${path}/all/all`,
      linksActive: [
        { url: `${path}/all/all` },
        { url: `${path}/all/:objectId` }
      ],
    },
    {
      name: 'ถังขยะ',
      icon: 'delete',
      link: `${path}/trash/all`,
      linksActive: [
        { url: `${path}/trash/all` },
        { url: `${path}/trash/:objectId` }
      ],
    }
  ]
}

const CustomersMenuContainer = (props) => {
  const { business, location, route, match, windowModal, closeWindowModal, history } = props

  return (
    <Window
      title="รายชื่อลูกค้า"
      titleIcon={titleIcon}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkClose={`/${match.params.username}`}>

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive
            btnAddModal={{
              modalId: 'customerCreateModal',
              text: 'เพิ่มรายชื่อลูกค้า'
            }}
            items={menuItems(match.params)}
          />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business },
            location
          }) }
        </Layouts2>
      </LayoutsTwo>

      <CustomerCreateModal id="customerCreateModal" match={match} business={business} />
    </Window>
  )
}

export default CustomersMenuContainer

import React, { Component } from 'react'

// Components Global
import TextInput from '../../../../../components/Inputs/TextInput'
import Textarea from '../../../../../components/Inputs/Textarea'
import SelectCountry from '../../../../../components/Inputs/SelectCountry'
import SelectProvince from '../../../../../components/Inputs/SelectProvince'

export const PlaceName = (props) => {
  const lang = props.lang || 'local'

  return (
    <TextInput
      label="ชื่อสถานที่ท่องเที่ยว"
      id="name.local"
      value={props.value && props.value[lang]}
      placeholder="ชื่อสถานที่ท่องเที่ยว"
      type="text"
      required
      iconValid />
  )
}


export const PlaceDescriptions = (props) => {
  const lang = props.lang || 'local'

  return (
    <Textarea
      label="รายละเอียดสถานที่ท่องเที่ยว"
      placeholder="รายละเอียดสถานที่ท่องเที่ยว"
      id="descriptions.local"
      value={props.value && props.value[lang]}
      iconValid
      required
    />
  )
}

export const PlaceCountry = (props) => {
  return (
    <SelectCountry
      label="ประเทศ"
      placeholder="ประเทศ"
      id="country"
      value={props.value}
      getValue={props.getValue}
      iconValid
      required
    />
  )
}

export const PlaceProvince = (props) => {
  return (
    <div>
      { props.countryCode &&
        <SelectProvince
          label="จังหวัด/รัฐ"
          placeholder="จังหวัด/รัฐ"
          id="province"
          value={props.value}
          countryCode={props.countryCode}
          iconValid
        />
      }
    </div>
  )
}

export class PlaceLocation extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getCountryCode = (countryCode) => {
    this.setState({ countryCode })
  }

  render() {
    return (
      <div>
        <PlaceCountry getValue={this.getCountryCode} value={this.props.countryCode} />
        <PlaceProvince countryCode={this.state.countryCode} value={this.props.provinceId} />
      </div>
    )
  }
}


import React, { Fragment } from 'react'

// Components Global
import InputDropdown from '../../../../../components/Inputs/InputDropdown'

// Components Customers
import { CustomerProfileAvater } from '../CustomersUserInterface'

// Redux Actions
import { fetchCustomers } from '../../../../../redux/Customers/CustomersActions'

// Values
import { customerValue } from '../../../../../redux/Customers/CustomersValues'


const CustomerInputSelect = (props) => {
  const lang = props.lang || 'local'
  const id = `name.${lang}`
  const { business, where } = props

  const fetchAction = {
    action: fetchCustomers,
    actionQuery: {
      authName: 'admin',
      dbNo: business.dbNo,
      businessType: business.type,
      businesses_id: business._id
    },
    id: business._id,
    addGroupId: props.addGroupId,
    where,
    countWhere: where,
    sort: '_id',
    limit: 25,
    searchAttributes: [
      { name: 'name.local', options: 'i' },
      { name: 'members.name.local', options: 'i' },
      { name: 'members.mobile.number', options: 'i' },
      { name: 'code', options: 'i' }
    ]
  }

  const item = (customer) => {
    customer = customerValue({ customer })
    const { code } = customer

    return (
      <div>
        { customer &&
          <Fragment>
            <table>
              <tbody>
                <tr>
                  <td width="40" className="pd-0 text-left">
                    <div className="width-30 height-30 line-height-30">
                      <CustomerProfileAvater circle customer={customer} fontClassName="font-1-3" />
                    </div>
                  </td>
                  <td className="pd-5 line-height-0">
                    <div className="font-0-9">{ customer.nameLang }</div>
                    <div className="font-0-6 mg-top-20">
                      รหัสลูกค้า: { code || '-' }
                    </div>
                    <div className="font-0-6 mg-top-15">
                      โทร: { customer.mobileShort || '-' }
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Fragment>
        }
      </div>
    )
  }


  let { customer } = props
  customer = customerValue({ customer })

  let avatarIcon, value

  if (customer) {
    value = customer.nameLang
    avatarIcon = {
      iconFa: customer.typeIcon,
      color: '#333',
      bgColor: '#fff',
    }
  }

  return (
    <InputDropdown
      fetchAction={fetchAction}
      item={item}
      storeName="customers"
      select={props.onChange}
      onKeyChange={props.onKeyChange}
      label={props.label}
      id={id}
      dataSeleted={props.customer}
      value={value}
      placeholder={props.label}
      type="text"
      chipValue={value}
      avatarIcon={avatarIcon}
      chipInput={props.chipInput && {
        id: 'customerName',
        onChange: props.chipInputOnChange
      }}
      selectId="customers_id"
      chipSize={props.chipSize}
      inputClassName={props.inputClassName}
      labelClassName={props.labelClassName}
      //chipSize={25}
      /*inputClassName="height-35 line-height-35 font-0-8"
      labelClassName="font-0-8"*/
      required={props.required}
      notSave={props.notSave}
      disabled={props.disabled}
      addGroupId={props.addGroupId}
    />
  )
}


export default CustomerInputSelect

import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../components/Modals/Modal'
import { Row, Column } from '../../../../../components/Columns'
import Section from '../../../../../components/Section'
import EditData from '../../../../../components/EditData'
import DatasList from '../../../../../components/DatasList'

// Components Sites
import { ContentGroupPageName, ContentGroupPagePath } from '../SitesForms'

// Components Path
import { BoxStatus, PageStatusSwitch } from '../../../Pages/components/PageForms'

class ContentGroupPageUpdateModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleSubmit = (params, formName) => {
    const { business, contentGroupPage, actionSinglePage } = this.props
    const action = actionSinglePage

    if (params) {
      params.updateType = 'set'
      params.updateElement = 'contentGroupPages'
      params.updateId = contentGroupPage._id


      if (action.useQuery) {
        action.useQuery._id = action.data._id

        this.props.dispatch(action.update(action.useQuery, {
          body: params,
          loadId: formName,
          fieldId: formName,
          props: this.props,
          toastMsg: 'แก้ไขเทมเพลตแล้ว'
        }))

      } else if (action.authName) {
        this.props.dispatch(action.update(action.authName, business._id, action.data._id, {
          body: params,
          loadId: formName,
          fieldId: formName,
          props: this.props,
          toastMsg: 'แก้ไขเทมเพลตแล้ว'
        }))

      } else {
        this.props.dispatch(action.update(business._id, action.data._id, {
          body: params,
          loadId: formName,
          fieldId: formName,
          props: this.props,
          toastMsg: 'แก้ไขเทมเพลตแล้ว'
        }))
      }

      /*this.props.dispatch(update(business._id, data._id, {
        body: params,
        loadId: formName,
        fieldId: formName,
        props: this.props,
        toastMsg: 'แก้ไขเทมเพลตแล้ว'
      }))*/
    }
  }

  render() {
    const lang = this.props.lang || 'local'
    const { contentGroupPage, main } = this.props
    const id = `${contentGroupPage._id}-contentGroupPageUpdateModal`

    return (
      <Modal
        id={id}
        headerTitle="ข้อมูลหน้าเพจ"
        blockBgModal
        padding="30px 0px 0px 0px"
        width="650px">

        <Row className="pd-0">
          <Section title="ข้อมูลหน้าเพจ">

            { main ?
              <DatasList
                style={{ border: '0' }}
                items={[
                  {
                    labelTop: true,
                    label: 'ชื่อหน้าเพจ',
                    value: contentGroupPage.name[lang],
                    icon: 'account_box'
                  },
                  {
                    labelTop: true,
                    label: 'URL',
                    value: '/',
                    icon: 'account_box'
                  }
                ]}
              />
            :
              <EditData
                name="name"
                submit={this.handleSubmit}>

                <div>
                  <DatasList
                    style={{ border: '0' }}
                    items={[
                      {
                        labelTop: true,
                        label: 'ชื่อหน้าเพจ',
                        value: contentGroupPage.name[lang],
                        icon: 'account_box'
                      },
                      {
                        labelTop: true,
                        label: 'URL',
                        value: contentGroupPage.path || '-',
                        icon: 'account_box'
                      }
                    ]}
                  />
                </div>

                <div className="pd-top-15">
                  <Row>
                    <Column col="column col-xs-12">
                      <ContentGroupPageName value={contentGroupPage && contentGroupPage.name} />
                      <ContentGroupPagePath value={contentGroupPage && contentGroupPage.path} />
                    </Column>
                  </Row>
                </div>
              </EditData>
            }
          </Section>

          <Section title="การแสดงผลส่วนหัว (Header)">
            <BoxStatus title="ส่วนหัว (Header)" status={contentGroupPage.headerShow}>
              <PageStatusSwitch {...this.props} singlePage title="ส่วนหัว (Header) " page={contentGroupPage} name="headerShow" />
            </BoxStatus>

            <BoxStatus title="ส่วนหัวแบบปกติ (Header Default)" status={contentGroupPage.headerDefaultShow}>
              <PageStatusSwitch {...this.props} singlePage title="ส่วนหัวแบบปกติ " page={contentGroupPage} name="headerDefaultShow" />
            </BoxStatus>

            <BoxStatus title="ส่วนหัวแบบคงที่ (Header Fixed)" status={contentGroupPage.headerFixedShow}>
              <PageStatusSwitch {...this.props} singlePage title="ส่วนหัวแบบคงที่ " page={contentGroupPage} name="headerFixedShow" />
            </BoxStatus>

            { !main &&
              <BoxStatus title="ส่วนหัวของหน้าหลัก (Header Main)" status={contentGroupPage.headerMainShow}>
                <PageStatusSwitch {...this.props} singlePage title="ส่วนหัวของหน้าหลัก " page={contentGroupPage} name="headerMainShow" />
              </BoxStatus>
            }
          </Section>

          <Section title="การแสดงผลส่วนท้าย (Footer)">
            <BoxStatus title="ส่วนท้าย (Footer)" status={contentGroupPage.footerShow}>
              <PageStatusSwitch {...this.props} singlePage title="ส่วนท้าย " page={contentGroupPage} name="footerShow" />
            </BoxStatus>

            { !main &&
              <BoxStatus title="ส่วนท้ายของหน้าหลัก (Footer Main)" status={contentGroupPage.footerMainShow}>
                <PageStatusSwitch {...this.props} singlePage title="ส่วนท้ายของหน้าหลัก " page={contentGroupPage} name="footerMainShow" />
              </BoxStatus>
            }
          </Section>
        </Row>
      </Modal>
    )
  }
}

export default connect()(ContentGroupPageUpdateModal)

import { getAttributeItems, getDataAttributes } from '../Attributes/AttributesValues'

export const noteValue = ({ note, attribute, lang="local" }) => {
  let titleLang, avatarName, descriptionsLang
  let statusName, statusIcon, statusColor
  let attributeItems, dataAttributes

  if (note) {
    if (attribute) {
      const noteAttr = note.attributes
      const atrrItems = attribute.items
      const itemTitle = atrrItems.filter(item => item.showTitle === true)[0]
      const itemDes = atrrItems.filter(item => item.showDescriptions === true)[0]

      let title, descriptions

      if (itemTitle && noteAttr && noteAttr[itemTitle._id]) {
        title = noteAttr[itemTitle._id]

        if (itemTitle.inputType === 'string-lang') {
          titleLang = title ? title[lang] : null

        } else if (itemTitle.inputType === 'string') {
          titleLang = title || null
        }
      }

      if (itemDes && noteAttr[itemDes._id]) {
        descriptions = noteAttr[itemDes._id]

        if (itemDes.inputType === 'text-lang') {
          descriptionsLang = descriptions ? descriptions[lang] : null
        }
      }

      avatarName = titleLang ? [titleLang] : null
    }

    attributeItems = getAttributeItems({ data: note, attribute, lang })
    dataAttributes = getDataAttributes({ data: note, attribute })

    // Status
    if (note.status === 'open') {
      if (lang === 'local') statusName = 'เปิด'

      statusIcon = "public"
      statusColor = "success"

    } else if (note.status === 'close') {
      if (lang === 'local') statusName = 'ปิด'

      statusIcon = "lock"
      statusColor = "warning"

    } else if (note.status === 'trash') {
      if (lang === 'local') statusName = 'ถังขยะ'

      statusIcon = "delete"
      statusColor = "error"
    }
  }

  return {
    ...note,
    titleLang,
    avatarName,
    statusName, statusIcon, statusColor,
    descriptionsLang,
    attributeItems, dataAttributes
  }
}

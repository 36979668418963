import React, { Fragment, useEffect, useState } from 'react'

// Components Global
import InputDatePicker from '../../../../../../components/Inputs/InputDatePicker'
import DatePickerBetween from '../../../../../../components/Inputs/DatePickerBetweenV2'
import InputNumeral from '../../../../../../components/Inputs/InputNumeral'

// Actions
import { checkProductDuplicate } from '../../../../../../redux/Products/ProductsActions'

// Values
import { getUseRubberProductTypes, getRubberProductItems } from '../../../../../../redux/RubberProducts/RubberProductsValues'




export const DateSearch = (props) => {
  return (
    <DatePickerBetween
      id="date-search"
      onChange={props.onChange}
      dateFormat="D MMM YYYY"
      timeFormat="เวลา H:mm น."
      start={{
        id: 'startDate',
        label: 'วันที่เริ่มต้น',
        value: props.dateStart,
        col: 'column col-xxs-12 col-xs-12 col-sm-6 col-md-6 pd-right-10-sm pd-right-10-md pd-right-10-lg pd-right-10-xlg'
      }}
      end={{
        id: 'dueDate',
        label: 'วันที่สิ้นสุด',
        value: props.dateEnd,
        col: 'column col-xxs-12 col-xs-12 col-sm-6 col-md-6 pd-left-10-sm pd-left-10-md pd-left-10-lg pd-left-10-xlg'
      }}
    />
  )
}

export const ProductDate = (props) => {
  const { business, value, product, onChange } = props
  const query = `businesses_id=${business._id}`
  let unselectId

  if (product && product._id) {
    unselectId = product._id
  }

  return (
    <InputDatePicker
      id="date"
      required
      label="วันที่ (วัน/เดือน/ค.ศ.)"
      dateNow
      value={value}
      onChange={props.onChange}
      checkDuplicate={{
        action: checkProductDuplicate,
        useQuery:{
          authName: 'admin',
          dbNo: business.dbNo,
          businessType: business.type,
          businesses_id: business._id
        },
        element: 'date',
        query,
        unselectId
      }}
    />
  )
}

export const ProductItems = (props) => {
  const types = getUseRubberProductTypes()
  const [items, setItems] = useState([])
  const { product } = props
  const priceItems = product && product.priceItems

  useEffect(() => {
    let newItems

    if (!priceItems) {
      newItems = getRubberProductItems({ types })
    } else {
      newItems = priceItems
    }

    setItems(newItems)
  }, [])

  const handlePriceChange = (i, value) => {
    const newItems = [...items]

    if (newItems[i]) {
      newItems[i].price = value
      setItems(newItems)
    }
  }

  let useItems

  if (priceItems) {
    useItems = priceItems
  } else {
    useItems = types
  }

  return (
    <Fragment>
      { useItems.map((item, i) => {
        return (
          <div key={i}>
            <InputNumeral
              required
              i={i}
              label={`ราคา${item.name}`}
              placeholder={`ราคา${item.name}`}
              id="price"
              value={item.price}
              notSave
              maxValue="1000"
              notMinus
              decimal="2"
              decimalFull
              onChange={handlePriceChange}
            />
          </div>
        )
      }) }

      <input type="hidden" data-id="items" value={JSON.stringify(items)} />
    </Fragment>
  )
}

import React, { Component } from 'react'

// css
import css from './css/switch.css'

class Switch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      checked: this.props.value ? this.props.value : false,
      id: this.props.id.replace('[', '').replace(']', '').replace('.', ''),
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.checked) {
      this.setState({ checked: nextProps.value })
    }
  }

  handelOnChane = (e, checked) => {
    if (checked) {
      this.setState({ checked: false }, () => {
        if (this.props.getValue) {
          this.props.getValue(this.state.checked, this.state.id)
        }
      })
    } else {
      this.setState({ checked: true }, () => {
        if (this.props.getValue) {
          this.props.getValue(this.state.checked, this.state.id)
        }
      })
    }
  }

  render() {
    return(
      <div className={`switch ${css.switch}`} style={{ float: 'left', height: '33px', lineHeight: '33px' }}>
        <label className="f5">
          ปิด
          <input
            onChange={(e) => this.handelOnChane(e, this.state.checked)}
            id={this.state.id}
            data-id={this.props.id}
            ref={this.state.id}
            checked={this.state.checked}
            value={this.state.checked}
            data-not-save={this.props.notSave}
            type="checkbox" />
          <span className={`lever ${css.lever}`}></span>
          เปิด
        </label>
      </div>
    )
  }
}

export default Switch
import React, { Fragment } from 'react'
import { connect } from 'react-redux'

// Components Global
import { TextMiddle } from '../Text'
import { Text } from '../Btn/Btn'

// css
import css from './css/preLoading.css'

const PreLoading = (props) => {
  const styleLoading = {
    width: props.size || '64px',
    height: props.size || '64px',
    color: props.borderColor ||'#26a69a'
  }

  const textStyle = {
    ...props.textStyle,
    fontSize: props.textSize ||'1.2rem',
  }

  const borderColor = props.border || '4px'


  //color: #26a69a;

  if (!props.full) {
    return (
      <Fragment>
        { ((!props.notUse) && (props.loadTest || props.preLoading)) ?
          <div className={`${css.box} ${props.className || ''}`} style={props.style}>
            <TextMiddle height="100%" width="100%">
              <div className={`${css.laBallClipRotate} ${css.la2x}`} style={styleLoading}>
                <div style={{ height: '100%', width: '100%', borderWidth: borderColor }}></div>
              </div>

              {/*<Text theme={props.theme} html="กำลังโหลดข้อมูล<b>...</b>" />*/}

              <div className="mg-top-10">
                <div style={textStyle}>
                  กำลังโหลดข้อมูล<b>...</b>
                </div>
              </div>
            </TextMiddle>
          </div>
        :
          props.children
        }
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <div className={`${css.preloading} ${props.preLoading && css.showPreloading }`}>
          <div>
            <div className={`${css.laBallClipRotate} ${css.la2x}`}>
              <div></div>
            </div>
            <p className="mg-top-20">กำลังโหลดข้อมูล<b>...</b></p>
          </div>
        </div>

        <Fragment>
          { props.children }
        </Fragment>
      </Fragment>
    )
  }
}

function mapStateToProps(store, props) {
  return {
    preLoading: store.app.loads.filter(load => load === props.id)[0],
  }
}

export default connect(mapStateToProps)(PreLoading)

import React, { useState } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from 'components/Modals/Modal'

// Values
import { conversationChannelPlatforms } from 'core/redux/ConversationChannels/ConversationChannelsValues'

import SelectPlatform from './components/SelectPlatform'
import ConnectPlatform from './components/ConnectPlatform'

// line oa
import OpenMessagingApi from './components/lineOA/OpenMessagingApi'
import CopyLineToken from './components/lineOA/CopyLineToken'


const platforms = conversationChannelPlatforms

const ConversationChannelCreateModal = (props) => {
  const [platformId, setPlatformId] = useState()
  const [step, setStep] = useState(1)
  const { id, business } = props

  const fnStartModal = () => {
    setPlatformId()
    setStep(1)
  }

  const onSelectPlatform = (id) => {
    setPlatformId(id)
    goNext()
  }

  const onConnectPlatform = (id) => {
    goNext()
  }

  const goBack = () => {
    setStep(step -1)
  }

  const goNext = () => {
    setStep(step + 1)
  }

  let title, comp

  const platform = platforms.filter(platform => platform.id === platformId)[0]
  const compParams = {
    modalId: id,
    business,
    platforms,
    platform,
    goBack,
    goNext,
    step
  }

  if (step === 1) {
    title = "เลือกช่องทางที่ต้องการเชื่อมต่อ"
    comp = <SelectPlatform {...compParams} onSelect={onSelectPlatform}  />

  } else if (step === 2) {
    title = `เชื่อมต่อ ${platform.name}`
    comp = <ConnectPlatform {...compParams} onConnect={onConnectPlatform} useFooter />

  } else if (step > 2 && platform) {
    if (platform.id === 'line-oa') {
      if (step === 3) {
        title = `เปิดการใช้งาน Messaging API`
        comp = <OpenMessagingApi {...compParams} useFooter />
      } else if (step === 4) {
        title = `คัดลอก Token`
        comp = <CopyLineToken {...compParams} useFooter />
      }

    } else if (platform.id === 'facebook-page') {

    } else if (platform.id === 'instargram') {
      if (step === 3) {
        title =  `ตั้งค่าบัญชีมืออาชีพ`
        comp = <OpenMessagingApi platform={platform} />

      } else if (step === 4) {

      }
    }
  }

  return (
    <Modal
      id={id}
      headerTitle={title}
      headerLeft
      fnStartModal={() => fnStartModal()}
      width="500px">

      <div className="pd-15">
        { comp }
      </div>
    </Modal>
  )
}

function mapStateToProps(store, props) {
  return {
    setting: store.settings.data[0],
  }
}

export default connect(mapStateToProps)(ConversationChannelCreateModal)

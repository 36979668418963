
import React, { Fragment } from 'react'
import { connect } from 'react-redux'

// components
import ReorderModal from 'components/ReorderModal'

// redux
import { openModal } from 'core/redux/App/AppActions'

const UseReorderNo = (props) => {
  const { useReorderNo, paginationRef } = props

  return (
    <Fragment>
      <a
        onClick={() => props.dispatch(openModal(`${useReorderNo.id}-modal`))}
        className={`btn pd-0 btn-white width-40 height-40 line-height-40 btn-shadow-none border-radius-3`}>

        <i className="material-icons font-1-5">low_priority</i>
      </a>

      <ReorderModal
        id={`${useReorderNo.id}-modal`}
        useReorderNo={useReorderNo}
        paginationRef={paginationRef}
      />
    </Fragment>
  )
}

export default connect()(UseReorderNo)

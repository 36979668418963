import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../routes/renderRoutes'



const ModalRoutesContainer = (props) => {
  const [open, setOpen] = useState(false)
  const margin = props.margin || 30
  const { route, location } = props
  const handleStay = (e) => e.stopPropagation()

  const closeWindowModal = () => {
    setOpen(false)

    setTimeout(() => {
      props.history.push(props.pathClose)
    }, 500)
  }

  useEffect(() => {
    const use = location.pathname.search(props.pathUseModal)

    if (use !== -1) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [location.pathname])

  return (
    <Fragment>
      <div
        onClick={closeWindowModal}
        className="modal-jsx"
        style={{
          visibility: open && 'visible', opacity: open && '1',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>

        <div
          onClick={handleStay}
          className={`modal-content-jsx ${props.modalClassName}`}
          style={{
            visibility: open && 'visible', opacity: open && '1'
          }}>

          { renderRoutes({
            routes: route.routes,
            extraProps: { ...props, windowModal: open, closeWindowModal },
            location
          }) }
        </div>
      </div>

      <style jsx>{`
        .modal-jsx {
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 9999;
          transition: all 0.5s;
          opacity: 0;
          visibility: hidden;
          background: rgb(43 46 56 / 90%);
        }

        .modal-content-jsx {
          transition: all 0.5s;
          opacity: 1;
          visibility: hidden;
          overflow: hidden;
          margin: ${margin}px;
          max-width: ${props.width ? `${props.width}px`: ''};
          width: calc(100% - ${margin*2}px);
        }

        @media (min-width: 1500px) {
          .modal-content-jsx {
            margin: ${margin/15}vw;
            max-width: ${props.width ? `${props.width/15}vw`: ''};
            width: calc(100% - ${margin*2/15}vw);
          }
        }

        @media (min-width: 601px) and (max-width: 1024px) {
          .modal-content-jsx {
            margin: 10px;
            width: calc(100% - ${20}px);
          }
        }

        @media (min-width: 50px) and (max-width: 600px) {
          .modal-content-jsx {
            margin: 0;
            width: 100%;
          }
        }
      `}</style>
    </Fragment>
  )
}

export default connect()(ModalRoutesContainer)

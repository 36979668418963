import React, { Fragment } from "react"

// Components Global
import { LayoutsDesign } from '../../../LayoutsDesign'

// Components Local
import PageDisplay from '../PageDisplay'

const PageManage = (props) => {
  const { action, datas, mode } = props
  const { business, setting, site, page, templateData, imagePrefix } = datas

  return (
    <Fragment>
      { mode === 'view' &&
        <PageDisplay
          {...props}
          datas={{
            business,
            setting,
            site,
            page,
            templateData
          }}
        />
      }

      { mode === 'edit' && page &&
        <div style={{ padding: '20px 20px 20px 50px' }}>
          <LayoutsDesign
            {...props}
            body
            action={{
              ...action,
              params: {
                business,
                setting,
                site,
                data: page,
                _id: page._id,
                imagePrefix,
                templateData
              }
            }}
          />
        </div>
      }
    </Fragment>
  )
}

export default PageManage

import React from 'react'
import renderRoutes from '../../../../routes/renderRoutes'

// Components Global
import Window from '../../../../components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../components/MenuSidebarResponsive'

// imgs
import titleIcon from '../../Businesses/resources/icons/attributes.png'


const menuItems = (params) => {
  return [
    {
      name: 'คุณลักษณะ',
      title: true
    },
    {
      name: 'คุณลักษณะสินค้า',
      nameShort: 'สินค้า',
      icon: 'group_work',
      link: `/${params.username}/attributes`,
      exact: true
    },
    {
      name: 'คุณลักษณะบทความ',
      nameShort: 'บทความ',
      icon: 'group_work',
      link: `/${params.username}/attributes/article`
    },
    {
      name: 'คุณลักษณะบันทึก',
      nameShort: 'บันทึก',
      icon: 'group_work',
      link: `/${params.username}/attributes/note`
    },
    {
      name: 'คุณลักษณะลูกค้า',
      nameShort: 'ลูกค้า',
      icon: 'group_work',
      link: `/${params.username}/attributes/customer`
    }
  ]
}

const AttributesMenuContainer = (props) => {
  return (
    <Window
      title="คุณลักษณะ"
      titleIcon={titleIcon}
      linkClose={`/${props.match.params.username}`}
      linkHelp="">

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive
            items={menuItems(props.match.params)}
          />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: props.route.routes,
            extraProps: { business: props.business },
            location: props.location,
          }) }
        </Layouts2>
      </LayoutsTwo>
    </Window>
  )

}

export default AttributesMenuContainer

import React from "react"

// Components Global
import { LayoutsDesign } from '../../../LayoutsDesign'
import { fetchSite, updateSite, uploadImagesSite } from '../../../../redux/Sites/SitesActions'


const TemplatesManage = (props) => {
  const { datas, template } = props
  const { business, setting, site } = datas

  return (
    <div style={{ padding: '20px 20px 20px 50px' }}>
      <LayoutsDesign
        {...props}
        groupId={template._id}
        action={{
          fetch: fetchSite,
          update: updateSite,
          uploadImage: uploadImagesSite,
          params: {
            business,
            setting,
            site,
            data: site,
            _id: site._id,
            imagePrefix: 'sites'
          }
        }}
      />
    </div>
  )
}

export default TemplatesManage

import moment from 'moment'


export const businessValue = ({ business, lang='local' }) => {
  if (business) {
    let companyNameLang, nameLang, branchNameLang, avatarName, addressLang, phone, tel, mobile, telShort, mobileShort

    companyNameLang = business.companyName ? business.companyName[lang] : null
    nameLang = business.name ? business.name[lang] : null
    branchNameLang = business.branchName ? business.branchName[lang] : null
    avatarName = nameLang ? [nameLang] : []

    // contact
    addressLang = business.address ? business.address[lang] : null
    //phone = business.phone && business.phone.number ? `${business.phone.number}` : null
    tel = business.tel && business.tel.number ? `${business.tel.number}` : null
    //mobile = business.mobile && business.mobile.number ? `${business.mobile.number}` : null
    telShort = business.tel && business.tel.number ? `${business.tel.number}` : null
    mobileShort = business.mobile && business.mobile.number ? `${business.mobile.number}` : null

    return {
      ...business,
      nameLang,
      companyNameLang,
      branchNameLang,
      addressLang,
      avatarName,
      //phone,
      tel,
      //mobile,
      telShort,
      mobileShort
    }
  }
}


export const tmpNotiValue = ({ item, lang="local" }) => {
  let nameLang, subjectLang, fromNameLang, emailBodyLang, msgBodyLang, typeName, typeIcon, statusName, statusIcon, statusColor

  if (item) {
    nameLang = item.name ? item.name[lang] : null
    subjectLang = item.subject ? item.subject[lang] : null
    fromNameLang = item.fromName ? item.fromName[lang] : null
    emailBodyLang = item.emailBody ? item.emailBody[lang] : null
    msgBodyLang = item.msgBody ? item.msgBody[lang] : null

    // Status
    if (item.status) {
      if (lang === 'local') statusName = 'เปิด'

      statusIcon = "public"
      statusColor = "success"

    } else {
      if (lang === 'local') statusName = 'ปิด'
      statusIcon = "lock"
      statusColor = "warning"
    }


    // type
    if (item.type === 'email') {
      if (lang === 'local') typeName = 'อีเมล'
      typeIcon = "fas fa-envelope"

    } else if (item.type === 'facebook') {
      if (lang === 'local') typeName = 'เฟซบุ๊ค'
      typeIcon = "fab fa-facebook-messenger"

    } else if (item.type === 'line-notify') {
      if (lang === 'local') typeName = 'ไลน์แจ้งเตือน'
      typeIcon = "fab fa-line"
    }
  }

  return {
    ...item,
    nameLang, subjectLang, fromNameLang, emailBodyLang, msgBodyLang,
    typeName, typeIcon,
    statusName, statusIcon, statusColor,
  }
}

export const groupValue = ({ item, lang="local" }) => {
  let nameLang

  if (item) {
    nameLang = item.name ? item.name[lang] : null
  }

  return {
    ...item,
    nameLang
  }
}

export const expirationDate = (packages) => {
  if (packages) {
    let data = packages

    if (data.name !== null) {
      let now = moment(new Date())
      let expirationDate = moment(data.expirationDate)
      let duration = moment.duration(expirationDate.diff(now))
      let minutes = duration.asMinutes()

      if (minutes < 0) {
        return true
      } else {
        return false
      }

    } else {
      return true
    }
  }
}

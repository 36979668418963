import React, { Component } from 'react'
import Cleave from 'cleave.js/react'
import cuid from 'cuid'

// Components Global
import Required from '../Required'

import { setStyleValue, getStyle } from '../../TextEditor/components/ElementStyles/components/js/setDivStyle'


class InputCleave extends Component {
  constructor(props) {
    super(props)

    const id = this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()

    this.state = {
      id: randomId+id,
      value: this.props.value || '',
      rawValue: this.props.value || '',
      cleave: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setCleave(this.props.value)
      this.setState({
        value: this.props.value || '',
        rawValue: this.props.value || ''
      })
    }
  }

  onChange = (e) => {
    let value = e.target.value || ''
    let rawValue = e.target.rawValue || ''

    this.setState({ value, rawValue }, () => {
      this.valueCallback(rawValue)
    })
  }

  valueCallback = (value) => {
    if (this.props.onChange) {
      if (this.props.i) {
        this.props.onChange(this.props.i, value)
      } else {
        this.props.onChange(value)
      }
    }
  }

  setCleave = (value) => {
    if (!isNaN(value)) {
      if (this.state.cleave && value !== '') {
        this.state.cleave.setRawValue(value || '')
      }
    }
  }

  onInit = (cleave) => {
    this.setState({ cleave })
  }

  render() {
    const { inputForSite, styleName, styleGlobal, styleJsx, options, required, placeholder, className } = this.props
    const name = styleName
    const setEvent = (event, important=false) => {
      return (
        `
          ${getStyle('box-shadow', styleJsx[`${name}-${event}-box-shadow`], important)}
          ${getStyle('border-style', styleJsx[`${name}-${event}-border-style`], important)}
          ${getStyle('border-width', styleJsx[`${name}-${event}-border-width`], important)}
          ${getStyle('border-color', styleJsx[`${name}-${event}-border-color`], important)}
        `
      )
    }

    //console.log(this.state.value)

    return (
      <div className={`${styleGlobal ? 'box-input-global-jsx' : ''}`}>
        <Required
          {...this.props}
          id={this.state.id}
          classId={this.state.id}
          className={this.props.requiredClassName}
          type="text"
          value={this.state.rawValue}>

          <div>
            <div className="box-input">
              <Cleave
                autoFocus={this.props.autoFocus}
                id={this.state.id}
                className={`${className || ''} ${inputForSite ? 'input-site' : 'input'} ${styleGlobal ? ' input-global-jsx' : ''} input-jsx ${required ? 'required' : ''} ${this.props.inputClassName || ''}`}
                placeholder={placeholder}
                value={this.state.value}
                options={options}
                onInit={this.onInit}
                onChange={this.onChange}
              />
            </div>

            <input
              type="hidden"
              value={this.state.rawValue || ''}
              data-id={this.props.id}
              data-label={this.props.useLabel && this.props.label}
              data-use-attr={this.props.useAttr}
              data-not-save={this.props.notSave}
            />

            <style jsx>{`
              .box-input > :global(.input-jsx) {
                ${styleJsx && setStyleValue({ name, style: styleJsx })}

                outline: none;
                -webkit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                        box-sizing: border-box;
              }
              .box-input > :global(.input-jsx:focus:not([readonly])) {
                ${styleJsx && setEvent('focus')}
              }
              .box-input > :global(.input-jsx.valid),
              .box-input > :global(.input-jsx:focus.valid) {
                ${styleJsx && setEvent('valid', true)}
              }
              .box-input > :global(.input-jsx.invalid),
              .box-input > :global(.input-jsx:focus.invalid) {
                ${styleJsx && setEvent('invalid', true)}
              }

              @media (min-width: 1500px) {
                .box-input > :global(.input-jsx) {
                  ${styleJsx && setStyleValue({ name, style: styleJsx, vw: true })}
                }
                .box-input > :global(.input-jsx:focus:not([readonly])) {
                  ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-focus-box-shadow-vw`])}
                  ${styleJsx && getStyle('border-width', styleJsx[`${name}-focus-border-width-vw`])}
                }
                .box-input > :global(.input-jsx.valid) {
                  ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-valid-box-shadow-vw`], true)}
                  ${styleJsx && getStyle('border-width', styleJsx[`${name}-valid-border-width-vw`], true)}
                }
                .box-input > :global(.input-jsx.invalid) {
                  ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-invalid-box-shadow-vw`], true)}
                  ${styleJsx && getStyle('border-width', styleJsx[`${name}-invalid-border-width-vw`], true)}
                }
              }

              @media (min-width: 601px) and (max-width: 1024px) {
                .box-input > :global(.input-jsx) {
                  ${styleJsx && setStyleValue({ device: 'tablet', name, style: styleJsx })}
                }
              }

              @media (min-width: 50px) and (max-width: 600px) {
                .box-input > :global(.input-jsx) {
                  ${styleJsx && setStyleValue({ device: 'mobile', name, style: styleJsx })}
                }
              }
            `}</style>
          </div>
        </Required>
      </div>
    )
  }
}

export default InputCleave

import React from 'react'

// Components Global
import Selects from '../../../../../components/Inputs/Select'
import TextInput from '../../../../../components/Inputs/TextInput'
import InputDecimal from '../../../../../components/Inputs/InputDecimal'
import Textarea from '../../../../../components/Inputs/Textarea'
import RadioGroup from '../../../../../components/Inputs/RadioGroup'
import { Row, Column } from '../../../../../components/Columns'



export const AlbumLabel = (props) => {
  const { album, id } = props

  let newId = "label"

  if (id) {
    newId = `${id}.${newId}`
  }

  return (
    <TextInput
      labelHide={props.update}
      label="ชื่ออัลบัม"
      id={`${newId}.local`}
      value={(album && album.label) ? album.label.local : ''}
      placeholder="ชื่ออัลบัม"
      inputType="text"
      type="text"
      required
      iconValid
    />
  )
}

export const AlbumCode = (props) => {
  const { album, id } = props

  let newId = "code"

  if (id) {
    newId = `${id}.${newId}`
  }

  return (
    <TextInput
      label="รหัสอัลบัม"
      placeholder="รหัสอัลบัม"
      active
      id={newId}
      inputType="text"
      type="text"
      required="true"
      value={album ? album.code : ''}
      iconValid
    />
  )
}

export const AlbumMultiple = (props) => {
  const { album, id } = props

  let radios = [
    {
      value: 'false',
      label: 'มีรูปภาพเดียว',
    },
    {
      value: 'true',
      label: 'มีหลายรูปภาพ'
    }
  ]

  let value

  if (album) {
    if (album.multiple === true) {
      value = 'true'
    } else {
      value = 'false'
    }
  } else {
    value = 'true'
  }

  let newId = "multiple"

  if (id) {
    newId = `${id}.${newId}`
  }

  return (
    <RadioGroup
      required
      childExternal
      label="จำนวนไฟล์รูปภาพ"
      id={newId}
      value={value}
      radios={radios}
    />
  )
}

export const AlbumCrop = (props) => {
  const { album, id } = props

  let radios = [
    {
      value: 'false',
      label: 'ไม่มีการครอบรูปภาพ',
      child: (
        <div>
          <input type="hidden" data-id="width" defaultValue="null" />
          <input type="hidden" data-id="height" defaultValue="null" />
        </div>
      )
    },
    {
      value: 'true',
      label: 'มีการครอบรูปภาพ',
      child: (
        <div>
          <AlbumWidth album={album} id={id} />
          <AlbumHeight album={album} id={id}  />
        </div>
      )
    }
  ]

  let value

  if (album) {
    if (album.crop === true) {
      value = 'true'
    } else {
      value = 'false'
    }
  } else {
    value = 'false'
  }

  let newId = "crop"

  if (id) {
    newId = `${id}.${newId}`
  }

  return (
    <RadioGroup
      required
      childExternal
      label="การครอบรูปภาพ"
      id={newId}
      value={value}
      radios={radios}
    />
  )
}

export const AlbumWidth = (props) => {
  const { album, id } = props

  let newId = "width"

  if (id) {
    newId = `${id}.${newId}`
  }

  return (
    <InputDecimal
      required
      label="ความกว้างรูปภาพ"
      unitName="(px)"
      placeholder="ความกว้างรูปภาพ"
      id={newId}
      decimal="2"
      value={album && album.width}
      iconValid
    />
  )
}

export const AlbumHeight = (props) => {
  const { album, id } = props

  let newId = "height"

  if (id) {
    newId = `${id}.${newId}`
  }

  return (
    <InputDecimal
      required
      label="ความสูงรูปภาพ"
      unitName="(px)"
      placeholder="ความสูงรูปภาพ"
      id={newId}
      decimal="2"
      value={album && album.height}
      iconValid
    />
  )
}


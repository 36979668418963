export const getTitle = (props) => {

  const { match, params } = props
  const { content_group_page_id } = match.params
  const { contentGroupPage, contentGroupPageHeader, contentGroupPageFooter } = params

  let groupId = content_group_page_id
  let title, titleFull, subtitle

  if (content_group_page_id === 'cart') {
    title = contentGroupPage.name.local
    titleFull = title
  }

  if (contentGroupPageHeader) {
    groupId = `${groupId}-header`
    subtitle = 'ส่วนหัว'
    titleFull = `${subtitle} (${title})`
  }

  if (contentGroupPageFooter) {
    groupId = `${groupId}-footer`
    subtitle = 'ส่วนท้าย'
    titleFull = `${subtitle} (${title})`
  }

  return {
    title,
    groupId,
    titleFull,
    subtitle
  }
}

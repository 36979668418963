import React from 'react'

// Util
import { getNumeral } from '../../../../../util/getNumeral'

// Values
import {
  getOrderLabelsSummary,
  getOrderInstallmentLabelsSummary,
  getTotalPriceAll
} from '../../../../../redux/Orders/OrdersValues'


const OrderPriceSummary = (props) => {
  const { order, bill } = props

  let orderLabels, totalPriceAll

  if (!bill) {
    orderLabels = getOrderLabelsSummary(order)
    totalPriceAll = getTotalPriceAll(order)

  } else {
    if (bill.type === 'quotation') {
      orderLabels = getOrderLabelsSummary(order)
      totalPriceAll = getTotalPriceAll(order)

    } else {
      if (!bill.installment.multiple) {
        orderLabels = getOrderLabelsSummary(order)
      } else {
        orderLabels = getOrderInstallmentLabelsSummary(order, bill.installment)
      }

      totalPriceAll = bill.installment.price
    }
  }


  const list = (label, value, rate=null) => {
    return (
      <table className="font-0-9">
        <tbody>
          <tr>
            <td className="pd-0 height-25 line-height-25">
              <span className={props.fontSize}>{label}</span>

              { rate && <span className={`mg-left-10 ${props.fontSize}`}>({rate}%)</span> }

            </td>
            <td className="pd-0 text-align-right">
              <b className={props.fontSize}>{ getNumeral(value, 2) }</b>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }


  return (
    <div>
      { orderLabels.map((orderLabel, i) => {
        return (
          <div key={i} className={props.rowClassName}>
            { list(orderLabel.label, orderLabel.value, orderLabel.rate) }
          </div>
        )
      }) }

      { props.totalPriceAll &&
        <div className="pd-top-5 pd-bottom-5">
          <table>
            <tbody>
              <tr>
                <td height="27" className="pd-0" style={{ lineHeight: '27px' }}>
                  <span className={`color-000 ${props.fontSize}`}>ราคารวมทั้งหมด</span>
                </td>
                <td className="pd-0 text-align-right">
                  <b className={props.fontSize}>{ getNumeral(totalPriceAll, 2) }</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      }
    </div>
  )
}

export default OrderPriceSummary

import React from 'react'

// Components Local
import CustomerPreviewManager from './CustomerPreviewManager'
import BtnModalSelectCustomer from './BtnModalSelectCustomer'

// css
import css from './css/customer.css'


const CustomerBox = (props) => {
  const { customer } = props

  return (
    <div className={css.customer}>
      { customer ?
        <CustomerPreviewManager {...props} customer={customer} />
      :
        <BtnModalSelectCustomer {...props} />
      }
    </div>
  )
}

export default CustomerBox
import React, { Component } from 'react'

// css
import css from './css/ellipsis.css'

class Ellipsis extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: parseInt(this.props.height),
      maxHeight: parseInt(this.props.height) + 1,
      lineHeight: parseInt(this.props.height) / parseInt(this.props.lines),
      ellipsis: false
    }
  }

  componentDidMount() {
    this.getDivElement()
    window.addEventListener("resize", this.handleResizeDivElement)
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        height: parseInt(this.props.height),
        maxHeight: parseInt(this.props.height) + 1,
        lineHeight: parseInt(this.props.height) / parseInt(this.props.lines),
      }, () => {
        this.getDivElement()
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", null)
  }

  getDivElement = () => {
    setTimeout(()=> {
      if (this.divElement) {
        const height = this.divElement.clientHeight

        if (height <= this.state.height)
          this.setState({ ellipsis: false })
        else
          this.setState({ ellipsis: true })
      }
    }, 0)
  }

  handleResizeDivElement = () => {
    if (this.divElement) {
      const height = this.divElement.clientHeight

      /*if (height !== 0) {
        console.log('clientHeight : ' + height)
        console.log('      height : ' + this.state.height)
      }*/


      if (height <= this.state.height)
        this.setState({ ellipsis: false })
      else
        this.setState({ ellipsis: true })
    }
  }


  render() {
    const fontSize = this.props.fontSize ? this.props.fontSize : '0.9rem'
    const style = {
      fontSize: fontSize,
      lineHeight: `${this.state.lineHeight}px`,
      maxHeight: `${this.state.maxHeight}px`,
      overflow: this.state.ellipsis ? 'hidden' : undefined
    }

    const joinStyle = Object.assign(style, this.props.style);

    return (
      <div className={css.box} ref={ (divElement) => this.divElement = divElement} style={joinStyle}>
        {this.props.text}

        { this.state.ellipsis && <div className={css.dots}> ...</div> }
      </div>
    )
  }
}

export default Ellipsis

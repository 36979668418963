export const voucherValue = ({ voucher, lang="local" }) => {
  let nameLang, avatarName, descriptions, descriptionsFull
  let statusName, statusIcon, statusColor
  let typeName

  if (voucher) {
    nameLang = voucher.name ? voucher.name[lang] : null
    avatarName = nameLang ? [nameLang] : null
    descriptions = voucher.descriptions ? voucher.descriptions[lang] : null
    descriptionsFull = voucher.descriptionsFull ? voucher.descriptionsFull[lang] : null
  }

  // Status
  if (voucher.status === 'open') {
    if (lang === 'local') statusName = 'เปิด'

    statusIcon = "public"
    statusColor = "#7cb342"

  } else if (voucher.status === 'close') {
    if (lang === 'local') statusName = 'ปิด'

    statusIcon = "lock"
    statusColor = "#ffb300"

  } else if (voucher.status === 'trash') {
    if (lang === 'local') statusName = 'ถังขยะ'

    statusIcon = "delete"
    statusColor = "red"
  }

  // typeName
  if (voucher.type === 'product') {
    if (lang === 'local') typeName = 'ส่วนลดสินค้า'
  } else if (voucher.type === 'delivery') {
    if (lang === 'local') typeName = 'ส่วนลดค่าจัดส่ง'
  }

  return {
    ...voucher,
    nameLang,
    avatarName,
    descriptions,
    descriptionsFull,
    statusName, statusIcon, statusColor,
    typeName
  }
}

import React, { Fragment } from 'react'
import css from './css/windowDefault.css'


const WindowDefault = (props) => {
  const { windowModal } = props

  return (
    <div
      className={`${css.windowDefault} ${props.className || ''}`}
      style={{
        ...props.style || {},
        top: windowModal === false && '100%'
      }}>

      { props.children }
    </div>
  )
}

export default WindowDefault

import React, { Fragment } from 'react'

// Components Global
import InputCheckDuplicate from '../../../../../components/Inputs/InputCheckDuplicate'
import TextInput from '../../../../../components/Inputs/TextInput'
import Switch from '../../../../../components/Inputs/Switch'
import Textarea from '../../../../../components/Inputs/Textarea'
import SelectDefault from '../../../../../components/Inputs/SelectDefault/SelectDefault'

// Actions
import { checkValueDuplicate } from '../../../../../redux/Pages/PagesActions'

// Functions
import { handleSwitch, handleSwitchSinglePage } from '../../../../../redux/Pages/PagesFunctions'


export const PageStatusSwitch = (props) => {
  const { page, name, title, singlePage } = props

  let params, updateParams, fnSwitch

  if (singlePage) {
    fnSwitch = handleSwitchSinglePage
  } else {
    fnSwitch = handleSwitch
  }

  if (page) {
    if (page[name] === 'close' || page[name] === false) {

      if (name === 'status') {
        updateParams = {
          status: 'open'
        }
      } else {
        updateParams = {
          [name]: true
        }
      }

      params = fnSwitch({
        props,
        title: `แสดง${title}`,
        updateParams
      })

    } else if (page[name] === 'open' || page[name] === true) {
      if (name === 'status') {
        updateParams = {
          status: 'close',
          navbarShow: false
        }
      } else {
        updateParams = {
          [name]: false
        }
      }

      params = fnSwitch({
        props,
        title: `ซ่อน${title}`,
        updateParams
      })
    }
  }

  return (
    <div>
      { page &&
        <Fragment>
          { (page[name] === 'close' || page[name] === false) &&
            <Switch
              id={page._id}
              value={false}
              labelOff="ซ่อน"
              labelOpen="แสดง"
              confirm={{
                label: params.label,
                type: "success",
                icon: "public",
                onConfirm: params.onConfirm
              }}
            />
          }

          { (page[name] === 'open' || page[name] === true) &&
            <Switch
              id={page._id}
              value={true}
              labelOff="ซ่อน"
              labelOpen="แสดง"
              confirm={{
                label: params.label,
                type: "warning",
                icon: "lock",
                onConfirm: params.onConfirm
              }}
            />
          }
        </Fragment>
      }
    </div>
  )
}

export const PageName = (props) => {
  const lang = props.lang || 'local'
  const label = props.label || 'ชื่อหน้าเว็บ'

  return (
    <TextInput
      label={label}
      id={`name.${lang}`}
      value={props.value && props.value[lang]}
      placeholder={label}
      type="text"
      required
      iconValid
    />
  )
}

export const PageSeoImageUrl = (props) => {
  const label = props.label || 'รูปภาพ (url)'

  return (
    <TextInput
      label={label}
      id={`seo.imageUrl`}
      value={props.value}
      placeholder={label}
      type="text"
      iconValid
    />
  )
}


export const PageSeoTitle = (props) => {
  const lang = props.lang || 'local'

  return (
    <TextInput
      label="หัวข้อ"
      id={`seo.title.${lang}`}
      value={props.value && props.value[lang]}
      placeholder="หัวข้อ"
      type="text"
      iconValid
    />
  )
}

export const PageSeoDescription = (props) => {
  const lang = props.lang || 'local'

  return (
    <Textarea
      label="รายละเอียด"
      placeholder="รายละเอียด"
      id={`seo.description.${lang}`}
      value={props.value && props.value[lang]}
      iconValid
    />
  )
}

export const PageSeoKeywords = (props) => {
  const lang = props.lang || 'local'

  return (
    <Textarea
      label="คีย์เวิร์ด"
      placeholder="คีย์เวิร์ด"
      id={`seo.keywords.${lang}`}
      value={props.value && props.value[lang]}
      iconValid
    />
  )
}

export const PagePath = (props) => {
  const { site, value, page, onChange } = props
  const query =`sites_id=${site._id}`

  let unselectId

  if (page && page._id) {
    unselectId = page._id
  }

  return (
    <InputCheckDuplicate
      id="path"
      label="ชื่อ URL"
      type="path"
      value={value}
      action={checkValueDuplicate}
      query={query}
      unselectId={unselectId}
      onChange={onChange}
      required
    />
  )
}

export const BoxStatus = (props) => {
  const { status, title, children } = props

  return (
    <div className={`${status === true ? 'border-success' : 'border-warning'} mg-10 mg-bottom-20 pd-10 border-radius-5`}>
      <table>
        <tbody>
          <tr>
            <td className="pd-0 color-000">
              { title }
            </td>
            <td width="150" className="pd-0 text-right">
              { children }
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}


export const PageGroupPageType = (props) => {
  return (
    <SelectDefault
      active
      id="groupPageType"
      label="ประเภทกลุ่มหน้าเพจ"
      placeholder="ประเภทกลุ่มหน้าเพจ"
      value={props.value}
      required
      options={[
        { value: 'main', name: 'หน้าหลัก (main)' },
        { value: 'profile', name: 'หน้าโปรไฟล์ (profile)' },
      ]}
    />
  )
}

export const PageFetchType = (props) => {
  return (
    <SelectDefault
      active
      id="fetchType"
      label="ดึงข้อมูล API"
      value={props.value}
      default
      options={[
        { value: 'null', name: 'ไม่มี' },
        { value: 'product-details', name: 'รายละเอียดสินค้า' },
      ]}
    />
  )
}

export const PageFetchId = (props) => {
  const label = props.label || 'ไอดี (ดึงข้อมูล API)'

  return (
    <TextInput
      label={label}
      id={`fetchId`}
      value={props.value}
      placeholder={label}
      type="text"
    />
  )
}




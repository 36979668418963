import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components Global
import FormValidation from '../../../../../../../components/FormValidation'
import BtnSubmit from '../../../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../../../util/getFormValue'

// Components Salse
import { ProductQty  } from '../../../OrderForms'
import ProductDescriptions from '../../../ProductDescriptions'
import ProductItemDescriptions from '../../../ProductItemDescriptions'


class ProductItemUpdate extends Component {
  handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(this.refs[formName])
    const { orderItem, submit } = this.props

    if (params) {
      if (orderItem) {
        params.qty = params.qty - orderItem.qty
      }

      submit(params, formName)
    }
  }

  render() {
    const { orderItem, product, productItem } = this.props
    const id = productItem._id

    return (
      <div className="pd-0">
        <div className="pd-10">
          <ProductDescriptions product={product} />
        </div>

        <div className="pd-10 border-top-efefef">
          <ProductItemDescriptions
            orderItem={orderItem}
            product={product}
            productItem={productItem}
          />
        </div>

        <div className="pd-10 text-right">
          <FormValidation
            recheckForm
            ref={id}
            name={id}
            submit={(e) => this.handleSubmit(e, id)}>

            <table>
              <tbody>
                <tr>
                  <td className="pd-0 pd-right-10">
                    <ProductQty
                      product={product}
                      productItem={productItem}
                      value={{
                        qty: orderItem && orderItem.qty
                      }}
                    />
                  </td>
                  <td width="130" className="pd-0 text-right">
                    <BtnSubmit
                      loadingId={id}
                      icon="check"
                      iconAlign="left"
                      text="บันทึก"
                      className="waves-effect width-full btn-shadow-none waves-light btn light-green darken-1"
                      btnDisabled
                    />
                  </td>
                </tr>
              </tbody>
            </table>

          </FormValidation>
        </div>
      </div>
    )
  }
}

ProductItemUpdate.propTypes = {
  submit: PropTypes.func.isRequired,
  orderItem: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  productItem: PropTypes.object.isRequired
}

export default ProductItemUpdate

import React, { useState } from 'react'

/*const SectionCircle = (props) => {
  let { size } = props
  size = size || 50
  size = parseInt(size)

  console.log(size)

  return (
    <div className={`center height-${size} ${props.className}`}>
      <div className={`height-${size} bg-fafafa line-height-${size} border-c0c0c0 border-radius-${size/2} dp-inline-block font-1-1 pd-left-${size/2} pd-right-${size/2}`}>{props.title}</div>
      <div className={`border-top-c0c0c0 mg-top--${size/2}`}></div>
    </div>
  )
}

export default SectionCircle*/


const SectionCircle = (props) => {
  let { size } = props
  size = size || 50
  size = parseInt(size)

  return (
    <div className={`${props.className}`}>
      <div className={`center height-${size}`}>
        <div className={`height-${size} bg-fafafa line-height-${size} border-c0c0c0 border-radius-${size/2} dp-inline-block font-1-1 pd-left-${size/2} pd-right-${size/2}`}>{props.title}</div>
      </div>

      <div className={`border-ddd border-radius-5 mg-top--${size/2} ${props.bodyClassName}`}>
        <div className={`pd-top-${size/2}`}>
          { props.children }
        </div>
      </div>
    </div>
  )
}

export default SectionCircle

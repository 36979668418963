import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// Redux Actions
import { addTransition } from '../../../redux/App/AppActions'


const LinkTransition = (props) => {
  const onClick = () => {
    props.dispatch(addTransition(props.transition))

    setTimeout(() => {
      props.history.push({ 
        pathname: props.to,
        state: { transition: true }
      })
    }, 1)
  }

  return (
    <span onClick={onClick} className={props.className} style={props.style}>
      {props.children}
    </span>
  )
}

const withRouterComponent = withRouter(LinkTransition)

export default connect()(withRouterComponent)
import React from 'react'
import { Link } from 'react-router-dom'

// components local
import WindowContainer from '../WindowContainer'

// css
import css from './css/windowBack.css'

const WindowBack = (props) => {
  let pathname
  let linkBack

  if (window) {
    let url = window.location.pathname
    let urlSplit = url.split('/')
    let replace = urlSplit[urlSplit.length-1]

    if (replace) pathname = url.replace(`/${replace}`, '')
  }

  if (props.linkBack) {
    linkBack = props.linkBack
  } else {
    linkBack = pathname
  }

  const { history, pageSlider } = props

  const goBack = () => {
    if (pageSlider) {
      pageSlider.current.close()

      setTimeout(() => {
        history.push(linkBack)
      }, 1000)
    } else {
      history.push(linkBack)
    }
  }

  return (
    <WindowContainer minusTotop={props.minusTotop}>
      <div className={`${css.header} ${props.className || ''}`} style={props.style}>
        <div className={`${css.back} width-70-xxs width-70-xs width-70-sm`}>
          <a className={css.btnBack} onClick={goBack} style={{ cursor: 'pointer' }}>
            <i className="material-icons left i-middle mg-left-10-xxs mg-left-10-xs mg-left-10-sm">arrow_back</i>
            <span className="hidden-xs hidden-sm"> กลับ</span>
          </a>
        </div>

        <div className={`${css.boxTitle} left-70-xxs left-70-xs left-70-sm hidden-xlg hidden-lg hidden-md`}>
          <div className={css.title}>
            <span>{props.title}</span>
          </div>
        </div>

        { props.breadcrumbs ?
          <div className={`${css.boxBreadcrumb} left-70-xxs left-70-xs left-70-sm hidden-xxs hidden-xs hidden-sm`}>
            { props.breadcrumbs.map((breadcrumb, i) => {
              return (
                <Link key={i} to={breadcrumb.url} className={css.breadcrumb}>
                  <label className={css.label}>{breadcrumb.label}</label>
                </Link>
              )
            }) }
          </div>
        :
          <div className={`${css.boxTitle} left-70-xxs left-70-xs left-70-sm hidden-xxs hidden-xs hidden-sm hidden-md`}>
            <div className={css.title}>
              <span>{props.title}</span>
            </div>
          </div>
        }
      </div>

      { props.scrollbars ?
        <div className={css.childrenScroll}>{props.children}</div>
      :
        <div className={css.children}>
          {props.children}
        </div>
      }
    </WindowContainer>
  )
}

export default WindowBack

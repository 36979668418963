import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../../../components/Modals/Modal'
import FormValidation from '../../../../../../../components/FormValidation'
import Select from '../../../../../../../components/input/Select/index'
import CheckBox from '../../../../../../../components/input/CheckBox/index'
import BtnSubmit from '../../../../../../../components/button/BtnSubmit'

// Util
import getFormValue from '../../../../../../../util/getFormValue'

// Actions
import { updateOrder } from '../../../../../../../redux/Orders/OrdersActions'



class SrcEditModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  fnStartModal() {
    let order = this.props.order
    let checkBox = order.src.status === true

    this.mounted && this.setState({ checkBox: checkBox }, () => {
      this.setState({ disabled: this.checkDisabled() })
    })
  }

  checkDisabled = () => {
    let order = this.props.order
    let checkBox = this.state.checkBox
    let disabled

    if (order.src.status) {
      if (checkBox === undefined) disabled = false
      else if (checkBox === true) disabled = false
      else if (checkBox === false) disabled = true
    } else {
      if (checkBox === undefined) disabled = true
      else if (checkBox === true) disabled = false
      else if (checkBox === false) disabled = true
    }

    return disabled
  }

  handleSubmit = (e, formName) => {
    e.preventDefault()
    let params = getFormValue(this.refs[formName])

    if (params['src.rate'] === '0') {
      params['src.status'] = false
    }

    const { business, order } = this.props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id,
      orders_id: order._id
    }

    this.props.dispatch(updateOrder(query, {
      body: params,
      loadId: 'srcEditModal',
      modalId: 'srcEditModal',
      toastMsg: 'แก้ไขเซอร์วิสชาร์จแล้ว'
    })).then(res => {
      if (res.success && this.props.fetch) {
        this.props.fetch()
      }
    })
  }

  onChange = (value, id) => {
    this.mounted && this.setState({ checkBox: value, disabled: value === false })
  }

  render() {
    let order = this.props.order

    return (
      <Modal
        id="srcEditModal"
        headerTitle="เซอร์วิสชาร์จ"
        headerLeft
        fnStartModal={() => this.fnStartModal()}
        width="350px"
        padding="0px">

        <div className="pd-15">
          <FormValidation ref="srcEditModal" name="srcEditModal" submit={(e) => this.handleSubmit(e, 'srcEditModal')} >
            <table>
              <tbody>
                <tr>
                  <td className="pd-0" width="40">
                    <CheckBox
                      id={`src.status`}
                      size="sm"
                      value={order.src.status}
                      onChange={this.onChange}
                      className="mg-top-15"
                    />
                  </td>
                  <td className="pd-0">
                    <Select
                      active
                      disabled={this.state.disabled}
                      disabledValue="0"
                      disabledPlaceholder="0%"
                      id={`src.rate`}
                      default
                      value={this.state.disabled ? '0' : order.src.rate }
                      className="mg-bottom-0"
                      options={[
                        { value: 0, name: '0%' },
                        { value: 10, name: '10%' }
                      ]}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="pd-top-15 text-right">
              <BtnSubmit
                icon="send"
                iconAlign="left"
                text="บันทึก"
                className="waves-effect waves-light btn light-green darken-1"
                loadingId="srcEditModal"
                btnDisabled
              />
            </div>
          </FormValidation>
        </div>
      </Modal>
    )
  }
}

export default connect()(SrcEditModal)

import { stateSearchDefault } from "core/redux/reduxReducerV2"

export const findStoreDatasByGroupId = ({ state, store, storeName, groupId }) => {
  let datas = []
  let datasSearch = stateSearchDefault()

  if (store) {
    storeName = store.name
    groupId = store.groupId
  }

  if (storeName && groupId) {
    const datasStore = state[storeName]

    if (groupId) {
      const dataGroups = datasStore.dataGroups || []
      const group = dataGroups.filter(g => g.groupId === groupId)[0]

      if (group) {
        if (group.data) {
          datas = group.data
        }

        if (group.search) {
          datasSearch = group.search
        }
      }

    } else {
      datas = datasStore.data
      datasSearch = datasStore.search
    }
  }

  return {
    datas,
    datasSearch
  }
}

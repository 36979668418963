import React from 'react'
import Scrollbar from 'react-perfect-scrollbar'
import { NavLink, Link, withRouter } from 'react-router-dom'
import renderRoutes from 'core/routes/renderRoutes'

// Components Global
import Window from 'components/Windows/Window'

// imgs
import titleIcon from '../../resources/imgs/conversation-title-icon.png'

// css
import css from './css/conversationsMenuContainer.css'
import { checkMenuActive } from 'components/Menu'

const ConversationsMenuContainer = (props) => {
  const { business, location, route, match, history, windowModal, closeWindowModal, params } = props
  const path = `/${match.params.username}/conversations`

  const menu = [
    {
      icon: 'forum',
      name: "ทั้งหมด",
      link: `${path}/all/all/all`,
      linksActive: [
        { url: `${path}/all/all/all` },
        { url: `${path}/all/all/:objectId` },
        { url: `${path}/:objectId/all/:objectId` }
      ],
    },
    {
      icon: '3p',
      name: "แชทของคุณ",
      link: `${path}/all/me/all`,
      linksActive: [
        { url: `${path}/all/me/all` },
        { url: `${path}/all/me/:objectId` }
      ],
    },
    {
      icon: 'push_pin',
      name: "ปักหมุด",
      link: `${path}/all/pin/all`,
      linksActive: [
        { url: `${path}/all/pin/all` },
        { url: `${path}/all/pin/:objectId` }
      ],
    },
    {
      icon: 'mark_unread_chat_alt',
      name: "ยังไม่อ่าน",
      link: `${path}/all/unread/all`,
      linksActive: [
        { url: `${path}/all/unread/all` },
        { url: `${path}/all/unread/:objectId` }
      ],
    },
    {
      icon: 'group',
      name: "ลูกค้า",
      link: `${path}/customers`,
    },
    {
      icon: 'settings',
      name: "ตั้งค่า",
      link: `${path}/settings/channels/all`,
      linksActive: [
        { url: `${path}/settings/channels/all` },
        { url: `${path}/settings/channels/:objectId` }
      ],
    },
  ]

  return (
    <Window
      title="สนทนา (แชท)"
      titleIcon={titleIcon}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkClose={`/${match.params.username}`}>

      <div className={css.layout}>
        <div className={css.defaultLayout1}>
          <Scrollbar>
            <div className={`${css.menu}  `}>
              <div>
                { menu.map(item => {
                  const active = checkMenuActive(props.location, item, css)

                  return (
                    <div className={css.menuItem} key={item.icon}>
                      <NavLink to={item.link} className={`${css.menuItemBox} ${active}`}>
                        <i className="material-icons">{item.icon}</i>
                        <div className="font-0-6 mg-top-3"><span>{item.name}</span></div>
                      </NavLink>
                    </div>
                  )
                }) }
              </div>
            </div>
          </Scrollbar>
        </div>
        <div className={css.defaultLayout2}>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, windowModal, closeWindowModal },
            location
          }) }
        </div>
      </div>
    </Window>
  )
}

export default ConversationsMenuContainer

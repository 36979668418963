import React from 'react'
import renderRoutes from '../../../../routes/renderRoutes'

// Components Global
import Window from '../../../../components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../components/MenuSidebarResponsive'

// Components Products
import ProductCreateModal from '../components/ProductCreateModal'

// Components Attributes
import AttributesMenu from '../../Attributes/components/AttributesMenu'

// Components Folders
import FoldersMenu from '../../Folders/components/FoldersMenu'

// imgs
import titleIcon from '../resources/imgs/Products-96.png'

const menuItems = (params, set) => {
  const { username, attributes_path } = params

  let path = `/${username}/products`

  if (attributes_path) {
    path = `/${username}/products/${attributes_path}`
  }

  if (set) {
    return [
      /*{
        name: 'รายการ',
        title: true
      },
      {
        name: 'ภาพรวม',
        icon: 'dashboard',
        link: `/${params.username}/products`
      },*/
      {
        name: 'สินค้า',
        title: true
      },
      {
        name: 'ทั้งหมด',
        nameShort: 'ทั้งหมด',
        icon: 'assignment',
        link: `${path}/all/all`,
        searchLinkActive: `${path}/all/`
      },
      {
        name: 'ปิดใช้งาน',
        nameShort: 'ลบแล้ว',
        icon: 'lock',
        link: `${path}/close/all`,
        searchLinkActive: `${path}/close/`
      },
      {
        name: 'ที่ถูกลบ',
        nameShort: 'ลบแล้ว',
        icon: 'delete',
        link: `${path}/trash/all`,
        searchLinkActive: `${path}/trash/`
      },

      {
        name: 'จัดการ',
        title: true
      },
      {
        name: 'คลังสินค้า',
        icon: 'archive',
        link: `/${params.username}/products/stock`,
        hidden: !set.stockAdjust.use
      },
      ...FoldersMenu({
        path,
        hidden: !set.folder.use,
        hiddenTitle: true
      }),
      {
        name: 'พิมพ์ป้ายสินค้า',
        nameShort: 'พิมพ์ป้ายสินค้า',
        icon: 'qr_code',
        link: `/${params.username}/products/print-barcode`,
        hidden: !set.stockAdjust.use
      },
      {
        name: 'ส่งออกสินค้าไฟล์ Excel',
        nameShort: 'ส่งออกไฟล์ Excel',
        icon: 'import_export',
        link: `/${params.username}/products/export`
      },


      {
        name: 'ส่งเสริมการขาย',
        title: true
      },
      {
        name: 'โปรโมชั่นส่วนลด',
        nameShort: 'โปรโมชั่น',
        icon: 'money_off',
        link: `/${params.username}/products/discounts`,
        exact: true
      },
      /*{
        name: 'โค้ดส่วนลด',
        nameShort: 'โค้ดส่วนลด',
        icon: 'confirmation_number',
        link: `/${params.username}/products/vouchers`,
        exact: true
      },*/
      ...AttributesMenu({ params, typePath: 'products', label: 'สินค้า', hidden: !set.attribute.use }),
    ]
  }
}

const ProductsMenuContainer = (props) => {
  const { business, setting, location, route, match, windowModal, closeWindowModal, history, lang } = props
  const { productsSettings } = business

  return (
    <Window
      title="สินค้า"
      titleIcon={titleIcon}
      linkClose={`/${match.params.username}`}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      langSetting
      linkHelp="https://www.youtube.com/playlist?list=PLHuj62tDAxzP837HaBgfO75eL3TUbL95H">

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive
            btnAddModal={{
              modalId: 'productCreateModal',
              text: 'เพิ่มสินค้า',
            }}
            items={menuItems(match.params, business.productsSettings)}
          />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, setting, lang },
            location
          }) }
        </Layouts2>
      </LayoutsTwo>

      <ProductCreateModal match={match} business={business} />
    </Window>
  )
}

export default ProductsMenuContainer

import React, { Component } from 'react'

const Hammer = (typeof window !== 'undefined') ? require('hammerjs') : undefined
const privateProps = {}
const handlerToEvent = {
	onPan: 'pan',
	onPanCancel: 'pancancel',
	onPanEnd: 'panend',
	onPanStart: 'panstart',
  onPanUp: 'panup',
  onPanDown: 'pandown',
  onPanLeft: 'panleft',
  onPanRight: 'panright',
  onPanMove: 'panmove',
  onSwipeLeft: 'swipeleft',
  onSwipeRight: 'swiperight',
  onSwipeUp: 'swipeup',
  onSwipeDown: 'swipedown'
}

Object.keys(handlerToEvent).forEach((i) => {
	privateProps[i] = true
})

const updateHammer = (props, stop=false) => {
  let stage = document.getElementById(props.id)
  let manager = new Hammer.Manager(stage, {
    touchAction: 'pan-y',
    recognizers: [
        //[Hammer.Pan,{ direction: Hammer.DIRECTION_HORIZONTAL }],
        [Hammer.Swipe,{ direction: Hammer.DIRECTION_HORIZONTAL }],
    ]
  })

  Object.keys(props).forEach((i) => {
    if (privateProps[i]) {
      let event = i

      if (event === 'onPan' 
        || event === 'onPanCancel' 
        || event === 'onPanEnd' 
        || event === 'onPanStart' 
        || event === 'onPanUp' 
        || event === 'onPanDown' 
        || event === 'onPanLeft' 
        || event === 'onPanRight' 
        || event === 'onPanMove') {
        
        let eventName = event.split('on')[1].toLowerCase()
        let Pan = new Hammer.Pan()
        manager.add(Pan)

        manager.on(eventName, (e) => {
          props[event](e)
        })
      }

      if (event === 'onSwipeLeft' || event === 'onSwipeRight' || event === 'onSwipeUp' || event === 'onSwipeDown') {
        let eventName = event.split('on')[1].toLowerCase()
        let Swipe = new Hammer.Swipe()
        manager.add(Swipe)

        manager.on(eventName, (e) => {
          console.log(e)
          props[event](e)
        })
      }
    }
  }) 
}


class Hammerjs extends Component {

  displayName = 'Hammer'

  componentDidMount() {
    var stage = document.getElementById(this.props.id)
    var manager = new Hammer.Manager(stage)

    updateHammer(this.props)
  }

  render() {
    var props = {}
 
    Object.keys(this.props).forEach((i) => {
			if (!privateProps[i]) props[i] = this.props[i]
		})

    return React.cloneElement(React.Children.only(this.props.children), props)
  }
}

export default Hammerjs
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../routes/renderRoutes'

// Actions Redux
import { fetchSite } from '../../../../redux/Sites/SitesActions'


class SiteContainer extends Component {
  componentDidMount() {
    const { business, match } = this.props
    const { params } = match

    this.props.dispatch(fetchSite(business._id, params.sites_id))
  }

  render() {
    const { route, site, business, setting, location } = this.props

    return (
      <Fragment>
        { site ?
          <Fragment>
            { renderRoutes({
              routes: route.routes,
              extraProps: { business, site, setting },
              location
            }) }
          </Fragment>
        :
          <h1>404 Not Found</h1>
        }
      </Fragment>
    )
  }
}


const mapStateToProps = (store, props) => {
  const { business, match } = props
  const { params } = match

  return {
    site: store.sites.data.filter((site => site._id === params.sites_id))[0]
  }
}

export default connect(mapStateToProps)(SiteContainer)

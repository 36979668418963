import React, { Component, useEffect, useState, useRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'
import cuid from 'cuid'

// Components Inputs
import Required from '../Required'
import Chip from '../../Chip'

// Components
import InfiniteScroll from '../../InfiniteScroll'
import PreLoading from '../../PreLoading'

// Redux Actions
import { addLoad } from '../../../redux/App/AppActions'

//css
import css from './css/dropdown.css'

// Js
import { onSearchData } from '../../InputSearch/js/InputSearchFunction'


class InputDropdown extends Component {
  constructor(props) {
    super(props)

    const id = this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()
    this.timer = null
    this.state = {
      id: randomId+id,
      open: false,
      items: this.props.items || []
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.setState({ items: this.props.items || [] })
    }

    if (prevProps.itemsReload && this.props.itemsReload)
    if (JSON.stringify(prevProps.itemsReload) !==  JSON.stringify(this.props.itemsReload)) {
      this.setState({ items: [] })
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick)
  }

  handleDocumentClick = (e) => {
    if (this.state.open) {
      if (!ReactDOM.findDOMNode(this).contains(e.target)) {
        this.closeDropdown()
      }
    }
  }

  openDropdown = (e) => {
    if (!this.state.open) {
      this.setState({ open: true })

      if (e.target.value) {
        this.search(e)
      }
    }
  }

  closeDropdown = () => {
    if (this.state.open) {
      this.setState({ open: false })
    }
  }

  search = (e) => {
    clearTimeout(this.timer)

    const fetch = this.props.fetchAction
    const value = e.target.value

    if (!this.props.load) {
      this.props.dispatch(addLoad(this.props.id))
    }

    this.timer = setTimeout(() => {
      this.setState({ valueSearch: false }, () => {
        onSearchData(this, value, fetch, { loadId: this.props.id })
      })
    }, 1000)
  }

  select = (data) => {
    const index = this.state.items.findIndex(item => item._id === data._id)
    const newItems = [...this.state.items]

    if (index !== -1) {
      newItems.splice(index, 1)
    } else {
      newItems.push(data)
    }

    this.setState({ items: newItems }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.items)
      }
    })
  }

  unSelect = (i) => {
    const newItems = [...this.state.items]
    newItems.splice(i, 1)
    this.setState({ items: newItems }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.items)
      }
    })
  }

  render() {
    const { useSubData } = this.props
    const styleDropdwon = {
      left: this.props.left && '0',
      visibility: this.state.open ? 'visible' : 'hidden',
      opacity: this.state.open ? '1' : '0'
    }

    const getValueSearch = (datas, dataSearch) => {
      return dataSearch && dataSearch.status ? dataSearch.data : datas
    }

    const datas = getValueSearch(this.props.datas, this.props.datasSearch)

    return (
      <Fragment>
        { !this.props.disabled ?
          <Required
            id={this.state.id}
            classId={this.state.id}
            value={this.state.items.length === 0 ? '' : 'pass'}
            label={this.props.label}
            labelHidden={this.props.labelHidden}
            unitName={this.props.unitName}
            className={this.props.className}
            labelClassName={this.props.labelClassName}
            type="text"
            required={this.props.required}>

            <div
              onClick={this.openDropdown}
              className={`pd-top-7 min-height-35 ${this.state.id} ${this.props.inputClassName}`}
              style={{ borderBottom: '1px solid #9e9e9e' }}>

              { this.state.items.map((item, i) => {
                const chip = this.props.getChipValue(item)

                return (
                  <Chip
                    key={i}
                    id={this.props.id}
                    value={chip && chip.value}
                    remove={() => this.unSelect(i)}
                    avatar={chip && chip.avatar}
                    avatarIcon={chip && chip.avatarIcon}
                    className={`${this.props.chipClassName} mg-right-5`}
                    size={this.props.chipSize}
                  />
                )
              }) }
            </div>

            <input
              id={this.state.id}
              data-id={this.props.id}
              data-not-save={true}
              value={this.state.value ? this.state.value : ''}
              className={this.props.required ? 'required' : ''}
              type="hidden"
            />

            <div className={css.boxDropdown}>
              <ul className={`${css.dropdown} mg-bottom-30 min-width-250 border-radius-2 max-height-260 top--10`} style={styleDropdwon}>

                { !this.props.hiddenSearch &&
                  <div className="height-55 pd-10 border-bottom-efefef">
                    <input
                      type="text"
                      data-not-save={true}
                      placeholder="ค้นหา"
                      onKeyUp={this.search}
                      className={`pd-left-15 pd-right-15 mg-bottom-0 border-1 border-eee box-shadow-none`}
                      style={{
                        boxSizing: 'border-box'
                      }}
                    />
                  </div>
                }

                { datas.length !== 0 &&
                  <div className="height-3">
                    { this.props.load &&
                      <div className="progress height-3 mg-0 border-bottom-radius-0">
                        <div className="indeterminate"></div>
                      </div>
                    }
                  </div>
                }

                <div id={this.props.id + this.state.id} className={`overflow-auto max-height-200`}>
                  <InfiniteScroll
                    { ...this.props }
                    id={this.props.id}
                    startLoad={this.state.open}
                    scrollId={this.props.id + this.state.id}
                    fetchAction={this.props.fetchAction}
                    itemsData={this.props.datas}
                    searchData={this.props.datasSearch}>

                    <PreLoading
                      id={`preload-${this.props.id}`}
                      size="30px"
                      border="2px"
                      textSize="0.8rem"
                      style={{ position: 'relative' }}>

                      <div>
                        { !useSubData && datas.map(data => {
                          return (
                            <li key={`${this.state.id}-${data._id}`}>
                              <a onClick={() => this.select(data)}>
                                { this.props.item && this.props.item({ data, checked: this.state.items.filter(item => item._id === data._id)[0] }) }
                              </a>
                            </li>
                          )
                        }) }


                        { useSubData && datas.map(data => {
                          return data.items.map(item => {
                            return (
                              <li key={item._id}>
                                <a onClick={() => this.select({ data, item })}>
                                  { this.props.item && this.props.item({ data, item }) }
                                </a>
                              </li>
                            )
                          })
                        }) }
                      </div>

                    </PreLoading>
                  </InfiniteScroll>
                </div>
              </ul>
            </div>
          </Required>
        :
          <Required
            {...this.props}
            id={this.props.id}
            type="disabled"
            className={this.props.requiredClassName}
            labelClassName={this.props.labelClassName}
            label={this.props.label}
            unitName={this.props.unitName}
            required={this.props.required}
            default={this.props.value}>

            <div
              onClick={this.openDropdown}
              className={`pd-top-7 min-height-35 ${this.state.id} ${this.props.inputClassName}`}
              style={{ borderBottom: '1px solid #9e9e9e' }}>

              { this.state.items.map((item, i) => {
                const chip = this.props.getChipValue(item)

                return (
                  <Chip
                    key={i}
                    id={this.props.id}
                    value={chip && chip.value}
                    avatar={chip && chip.avatar}
                    avatarIcon={chip && chip.avatarIcon}
                    className={`${this.props.chipClassName} mg-right-5`}
                    size={this.props.chipSize}
                  />
                )
              }) }
            </div>
          </Required>
        }
      </Fragment>
    )
  }
}

InputDropdown.propTypes = {
  storeName: PropTypes.string.isRequired,
  fetchAction: PropTypes.object.isRequired,
  select: PropTypes.func.isRequired
}

const mapStateToProps = (state, props) => {
  let datas = state[props.storeName].data
  let datasSearch = state[props.storeName].search

  if (props.fetchAction && props.fetchAction.addGroupId) {
    datas = datas.filter(data => data.groupId === props.fetchAction.addGroupId)
  }

  return {
    datas,
    datasSearch,
    load: state.app.loads.filter(load => load === props.id)[0]
  }
}

export default connect(mapStateToProps)(InputDropdown)


// Containers
import ConversationsContainer from 'core/modules/Conversations/containers/ConversationsContainer'

// Routes
import ConversationsMenuRoutes from './routes/ConversationsMenuRoutes'


const ConversationsRoutes = [
  {
    path: '/:username/conversations',
    component: ConversationsContainer,
    routes: [
      ...ConversationsMenuRoutes
    ]
  }
]

export default ConversationsRoutes

import callApi from 'client/core/utils/apiCaller'
import cookie from 'react-cookie'

import {
  checkAppActions,
  httpStatus
} from '../App/AppActions'

// Export Constants
export const SIGN_UP = 'SIGN_UP'
export const SIGN_IN = 'SIGN_IN'
export const SIGN_OUT = 'SIGN_OUT'
export const GET_AUTH = 'GET_AUTH'

// Export type
export const _SIGN_UP = auth => ({ type: SIGN_UP, auth })
export const _SIGN_IN = auth => ({ type: SIGN_IN, auth })
export const _SIGN_OUT = auth => ({ type: SIGN_OUT, auth })
export const _GET_AUTH = auth => ({ type: GET_AUTH, auth })

// Actions
let customer

if (process.env.APP_PROJECT === 'dashboard' || process.env.APP_PROJECT === 'store' || process.env.APP_PROJECT === 'rubber' || process.env.APP_PROJECT === 'websites') {
  customer = require(`../../../redux/Customers/CustomersActions`)
} else {
  customer = require(`../../../projects/${process.env.APP_PROJECT}/redux/Customers/CustomersActions`)
}


// Export Actions
export const fetchAuthUserCustomer = (businesses_id, params) => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi(`auth/user-customer/${businesses_id}`, 'get', params.body).then(res => {
    if (res.success) {
      cookie.save('token', res.token, { path: '/' })

      dispatch(_GET_AUTH(res.data))

      checkAppActions(dispatch, params, 'success')
    } else {
      dispatch(_GET_AUTH('unauthorized'))
      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}

export const updateAuthUserCustomer = (auth_name, businesses_id, _id, params) => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi(`auth/user-customer/${businesses_id}`, 'put', params.body).then(res => {
    if (res.success) {
      cookie.save('token', res.token, { path: '/' })

      dispatch(_GET_AUTH(res.data))

      checkAppActions(dispatch, params, 'success')
    } else {
      dispatch(_GET_AUTH('unauthorized'))
      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}


export const getAuth = (params={}) => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi('auth/get-auth').then(res => {
    if (res.success) {
      dispatch(_GET_AUTH(res.data))

      checkAppActions(dispatch, params, 'success')
    } else {
      dispatch(_GET_AUTH('unauthorized'))
      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}


export const signOut = params => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi('auth/signout').then(res => {
    if (res.success) {
      dispatch(_SIGN_OUT('unauthorized'))

      cookie.save('token', '', { path: '/' })
      cookie.remove('token')

      checkAppActions(dispatch, params, 'success')
    } else {
      checkAppActions(dispatch, params, 'error')
    }
  })
}

export const logout = params => dispatch => {
  checkAppActions(dispatch, params, 'start')

  dispatch(_SIGN_OUT('unauthorized'))

  cookie.save('token', '', { path: '/' })
  cookie.remove('token')

  return callApi('auth/signout').then(res => {
    if (res.success) {
      checkAppActions(dispatch, params, 'success')
    } else {
      checkAppActions(dispatch, params, 'error')
    }
  })
}


export const sendEmailConfirmAccount = params => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi('auth/send-email-confirm-account', 'put', params.body).then(res => {
    if (res.success) {
      cookie.save('token', res.token, { path: '/' })
      dispatch(_SIGN_IN(res.data))

      checkAppActions(dispatch, params, 'success', res)
    } else {
      checkAppActions(dispatch, params, 'error', res)
    }
  })
}


export const checkConfirmAccountToken = params => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi('auth/check-confirm-accont-token', 'put', params.body).then(res => {
    if (res.success) {
      checkAppActions(dispatch, params, 'success', res)

      return res
    } else {
      checkAppActions(dispatch, params, 'error', res)

      return res
    }
  })
}


export const confirmAccountToken = params => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi('auth/confirm-accont-token', 'put', params.body).then(res => {
    if (res.success) {
      cookie.save('token', res.token, { path: '/' })

      checkAppActions(dispatch, params, 'success', res)

      return res
    } else {
      checkAppActions(dispatch, params, 'error', res)

      return res
    }
  })
}


export const forgotPassword = params => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi('auth/forgot-password', 'put', params.body).then(res => {
    if (res.success) {
      checkAppActions(dispatch, params, 'success', res)

      return res
    } else {
      checkAppActions(dispatch, params, 'error', res)

      return res
    }
  })
}


export const checkResetPasswordToken = params => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi('auth/check-reset-password-token', 'put', params.body).then(res => {
    if (res.success) {
      checkAppActions(dispatch, params, 'success', res)

      return res
    } else {
      checkAppActions(dispatch, params, 'error', res)

      return res
    }
  })
}


export const resetPassword = params => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi('auth/reset-password', 'put', params.body).then(res => {
    if (res.success) {
      cookie.save('token', res.token, { path: '/' })

      dispatch(_SIGN_IN(res.data))
      checkAppActions(dispatch, params, 'success', res)

      return res
    } else {
      checkAppActions(dispatch, params, 'error', res)

      return res
    }
  })
}


export const signUp = params => (dispatch) => {
  checkAppActions(dispatch, params, 'start')

  return callApi('auth/signup', 'put', params.body).then(res => {
    if (res.success) {
      cookie.save('token', res.token, { path: '/' })

      dispatch(_SIGN_IN(res.data))
      checkAppActions(dispatch, params, 'success', res)
    } else {
      checkAppActions(dispatch, params, 'error', res)
    }
  })
}

export const signUpForBusiness = (businesses_id, params) => (dispatch) => {
  checkAppActions(dispatch, params, 'start')

  return callApi(`auth/${businesses_id}/signup`, 'put', params.body).then(res => {
    if (res.success) {
      cookie.save('token', res.token, { path: '/' })

      dispatch(_SIGN_IN(res.data))
      checkAppActions(dispatch, params, 'success', res)
    } else {
      checkAppActions(dispatch, params, 'error', res)
    }
  })
}


export const socialSignInForBusiness = (businesses_id, params) => dispatch => {
  socialSignInFn({
    path: `auth/${businesses_id}/social-signin`,
    params,
    dispatch
  })
}

export const socialSignIn = (params) => dispatch => {
  socialSignInFn({
    path: `auth/social-signin`,
    params,
    dispatch
  })
}

export const signInForBusiness = (businesses_id, params) => dispatch => {
  signInFn({
    path: `auth/${businesses_id}/signin`,
    params,
    dispatch
  })
}

export const signIn = (params) => dispatch => {
  signInFn({
    path: `auth/signin`,
    params,
    dispatch
  })
}


export const refreshToken = auth => dispatch => {
  cookie.save('token', auth.token, { path: '/' })
  dispatch(_SIGN_IN(auth.data))

  console.log('Refresh Token')
}


export const reSignIn = username => dispatch => {
  return callApi('auth/re-signin').then(res => {
    if (res.success) {

      cookie.save('token', res.token, { path: '/' })
      dispatch(_SIGN_IN(res.data))

      if (username) {
        let businessesAuth = res.data.businessesAuth.filter(business => business.username === username)[0]

        if (businessesAuth === undefined) {
          dispatch(httpStatus(403))
        }
      }
    }

    return res
  })
}



const socialSignInFn = ({ path, params, dispatch }) => {
  checkAppActions(dispatch, params, 'start')

  return callApi(path, 'put', params.body).then(res => {
    if (res.success) {
      cookie.save('token', res.token, { path: '/' })

      dispatch(_SIGN_IN(res.data))

      if (params.body.businesses_id) {
        dispatch(customer.adds(res.customers))
      }

      checkAppActions(dispatch, params, 'success', res)
    } else {
      checkAppActions(dispatch, params, 'error', res)
    }
  })
}

const signInFn = ({ path, params, dispatch }) => {
  checkAppActions(dispatch, params, 'start')

  return callApi(path, 'put', params.body).then(res => {
    if (res.success) {
      cookie.save('token', res.token, { path: '/' })

      dispatch(_SIGN_IN(res.data))

      if (params.body.businesses_id) {
        dispatch(customer.adds(res.customers))
      }

      checkAppActions(dispatch, params, 'success', res)
    } else {
      checkAppActions(dispatch, params, 'error', res)
    }
  })
}

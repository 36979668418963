import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

// Components Global
import Switch from '../../../../../../Inputs/Switch'

// css
import css from './css/sectionManage.css'


const SectionTitle = (props) => {
  const [mode, setMode] = useState()
  const [loggedIn, setLoggedIn] = useState()

  useEffect(() => {
    setMode(props.mode || 'edit')
    setLoggedIn(props.loggedIn !== undefined ? props.loggedIn : false)
  }, [props.mode, props.loggedIn])


  const handleSelectMode = (mode) => {
    setMode(mode)
  }

  const handleChangeLoggedIn = (loggedIn) => {
    setLoggedIn(loggedIn)

    if (props.getLoggedIn) {
      props.getLoggedIn(loggedIn)
    }
  }

  const { editLink, modal, style, width, borderHidden, business } = props
  const boxStyle = {
    marginTop: '100px',
    marginBottom: '100px',
    border: !borderHidden && '2px dashed #039be5',
    borderTop: !borderHidden && '2px dashed #039be5',
    ...style
  }


  return (
    <div style={boxStyle}>

      { !borderHidden &&
        <div style={{ color: '#fff', fontSize: '0.9rem', background: '#039be5', display: 'inline-block', padding: '5px 10px' }}>
          { props.title }
        </div>
      }

      { editLink ?
        <div className="center">
          <Link to={editLink.url} className="btn btn-sm  btn-shadow-none btn-edit pd-right-10">
            <i className="material-icons left">{editLink.icon}</i> {editLink.label}
          </Link>
        </div>
      :
        <div className={css.boxBtn}>

          <div className={css.boxSwitch}>
            <Switch
              notSave
              id="iconHidden"
              value={loggedIn}
              onChange={handleChangeLoggedIn}
              labelOff="ไม่เข้าสู่ระบบ"
              labelOpen="เข้าสู่ระบบ"
              style={{ height: '22px', marginTop: '-12px' }}
            />
          </div>

          <div>
            <a
              onClick={() => handleSelectMode('view')}
              className={`btn btn-sm font-0-9 btn-shadow-none ${css.btnView}  ${mode === 'view' ? 'btn-success color-fff' : 'btn-white color-000'}`}> โหมดแสดง
            </a>
            <a
              onClick={() => handleSelectMode('edit')}
              className={`btn btn-sm font-0-9 btn-shadow-none ${css.btnEdit} ${mode === 'edit' ? 'btn-success color-fff' : 'btn-white color-000'}`}>โหมดแก้ไข
            </a>
          </div>
        </div>
      }

      { editLink ?
        <div style={{ background: '#fff', margin: '10px'}}>
          { React.cloneElement(props.children, { loggedIn }) }
        </div>
      :
        <Fragment>
          { mode === 'view' &&
            <Fragment>
              { modal ?
                <div className="pd-top-15" style={{ width, margin: '0 auto' }}>
                  { React.cloneElement(props.children[1], { loggedIn }) }
                </div>
              :
                <div style={{ margin: '10px'}}>
                  { React.cloneElement(props.children[0], { loggedIn }) }
                </div>
              }
            </Fragment>

          }

          { mode === 'edit' &&
            <Fragment>
              { modal ?
                <div className="pd-top-15" style={{ width, margin: '0 auto' }}>
                  { React.cloneElement(props.children[1], { loggedIn }) }
                </div>
              :
                <div style={{ margin: '10px'}}>
                  { React.cloneElement(props.children[1], { loggedIn }) }
                </div>
              }
            </Fragment>
          }
        </Fragment>
      }
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const { username } = props.match.params
  const businesses = state.businesses.data
  const business = businesses.filter(biz => biz.username === username)[0]

  return {
    business
  }
}

export default withRouter(connect(mapStateToProps)(SectionTitle))

import React from 'react'
import renderRoutes from '../../../../routes/renderRoutes'
import WindowDefault from '../../../../components/Windows/WindowDefault'

const ServicesContainer = (props) => {
  const { route, business, location, windowModal, closeWindowModal } = props

  return (
    <WindowDefault windowModal={windowModal}>
      { renderRoutes({
        routes: route.routes,
        extraProps: { business, windowModal, closeWindowModal },
        location
      }) }
    </WindowDefault>
  )
}

export default ServicesContainer

const FoldersMenu = ({ path, label="", hidden, hiddenTitle }) => {
  const items = [
    {
      name: 'หมวดหมู่',
      title: true,
      hidden
    },
    {
      name: 'หมวดหมู่' + label,
      nameShort: 'หมวดหมู่',
      icon: 'folder',
      link: `${path}/folders`,
      hidden
    }
  ]


  if (hiddenTitle) {
    items.splice(0, 1)
  }


  return items
}

export default FoldersMenu

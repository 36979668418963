import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// Components Global
import { Tooltip } from '../tooltip/Tooltip'
import Switch from '../input/Switch'
import Dropdown from '../Dropdown'

// css
import css from './css/itemManagement.css'

// Redux Actions
import { openConfirm, openModal } from '../../redux/App/AppActions'


const BtnManager = (props) => {

  const handleGoTo = (manager) => {
    const handle = manager.handle

    if (handle.url) {
      props.history.push(handle.url)
    }

    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)

      if (props.setOverflowNull) {
        document.body.style.overflow = null
      }
    }
  }

  const handleOpenModal = (manager) => {
    const handle = manager.handle

    if (manager.onClick) {
      manager.onClick()
    }

    if (handle && handle.modal) {
      props.dispatch(openModal(handle.modal.id, handle.modal.params))
    }


  }

  const handleOpenConfirm = ({ manager, type, icon, labelConfirm, title, msg, lastMsg='' }) => {
    const handle = manager.handle
    const label = labelConfirm || handle.label
    const message = msg || `กดปุ่ม "${label}นี้" ถ้าคุณต้องการ${label}นี้${lastMsg}`
    const confirmLabel = labelConfirm || `${label}นี้`

    title  = title || `${label}นี้${lastMsg}`

    props.dispatch(openConfirm(props.id, {
      title,
      message,
      type,
      icon,
      confirmLabel,
      cancelLabel: 'ยกเลิก',
      onConfirm: handle.onConfirm,
    }))
  }

  const getValueOfType= (manager) => {
    const type = manager.type
    const disabled = manager.disabled

    let btnColor, icon, label, labelConfirm, handle, msg, title

    if (manager.show) {
      if (type === 'link-view' || type === 'modal-view') {
        btnColor = ""
        icon = manager.icon || "visibility"
        label = manager.label || "ดูรายละเอียด"

        if (type === 'link-view') handle = () => handleGoTo(manager)
        else if (type === 'modal-view') handle = () => handleOpenModal(manager)

      } else if (type === 'link-view-folder') {
        btnColor = ""
        icon = manager.icon || "folder"
        label = manager.label || "เปิด"

        if (type === 'link-view-folder') handle = () => handleGoTo(manager)

      } else if (type === 'link-edit' || type === 'modal-edit') {
        btnColor = "btn-edit"
        icon = manager.icon || "edit"
        label = manager.label || "แก้ไข"

        if (type === 'link-edit') handle = () => handleGoTo(manager)
        else if (type === 'modal-edit') handle = () => handleOpenModal(manager)

      } else if (type === 'modal-move') {
        btnColor = "bg-help"
        icon = manager.icon || "zoom_out_map"
        label = manager.label || "ย้าย"

        if (type === 'modal-move') handle = () => handleOpenModal(manager)


      } else if (type === 'trash') {
        btnColor = "btn-delete"
        icon = manager.icon || "delete"
        label = manager.label || "ลบ"
        labelConfirm = manager.labelConfirm
        handle = handle = () => handleOpenConfirm({ manager, type, icon, labelConfirm })

      } else if (type === 'delete') {
        btnColor = "btn-delete"
        icon = manager.icon || "delete"
        label = manager.label || "ลบ"
        labelConfirm = manager.labelConfirm
        msg = manager.msg
        title = manager.title
        handle = handle = () => handleOpenConfirm({ manager, type, icon, labelConfirm, msg, title, lastMsg: ' อย่างถาวร' })

      } else if (type === 'restore' || type === 'success') {
        btnColor = "btn-success btn-restore"
        icon = manager.icon || "restore"
        label = manager.label || "กู้คืน"
        labelConfirm = manager.labelConfirm
        msg = manager.msg
        title = manager.title
        handle = handle = () => handleOpenConfirm({ manager, type, icon, labelConfirm, msg, title })
      }

      if (disabled) {
        btnColor = `${btnColor} disabled`
        handle = undefined
      }
    }

    if (manager.btnColor && !disabled) {
      btnColor = manager.btnColor
    }

    return { btnColor, icon, label, handle }
  }

  const btnRender = (manager, i) => {
    const btnSize = !props.btnClassName && (props.btnSize ? props.btnSize : 'btn-xs')
    const { btnColor, icon, label, disabled, handle } = getValueOfType(manager)
    const btn = manager.btnCircle ? `btn-floating ${btnSize}` : `btn ${btnSize} ${props.btnClassName} width-full text-overflow-ellipsis`
    const width = manager.type === 'modal-move' ? 'width-full' : ''

    return (
      <button title={label} key={i} onClick={handle} className={`${btnColor} ${btn} btn-shadow-none ${width}`}>
        <i className={`material-icons ${props.iconClassName} ${props.iconSize} ${!props.iconOnly && 'left'}`}>
          {icon}
        </i>

        { (!manager.btnCircle && !props.iconOnly) && label }
      </button>
    )
  }

  const handleManager = props.manager.filter(manager => manager.show === true)
  const handleStay = (e) => e.stopPropagation()

  return (
    <div className={`${css.container} ${props.className}`} style={props.style} onClick={handleStay}>
      { props.listTable && handleManager.length > 1 &&
        <table>
          <tbody>
            <tr>
              { handleManager.map((manager, i) => {

                let btnWidth = ''

                if (manager.type === 'modal-move') {
                  btnWidth = 'width-70'
                }

                return (
                  <td key={i} className={`pd-0 pd-left-5 ${props.tdClassName} ${btnWidth}`}>
                    { btnRender(manager) }
                  </td>
                )
              }) }
            </tr>
          </tbody>
        </table>
      }

      { handleManager.length === 1 &&
        <Fragment>
          { handleManager.map((manager, i) => {
            return btnRender(manager, i)
          }) }
        </Fragment>
      }
    </div>
  )
}

export default withRouter(connect()(BtnManager))

import React, { Component } from 'react'


// Components Customers
import CustomersModal from '../CustomersModal'
import CustomerUpdateModal from '../CustomerUpdateModal'
import { CustomerContact } from '../CustomersUserInterface'

// Components Local
import CustomerBox from './components/CustomerBox'
import CustomerCollapsible from './components/CustomerCollapsible'


class CustomerSelected extends Component {
  selectCustomer = (customer) => {
    if (customer) {
      this.props.select(customer)
    }
  }

  updateCustomer = (customer) => {
    if (customer) {
      this.props.select(customer, false)
    }
  }



  render() {
    const { customer, business } = this.props

    return (
      <div>
        <CustomersModal
          {...this.props}
          id="selectCustomer"
          required={this.props.required}
          onSelect={this.selectCustomer}
        />

        { customer &&
          <CustomerUpdateModal
            id={customer._id}
            required={this.props.required}
            onSelect={this.updateCustomer}
            business={business}
            customer={customer} />
        }

        { customer ?
          <div>
            { !this.props.format ?
              <div>
                <CustomerBox {...this.props} />
              </div>
            :
              <div>
                { this.props.format === 'descriptions' &&
                  <div>
                    <div className="border-bottom-eee pd-bottom-10">
                      <CustomerBox {...this.props} />
                    </div>

                    <div className="mg-top-10">
                      <CustomerContact format="descriptions" customer={customer} />
                    </div>
                  </div>
                }

                {/* this.props.format === 'collapsible' &&
                  <CustomerCollapsible
                    {...this.props}
                    deleteCustomer={() => this.deleteCustomer()} />
              */}
              </div>
            }
          </div>
        :
          <div>
            <CustomerBox {...this.props} deleteCustomer={() => this.deleteCustomer() } />
          </div>
        }
      </div>
    )
  }
}

export default CustomerSelected

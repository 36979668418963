
import React, { Fragment, useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import cuid from 'cuid'


let map, maps, marker


const GoogleMapGeoDisplay = (props) => {
  useEffect(() => {
    map = undefined, maps = undefined, marker = undefined
  }, [])

  useEffect(() => {
    if (props.location) {
      placeMarker(setLocationFloat(props.location))
    }

    return () => {

    }
  }, [props.location])


  const setLocationFloat = (location) => {
    if (location) {
      return {
        lat: parseFloat(location.lat),
        lng: parseFloat(location.lng),
      }
    }
  }

  const handleApiLoaded = (apiMap, apiMaps) => {
    map = apiMap
    maps = apiMaps

    placeMarker(setLocationFloat(props.location))
  }

  const placeMarker = (position) => {
    if (map && maps) {
      if (!marker) {
        map.setCenter(position)
        marker = new maps.Marker({
          position,
          map,
          title: 'ที่อยู่ของฉัน'
        })
      } else {
        map.setCenter(position)
        marker.setPosition(position)

        setTimeout(() => {
          marker.setPosition(position)
        }, 100)
      }
    } else {
      console.log('map is undefined')
      console.log(map, maps)
    }
  }

  const getParamsMap = (location) => {
    let params  = ''

    if (location) {
      for (let key in location) {
        params = `${params}${location[key]},`
      }
    }

    return params.slice(0, -1)
  }

  return (
    <Fragment>
      { props.location && props.location.lat && props.location.lng &&
        <div style={{ height: '100%', width: '100%', position: 'relative' }}>
          <div style={{ zIndex: '1' }} className="pst-absolute right-10 top-10 text-right">
            { props.btn.display &&
              <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${getParamsMap(props.location)}`} className="btn btn-white pd-0 center height-40 width-40 line-height-40 border-radius-circle mg-left-10">
                <i className="material-icons font-1-3 color-333">map</i>
              </a>
            }

            { props.btn.navigator &&
              <a target="_blank" href={`https://www.google.com/maps/dir/?api=1&destination=${getParamsMap(props.location)}`} className="btn btn-white pd-0 center height-40 width-40 line-height-40 border-radius-circle mg-left-10">
                <i className="material-icons font-1-3 color-333">drive_eta</i>
              </a>
            }

            { props.btn.edit &&
              <a onClick={props.btn.edit} className="btn btn-white pd-0 center height-40 width-40 line-height-40 border-radius-circle mg-left-10">
                <i className="material-icons font-1-3 color-333">edit</i>
              </a>
            }
          </div>

          <GoogleMapReact
            options={{ draggableCursor: 'default', disableDefaultUI: true, scrollwheel: false }}
            bootstrapURLKeys={{ key: 'AIzaSyAhpxtIOthrW2SViWcGpO0UWSR8sDZbWpI', language: 'th' }}
            center={setLocationFloat(props.location)}
            defaultZoom={props.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          />
        </div>
      }
    </Fragment>
  )
}

GoogleMapGeoDisplay.defaultProps = {
  zoom: 11,
  btn: {
    edit: false,
    display: false,
    navigator: false
  }
}

export default GoogleMapGeoDisplay


import React, { Fragment, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import ReactDOMServer from 'react-dom/server'
import { closeModal } from '../../redux/App/AppActions'


const InfoWindowContent = (props) => {
  return (
    <div className="width-200 pd-10">
      { props.loading ?
        <div className="center pd-10">
          กำลังโหลด...
        </div>
      :
        <Fragment>
          { props.error ?
            <div className="center pd-10 color-error">
              <div>ไม่สามารถเลือกพื้นที่นี้ได้</div>

              { props.msg &&
                <div className="pd-top-5">({props.msg})</div>
              }
            </div>
          :
            <div>
              <div className="pd-bottom-10">
                { props.address }
              </div>

              <a id="btnSelectMap" className="btn height-25 line-height-25 font-0-8 width-full">เลือกตำแหน่งนี้</a>
            </div>
          }
        </Fragment>
      }
    </div>
  )
}


let map, maps, marker, infoWindow, geocoder, geo


const GoogleMapGeo = (props) => {
  useEffect(() => {
    /*return () => {
      map = undefined, maps = undefined, marker = undefined, infoWindow = undefined, geocoder = undefined, geo = undefined
    }*/

    map = undefined, maps = undefined, marker = undefined, infoWindow = undefined, geocoder = undefined, geo = undefined
  }, [])

  const handleApiLoaded = (apiMap, apiMaps) => {
    map = apiMap
    maps = apiMaps
    geocoder = new maps.Geocoder()
    infoWindow = new maps.InfoWindow()

    if (props.location && props.location.lat && props.location.lng) {
      placeMarker(setLocationFloat(props.location))
    } else {
      getCurrentLocation()
    }

    maps.event.addListener(map, 'click', (e) => {
      const position = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      }

      placeMarker(position)
      getGeocoder(position)
    })
  }

  const placeMarker = (position) => {
    if (maps && maps) {
      if (!marker) {
        marker = new maps.Marker({
          position,
          map,
          title: 'ที่อยู่ของฉัน'
        })
      } else {
        marker.setPosition(position)
      }

      getGeocoder(position)

      marker.addListener("click", () => {
        infoWindow.open(map, marker)
      })
    } else {
      console.log('map is undefined')
      alert('ไม่เจอแผนที่')
    }
  }

  const setLocationFloat = (location) => {
    if (location) {
      return {
        lat: parseFloat(location.lat),
        lng: parseFloat(location.lng),
      }
    }
  }

  const setNewAddresses = (addresses) => {
    const paths = [
      { key: 'district', types: ['locality', 'sublocality'] },
      { key: 'amphoe', types: ['administrative_area_level_2', 'sublocality_level_1'] },
      { key: 'province', types: ['administrative_area_level_1'] },
      { key: 'zipcode', types: ['postal_code'] },
      { key: 'country', types: ['country'] },
    ]

    addresses.map(address => {
      paths.map(path => {
        path.types.map(pathType => {
          if (!address.type) {
            const have = address.types.filter(type => type === pathType)[0]

            if (have) {
              address.type = path.key
            }
          }
        })
      })
    })
  }

  const convertGeo = (geo) => {
    if (geo) {
      const addresses = geo.address_components
      setNewAddresses(addresses)

      let zipcode = addresses.filter(d => d.type === 'zipcode')[0]
      let country = addresses.filter(d => d.type === 'country')[0]
      let countryCode = country && country.short_name
      let province = addresses.filter(d => d.type === 'province')[0]
      let amphoe = addresses.filter(d => d.type === 'amphoe')[0]
      let district = addresses.filter(d => d.type === 'district')[0]


      district = district && district.long_name && district.long_name.replace('ตำบล', '').replace('แขวง', '').replaceAll(' ', '')
      amphoe = amphoe && amphoe.long_name && amphoe.long_name.replace('อำเภอ', '').replace('เขต', '').replaceAll(' ', '')
      province = province && province.long_name
      country = country && country.long_name && country.long_name.replace('ประเทศ', '')
      zipcode = zipcode && zipcode.long_name

      const address = {
        district,
        amphoe,
        province,
        zipcode,
        country,
        countryCode
      }

      /*console.log(addresses)
      console.log(address)*/

      return address
    }
  }

  const checkScope = (address) => {
    let status

    if (address) {
      let requiredDatas = [...props.requiredDatas]

      // check required
      requiredDatas.map((data, i) => {
        if (address[data]) requiredDatas[i] = 'pass'
        else requiredDatas[i] = 'error'
      })

      // check required
      if (requiredDatas.filter(data => data === 'error').length === 0) {

        // use scope
        if (props.scope) {
          const scopeElements = [...props.scope.elements]

          // check scopes
          scopeElements.map((element, i) => {
            const scopeValues = props.scope[`${element}Values`]
            const value = scopeValues.filter(value => value === address[`${element}`])[0]

            if (value) scopeElements[i] = 'pass'
            else scopeElements[i] = 'error'
          })

          // check error
          if (scopeElements.filter(data => data === 'error').length === 0) {
            status = true
          }

        } else {
          status = true
        }
      }
    }

    return status
  }

  const selectMap = () => {
    if (geo.address && geo.location) {
      props.onChange(geo, geo.location)
      closeGeoModal()
    }
  }

  const getGeocoder = (location) => {
    infoWindow.open(map, marker)
    infoWindow.setContent(ReactDOMServer.renderToString(<InfoWindowContent loading />))

    geocoder.geocode({ location }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          geo = results[0]
          const address = convertGeo(results[0])

          console.log(geo.formatted_address)

          //console.log(geo)

          if (checkScope(address)) {
            geo.address = address
            geo.location = location
            const addressFull = geo.formatted_address
            infoWindow.setContent(ReactDOMServer.renderToString(<InfoWindowContent address={addressFull} />))

          } else {
            geo = undefined
            infoWindow.setContent(ReactDOMServer.renderToString(<InfoWindowContent error msg="ไม่อยู่ในพื้นที่ให้บริการ" />))
          }

          setTimeout(() => {
            const btnSelectMap = document.getElementById("btnSelectMap")

            if (btnSelectMap) {
              btnSelectMap.addEventListener("click", () => {
                selectMap()
              })
            }
          }, 100)

        } else {
          geo = undefined
          infoWindow.setContent(ReactDOMServer.renderToString(<InfoWindowContent error msg="ไม่พบผลลัพธ์" />))
          console.log("No results found")
        }
      } else {
        geo = undefined
        infoWindow.setContent(ReactDOMServer.renderToString(<InfoWindowContent error msg={status} />))
        console.log("Geocoder failed due to: " + status)
      }
    })
  }

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pst) => {
        const pos = {
          lat: pst.coords.latitude,
          lng: pst.coords.longitude,
        }

        map.setCenter(pos)
        placeMarker(pos)

      }, (err) => {
        console.log(err)

        setTimeout(() => {
          if(err.code == 1) {
            alert("ผู้ใช้ปฏิเสธการระบุตำแหน่งทางภูมิศาสตร์")
          } else if( err.code == 2) {
            alert("ไม่เจอตำแหน่งปัจจุบันของคุณในแผนที่")
          }
        }, 1000)
      }, {
        enableHighAccuracy: true,
        timeout: 6000,
        maximumAge: 0
      })
    } else {
      alert('เบราว์เซอร์นี้ไม่รองรับ')
    }
  }

  const closeGeoModal = () => {
    props.dispatch(closeModal(props.modalId))
  }

  return (
    <div style={{ height: '100%', width: '100%' }} >
      <div className="pst-fixed top-0 left-0 right-0 pd-10" style={{ zIndex: '1' }}>
        <table>
          <tbody>
            <tr>
              <td className="pd-0 width-50">
                <a onClick={getCurrentLocation} className="btn pd-0 tect-center btn-white height-40 width-40 line-height-40 border-radius-circle">
                  <i className="material-icons font-1-8 color-333">my_location</i>
                </a>
              </td>
              <td className="pd-0 center font-1-2">
                <div className="pd-5 box-shadow-1 border-radius-30 bg-fff pd-left-15 pd-right-15 dp-inline-block">เลือกตำแหน่งที่ตั้ง</div>
              </td>
              <td className="pd-0 width-50 text-right">
                <a onClick={closeGeoModal} className="btn pd-0 tect-center btn-white height-40 width-40 line-height-40 border-radius-circle">
                  <i className="material-icons font-1-8 color-333">close</i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <GoogleMapReact
        options={{
          draggableCursor: 'default', /*disableDefaultUI: true,*/
          fullscreenControl: false,
          gestureHandling: 'greedy'
        }}
        bootstrapURLKeys={{ key: 'AIzaSyAhpxtIOthrW2SViWcGpO0UWSR8sDZbWpI', language: 'th' }}
        defaultCenter={props.location && props.location.lat && props.location.lng ? setLocationFloat(props.location) : props.center}
        //center={props.location || props.center}
        defaultZoom={props.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      />
    </div>
  )
}

GoogleMapGeo.defaultProps = {
  center: { lat: 15.870032, lng: 100.992538 },
  zoom: 17,
  requiredDatas: [/*'district', *//*'amphoe'*/, 'province', /*'zipcode',*/ 'countryCode'],
  /*scope: {
    elements: ['countryCode', 'province'],
    countryCodeValues: ['TH'],
    provinceValues: ['สตูล']
  }*/
}

export default GoogleMapGeo

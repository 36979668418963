// Redux Actions
import {
  updatePlace,
  removePlace,
  uploadImagesPlace
} from './PlacesActions'

const title = "สถานที่"

// Main
const handleChangeStatus = (props, status, toastMsg) => {
  return (id) => {
    props.dispatch(updatePlace(props.business._id, id, {
      body: { status: status },
      toastMsg: toastMsg,
      loadId: id,
      confirmId: id
    }))
  }
}

const handleDelete = (props, toastMsg) => {
  return (id) => {
    props.dispatch(removePlace(props.business._id, id, {
      toastMsg: toastMsg,
      loadId: id,
      confirmId: id
    }))
  }
}

export const onEditModal = (id) => {
  return {
    modal: { id }
  }
}

export const onEdit = (url) => {
  return { url }
}

export const onView = (url) => {
  return { url }
}

export const onTrash = (props, id) => {
  return {
    label: `ลบ${title}`,
    onConfirm: handleChangeStatus(props, 'trash', `ลบ${title}นี้แล้ว`)
  }
}

export const onDelete = (props) => {
  return {
    label: `ลบ${title}`,
    onConfirm: handleDelete(props, `ลบ${title}นี้ อย่างถาวรแล้ว`)
  }
}

export const onRestore = (props) => {
  return {
    label: `กู้คืน${title}`,
    onConfirm: handleChangeStatus(props, 'open', `กู้คืน${title}แล้ว`)
  }
}

export const onSwitchOpen = (props) => {
  return {
    label: `เปิดการใช้งาน${title}นี้`,
    onConfirm: handleChangeStatus(props, 'open', `เปิดการใช้งาน${title}นี้แล้ว`)
  }
}

export const onSwitchClose = (props) => {
  return {
    label: `ปิดการใช้งาน${title}นี้`,
    onConfirm: handleChangeStatus(props, 'close', `ปิดการใช้งาน${title}นี้แล้ว`)
  }
}


// Images Main
export const uploadImage = (props) => {
  return (id, files, album) => {
    if (files) {
      props.dispatch(uploadImagesPlace(props.business._id, props.place._id, files, 'places', {
        body: {
          updateType: 'push',
          updateElement: 'images',
          album: album
        },
        loadId: id,
        modalId: id,
        progressId: id
      }))
    }
  }
}

export const onDeleteImage = (props) => {
  return (id) => {
    props.dispatch(updatePlace(props.business._id, props.place._id, {
      body: {
        updateType: 'pull-image',
        updateElement: 'images',
        deleteId: id
      },
      toastMsg: `ลบภาพนี้แล้ว`,
      loadId: id,
      confirmId: id,
      props: props
    }))
  }
}


export const onDeleteItemImage = (props) => {
  return (id) => {
    props.dispatch(updatePlace(props.business._id, props.place._id, {
      body: {
        updateType: 'pull-sub-image',
        updateElement: 'items',
        updateElementSub: 'items.images',
        updateElementSubPull: 'items.$.images',
        updateId: props.item._id,
        deleteId: id
      },
      toastMsg: `ลบภาพนี้แล้ว`,
      loadId: id,
      confirmId: id,
      props: props
    }))
  }
}


import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../components/Modals/Modal'
import { Row, Column } from '../../../../../components/Columns'
import Section from '../../../../../components/Section'
import EditData from '../../../../../components/EditData'
import DatasList from '../../../../../components/DatasList'

// Components Sites
import { TemplateName, TemplateWidth, TemplateType } from '../SitesForms'

// Actions Redux
import { updateSite } from '../../../../../redux/Sites/SitesActions'


class TemplateUpdateModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleSubmit = (params, formName) => {
    const { business, site, template } = this.props

    if (params) {
      params.updateType = 'set'
      params.updateElement = 'templates'
      params.updateId = template._id

      this.props.dispatch(updateSite(business._id, site._id, {
        body: params,
        loadId: formName,
        fieldId: formName,
        props: this.props,
        toastMsg: 'แก้ไขเทมเพลตแล้ว'
      }))
    }
  }

  render() {
    const lang = this.props.lang || 'local'
    const { template } = this.props
    const id = `${template._id}-templateUpdateModal`

    return (
      <Modal
        id={id}
        headerTitle="แก้ไขเทมเพลต"
        blockBgModal
        padding="30px 0px 0px 0px"
        width="650px">

        <Row className="pd-0">
          <Section title="ข้อมูลเทมเพลต">
            <EditData
              name="name"
              submit={this.handleSubmit}>

              <div>
                <DatasList
                  style={{ border: '0' }}
                  items={[
                    {
                      labelTop: true,
                      label: 'ชื่อเทมเพลต',
                      value: template.name[lang],
                      icon: 'account_box'
                    },
                    {
                      labelTop: true,
                      label: 'ประเภทเทมเพลต',
                      value: template.type || '-',
                      icon: 'account_box'
                    },
                    {
                      labelTop: true,
                      label: 'ความกว้างเทมเพลต',
                      value: template.width || '-',
                      icon: 'account_box'
                    }
                  ]}
                />
              </div>

              <div className="pd-top-15">
                <Row>
                  <Column col="column col-xs-12">
                    <TemplateName value={template && template.name} />
                    <TemplateType value={template && template.type} />
                    <TemplateWidth value={template && template.width} />
                  </Column>
                </Row>
              </div>
            </EditData>
          </Section>
        </Row>
      </Modal>
    )
  }
}

export default connect()(TemplateUpdateModal)

import React from 'react'

// Components Local
import InputNumeral from '../../../../../../../components/Inputs/InputNumeral'
import Select from '../../../../../../../components/Inputs/Select'
import TextInput from '../../../../../../../components/Inputs/TextInput'


export const getDisabledClassName = (disabled, align='text-right') => {
  let inputClassName, labelClassName, inputStyle

  if (disabled) {
    inputClassName = `color-000 bg-eee height-30 font-0-8 line-height-30 ${align} pd-right-10`
    labelClassName = "color-000 mg-bottom-4 font-0-8"
    inputStyle ={
      border: '1px dotted rgba(0, 0, 0, 0.26)',
      boxSizing: 'border-box'
    }

  } else {
    inputClassName="text-center height-35 line-height-35 font-0-8"
    labelClassName="font-0-8"
  }

  return { inputClassName, labelClassName, inputStyle }
}


export const PriceType = (props) => {
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(props.disabled, 'text-center')
  const options = [
    { name: 'จน.เงิน', value: 'amount' },
    { name: 'ราคา/กก.', value: 'per-unit' }
  ]

  let value = props.value || 'amount'

  if (props.disabled) {
    value = options.filter(v => v.value === value)[0]
    value = value && value.name
  }

  return (
    <Select
      id={`pricePerUnit`}
      label="การหัก"
      inputClassName={`${inputClassName}`}
      labelClassName={labelClassName}
      inputStyle={inputStyle}
      onChange={props.onChange}
      value={value}
      disabled={props.disabled}
      required={props.required}
      options={options}
      notSave
    />
  )
}

export const PricePerUnit = (props) => {
  const { digit } = props.type.buyPriceDCM
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(props.disabled)

  return (
    <InputNumeral
      id={`pricePerUnit`}
      label="หัก ราคา/กก."
      placeholder="หัก ราคา/กก."
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      inputStyle={inputStyle}
      onChange={props.onChange}
      value={props.value}
      disabled={props.disabled}
      required={props.required}
      decimal={2}
      notSave
    />
  )
}

export const Price = (props) => {
  const { digit } = props.type.buyPriceDCM
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(props.disabled)

  return (
    <InputNumeral
      id={`price`}
      label="จำนวนเงินที่หัก"
      placeholder="จำนวนเงินที่หัก"
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      inputStyle={inputStyle}
      onChange={props.onChange}
      value={props.value}
      disabled={props.disabled}
      required={props.required}
      decimal={digit}
      notSave
    />
  )
}

export const Title = (props) => {
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(props.disabled)

  return (
    <TextInput
      id="title"
      label="รายการหัก"
      placeholder="รายการหัก"
      inputClassName={`font-0-8 text-left ${inputClassName} pd-left-10`}
      labelClassName={labelClassName}
      inputStyle={{...inputStyle, textAlign: 'left' }}
      value={props.value}
      disabled={props.disabled}
      onChange={props.onChange}
      required={props.required}
      notSave
    />
  )
}

export const TotalDeductCosts = (props) => {
  const { digit } = props.type.buyPriceDCM
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(true)

  return (
    <InputNumeral
      id={`totalDeductCosts`}
      label="จำนวนเงินรวมที่หัก"
      inputClassName={`text-right ${inputClassName}`}
      labelClassName={`${labelClassName}`}
      inputStyle={inputStyle}
      disabled
      decimal={digit}
      value={props.value || 0}
      valueForSplace="0"
      notSave
    />
  )
}

export const TotalBeforeDeductCosts = (props) => {
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(true)

  return (
    <InputNumeral
      id={`totalBeforeDeductCosts`}
      label="จำนวนเงินรวมก่อนหัก"
      inputClassName={`text-right ${inputClassName}`}
      labelClassName={`${labelClassName}`}
      inputStyle={inputStyle}
      disabled
      decimal={2}
      value={props.value || 0}
      valueForSplace="0"
      notSave
    />
  )
}

import { fetchDatas, fetchData, createData, updateData, removeData, fetchApi } from 'core/redux/reduxActions'
import { fetchDatasMany, addDataOne } from 'core/redux/reduxActionsV2'
import { generateTypeName, generateTypeFunctions } from 'core/redux/reduxTypesV2'

import uploadsForS3 from 'core/utils/uploadsForS3'
import { checkAppActions } from 'core/redux/App/AppActions'


// Export Constants
const NAME_TYPE = ['CONVERSATIONS', 'CONVERSATION']
const name = "conversations"

// Type Names and Type Functions
export const {
  ADDS, ADDS_MORE, ADD, UPDATE, REMOVE,
  ADD_ONE, ADDS_MANY, ADDS_MANY_MORE,
} = generateTypeName(NAME_TYPE)

export const {
  adds, adds_more, add, update, remove,
  add_one, adds_many, adds_many_more,
} = generateTypeFunctions(NAME_TYPE)



// Export Actions
export const countConversations = (query, params = {}) => dispatch => {
  const { authName, dbNo, businesses_id } = query

  return fetchApi({
    dispatch,
    params,
    api: `${name}-count/${authName}/${dbNo}/${businesses_id}`,
  })
}

export const checkConversationDuplicate = (query, params = {}) => dispatch => {
  const { businesses_id } = query

  return fetchApi({
    dispatch,
    params,
    api: `${name}-query/${businesses_id}/check-value-duplicate?${params.query}`
  })
}


export const fetchQueryConversationOne = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return fetchData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}`,
    add: add
  })
}

export const fetchQueryConversationsMany = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return fetchDatasMany({
    dispatch,
    params,
    api: `${name}-many/${authName}/${businesses_id}`,
    adds_many,
    adds_many_more
  })
}

export const createQueryConversationOne = (query, params) => dispatch => {
  const { authName, businesses_id } = query

  return addDataOne({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/create`,
    add_one
  })
}

export const updateQueryConversationOne = (query, params) => dispatch => {
  const { authName, businesses_id, conversations_id, _id } = query

  return updateData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/${_id || conversations_id}`,
    update: update,
    updateId: _id || conversations_id
  })
}

export const uploadImagesConversation = (query, fileParams, params) => dispatch => {
  const { files, imagePrefix } = fileParams
  const { businesses_id } = query

  checkAppActions(dispatch, params, 'start')
  uploadsForS3({
    files,
    prefix: imagePrefix,
    progressId: params.progressId,
    dispatch,
    businesses_id
  }).then(val => {
    val.map(paths => {
      params.body.paths = paths
      dispatch(updateQueryConversationOne(query, params))
    })
  })
}


export const removeConversation = (query, params) => dispatch => {
  const { authName, businesses_id, conversations_id, _id } = query

  return removeData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/${_id || conversations_id}`,
    remove: remove,
    deleteId: _id || conversations_id
  })
}






import React from 'react'
import renderRoutes from '../../../../routes/renderRoutes'

// Components Global
import Window from '../../../../components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../components/MenuSidebarResponsive'

// imgs
import titleIcon from '../resources/imgs/component-title-icon.png'


const menuItems = (params) => {
  return [
    {
      name: 'ข้อมูลกิจการ',
      title: true
    },
    {
      name: 'ข้อมูลทั่วไป',
      icon: 'perm_data_setting',
      link: `/${params.username}/settings`,
      exact: true,
    },
    {
      name: 'ข้อมูลบริษัท',
      icon: 'business',
      link: `/${params.username}/settings/company`,
      exact: true,
    },
    {
      name: 'ข้อมูลภาษี',
      icon: 'receipt',
      link: `/${params.username}/settings/tax`
    },
    {
      name: 'ภาพโปรไฟล์',
      icon: 'photo_size_select_large',
      link: `/${params.username}/settings/pictures`
    },

    {
      name: 'ตั้งค่าการแจ้งเตือน',
      title: true
    },

    {
      name: 'อีเมล',
      icon: 'email',
      link: `/${params.username}/settings/messaging/email`,
      exact: true,
    },
    {
      name: 'ไลน์แจ้งเตือน',
      iconFa: 'fab fa-line',
      link: `/${params.username}/settings/messaging/line-notify`,
      exact: true,
    },
    {
      name: 'เฟซบุ๊คแชท',
      iconFa: 'fab fa-facebook-messenger',
      link: `/${params.username}/settings/messaging/facebook`,
      exact: true,
    },


    {
      name: 'ตั้งค่าการจัดส่ง',
      title: true
    },
    {
      name: 'การจัดส่ง',
      icon: 'local_shipping',
      link: `/${params.username}/settings/shipping-fees`
    },

    {
      name: 'งานเอกสาร',
      title: true
    },
    {
      name: 'การรันเลขเอกสาร',
      icon: 'filter_9_plus',
      link: `/${params.username}/settings/running-numbers`
    },
    {
      name: 'โลโก้บนเอกสาร',
      icon: 'center_focus_weak',
      link: `/${params.username}/settings/logo`
    },
    {
      name: 'ตรายางบริษัท',
      icon: 'select_all',
      link: `/${params.username}/settings/stamp`
    },
    {
      name: 'ลายเซ็นอิเล็กทรอนิกส์',
      icon: 'border_color',
      link: `/${params.username}/settings/electronic-signature`
    },

    {
      name: 'ตั้งค่าบิล',
      title: true
    },
    {
      name: 'บิลแบบย่อ',
      icon: 'description',
      link: `/${params.username}/settings/bill-short`
    },
    {
      name: 'บิลแบบเต็มรูปแบบ',
      icon: 'chrome_reader_mode',
      link: `/${params.username}/settings/bill-full`
    },

    {
      name: 'ตั้งค่าระบบ',
      title: true
    },
    {
      name: 'สินค้า',
      icon: 'widgets',
      link: `/${params.username}/settings/system/products`
    },
    {
      name: 'หมวดหมู่',
      icon: 'folder',
      link: `/${params.username}/settings/system/folders`
    },
    {
      name: 'บทความ',
      icon: 'article',
      link: `/${params.username}/settings/system/articles`
    },
    {
      name: 'ลูกค้า',
      icon: 'account_circle',
      link: `/${params.username}/settings/system/customers`
    },
    {
      name: 'คุณลักษณะ',
      icon: 'local_offer',
      link: `/${params.username}/settings/system/attributes`
    },
    {
      name: 'การสั่งซื้อ',
      icon: 'shopping_cart',
      link: `/${params.username}/settings/system/orders`
    },
    {
      name: 'บิล',
      icon: 'receipt',
      link: `/${params.username}/settings/system/bills`
    },

    {
      name: 'เทลมเพลตการแจ้งเตือน',
      title: true
    },
    {
      name: 'อีเมล',
      nameShort: 'อีเมล',
      icon: 'email',
      link: `/${params.username}/settings/notifications/email`
    },
    {
      name: 'ไลน์แจ้งเตือน',
      nameShort: 'ไลน์แจ้งเตือน',
      iconFa: 'fab fa-line',
      link: `/${params.username}/settings/notifications/line-notify`
    },

    {
      name: 'กลุ่ม',
      title: true
    },
    {
      name: 'กลุ่มลูกค้า',
      nameShort: 'กลุ่มลูกค้า',
      icon: 'group_add',
      link: `/${params.username}/settings/groups/customer`
    },

    {
      name: 'ตั้งค่าระบบ',
      title: true
    },
    {
      name: 'ระบบจัดการ',
      icon: 'settings_applications',
      link: `/${params.username}/settings/systems`
    },
    {
      name: 'เพิ่มเติม',
      icon: 'settings_applications',
      link: `/${params.username}/common-settings`
    },
  ]
}

const SettingsMenuContainer = (props) => {
  const { business, setting, location, route, match, windowModal, closeWindowModal, history } = props
  const params = match.params

  return (
    <Window
      title="จัดการข้อมูลกิจการ"
      titleIcon={titleIcon}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkClose={`/${params.username}`}>

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive items={menuItems(params)} />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, setting },
            location
          }) }
        </Layouts2>

      </LayoutsTwo>
    </Window>
  )
}

export default SettingsMenuContainer

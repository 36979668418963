import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../components/Modals/Modal'
import { Row, Column } from '../../../../../components/Columns'

// Components forms
import Form from '../../../../../components/Form'
import BtnSubmit from '../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../util/getFormValue'

// Components Services
import { TemplateName, TemplateWidth, TemplateType } from '../SitesForms'

// Actions Redux
import { updateSite } from '../../../../../redux/Sites/SitesActions'


class TemplateCreateModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(this.refs[formName])
    const { business, site } = this.props

    if (params) {
      params.updateType = 'push'
      params.updateElement = 'templates'

      this.props.dispatch(updateSite(business._id, site._id, {
        body: params,
        loadId: formName,
        modalId: formName,
        props: this.props,
        toastMsg: 'เพิ่มเทมเพลตแล้ว'
      }))
    }
  }


  render() {
    const id = `templateCreateModal`

    return (
      <Modal
        id={id}
        headerTitle="เพิ่มเทมเพลต"
        headerLeft
        blockBgModal
        width="450px">

        <Row className="pd-20 pd-top-30">
          <Form
            recheckForm
            ref={id}
            name={id}
            submit={(e) => this.handleSubmit(e, id)}>

            <Column col="column col-xs-12">
              <TemplateName label="ชื่อเทมเพลต" />
              <TemplateType />
              <TemplateWidth />
            </Column>

            <Column col="column col-xs-12 text-right">
              <BtnSubmit
                loadingId={id}
                icon="send"
                iconAlign="left"
                text="เพิ่มเทมเพลต"
                className="waves-effect waves-light btn btn-submit btn-shadow-none"
                btnDisabled
              />
            </Column>
          </Form>
        </Row>
      </Modal>
    )
  }
}

export default connect()(TemplateCreateModal)

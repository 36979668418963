import React, { Component } from 'react'
import { connect } from 'react-redux'
import cuid from 'cuid'
import renderRoutes from '../../routes/renderRoutes'


// Components Local
import App from '../App'

// Containers
//import AppAuthContainer from '../../containers/auth/AppAuthContainer'
//import AppDashboardContainer from '../../containers/dashboard/AppDashboardContainer'
//import AppDocumentsContainer from '../../containers/documents/AppDocumentsContainer'
//import AppWebsitesContainer from '../../containers/websites/AppWebsitesContainer'

// Redux Actions
//import { getAuth } from '../../redux/Auth/AuthActions'


const APP_PROJECT = process.env.APP_PROJECT

let Container

if (APP_PROJECT === 'auth') {
  Container = require('../../containers/auth/AppAuthContainer').default

} else if (APP_PROJECT === 'websites') {
  Container = require('../../containers/websites/AppWebsitesContainer').default

} else if (APP_PROJECT === 'docs') {
  Container = require('../../containers/documents/AppDocumentsContainer').default

} else if (APP_PROJECT === 'dashboard' || process.env.APP_PROJECT === 'store') {
  Container = require('../../containers/dashboard/AppDashboardContainer').default
}


class AppContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const users_id = localStorage.getItem('users_id')

    if (!users_id) {
      const randomUserId = cuid()

      localStorage.setItem('users_id', randomUserId)
    }
  }

  render() {
    const pathname = this.props.location.pathname
    const searchDocs = pathname.search('/docs/') === 0 ? true : false
    const searchAuth = pathname.search('/auth/') === 0 ? true : false
    const searchWebsites = pathname.search('/websites/') === 0 ? true : false

    return (
      <App>
        <Container {...this.props}>
          { renderRoutes({
            routes: this.props.route.routes,
            location: this.props.location,
          }) }
        </Container>

        {/* searchAuth &&
          <AppAuthContainer {...this.props}>
            { renderRoutes({
              routes: this.props.route.routes,
              location: this.props.location,
            }) }
          </AppAuthContainer>
          */}

        {/* searchDocs &&
          <AppDocumentsContainer {...this.props}>
            { renderRoutes({
              routes: this.props.route.routes,
              location: this.props.location,
            }) }
          </AppDocumentsContainer>
          */}

        {/* searchWebsites &&
          <AppWebsitesContainer {...this.props}>
            { renderRoutes({
              routes: this.props.route.routes,
              location: this.props.location,
            }) }
          </AppWebsitesContainer>
        */}

        {/* !searchAuth && !searchDocs && !searchWebsites &&
          <AppDashboardContainer>
            { renderRoutes({
              routes: this.props.route.routes,
              location: this.props.location,
            }) }
          </AppDashboardContainer>
        */}
      </App>
    )
  }
}

/*AppContainer.fetchData = (store) => {
  return store.dispatch(getAuth())
}*/

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(AppContainer)

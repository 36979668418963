import React, { Fragment } from 'react'

export const InputKeyboard = (props) => {
  const labelClassName = props.labelClassName || `width-60 pd-left-10 pd-right-10 font-0-8 center`
  const iconClassName = props.iconClassName || `font-1-8`
  const inputClassName = props.inputClassName || 'center font-0-9'

  return (
    <InputKeyDefault {...props}>
      <div className={labelClassName}>
        { props.label && props.label }

        { props.iconFa &&
          <i className={`${props.iconFa} ${iconClassName} i-middle`}></i>
        }
      </div>


      <div className={inputClassName}>
        { (props.value || props.value === 0) ? props.value :
          <Fragment>{props.placeholder && <span className="color-c0c0c0">{props.placeholder}</span>}</Fragment>
        }
      </div>

      { props.unit &&
        <div className="width-40">{props.unit}</div>
      }
    </InputKeyDefault>
  )
}


export const InputKeyDefault = (props) => {
  const focusColor = '#74c0fc'
  const focusBoxShadow = '0px 0px 3px 0px #74c0fc'

  return (
    <div
      onClick={props.onClick}
      style={{
        borderColor: props.focus ? focusColor : '#ddd',
        boxShadow: props.focus && focusBoxShadow
      }}
      className={`${props.className || 'height-40'} ${props.focus ? 'border-1' : 'border-1' } border-radius-5`}>

      <div
        style={{ display: 'flex', flexDirection: 'row' }}
        className={`height-full`}>

        { !props.hiddleLabel &&
          <div
            style={{
              display: 'flex', alignItems: 'center', justifyContent: 'center',
              borderColor: props.focus ? focusColor : '#ddd'
            }}
            className={`height-full border-right-1`}>

            { props.children[0] }
          </div>
        }

        <div
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          className={`width-full height-full`}>
          { props.children[1] }
        </div>

        { props.children[2] &&
          <div
            style={{ borderColor: props.focus ? focusColor : '#ddd', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            className={`height-full border-left-1 center`}>

            { props.children[2] }
          </div>
        }
      </div>
    </div>
  )

  /*return (
    <div
      onClick={props.onClick}
      style={{ borderColor: props.focus ? focusColor : '#ddd' }}
      className={`height-${height} line-height-${height} ${props.focus ? 'border-2' : 'border-1' } border-radius-5`}>

      <div
        style={{ display: 'flex', flexDirection: 'row' }}
        className={`height-${height} ${props.focus ? 'mg-top--2' : 'mg-top--1' }`}>

        { !props.hiddleLabel &&
          <div
            style={{ borderColor: props.focus ? focusColor : '#ddd' }}
            className={`height-${height} border-right-1`}>

            { props.children[0] }
          </div>
        }

        <div className={`width-full height-${height}`}>
          { props.children[1] }
        </div>

        { props.children[2] &&
          <div
            style={{ borderColor: props.focus ? focusColor : '#ddd' }}
            className={`height-${height} border-left-1 center`}>

            { props.children[2] }
          </div>
        }
      </div>
    </div>
  )*/
}

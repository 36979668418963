import React from 'react'
import { connect } from 'react-redux'

// Components Global
import { BtnModalOpen } from '../../../../../../components/Modals/BtnModal'
import Loading from '../../../../../../components/Loading'
import IconLoading from '../../../../../../components/IconLoading/'

// Components Customers
import { CustomerProfileAvater } from '../../CustomersUserInterface'

// Values
import { customerValue } from '../../../../../../redux/Customers/CustomersValues'

// Redux Actions
import { openConfirm } from '../../../../../../redux/App/AppActions'

// css
import css from './css/customer.css'



const CustomerPreviewManager = (props) => {
  const { customer } = props
  const { nameLang } = customerValue({ customer })

  const deleteCustomer = () => {
    props.dispatch(openConfirm('deleteCustomer', {
      title: `ลบรายชื่อลูกค้านี้`,
      message: `กดปุ่ม "ลบรายชื่อลูกค้า" ถ้าคุณต้องการลบรายชื่อลูกค้านี้`,
      icon: 'account_circle',
      type: 'error',
      confirmIcon: 'delete_forever',
      confirmLabel: "ลบรายชื่อลูกค้า",
      cancelLabel: 'ยกเลิก',
      onConfirm: () => props.delete()
    }))
  }

  let tdWidth = "55", profileWidth = "45px", loadingWidth="30px", fontSize="font-1-1"

  if (props.format === 'descriptions') {
    tdWidth = "45", profileWidth = "35px", loadingWidth="25px", fontSize="font-0-8"
  }

  return (
    <div className={css.customer}>
      <table>
        <tbody>
          <tr>
            <td className="pd-0" width={tdWidth}>
              <div style={{ width: profileWidth, height: profileWidth }}>
                <Loading id={props.id}>
                  <CustomerProfileAvater customer={customer} fontClassName={fontSize} />
                  <IconLoading className="pd-top-7 mg-left-7" width={loadingWidth} height={loadingWidth} />
                </Loading>
              </div>
            </td>
            <td className="pd-0">
              <div className={css.contact}>
                <div className="f5 f-title">
                  { nameLang }
                </div>

                { props.format === 'descriptions' &&
                  <div className="f6 mg-top-5">ชื่อลูกค้า</div>
                }
              </div>
            </td>

            <td className="pd-0 text-align-right" width="120">
              <BtnModalOpen id="selectCustomer">
                <a className="btn-floating btn-xs waves-effect waves-light btn-shadow-none mg-right-5">
                  <i className="material-icons i-middle">add</i>
                </a>
              </BtnModalOpen>

              <BtnModalOpen id={customer._id}>
                <button className="btn-floating btn-xs waves-effect waves-light orange accent-4 btn-shadow-none mg-right-5">
                  <i className="material-icons i-middle">edit</i>
                </button>
              </BtnModalOpen>

              { props.delete &&
                <button onClick={deleteCustomer} className="btn-floating btn-xs waves-effect waves-light red btn-shadow-none">
                  <i className="material-icons font-1-6 i-middle">remove</i>
                </button>
              }

            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default connect()(CustomerPreviewManager)

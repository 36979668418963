import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../../routes/renderRoutes'

// Components Global
import LayoutSidebarManage from '../../../../../components/LayoutSidebarManage'

// Component Local
import OrdersListItems from './components/OrdersListItems'

// Values
import { orderValue } from '../../../../../redux/Orders/OrdersValues'

// Actions Redux
import { fetchOrder } from '../../../../../redux/Orders/OrdersActions'



const OrdersListContainer = (props) => {
  const layoutSidebar = useRef()
  const { match } = props
  const { orders_drafts_id, orders_id } = match.params
  const { business, location, route, order, dispatch } = props
  const openSidebar = () => layoutSidebar.current.open()

  useEffect(() => {
    if (!order) {
      layoutSidebar.current.close()
    } else {
      layoutSidebar.current.open()
    }
  }, [order])

  useEffect(() => {
    if (!orders_id && orders_id) {
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businesses_id: business._id,
        orders_id
      }

      if (orders_id !== 'all') {
        setTimeout(() => {
          dispatch(fetchOrder(query))
        }, 500)
      }
    }
  }, [])

  const goBack = () => {
    props.history.push(`/${business.username}/pos/sales/${orders_drafts_id}`)
  }


  return (
    <div className="height-full width-full pst-relative">
      <div className="height-50 bg-fff border-bottom-eee" style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="width-full pd-left-15" style={{ display: 'flex', alignItems: 'center' }}>ประวัติการขาย</div>
        <div className="width-50" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <a onClick={goBack} className="btn btn-white btn-shadow-none border-ddd border-radius-circle width-40 height-40 center line-height-40 pd-0">
            <i className="material-icons font-1-5 color-333">close</i>
          </a>
        </div>
      </div>

      <div className="pst-absolute top-50 left-0 right-0 bottom-0">
        <LayoutSidebarManage
          {...props}
          relative
          urlMain={`/${business.username}/pos/sales/${orders_drafts_id}/histories/all`}
          ref={layoutSidebar}
          dataId={orders_id}>

          <div>
            <OrdersListItems {...props} openSidebar={openSidebar} />
          </div>

          <div>
            { renderRoutes({
              routes: route.routes,
              extraProps: { ...props, order: orderValue({ order }) },
              location
            }) }
          </div>
        </LayoutSidebarManage>
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const { match } = props
  const { orders_id } = match.params
  const { orders } = state

  let order = orders.data.filter(order => order._id === orders_id)[0]

  if (!order && orders.search && orders.search.status === true) {
    order = orders.search.data.filter(order => order._id === orders_id)[0]
  }

  return {
    order
  }
}

export default connect(mapStateToProps)(OrdersListContainer)


import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../routes/renderRoutes'

// Actions Redux
import { fetchAttribute } from '../../../../redux/Attributes/AttributesActions'

// Values
import { attributeValue } from '../../../../redux/Attributes/AttributesValues'


class AttributeContainer extends Component {
  componentDidMount() {
    this.props.dispatch(fetchAttribute('admin', this.props.business._id, this.props.match.params.attributes_id))
  }

  render() {
    const { route, attribute, business, location } = this.props

    return (
      <Fragment>
        { attribute ?
          <Fragment>
            { renderRoutes({
              routes: route.routes,
              extraProps: { business, attribute: attributeValue({ attribute }) },
              location
            }) }
          </Fragment>
        :
          <h1>404 Not Found</h1>
        }
      </Fragment>
    )
  }
}


const mapStateToProps = (store, props) => {
  return {
    attribute: store.attributes.data.filter((attribute => attribute._id === props.match.params.attributes_id))[0]
  }
}

export default connect(mapStateToProps)(AttributeContainer)

import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

class ItemSeleted extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.props.unSelect()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick)
  }

  handleDocumentClick = (e) => {
    const blockUnSelect = document.querySelector('#blockUnSelect')
    const blockUnSelectItems = document.querySelector('#blockUnSelectItems')
    const confirmAlert = document.querySelector('#confirmAlert')
    const modal = document.querySelector('#modal')
    const modalUpload = document.querySelector('#modalUpload')
    const foldersManage = document.querySelector('#foldersManage')

    const checkBlockUnSelect = blockUnSelect && ReactDOM.findDOMNode(blockUnSelect).contains(e.target)
    const checkBlockUnSelectItems = blockUnSelectItems && ReactDOM.findDOMNode(blockUnSelectItems).contains(e.target)
    const checkConfirmAlert = confirmAlert && ReactDOM.findDOMNode(confirmAlert).contains(e.target)

    const checkModal = modal && ReactDOM.findDOMNode(modal).contains(e.target)
    const checkModalUpload = modalUpload && ReactDOM.findDOMNode(modalUpload).contains(e.target)

    const checkFoldersManage = foldersManage && ReactDOM.findDOMNode(foldersManage).contains(e.target)
    const target = ReactDOM.findDOMNode(this).contains(e.target)

    if (confirmAlert) {
      if (!checkBlockUnSelect && !checkBlockUnSelectItems && !checkConfirmAlert) {
        this.props.unSelect()
      }

    } else if (modal) {
      if (!checkBlockUnSelect && !checkBlockUnSelectItems && !checkModal) {
        this.props.unSelect()
      }

    } else if (modalUpload) {
      if (!checkBlockUnSelect && !checkBlockUnSelectItems && !checkModalUpload) {
        this.props.unSelect()
      }

    } else if (foldersManage) {
      if (!checkBlockUnSelect && !checkBlockUnSelectItems && !checkFoldersManage) {
        this.props.unSelect()
      }

    } else {
      if (!checkBlockUnSelect && !checkBlockUnSelectItems) {
        this.props.unSelect()
      }
    }
  }

  render() {
    return <div>{this.props.children}</div>
  }
}

ItemSeleted.propTypes = {
  children: PropTypes.object.isRequired,
  unSelect: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
}

export default ItemSeleted

import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../../components/Modals/Modal'
import { Row, Column } from '../../../../../../components/Columns'

// Components forms
import Form from '../../../../../../components/Form'
import BtnSubmit from '../../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../../util/getFormValue'

// Components Customers
import RubberCustomerCreateAndUpdateModal from '../../../RubberCustomerBuyers/components/RubberCustomerCreateAndUpdateModal'

// Components Local
import RubberItemSale from '../RubberSaleForms/RubberItemSale'
import { SaleTotalPriceAll } from '../RubberSaleForms'

// Actions Redux
import { createSale, updateSale } from '../../../../../../redux/Sales/SalesActions'

// Values
import { getRubberSettingTypeValue } from '../../../../../../redux/RubberSettings/RubberSettingsValue'
import InventoryForms from '../RubberSaleForms/InventoryForms'
import SaleDetailsForms from '../RubberSaleForms/SaleDetailsForms'

// Functions
import { updateContractNetWeightSale } from '../../../../../../functions/RubberSystem/RubberContracts/RubberContractsFunctions'


const RubberSaleCreateAndUpdateModal = (props) => {
  const [data, setData] = useState('')
  const [sale, setSale] = useState()
  const [itemSale, setItemSale] = useState()
  const [cost, setCost] = useState()
  const [customer, setCustomer] = useState()
  const [rubberType, setRubberType] = useState()
  const [rubberType2, setRubberType2] = useState()

  const formRef = useRef()
  const { id, event, match, business, setting, dispatch, forDeposit, forContract } = props
  let { username, productType, saleType } = match.params

  let inventoryType = 'inventory'

  if (forDeposit) {
    productType = 'rubber-sheet'
    saleType = 'deposit'
    inventoryType = 'deposit'
  } else if (forContract) {
    productType = 'rubber-sheet'
    saleType = 'contract'
  }

  const type = getRubberSettingTypeValue({ setting, code: productType })

  let title, saleName

  if (saleType === 'deposit') {
    saleName = 'ฝากยาง'
    title = "การขาย"

  } else if (saleType === 'contract') {
    title = "การขาย"

  } else if (saleType === 'make') {
    title = "การ"

  } else if (saleType === 'sell-on-cash') {
    title = "การขาย"
  }

  if (event === 'create') {
    title = `เพิ่ม${title}${type.defaultValue.name}`
  } else if (event === 'update') {
    title = `แก้ไข${title}${type.defaultValue.name}`
  }

  if (saleName) {
    title = `${title} (${saleName})`
  }

  const handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(formRef.current)

    if (params) {
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id
      }

      if (props.forContract) {
        params.contracts_id = props.forContract._id
      }

      params.customer = customer
      params.customers_id = customer && customer._id
      params.cost = cost
      params.itemSale = itemSale

      params.productType = productType
      params.rubberType = rubberType
      params.rubberType2 = rubberType2
      params.saleType = saleType

      params.profit = {
        netWeight: itemSale.netWeight - cost.netWeightSale,
        latexPercent: itemSale.latexPercent - cost.latexPercentAvg,
        dryLatexWeight: itemSale.dryLatexWeight - cost.dryLatexWeight,
        netPrice: itemSale.netPrice - cost.netPriceAvg,
        totalPrice: itemSale.totalPrice - cost.totalPrice,
        totalIncomes: itemSale.totalIncomes,
        totalPriceAll: itemSale.totalPriceAll - cost.totalPrice
      }

      if (event === 'create') {
        let dataRedirect

        if (!forContract && !forDeposit) {
          dataRedirect = {
            url: `/${username}/rubber-sales/${productType}/all/${saleType}/sales/:_id`,
            resName: 'data'
          }
        }

        if (forDeposit) {
          dataRedirect = {
            url: `/${username}/rubber-deposits/sales/rubber-sheet/all/deposit/sales/:_id`,
            resName: 'data'
          }
        }

        dispatch(createSale(query, {
          body: params,
          loadId: formName,
          modalId: formName,
          toastMsg: title,
          props,
          dataRedirect
        })).then(res => {
          if (res.success && forContract) {
            updateContractNetWeightSale(props, forContract._id)
          }
        })

      } else if (event === 'update') {
        query.sales_id = props.sale._id

        dispatch(updateSale(query, {
          body: params,
          loadId: formName,
          modalId: formName,
          toastMsg: title
        })).then(res => {
          if (res.success && forContract) {
            updateContractNetWeightSale(props, forContract._id)
          }
        })
      }
    }
  }

  useEffect(() => {
    if (productType === 'field-latex') {
      setRubberType('field-latex')
    }

    if (props.fixed) {
      setCustomer(props.fixed.customer)
      setRubberType(props.fixed.rubberType)

      if (props.fixed.rubberType === 'rubber-sheet') {
        setRubberType2('rs-good')
      } else if (props.fixed.rubberType === 'ribbed-smoked-sheet') {
        setRubberType2('rss-3')
      }
    }

  }, [props.fixed])

  useEffect(() => {
    setData({ itemSale, cost, customer })
  }, [itemSale, cost, customer, rubberType, rubberType2 ])

  useEffect(() => {
    if (props.sale) {
      setCost(props.sale.cost)
      setItemSale(props.sale.itemSale)
      setCustomer(props.sale.customer)
      setRubberType(props.sale.rubberType)
      setRubberType2(props.sale.rubberType2)
    }
  }, [props.sale])

  /*useEffect(() => {
    if (props.forDeposit || props.forContract) {
      props.customer && setCustomer(props.customer)
    }
  }, [props.customer])*/

  /*useEffect(() => {
    if (props.forContract) {
      setRubberType(props.forContract.rubberType)
    }
  }, [props.forContract])*/


  const handleSelectCustomer = (customer) => {
    if (customer) {
      setCustomer(customer)
    } else {
      setCustomer(undefined)
    }
  }

  const handleChangeCost = (cost) => {
    setCost(cost)
  }

  const handleChangeSale = (itemSale) => {
    setItemSale(itemSale)
  }

  const handleSelectRubberType = (value) => {
    setRubberType(value)

    if (productType === 'rubber-sheet-make') {
      if (value === 'rubber-sheet') {
        setRubberType2('rs-good')
      } else if (value === 'ribbed-smoked-sheet') {
        setRubberType2('rss-3')
      }
    } else {
      setRubberType2('')
    }
  }

  const handleSelectRubberType2 = (value) => {
    setRubberType2(value)
  }

  const col_1 = "column col-xxs-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 pd-right-20-lg pd-right-20-xlg pd-bottom-20"
  const col_2 = "column col-xxs-12 col-xs-12 col-sm-12 col-md-12 col-lg-6  pd-left-20-lg  pd-left-20-xlg pd-bottom-20"

  return (
    <Modal
      id={id}
      headerTitle={title}
      headerLeft
      blockBgModal
      width="1100px"
      padding="0">

      <div className="pd-20">
        <RubberCustomerCreateAndUpdateModal
          {...props}
          id="customerCreateModal"
          event="create"
          match={props.match}
          business={props.business}
          onSelect={handleSelectCustomer}
        />

        <Form
          recheckForm
          ref={formRef}
          name={id}
          dataChange={JSON.stringify(data)}
          submit={(e) => handleSubmit(e, id)}>

          <Row>
            <Column col={col_1}>
              <div className="font-1-1 pd-bottom-20">
                { type.code === 'rubber-sheet-make' ? 'รายละเอียดการทำยางแผ่น'  : 'รายละเอียดการขาย' }
              </div>

              <SaleDetailsForms
                {...props}
                data={props.sale}
                type={type}
                productType={productType}
                rubberType={rubberType}
                rubberType2={rubberType2}
                customer={customer}
                handleSelectCustomer={handleSelectCustomer}
                handleSelectRubberType={handleSelectRubberType}
                handleSelectRubberType2={handleSelectRubberType2}
                saleType={saleType}
              />
            </Column>

            <Column col={col_2}>
              <div className="font-1-1 pd-bottom-20">
                { type.code === 'field-latex' && 'น้ำยางสดในสต็อก' }
                { type.code === 'rubber-sheet-make' && 'น้ำยางสดในสต็อก' }
                { type.code === 'rubber-sheet' && 'ยางแผ่นในสต็อก' }

                { saleType === 'deposit' && ' (ยางที่ฝาก)' }
              </div>

              <InventoryForms
                {...props}
                element="cost"
                cost={props.sale && props.sale.cost}
                type={type}
                inventoryType={inventoryType}
                rubberType={rubberType}
                productType={productType}
                customer={customer}
                onChange={handleChangeCost}
              />
            </Column>
          </Row>

          <RubberItemSale
            {...props}
            type={type}
            forContract={props.forContract}
            forDeposit={undefined}
            itemSale={props.sale && props.sale.itemSale}
            cost={cost}
            rubberType={rubberType}
            onChange={handleChangeSale}
          />

          <Row>
            <Column col="column col-xxs-12 mg-top--15">
              <SaleTotalPriceAll type={type} value={itemSale && itemSale.totalPriceAll} />
            </Column>

            <Column col="column col-xxs-12">
              <BtnSubmit
                loadingId={id}
                icon="send"
                iconAlign="left"
                text={title}
                className="btn btn-submit btn-shadow-none height-45 line-height-45 font-0-9 width-full"
                btnDisabled
              />
            </Column>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}


export default connect()(RubberSaleCreateAndUpdateModal)

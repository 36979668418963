// Redux Actions
import { updateContract, removeContract } from '../../../redux/Contracts/ContractsActions'

const title = "สัญญา"

export const onEditModal = (id) => {
  return {
    modal: { id: id }
  }
}

export const onDelete = (props) => {
  const handleDelete = (props, toastMsg) => {
    return (id) => {
      const { business } = props
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businesses_id: business._id,
        businessType: business.type,
        contracts_id: id
      }

      props.dispatch(removeContract(query, {
        toastMsg: toastMsg,
        loadId: id,
        confirmId: id,
        props: props
      }))
    }
  }

  return {
    label: `ลบ${title}`,
    onConfirm: handleDelete(props, `ลบ${title}นี้ อย่างถาวรแล้ว`)
  }
}


export const updateContractNetWeightSale = (props, contracts_id) => {
  const { business, dispatch } = props

  const query = {
    authName: 'admin',
    dbNo: business.dbNo,
    businessType: business.type,
    businesses_id: business._id,
    contracts_id
  }

  dispatch(updateContract(query, {
    body: {
      scenario: 'updateNetWeightSale'
    },
    loadId: 'updateNetWeightSaleLoading'
  }))
}










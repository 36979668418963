import React from 'react'

// Components Global
import NumberPicker from '../../../../../components/Inputs/NumberPicker'

// Utils
import { getNumeral } from '../../../../../util/getNumeral'


export const ProductQty = (props) => {
  const { product, productItem, value } = props

  let qtyMax

  if (product.stockAdjust) {
    if (value && value.qty) {
      qtyMax = value.qty + productItem.qty
    } else {
      qtyMax = productItem.qty
    }
  } else {
    qtyMax = 50
  }

  return (
    <div>
      <input type="hidden" data-id="products_items_id" defaultValue={productItem._id} />
      <input type="hidden" data-id="price" defaultValue={productItem.priceCondition} />

      <NumberPicker
        numberCenter
        notZero
        className=""
        id="qty"
        value={value && value.qty}
        //value={ orderItem ? orderItem.qty : productItem.qty <= 0 ? 0 : 0 }
        min="0"
        max={qtyMax}
        step="1"
      />
    </div>
  )
}

import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'

// Componets Global
import { Row, Column } from 'components/Columns'
import Modal from 'components/Modals/Modal'
import FormValidation from 'components/Form'
import BtnSubmit from 'components/button/BtnSubmit'
import getFormValue from 'utils/getFormValue'

// Components Local
import {
  UserName,
  UserEmail,
  UserPassword
} from '../UserForms'

// Actions Redux
import { createUser } from 'core/redux/Users/UsersActions'


const UserCreateModal = (props) => {
  const formRef = useRef()
  const [data, setData] = useState('')
  const { id, business, dispatch } = props

  const handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(formRef.current)

    if (params) {
      params.isAdmin = true
      params.active = true

      if (props.onSubmit) {
        props.onSubmit(params, formName)
      } else {
        const query = {
          authName: 'admin',
          businesses_id: business._id
        }

        dispatch(createUser(query, {
          body: params,
          loadId: formName,
          modalId: formName,
          toastMsg: 'เพิ่มสมาชิกแล้ว'
        }))
      }
    }
  }

  const handleChangeEmail = (value) => {
    setData(value)
  }

  return (
    <Modal
      id={id}
      headerTitle="เพิ่มผู้ใช้งาน"
      headerLeft
      width="550px">

      <div className="pd-20">
        <FormValidation
          ref={formRef}
          name={id}
          dataChange={JSON.stringify(data)}
          submit={(e) => handleSubmit(e, id)}>

          <Row>
            <Column col="column col-xs-12 mg-top-20">
              <UserName />
            </Column>

            <Column col="column col-xs-12">
              <UserEmail
                {...props}
                business={business}
                onChange={handleChangeEmail}
              />
            </Column>

            <Column col="column col-xs-12">
              <UserPassword required />
            </Column>

            <Column col="column col-xs-12 text-align-right">
              <BtnSubmit
                loadingId={id}
                icon="send"
                iconAlign="left"
                text="เพิ่มผู้ใช้งาน"
                className="btn btn-success btn-shadow-none height-35 line-height-35"
                btnDisabled
              />
            </Column>
          </Row>
        </FormValidation>
      </div>
    </Modal>
  )
}

export default connect()(UserCreateModal)

import React from 'react'
import Hammer from '../../hammerjs'

// css
import css from '../css/calendar.css'


const SelectionButton = (props) => {
  return (
    <div className="hidden-xxs hidden-xs hidden-sm hidden-md hidden-lg">
      {props.startDate === props.date &&
        <Hammer
          id="button-left"
          onPanUp={props.onPanLeft}
          onPanLeft={props.onPanLeft}
          onPanRight={props.onPanLeft}
          onPanEnd={props.onPanEnd}
          className={css.buttonLeft}>

          <i className="material-icons">chevron_left</i>
        </Hammer>
      }

      {props.endDate === props.date &&
        <Hammer
          id="button-right"
          onPanDown={props.onPanRight}
          onPanLeft={props.onPanRight}
          onPanRight={props.onPanRight}
          onPanEnd={props.onPanEnd}
          className={css.buttonRight}>

          <i className="material-icons">chevron_right</i>
        </Hammer>
      }
    </div>
  )
}

export default SelectionButton
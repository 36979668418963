import React from 'react'
import { connect } from 'react-redux'

// Components Local
import InputRemote from '../InputRemote'


const InputCheckDuplicate = (props) => {
  const handleChange = (data) => {
    if (props.onChange) {
      props.onChange(data)
    }
  }

  let {
    id,
    element,
    type,
    placeholder,
    disabled,
    barcodeScanner,
    label,
    value,
    params_business_id,
    action,
    useQuery,
    query,
    unselectId,
    required
  } = props

  const split = id.split('[0].')

  if (split[0] && split[1]) {
    id = split[1]
  }

  if (element) {
    query = `${query}&element=${element}`
  } else {
    query = `${query}&element=${id}`
  }

  if (unselectId) {
    query = `${query}&unselectId=${unselectId}`
  }

  query = `${query}&${element || id}=`

  let pattern, msg

  if (type === 'path' || type === 'username' || type === 'code') {
    pattern = /^([\A-Za-z0-9]+((?:\-[\A-Za-z0-9]+)*)){2}$/i
    msg = 'ไม่ถูกต้อง ต้องเป็น ภาษาอังกฤษหรือตัวเลข มีอย่างน้อย 2 ตัวอักษร'

  } else if (type === "email") {
    pattern = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
    msg = 'รูปแบบอีเมลไม่ถูกต้อง'

  } else {
    pattern = /^./
    msg = ''
  }

  return (
    <div className="mg-bottom-20">
      <InputRemote
        disabled={disabled}
        required={required}
        barcodeScanner={barcodeScanner}
        iconValid
        label={label}
        id={props.id}
        value={value}
        placeholder={placeholder || label}
        type="regex"
        onChange={handleChange}
        regex={pattern && {
          pattern,
          msg
        }}
        remote={{
          params_business_id,
          action,
          useQuery,
          query,
          fnQuery: props.fnQuery,
          msgLoading: 'กำลังตรวจสอบ...',
          msgDuplicate: `${label} นี้ คุณใช้งานแล้ว ไม่สามารถใช้ซ้ำได้`,
          msgNone: `${label} นี้ สามารถใช้งานได้`,
          dataSuccess: null
        }}
      />
    </div>
  )
}

export default connect()(InputCheckDuplicate)

import React from 'react'

// Components Global
import Required from '../Required'

const InputDisable = (props) => {
  const { digit } = props

  return (
    <div>
      <Required
        {...props}
        id={props.id}
        type="disabled"
        default={props.value}>

        <input disabled defaultValue={props.value} />
      </Required>
    </div>
  )
}

export default InputDisable

import React from 'react'

// Components Global
import ProfileAvatar from '../../../../../../components/ProfileAvatar'

// Funtions Product
import { getItemImage } from '../../../../../../redux/Products/ProductsFunctions'

// Values
import { productValue } from '../../../../../../redux/Products/ProductsValues'


const ProductProfileAvater = (props) => {
  let { product, fontSize, item } = props

  if (!product.avatarName) {
    product = productValue({product})
  }

  let images

  if (product.images && product.images.length !== 0) {
    images = product.images

  } else if (item) {
    images = getItemImage({ product, item })
  }

  let singleItem

  if (product.items && product.items.length <= 1) {
    singleItem = product.items.filter(item => item.itemChoice === 'single')[0]
  }


  if (singleItem && !item) {
    const profile = product.images.filter(img => img.album === 'profile')[0]

    if (profile) {
      images = product.images
    } else {
      images = singleItem.images
    }
  }

  return (
    <ProfileAvatar
      circle={props.circle}
      images={images}
      imageSize="m"
      color={product.avatarColor}
      texts={product.avatarName}
      textSize={fontSize || '1.1rem'}
    />
  )
}

export default ProductProfileAvater

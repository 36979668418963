
import React, { Fragment } from 'react'
import GoogleMapReact from 'google-map-react'
import ReactDOMServer from 'react-dom/server'


const InfoWindowContent = (props) => {
  return (
    <div className="pd-0 width-200">
      { props.loading ?
        <div className="center pd-10">
          กำลังโหลด...
        </div>
      :
        <Fragment>
          { props.error ?
            <div className="center pd-10 color-error">
              ไม่สามารถเลือกพื้นที่นี้ได้
            </div>
          :
            <div>
              <div className="pd-bottom-5">
                { props.address }
              </div>

              <a id="btnSelectMap" className="btn height-25 line-height-25 font-0-8 width-full">เลือกตำแหน่งนี้</a>
            </div>
          }
        </Fragment>
      }
    </div>
  )
}


let map, maps, marker, infoWindow, geocoder, geo

const SimpleMap = (props) => {
  const handleApiLoaded = (apiMap, apiMaps) => {
    map = apiMap
    maps = apiMaps
    geocoder = new maps.Geocoder()
    infoWindow = new maps.InfoWindow()
    getCurrentLocation()

    maps.event.addListener(map, 'click', (e) => {
      const position = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      }

      marker.setPosition(position)
      getGeocoder(position)
    })
  }

  const selectMap = () => {
    const { geometry } = geo
    const location = {
      lat: geometry.location.lat(),
      lng: geometry.location.lng()
    }

    console.log(location)
  }

  const getGeocoder = (location) => {
    infoWindow.open(map, marker)
    infoWindow.setContent(ReactDOMServer.renderToString(<InfoWindowContent loading />))

    geocoder.geocode({ location }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          geo = results[0]
          const addressFull = geo.formatted_address
          infoWindow.setContent(ReactDOMServer.renderToString(<InfoWindowContent address={addressFull} />))

          setTimeout(() => {
            const btnSelectMap = document.getElementById("btnSelectMap")

            if (btnSelectMap) {
              btnSelectMap.addEventListener("click", () => {
                selectMap()
              })
            }
          }, 100)

        } else {
          geo = undefined
          infoWindow.setContent(ReactDOMServer.renderToString(<InfoWindowContent error />))
          window.alert("No results found")
        }
      } else {
        geo = undefined
        infoWindow.setContent(ReactDOMServer.renderToString(<InfoWindowContent error />))
        window.alert("Geocoder failed due to: " + status)
      }
    })
  }

  const placeMarker = (position) => {
    if (maps && maps) {
      if (!marker) {
        marker = new maps.Marker({
          position,
          map,
          title: 'ที่อยู่ของฉัน'
        })
      } else {
        marker.setPosition(position)
      }

      getGeocoder(position)

      marker.addListener("click", () => {
        infoWindow.open(map, marker)
      })
    }
  }


  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pst) => {
        const pos = {
          lat: pst.coords.latitude,
          lng: pst.coords.longitude,
        }

        map.setCenter(pos)
        placeMarker(pos)

      }, () => {
        console.log('error')
      })
    } else {
      console.log('error')
    }
  }

  return (
    <div style={{ height: '100vh', width: '100%' }} >
      <GoogleMapReact
        options={{ draggableCursor: 'default' }}
        bootstrapURLKeys={{ key: 'AIzaSyAhpxtIOthrW2SViWcGpO0UWSR8sDZbWpI', language: 'th' }}
        defaultCenter={props.center}
        defaultZoom={props.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      />

      <div className="pst-fixed bottom-0 left-0 pd-10">
        <a onClick={() => getCurrentLocation('update')} className="btn">ตำแหน่งของฉัน</a>
      </div>
    </div>
  )
}

SimpleMap.defaultProps = {
  center: { lat: 15.870032, lng: 100.992538 },
  zoom: 11
}

export default SimpleMap

import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import cuid from 'cuid'

// Components Inputs
import Required from '../Required'
import Chip from '../../Chip'
import DropdownDefault from '../../DropdownDefaultV2'

// Components
import InfiniteScroll from '../../InfiniteScroll'
import PreLoading from '../../PreLoading'

// Redux Actions
import { addLoad } from '../../../redux/App/AppActions'

//css
import css from './css/dropdown.css'

// Js
import { onSearchData } from '../../InputSearch/js/InputSearchFunction'


/*
const InputDropdown = (props) => {
  const dropdownRef = useRef()
  const [id, setId] = useState('')
  const [value, setValue] = useState('')
  const [dataSeleted, setDataSeleted] = useState()

  useEffect(() => {
    const newId = props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()

    setId(randomId+newId)
  }, [])

  useEffect(() => {
    setValue(props.value || '')
  }, [props.value])

  useEffect(() => {
    setDataSeleted(props.dataSeleted)
  }, [props.dataSeleted])

  useEffect(() => {
    if (props.onKeyChange) {
      this.props.onKeyChange(dataSeleted)
    }

    if (props.select) {
      props.select(dataSeleted)
    }
  }, [dataSeleted])


  const handleChange = (e) => {
    const value = e.target.value || ''
    setValue(value)

    if (!e.target.value) {
      setDataSeleted(undefined)
    }

    if (props.onKeyChange) {
      props.onKeyChange(value)
    }
  }

  const search = (e) => {
    clearTimeout(this.timer)

    const fetch = this.props.fetchAction
    const value = e.target.value

    if (!this.props.load) {
      this.props.dispatch(addLoad(this.props.id))
    }

    this.timer = setTimeout(() => {
      this.setState({ valueSearch: false }, () => {
        onSearchData(this, value, fetch, { loadId: this.props.id })
      })
    }, 1000)
  }

  const select = (data) => {
    setDataSeleted(data)
    dropdownRef.current.close()
  }

  const unSelect = () => {
    setDataSeleted(null)
    setValue('')

    if (props.remove) {
      this.props.remove()
    }
  }

  const getValueSearch = (datas, dataSearch) => {
    return dataSearch && dataSearch.status ? dataSearch.data : datas
  }

  const { hiddenInputBorderForSeleted, useSubData } = props
  const datas = getValueSearch(props.datas, props.datasSearch)

  return (
    <Required
      id={id}
      value={value}
      label={props.label}
      labelHidden={props.labelHidden}
      unitName={props.unitName}
      className={props.className}
      type="text"
      required={props.required}
      iconValid={props.iconValid}>

      { dataSeleted ?
        <div
          className="pd-top-7"
          id={id}
          style={!hiddenInputBorderForSeleted ? { borderBottom: '1px solid #4CAF50', boxShadow: '0 1px 0 0 #4CAF50' } : {}}>

          <input
            type="hidden"
            data-id={`${props.selectId}`}
            defaultValue={dataSeleted._id}
          />

          <Chip
            id={props.id}
            value={props.chipValue}
            remove={unSelect}
            avatar={props.avatar}
            chipInput={props.chipInput}
            className={props.chipClassName}
          />
        </div>
      :
        <DropdownDefault
          targetNotClose
          left={props.left}
          right={props.right}
          ref={dropdownRef}
          className="width-full"
          contentClassName="min-width-250 mg-bottom-50">

          <input
            type="text"
            id={id}
            data-id={props.id}
            value={value}
            placeholder={props.placeholder}
            onChange={handleChange}
            //onKeyUp={search}
            className={`${props.inputClassName || ''}`}
          />

          <div className={css.boxDropdown}>
            <ul className={`${css.dropdown} mg-bottom-30`}>
              { datas.length !== 0 &&
                <div className={css.header}>
                  { props.load &&
                    <div className="progress height-3 mg-0 border-bottom-radius-0">
                      <div className="indeterminate"></div>
                    </div>
                  }
                </div>
              }

              <div id={id} className={css.content}>
                <InfiniteScroll
                  { ...props }
                  id={id}
                  //startLoad={this.state.open}
                  scrollId={id}
                  fetchAction={props.fetchAction}
                  itemsData={props.datas}
                  searchData={props.datasSearch}>

                  <PreLoading
                    id={`preload-${id}`}
                    size="30px"
                    border="2px"
                    textSize="0.8rem"
                    style={{ position: 'relative' }}>

                    <div>
                      { !useSubData && datas.map(data => {
                        return (
                          <li key={data._id}>
                            <a onClick={() => select(data)}>
                              { props.item && props.item(data) }
                            </a>
                          </li>
                        )
                      }) }


                      { useSubData && datas.map(data => {
                        return data.items.map(item => {
                          return (
                            <li key={item._id}>
                              <a onClick={() => select({ data, item })}>
                                { props.item && props.item(data, item) }
                              </a>
                            </li>
                          )
                        })
                      }) }
                    </div>

                  </PreLoading>
                </InfiniteScroll>
              </div>
            </ul>
          </div>
        </DropdownDefault>
      }
    </Required>
  )

}

InputDropdown.propTypes = {
  storeName: PropTypes.string.isRequired,
  fetchAction: PropTypes.object.isRequired,
  select: PropTypes.func.isRequired
}

const mapStateToProps = (state, props) => {
  return {
    datas: state[props.storeName].data,
    datasSearch: state[props.storeName].search,
    load: state.app.loads.filter(load => load === props.id)[0]
  }
}

export default connect(mapStateToProps)(InputDropdown)*/



class InputDropdown extends Component {
  constructor(props) {
    super(props)

    const id = this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()
    this.timer = null
    this.state = {
      id: randomId+id,
      value: this.props.value ? this.props.value : '',
      open: false,
      dataSeleted: this.props.dataSeleted
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value || '' })
    }

    if (!this.props.notUpdateDataSelected) {
      if (prevProps.dataSeleted !== this.props.dataSeleted) {
        this.setState({ dataSeleted: this.props.dataSeleted })
      }
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick)
  }

  handleDocumentClick = (e) => {
    if (this.state.open) {
      if (!ReactDOM.findDOMNode(this).contains(e.target)) {
        this.closeDropdown()
      }
    }
  }

  openDropdown = (e) => {
    if (!this.state.open) {
      this.setState({ open: true })

      if (e.target.value) {
        this.search(e)
      }
    }
  }

  closeDropdown = () => {
    if (this.state.open) {
      this.setState({ open: false })
    }
  }

  handleChange = (e) => {
    const value = e.target.value || ''
    this.setState({ value })

    if (!e.target.value) {
      this.setDataSeleted(undefined)
    }

    if (this.props.onKeyChange) {
      this.props.onKeyChange(value)
    }
  }

  search = (e) => {
    clearTimeout(this.timer)

    const fetch = this.props.fetchAction
    const value = e.target.value

    if (!this.props.load) {
      this.props.dispatch(addLoad(this.props.id))
    }

    this.timer = setTimeout(() => {
      this.setState({ valueSearch: false }, () => {
        onSearchData(this, value, fetch, { loadId: this.props.id })
      })
    }, 1000)
  }

  setDataSeleted = (data) => {
    this.setState({ dataSeleted: data })

    if (this.props.select) {
      this.props.select(data)
    }
  }

  select = (data) => {
    this.setDataSeleted(data)
    this.closeDropdown()
  }

  unSelect = () => {
    this.setDataSeleted(null)
    this.setState({ value: '' })

    if (this.props.remove) {
      this.props.remove()
    }

    if (this.props.onKeyChange) {
      this.props.onKeyChange('')
    }
  }

  edit = () => {
    this.setState({ dataSeleted: null })

    if (this.props.edit) {
      this.props.edit()
    }
  }

  render() {
    const { hiddenInputBorderForSeleted, useSubData } = this.props
    const styleDropdwon = {
      left: this.props.left && '0',
      visibility: this.state.open ? 'visible' : 'hidden',
      opacity: this.state.open ? '1' : '0'
    }

    const getValueSearch = (datas, dataSearch) => {
      return dataSearch && dataSearch.status ? dataSearch.data : datas
    }

    const datas = getValueSearch(this.props.datas, this.props.datasSearch)

    return (
      <Fragment>
        { !this.props.disabled ?
          <Required
            id={this.state.id}
            value={this.state.value}
            label={this.props.label}
            labelHidden={this.props.labelHidden}
            unitName={this.props.unitName}
            className={this.props.className}
            labelClassName={this.props.labelClassName}
            type="text"
            required={this.props.required}
            iconValid={this.props.iconValid}>

            { this.state.dataSeleted ?
              <div
                className="pd-top-7"
                id={this.state.id}
                style={!hiddenInputBorderForSeleted ? { borderBottom: '1px solid #4CAF50', boxShadow: '0 1px 0 0 #4CAF50' } : {}}>

                <input
                  type="hidden"
                  data-id={`${this.props.selectId}`}
                  data-not-save={this.props.notSave}
                  defaultValue={this.state.dataSeleted._id}
                />

                { this.props.valueComp ?
                  this.props.valueComp({ remove: this.unSelect, edit: this.edit })
                :
                  <Chip
                    id={this.props.id}
                    value={this.props.chipValue}
                    remove={this.unSelect}
                    avatar={this.props.avatar}
                    avatarIcon={this.props.avatarIcon}
                    chipInput={this.props.chipInput}
                    className={this.props.chipClassName}
                    size={this.props.chipSize}
                  />
                }
              </div>
            :
              <input
                type="text"
                id={this.state.id}
                data-id={this.props.id}
                data-not-save={this.props.notSave}
                ref={this.state.id}
                value={this.state.value}
                placeholder={this.props.placeholder}
                onChange={this.handleChange}
                onClick={this.openDropdown}
                onKeyUp={this.search}
                className={`input ${this.props.inputClassName || ''} ${this.props.required ? `required` : ''}`}
              />
            }

            <div className={css.boxDropdown}>
              <ul className={`${css.dropdown} mg-bottom-30`} style={styleDropdwon}>
                { datas.length !== 0 &&
                  <div className={css.header}>
                    { this.props.load &&
                      <div className="progress height-3 mg-0 border-bottom-radius-0">
                        <div className="indeterminate"></div>
                      </div>
                    }
                  </div>
                }

                <div id={this.props.id + this.state.id} className={css.content}>
                  <InfiniteScroll
                    { ...this.props }
                    id={this.props.id}
                    startLoad={this.state.open}
                    scrollId={this.props.id + this.state.id}
                    fetchAction={this.props.fetchAction}
                    itemsData={this.props.datas}
                    searchData={this.props.datasSearch}>

                    <PreLoading
                      id={`preload-${this.props.id}`}
                      size="30px"
                      border="2px"
                      textSize="0.8rem"
                      style={{ position: 'relative' }}>

                      <div>
                        { !useSubData && datas.map(data => {
                          return (
                            <li key={`${this.state.id}-${data._id}`}>
                              <a onClick={() => this.select(data)}>
                                { this.props.item && this.props.item(data) }
                              </a>
                            </li>
                          )
                        }) }


                        { useSubData && datas.map(data => {
                          return data.items.map(item => {
                            return (
                              <li key={item._id}>
                                <a onClick={() => this.select({ data, item })}>
                                  { this.props.item && this.props.item(data, item) }
                                </a>
                              </li>
                            )
                          })
                        }) }
                      </div>

                    </PreLoading>
                  </InfiniteScroll>
                </div>
              </ul>
            </div>
          </Required>
        :
          <Required
            {...this.props}
            id={this.props.id}
            type="disabled"
            className={this.props.requiredClassName}
            labelClassName={this.props.labelClassName}
            label={this.props.label}
            unitName={this.props.unitName}
            required={this.props.required}
            default={this.props.value}>

            <div className="pd-top-7" style={{ borderBottom: '1px solid #9e9e9e' }}>
              <Chip
                id={this.props.id}
                value={this.props.chipValue}
                avatar={this.props.avatar}
                avatarIcon={this.props.avatarIcon}
                chipInput={this.props.chipInput}
                className={this.props.chipClassName}
                size={this.props.chipSize}
              />
            </div>
          </Required>
        }
      </Fragment>
    )
  }
}

InputDropdown.propTypes = {
  storeName: PropTypes.string.isRequired,
  fetchAction: PropTypes.object.isRequired,
  select: PropTypes.func.isRequired
}

const mapStateToProps = (state, props) => {
  let datas = state[props.storeName].data
  let datasSearch = state[props.storeName].search

  if (props.addGroupId) {
    datas = datas.filter && datas.filter(data => data.groupId === props.addGroupId)
    datasSearch = datasSearch.filter && datasSearch.filter(data => data.groupId === props.addGroupId)
  }

  return {
    datas,
    datasSearch,
    load: state.app.loads.filter(load => load === props.id)[0]
  }
}

export default connect(mapStateToProps)(InputDropdown)

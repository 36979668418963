import React from 'react'

const SelectPlatform = (props) => {
  return (
    <div>
      { props.platforms.map((platform, i) => {
        return (
          <div key={platform.id} className={`flx-row ${i+1 != props.platforms.length ? 'border-bottom-1 border-bottom-eee' : ''}`}>
            <div className="width-50 flx-column jtf-center pd-top-10 pd-bottom-10">
              <div className="overflow-hidden box-shadow-1 height-50 width-50 bg-fff border-radius-5 dp-flex flex-direction-column justify-content-center align-items-center">
                <i className={`${platform.icon} font-2-5`} style={{ color: platform.color }}></i>
              </div>
            </div>
            <div className="width-full flx-column jtf-center pd-left-15">
              <span className='font-1-0'>{platform.name}</span>
            </div>
            <div className="width-100 text-right flx-column jtf-center">
              <a onClick={() => props.onSelect(platform.id)} className="btn btn-white border-1 border-ddd height-40 line-height-40 btn-shadow-none">
                เชื่อมต่อ
              </a>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default SelectPlatform

import React, { Component } from 'react'

// Compoents Local
import SectionTitle from '../SectionTitle'

import { ContentGroupPageManage, ContentGroupPageDisplay } from '../../../../../ContentGroupPage'


class ContentGroupPageFullManage extends Component {
  render() {
    const {
      business,
      setting,
      title,
      params,
      pages,
      site,
      pageCurrent,
      singlePageData,
      singlePageType,
      contentGroupPage
    } = this.props

    return (
      <div>
        <SectionTitle title={title} mode="view">
          <ContentGroupPageDisplay
            {...this.props}
            mode="view"
            datas={{
              business,
              setting,
              site,
              pages,
              pageCurrent,
              singlePageData,
              singlePageType
            }}
          />

          <ContentGroupPageManage
            {...this.props}
            mode="edit"
            datas={{
              business,
              setting,
              site,
              pages,
              pageCurrent,
              singlePageData,
              singlePageType
            }}
          />
        </SectionTitle>
      </div>
    )
  }
}

export default ContentGroupPageFullManage

import React, { Component } from 'react'

import css from './css/inputDate.css'

class InputDate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value ? this.props.value : '',
      errorMsg: false
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.value !== nextProps.value) {
      this.setState({ value: nextProps.value })
    }
  }


  render() {
    return (
      <div className={`input-field ${css.inputDate}`}>
        <div className={`${css.input} ${this.state.errorMsg && css.error} ${this.state.value !== "" && css.valid}`}>

          { !this.props.labelHide &&
            <p className={`${this.state.errorMsg && css.labelError} ${css.label} ${this.state.value !== "" && css.labelValid}`}>
              {this.props.label}
            </p>
          }

          { this.state.value === "" ?
            <span style={{ color: '#aaaaaa' }}>{this.props.label}</span>
          :
            <span>{this.state.value}</span>
          }
        </div>

        { this.state.errorMsg && <span className={css.errorMessage}>{this.props.label} ต้องไม่ว่าง</span> }

        <div className={css.boxDate}>
        
        </div>
      </div>
    )
  }
}

export default InputDate
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../components/Modals/Modal'
import FetchData from '../../../../../components/FetchData'
import PreLoading from '../../../../../components/PreLoading'


// Components Sales
import ProductItemUpdate from './components/ProductItemUpdate'

// Actions Redux
import { fetchProductUpdate } from '../../../../../redux/Products/ProductsActions'
import { updateOrder } from '../../../../../redux/Orders/OrdersActions'


class OrderItemUpdateModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.product != this.props.product
  }

  fnStartModal = () => {
    const { business, productId } = this.props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id,
      products_id: productId
    }

    this.props.dispatch(fetchProductUpdate(query, {
      loadId: 'orderItemUpdateModal',
      update: true
    }))
  }

  handleSubmit = (params, formName) => {
    const { business, order, orderItem, productId } = this.props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id,
      orders_id: order._id
    }

    if (params) {
      params.updateType = 'set'
      params.updateElement = 'items'
      params.updateId = orderItem._id
      params.products_id = productId
      params.scenario = 'addOrderItem'

      this.props.dispatch(updateOrder(query, {
        body: params,
        loadId: formName,
        modalId: `orderItemUpdateModal-${productId}`,
        toastMsg: 'แก้ไขจำนวนสินค้าแล้ว'
      }))
    }
  }

  render() {
    const { product, orderItem, id } = this.props
    const productItem = product && product.items.filter(item => item._id === orderItem.productItem._id)[0]

    return (
      <Modal
        id={id}
        fnStartModal={this.fnStartModal}
        headerTitle="แก้ไขรายการสินค้าในตะกร้า"
        headerLeft
        width="650px"
        padding="0">

        <PreLoading id={id} style={{ position: 'relative' }}>
          <FetchData
            length={product ? 1 : 0}
            className="position-relative"
            style={{ position: 'relative' }}
            icon="shopping_basket"
            msg="ไม่มีรายการ">

            { product && productItem &&
              <ProductItemUpdate
                {...this.props}
                productItem={productItem}
                submit={this.handleSubmit}
              />
            }
          </FetchData>
        </PreLoading>
      </Modal>
    )
  }
}

const mapStateToProps = (store, props) => {
  return {
    product: store.products.data.filter((product => product._id === props.productId))[0]
  }
}

OrderItemUpdateModal.propTypes = {
  order: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired,
  orderItem: PropTypes.object.isRequired,
  productId: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(OrderItemUpdateModal)

import React from 'react'

// Components Global
import { Column, Row } from '../../../../../../components/Columns'

// Actions
import { openModal } from '../../../../../../redux/App/AppActions'

// Components RubberSaleForms
import { SaleCustomerBuyer } from '../../../RubberSales/components/RubberSaleForms/RubberSaleForms'

// Components Local
import {
  ContractCode,
  ContractDate,
  ContractDeadline,
  ContractRubberType,
  ContractNote,
  ContractNetWeight,
  ContractNetPrice
} from '../ContractFroms'


const ContractDetailsForm = (props) => {
  const { type, customer } = props

  return (
    <Row className="row-dp-flex mg-left--10 mg-right--10">
      <Column col="column col-xxs-12 pd-left-10 pd-right-10">
        <ContractCode {...props} value={props.data && props.data.code} onChange={props.handleCodeChange} />
      </Column>
      <Column col="column col-xxs-12 col-xs-6 pd-left-10 pd-right-10">
        <ContractDate value={props.data && props.data.date} />
      </Column>
      <Column col="column col-xxs-12 col-xs-6 pd-left-10 pd-right-10">
        <ContractDeadline value={props.data && props.data.date} />
      </Column>

      <Column col="column col-xxs-12 col-xs-6 pd-left-10 pd-right-10">
        <table>
          <tbody>
            <tr>
              <td className="pd-0">
                <SaleCustomerBuyer
                  {...props}
                  type={type}
                  customer={customer}
                  disabled={props.event === 'update'}
                  onChange={props.handleSelectCustomer}
                  required
                />
              </td>

              { props.event === 'create' &&
                <td className="pd-0 pd-top-8 width-50 text-right vertical-top">
                  <a onClick={() => props.dispatch(openModal('customerCreateModal'))} className="btn btn-shadow-none btn-white border-ddd width-40 height-40 line-height-40  pd-0 center">
                    <i className="material-icons">add</i>
                  </a>
                </td>
              }
            </tr>
          </tbody>
        </table>

      </Column>

      <Column col="column col-xxs-12 col-xs-6 pd-left-10 pd-right-10">
        <ContractRubberType
          disabled={props.event === 'update'}
          value={props.data && props.data.rubberType}
        />
      </Column>

      <Column col="column col-xxs-12 col-xs-6 pd-left-10 pd-right-10">
        <ContractNetWeight value={props.data && props.data.netWeight} />
      </Column>

      <Column col="column col-xxs-12 col-xs-6 pd-left-10 pd-right-10">
        <ContractNetPrice value={props.data && props.data.netPrice} />
      </Column>

      <Column col="column col-xxs-12 pd-left-10 pd-right-10">
        <ContractNote value={props.data && props.data.note}  />
      </Column>
    </Row>
  )
}

export default ContractDetailsForm

import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../components/Modals/Modal'
import { Row, Column } from '../../../../../components/Columns'

// Components forms
import Form from '../../../../../components/Form'
import BtnSubmit from '../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../util/getFormValue'

// Components Services
import { ContentGroupPageName, ContentGroupPagePath } from '../SitesForms'


class ContentGroupPageCreateModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(this.refs[formName])
    const { business, actionSinglePage } = this.props
    const action = actionSinglePage

    if (params) {
      params.updateType = 'push'
      params.updateElement = 'contentGroupPages'

      if (action.useQuery) {
        action.useQuery._id = action.data._id

        this.props.dispatch(action.update(action.useQuery, {
          body: params,
          loadId: formName,
          modalId: formName,
          props: this.props,
          toastMsg: 'เพิ่มหน้าเพจแล้ว'
        }))

      } else if (action.authName) {
        this.props.dispatch(action.update(action.authName, business._id, action.data._id, {
          body: params,
          loadId: formName,
          modalId: formName,
          props: this.props,
          toastMsg: 'เพิ่มหน้าเพจแล้ว'
        }))

      } else {
        this.props.dispatch(action.update(business._id, action.data._id, {
          body: params,
          loadId: formName,
          modalId: formName,
          props: this.props,
          toastMsg: 'เพิ่มหน้าเพจแล้ว'
        }))
      }
    }
  }


  render() {
    const id = `contentGroupPageCreateModal`

    return (
      <Modal
        id={id}
        headerTitle="เพิ่มหน้าเพจ"
        headerLeft
        blockBgModal
        width="450px">

        <Row className="pd-20 pd-top-30">
          <Form
            recheckForm
            ref={id}
            name={id}
            submit={(e) => this.handleSubmit(e, id)}>

            <Column col="column col-xs-12">
              <ContentGroupPageName />
              <ContentGroupPagePath />
            </Column>

            <Column col="column col-xs-12 text-right">
              <BtnSubmit
                loadingId={id}
                icon="send"
                iconAlign="left"
                text="เพิ่มหน้าเพจ"
                className="waves-effect waves-light btn btn-submit btn-shadow-none"
                btnDisabled
              />
            </Column>
          </Form>
        </Row>
      </Modal>
    )
  }
}

export default connect()(ContentGroupPageCreateModal)

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

export class AppDocumentsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Fragment>
        { this.props.children }
      </Fragment>
    )
  }
}

export default connect()(AppDocumentsContainer)

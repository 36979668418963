import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

// Components Global
import ModalFixed from '../../../../../components/Modals/ModalFixed'
import FetchData from '../../../../../components/FetchData'
import PreLoading from '../../../../../components/PreLoading'
import InfiniteScroll from '../../../../../components/InfiniteScroll'
import InputSearch from '../../../../../components/InputSearch'
import { BtnModalOpen } from '../../../../../components/Modals/BtnModal'

// Components Local
import CustomerCreateModal from '../CustomerCreateModal'
import { CustomerProfileAvater } from '../CustomersUserInterface'

// Actions Redux
import { createCustomer } from '../../../../../redux/Customers/CustomersActions'
import { closeModal } from '../../../../../redux/App/AppActions'
import { fetchCustomers } from '../../../../../redux/Customers/CustomersActions'

// Values
import { customerValue } from '../../../../../redux/Customers/CustomersValues'


class CustomersModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onSelect = (customer) => {
    this.props.onSelect(customer)
    this.props.dispatch(closeModal(this.props.id))
  }

  createCustomer = (params, formName) => {
    if (params) {
      const { business, dispatch } = this.props
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id
      }

      dispatch(createCustomer(query, {
        body: params,
        loadId: formName,
        modalId: formName,
      })).then(res => {
        if (res.success && res.data.length !== 0) {
          this.onSelect(res.data)
        }
      })
    }
  }

  render() {
    const { business } = this.props

    const getValueSearch = (data, dataSearch) => {
      return dataSearch && dataSearch.status ? dataSearch.data : data
    }

    const customers = getValueSearch(this.props.customers, this.props.customersSearch)
    const fetchAction = {
      action: fetchCustomers,
      actionQuery: {
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id
      },
      id: business._id,
      where: 'status=open',
      sort: '_id',
      limit: 30,
      searchAttributes: [
        { name: 'code', options: 'i' },
        { name: 'companyName.local', options: 'i' },
        { name: 'firstName.local', options: 'i' },
        { name: 'lastName.local', options: 'i' },
        { name: 'mobile.number', options: 'i' },
        { name: 'tel.number', options: 'i' },
        { name: 'email', options: 'i' },
        { name: 'firstName.local', options: 'i', join: 'lastName.local' }
      ]
    }

    const searchComponent = (
      <div className="pd-top-0 pd-left-8 pd-right-8 pd-bottom-8">
        <InputSearch {...this.props}
          id="search-customer-list"
          placeholder={`ค้นหาลูกค้า`}
          fetchAction={fetchAction}
        />
      </div>
    )

    return (
      <div>
        <ModalFixed
          blockBgModal
          id={this.props.id}
          title="รายชื่อลูกค้า"
          width="500px"
          headerClassName="height-95"
          bodyClassName="top-95"
          headerComponent={searchComponent}
          padding="10px">

          <div>
            <InfiniteScroll
              { ...this.props }
              id="customers-list"
              scrollId={this.props.id}
              fetchAction={fetchAction}
              itemsData={this.props.customers}
              searchData={this.props.customersSearch}>

              <PreLoading id="preload-customers-list">
                <FetchData
                  length={customers && customers.length}
                  icon="contact_phone"
                  msg={`ไม่มีรายชื่อลูกค้า`}>

                    <table className="bg-fff">
                      <ReactCSSTransitionGroup
                        component="tbody"
                        transitionName="example"
                        transitionEnterTimeout={1000}
                        transitionLeaveTimeout={1000}>

                        { customers && customers.map((customer, key) => {

                          const { nameLang, mobileFull }  = customerValue({ customer })

                          return (
                            <tr key={customer._id}>
                              <td className="pd-10 pd-left-15 width-65 border-bottom-efefef">
                                <div className="width-50 height-50 mg-center">
                                  <CustomerProfileAvater customer={customer} fontClassName="font-1-1" />
                                </div>
                              </td>
                              <td className="pd-10 line-height-10 border-bottom-efefef">
                                <div className="f5 f-title mg-bottom-10">
                                  { nameLang }
                                </div>

                                <div className="f6 mg-top-5">
                                  <i className="material-icons font-0-8 left">code</i> รหัส { customer.code || '-' }
                                </div>
                                <div className="f6 mg-top-5">
                                  <i className="material-icons font-0-8 left">phone_iphone</i> มือถือ { mobileFull ? mobileFull : '-' }
                                </div>
                                <div className="f6 mg-top-5">
                                  <i className="material-icons font-0-8 left">email</i> อีเมล { customer.email ? customer.email : '-' }
                                </div>
                              </td>
                              <td className="pd-10" width="90" style={{ borderBottom: '1px solid #efefef' }}>
                                <button
                                  onClick={() => this.onSelect(customer)}
                                  className="btn btn-xs dp-block width-full waves-effect waves-light light-green darken-1 btn-shadow-none">
                                  เลือก
                                </button>
                              </td>
                            </tr>
                          )
                        }) }
                      </ReactCSSTransitionGroup>
                    </table>
                </FetchData>
              </PreLoading>
            </InfiniteScroll>
          </div>
          <div>
            <BtnModalOpen id="createCustomer">
              <button className="btn btn-shadow-none width-full">
                <i className="material-icons font-1-7 left">add_circle_outline</i> สร้างรายชื่อลูกค้าใหม่
              </button>
            </BtnModalOpen>
          </div>
        </ModalFixed>

        <CustomerCreateModal required={this.props.required} id="createCustomer" onSubmit={this.createCustomer} />
      </div>
    )
  }
}

CustomersModal.propTypes = {
  business: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    customers: state.customers.data,
    customersSearch: state.customers.search
  }
}

export default connect(mapStateToProps)(CustomersModal)

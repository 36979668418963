import { a } from 'bowser'
import React, { Fragment, useEffect, useState } from 'react'
import { productItemValue, productValue } from '../../../../../../../../../redux/Products/ProductsValues'
import { ProductItemProfileAvater } from '../../../../../../../Products/components/ProductsUserInterface'

// Components POS
import { InputKeyboard } from '../../../../../../components/InputKeyboard/InputKeyboard'

// img
import img from './img/none-img.png'


const OrderItemOneManage = (props) => {
  const [item, setItem] = useState()
  const [qty, setQty] = useState()
  const [price, setPrice] = useState()

  const [type, setType] = useState()
  const [percent, setPercent] = useState()
  const [amount, setAmount] = useState()
  const [discount, setDiscount] = useState()

  const { keyboard } = props

  useEffect(() => {
    const { qty, price, discount } = props.itemSelected || {}

    setItem(props.itemSelected)
    setQty(qty)
    setPrice(price)

    if (discount) {
      setType(discount.type || '')
      setPercent(discount.percent || '')
      setAmount(discount.amount || '')
    }

  }, [JSON.stringify(props.itemSelected)])


  useEffect(() => {
    if (qty !== undefined && price !== undefined && item !== undefined) {
      if (keyboard && keyboard.current) {
        keyboard.current.setInputValue('qty', qty)
        keyboard.current.setInputValue('price', price)
        keyboard.current.setInputValue('discount-percent', percent)
        keyboard.current.setInputValue('discount-amount', amount)

        // set maxValue
        const netAmountProduct = (item.qty * item.price) - item.discount.amount
        keyboard.current.setInputElement('discount-amount', 'maxValue', netAmountProduct)
      }
    }
  }, [qty, price, percent, amount])


  useEffect(() => {
    if (percent && amount) {
      setDiscount({ type: 'percent', percent, amount })
    } else if (!percent && amount) {
      setDiscount({ type: 'amount', percent: 0, amount })
    } else {
      setDiscount({ type: 'percent', percent: 0, amount: 0 })
    }
  }, [type, percent, amount])


  useEffect(() => {
    if (percent !== undefined && amount !== undefined && price !== undefined && type !== undefined) {
      if (type === 'percent') {
        if (percent !== '') {
          keyboard.current.setInputValue('discount-amount', price * percent / 100)
        } else if (percent === '') {
          keyboard.current.setInputValue('discount-amount', '')
        }
      } else if (type === 'amount') {
        keyboard.current.setInputValue('discount-percent', '')
      }
    }
  }, [percent, amount])


  useEffect(() => {
    if (props.keyboardValues) {
      if (keyboard && keyboard.current) {
        const qty = keyboard.current.getValue('qty')
        const price = keyboard.current.getValue('price')
        const percent = keyboard.current.getValue('discount-percent')
        const amount = keyboard.current.getValue('discount-amount')

        setQty(qty)
        setPrice(price)
        setPercent(percent)
        setAmount(amount)
      }
    }
  }, [JSON.stringify(props.keyboardValues)])


  // update qty
  useEffect(() => {
    if (qty !== undefined && qty !== '') {
      props.updateItem({ qty })
    }
  }, [qty])


  // update item
  useEffect(() => {
    if (discount !== undefined && price !== undefined) {
      props.updateItem({ discount, price })
    }
  }, [JSON.stringify(discount), price])


  const plusQty = () => {
    setQty(qty + 1)
  }

  const minusQty = () => {
    if (qty > 0) {
      setQty(qty - 1)
    }
  }

  const focus = (name) => {
    keyboard.current.handleFocus(name)

    if (name === 'discount-percent') {
      setType('percent')
    } else if (name === 'discount-amount') {
      setType('amount')
    }
  }

  const setPriceDefault = () => {
    setPrice(item.productItem.price)
  }

  let { product, productItem } = item || {}

  if (product && productItem) {
    product = productValue({ product })
    productItem = productItemValue({ product, item: productItem })
  }

  return (
    <Fragment>
      { product && productItem &&
        <div>
          <div className="pd-top-10 pd-left-10 pd-right-10">
            <div className="height-180 center bg-ddd">
              <ProductItemProfileAvater product={product} item={productItem} fontSize="2rem" />
            </div>

            <div className="pd-top-10">
              <div className="font-1-0">
                { product.nameLang } { productItem.choiceFullNameLang && <span>| { productItem.choiceFullNameLang }</span> }
              </div>

              <div className="font-0-9 pd-top-5" style={{ color: '#727272' }}>
                บาร์โค้ด: { productItem.barcode || '-' }
              </div>
            </div>
          </div>

          <div className="pd-top-10 pd-left-10 pd-right-10">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className="width-full">
                <InputKeyboard
                  onClick={() => keyboard.current.handleFocus('qty')}
                  focus={keyboard && keyboard.current && keyboard.current.focus('qty')}
                  value={qty}
                  label="จำนวน"
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="width-45 pd-left-5">
                  <a onClick={minusQty} className='btn btn-white border-ddd btn-shadow-none height-40 line-height-40 width-40'>
                    <i className="material-icons-outlined">remove</i>
                  </a>
                </div>

                <div className="width-45 pd-left-5">
                  <a onClick={plusQty} className='btn btn-success btn-shadow-none height-40 line-height-40 width-40'>
                    <i className="material-icons-outlined">add</i>
                  </a>
                </div>

                <div className="width-50 pd-left-10">
                  <a onClick={() => props.removeItem(item)} className='btn btn-delete btn-shadow-none height-40 line-height-40 width-40'>
                    <i className="material-icons-outlined">delete</i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="pd-top-10 pd-left-10 pd-right-10">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className="width-full">
                <InputKeyboard
                  onClick={() => keyboard.current.handleFocus('price')}
                  focus={keyboard && keyboard.current && keyboard.current.focus('price')}
                  value={price}
                  label="ราคา"
                  unit="฿"
                />
              </div>

              { item.productItem.price !== price &&
                <div className="width-50 pd-left-10">
                  <a onClick={setPriceDefault} className='btn btn-white border-ddd btn-shadow-none height-40 line-height-40 width-40'>
                    <i className="material-icons-outlined">refresh</i>
                  </a>
                </div>
              }
            </div>
          </div>

          <div className="pd-top-10 pd-left-10 pd-right-10">
            <InputKeyboard
              onClick={() => focus('discount-percent')}
              focus={keyboard && keyboard.current && keyboard.current.focus('discount-percent')}
              value={percent || ''}
              label="ส่วนลด"
              unit="%"
            />
          </div>

          <div className="pd-top-10 pd-left-10 pd-right-10 pd-bottom-10">
            <InputKeyboard
              onClick={() => focus('discount-amount')}
              focus={keyboard && keyboard.current && keyboard.current.focus('discount-amount')}
              value={amount || ''}
              label="ส่วนลด"
              unit="฿"
            />
          </div>

          {/*<div className="pd-10">
            <div className="width-full height-70 line-height-30 pd-left-15 border-ddd border-radius-3 font-0-8">หมายเหตุ</div>
          </div>*/}
        </div>
      }
    </Fragment>
  )
}

export default OrderItemOneManage

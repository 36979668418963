// Containers
import RubberReportsContainer from '../../../../../../containers/dashboard/SystemRubber/RubberReports/containers/RubberReportsContainer'

// Routes
import RubberReportsMenuRoutes from './routes/RubberReportsMenuRoutes'



const RubberReportsRoutes = [
  {
    path: '/:username/rubber-reports',
    component: RubberReportsContainer,
    routes: [
      ...RubberReportsMenuRoutes,
    ]
  }
]

export default RubberReportsRoutes

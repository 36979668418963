import React, { Component } from 'react'

// Components Global
import DropdownDefault from '../../../../../../../../DropdownDefault'
import { BtnModalOpen } from '../../../../../../../../Modals/BtnModal'

// Components Local
import PagesLists from '../PagesLists'

// js
import { getPageActive } from '../../../../../../functions/siteManageFunctions'

// css
import css from './css/pagesDropdown.css'


class PagesDropdown extends Component {
  handleCloseDropdown = () => {
    this.dropdown.closeDropdown()
  }

  render() {
    const lang = this.props.lang || 'local'
    const page = getPageActive(this.props)

    return (
      <div>
        <DropdownDefault
          onRef={dropdown => this.dropdown = dropdown}
          styleContent={{
            padding: '2px',
            top: '50px',
            left: '0',
            width: '300px',
            borderTopRightRadius: '0',
            borderTopLeftRadius: '0'
          }}>

          <div className={css.boxPage}>
            <div className={css.name}>
              { page && page.name[lang] }
            </div>
            <div className={css.arrow}>
              <i className="material-icons i-middle">keyboard_arrow_down</i>
            </div>
          </div>

          <div className={css.content}>
            <div className={css.boxDropdown}>
              <PagesLists {...this.props} onClick={this.handleCloseDropdown} />
            </div>
            <div className={css.footer}>
              <BtnModalOpen id="pagesManageModal">
                <a>จัดการหน้า</a>
              </BtnModalOpen>
            </div>
          </div>
        </DropdownDefault>
      </div>
    )
  }
}

export default PagesDropdown

import { lazy } from 'react'
import suspenseComponent from 'utils/suspenseComponent'

// Containers
import ConversationsMenuContainer from 'core/modules/Conversations/containers/ConversationsMenuContainer'
import ConversationSettingsMenuContainer from 'core/modules/Conversations/containers/ConversationSettingsMenuContainer'
import ConversationChannelListContainer from 'core/modules/ConversationChannels/containers/ConversationChannelListContainer'

// Components
const ConversationsListContainer = lazy(() => import('core/modules/Conversations/containers/ConversationsListContainer'))
const ConversationChannelManagePage = lazy(() => import('core/modules/ConversationChannels/pages/ConversationChannelManagePage'))
const ConversationManagePage = lazy(() => import('core/modules/Conversations/pages/ConversationManagePage'))


const ConversationsMenuRoutes = [
  {
    path: '/:username/conversations',
    component: ConversationsMenuContainer,
    routes: [
      {
        path: '/:username/conversations/settings',
        component: ConversationSettingsMenuContainer,
        routes: [
          {
            path: '/:username/conversations/settings/channels/:conversation_channels_id',
            auth: { reqAuthBiz: true },
            component: ConversationChannelListContainer,
            routes: [
              {
                path: '/:username/conversations/settings/channels/:conversation_channels_id',
                auth: { reqAuthBiz: true },
                component: suspenseComponent(ConversationChannelManagePage),
              }
            ]
          },
          {
            path: '/:username/conversations/settings/admins',
            auth: { reqAuthBiz: true },
            component: suspenseComponent(ConversationChannelManagePage),
          },{
            path: '/:username/conversations/contact',
            auth: { reqAuthBiz: true },
            component: suspenseComponent(ConversationChannelManagePage),
          }
        ]
      },
      {
        path: '/:username/conversations/:conversation_channels_id/:group/:conversation_users_id',
        component: suspenseComponent(ConversationsListContainer),
        routes: [
          {
            path: '/:username/conversations/:conversation_channels_id/:group/:conversation_users_id',
            auth: { reqAuthBiz: true },
            component: suspenseComponent(ConversationManagePage),
          }
        ]
      },
    ]
  }
]

export default ConversationsMenuRoutes

import { lazy } from 'react'
import suspenseComponent from 'utils/suspenseComponent'

// Containers
import UsersMenuContainer from 'core/modules/Users/containers/UsersMenuContainer'

// Components
const UsersListContainer = lazy(() => import('core/modules/Users/containers/UsersListContainer'))
const UserManagePage = lazy(() => import('core/modules/Users/pages/UserManagePage'))

const status = ':status(all|open|close|trash)'

const UsersMenuRoutes = [
  {
    path: '/:username/users',
    component: UsersMenuContainer,
    routes: [
      {
        path: `/:username/users/${status}/:users_id`,
        component: suspenseComponent(UsersListContainer),
        routes: [
          {
            path: `/:username/users/${status}/:users_id`,
            auth: { reqAuthBiz: true },
            component: suspenseComponent(UserManagePage),
          }
        ]
      },
    ]
  }
]

export default UsersMenuRoutes

import React, { useState, useEffect } from 'react'

// css
import css from './css/chip.css'

// Components Global
import ProfileAvatar from '../ProfileAvatar'
import AvatarIcon from '../AvatarIcon'


const Chip = (props) => {
  const { avatar, avatarIcon, chipInput } = props
  const { images, color, name } = avatar || {}
  const [value, setValue] = useState('')

  const removeChip = () => {
    //setValue('')
    if (props.remove) {
      props.remove()
    }
  }

  useEffect(() => {
    if (chipInput) {
      setValue(props.value)
    }
  }, [props.value])

  const handleChangValue = (e) => {
    const value = e.target.value
    setValue(value)

    if (chipInput.onChange) {
      chipInput.onChange(value)
    }

    if (value === '') {
      removeChip()
    }
  }

  const size = props.size || 30

  return (
    <div className={`${css.chip} ${props.className || ''} height-${size} line-height-0`}>
      <table className="table-border-none">
        <tbody>
          <tr>
            { avatar &&
              <td className={`pd-0 line-height-0 height-${size} width-${size}`}>
                <div className={`width-${size} height-${size}`}>
                  <ProfileAvatar
                    circle
                    images={images || []}
                    imageSize="m"
                    color={color}
                    texts={name}
                    textSize={'1rem'}
                  />
                </div>
              </td>
            }

            { avatarIcon &&
              <td className={`pd-0 line-height-0 height-${size} width-${size}`}>
                <div className={`width-${size} height-${size}`}>
                  <AvatarIcon
                    circle
                    iconFa={avatarIcon.iconFa}
                    icon={avatarIcon.icon}
                    iconClassName="font-1-0"
                    className="border-eee"
                    color={avatarIcon.color}
                    bgColor={avatarIcon.bgColor}
                  />
                </div>
              </td>
            }

            { chipInput ?
              <td className={`pd-0 pd-left-5 pd-right-5 height-${size}`}>
                <input
                  //autoFocus
                  data-id={chipInput.id}
                  style={{ backgroundColor: '#fff' }}
                  className="border-bottom-0 pd-left-5 box-shadow-none height-25 line-height-25 color-000"
                  value={value}
                  onChange={handleChangValue}
                />
              </td>
            :
              <td className="pd-0 pd-left-8 pd-right-10 font-0-8 line-height-13">{props.value}</td>
            }

            { !props.hiddenBtnRemove &&
              <td className={`pd-0 center width-20 text-right height-${size} pd-right-6 user-select-none`}>
                <i id="remove-chip" onClick={removeChip} className="close material-icons font-1-2 mg-left-0">close</i>
              </td>
            }
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Chip

export const getBillTotalAmountProduct = ({ items }) => {
  let totalAmountProduct = 0

  items.map(item => {
    let amountProduct = item.price * item.qty

    if (item.discount && item.discount.amount) {
      amountProduct = amountProduct - item.discount.amount
    }

    totalAmountProduct += amountProduct
  })

  return totalAmountProduct
}

export const getBillDiscountAmount = ({ data, discount }) => {
  if (!discount) {
    discount = data.discount
  }

  let discountAmount = 0

  if (discount && discount.amount) {
    discountAmount = discount.amount
  }

  return discountAmount
}

export const getBillVatAmount = ({ data, vat }) => {
  if (!vat) {
    vat = data.vat
  }

  let vatAmount = 0

  if (vat && vat.status === true) {
    if (vat.include === true) vatAmount = 0 // vat ใน
    else vatAmount = vat.amount // vat นอก
  }

  return vatAmount
}

export const getBillWhtAmount = ({ data, wht }) => {
  if (!wht) {
    wht = data.wht
  }

  let whtAmount = 0

  if (wht && wht.amount) {
    whtAmount = wht.amount
  }

  return whtAmount
}

export const getBillVat = ({ data, vat, totalNetAmountProduct }) => {
  if (!vat) {
    vat = data.vat
  }

  if (vat) {
    let amount = 0

    if (vat.status === true) {

      // vat ใน
      if (vat.include === true) {
        // VAT = ราคาสินค้า – (ราคาสินค้า x 100 ÷ 107)
        amount = totalNetAmountProduct - (totalNetAmountProduct * 100 / (100 + vat.rate))

      // vat นอก
      } else {
        amount = totalNetAmountProduct * vat.rate / 100
      }
    }


    return {
      status: vat.status,
      rate: vat.rate,
      include: vat.include,
      amount
    }
  }
}

export const getBillCalculateVales = ({ data, items, discount, vat, wht }) => {
  if (!items) {
    items = data.items
  }

  const totalAmountProduct = getBillTotalAmountProduct({ items })
  const discountAmount = getBillDiscountAmount({ data, discount })
  const whtAmount = getBillWhtAmount({ data, wht })

  let totalNetAmountProduct = totalAmountProduct - discountAmount
  let vatNew = getBillVat({ data, vat, totalNetAmountProduct })
  let vatAmount = getBillVatAmount({ data, vat })

  let totalNetAmount = totalNetAmountProduct + vatAmount
  let paymentNetAmount = totalNetAmount - whtAmount

  return {
    totalAmountProduct,
    totalNetAmountProduct,
    vat: vatNew,
    totalNetAmount,
    paymentNetAmount
  }
}



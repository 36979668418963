import React, { useRef } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../components/Modals/Modal'
import { Row, Column } from '../../../../../components/Columns'

// Components forms
import Form from '../../../../../components/Form'
import BtnSubmit from '../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../util/getFormValue'

// Components Local
import { ArticleTitle, ArticleDescriptions, ArticleHighlight } from '../ArticleForms'
import SelectTagIds from '../../../Tags/components/SelectTagIds'

// Actions Redux
import { createArticle } from '../../../../../redux/Articles/ArticlesActions'
import { AttributeInputs } from '../../../Attributes/components/AttributeInputForms'


const ArticleCreateModal = (props) => {
  const id = "articleCreateModal"
  const formRef = useRef()
  let { match, attribute, business, title } = props
  const { folders_id, username, attributes_path } = match.params

  if (!title) {
    title = "บทความ"
  }

  const handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(formRef.current)


    if (params) {
      params.folders_id = folders_id
      params.status = 'close'

      if (attribute && attribute._id) {
        params.attributes_id = attribute._id
        params.pages_id = attribute.pages_id
      }

      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id
      }

      let path = `/${username}/articles`

      if (attributes_path) {
        path = `/${username}/articles-attr/${attributes_path}`
      }

      props.dispatch(createArticle(query, {
        body: params,
        loadId: formName,
        modalId: 'articleCreateModal',
        props,
        dataRedirect: {
          url: `${path}/all/:_id/update/content`,
          resName: 'data'
        },
        toastMsg: `สร้าง${title}แล้ว`
      }))
    }
  }

  return (
    <Modal
      id={id}
      headerTitle={`สร้าง${title}`}
      headerLeft
      width="550px"
      padding="0">

      <Row className="pd-20">
        <Form
          recheckForm
          ref={formRef}
          name={id}
          submit={(e) => handleSubmit(e, id)}>

          <Column col="column col-xs-12">
            <ArticleTitle />
            <SelectTagIds
              business={business}
              type="article"
              attributes_id={attribute && attribute._id}
            />
            <ArticleHighlight />

            { attribute &&
              <AttributeInputs attribute={attribute} />
            }

            <ArticleDescriptions />
          </Column>

          <Column col="column col-xs-12 text-align-right">
            <BtnSubmit
              loadingId={id}
              icon="send"
              iconAlign="left"
              text={`สร้าง${title}`}
              className="btn btn-submit btn-shadow-none"
              btnDisabled
            />
          </Column>
        </Form>
      </Row>
    </Modal>
  )
}

export default connect()(ArticleCreateModal)

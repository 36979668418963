import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import WindowBack from '../../../../../components/WindowsV2/WindowBack'

const TestPage = (props) => {
  const { business, match, pageSlider, history } = props

  return (
    <WindowBack
      minusTotop
      title={'xxxx'}
      pageSlider={pageSlider}
      history={history}
      linkBack={'/porntawee/sales'}>

      <div className="text-right">666669</div>
    </WindowBack>
  )
}

export default connect()(TestPage)

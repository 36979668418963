import React, { Fragment } from 'react'
import InputCheckDuplicate from '../../../../../../components/Inputs/InputCheckDuplicate'

// Components Global
import InputDatePicker from "../../../../../../components/Inputs/InputDatePicker"
import InputNumeral from '../../../../../../components/Inputs/InputNumeral'
import Select from '../../../../../../components/Inputs/Select'
import Textarea from '../../../../../../components/Inputs/Textarea'

// Components Sales
import { TitleShow } from '../../../RubberSales/components/RubberSaleForms/RubberSaleForms'


// Actions
import { checkContractDuplicate } from '../../../../../../redux/Contracts/ContractsActions'


export const ContractDate = (props) => {
  return (
    <InputDatePicker
      id="date"
      required
      label="วันที่ทำสัญญา"
      dateNow
      value={props.value}
      onChange={props.onChange}
      inputClassName="height-35 line-height-35 font-0-8"
      labelClassName="font-0-8"
      bbYear
    />
  )
}

export const ContractDeadline = (props) => {
  return (
    <InputDatePicker
      id="deadline"
      required
      label="กำหนดส่งมอบภายในวันที่"
      dateNow
      value={props.value}
      onChange={props.onChange}
      inputClassName="height-35 line-height-35 font-0-8"
      labelClassName="font-0-8"
      bbYear
    />
  )
}

export const ContractNote = (props) => {
  return (
    <Textarea
      id="note"
      label="หมายเหตุ"
      placeholder="หมายเหตุ"
      inputClassName="height-35 line-height-35 font-0-8"
      inputClassNameModify="min-height-35 pd-top-0 pd-bottom-0"
      labelClassName="font-0-8"
      value={props.value}
      onChange={props.onChange}
    />
  )
}


export const ContractRubberType = (props) => {
  let options = [
    { value: 'rubber-sheet', name: 'ยางแผ่นดิบ' },
    { value: 'ribbed-smoked-sheet', name: 'ยางแผ่นรมควัน' }
  ]

  const value = props.value && options.filter(o => o.value === props.value)[0]

  return (
    <Fragment>
      { !props.disabled ?
        <Select
          id="rubberType"
          label="ประเภทยาง"
          placeholder="-- เลือกประเภท"
          value={props.value}
          default
          required
          inputClassName="height-35 font-0-8"
          labelClassName="font-0-8"
          options={options}
          onChange={props.onChange}
        />
      :
        <TitleShow
          value={value && value.name}
          type="text"
          label="ประเภทยาง"
          textAlign="left"
        />
      }
    </Fragment>
  )
}


export const ContractNetWeight = (props) => {
  const inputClassName = "height-35 line-height-35 font-0-8"
  const labelClassName =" font-0-8"

  return (
    <InputNumeral
      id="netWeight"
      label="นน.ยางสด"
      placeholder="นน.ยางสด"
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      onChange={props.onChange}
      value={props.value}
      required
      decimal={2}
    />
  )
}

export const ContractNetPrice = (props) => {
  const inputClassName = "height-35 line-height-35 font-0-8"
  const labelClassName =" font-0-8"

  return (
    <InputNumeral
      id="netPrice"
      label="ราคา/กก."
      placeholder="ราคา/กก."
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      onChange={props.onChange}
      value={props.value}
      required
      decimal={2}
    />
  )
}


export const ContractCode = (props) => {
  const { business, value, contract, onChange } = props
  const query =`businesses_id=${business._id}`

  let unselectId

  if (contract && contract._id) {
    unselectId = contract._id
  }

  return (
    <InputCheckDuplicate
      id="code"
      label="เลขที่สัญญา"
      type="code"
      value={value}
      params_business_id={business._id}
      action={checkContractDuplicate}
      useQuery={{
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id
      }}
      query={query}
      unselectId={unselectId}
      onChange={onChange}
    />
  )
}

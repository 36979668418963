// Containers
import VouchersContainer from '../../../../../containers/dashboard/Vouchers/containers/VouchersContainer'
import VoucherContainer from '../../../../../containers/dashboard/Vouchers/containers/VoucherContainer'

// Routes
import VouchersMenuRoutes from './routes/VouchersMenuRoutes'
//import VouchersUpdateRoutes from './routes/VouchersUpdateRoutes'


const VouchersRoutes = [
  {
    path: '/:username/vouchers',
    component: VouchersContainer,
    routes: [
      {
        path: '/:username/vouchers/:status(open|close)/:vouchers_id',
        component: VoucherContainer,
        auth: { reqAuthBiz: true, reqExpDate: true },
        routes: [
          //...VouchersUpdateRoutes,
        ]
      },
      ...VouchersMenuRoutes,
    ]
  }
]

export default VouchersRoutes

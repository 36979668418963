import { numberRound } from '../../util/getNumeral'


export const getRubberSystemValue = ({ productType, rubberType, saleType }) => {
  let systemType = productType
  let id, name

  if (systemType === 'field-latex') {
    id = "field-latex-sell-on-cash"
    name = "ขายน้ำยางสด"

  } else if (systemType === 'rubber-sheet') {
    if (saleType === 'make') {
      id = "rubber-sheet-make"
      name = "ทำยางแผ่น"
    } else if (saleType === 'sell-on-cash') {
      id = "rubber-sheet-sell-on-cash"
      name = "ขายยางแผ่น (ขายสด)"
    } else if (saleType === 'deposit') {
      id = "rubber-sheet-deposit"
      name = "ขายยางแผ่น (ฝากยาง)"
    } else if (saleType === 'contract') {
      id = "rubber-sheet-contract"
      name = "ขายยางแผ่น (ล่วงหน้า)"
    }
  } else if (systemType === 'rubber-sheet-make') {
    id = "rubber-sheet-make"
    name = "ทำยางแผ่น"
  }

  return {
    id,
    name
  }
}

export const getProductTypesDefault = () => {
  return [
    { code: 'rubber-sheet', name: 'ยางแผ่น', saleName: 'ยางแผ่น', appName: 'ขายยางแผ่น', icon: 'fas fa-layer-group', main: true },
    { code: 'rubber-sheet-make', name: 'ทำยางแผ่น', saleName: 'ทำยางแผ่น', appName: 'ทำยางแผ่น', icon: 'fas fa-scroll', main: false },
    { code: 'field-latex', name: 'น้ำยางสด', saleName: 'ขายน้ำยางสด', appName: 'ขายน้ำยางสด', icon: 'fas fa-glass-whiskey', main: true },
    { code: 'cup-lump', name: 'เศษยาง', saleName: 'ขายเศษยาง', appName: 'ขายเศษยาง', icon: 'grain', main: true },
  ]
}

export const getRubberTypesDefault = () => {
  return [
    { code: 'rubber-sheet', name: 'ยางแผ่นดิบ', nameShort: 'แผ่นดิบ', icon: 'fas fa-layer-group', color: '#9c27b0' },
    { code: 'ribbed-smoked-sheet', name: 'ยางแผ่นรมควัน', nameShort: 'แผ่นรมควัน', icon: 'fas fa-dumpster-fire', color: '#00bcd4' },
    { code: 'field-latex', name: 'น้ำยางสด', nameShort: 'น้ำยาง', icon: 'fas fa-glass-whiskey', color: '#2196f3' },
    { code: 'cup-lump', name: 'เศษยาง', nameShort: 'เศษยาง', icon: 'grain' }
  ]
}

export const getRubberSheetTypesDefault = () => {
  const items = [
    { code: 'rubber-sheet', name: 'ยางแผ่นดิบ', icon: 'fas fa-layer-group' },
    { code: 'ribbed-smoked-sheet', name: 'ยางแผ่นรมควัน', icon: 'fas fa-dumpster-fire' },
  ]

  return items
}

export const getRSTypesDefault = () => {
  return [
    { value: 'rs-good', name: 'คุณภาพดี', nameShort: 'คุณภาพดี', group: 'rs' },
    { value: 'rs-3-5', name: 'ความชื้น 3-5%', nameShort: 'ชื้น 3-5%', group: 'rs' },
    { value: 'rs-5-7', name: 'ความชื้น 5-7%', nameShort: 'ชื้น 5-7%', group: 'rs' },
    { value: 'rs-7-10', name: 'ความชื้น 7-10%', nameShort: 'ชื้น 7-10%', group: 'rs' },
    { value: 'rs-10-15', name: 'ความชื้น 10-15%', nameShort: 'ชื้น 10-15%', group: 'rs' },
  ]
}

export const getRSSTypesDefault = () => {
  return [
    { value: 'rss-3', name: 'รมควันชั้น 3', nameShort: 'ชั้น 3', group: 'rss'  },
    { value: 'rss-4', name: 'รมควันชั้น 4', nameShort: 'ชั้น 4', group: 'rss'  },
    { value: 'rss-5', name: 'รมควันชั้น 5', nameShort: 'ชั้น 5', group: 'rss'  },
    { value: 'rss-bubbles', name: 'รมควันฟองอากาศ', nameShort: 'ฟองอากาศ', group: 'rss' },
    { value: 'rss-cutting', name: 'รมควันคัดติ้ง', nameShort: 'คัดติ้ง', group: 'rss'  }
  ]
}

export const getProductTypeValue = (code) => {
  let types = getProductTypesDefault()
  let type = types.filter(v => v.code === code)[0]

  return type
}

export const getRubberSheetTypeValue = (code) => {
  let rubbers = getRubberSheetTypesDefault()
  let defaultValue = rubbers.filter(v => v.code === code)[0]

  return defaultValue
}

export const getRubberTypeValue = (code) => {
  let rubbers = getRubberTypesDefault()
  let rubber = rubbers.filter(v => v.code === code)[0]

  return rubber
}

export const getRubberType2Value = (code) => {
  let RS = getRSTypesDefault()
  let RSS = getRSSTypesDefault()
  let rubbers = [...RS, ...RSS]
  let rubber = rubbers.filter(v => v.value === code)[0]

  return rubber
}



export const getRubberSettingTypeValue = ({ setting, code }) => {
  let rubbers = getProductTypesDefault()
  let defaultValue = rubbers.filter(v => v.code === code)[0]
  let typeSetting = setting.types.filter(type => type.code === code)[0]

  return {
    ...typeSetting,
    defaultValue
  }
}

export const settingTypeValue = ({ type, lang='local'}) => {
  let nameLang

  if (type) {
    const defaultType = getRubberDefaultType(type.code)
    nameLang = type.name ? type.name[lang] : null

    return {
      ...type,
      nameLang,
      defaultType
    }
  }
}

export const settingWarehouseValue = ({ warehouse, lang='local'}) => {
  let nameLang

  if (warehouse) {
    nameLang = warehouse.name ? warehouse.name[lang] : null

    return {
      ...warehouse,
      nameLang,
    }
  }
}

export const getRubberType = ({ types, types_id }) => {
  return types.filter(type => type._id === types_id)[0]
}

export const getRubberDefaultType = (code) => {
  const types = getProductTypesDefault()

  return types.filter(type => type.code === code)[0]
}

export const convertDecimal = ({ number, name, type }) => {
  const { digit, round } = type[name]

  return numberRound({ number, digit, round })
}


import React, { Fragment, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'


// Components POS
import { InputKeyDefault } from '../../components/InputKeyboard/InputKeyboard'

// img
import SelectProductsListModal from '../../components/SelectProductsListModal/SelectProductsListModal'

// Actions
import { openModal } from '../../../../../redux/App/AppActions'

// Components Local
import MenuAndKeyboard from './components/MenuAndKeyboard'
import POSManage from './components/POSManage'
import OrderItemsAllManage from './components/OrderItemsAllManage'

import { createOrder, fetchOrders, updateOrder } from '../../../../../redux/OrdersDrafts/OrdersDraftsActions'
import { getNumeral } from '../../../../../util/getNumeral'
import ModalRoutesContainerV2 from '../../../../../components/ModalRoutesContainerV2/ModalRoutesContainerV2'

// Values
import { getBillCalculateVales } from '../../../../../redux/Bills/BillsCommonValues'


const POSPage = (props) => {
  const itemsManage = useRef()
  const [keyboardValues, setKeyboardValues] = useState([])
  const { ordersDrafts, business, match, location } = props
  const { username, orders_drafts_id } = match.params
  const orderDraft = ordersDrafts.filter(order => order._id === orders_drafts_id)[0]
  const keyboard = useRef()

  useEffect(() => {
    fetchDaftOrders()
  }, [])

  useEffect(() => {
    if (location && location.pathname) {
      if (location.pathname.search('/payment/successed') === -1) {
        if (orderDraft && orderDraft.status !== 'draft') {
          createDraftOrder({})
        }
      }
    }
  }, [
    location && location.pathname,
    orderDraft && orderDraft.status
  ])

  const fetchDaftOrders = () => {
    let where

    if (location.pathname.search('/payment/successed') === -1) {
      where = 'status=draft'
    }

    const query = {
      authName: 'admin',
      businesses_id: business._id,
      dbNo: business.dbNo
    }

    props.dispatch(fetchOrders(query, {
      body: {
        where,
        sort: '-_id'
      },
      loadId: 'draftOrdersList',
      useDB: 'client',
    })).then(res => {
      if (res.success) {
        if (res.data.length === 0) {
          createDraftOrder({})
        } else {
          if (!orders_drafts_id || orders_drafts_id === 'all') {
            props.history.push(`/${username}/pos/sales/${res.data[0]._id}`)
          }
        }
      }
    })
  }

  const createDraftOrder = ({ toastMsg, confirmId }) => {
    const params = {
      status: "draft",
      createSystemBy: 'pos'
    }

    if (business.vat && business.vat.status === true) {
      params.vat = {
        status: true,
        include: business.vat.include,
        rate: business.vat.rate
      }
    }

    const query = {
      authName: 'admin',
      businesses_id: business._id,
      dbNo: business.dbNo
    }

    props.dispatch(createOrder(query, {
      body: params,
      loadId: 'createHoldOrder',
      useDB: 'client',
      props,
      dataRedirect: {
        url: `/${username}/pos/sales/:_id`,
        resName: 'data'
      },
      confirmId,
      toastMsg
    }))
  }

  const handleItemChange = (item) => {
    itemsManage.current.addItem(item)
  }

  const handleRemoveManyItems = (item) => {
    itemsManage.current.removeManyItems(item)
  }

  const handleKeyboardChange = (keyboardValues) => {
    setKeyboardValues(keyboardValues)
  }

  const barcode = keyboard && keyboard.current && keyboard.current.getValue('barcode')
  const [totalAmountProduct, setTotalAmountProduct] = useState()
  const [totalNetAmountProduct, setTotalNetAmountProduct] = useState()
  const [vat, setVat] = useState()
  const [totalNetAmount, setTotalNetAmount] = useState()
  const [paymentNetAmount, setPaymentNetAmount] = useState()

  useEffect(() => {
    if (orderDraft) {
      const {
        totalAmountProduct,
        totalNetAmountProduct,
        vat,
        totalNetAmount,
        paymentNetAmount
      } = getBillCalculateVales({ data: orderDraft })

      setTotalAmountProduct(totalAmountProduct)
      setTotalNetAmountProduct(totalNetAmountProduct)
      setVat(vat)
      setTotalNetAmount(totalNetAmount)
      setPaymentNetAmount(paymentNetAmount)
    }
  }, [
    orderDraft && JSON.stringify(orderDraft.items)
  ])

  useEffect(() => {
    if (totalAmountProduct !== undefined &&
        totalNetAmountProduct !== undefined &&
        vat !== undefined &&
        totalNetAmount !== undefined &&
        paymentNetAmount !== undefined
    ) {

      update({
        totalAmountProduct,
        totalNetAmountProduct,
        vat,
        totalNetAmount,
        paymentNetAmount
      })
    }
  }, [
    totalAmountProduct,
    totalNetAmountProduct,
    vat,
    totalNetAmount,
    paymentNetAmount
  ])

  const update = (params={}) => {
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id,
      orders_id: orderDraft._id
    }

    props.dispatch(updateOrder(query, {
      body: params,
      loadId: 'formName',
      useDB: 'client'
    }))
  }

  return (
    <Fragment>
      { orderDraft &&
        <Fragment>
          <div className="pst-absolute left-0 right-0 top-0 bottom-0" style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="pst-relative width-full border-right-1 border-right-ddd bg-fff">

              <div className="pst-absolute left-0 right-0 top-0 bottom-0">

                {/* header */}
                <div className="height-70 pst-absolute left-0 right-0 top-0 bg-fff" style={{ display: 'flex', flexDirection: 'row' }}>

                  <div className="pd-8" style={{ width: '30%' }}>
                    <div  className="border-ddd width-full height-full border-radius-5" style={{ display: 'flex', flexDirection: 'row' }}>
                      <div
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        className="width-60 border-right-ddd center">

                        <i className="fas fa-user-circle font-1-8 i-middle"></i>
                      </div>
                      <div
                        style={{ display: 'flex', alignItems: 'center' }}
                        className="width-full pd-left-15">
                        ลูกค้า
                      </div>
                    </div>
                  </div>

                  <div className="pd-8" style={{ width: '50%' }}>
                    <InputKeyDefault
                      className="height-full"
                      focus={keyboard && keyboard.current && keyboard.current.focus('barcode')}
                      onClick={() => keyboard.current.handleFocus('barcode')}>

                      <div className="width-50 center pd-top-2">
                        <i className="fas fa-barcode font-1-8 i-middle"></i>
                      </div>

                      <div className="width-full pd-left-15 pd-top-2">
                        { barcode ? barcode : <span className="color-c0c0c0">รหัสสินค้า</span> }
                      </div>
                    </InputKeyDefault>
                  </div>

                  <div className="pd-8" style={{ width: '20%' }}>
                    <a
                      onClick={() => props.dispatch(openModal('selectProductsListModal'))}
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      className="btn btn-white height-full border-radius-5 width-full border-ddd btn-shadow-none">

                      <i className="fab fa-product-hunt font-1-8 left"></i> เลือกสินค้า
                    </a>

                    { itemsManage &&
                      <SelectProductsListModal
                        {...props}
                        id="selectProductsListModal"
                        order={orderDraft}
                        getItem={handleItemChange}
                        removeManyItems={handleRemoveManyItems}
                      />
                    }
                  </div>

                  <div className="pd-8" style={{ width: '550px' }}>
                    <div className="bg-000 text-right height-full pd-right-15 border-radius-5">
                      <div className="font-2-5" style={{ color: '#00ff0a' }}>{getNumeral(orderDraft.totalAmountProduct, 'auto')}</div>
                    </div>
                  </div>
                </div>
                {/* header */}

                {/* body */}
                <div className="pst-absolute left-0 right-0 top-70 bottom-30 border-top-ddd" style={{ background: '#f5f5f5' }}>
                  <OrderItemsAllManage
                    {...props}
                    ref={itemsManage}
                    orderDraft={orderDraft}
                    keyboard={keyboard}
                    keyboardValues={keyboardValues}
                  />
                </div>
                {/* body */}

                {/* footer */}
                <div className="pst-absolute border-top-ddd bg-fff height-30 left-0 right-0 bottom-0">
                  <POSManage />
                </div>
                {/* footer */}
              </div>
            </div>

            <div className="pd-10 overflow-auto" style={{ width: '400px', display: 'flex', flexDirection: 'column' }}>
              <MenuAndKeyboard
                {...props}
                keyboardRef={keyboard}
                ordersDrafts={ordersDrafts.filter(order => order.status === 'draft')}
                orderDraft={orderDraft}
                createDraftOrder={createDraftOrder}
                handleKeyboardChange={handleKeyboardChange}
                keyboardValues={keyboardValues}
              />
            </div>
          </div>

          <ModalRoutesContainerV2
            {...props}
            order={orderDraft}
            pathClose={`/${business.username}/pos/sales/${orderDraft._id}`}
            pathUseModal={`/${business.username}/pos/sales/${orderDraft._id}/payment`}
            modalClassName="border-radius-5 bg-f5f5f5"
            margin={10}
            width={900}
          />

          <ModalRoutesContainerV2
            {...props}
            order={orderDraft}
            pathClose={`/${business.username}/pos/sales/${orderDraft._id}`}
            pathUseModal={`/${business.username}/pos/sales/${orderDraft._id}/histories`}
            modalClassName="border-radius-5 bg-f5f5f5 height-full"
            margin={10}
            width={1000}
          />
        </Fragment>
      }
    </Fragment>
  )
}

const mapStateToProps = (store, props) => {
  return {
    ordersDrafts: store.ordersDrafts.data
  }
}

export default connect(mapStateToProps)(POSPage)







// Containers
import SellOnlineContainer from '../../../../../containers/dashboard/SellOnline/containers/SellOnlineContainer'

// Routes
import SellOnlineMenuRoutes from './routes/SellOnlineMenuRoutes'


const SellOnlineRoutes = [
  {
    path: '/:username/sell-online',
    component: SellOnlineContainer,
    routes: [
      ...SellOnlineMenuRoutes
    ]
  }
]

export default SellOnlineRoutes

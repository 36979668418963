import React, { Component } from 'react'
import { connect } from 'react-redux'
import Scrollbar from 'react-perfect-scrollbar'

// Components Global
import FetchListItems from '../../../../../../components/FetchListItems'

// Component Local
import { BillItem } from './BillItem'


// Actions Redux
import { fetchBills } from '../../../../../../redux/Bills/BillsActions'


const BillsListItems = (props) => {
  const { business, openSidebar, match } = props
  const { type, bills_id } = match.params
  const icon = 'receipt'

  let where, countWhere, title

  if (type === 'all') {
    title = 'เอกสารทั้งหมด'
  } else {

    if (type === 'quotation') {
      title = 'ใบเสนอราคา'
    } else if (type === 'invoice') {
      title = 'ใบวางบิล'
    } else if (type === 'receipt') {
      title = 'ใบเสร็จรับเงิน'
    }

    where = `type=${type}`
    countWhere = `type=${type}`
  }

  const fetchAction = {
    action: fetchBills,
    actionQuery: {
      authName: 'admin',
      dbNo: business.dbNo,
      businessType: business.type,
      businesses_id: business._id
    },
    id: business._id,
    where,
    countWhere,
    limit: 25,
    sort: '-_id',
    searchAttributes: [
      { name: 'code', options: 'i' },
      { name: 'order.customer.firstName', options: 'i' },
      { name: 'order.customer.lastName', options: 'i' },
      { name: 'order.customer.firstName', join: 'customer.lastName', options: 'i' },
      { name: 'order.customer.phone.phoneNumber', options: 'i' },
      { name: 'order.customer.phone.dialingCode', join: 'customer.phone.phoneNumber', options: 'i' },
    ]
  }

  return (
    <div style={{ position: 'absolute', top: '0', left: '0', bottom: '0', right: '0' }}>
      <Scrollbar>
        <FetchListItems
          {...props}
          id="billsList"
          storeName="bills"
          tableClassName=""
          title={title}
          icon={icon}
          fetchAction={fetchAction}
          componentList={BillItem}
          columnTitle={{
            column1: 'column col-xxs-12',
            column2: 'column col-xxs-12 pd-top-10'
          }}
          notReloadForUrlChange
          dataReloadForUrlChange={type}
          openSidebar={openSidebar}
        />
      </Scrollbar>
    </div>
  )
}

export default connect()(BillsListItems)

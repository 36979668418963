import React, { Component } from 'react'

// Components Global
import TextInput from '../../../../../components/Inputs/TextInput'
import Textarea from '../../../../../components/Inputs/Textarea'
import InputRemote from '../../../../../components/Inputs/InputRemote'
import InputFolder from '../../../../../components/Inputs/InputFolder'

// Actions
import { checkPathCreateFolder } from '../../../../../redux/Folders/FoldersActions'


export const FoldersId = (props) => {
  const lang = props.lang || 'local'

  return (
    <InputFolder
      business={props.business}
      labelHide={props.update}
      label="หมวดหมู่"
      id="folders_id"
      folderType="product"
      value={props.value}
      placeholder="หมวดหมู่"
      required
    />
  )
}


export const FolderName = (props) => {
  const lang = props.lang || 'local'

  return (
    <TextInput
      labelHide={props.update}
      label="ชื่อ"
      id="name.local"
      value={props.value && props.value[lang]}
      placeholder="ชื่อ"
      inputType="text"
      type="text"
      required
      iconValid
    />
  )
}

export const FolderDescriptions = (props) => {
  const lang = props.lang || 'local'

  return (
    <Textarea
      labelHide={props.update}
      label="รายละเอียด"
      placeholder="รายละเอียด"
      id="descriptions.local"
      value={props.value && props.value[lang]}
      iconValid
    />
  )
}

export class FolderPath extends Component {
  render() {
    const { business, folder, type } = this.props
    let query

    if (folder && folder._id) {
      query =`businesses_id=${business._id}&folders_id=${folder._id}&type=${folder.type}&path=`
    } else {
      query =`businesses_id=${business._id}&type=${type}&path=`
    }

    return (
      <div>
        <InputRemote
          disabled={this.props.disabled}
          required
          iconValid
          label="ชื่อ URL"
          id="path"
          value={this.props.value}
          placeholder="ชื่อ URL"
          type="regex"
          onChange={this.props.onChange}
          regex={{
            pattern: /^([\A-Za-z0-9]+((?:\-[\A-Za-z0-9]+)*)){3}$/i,
            msg: 'ไม่ถูกต้อง ต้องเป็น ภาษาอังกฤษหรือตัวเลข มีอย่างน้อย 3 ตัวอักษร'
          }}
          remote={{
            action: checkPathCreateFolder,
            query,
            msgLoading: 'กำลังตรวจสอบ...',
            msgDuplicate: 'ชื่อ URL นี้ คุณใช้งานแล้ว ไม่สามารถใช้ซ้ำได้',
            msgNone: 'ชื่อ URL นี้ สามารถใช้งานได้',
            dataSuccess: null
          }}
        />
      </div>
    )
  }
}




import React, { Component } from 'react'
import { connect } from 'react-redux'
import cuid from 'cuid'

// Components Global
import Modal from '../../../../../components/Modals/Modal'
import { Row, Column } from '../../../../../components/Columns'

// Components forms
import FormValidation from '../../../../../components/FormValidation'
import BtnSubmit from '../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../util/getFormValue'

// Components Services
import { PageName } from '../PageForms'

// Actions Redux
import { createPage } from '../../../../../redux/Pages/PagesActions'


class PageTemplateCreateModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(this.refs[formName])
    const { business, site, templateType } = this.props

    if (params) {
      params.path = cuid()
      params.type = "template"
      params.templateType = templateType
      params.sites_id = site._id

      this.props.dispatch(createPage(business._id, site._id, {
        body: params,
        loadId: formName,
        modalId: formName,
        props: this.props,
        toastMsg: 'เพิ่มรูปแบบแล้ว'
      }))
    }
  }


  render() {
    const { templateType } = this.props
    const id = `${templateType}-pageTemplateCreateModal`

    return (
      <Modal
        id={id}
        headerTitle="เพิ่มรูปแบบเทมเพลต"
        headerLeft
        blockBgModal
        width="450px">

        <Row className="pd-20 pd-top-30">
          <FormValidation
            recheckForm
            ref={id}
            name={id}
            submit={(e) => this.handleSubmit(e, id)}>

            <Column col="column col-xs-12">
              <PageName label="ชื่อรูปแบบเทมเพลต" />
            </Column>

            <Column col="column col-xs-12 text-right">
              <BtnSubmit
                loadingId={id}
                icon="send"
                iconAlign="left"
                text="เพิ่มรูปแบบ"
                className="waves-effect waves-light btn btn-submit btn-shadow-none"
                btnDisabled
              />
            </Column>
          </FormValidation>
        </Row>
      </Modal>
    )
  }
}

export default connect()(PageTemplateCreateModal)

import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'

// Componets Global
import { Row, Column } from '../../../../../../components/Columns'
import Modal from '../../../../../../components/Modals/Modal'
import FormValidation from '../../../../../../components/Form'
import BtnSubmit from '../../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../../util/getFormValue'
import Switch from '../../../../../../components/Inputs/Switch'


// Components Local
import {
  CustomerName,
  CustomerMobile,
  CustomerAddress,
  CustomerTaxId,
  CustomerNote
} from '../RubberCustomerForms'

// Actions Redux
import { createCustomer, updateCustomer } from '../../../../../../redux/Customers/CustomersActions'


const RubberCustomerCreateAndUpdateModal = (props) => {
  const formRef = useRef()
  const [data, setData] = useState('')
  const { id, business, dispatch, customer, event } = props

  let title

  if (event === 'create') {
    title = 'เพิ่มโรงงาน/ผู้ซื้อ'
  } else {
    title = 'แก้ไขโรงงาน/ผู้ซื้อ'
  }


  const handleSubmit = async (e, formName) => {
    e.preventDefault()
    const params = getFormValue(formRef.current)
    params.type = 'buyer'

    if (params) {
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id,
        customers_id: customer && customer._id
      }

      // carete
      if (event === 'create') {
        dispatch(createCustomer(query, {
          body: params,
          loadId: formName,
          modalId: formName,
          toastMsg: `${title}แล้ว`
        })).then(res => {
          if (res.success) {
            if (props.onSelect) {
              props.onSelect(res.data)
            } else {
              props.history.push(`/${business.username}/rubber-customer-buyers/${res.data._id}`)
            }
          }
        })

      // update
      } else if (event === 'update') {
        dispatch(updateCustomer(query, {
          body: params,
          loadId: formName,
          modalId: formName,
          toastMsg: `${title}แล้ว`
        }))
      }
    }
  }

  return (
    <Modal
      id={id}
      headerTitle={title}
      headerLeft
      width="550px">

      <div className="pd-20">
        <FormValidation
          ref={formRef}
          name={props.id}
          recheckForm
          dataChange={{
            data: data && JSON.stringify(data)
          }}
          submit={(e) => handleSubmit(e, props.id)}>

          <Row>
            <Column col="column col-xxs-12 col-xs-12 col-sm-6">
              <CustomerName
                event={event}
                customer={customer}
                value={customer && customer.name}
                business={business}
                onChange={value => setData(value)}
              />
            </Column>

            <Column col="column col-xxs-12 col-xs-12 col-sm-6 pd-left-20-sm pd-left-20-md pd-left-20-lg pd-left-20-xlg">
              <CustomerMobile value={customer && customer.mobile} />
            </Column>
          </Row>

          <Row>
            <Column col="column col-xs-12">
              <CustomerAddress value={customer && customer.address} />
            </Column>
            <Column col="column col-xs-12">
              <CustomerTaxId value={customer && customer.taxId} />
            </Column>
            <Column col="column col-xs-12">
              <CustomerNote value={customer && customer.note} />
            </Column>
          </Row>

          <div>
            <div className="border-1 border-efefef border-radius-5 pd-10 pd-left-15 mg-bottom-10">
              <table>
                <tbody>
                  <tr>
                    <td className="pd-0">รับซื้อน้ำยางสด</td>
                    <td className="pd-0 text-right pd-right-10">
                      <Switch
                        id="fieldLatex"
                        value={customer && customer.fieldLatex ? 'true' : 'false'}
                        valueOpen="true"
                        valueOff="false"
                        label="รับซื้อน้ำยางสด"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="border-1 border-efefef border-radius-5 pd-10 pd-left-15 mg-bottom-20">
              <table>
                <tbody>
                  <tr>
                    <td className="pd-0">รับซื้อยางแผ่น</td>
                    <td className="pd-0 text-right pd-right-10">
                      <Switch
                        id="rubberSheet"
                        value={customer && customer.rubberSheet ? 'true' : 'false'}
                        valueOpen="true"
                        valueOff="false"
                        label="รับซื้อยางแผ่น"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <Row>
            <Column col="column col-xs-12 text-align-right">
              <BtnSubmit
                loadingId={props.id}
                icon="send"
                iconAlign="left"
                text={title}
                className="btn btn-success btn-shadow-none height-35 line-height-35"
                btnDisabled
              />
            </Column>
          </Row>
        </FormValidation>
      </div>
    </Modal>
  )
}

export default connect()(RubberCustomerCreateAndUpdateModal)

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../components/Modals/Modal'
import { Row, Column } from '../../../../../components/Columns'

// Components forms
import Form from '../../../../../components/Form'
import BtnSubmit from '../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../util/getFormValue'

// Components Local
import {
  ProductName,
  ProductCode,
  ProductStockAdjust,
  ProductItemChoice,
  ProductDescriptions,
  ProductItemSingle
} from '../ProductForms'

// Components Local
import { FoldersId } from '../../../Folders/components/FolderForms'
import { AttributesId } from '../../../Attributes/components/AttributeForms'
import { AttributeInputs } from '../../../Attributes/components/AttributeInputForms'

// Actions Redux
import { createProduct } from '../../../../../redux/Products/ProductsActions'

// Value
import { attributeValue } from '../../../../../redux/Attributes/AttributesValues'


class ProductCreateModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stockAdjust: true,
      data: ''
    }
  }

  handleCodeChange = (data) => {
    this.setState({ data })
  }

  handleStockAdjustChange = (value) => {
    if (value === 'true') value = true
    else if (value === 'false') value = false

    this.setState({ stockAdjust: value })
  }

  handleAttrIdChange = (attributes_id) => {
    this.setState({ attributes_id })
  }

  handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(this.refs[formName])
    const { match, business } = this.props
    const { folders_id } = match.params

    if (params) {
      params.type = 'product'
      params.subtype = 'product'

      if (folders_id) {
        params.folders_id = folders_id
      }

      if (!params.attributes_id) {
        params.attributes_id = undefined
      }

      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businesses_id: business._id
      }

      this.props.dispatch(createProduct(query, {
        body: params,
        loadId: formName,
        modalId: 'productCreateModal',
        props: this.props,
        redirect: {
          url: `/${business.username}/products/v2`,
        },
        toastMsg: 'เพิ่มสินค้าแล้ว'
      }))
    }
  }

  render() {
    const id = "productCreateModal"
    const { data, attributes_id } = this.state
    const { business, attributes, match } = this.props
    const { folders_id } = match.params
    const { productsSettings } = business

    let attribute

    if (attributes_id) {
      attribute = attributes.filter(attribute => attribute._id === attributes_id)[0]
      attribute = attributeValue({ attribute })
    }

    return (
      <Modal
        id={id}
        headerTitle="เพิ่มสินค้า"
        headerLeft
        width="650px"
        padding="0">

        { productsSettings &&
          <Row className="pd-20">
            <Form
              recheckForm
              ref={id}
              name={id}
              dataChange={JSON.stringify(data)}
              submit={(e) => this.handleSubmit(e, id)}>

              <Column col="column col-xs-12">
                <ProductName />

                { productsSettings.code.use && productsSettings.code.createShow &&
                  <ProductCode business={business} onChange={this.handleCodeChange} />
                }

                { !folders_id && productsSettings.folder.use && productsSettings.folder.createShow &&
                  <FoldersId {...this.props} />
                }

                { productsSettings.stockAdjust.use ?
                  <ProductStockAdjust onChange={this.handleStockAdjustChange} value={true} />
                :
                  <ProductItemSingle
                    setStockAdjust={productsSettings.stockAdjust}
                    setBarcode={productsSettings.barcode}
                    setWeight={productsSettings.weight}
                  />
                }

                { productsSettings.itemChoice.use &&
                  <ProductItemChoice
                    type="single"
                    value="single"
                    business={business}
                    stockAdjust={this.state.stockAdjust}
                    onChange={this.handleStockAdjustChange}
                    setStockAdjust={productsSettings.stockAdjust}
                    setBarcode={productsSettings.barcode}
                    setWeight={productsSettings.weight}
                  />
                }

                { productsSettings.attribute.use && productsSettings.attribute.createShow &&
                  <Fragment>
                    <AttributesId {...this.props} type="product" onChange={this.handleAttrIdChange} />

                    { attribute &&
                      <AttributeInputs attribute={attribute} />
                    }
                  </Fragment>
                }

                <ProductDescriptions />
              </Column>

              <Column col="column col-xs-12 text-align-right">
                <BtnSubmit
                  loadingId={id}
                  icon="send"
                  iconAlign="left"
                  text="เพิ่มสินค้า"
                  className="waves-effect waves-light btn btn-submit btn-shadow-none"
                  btnDisabled
                />
              </Column>
            </Form>
          </Row>
        }
      </Modal>
    )
  }
}

function mapStateToProps(store, props) {
  return {
    attributes: store.attributes.data
  }
}

export default connect(mapStateToProps)(ProductCreateModal)

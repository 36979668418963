import React from 'react'
import renderRoutes from '../../../../routes/renderRoutes'

// Components Global
import Window from '../../../../components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../components/MenuSidebarResponsive'

// imgs
import titleIcon from '../../Businesses/resources/icons/vouchers.png'


const menuItems = (params) => {
  return [
    {
      name: 'คูปองส่วนลด',
      title: true
    },
    {
      name: 'คูปองเปิดใช้งาน',
      nameShort: 'เปิดใช้งาน',
      icon: 'public',
      link: `/${params.username}/vouchers`,
      exact: true
    },
    {
      name: 'คูปองปิดใช้งาน',
      nameShort: 'ปิดใช้งาน',
      icon: 'lock',
      link: `/${params.username}/vouchers/close`
    },
    {
      name: 'คูปองที่ถูกลบ',
      nameShort: 'ลบแล้ว',
      icon: 'delete',
      link: `/${params.username}/vouchers/trash`
    },
  ]
}


const VouchersMenuContainer = (props) => {
  return (
    <Window
      title="คูปองส่วนลด"
      titleIcon={titleIcon}
      linkClose={`/${props.match.params.username}`}
      linkHelp="https://www.youtube.com/playlist?list=PLHuj62tDAxzP837HaBgfO75eL3TUbL95H">

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive
            btnAddModal={{
              modalId: 'voucherCreateModal',
              text: 'เพิ่มคูปอง',
            }}
            items={menuItems(props.match.params)}
          />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: props.route.routes,
            extraProps: { business: props.business },
            location: props.location,
          }) }
        </Layouts2>
      </LayoutsTwo>
    </Window>
  )

}

export default VouchersMenuContainer

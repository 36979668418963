import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../components/Modals/Modal'
import { Row, Column } from '../../../../../components/Columns'

// Components Forms
import Form from '../../../../../components/Form'
import BtnSubmit from '../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../util/getFormValue'

// Componets Local
import {
  BusinessName,
  BusinessUsername,
  BusinessType,
  BusinessPhone
} from '../BusinessForms'

// Actions Redux
import { createBusiness } from '../../../../../redux/Businesses/BusinessesActions'


class BusinessCreateModal extends Component {
  handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(this.refs[formName])

    if (params) {
      this.props.dispatch(createBusiness({
        body: params,
        loadId: formName,
        modalId: 'businessCreateModal',
        props: this.props,
        redirect: {
          url: `/`,
          element: 'username'
        },
        toastMsg: 'สร้างธุรกิจแล้ว'
      }))
    }
  }

  render() {
    const id = "businessCreateModal"

    return (
      <Modal
        id={id}
        headerTitle="สร้างระบบจัดการธุรกิจ"
        headerDescription="กรอกรายละเอียดเกี่ยวกับกิจการของคุณ เพื่อสร้างระบบจัดการธุรกิจ"
        width="580px"
        padding="30px">

        <Row>
          <Form
            recheckForm
            ref={id}
            name={id}
            submit={(e) => this.handleSubmit(e, id)}>

            <Column col="column col-xs-12">
              <BusinessUsername />
              <BusinessName />
              <BusinessType />
              <BusinessPhone
                value={{
                  code: '+66',
                  number: ''
                }}
              />
            </Column>

            <Column col="column col-xxs-12">
              <span className="font-0-9">
                การคลิก "สร้างธุรกิจ" แสดงว่าคุณยอมรับ <a>เงื่อนไขการใช้บริการ</a> และ <a>นโยบายความเป็นส่วนตัว</a>
              </span>
            </Column>

            <Column col="column col-xs-12 mg-top-10 text-align-right">
              <BtnSubmit
                loadingId={id}
                icon="send"
                iconAlign="left"
                text="สร้างธุรกิจ"
                className="waves-effect waves-light btn btn-submit btn-shadow-none"
                btnDisabled
              />
            </Column>
          </Form>
        </Row>
      </Modal>
    )
  }
}

export default connect()(BusinessCreateModal)

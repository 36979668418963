import React from 'react'

// Components Local
import OrderItemsDisplay from './components/OrderItemsDisplay/OrderItemsDisplay'
import OrderSummaryDisplay from './components/OrderSummaryDisplay/OrderSummaryDisplay'


const OrderDetailsPage = (props) => {
  const { order } = props

  return (
    <div>
      { order &&
        <div>
          <OrderItemsDisplay order={order} />
          <OrderSummaryDisplay order={order} />
        </div>
      }
    </div>
  )
}

export default OrderDetailsPage

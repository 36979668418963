import React, { Component } from 'react'
import cuid from 'cuid'

// css
import css from './css/selectData.css'

// Components Global
import InputSelectData from '../InputSelectData'
import Required from '../Required'


class SelectData extends Component {
  constructor(props) {
    super(props)

    const id = this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()

    this.state = {
      id: randomId+id,
      value: ''
    }
  }

  componentDidMount() {
    this.setState({
      value: this.props.value || '',
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value ? this.props.value : ''
      })
    }
  }

  getValue = (value) => {
    this.setState({ value })
  }

  render() {
    const { datas } = this.props

    return (
      <div className={css.inputPhoneNumber}>
        <Required
          id={this.state.id}
          label={this.props.label}
          type="text"
          value={this.state.value}
          required={this.props.required}
          iconValid={this.props.iconValid}>

          <div>
            <InputSelectData
              avatar
              showLabel
              notSave
              id={`${this.props.id}`}
              classId={this.state.id}
              placeholder={this.props.placeholder}
              value={this.state.value}
              datas={datas}
              getValue={this.getValue}
              required={this.props.required}
            />

            <input
              type="hidden"
              data-id={`${this.props.id}`}
              data-label={this.props.useLabel && this.props.label}
              data-use-attr={this.props.useAttr}
              data-use-attr-id={this.props.useAttrId}
              value={this.state.value}
              data-not-save={this.props.notSave}
            />
          </div>
        </Required>
      </div>
    )
  }
}

export default SelectData

import React from 'react'
import renderRoutes from '../../../../../routes/renderRoutes'
import { connect } from 'react-redux'

// Components Global
import Window from '../../../../../components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../../components/MenuSidebarResponsive'

// imgs
import titleIcon from '../resources/imgs/rubber-payments.png'


const menuItems = (params) => {
  const { username } = params
  const path = `/${username}/rubber-payments`

  return [
    {
      name: 'รายการค้างชำระ',
      nameShort: 'ค้างชำระ',
      title: true
    },
    {
      name: 'ทั้งหมด',
      nameShort: 'ทั้งหมด',
      iconFa: 'fas fa-users',
      link: `${path}/unpaid/all`,
      searchLinkActive: `${path}/unpaid/all`
    },
    {
      name: 'รายวัน',
      nameShort: 'รายวัน',
      iconFa: 'fas fa-calendar-day',
      link: `${path}/unpaid/day`,
      searchLinkActive: `${path}/unpaid/day`
    },
    {
      name: 'รายสัปดาห์',
      nameShort: 'รายสัปดาห์',
      iconFa: 'fas fa-calendar-week',
      link: `${path}/unpaid/week`,
      searchLinkActive: `${path}/unpaid/week`
    },
    {
      name: 'รายเดือน',
      nameShort: 'รายเดือน',
      iconFa: 'fas fa-calendar-alt',
      link: `${path}/unpaid/month`,
      searchLinkActive: `${path}/unpaid/month`
    },
    {
      name: 'รายปี',
      nameShort: 'รายเดือน',
      iconFa: 'far fa-calendar-check',
      link: `${path}/unpaid/year`,
      searchLinkActive: `${path}/unpaid/year`
    },

    {
      name: 'เอกสาร',
      title: true
    },
    {
      name: 'ใบสำคัญจ่าย',
      nameShort: 'ใบสำคัญจ่าย',
      icon: 'receipt',
      link: `${path}/voucher-payment/all`,
      searchLinkActive: `${path}/voucher-payment/`
    },
  ]
}

const RubberPaymentsMenuContainer = (props) => {
  const { business, setting, location, route, match, windowModal, closeWindowModal, history } = props

  return (
    <Window
      title="จ่ายชำระหนี้"
      titleIcon={titleIcon}
      linkClose={`/${match.params.username}`}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkHelp="">

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive items={menuItems(match.params)} />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, setting },
            location
          }) }
        </Layouts2>
      </LayoutsTwo>
    </Window>
  )
}

function mapStateToProps(store, props) {
  return {
    modal: store.app.modals.filter(modal => modal.id === 'productCreateModal')[0]
  }
}

export default connect(mapStateToProps)(RubberPaymentsMenuContainer)

import React, { useMemo } from 'react'

// Components Global
import TextInput from 'components/Inputs/TextInput'
import Select from 'components/Inputs/Select'

import { checkUserCreateAdmin } from 'core/redux/Admins/AdminsActions'
import TextInputFetch from 'client/components/Inputs/TextInputFetch'


export const AdminFirstName = (props) => {
  const lang = props.lang || 'local'
  const id = `firstName.${lang}`
  const value = props.value || ''

  return (
    <TextInput
      label="ชื่อ"
      id={id}
      name={id}
      value={value}
      placeholder="ชื่อ"
      type="text"
      required
      iconValid
    />
  )
}

export const AdminLastName = (props) => {
  const lang = props.lang || 'local'
  const id = `lastName.${lang}`
  const value = props.value || ''

  return (
    <TextInput
      label="นามสกุล"
      id={id}
      name={id}
      value={value}
      placeholder="นามสกุล"
      type="text"
      required
      iconValid
    />
  )
}

export const AdminEmail = (props) => {
  return (
    <TextInput
      label="อีเมล"
      id="email"
      name="email"
      value={props.value}
      active
      placeholder="อีเมล"
      inputType="email"
      type="email"
      msgError="รูปแบบอีเมลไม่ถูกต้อง"
      required="true"
      iconValid />
  )
}

export const AdminCheckEmail = (props) => {
  const { business, authName } = props
  const fetchAction = (value, params) => {
    const query = {
      authName:  authName || 'admin',
      businesses_id: business._id
    }

    return checkUserCreateAdmin(query, {
      body: {
        email: value
      },
      ...params
    })
  }

  return useMemo(() => <TextInputFetch
    label="อีเมล"
    id="email"
    name="email"
    fetchAction={fetchAction}
    getFetchData={props.getFetchData}
    value={props.value}
    active
    placeholder="อีเมล"
    inputType="text"
    type="email"
    fetchMsg={{
      success: "อีเมลนี้ เป็นผู้แลระบบอยู่แล้ว",
    }}
    required
    iconValid
  />, [
    props.value
  ])
}

export const AdminRole = (props) => {
  const { setting, value } = props
  const options = [{ value: 'highest', name: 'สิทธิสูงสุด' }]

  let adminRoleGroups =  setting.adminRoleGroups || []

  adminRoleGroups.map(group => {
    options.push({
      value: group.code,
      name: group.name.local
    })
  })

  return (
    <Select
      active
      required
      id="role"
      label="สิทธิการใช้งาน"
      placeholder="สิทธิการใช้งาน"
      placeholderDisabled
      value={value || ''}
      options={options}
    />
  )
}





import React from 'react'

// Components Global
import InputCleave from '../InputCleave'


const InputNo = (props) => {
  const { digit, delimiter, blocks } = props

  let blocksValue

  if (digit) {
    blocksValue = [blocks]
  }

  if (blocks) {
    blocksValue = blocks
  }

  return (
    <div>
      <InputCleave
        {...props}
        options={{
          numericOnly: true,
          delimiter: delimiter || '',
          blocks: blocksValue,
          numeralPositiveOnly: true
        }}
      />
    </div>
  )
}

export default InputNo

import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Components Global
import { Row, Column } from '../../../../../components/Columns'
import Modal from '../../../../../components/Modals/Modal'
import FormValidation from '../../../../../components/FormValidation'
import Select from '../../../../../components/Inputs/Select'
import InputDecimal from '../../../../../components/Inputs/InputDecimal'
import DatePickerBetween from '../../../../../components/input/DatePickerBetween'
import BtnSubmit from '../../../../../components/button/BtnSubmit'

// Util
import getFormValue from '../../../../../util/getFormValue'
import { getNumeral } from '../../../../../util/getNumeral'

// Actions
import { updateOrder } from '../../../../../redux/Orders/OrdersActions'

// Value
import { getTotalPriceAll } from '../../../../../redux/Orders/OrdersValues'


class OrderInstallmentsCreateModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      installments: 2,
      prices: [],
      total: 0
    }
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  fnStartModal = () => {
    const { order } = this.props
    let installments = order.installments
    let prices = []

    installments.map(installment => {
      let price = installment.price ? installment.price !== "" ? installment.price : 0 : 0
      prices.push(price)
    })

    this.mounted && this.setState({ prices: prices }, () => {
      this.getPriceTotal()
    })
  }

  installmentsChange = (value) => {
    this.mounted && this.setState({ installments: parseInt(value) })
  }

  getPriceValue = (i, value) => {
    let prices = this.state.prices

    prices[parseInt(i)] = parseFloat(value || 0)

    this.mounted && this.setState({ prices: prices }, () => {
      this.getPriceTotal()
    })
  }

  getPriceTotal = () => {
    let total = 0
    let prices = this.state.prices

    prices.map(price => {
      if (price) total += price
    })

    this.mounted && this.setState({ total: total })
  }

  handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(this.refs[formName])
    const { business, order } = this.props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id,
      orders_id: order._id
    }

    if (params) {
      this.props.dispatch(updateOrder(query, {
        body: params,
        loadId: formName,
        modalId: formName,
        toastMsg: 'เพิ่มการชำระแบบแบ่งงวดแล้ว'
      })).then(res => {
        if (res.success && this.props.fetch) {
          this.props.fetch()
        }
      })
    }
  }

  render() {
    let order = this.props.order
    let installments = order.installments
    let installmentsOptions = []

    let priceAll = getTotalPriceAll(order)
    let totalStatus = priceAll === this.state.total ? true : false

    Array.apply(1, Array(12)).map((v, i) => {
      if (i > 0) {
        installmentsOptions.push({ value: i+1, option: `${i+1} งวด` })
      }
    })

    return (
      <Modal
        id="installmentsModal"
        headerTitle="ชำระแบบแบ่งงวด"
        fnStartModal={() => this.fnStartModal()}
        headerLeft
        width="700px"
        padding="0px">

        <div className="pd-20">
          <Row className="pd-bottom-15">
            <Column col="column col-xxs-12 col-xs-12 col-sm-12 col-md-6 pd-right-10-md pd-right-10-lg pd-right-10-xlg pd-bottom-10-xxs pd-bottom-10-xs pd-bottom-10-sm ">
              <div>
                <span className="font-0-9">ยอดเงินที่ต้องชำระ</span>

                <div className="pd-left-15 mg-top-5 width-full bg-000 border-radius-5 pd-right-15 left text-right">
                  <span className="font-1-5 color-monitor line-height-50">
                    ฿{ getNumeral(getTotalPriceAll(order), 2) }
                  </span>
                </div>
              </div>
            </Column>

            <Column col="column col-xxs-12 col-xs-12 col-sm-12 col-md-6 pd-left-10-md pd-left-10-lg pd-left-10-xlg">
              <div>
                <span className="font-0-9">
                  ผลรวมยอดชำระทั้งหมด
                </span>
                <div className="pd-left-15 mg-top-5 width-full bg-efefef border-radius-5 pd-right-15 left text-right">
                  <span className="font-1-5 color-success line-height-50">
                    ฿{ getNumeral(this.state.total, 2) }
                  </span>
                </div>
              </div>
            </Column>
          </Row>

          <Row className="pd-bottom-15">
            <Column col="column col-xxs-12 col-xs-12 col-sm-12 col-md-6 pd-right-10-md pd-right-10-lg pd-right-10-xlg pd-bottom-10-xxs pd-bottom-10-xs pd-bottom-10-sm ">
              <Select
                required
                notSave
                active
                id={`installment`}
                default
                label="แบ่งชำระ"
                className="mg-bottom-0 mg-top-2-xlg mg-top-5-lg mg-top-8-md"
                onChange={this.installmentsChange}
                value={this.state.installments}
                options={installmentsOptions}
              />
            </Column>
          </Row>

          <FormValidation
            recheckForm
            ref="installmentsModal"
            name="installmentsModal"
            submit={(e) => this.handleSubmit(e, 'installmentsModal')}>

            { installments && Array.apply(1, Array(this.state.installments)).map((v, i) => {
              const installment = installments[i]

              return (
                <div key={i} className="mg-top-20 border-efefef border-radius-5">

                  <div className="pd-left-10 bg-efefef border-bottom-efefef height-40 line-height-40">
                    <span>แบ่งจ่ายครั้งที่ {i+1}</span>
                  </div>

                  <input type="hidden" data-id={`installments[${i}].no`} defaultValue={i+1} />
                  <input type="hidden" data-id={`installments[${i}].multiple`} defaultValue={true} />

                  <Row className="pd-10 pd-top-20 pd-bottom-0">
                    <Column col="column col-xxs-12 col-xs-12 col-sm-8 col-md-8 col-lg-8 col-xlg-8">
                      <DatePickerBetween
                        id={`installments[${i}]`}
                        startId={`installments[${i}].startDate`}
                        endId={`installments[${i}].dueDate`}
                        labelStart="วันที่"
                        labelEnd="วันหมดอายุ"
                        colEndRight
                        colStart="column col-xxs-12 col-xs-12 col-sm-6 col-md-6 pd-right-10-sm pd-right-10-md pd-right-10-lg pd-right-10-xlg"
                        colEnd="column col-xxs-12 col-xs-12 col-sm-6 col-md-6 pd-left-10-sm pd-left-10-md pd-left-10-lg pd-left-10-xlg pd-right-10-sm pd-right-10-md pd-right-10-lg pd-right-10-xlg"
                        valueStart={installment && installment.startDate || ''}
                        valueEnd={installment && installment.dueDate || ''}
                        dateFormat="D MMM YYYY"
                      />
                    </Column>
                    <Column col="column col-xxs-12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xlg-4 pd-left-10-sm pd-left-10-sm pd-left-10-md pd-left-10-lg pd-left-10-xlg">
                      <InputDecimal
                        required
                        label="ยอดชำระ"
                        unitName="(บาท)"
                        placeholder="0.00"
                        active
                        id={`installments[${i}].price`}
                        i={`${i}`}
                        decimal="2"
                        onChange={this.getPriceValue}
                        value={installment && installment.price || ''}
                      />
                    </Column>
                  </Row>
                </div>
              )
            }) }

            <Row className="pd-top-20 pd-bottom-0">
              <Column col="column col-xxs-12">
                <div>
                  { !totalStatus &&
                    <div className="font-0-9 color-error">
                      <p>*** <u>ผลรวมยอดชำระทั้งหมดไม่ถูกต้อง</u> กรุณาแก้ไข <u>ยอดชำระ</u> ให้ผลรวมยอดชำระทั้งหมดเท่ากับ ฿<u>{ getNumeral(getTotalPriceAll(order), 2) }</u></p>
                    </div>
                  }
                </div>
              </Column>
            </Row>

            <div className="pd-top-15 text-right">
              { totalStatus ?
                <BtnSubmit
                  icon="send"
                  iconAlign="left"
                  text="บันทึก"
                  className="waves-effect waves-light btn light-green darken-1"
                  loadingId="installmentsModal"
                  btnDisabled
                />
              :
                <button className="waves-effect waves-light btn light-green darken-1" disabled="disabled">
                  <i className="material-icons left">send</i>บันทึก
                </button>
              }
            </div>
          </FormValidation>
        </div>
      </Modal>
    )
  }
}

OrderInstallmentsCreateModal.propTypes = {
  order: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired
}

export default connect()(OrderInstallmentsCreateModal)

import React from 'react'

// Components Global
import InputCleave from '../InputCleave'


const InputNumber = (props) => {
  const { digit } = props

  return (
    <div>
      <InputCleave
        {...props}
        options={{
          numeral: true,
          numeralIntegerScale: parseInt(digit),
          numeralDecimalScale: 0,
          delimiter: '',
          numeralPositiveOnly: true
        }}
      />
    </div>
  )
}

export default InputNumber

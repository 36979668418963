import { lazy } from 'react'
import suspenseComponent from '../../../../../../util/suspenseComponent'

// Containers
import CommonSettingsMenuContainer from '../../../../../../containers/dashboard/CommonSettings/containers/CommonSettingsMenuContainer'

// Pages
const Descriptions = lazy(() => import('../../../../../../containers/dashboard/CommonSettings/pages/Descriptions'))
const LanguagesPage = lazy(() => import('../../../../../../containers/dashboard/Settings/pages/Business/LanguagesPage'))
const Company = lazy(() => import('../../../../../../containers/dashboard/CommonSettings/pages/Company'))
const Tax = lazy(() => import('../../../../../../containers/dashboard/CommonSettings/pages/Tax'))
const Pictures = lazy(() => import('../../../../../../containers/dashboard/CommonSettings/pages/Pictures'))


// Pages Rubber Settings
import RubberSettingsRoutes from '../../../../../../routes/dashboard/Businesses/routes/SystemRubber/RubberSettingsRoutes/routes/RubberSettingsMenuRoutes'

// Pages Settings
import SettingsRoutes from '../../../../../../routes/dashboard/Businesses/routes/SettingsRoutes2/routes/SettingsMenuRoutes'


const CommonSettingsMenuRoutes = [
  {
    path: '/:username/common-settings',
    component: CommonSettingsMenuContainer,
    routes: [
      {
        path: '/:username/common-settings',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(Descriptions)
      },
      {
        path: '/:username/common-settings/languages',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(LanguagesPage)
      },
      {
        path: '/:username/common-settings/company',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(Company)
      },
      {
        path: '/:username/common-settings/tax',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(Tax)
      },
      {
        path: '/:username/common-settings/pictures',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(Pictures)
      },

      ...RubberSettingsRoutes({ path: '/:username/common-settings' }),
      ...SettingsRoutes({ path: '/:username/common-settings' })
    ]
  }
]





export default CommonSettingsMenuRoutes

import React from 'react'

// Values
import { productItemValue, productValue } from '../../../../../../../redux/Products/ProductsValues'
import { getNumeral } from '../../../../../../../util/getNumeral'

// Components Products
import { ProductItemProfileAvater } from '../../../../../Products/components/ProductsUserInterface'



const OrderItemsDisplay = (props) => {
  const { order } = props
  const { items } = order

  return (
    <div className="pd-top-20 pd-left-20 pd-right-20">
      { (items && items.length !== 0) &&
        <div>
          { items.map((item, i) => {
            if (item.productItem.subtype !== 'payment-fee' && item.productItem.subtype !== 'shipping-fee') {

              const product = productValue({ product: item.product })
              const productItem = productItemValue({ product, item: item.productItem })

              return (
                <div key={item._id} className="border-bottom-1 border-bottom-ddd bg-fff mg-bottom-10 pd-bottom-10">
                  <table>
                    <tbody>
                      <tr>
                        <td rowSpan="2" className="pd-0 vertical-top width-80">
                          <div className="width-70 height-70">
                            <ProductItemProfileAvater
                              product={product}
                              item={productItem}
                              textSize="1.1rem"
                            />
                          </div>
                        </td>
                        <td className="pd-0 pd-top-5 text-left vertical-top">
                          <div className="font-0-9 color-000 f-title">
                            { product.nameLang }
                          </div>

                          { productItem.choiceFullNameLang &&
                            <div className="font-0-8">
                              { productItem.choiceFullNameLang }
                            </div>
                          }

                          <div className="font-0-8">
                            {item.qty} x {getNumeral(item.price, 'auto')}
                          </div>
                        </td>

                        <td className="pd-0 pd-right-5 color-price text-right">
                          ฿{getNumeral(item.qty * item.price, 'auto')}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )
            }
          }) }
        </div>
      }
    </div>
  )
}

export default OrderItemsDisplay

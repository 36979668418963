import React, { useEffect, useState, Fragment, useRef } from 'react'

// css
import css from './css/required.css'

// Util
import { getNumeral } from 'utils/getNumeral'

import { setStyleValue } from '../../TextEditor/components/ElementStyles/components/js/setDivStyle'

const usePrevious = (value) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value;
  })

  return ref.current || {};
}

const ValueRequired = (props) => {
  const [status, setStatus] = useState()
  const [msgInvalid, setMsgInvalid] = useState('')
  const prevValue = usePrevious({ value: props.value })

  useEffect(() => {
    if (prevValue.value != undefined) {
      //console.log('prevValue: '+ prevValue.value)
      //console.log('value: '+ props.value)

      if (prevValue.value == '' && props.value !== '') {
        checkRequired()
      } else if (prevValue.value != '') {
        checkRequired()
      }
    }
  }, [props.value, props.errorMsg])

  useEffect(() => {
    if (props.getValid) {
      const valid = getConvertValid()
      props.getValid(valid)
    }
  }, [status, props.value, props.errorMsg])


  const getConvertValid = () => {
    let valid

    if (status === 'valid') {
      if (props.errorMsg) {
        valid = 'invalid invalidErrorMsg'
      } else {
        valid = status
      }
    } else {
      valid = status
    }

    return valid
  }

  const checkRequired = () => {
    let { type, required} = props
    let propsValue = props.value

    if (type !== 'disabled') {
      const value = propsValue !== undefined ? propsValue : ''

      if (required) {
        if (value === "" || value === null || value.length === 0) {

          setInvalid('ต้องไม่ว่าง')
        } else {
          checkType()
        }

      } else {
        if (value === "" || value === null || value.length === 0) {
          setDefault()
        } else {
          checkType()
        }
      }
    }
  }

  const setValid = () => {
    let { type } = props

    if (type !== 'disabled') {
      setStatus('valid')
      setMsgInvalid('')
    }
  }

  const setDefault = () => {
    let type = props.type

    if (type !== 'disabled') {
      setStatus(null)
      setMsgInvalid('')
    }
  }

  const setInvalid = (msg) => {
    setTimeout(() => {
      let type = props.type

      if (type !== 'disabled') {
        if (props.value == '') {
          setStatus('invalid')
          setMsgInvalid(msg)
        } else {
          setStatus('invalid')
          setMsgInvalid(msg)
        }
      }
    }, 0)
  }

  const checkDigitMin = (value) => {
    const digitMin = props.digitMin
    const msg = props.digitMinErrorMsg ? props.digitMinErrorMsg : `ต้องมีจำนวนตัวอักษรอย่างน้อย ${digitMin} หลัก`

    if (value.toString().length < digitMin) {
      setInvalid(msg)
    } else {
      setValid()
    }
  }

  const checkDigitMax = (value) => {
    const digitMax = props.digitMax
    const msg = props.digitMaxErrorMsg ? props.digitMaxErrorMsg : `ต้องมีจำนวนตัวอักษรไม่เกิน ${digitMax} หลัก`

    if (value.toString().length > digitMax) {
      setInvalid(msg)
    } else {
      setValid()
    }
  }

  const checkDigitMinMax = (value) => {
    const digitMin = props.digitMinMax[0]
    const digitMax = props.digitMinMax[1]
    const msg = props.digitMinMaxErrorMsg ? props.digitMinMaxErrorMsg : `ต้องมีจำนวนตัวอักษร ${digitMin} - ${digitMax} หลัก`

    if (value.toString().length < digitMin || value.toString().length > digitMax) {
      setInvalid(msg)
    } else {
      setValid()
    }
  }

  const checkDigit = (value) => {
    const digit = props.digit
    const msg = props.digitErrorMsg ? props.digitErrorMsg : `ต้องมีจำนวนตัวอักษร ${digit} หลัก`

    if (value.toString().length < digit) {
      setInvalid(msg)
    } else {
      setValid()
    }
  }

  const checkDate = (value) => {
    const pattern = /^(\d{2})\/(\d{2})\/(\d{4})$/g
    const msg = 'รูปแบบวันที่ไม่ถูกต้อง'

    if (pattern.test(value)) {
      const split = value.split('/')
      value = `${split[2]}-${split[1]}-${split[0]}`

      const date = moment(value).format('YYYY-MM-DD')

      if (date === 'Invalid date') {
        setInvalid(msg)
      } else {
        setValid()
      }
    } else {
      setInvalid(msg)
    }
  }

  const checkType = () => {
    const {
      regex,
      type,
      value,
      maxValue,
      minValue,
      maxValueErrorMsg,
      minValueErrorMsg
    } = props

    let pattern
    let msg

    if (type === 'path' || type === 'username' || type === 'code') {
      pattern = /^([\A-Za-z0-9]+((?:\-[\A-Za-z0-9]+)*)){2}$/i
      msg = 'ไม่ถูกต้อง ต้องเป็น ภาษาอังกฤษหรือตัวเลข มีอย่างน้อย 2 ตัวอักษร'

    } else if (type === 'phoneNumber') {
      pattern = new RegExp("^[0-9]{" + 9 + ","+ 13 +"}$")
      msg = 'รูปแบบเบอร์โทรไม่ถูกต้อง'

    } else if (type === 'phoneNumber-10') {
      pattern = new RegExp("^[0-9]{" + 10 + "}$")
      msg = 'รูปแบบไม่ถูกต้อง ต้องเป็นตัวเลข 10 หลัก'

    } else if (type === 'email') {
      pattern = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
      msg = 'รูปแบบอีเมลไม่ถูกต้อง'

    } else if (type === 'username') {
      pattern = /^([\w-]+(?:\.[\w-]+)*)$/i
      msg = 'รูปแบบไม่ถูกต้อง'

    } else if (type === 'regex' && regex) {
      pattern = regex.pattern
      msg = regex.msg

    } else {
      pattern = /^./
      msg = ''
    }

    let maxMsg = maxValueErrorMsg ? maxValueErrorMsg : `ต้องมีค่าไม่เกิน ${getNumeral(maxValue)}`
    let minMsg = minValueErrorMsg ? minValueErrorMsg : `ต้องมีค่ามากกว่า ${getNumeral(minValue)} หรือเท่ากับ ${getNumeral(minValue)}`

    if (maxValue && !minValue) {
      if (parseFloat(value) > parseFloat(maxValue)) setInvalid(maxMsg)
      else setValid()

    } else if (!maxValue && minValue) {
      if (parseFloat(value) < parseFloat(minValue)) setInvalid(minMsg)
      else setValid()

    } else if (maxValue && minValue) {
      if (parseFloat(value) < parseFloat(minValue)) {
        setInvalid(minMsg)
      } else if (parseFloat(value) > parseFloat(maxValue)) {
        setInvalid(maxMsg)
      } else {
        setValid()
      }

    } else if (type === 'date') {
      checkDate(value)
    } else {
      if (pattern.test(value)) {
        if (props.digit) {
          checkDigit(value)
        } else if (props.digitMin) {
          checkDigitMin(value)
        } else if (props.digitMax) {
          checkDigitMax(value)
        } else if (props.digitMinMax) {
          checkDigitMinMax(value)
        } else {
          setValid()
        }
      } else {
        setInvalid(msg)
      }
    }
  }


  let label

  const valid = getConvertValid()

  if (valid === 'invalid' || valid === 'invalid invalidErrorMsg') {
    label = css.invalid
  } else if (valid === 'valid') {
    label = !props.notColorValid && css.valid
  }

  const { styleName, styleJsx } = props
  const name = styleName + '-label'

  return (
    <div className={`${css.container} ${styleName ? 'mg-bottom-0' : ''} ${props.className || ''}`} style={props.style}>

      { !props.labelHidden && props.label &&
        <label
          id={`label-${props.id}`}
          className={`${label || ''} label-jsx ${props.labelClassName || ''}`}
          style={{ color: props.type === 'disabled' && '#9e9e9e' }}>

          {props.label} {props.unitName && props.unitName}
          {props.required && <span style={{ color: props.disabled && '#9e9e9e' }}> *</span>}
        </label>
      }

      <div className={`${css.boxInput}`} style={{ display: 'grid' }}>
        { props.children }
      </div>

      { !props.msgHidden &&
        <Fragment>
          { valid === 'invalid' &&
            <div className={css.msqInvalid}>
              {props.label || ''} {msgInvalid}
            </div>
          }

          { valid === 'invalid invalidErrorMsg' &&
            <div className={css.msqInvalid}>
              {props.errorMsg}
            </div>
          }
        </Fragment>
      }

      <style jsx>{`
        .label-jsx {
          ${styleJsx && setStyleValue({ name, style: styleJsx })}
        }

        @media (min-width: 1500px) {
          .label-jsx {
            ${styleJsx && setStyleValue({ name, style: styleJsx, vw: true })}
          }
        }

        @media (min-width: 601px) and (max-width: 1024px) {
          .label-jsx {
            ${styleJsx && setStyleValue({ device: 'tablet', name, style: styleJsx })}
          }
        }

        @media (min-width: 50px) and (max-width: 600px) {
          .label-jsx {
            ${styleJsx && setStyleValue({ device: 'mobile', name, style: styleJsx })}
          }
        }
      `}</style>
    </div>
  )
}


export default ValueRequired

// Containers
import POSContainer from '../../../../../containers/dashboard/POS/containers/POSContainer'

// Routes
import POSMainRoutes from './routes/POSMainRoutes'


const POSRoutes = [
  {
    path: '/:username/pos',
    component: POSContainer,
    routes: [
      ...POSMainRoutes
    ]
  }
]

export default POSRoutes

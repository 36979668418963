let settingsValues


if (process.env.APP_PROJECT === 'dashboard' || process.env.APP_PROJECT === 'store' || process.env.APP_PROJECT === 'rubber' || process.env.APP_PROJECT === 'websites') {
  settingsValues = require('../../redux/Settings/SettingsValues')
} else {
  settingsValues = require(`../../projects/${process.env.APP_PROJECT}/redux/Settings/SettingsValues`)
}


const getDefaultSystemsAll = ({ attributes }) => {
  if (settingsValues) {
    if (attributes) {
      return settingsValues.getDefaultSystemsAll({ attributes })
    } else {
      return settingsValues.getDefaultSystemsAll()
    }
  } else {
    console.log('settingsValues is undefined')
  }
}

export const getRoleSystemsAll = ({ business, attributes, setting, auth, newV }) => {
  let systems = []

  if (business && setting && auth && auth.user && auth.user !== 'unauthorized') {
    const admin = getAdminBusinessAuth({ business, auth })

    // if (newV == true) {
    //   admin = getAdminBusinessAuth({ business, auth })
    // } else {
    //   if (business.admins) {
    //     admin = getAdmin({ business, auth })
    //   }
    // }

    if (admin) {
      const useSystems = getUseSystems({ setting, attributes })

      if (admin.role == 'founder' || admin.role == 'highest') {
        systems = useSystems
      } else {
        const roleSystems = getRoleSystems({ setting, role: admin.role })

        roleSystems.map(sys => {
          const use = useSystems.filter(useSys => useSys.id === sys.id)[0]

          if (use) {
            systems.push(use)
          }
        })
      }
    }
  }


  if (newV == true) {
    if (business.mainBranch == false) {
      const index = systems.findIndex(sys => sys.id === "settings")

      if (index !== -1) {
        systems.splice(index, 1);
      }
    }
  }

  return systems
}


export const getUseSystems = ({ setting, attributes }) => {
  const useSystems = []

  if (setting) {
    const settingSystems = setting.systems || []
    const defaultSystems = getDefaultSystemsAll({ attributes })

    settingSystems.map(systemId => {
      const use = defaultSystems && defaultSystems.filter(dSystems => dSystems.id === systemId)[0]

      if (use) {
        useSystems.push(use)
      }
    })
  }

  return useSystems
}

export const getRoleSystems = ({ setting,  role }) => {
  const adminRoleGroup = getAdminRoleGroup({ setting, role })
  const roles = adminRoleGroup && adminRoleGroup.roles || []
  const systemsRoles = []

  roles.map(value => {
    const split = value.split(':')

    if (split[0] && split[1]) {
      const systemId = split[0]
      const role = split[1]
      const index = systemsRoles.findIndex(sys => sys.id === systemId)

      if (index >= 0) {
        systemsRoles[index].roles.push(role)
      } else {
        systemsRoles.push({
          id: systemId,
          roles: [role]
        })
      }
    }
  })

  return systemsRoles
}


export const getSystemsAttributes = ({ attributes }) => {
  const systemsAttributes = []

  if (attributes) {
    attributes = attributes.filter(attr => (attr.type === 'product' || attr.type === 'article' || attr.type === 'note') && attr.showMain === true)

    attributes.map(attr => {
      let path

      if (attr.type === 'product') {
        path = 'products-attr'
      } else if (attr.type === 'article') {
        path = 'articles-attr'
      } else if (attr.type === 'note') {
        path = 'notes'
      }

      systemsAttributes.push({
        id: attr.path,
        name: attr.name.local,
        link: `/${path}/${attr.path}/all/all`,
        attribute: attr
      })
    })
  }

  return systemsAttributes
}

export const getAdminRoleGroup = ({ setting, role }) => {
  if (setting && role) {
    const adminRoleGroups = setting.adminRoleGroups || []
    return adminRoleGroups.filter(group => group.code === role)[0]
  }
}

export const getAdmin = ({ business, auth }) => {
  if (business && business.admins && auth && auth.user && auth.user !== 'unauthorized') {
    const businessAuth = getBusinessAuth({ business, auth })
    const admins_id = businessAuth && businessAuth.admins_id
    const admin = business.admins.filter(admin => admin._id === admins_id && admin.status === 'open')[0]

    return admin
  }
}

export const getAdminBusinessAuth = ({ business, auth }) => {
  if (business && auth && auth.user && auth.user !== 'unauthorized') {
    if (auth && auth.user && auth.user.businessesAuth) {
      const businessAuth = auth.user.businessesAuth.filter(biz => biz._id === business._id)[0]

      if (businessAuth) {
        return businessAuth;
      } else {
        if (auth.user.role === 'admin') {
          return {
            role: 'founder'
          }
        }
      }
    }
  }
}

export const getBusinessAuth = ({ business, auth }) => {
  let businessAuth

  if (auth && auth.user && auth.user.businessesAuth) {
    businessAuth = auth.user.businessesAuth.filter(biz => biz._id === business._id)[0]
  }

  return businessAuth
}



import React from 'react'

// Components Products
import { PlaceProfileAvater } from '../PlacesUserInterface'

// Values
import { placeValue } from '../../../../../redux/Places/PlacesValues'


const PlaceDescriptions = (props) => {
  const place = placeValue(props.data)

  return (
    <div className="bg-fff">
      <div className="line-height-0 height-180 bg-000">
        <PlaceProfileAvater place={place} fontSize="2rem" />
      </div>

      <div>
        <div className="pd-10">
          <div className="font-1-0">
            {place.nameLang}
          </div>

          <div className="font-0-8 pd-top-10">
            {place.descriptionsLang}
          </div>
        </div>


        <div className="pd-top-5 pd-bottom-5 font-0-8 border-top-efefef border-bottom-efefef">
          <table>
            <tbody>
              <tr>
                <td width="33.33%" className="pd-0 center">
                  <i className="material-icons">{place.statusIcon}</i>
                  <p>สถานะ{place.statusName}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>



      </div>
    </div>
  )
}

export default PlaceDescriptions

import React, { useEffect, useState } from 'react'
import RequiredV2 from '../RequiredV2'
import InputDisabled from '../InputDisabled'
import cuid from 'cuid'

import { setStyleValue, getStyle } from '../../TextEditor/components/ElementStyles/components/js/setDivStyle'


const TextInputV2 = (props) => {
  const [id, setId] = useState()
  const [value, setValue] = useState('')
  const [valid, setValid] = useState()

  useEffect(() => {
    const id = props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()

    setId(randomId+id)
  }, [])

  useEffect(() => {
    setValue(props.value || '')
  }, [props.value])

  useEffect(() => {
    propsGetValue()
  }, [value, valid])


  const handleChange = (e) => {
    setValue(e.target.value || '')
  }

  const propsGetValue = () => {
    if (props.getValue) {
      if (valid == 'valid' || valid == 'invalid invalidErrorMsg') {
        props.getValue(value)
      } else {
        props.getValue('')
      }
    }
  }

  const getValid = (valid) => {
    setValid(valid)
  }

  const { styleName, styleJsx, styleGlobal, inputForSite } = props
  const name = styleName
  const setEvent = (event, important=false) => {
    return (
      `
        ${getStyle('box-shadow', styleJsx[`${name}-${event}-box-shadow`], important)}
        ${getStyle('border-style', styleJsx[`${name}-${event}-border-style`], important)}
        ${getStyle('border-width', styleJsx[`${name}-${event}-border-width`], important)}
        ${getStyle('border-color', styleJsx[`${name}-${event}-border-color`], important)}
      `
    )
  }

  return (
    <div className={`${styleGlobal ? 'box-input-global-jsx' : ''} ${props.className || ''}`}>
      { !props.disabled ?
        <RequiredV2
          id={id}
          value={value}
          label={props.label}
          unitName={props.unitName}
          labelHidden={props.labelHidden}
          type={props.type}
          required={props.required}
          iconValid={props.iconValid}
          style={props.style}
          styleName={styleName}
          styleJsx={styleJsx}
          getValid={getValid}
          errorMsg={props.errorMsg}
          labelClassName={props.labelClassName}
          notColorValid={props.notColorValid}
          className={props.requiredClassName || props.className || ''}>

          <input
            type={props.inputType}
            id={id}
            data-id={props.id}
            data-not-save={props.notSave}
            data-label={props.useLabel && props.label}
            data-type={props.dataType || props.inputType}
            data-use-attr={props.useAttr}
            data-use-attr-id={props.useAttrId}
            value={value}
            className={`${valid} ${inputForSite ? 'input-site' : 'input'} ${styleGlobal ? ' input-global-jsx' : ''} input-jsx ${props.required ? 'required' : ''} ${props.inputClassName || ''}`}
            placeholder={props.placeholder}
            onChange={handleChange}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            style={props.inputStyle}
          />

          <style jsx>{`
            .input-jsx {
              ${styleJsx && setStyleValue({ name, style: styleJsx })}

              outline: none;
              -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                      box-sizing: border-box;
            }
            .input-jsx:focus:not([readonly]) {
              ${styleJsx && setEvent('focus')}
            }
            .input-jsx.valid,
            .input-jsx:focus.valid {
              ${styleJsx && setEvent('valid', true)}
            }
            .input-jsx.invalid,
            .input-jsx:focus.invalid {
              ${styleJsx && setEvent('invalid', true)}
            }

            @media (min-width: 1500px) {
              .input-jsx {
                ${styleJsx && setStyleValue({ name, style: styleJsx, vw: true })}
              }
              .input-jsx:focus:not([readonly]) {
                ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-focus-box-shadow-vw`])}
                ${styleJsx && getStyle('border-width', styleJsx[`${name}-focus-border-width-vw`])}
              }
              .input-jsx.valid {
                ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-valid-box-shadow-vw`], true)}
                ${styleJsx && getStyle('border-width', styleJsx[`${name}-valid-border-width-vw`], true)}
              }
              .input-jsx.invalid {
                ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-invalid-box-shadow-vw`], true)}
                ${styleJsx && getStyle('border-width', styleJsx[`${name}-invalid-border-width-vw`], true)}
              }
            }

            @media (min-width: 601px) and (max-width: 1024px) {
              .input-jsx {
                ${styleJsx && setStyleValue({ device: 'tablet', name, style: styleJsx })}
              }
            }

            @media (min-width: 50px) and (max-width: 600px) {
              .input-jsx {
                ${styleJsx && setStyleValue({ device: 'mobile', name, style: styleJsx })}
              }
            }
          `}</style>
        </RequiredV2>
      :
        <InputDisabled {...props} />
      }
    </div>
  )
}

export default TextInputV2

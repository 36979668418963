import React, { Fragment, useEffect, useState } from 'react'

// Components Global
import { Row, Column } from '../../../../../../components/Columns'

// Redux
import { convertDecimal } from '../../../../../../redux/RubberSettings/RubberSettingsValue'

import {
  RubberWarehouseName,
  RubberInventorySelectDates,
  TitleShow,
  InputNumber
} from './RubberSaleForms'

import { getRubberSettingTypeValue } from 'client/redux/RubberSettings/RubberSettingsValue'
import { fetchInventories } from 'client/redux/Inventories/InventoriesActions'
import InputDisabled from 'components/Inputs/InputDisabled/InputDisabled'


const InventoryForms = (props) => {
  const [inventories, setInventories] = useState([])
  const [netWeightOrg, setNetWeightOrg] = useState(0)
  const [netWeightSale, setNetWeightSale] = useState(0)
  const [dryLatexWeight, setDryLatexWeight] = useState(0)
  const [latexPercentAvg, setLatexPercentAvg] = useState(0)
  const [netPriceAvg, setNetPriceAvg] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [warehousesId, setWarehousesId] = useState()

  const [totalStockWeight, setTotalStockWeight] = useState()
  const { type } = props

  useEffect(() => {
    if (props.cost) {
      setInventories(props.cost.inventories)
      setNetWeightOrg(props.cost.netWeightOrg)
      setNetWeightSale(props.cost.netWeightSale)
      setNetPriceAvg(props.cost.netPriceAvg)
      setDryLatexWeight(props.cost.dryLatexWeight)
      setLatexPercentAvg(props.cost.latexPercentAvg)
      setTotalPrice(props.cost.totalPrice)
    }
  }, [])

  const setStateAll = ({ inventories }) => {
    let netWeightOrg = 0, totalPriceOrg = 0, dryLatexWeightOrg = 0
    let netWeightSale = 0, totalPrice = 0, dryLatexWeight = 0, netPriceAvg = 0, latexPercentAvg = 0

    inventories.map(inventory => {
      netWeightSale += inventory.netWeightSale
      netPriceAvg += inventory.netPriceAvg
      latexPercentAvg += inventory.latexPercentAvg

      netWeightOrg += inventory.netWeightOrg
      totalPriceOrg += inventory.totalPrice
      dryLatexWeightOrg += inventory.dryLatexWeight

      const dryLatexWeightFixed = inventory.netWeightSale * inventory.latexPercentAvg / 100
      const totalPriceFixed = dryLatexWeightFixed * inventory.netPriceAvg

      dryLatexWeight += dryLatexWeightFixed
      totalPrice += totalPriceFixed
    })

    netPriceAvg = (totalPrice/dryLatexWeight) || 0
    latexPercentAvg = (dryLatexWeight/netWeightSale*100) || 0

    setInventories(inventories)
    setNetWeightOrg(netWeightOrg)
    setNetWeightSale(netWeightSale)
    setNetPriceAvg(netPriceAvg)
    setDryLatexWeight(dryLatexWeight)
    setLatexPercentAvg(latexPercentAvg)
    setTotalPrice(totalPrice)

    const cost = {
      inventories,
      netWeightOrg,
      netWeightSale,
      netPriceAvg,
      latexPercentAvg,
      dryLatexWeight,
      totalPrice
    }

    props.onChange(cost)
  }

  const onChangeDates = (items) => {
    const inventories = []

    items.map(item => {
      let {
        date,
        purchases,
        netPriceAvg,
        latexPercentAvg,
        netWeight,
        netWeightOrg,
        dryLatexWeight,
        _id,
        sales_id,
        deposits_id,
        totalPrice
      } = item

      inventories.push({
        date,
        netPriceAvg,
        latexPercentAvg,
        dryLatexWeight,
        totalPrice,
        inventories_id: _id,
        sales_id,
        deposits_id,
        netWeightOrg,
        netWeight,
        netWeightSale: netWeight,
        purchases,
      })
    })

    setStateAll({ inventories })
  }

  const onChangeWarehouseName = (value) => {
    if (value) {
      setWarehousesId(value)
    } else {
      setWarehousesId()
    }

    setInventories([])
  }


  const handleNetWeightSaleChange = (value, i) => {
    const newInventories = [...inventories]
    newInventories[i].netWeightSale = parseFloat(value || 0)
    setStateAll({ inventories: newInventories})
  }

  const col = 'column col-xxs-12 col-xs-6 col-sm-6 col-md-3 col-lg-4 pd-left-10 pd-right-10'
  const col_2 = 'column col-xxs-12 col-xs-6 col-sm-4 col-md-3 col-lg-3 pd-left-10 pd-right-10'
  const { setting, sale } = props

  const typeLatex = getRubberSettingTypeValue({ setting, code: 'field-latex' })
  const typeRubberSheet = getRubberSettingTypeValue({ setting, code: 'rubber-sheet' })

  let warehouses =  setting.warehouses || []
  warehouses = warehouses.filter(value => value.type == "field-latex" && value.use == true)

  let warehouseName = "ทั้งหมด"

  if (sale && sale.settings_warehouses_id) {
    let warehouse = warehouses.filter(warehouse => warehouse._id === sale.settings_warehouses_id)[0]
    warehouseName = warehouse && warehouse.name.local || 'ทั้งหมด'
  }

  const handleSetTotalStockWeight = (value) => {
    value = parseFloat(value)
    setTotalStockWeight(value)
  }

  useEffect(() => {
    if (!props.cost) {
      fetch(totalStockWeight)
    }
  }, [
    totalStockWeight
  ])

  const fetch = (totalStockWeight) => {
    const { business, productType, inventoryType, rubberType, customer, warehousesId } = props

    let where = `businesses_id=${business._id},type=${inventoryType},netWeight=!0`

    if (inventoryType === 'deposit' && customer) {
      where = `${where},customers_id=${customer._id}`
    }

    if (productType === 'rubber-sheet-make') {
      where = `${where},rubberType=field-latex`

    } else if (productType === 'rubber-sheet') {
      where = `${where},rubberType=${rubberType}`

    } else if (productType === 'field-latex') {
      where = `${where},rubberType=${rubberType}`
    }

    if (warehousesId) {
      where = `${where},settings_warehouses_id=${warehousesId}`
    }

    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businessType: business.type,
      businesses_id: business._id
    }

    props.dispatch(fetchInventories(query, {
      body: {
        where,
        countWhere: where,
        sort: 'date',
        limit: 100,
      },
      notSaveStore: true
    })).then(res => {
      if (res.success) {
        let sum = 0
        const limit = totalStockWeight
        const inventories = res.data
        const result = []

        for (let i = 0; i < inventories.length; i++) {
          const {
            date,
            purchases,
            netPriceAvg,
            latexPercentAvg,
            netWeight,
            netWeightOrg,
            dryLatexWeight,
            _id,
            sales_id,
            deposits_id,
            totalPrice
          } = inventories[i]

          if (sum + inventories[i].netWeight <= limit) {
            sum += inventories[i].netWeight

            result.push({
              date,
              netPriceAvg,
              latexPercentAvg,
              dryLatexWeight,
              totalPrice,
              inventories_id: _id,
              sales_id,
              deposits_id,
              netWeightOrg,
              netWeight,
              netWeightSale: inventories[i].netWeight,
              purchases,
            })

          } else if (sum + inventories[i].netWeight >= limit) {
            sum += inventories[i].netWeight

            result.push({
              date,
              netPriceAvg,
              latexPercentAvg,
              dryLatexWeight,
              totalPrice,
              inventories_id: _id,
              sales_id,
              deposits_id,
              netWeightOrg,
              netWeight,
              netWeightSale: inventories[i].netWeight - (sum - limit),
              purchases,
            })

            break

          } else {
            break
          }
        }

        setStateAll({ inventories: result })
      }
    })
  }

  return (
    <div>
      <Row className="row-dp-flex mg-left--10 mg-right--10">
        { (warehouses.length > 0 && (type.code === 'field-latex' || type.code === 'rubber-sheet-make')) &&
          <Column col={`column col-xxs-12 pd-left-10 pd-right-10`}>
            { (sale && sale.settings_warehouses_id) ?
              <TitleShow
                value={warehouseName}
                type="text"
                label="บ่อน้ำยาง"
                textAlign="left"
              />
            :
              <RubberWarehouseName
                {...props}
                onChange={onChangeWarehouseName}
              />
            }
          </Column>
        }

        <Column col={`column col-xxs-12 pd-left-10 pd-right-10`}>
          { type.code !== 'rubber-sheet' ?
            <RubberInventorySelectDates
              {...props}
              warehousesId={warehousesId}
              onChange={onChangeDates}
            />
          :
            <Fragment>
              { !props.cost &&
                <Fragment>
                  { props.rubberType ?
                    <InputNumber
                      value={totalStockWeight}
                      inputClassName="text-left"
                      onChange={handleSetTotalStockWeight}
                      required
                      type="number"
                      label="นน.ยางแผ่นที่ขาย (ในสต็อกยางแผ่น)"
                    />
                  :
                    <InputDisabled
                      label="นน.ยางแผ่นที่ขาย (ในสต็อกยางแผ่น)"
                      required
                      value="*** ยังไม่เลือกประเภทยาง"
                      inputClassName="color-000 font-0-8 height-30 line-height-30 pd-right-10"
                      labelClassName="color-000 mg-bottom-4 font-0-8"
                    />
                  }
                </Fragment>
              }
            </Fragment>
          }
        </Column>

        { (inventories.length == 0 && props.rubberType && totalStockWeight > 0) &&
          <Column col={`column col-xxs-12 pd-left-10 pd-right-10`}>
            <div className="border-1 border-error center border-radius-5 mg-bottom-20 color-error pd-5">
              ไม่มียางในสต็อก
            </div>
          </Column>
        }


        { inventories.map((inventory, i) => {
          return (
            <Fragment key={i}>
              <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
                <TitleShow value={inventory.date} type="date-short" label="วันที่" />
              </Column>

              <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
                <TitleShow value={inventory.latexPercentAvg} type="number" label="% ยาง" />
              </Column>

              <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
                <TitleShow value={inventory.netPriceAvg} type="xxx" label="ราคา/กก." />
              </Column>

              <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
                <TitleShow value={inventory.netWeight} type="number" label="นน.ยาง" />
              </Column>

              <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
                { type.code !== 'rubber-sheet' ?
                  <InputNumber
                    value={inventory.netWeightSale}
                    onChange={(value) => handleNetWeightSaleChange(value, i)}
                    maxValue={inventory.netWeight}
                    required
                    type="number"
                    label="นน.ยางที่ขาย"
                  />
                :
                  <TitleShow value={inventory.netWeightSale} type="number" label="นน.ยางที่ขาย" />
                }
              </Column>
            </Fragment>
          )
        }) }

        <Column col={`column col-xxs-12 pd-left-10 pd-right-10 pd-bottom-10`}>
          <div className="font-1-1">สรุปต้นทุน</div>
        </Column>

        <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
          <TitleShow value={netWeightSale} type="number" label="นน.ยาง" />
        </Column>

        <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
          <TitleShow value={latexPercentAvg} type="number" label="% ยาง" />
        </Column>

        <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
          <TitleShow value={dryLatexWeight} type="number" label="นน.ยางแห้ง" />
        </Column>

        <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
          <TitleShow value={netPriceAvg} type="number" label="ราคา/กก." />
        </Column>

        <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
          <TitleShow value={totalPrice} type="number" label="รวมเป็นเงิน" />
        </Column>
      </Row>
    </div>
  )
}

export default InventoryForms

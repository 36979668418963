import React from 'react'
import { connect } from 'react-redux'

// Components Global
//import { SiteManagerDisplay } from '../../../../../components/SiteManager'


const PlaceSiteManagerDisplayPage = (props) => {
  const { match, place, business } = props

  /*return (
    <SiteManagerDisplay
      action={{
        params: {
          business: business,
          data: place,
          _id: place._id,
          imagePrefix: 'places'
        }
      }}
      linkBack={`/${match.params.username}/places/${place.status}/${match.params.places_id}/update/site`}
      linkManager={`/${match.params.username}/places/${place.status}/${match.params.places_id}/update/site-manage`}
    />
  )*/

  return (
    <div></div>
  )
}

export default connect()(PlaceSiteManagerDisplayPage)

// Containers
import PlacesContainer from '../../../../../containers/dashboard/Places/containers/PlacesContainer'
import PlaceContainer from '../../../../../containers/dashboard/Places/containers/PlaceContainer'

// Routes
import PlacesMenuRoutes from './routes/PlacesMenuRoutes'
import PlaceUpdateRoutes from './routes/PlaceUpdateRoutes'

const PlacesRoutes = [
  {
    path: '/:username/places',
    component: PlacesContainer,
    routes: [
      {
        path: '/:username/places/:status/:places_id',
        component: PlaceContainer,
        routes: [
          ...PlaceUpdateRoutes
        ]
      },
      ...PlacesMenuRoutes,
    ]
  }
]

export default PlacesRoutes

import { fetchDatas, fetchData, fetchDataUpdate, createData, updateData, updateDataMany, removeData, fetchApi } from '../reduxActions'
import { fetchDatasMany, updateApi } from 'core/redux/reduxActionsV2'
import { getTypePath } from '../reduxTypes'
import { generateTypeName, generateTypeFunctions } from 'core/redux/reduxTypesV2'

import uploadsForS3 from '../../util/uploadsForS3'
import { checkAppActions } from '../App/AppActions'

// Export Constants
const NAME_TYPE = ['PRODUCTS', 'PRODUCT']
const name = "products"


// Type Names and Type Functions
export const {
  ADDS, ADDS_MORE, ADD, UPDATE, REMOVE,
  ADDS_MANY, ADDS_MANY_MORE,
} = generateTypeName(NAME_TYPE)

export const {
  adds, adds_more, add, update, remove,
  adds_many, adds_many_more,
} = generateTypeFunctions(NAME_TYPE)


// Display
export const fetchProductsAllDisplay = (dbProductsNo, businesses_id, params = {}) => dispatch => {
  return fetchDatas({
    dispatch,
    params,
    api: `products-display/${dbProductsNo}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchProductOneDisplay = (dbProductsNo, _id, params = {}) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `product-display/${dbProductsNo}/${_id}`,
    add: add
  })
}



// ***************** new query *****************
export const fetchQueryProductOne = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return fetchData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}`,
    add: add
  })
}

export const fetchQueryProductsMany = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return fetchDatasMany({
    dispatch,
    params,
    api: `${name}-many/${authName}/${businesses_id}`,
    adds_many,
    adds_many_more
  })
}

export const cleanProductsAll = (params = {}) => dispatch => {
  return fetchDatasMany({
    dispatch,
    params,
    clean: true,
    adds_many,
  })
}


export const updateReorderProducts = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return updateApi({
    dispatch,
    params,
    api: `${name}-reorder/${authName}/${businesses_id}`,
  })
}
// ***************** new query *****************




// Export Actions
export const checkProductDuplicate = (query, params = {}) => dispatch => {
  const { dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchApi({
    dispatch,
    params,
    api: `${path}${name}-query/${dbNo}/${businesses_id}/check-value-duplicate?${params.query}`
  })
}


export const countProducts = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchApi({
    dispatch,
    params,
    api: `${path}${name}-count/${authName}/${dbNo}/${businesses_id}`,
  })
}

export const fetchProducts = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchDatas({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchProduct = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, products_id } = query
  const path = getTypePath(businessType)

  return fetchData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${products_id}`,
    add: add
  })
}


export const fetchProductUpdate = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, products_id, _id } = query
  const path = getTypePath(businessType)

  return fetchDataUpdate({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${_id || products_id}`,
    update: update,
    updateId: _id || products_id
  })
}


export const fetchProductMore = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, products_id, _id } = query
  const path = getTypePath(businessType)

  return fetchDataUpdate({
    dispatch,
    params,
    api: `${path}${name}-more/${authName}/${dbNo}/${businesses_id}/${_id || products_id}`,
    update: update,
    updateId: _id || products_id
  })
}


export const createProduct = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return createData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/create`,
    add: add
  })
}

export const updateProduct = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, products_id, _id } = query
  const path = getTypePath(businessType)

  return updateData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${_id || products_id}`,
    update: update,
    updateId: _id || products_id
  })
}

export const updateProducts = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return updateDataMany({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/update-many`
  })
}

export const uploadImagesProduct = (query, fileParams, params) => dispatch => {
  const { files, imagePrefix } = fileParams

  checkAppActions(dispatch, params, 'start')

  return new Promise(resolve => {
    uploadsForS3(files, imagePrefix, params.progressId, dispatch).then(val => {
      val.map(paths => {
        params.body.paths = paths

        return dispatch(updateProduct(query, params)).then(res => {
          if (res.success) {
            res.paths = paths
          }

          resolve(res)
        })
      })
    })
  })
}

export const removeProduct = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, products_id, _id } = query
  const path = getTypePath(businessType)

  return removeData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${_id || products_id}`,
    remove,
    deleteId: _id || products_id
  })
}

import React, { Fragment, useEffect, useState } from 'react'

// Components Global
import { Row, Column } from '../../../../../../components/Columns'

// Components Forms
import SaleForms from './SaleForms'
import IncomesForms from './IncomesForms'

// redux
import { convertDecimal } from '../../../../../../redux/RubberSettings/RubberSettingsValue'



const RubberItemSale = (props) => {
  const { type } = props
  const [itemSale, setItemSale] = useState({})
  const [incomes, setIncomes] = useState([])
  const [totalIncomes, setTotalIncomes] = useState(0)
  const [totalPriceAll, setTotalPriceAll] = useState(0)

  useEffect(() => {
    if (props.itemSale) {
      setItemSale(props.itemSale)
      setIncomes(props.itemSale.incomes)
      setTotalIncomes(props.itemSale.totalIncomes)
      setTotalPriceAll(props.itemSale.totalPriceAll)
    }
  }, [])

  const handleChangeSale = (itemSale) => {
    const totalPrice = itemSale && itemSale.totalPrice || 0
    const totalPriceAll = totalPrice + totalIncomes

    setItemSale(itemSale)
    setTotalPriceAll(totalPriceAll)

    props.onChange({ ...itemSale, incomes, totalIncomes, totalPriceAll })
  }

  const handleChangeIncomes = (incomes) => {
    const totalIncomes = getTotalIncomes(incomes)
    const totalPrice = itemSale && itemSale.totalPrice || 0
    const totalPriceAll = totalPrice + totalIncomes

    setIncomes(incomes)
    setTotalIncomes(totalIncomes)
    setTotalPriceAll(totalPriceAll)

    props.onChange({ ...itemSale, incomes, totalIncomes, totalPriceAll })
  }

  const getTotalIncomes = (incomes) => {
    let total = 0
    incomes && incomes.map(income => total += income.price || 0)
    total = convertDecimal({ number: total, name: 'saleTotalPriceDCM', type: props.type })

    return total
  }

  const col_1 = "column col-xxs-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 pd-right-20-lg pd-right-20-xlg pd-bottom-20"
  const col_2 = "column col-xxs-12 col-xs-12 col-sm-12 col-md-12 col-lg-6  pd-left-20-lg  pd-left-20-xlg pd-bottom-20"

  return (
    <Row className="row-dp-flex">
      <Column col={col_1}>
        <div className="font-1-1 pd-bottom-20">
          { props.forDeposit ?
            <Fragment>ข้อมูลยางแผ่นที่ฝาก (จากโรงงานที่รับซื้อ)</Fragment>
          :
            <Fragment>
              { type.code === 'rubber-sheet-make' && 'ข้อมูลน้ำยางสดที่ซื้อเพื่อทำยางแผ่น' }
              { type.code === 'rubber-sheet' && 'ข้อมูลยางแผ่นที่ขายได้ (จากโรงงานที่รับซื้อ)' }
              { type.code === 'field-latex' && 'ข้อมูลน้ำยางสดที่ขายได้ (จากโรงงานที่รับซื้อ)' }
            </Fragment>
          }
        </div>

        <SaleForms {...props} element="sale" onChange={handleChangeSale} />
      </Column>

      <Column col={col_2}>
        <IncomesForms
          {...props}
          onChange={handleChangeIncomes}
          netWeight={itemSale && itemSale.dryLatexWeight || 0}
        />
      </Column>
    </Row>
  )
}

export default RubberItemSale

import React, { Component } from 'react'

// Compoents Local
import SectionTitle from '../SectionTitle'


import { FooterManage, FooterDisplay } from '../../../../../Footer'


class HeaderFullManage extends Component {
  render() {
    const { business, setting, params, pages, site, pageCurrent, singlePageData, singlePageType } = this.props

    return (
      <div>
        <SectionTitle title="ส่วนท้าย (Footer)" mode="view">
          <FooterDisplay
            {...this.props}
            mode="view"
            datas={{
              business,
              setting,
              site,
              pageCurrent,
              singlePageData,
              singlePageType
            }}
          />

          <FooterManage
            {...this.props}
            mode="edit"
            datas={{
              business,
              setting,
              site,
              pages,
              pageCurrent,
              singlePageData,
              singlePageType
            }}
          />
        </SectionTitle>
      </div>
    )
  }
}

export default HeaderFullManage

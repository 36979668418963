import React from 'react'
import renderRoutes from '../../../../routes/renderRoutes'

// Components Global
import Window from '../../../../components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../components/MenuSidebarResponsive'

// Components Customers
import CustomerCreateModal from '../components/CustomerCreateModal'


// imgs
import titleIcon from '../resources/imgs/customer-affiliates.png'


const menuItems = (params) => {
  const { username, attributes_path } = params

  let path = `/${username}/customer-affiliates`

  if (attributes_path) {
    path = `/${username}/customer-affiliates/${attributes_path}`
  }

  return [
    {
      name: 'ตัวแทนจำหน่าย',
      title: true
    },
    {
      name: 'ทั้งหมด',
      icon: 'assignment',
      link: `${path}/all/all`,
      linksActive: [
        { url: `${path}/all/all` },
        { url: `${path}/all/:objectId` }
      ],
    },
    {
      name: 'วีไอพี',
      icon: 'account_box',
      link: `${path}/vip/all`,
      linksActive: [
        { url: `${path}/vip/all` },
        { url: `${path}/vip/:objectId` }
      ],
    },
    {
      name: 'อนุมัติแล้ว',
      icon: 'verified',
      link: `${path}/approved/all`,
      linksActive: [
        { url: `${path}/approved/all` },
        { url: `${path}/approved/:objectId` }
      ],
    },
    {
      name: 'ยังไม่อนุมัติ',
      icon: 'cancel',
      link: `${path}/not-approved/all`,
      linksActive: [
        { url: `${path}/not-approved/all` },
        { url: `${path}/not-approved/:objectId` }
      ],
    },
    {
      name: 'ถังขยะ',
      icon: 'delete',
      link: `${path}/trash/all`,
      linksActive: [
        { url: `${path}/trash/all` },
        { url: `${path}/trash/:objectId` }
      ],
    }
  ]
}

const CustomerAffiliatesMenuContainer = (props) => {
  const { business, location, route, match, windowModal, closeWindowModal, history } = props

  return (
    <Window
      title="ตัวแทนจำหน่าย"
      titleIcon={titleIcon}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkClose={`/${match.params.username}`}>

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive
            items={menuItems(match.params)}
          />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business },
            location
          }) }
        </Layouts2>
      </LayoutsTwo>
    </Window>
  )
}

export default CustomerAffiliatesMenuContainer

export const stateSearchDefault = () => {
  return { status: false, value: '', data: [], count: 0, searchParams: [] }
}

export const stateAddOneData = (state, action, actionData) => {
  return addOne({ state, action, actionData })
}

export const stateAddsManyDatas = (state, action, actionDatas) => {
  return addsMany({ state, action, actionDatas })
}

export const stateAddsManyMoreDatas = (state, action, actionDatas) => {
  return addsMany({ state, action, actionDatas, addsMore: true })
}


const getStateDatas = (state) => {
  let storeDatas = state && state.data && state.data.slice()
  let searchDatas = state && state.search && state.search.data && state.search.data.slice()
  let storeDataGroups = state && state.dataGroups && state.dataGroups.slice()

  return {
    storeDatas,
    storeDataGroups,
    searchDatas
  }
}

const getIndexGroup = (dataGroups, groupId) => {
  return dataGroups && dataGroups.findIndex(g => g.groupId === groupId)
}

const setSearchDatas = ({ search, addsMore, actionDatas, searchDatas }) => {
  if (addsMore) {
    searchDatas = searchDatas.concat(actionDatas)
  } else {
    searchDatas = actionDatas
  }

  return {
    status: search.status,
    value: search.value,
    data: searchDatas,
    count: search.count || 0,
    params: search.params
  }
}

const setDatas = ({ addsMore, actionDatas, datas }) => {
  if (addsMore) {
    datas = datas.concat(actionDatas)
  } else {
    datas = actionDatas
  }

  return datas
}

const setSearchDataOne = ({ search, actionData, searchDatas, order="first" }) => {
  if (order === 'first') {
    searchDatas = [actionData, ...searchDatas]
  } else if (order === 'last') {
    searchDatas = [...searchDatas, actionData]
  }

  return {
    status: search.status,
    value: search.value,
    data: searchDatas,
    count: search.count || 0,
    params: search.params
  }
}

const setDataOne = ({ actionData, datas, order="first" }) => {
  if (order === 'first') {
    datas = [actionData, ...datas]
  } else if (order === 'last') {
    datas = [...datas, actionData]
  }

  return datas
}

const addOne = ({ state, action, actionData }) => {
  const { params } = action
  const { search } = params
  const { store } = params || {}
  const order = store && store.addOrder || 'first'

  let { storeDatas, storeDataGroups, searchDatas } = getStateDatas(state)
  let searchValue = stateSearchDefault()

  // for groupId
  if (store && store.groupId) {
    const groupIndex = getIndexGroup(storeDataGroups, store.groupId)

    // by index
    if (groupIndex !== -1) {

      // for search
      if (search) {
        if (storeDataGroups) {
          storeDataGroups[groupIndex].search = setSearchDataOne({
            search,
            actionData,
            searchDatas: storeDataGroups[groupIndex].search.data,
            order
          })
        }

      // for data
      } else {
        if (storeDataGroups) {
          storeDataGroups[groupIndex].search = stateSearchDefault()
          storeDataGroups[groupIndex].data = setDataOne({
            actionData,
            datas: storeDataGroups[groupIndex].data,
            order
          })
        }
      }

    // add new
    } else {
      storeDataGroups.push({
        groupId: store.groupId,
        data: actionData,
        search: stateSearchDefault()
      })
    }

  // no groupId
  } else {
    if (search) {
      searchValue = setSearchDatas({ search, addsMore, actionDatas, searchDatas })
    } else {
      storeDatas = setDataOne({
        actionData,
        datas: storeDatas,
        order
      })
    }
  }

  return {
    data: storeDatas,
    dataGroups: storeDataGroups || [],
    search: searchValue
  }
}

const addsMany = ({ state, action, actionDatas, addsMore }) => {
  const { params } = action
  const { search } = params
  const { store } = params || {}

  let { storeDatas, storeDataGroups, searchDatas } = getStateDatas(state)
  let searchValue = stateSearchDefault()

  // for groupId
  if (store && store.groupId) {
    const groupIndex = getIndexGroup(storeDataGroups, store.groupId)

    // by index
    if (groupIndex !== -1) {

      // for search
      if (search) {
        if (storeDataGroups) {
          storeDataGroups[groupIndex].search = setSearchDatas({
            search,
            addsMore,
            actionDatas,
            searchDatas: storeDataGroups[groupIndex].search.data
          })
        }

      // for data
      } else {
        if (storeDataGroups) {
          storeDataGroups[groupIndex].search = stateSearchDefault()
          storeDataGroups[groupIndex].data = setDatas({
            addsMore,
            actionDatas,
            datas: storeDataGroups[groupIndex].data
          })
        }

        // search is undefined
        /*if (storeDataGroups[groupIndex].search == undefined) {
          storeDataGroups[groupIndex].search = stateSearchDefault()
        }*/
      }

    // add new
    } else {
      storeDataGroups.push({
        groupId: store.groupId,
        data: actionDatas,
        search: stateSearchDefault()
      })
    }

  // no groupId
  } else {
    if (search) {
      searchValue = setSearchDatas({ search, addsMore, actionDatas, searchDatas })
    } else {
      storeDatas = setDatas({
        addsMore,
        actionDatas,
        datas: storeDatas
      })
    }
  }

  return {
    data: storeDatas,
    dataGroups: storeDataGroups || [],
    search: searchValue
  }
}

import React, { Component, Fragment, useEffect, useState } from 'react'
import moment from 'moment'

// components global
import InputDatePicker from '../InputDatePicker'
import { Row, Column } from '../../Columns'
import { getURLSearchParams } from '../../../util/getURLSearchParams'


const DatePickerBetween = (props) => {
  const [id, setId] = useState('')
  const [valueStart, setValueStart] = useState('')
  const [valueEnd, setValueEnd] = useState('')
  const [openPickerEndDate, setOpenPickerEndDate] = useState(false)

  useEffect(() => {
    const id = props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    setId(id)
    setValueStart(props.start.value || '')
    setValueEnd(props.end.value || '')
  }, [])

  useEffect(() => {
    //setValueStart(props.start.value || '')
  }, [props.start])

  useEffect(() => {
    //setValueEnd(props.end.value || '')
  }, [props.end])

  useEffect(() => {
    if (props.onChange) {
      props.onChange(valueStart, valueEnd)
    }
  }, [valueStart, valueEnd])

  const plusDayStart = (startDate) => {
    if (props.plusDay) {
      let dayPlus = moment(startDate, "YYYY-MM-DD").add(props.plusDay, 'days')
      return dayPlus.format("YYYY-MM-DD")
    }

    return startDate
  }

  const onChangeStartDate = (date) => {
    setValueStart(date)
    setValueEnd(date)

    /*if (props.onChange) {
      props.onChange(date, date)
    }*/
  }

  const onChangeEndDate = (date) => {
    setValueEnd(date)

    /*if (props.onChange) {
      props.onChange(valueStart, date)
    }*/
  }

  const { start, end } = props

  return (
    <Fragment>
      <Column col={start.col ? start.col : 'column col-xxs-12'}>
        <InputDatePicker
          required={props.required}
          notSave
          id={start.id}
          name="dateStart"
          disabled={start.disabled}
          value={valueStart}
          label={start.label || 'วันที่เริ่มต้น'}
          getStartDatePicker={props.getStartDatePicker}
          getDatesCalendarActive={props.getDatesCalendarActive}
          onChange={onChangeStartDate}
          dateFormat={props.dateFormat}
          //startDate={moment().format('YYYY-MM-DD')}
          timePicker={props.timePicker}
          inputClassName={props.inputClassName}
          labelClassName={props.labelClassName}
          bbYear={props.bbYear}
          left
          hiddenIcon={props.hiddenIcon}
        />
      </Column>

      <Column col={end.col ? end.col : 'column col-xxs-12'}>
        <InputDatePicker
          required={props.required}
          notSave
          right={end.right}
          id={end.id}
          name="endDate"
          disabled={end.disabled}
          value={valueEnd}
          label={end.label || 'วันที่สิ้นสุด'}
          onChange={onChangeEndDate}
          dateFormat={props.dateFormat}
          startDate={plusDayStart(valueStart)}
          timePicker={props.timePicker}
          inputClassName={props.inputClassName}
          labelClassName={props.labelClassName}
          bbYear={props.bbYear}
          hiddenIcon={props.hiddenIcon}
        />

        <input
          data-id={start.id}
          value={valueStart}
          data-not-save={start.notSave}
          type="hidden"
        />

        <input
          data-id={end.id}
          value={valueEnd}
          data-not-save={end.notSave}
          type="hidden"
        />
      </Column>
    </Fragment>
  )

}

export default DatePickerBetween

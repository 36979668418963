import { lazy } from 'react'
import suspenseComponent from '../../../../../../util/suspenseComponent'

// Containers
import NoteUpdateContainer from '../../../../../../containers/dashboard/Notes/containers/NoteUpdateContainer'

// Component Pages
const NoteDescriptionsPage = lazy(() => import('../../../../../../containers/dashboard/Notes/pages/NoteDescriptionsPage'))
//const ArticlePhotosPage = lazy(() => import('../../../../../../containers/dashboard/Articles/pages/ArticlePhotosPage'))
//const ArticleContentPage = lazy(() => import('../../../../../../containers/dashboard/Articles/pages/ArticleContentPage'))
//const ArticleContentManagePage = lazy(() => import('../../../../../../containers/dashboard/Articles/pages/ArticleContentManagePage'))
//const ArticleSeoPage = lazy(() => import('../../../../../../containers/dashboard/Articles/pages/ArticleSeoPage'))


const NoteUpdateRoutes = [
  {
    path: '/:username/notes/:attributes_path/:status/:notes_id/update',
    component: NoteUpdateContainer,
    routes: [
      {
        path: '/:username/notes/:attributes_path/:status/:notes_id/update',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(NoteDescriptionsPage)
      },
      /*{
        path: '/:username/notes/:attributes_path/:status/:notes_id/update/content',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { contentGroupId: 'article', linkContentManage: 'content-manage' },
        component: suspenseComponent(ArticleContentPage)
      },
      {
        path: '/:username/notes/:attributes_path/:status/:notes_id/update/content-manage',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { contentGroupId: 'article', linkContentDisplay: 'content' },
        component: suspenseComponent(ArticleContentManagePage)
      },
      {
        path: '/:username/notes/:attributes_path/:status/:notes_id/update/photos',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ArticlePhotosPage)
      },
      {
        path: '/:username/notes/:type/:status/:notes_id/update/seo',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ArticleSeoPage)
      }*/
    ]
  }
]

export default NoteUpdateRoutes

import React, { Component, Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Img from '../../../../../../../components/Img'

// Components Global
import Modal from '../../../../../../../components/Modals/Modal'
import ModalFixed from '../../../../../../../components/Modals/ModalFixed'
import { closeModal } from '../../../../../../../redux/App/AppActions'
import { haveItemsImageProfile, productItemValue } from '../../../../../../../redux/Products/ProductsValues'


const ProductDetails = (props) => {
  const [itemSelected, setItemSelected] = useState()
  const { product, id } = props

  const handleSelectItem = () => {
    if (itemSelected) {
      props.getItem({
        product,
        productItem: itemSelected
      })

      props.dispatch(closeModal(id))
    }
  }

  const getItemSelected = (item) => {
    if (item) {
      setItemSelected(item)
    } else {
      setItemSelected()
    }
  }



  return (
    <ModalFixed
      id={id}
      title={product.nameLang}
      headerLeft
      width="600px"
      headerClassName="height-50 line-height-30"
      bodyClassName="top-50 bottom-50 pd-5 bg-efefef"
      footerClassName="height-50 pd-5">

      <div className="pd-10">
        <ChildItems
          {...props}
          parent_items_id={null}
          product={product}
          getItemSelected={getItemSelected}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="width-100"></div>
        <div className="width-100"></div>
        <div className="width-full pd-left-5">
          { itemSelected ?
            <a onClick={handleSelectItem} className="btn btn-success width-full height-40 line-height-40 btn-shadow-none">ตกลง</a>
          :
            <a className="btn btn-success width-full height-40 line-height-40 btn-shadow-none disabled">ตกลง</a>
          }
        </div>
      </div>
    </ModalFixed>
  )
}

const ChildItems = (props) => {
  const [itemSelected, setItemSelected] = useState()
  const { product, parent_items_id, getItemSelected } = props

  let items = product.items.filter(item => item.items_id === parent_items_id)
  items = items.filter(item => item.status === 'open')

  let parentItem = product.items.filter(item => item._id === parent_items_id)[0]
  parentItem = parentItem && productItemValue({ product, item: parentItem })

  let choiceDisplay = !parentItem ? product.subitemChoiceWebDisplay : parentItem.subitemChoiceWebDisplay
  choiceDisplay = choiceDisplay || 'auto'

  let haveImg

  if (choiceDisplay === 'auto') {
    haveImg = haveItemsImageProfile(product, parent_items_id)
  } else if (choiceDisplay === 'img') {
    haveImg = true
  }

  useEffect(() => {
    setItemSelected()
    getItemSelected()
  }, [parent_items_id])

  useEffect(() => {
    if (itemSelected && itemSelected.itemChoice === 'single') {
      getItemSelected(itemSelected)
    }
  }, [itemSelected && itemSelected._id])



  let label = !parentItem ? product.subitemChoiceNameLang : parentItem.subitemChoiceNameLang
  label = label || 'ตัวเลือก'

  return (
    <div>
      <div className="pd-bottom-10">เลือก: {label}</div>

      { items.length !== 0 ?
        <div>
          { items.map((item, i) => {
            let profile = item.images.filter(img => img.profile === true)[0]
            profile = profile && profile.paths && profile.paths.o

            const active = (itemSelected && itemSelected._id === item._id)

            return (
              <div
                key={item._id}
                onClick={() => setItemSelected(item)}
                style={{
                  display: 'flex', flexDirection: 'row',
                  borderColor: active ? '#74c0fc' : '#ddd'
                }}
                className={` ${active ? 'border-2' : 'border-1'} border-radius-5 mg-bottom-10 overflow-hidden bg-fff`}>

                { haveImg &&
                  <div className="width-60 height-60">
                    <Img
                      s3UrlOriginal
                      src={profile}
                      style={{ verticalAlign: 'middle', width: '100%', height: '100%', userSelect: 'none' }}
                    />
                  </div>
                }

                <div className="pd-10 width-full" style={{ display: 'flex', alignItems: 'center' }}>
                  <div>{ item.name.local }</div>
                </div>

                <div className="width-50 text-right" style={{ color: '#74c0fc', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  { active &&
                    <i className="material-icons-outlined">check_circle</i>
                  }
                </div>
              </div>
            )
          }) }

          <div>
            { itemSelected &&
              <Fragment>
                { itemSelected.itemChoice === 'multiple' &&
                  <ChildItems
                    parent_items_id={itemSelected._id}
                    product={product}
                    getItemSelected={getItemSelected}
                  />
                }
              </Fragment>
            }
          </div>
        </div>
      :
        <div>ไม่มี</div>
      }
    </div>
  )
}


export default connect()(ProductDetails)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// Components Global
import { BtnModalOpen } from '../../../../../components/Modals/BtnModal'

// Components Local
import DiscountEditModal from './components/DiscountEditModal'
import SrcEditModal from './components/SrcEditModal'
import VatEditModal from './components/VatEditModal'
import WhtEditModal from './components/WhtEditModal'

// Components Sales
import OrderDraftsListModal from '../OrderDraftsListModal'

// Components Orders
import OrderPriceSummary from '../../../Orders/components/OrderPriceSummary'

// Components Products
import ProductsListModal from '../../../Products/components/ProductsListModal'

// Functions
import { clearOrder, confirmCreateOrderDraft } from '../../../../../redux/Orders/OrdersFunctions'

// Actions
import { openModal, closeModal } from '../../../../../redux/App/AppActions'

// Values
import { getTotalPriceAll } from '../../../../../redux/Orders/OrdersValues'

// Utils
import { getNumeral } from '../../../../../util/getNumeral'

// css
import css from './css/buttonOrderManager.css'


class OrderButtonsManager extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openProductsList: false
    }
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }


  openPriceSummary= () => {
    this.props.dispatch(openModal('priceSummaryModal'))
  }

  openProductsList = () => {
    if (this.state.openProductsList) {
      this.mounted && this.setState({ openProductsList: false })
    } else {
      this.mounted && this.setState({ openProductsList: true })
    }
  }

  openModal = (modalId, check=true) => {
    const order = this.props.order

    if (check && order.items.length !== 0) {
      this.props.dispatch(openModal(modalId))
    } else {
      this.props.dispatch(openModal(modalId))
    }
  }

  goBack = () => {
    const { order, updatePage } = this.props
    const path = updatePage ? 'update' : 'pos-online'

    if (order) {
      this.props.history.push(`/${this.props.match.params.username}/sales/${path}/${order._id}/main`)
    }
  }

  checkout = () => {
    const { order, updatePage } = this.props
    const path = updatePage ? 'update' : 'pos-online'

    if (order.items.length !== 0) {
      this.props.history.push(`/${this.props.match.params.username}/sales/${path}/${order._id}/checkout`)
    }
  }

  billPause = () => {
    const order = this.props.order

    if (order.items.length !== 0) {
      return confirmCreateOrderDraft(this.props)
    }
  }

  render() {
    const { order, salesNote, pos, posCheckout, modal } = this.props
    const items = order.items

    let btnClass = 'btn btn-xxs width-full btn-shadow-none text-overflow-ellipsis'
    let checkoutPage = this.props.checkoutPage || false

    const disabled = !salesNote ? items.length === 0 : this.props.disabled

    if (disabled) btnClass = btnClass + ' btn-disabled'
    else btnClass = btnClass + ' waves-effect waves-light'

    return (
      <div className={css.orderManager}>

        <DiscountEditModal {...this.props} />
        <SrcEditModal {...this.props} />
        <VatEditModal {...this.props} />
        <WhtEditModal {...this.props} />

        { !modal &&
          <OrderDraftsListModal {...this.props} />
        }

        <ProductsListModal {...this.props} />

        { (!pos || posCheckout) &&
          <div className={css.addProductCheckOut}>
            <BtnModalOpen id="productsListModal">
              <a className="btn-shadow-none btn box-circle btn-submit waves-effect waves-light">
                <i className="material-icons font-2-0 i-middle">add</i>
              </a>
            </BtnModalOpen>
          </div>
        }


        { !posCheckout &&
          <div className={css.addProduct}>
            <BtnModalOpen id="productsListModal">
              <a className="btn-shadow-none btn box-circle btn-submit waves-effect waves-light">
                <i className="material-icons font-2-0 i-middle">add</i>
              </a>
            </BtnModalOpen>
          </div>
        }


        { !salesNote &&
          <div>
            <OrderPriceSummary order={order} />
          </div>
        }

        { !salesNote ?
          <div className={css.boxBtnManager}>

            <div className={css.boxButton}>
              <div className="pd-left-0">
                <button onClick={() => !disabled && this.openModal('discountEditModal')} className={`orange accent-4 ${btnClass}`}>
                  ส่วนลด
                </button>
              </div>

              <div>
                <button onClick={() => !disabled && this.openModal('couponEditModal')} className={`deep-purple darken-3 ${btnClass}`}>
                  คูปอง
                </button>
              </div>

              <div>
                <button onClick={() => !disabled && this.openModal('srcEditModal')} className={`brown darken-1 ${btnClass}`}>
                  เซอร์วิสชาร์จ
                </button>
              </div>

              <div className="pd-right-0">
                <button onClick={() => !disabled && this.openModal('vatEditModal')} className={`purple accent-4 ${btnClass}`}>
                  ภาษีมูลค่าเพิ่ม
                </button>
              </div>

              <div className="pd-left-0">
                <button onClick={() => !disabled && this.openModal('whtEditModal')} className={`pink ${btnClass}`}>
                  หัก ณ ที่จ่าย
                </button>
              </div>

              <div>
                { !modal ?
                  <button onClick={this.billPause} className={`light-green darken-4 ${btnClass}`}>
                    พักบิล
                  </button>
                :
                  <button disabled className={`${btnClass}`}>
                    พักบิล
                  </button>
                }
              </div>

              <div>
                { !modal ?
                  <button onClick={() => this.openModal('draftOrdersListModal', false)} className={`waves-effect waves-light btn btn-xs width-full btn-shadow-none light-blue darken-4`}>
                    เรียกบิล
                  </button>
                :
                  <button disabled className={`${btnClass}`}>
                    เรียกบิล
                  </button>
                }
              </div>

              <div className="pd-right-0">
                <button onClick={() => !disabled && clearOrder(this.props)} className={`red accent-4 ${btnClass}`}>
                  ล้างหน้าจอ
                </button>
              </div>
            </div>

            <table>
              <tbody>
                <tr>
                  { checkoutPage && !modal &&
                    <td width="37%" className="pd-0 pd-right-6 line-height-0">
                      <button onClick={this.props.goBackToMain} className={`${items.length === 0 ? 'btn-disabled' : 'waves-effect waves-light'} text-overflow-ellipsis light-green darken-1 btn btn-shadow-none width-full height-50 line-height-50`}>
                        <i className="material-icons left font-2-0">navigate_before</i> กลับ
                      </button>
                    </td>
                  }

                  <td className={`pd-0 line-height-0`}>
                    { items.length === 0 ?
                      <div className={`${css.monitorPriceTotal} cursor-not-allowed`} style={{ opacity: '0.5' }}>
                        <div className="hidden-xxs">ราคารวม</div>
                        <div>฿{ getNumeral(getTotalPriceAll(order), 2) }</div>
                      </div>
                    :
                      <div className={`${css.monitorPriceTotal}`}>
                        <div className="hidden-xxs hidden-xs">ราคารวม</div>
                        <div className="left-0-xxs left-0-xs">฿{ getNumeral(getTotalPriceAll(order), 2) }</div>
                      </div>
                    }
                  </td>

                  { !checkoutPage && !modal &&
                    <td width="37%" className="pd-0 pd-left-6 line-height-0">
                      <button onClick={this.checkout} className={`${items.length === 0 ? 'btn-disabled' : 'waves-effect waves-light'} text-overflow-ellipsis light-green darken-1 btn btn-shadow-none width-full height-50 line-height-50`}>
                        <i className="material-icons right font-2-0">navigate_next</i> ดำเนินการต่อ
                      </button>
                    </td>
                  }

                  { !checkoutPage && modal &&
                    <td width="37%" className="pd-0 pd-left-6 line-height-0">
                      <button onClick={() => this.props.dispatch(closeModal('orderItemsManagerModal')) } className={`${items.length === 0 ? 'btn-disabled' : 'waves-effect waves-light'} text-overflow-ellipsis light-green darken-1 btn btn-shadow-none width-full height-50 line-height-50`}>
                        <i className="material-icons left font-1-5">check</i> สำเสร็จ
                      </button>
                    </td>
                  }
                </tr>
              </tbody>
            </table>
          </div>
        :
          <div>
            <table>
              <tbody>
                <tr>
                  <td className="pd-0 pd-right-3">
                    <button disabled={disabled} onClick={() => !disabled && this.openModal('discountEditModal')} className={`orange accent-4 ${btnClass} mg-bottom-5`}>
                      ส่วนลด
                    </button>
                  </td>
                  <td className="pd-0 pd-left-3">
                    <button disabled={disabled} onClick={() => !disabled && this.openModal('srcEditModal')} className={`brown darken-1 ${btnClass} mg-bottom-5`}>
                      เซอร์วิสชาร์จ
                    </button>
                  </td>
                </tr>

                <tr>
                  <td className="pd-0 pd-right-3">
                    <button disabled={disabled} onClick={() => !disabled && this.openModal('vatEditModal')} className={`purple accent-4 ${btnClass}`}>
                      ภาษีมูลค่าเพิ่ม
                    </button>
                  </td>
                  <td className="pd-0 pd-left-3">
                    <button disabled={disabled} onClick={() => !disabled && this.openModal('whtEditModal')} className={`pink ${btnClass}`}>
                      หัก ณ ที่จ่าย
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      </div>
    )
  }
}

export default connect()(OrderButtonsManager)

import React, { Fragment, useEffect } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import Scrollbar from 'react-perfect-scrollbar'
import { connect } from 'react-redux'
import { Column, Row } from '../../../../../components/Columns'
import FetchData from '../../../../../components/FetchData'

// Components Global
import InputSearch from '../../../../../components/InputSearch'
import Pagination from '../../../../../components/Pagination'
import PreLoading from '../../../../../components/PreLoading'
import { openModal } from '../../../../../redux/App/AppActions'

// Actions
import { fetchProducts } from '../../../../../redux/Products/ProductsActions'
import { productValue } from '../../../../../redux/Products/ProductsValues'
import { getNumeral } from '../../../../../util/getNumeral'
import { ProductProfileAvater } from '../../../Products/components/ProductsUserInterface'
import ProductDetails from './components/ProductDetails/ProductDetails'



const SelectProductsList = (props) => {
  const { business } = props
  const where = `status=open,type=product,subtype=product`
  const limit = 20
  const fetchAction = {
    action: fetchProducts,
    actionQuery: {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id
    },
    id: business._id,
    where,
    countWhere: where,
    limit,
    sort: '_id',
    searchAttributes: [
      { name: 'name.local', options: 'i' },
      { name: 'code', options: 'i' },
      { name: 'items.name.local', options: 'i' },
      { name: 'items.barcode', options: 'i' },
    ]
  }

  const getItemsList = () => {
    let products

    if (props.productsSearch && props.productsSearch.status) {
      products = props.productsSearch.data
    } else {
      products = props.products
    }

    return products
  }

  const getItem = (item) => {
    if (item) {
      const { productItem } = item

      item.qty = 1
      item.priceOld = productItem.price
      item.price = productItem.price
      item.type = "product"

      props.getItem(item)
    }
  }

  const items = getItemsList()
  const columnProduct = 'column col-xxs-12 col-xs-12 col-sm-6 col-md-3 col-lg-2 col-xlg-2'
  const handleStay = (e) => e.stopPropagation()

  return (
    <Fragment>
      <Scrollbar>
        <div className="pd-20">

          { props.products.length !== 0 &&
            <Fragment>
              <div className="font-1-2">สินค้า</div>

              <div className="pd-right-10 pd-top-10">
                <InputSearch
                  {...props}
                  id="search-products-list"
                  placeholder={`ค้นหาสินค้า`}
                  getValueFromUrl
                  fetchAction={fetchAction}
                />
              </div>
            </Fragment>
          }

          <Pagination {...props}
            id="listProducts"
            notReloadForUrlChange
            fetchAction={fetchAction}
            itemsData={props.products}
            searchData={props.productsSearch}
            itemsPerPage={limit}
            showPage={5}>

            <PreLoading id="listProducts">
              <FetchData
                length={items.length}
                className="pd-100"
                style={{ position: props.products.length !== 0 && 'relative' }}
                icon="archive"
                msg="ไม่มีรายการ">

                <Row>
                  <ReactCSSTransitionGroup
                    transitionName="example"
                    transitionEnterTimeout={1000}
                    transitionLeaveTimeout={1000}>

                    { items.map((product, i) => {
                      const productOrigin = product

                      product = productValue({ product })
                      const { singleItem, firstItemValue, singleItemValue } = product

                      let onClick

                      if (singleItem) {
                        onClick = () => getItem({ product: productOrigin, productItem: singleItemValue })
                      } else {
                        onClick = () => props.dispatch(openModal(`productsListModal-${product._id}`))
                      }

                      let qty = 0

                      const items = props.order.items.filter(item => item.product._id === product._id)
                      items.map(item => qty += item.qty)

                      const removeItem = () => props.removeManyItems(items)

                      return (
                        <Column key={product._id} col={columnProduct}>
                          <div onClick={onClick} className="border-radius-3 border-efefef mg-bottom-10 mg-right-10 bg-fff" style={{ cursor: 'pointer' }}>
                            <div className="pd-8 pst-relative">
                              <div className="width-full pd-top-full position-relative">
                                <div className="position-absolute top-0 left-0 right-0 bottom-0">
                                  <ProductProfileAvater product={product} item={firstItemValue} fontSize="2rem" />
                                </div>
                              </div>

                              { qty > 0 &&
                                <div
                                  onClick={handleStay}
                                  className="pst-absolute top-8 right-8 width-100"
                                  style={{ zIndex: '1', display: 'flex', flexDirection: 'row' }}>

                                  <div
                                    className="width-50 line-height-50 height-50 center color-fff font-1-5"
                                    style={{ background: '#00000096' }}>
                                    {qty}
                                  </div>

                                  <div onClick={removeItem} className="btn-remove-jsx width-50 line-height-50 height-50 center color-fff mg-left-5" style={{ background: '#00000096' }}>
                                    <i className="material-icons-outlined i-middle">delete</i>
                                  </div>

                                  <style jsx>{`
                                    .btn-remove-jsx:hover {
                                      background: red !important;
                                    }
                                  `}</style>
                                </div>
                              }
                            </div>

                            <div className="pd-left-8 pd-right-8">
                              <div className=" ">
                                <div className="height-20 line-height-20 color-000 font-0-9 text-ellipsis">
                                  { product.nameLang }
                                </div>
                                <div className="height-20 line-height-20 color-c0c0c0 font-0-8 text-ellipsis">
                                  รหัส { product.code || '-' }
                                </div>
                              </div>

                              <div className="height-30 line-height-30 mg-top-8 center border-top-efefef">
                                <div className="font-1-0 color-price">
                                  ฿{ getNumeral(firstItemValue.price, 'auto') }
                                </div>
                              </div>

                              <ProductDetails
                                {...props}
                                id={`productsListModal-${product._id}`}
                                getItem={getItem}
                                product={product}
                                productId={product._id}
                              />
                            </div>
                          </div>
                        </Column>
                      )
                    }) }
                  </ReactCSSTransitionGroup>
                </Row>
              </FetchData>
            </PreLoading>
          </Pagination>
        </div>
      </Scrollbar>
    </Fragment>
  )
}

const mapStateToProps = (state, props) => {
  return {
    products: state.products.data,
    productsSearch: state.products.search
  }
}

export default connect(mapStateToProps)(SelectProductsList)

import React, { Fragment, useRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Components Global
import { Row, Column } from 'components/Columns'
import FetchData from 'components/FetchData'
import PreLoading from 'components/PreLoading'
import ItemSelected from 'components/ItemSelected'
import { BtnModalOpen } from 'components/Modals/BtnModal'
import DropdownDefault from 'components/DropdownDefaultV2'

// Components Local
import InputSearchItems from '../InputSearchItems'
import Pagination from '../Pagination'

// css
import css from './css/pageItems.css'
import UseSelector from './components/UseSelector'
import UseReorderNo from './components/UseReorderNo'


const PageItems = (props) => {
  const paginationRef = useRef()

  const getItemsList = () => {
    let items

    if (props.items && props.searchData && props.searchData.status) {
      items = props.searchData.data
    } else {
      items = props.items
    }

    return items
  }

  const {
    columnTitle,
    useFilter,
    foldersManage,
    layoutFull,
    match,
    btnAddMore,
    addItemModal,
    title,
    icon,
    fetchAction,
    componentTitle,
    componentHeader,
    modal,
    btnAdd
  } = props

  const CompTitle = componentTitle
  const CompHeader = componentHeader
  const CompForm = useFilter && useFilter.compForm
  const items = getItemsList()
  const { column1, column2 } = columnTitle || {}
  const columnTitle1 = column1 || 'column col-xxs-12 col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xlg-7'
  const columnTitle2 = column2 || 'column col-xxs-12 col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xlg-5'

  let btnModal

  if (modal && btnAdd) {
    btnModal = (
      <BtnModalOpen id={modal.id}>
        <a className={`mg-bottom-10 mg-top--15 waves-effect waves-light btn btn-shadow-none ${modal.className}`}>
          <i className="material-icons left">add</i> {modal.text}
        </a>
      </BtnModalOpen>
    )
  }

  return (
    <div className={`${props.className}`}>

      { (btnAddMore && props.items.length !== 0) &&
        <div className={css.boxBtnAdd}>
          <BtnModalOpen id={addItemModal.id}>
            <a className="btn-floating btn-large waves-effect waves-light">
              <i className="material-icons font-2-0">add</i>
            </a>
          </BtnModalOpen>
        </div>
      }

      { !props.hiddenHeader &&
        <Fragment>
          { !foldersManage &&
            <Row className="pd-bottom-10">

              { !props.hiddenTitle &&
                <Column col={columnTitle1}>
                  <div className="pd-right-10 pd-top-3">
                    <span className="font-1-4">{title}</span>
                  </div>
                </Column>
              }

              { !props.hiddenSearch &&
                <Column col={columnTitle2}>
                  <table>
                    <tbody>
                      <tr>
                        { props.useSelector &&
                          <td className="pd-0 width-50 text-left">
                            <UseSelector {...props} />
                          </td>
                        }

                        { props.useReorderNo &&
                          <td className="pd-0 width-50 text-left">
                            <UseReorderNo {...props} paginationRef={paginationRef} />
                          </td>
                        }

                        <td className="pd-0">
                          <InputSearchItems
                            {...props}
                            id={`search-${props.id}`}
                            placeholder={`ค้นหา`}
                            getValueFromUrl
                            fetchAction={fetchAction}
                            hiddenSearchCamera={props.hiddenSearchCamera}
                          />
                        </td>

                        { props.useFilter &&
                          <td className="pd-0 width-50 text-right">
                            <DropdownDefault
                              right
                              className="width-full"
                              contentClassName="min-width-250 mg-bottom-50"
                              dropdownClassName="border-radius-5">

                              <a
                                className="btn pd-0 btn-mini width-40 height-40 line-height-40 btn-shadow-none border-radius-3">

                                <i className="material-icons font-1-5">filter_list</i>
                              </a>

                              <div className="text-left">
                                <div className="bg-fafafa height-35 line-height-35 pd-left-10 font-0-8 border-bottom- border-bottom-eee border-top-radius-3">กรองข้อมูล</div>
                                { props.useFilter.compForm}
                              </div>
                            </DropdownDefault>
                          </td>
                        }
                      </tr>
                    </tbody>
                  </table>

                  { props.useFilter && props.useFilter.compLists }
                </Column>
              }
            </Row>
          }

          { componentHeader && <CompHeader /> }
        </Fragment>
      }

      { foldersManage &&
        <div style={{ borderBottom: '1px solid #e6e6e6', marginBottom: '5px' }}>
          <Row className="pd-bottom-10">
            <Column col="column col-xxs-12 col-xs-6">
              <div className="pd-right-10 pd-top-3">
                <span>{title}</span>
              </div>
            </Column>
            <Column col="column col-xxs-12 col-xs-6 text-right">
              <BtnModalOpen id={addItemModal.id}>
                <button className="waves-effect btn-shadow-none waves-light btn btn-xs pd-right-10">
                  <i className="material-icons left">add</i> {addItemModal.name}
                </button>
              </BtnModalOpen>
            </Column>
          </Row>
        </div>
      }

      { fetchAction ?
        <Pagination
          ref={paginationRef}
          preLoading={props.preLoading}
          id={props.id}
          dispatch={props.dispatch}
          history={props.history}
          location={props.location}
          fetchAction={fetchAction}
          itemsData={props.items}
          searchData={props.searchData}
          itemsPerPage={fetchAction.body.limit}
          match={match}
          foldersManage={foldersManage}
          showPage={5}
          hiddenPaginationNumber={props.hiddenPaginationNumber}
          notReloadForUrlChange={props.notReloadForUrlChange}
          dataReloadForUrlChange={props.dataReloadForUrlChange}
          notReloadForUrlSearchChange={props.notReloadForUrlSearchChange}
          currentPageName={props.currentPageName}
          onRef={props.onRef}>

          <PreLoading
            id={props.id}
            className="pd-top-100"
            style={{ position: (foldersManage || layoutFull) && 'relative' }}>

            <FetchData
              length={items.length}
              textClassName={foldersManage && 'font-1-0'}
              style={{ position: (props.items.length !== 0 || foldersManage || layoutFull) && 'relative' }}
              icon={!foldersManage && icon}
              className="pd-top-100"
              componentItemsNone={props.componentItemsNone}
              modal={!foldersManage && addItemModal}
              msg={`ไม่มี${title}`}>

                { componentTitle &&
                  <CompTitle />
                }

                { props.unSelect ?
                  <ItemSelected location={props.location} unSelect={props.unSelect}>
                    <div>
                      <div className="text-right">
                        {btnModal}
                      </div>

                      { React.cloneElement(props.children, { items }) }
                    </div>
                  </ItemSelected>
                :
                  <div>
                    <div className="text-right">
                      {btnModal}
                    </div>

                    { React.cloneElement(props.children, { items })}
                  </div>
                }
            </FetchData>
          </PreLoading>
        </Pagination>
      :
        <PreLoading
          id={props.id}
          className="pd-top-100"
          style={{ position: (foldersManage || layoutFull) && 'relative' }}>

          <FetchData
            length={items.length}
            textClassName={foldersManage && 'font-1-0'}
            style={{ position: (props.items.length !== 0 || foldersManage || layoutFull) && 'relative' }}
            icon={!foldersManage && icon}
            className="pd-top-100"
            modal={!foldersManage && addItemModal}
            componentItemsNone={props.componentItemsNone}
            msg={`ไม่มี${title}`}>

              { componentTitle &&
                <CompTitle />
              }

              { props.unSelect ?
                <ItemSelected location={props.location} unSelect={props.unSelect}>
                  <div>
                    <div className="text-right">
                      {btnModal}
                    </div>

                    { React.cloneElement(props.children, { items }) }
                  </div>
                </ItemSelected>
              :
                <div>
                  <div className="text-right">
                    {btnModal}
                  </div>

                  { React.cloneElement(props.children, { items })}
                </div>
              }
          </FetchData>
        </PreLoading>
      }
    </div>
  )

}

PageItems.propTypes = {
  children: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

function mapStateToProps(store, props) {
  return {
    preLoading: store.app.loads.filter(load => load === props.id)[0],
  }
}

export default connect(mapStateToProps)(PageItems)

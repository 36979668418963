import React, { Fragment } from 'react'

// Components Global
import TextInput from '../../../../../components/Inputs/TextInput'
import InputCheckDuplicate from '../../../../../components/Inputs/InputCheckDuplicate'
import InputNumeral from '../../../../../components/Inputs/InputNumeral'
import Textarea from '../../../../../components/Inputs/Textarea'
import RadioGroup from '../../../../../components/Inputs/RadioGroup'
import Select from '../../../../../components/Inputs/Select'
import { Row, Column } from '../../../../../components/Columns'

// Actions
import { checkProductDuplicate } from '../../../../../redux/Products/ProductsActions'


export const ProductName = (props) => {
  const lang = props.lang || 'local'

  return (
    <TextInput
      labelHide={props.update}
      label="ชื่อสินค้า"
      id={`name.${lang}`}
      value={props.value}
      placeholder="ชื่อสินค้า"
      inputType="text"
      type="text"
      required
      iconValid />
  )
}

export const ProductUnit = (props) => {
  const lang = props.lang || 'local'

  return (
    <TextInput
      labelHide={props.update}
      label="หน่วยเรียก"
      id={`unit.${lang}`}
      value={props.value}
      placeholder="หน่วยเรียก"
      inputType="text"
      type="text"
      iconValid
    />
  )
}

export const ProductCode = (props) => {
  const { business, value, product, onChange } = props
  const query =`businesses_id=${business._id}`

  let unselectId

  if (product && product._id) {
    unselectId = product._id
  }

  return (
    <InputCheckDuplicate
      id="code"
      label="รหัสสินค้า"
      type="code"
      value={value}
      params_business_id={business._id}
      action={checkProductDuplicate}
      useQuery={{
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id
      }}
      query={query}
      unselectId={unselectId}
      onChange={onChange}
    />
  )
}




export const ProductItemBarcode = (props) => {
  const { business, value, product, item, onChange } = props
  const query =`businesses_id=${business._id}&status=!trash`

  let unselectId

  if (item && item._id) {
    unselectId = item._id
  }

  let id

  if (props.single) {
    id = `items[0].barcode`
  } else {
    id = `barcode`
  }

  return (
    <InputCheckDuplicate
      id={id}
      element="items.barcode"
      label="บาร์โค้ด"
      type="code"
      value={value}
      params_business_id={business._id}
      action={checkProductDuplicate}
      useQuery={{
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id
      }}
      query={query}
      unselectId={unselectId}
      onChange={onChange}
      barcodeScanner={{
        id: 'create-item-barcode'
      }}
    />
  )
}

export const ProductDescriptions = (props) => {
  const lang = props.lang || 'local'

  return (
    <Textarea
      labelHide={props.update}
      label="รายละเอียดสินค้า"
      placeholder="รายละเอียดสินค้า"
      id={`descriptions.${lang}`}
      value={props.value}
      iconValid />
  )
}

export const ProductDescriptionFull = (props) => {
  const lang = props.lang || 'local'

  return (
    <Textarea
      labelHide={props.update}
      label="รายละเอียดสินค้าแบบเต็ม"
      placeholder="รายละเอียดสินค้าแบบเต็ม"
      id={`descriptionsFull.${lang}`}
      value={props.value}
      iconValid
    />
  )
}

export const ProductItemName = (props) => {
  const lang = props.lang || 'local'
  let id

  if (props.single) {
    id = `items[0].name.${lang}`
  } else {
    id = `name.${lang}`
  }

  return (
    <TextInput
      labelHide={props.update}
      label="ชื่อตัวเลือก"
      id={id}
      value={props.value || ''}
      placeholder="ชื่อตัวเลือก (ตัวอย่าง สีแดง Size M)"
      type="text"
      required
      iconValid />
  )
}

export const ProductItemCode = (props) => {
  let id

  if (props.single) {
    id = `items[0].code`
  } else {
    id = `code`
  }

  return (
    <input data-id={id} type="hidden" value={props.value || ''} />
  )
}

export const ProductItemPrice = (props) => {
  let id

  if (props.single) {
    id = `items[0].price`
  } else {
    id = `price`
  }

  return (
    <InputNumeral
      required
      label="ราคา"
      unitName="(บาท)"
      placeholder="ราคา"
      id={id}
      decimal="2"
      value={props.value}
      iconValid
    />
  )
}

export const ProductItemQty = (props) => {
  let id

  if (props.single) {
    id = `items[0].qty`
  } else {
    id = `qty`
  }

  return (
    <InputNumeral
      required
      label="คลัง"
      placeholder="คลัง"
      active
      id={id}
      decimal="0"
      value={props.value}
      disabled={props.disabled}
      iconValid
    />
  )
}

export const ProductItemWeight = (props) => {
  let id

  if (props.single) {
    id = `items[0].weight`
  } else {
    id = `weight`
  }

  return (
    <InputNumeral
      label="น้ำหนัก"
      unitName="(กิโลกรัม)"
      placeholder="น้ำหนัก"
      id={id}
      decimal="2"
      value={props.value}
      iconValid
    />
  )
}

export const ProductStockAdjust = (props) => {
  return (
    <RadioGroup
      required
      label="การนับสต็อกสินค้า"
      id="stockAdjust"
      className="mg-bottom-20"
      onChange={props.onChange}
      value={props.value === true || props.value === 'true'  ? 'true' : 'false'}
      radios={[
        {
          value: 'true',
          label: 'นับสต็อกสินค้า'
        },
        {
          value: 'false',
          label: 'ไม่นับสต็อกสินค้า'
        }
      ]}
    />
  )
}

export const ProductItemChoice = (props) => {
  if (props.type) {
    return (
      <Fragment>
        { props.type === 'single' &&
          <Fragment>
            <input type="hidden" data-id="itemChoice" value="single" />
            <ProductItemSingle {...props} />
          </Fragment>
        }

        { props.type === 'group' &&
          <Fragment>
            <input type="hidden" data-id="itemChoice" value="multiple" />
            <ProductSubitemChoiceName {...props} value="" />
          </Fragment>
        }
      </Fragment>
    )

  } else {
    let radios = [
      {
        value: 'single',
        label: 'ไม่มีตัวเลือกย่อย',
        child: !props.childOff && <ProductItemSingle {...props} />
      },
      {
        value: 'multiple',
        label: 'มีตัวเลือกย่อย (ตัวอย่าง สี ขนาด เป็นต้น)',
        child: !props.childOff && <ProductSubitemChoiceName {...props} value="" />
      }
    ]

    return (
      <RadioGroup
        required
        childExternal
        label="ตัวเลือกสินค้าย่อย"
        id="itemChoice"
        value={props.value || ''}
        radios={radios}
        className="mg-bottom-20"
      />
    )
  }
}

export const ProductSubitemChoiceName = (props) => {
  const lang = props.lang || 'local'

  return (
    <TextInput
      labelHide={props.update}
      label="ชื่อหัวข้อตัวเลือก (ตัวอย่าง สี ขนาด เป็นต้น)"
      id={`subitemChoiceName.${lang}`}
      value={props.value}
      placeholder="ชื่อหัวข้อตัวเลือก (ตัวอย่าง สี ขนาด เป็นต้น)"
      inputType="text"
      type="text"
      required
      iconValid />
  )
}

export const ProductSubitemChoiceWebDisplay = (props) => {
  const options = [
    { value: '', name: 'อัตโนมัติ' },
    { value: 'text', name: 'ชื่อตัวเลือก' },
    { value: 'img', name: 'รูปภาพตัวเลือก' }
  ]

  return (
    <Select
      id="subitemChoiceWebDisplay"
      label="แสดงตัวเลือกบนเว็บ"
      value={props.value || ''}
      default
      options={options}
      onChange={props.onChange}
    />
  )
}

export const ProductItemSingle = (props) => {
  const { setStockAdjust, setWeight, setBarcode } = props

  return (
    <Row>
      <Column col="column col-sm-12">
        <ProductItemCode single={!props.sub} />
      </Column>

      <Column col="column col-sm-12">
        <ProductItemPrice single={!props.sub} />
      </Column>

      { setStockAdjust.use &&
        <Column col="column col-sm-12">
          <ProductItemQty disabled={!props.stockAdjust} single={!props.sub} />
        </Column>
      }

      { setWeight.use &&
        <Column col="column col-sm-12">
          <ProductItemWeight single={!props.sub} />
        </Column>
      }

      { setBarcode.use && setBarcode.createShow &&
        <Column col="column col-sm-12">
          <ProductItemBarcode {...props} value={undefined} single={!props.sub} />
        </Column>
      }
    </Row>
  )
}

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import css from './css/dropdown.css'


// targetNotClose


const useOutsideAlerter = (ref, closeDropdown) => {
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        closeDropdown()
      }
    }

    document.addEventListener("click", handleClickOutside)
    //document.addEventListener("focus", handleClickOutside)


    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [ref])
}


const DropdownDefaultV2 = forwardRef((props, ref) => {
  const wrapperRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [openTransition, setOpenTransition] = useState(false)

  useImperativeHandle(ref, () => ({
    open() {
      openDropdown()
    },

    close() {
      closeDropdown()
    },
  }))

  useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  useEffect(() => {
    if (props.getOpenValue) {
      props.getOpenValue(open)
    }
  }, [open])

  const openDropdown = () => {
    if (open) {
      if (!props.targetNotClose) {
        closeDropdown()
      }
    } else {
      setOpen(true)
      setTimeout(() => {
        setOpenTransition(true)
      }, 100)
    }
  }

  const clickOpen = (e) => {
    openDropdown()
  }

  const closeDropdown = () => {
    setOpenTransition(false)
    setTimeout(() => {
      setOpen(false)
    }, 500)
  }

  useOutsideAlerter(wrapperRef, closeDropdown)

  const styleContent = props.styleContent || {}
  const style = {
    ...styleContent,
    visibility: openTransition ? 'visible' :'hidden',
    opacity: openTransition ? '1' :'0',
    left: props.left && 0,
    right: props.right && 0,
    right: !props.left && props.right && 0
    //height: this.state.openTransition ? 'auto' :'0'
  }

  return (
    <div ref={wrapperRef} className={`${css.boxDropdown} ${props.className || ''}`} style={props.style}>
      <div className={css.boxClick} onClick={(e) => !props.blockClickOpen && clickOpen(e)}>
        { props.children[0] }
      </div>

      { open &&
        <div className={`${css.content} ${props.contentClassName || ''} content-jsx`} style={style}>
          <div className={`${css.dropdown} ${props.dropdownClassName || ''}`}>
            { props.children[1] }
          </div>

          <div className="height-30"></div>

          <style jsx>{`
            .content-jsx {
              min-width: ${props.widthAuto && 'unset !important'};
              max-width: ${props.widthAuto && 'unset !important'};
            }
          `}</style>
        </div>
      }
    </div>
  )
})

export default DropdownDefaultV2

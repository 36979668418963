import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import AlbumManager from '../../../../../components/AlbumManager'
import Section from '../../../../../components/Section'

// Redux Actions
import { updatePlace, uploadImagesPlace } from '../../../../../redux/Places/PlacesActions'


class PlacePhotosPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { place } = this.props

    return (
      <div className={`container-full-scroll`}>
        <div className={`box`}>
          <Section title="ภาพหน้าปกสไลด์โชว์">
            <AlbumManager
              {...this.props}
              id="imagesProfile"
              thumbnail="m"
              images={place.images.filter(img => img.album === 'cover')}
              btnAdd={{ maxFile: 10, text: 'เพิ่มภาพ', icon: 'add' }}
              delete
              upload={{
                album: 'cover',
                crop: {
                  title: 'ภาพหน้าปกสไลด์โชว์',
                  width: 1200,
                  height: 500,
                  circle: false,
                  style: { width: '100%', height: 'auto' }
                },
                multiple: false,
                maxFile: 1,
              }}
              action={{
                update: updatePlace,
                uploadImage: uploadImagesPlace,
                params: {
                  business: this.props.business,
                  _id: place._id,
                  imagePrefix: 'places'
                }
              }}
            />
          </Section>

          <Section title="ภาพโปรไฟล์">
            <AlbumManager
              {...this.props}
              id="imagesProfile"
              thumbnail="m"
              images={place.images.filter(img => img.album === 'profile')}
              btnAdd={{ maxFile: 1, text: 'เพิ่มภาพ', icon: 'add' }}
              delete
              upload={{
                album: 'profile',
                crop: { title: 'ภาพโปรไฟล์', width: 250, height: 250, circle: false },
                multiple: false,
                maxFile: 1
              }}
              action={{
                update: updatePlace,
                uploadImage: uploadImagesPlace,
                params: {
                  business: this.props.business,
                  _id: place._id,
                  imagePrefix: 'places'
                }
              }}
            />
          </Section>


          <Section title="รูปภาพสถานที่ท่องเที่ยว">
            <AlbumManager
              {...this.props}
              id="imagesOther"
              thumbnail="q"
              images={place.images.filter(img => img.album === null)}
              btnAdd={{ text: 'เพิ่มภาพ', icon: 'add' }}
              upload={{ album: null, multiple: true, maxFile: 10 }}
              delete
              action={{
                update: updatePlace,
                uploadImage: uploadImagesPlace,
                params: {
                  business: this.props.business,
                  _id: place._id,
                  imagePrefix: 'places'
                }
              }}
            />
          </Section>

          <Section title="คลังรูปภาพ">
            <AlbumManager
              {...this.props}
              id="imagesGallery"
              thumbnail="q"
              images={place.images.filter(img => img.album === 'gallery')}
              btnAdd={{ text: 'เพิ่มภาพ', icon: 'add' }}
              upload={{ album: 'gallery', multiple: true, maxFile: 10 }}
              delete
              action={{
                update: updatePlace,
                uploadImage: uploadImagesPlace,
                params: {
                  business: this.props.business,
                  _id: place._id,
                  imagePrefix: 'places'
                }
              }}
            />
          </Section>
        </div>
      </div>
    )
  }
}

export default connect()(PlacePhotosPage)

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import TH from './imgs/TH.png'
import EN from './imgs/EN.png'

import DropdownDefault from '../DropdownDefaultV2'
import { setLang } from '../../redux/App/AppActions'

const LangSetting = (props) => {
  let { lang, business } = props
  const { langLocal } = business

  if (lang === 'local') {
    lang = langLocal
  }

  const getFlag = (lang) => {
    let flag

    if (lang === 'TH') {
      flag = TH
    } else if (lang === 'EN') {
      flag = EN
    }

    return flag
  }

  const handleChange = (value) => {
    if (value === langLocal) {
      value = 'local'
    }

    props.dispatch(setLang(value))
  }

  const choices = business.langs.filter(v => v !== lang)

  let contentClassName = "top-45 right--4 width-50 mg-top--2"

  if (props.left) {
    contentClassName = "top-0 right-45 height-50 mg-top--2"
  }

  return (
    <Fragment>
      { business.langMulti === true &&
        <div style={{ display: 'flex' }}>
          <DropdownDefault
            style={{}}
            styleContent={{}}
            widthAuto
            contentClassName={contentClassName}>

            <div className="border-1 pd-2 border-radius-circle icon-lang line-height-0">
              <img src={getFlag(lang)} className="height-35" style={{ cursor: 'pointer' }} />
            </div>

            <div className="center">
              { choices.map(l => {
                return (
                  <a
                    key={l}
                    onClick={() => handleChange(l)}
                    style={{ cursor: 'pointer', display: 'flex', flexDirection: props.left ? 'row' :  'column' }}>

                    <div className="pd-5 icon-list line-height-0">
                      <img src={getFlag(l)} className="height-35" />
                    </div>
                  </a>
                )
              }) }
            </div>
          </DropdownDefault>

          <style jsx>{`
            .icon-lang {
              border-color: #c0c0c0;
            }
            .icon-lang:hover {
              border-color: #039be5;
            }
            .icon-list:hover {
              background: #039be5;
            }
          `}
          </style>
        </div>
      }
    </Fragment>
  )
}

const mapStateToProps = (state, props) => {
  const { username } = props.match.params

  return {
    lang: state.app.lang,
    business: state.businesses.data.filter(biz => biz.username === username)[0]
  }
}

export default withRouter(connect(mapStateToProps)(LangSetting))


import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../components/Modals/Modal'
import { Row, Column } from '../../../../../components/Columns'
import Section from '../../../../../components/Section'
import EditData from '../../../../../components/EditData'
import DatasList from '../../../../../components/DatasList'

// Components Local
import {
  BoxStatus,
  PageName,
  PageStatusSwitch
} from '../PageForms'

// Actions Redux
import { updatePage } from '../../../../../redux/Pages/PagesActions'


class PageTemplateCreateModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleSubmit = (params, formName) => {
    const { business, page } = this.props

    if (params) {
      this.props.dispatch(updatePage(business._id, page._id, {
        body: params,
        loadId: formName,
        fieldId: formName,
        props: this.props,
        toastMsg: 'แก้ไขรูปแบบแล้ว'
      }))
    }
  }


  render() {
    const lang = this.props.lang || 'local'
    const { page } = this.props
    const id = `${page._id}-pageTemplateUpdateModal`

    return (
      <Modal
        id={id}
        headerTitle="แก้ไขรูปแบบเทมเพลต"
        blockBgModal
        padding="30px 0px 0px 0px"
        width="650px">

        <Row className="pd-0">
          <Section title="ข้อมูลเทมเพลต">
            <EditData
              name="name"
              submit={this.handleSubmit}>

              <div>
                <DatasList
                  style={{ border: '0' }}
                  items={[
                    {
                      labelTop: true,
                      label: 'ชื่อเทมเพลต',
                      value: page.name[lang],
                      icon: 'account_box'
                    }
                  ]}
                />
              </div>

              <div className="pd-top-15">
                <Row>
                  <Column col="column col-xs-12">
                    <PageName label="ชื่อรูปแบบเทมเพลต" value={page && page.name} />
                  </Column>
                </Row>
              </div>
            </EditData>
          </Section>

          <Section title="การแสดงผลส่วนหัว (Header)">
            <BoxStatus title="แสดงส่วนหัว (Header)" status={page.headerShow}>
              <PageStatusSwitch {...this.props} title="ส่วนหัว (Header) " name="headerShow" />
            </BoxStatus>

            <BoxStatus title="ส่วนหัวแบบปกติ (Header Default)" status={page.headerDefaultShow}>
              <PageStatusSwitch {...this.props} title="ส่วนหัวแบบปกติ" name="headerDefaultShow" />
            </BoxStatus>

            <BoxStatus title="ส่วนหัวแบบคงที่ (Header Fixed)" status={page.headerFixedShow}>
              <PageStatusSwitch {...this.props} title="ส่วนหัวแบบคงที่" name="headerFixedShow" />
            </BoxStatus>
          </Section>

          <Section title="การแสดงผลส่วนท้าย (Footer)">
            <BoxStatus title="ส่วนท้าย (Footer)" status={page.footerShow}>
              <PageStatusSwitch {...this.props} title="ส่วนท้าย (Footer) " name="footerShow" />
            </BoxStatus>
          </Section>
        </Row>
      </Modal>
    )
  }
}

export default connect()(PageTemplateCreateModal)

import React, { Component, Fragment, useEffect, useState } from 'react'
import cuid from 'cuid'

// Components Global
import InputDisabled from '../InputDisabled'
import Required from '../Required'
import { getFloat } from '../../../util/getNumeral'

//maxValue="100"
//notMinus
//decimal="3"
//valueForSplace="0"
//decimalFull
//minValue="1"
//notMinus
//decimal="3"

const InputNumeral = (props) => {
  const [id, setId] = useState('')
  const [value, setValue] = useState('')
  const [valueFormat, setValueFormat] = useState('')

  useEffect(() => {
    const id = props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()

    setId(randomId+id)
  }, [])

  useEffect(() => {
    const display = props.multi ? false : true

    onChange(null, props.value, display)
  }, [props.value, props.reload])

  useEffect(() => {
    //callbackValue(value)
  }, [value])

  const callbackValue = (value) => {
    let newValue = parseFloat(value)
    newValue = !isNaN(newValue) ?  newValue : ''

    if (props.onChange) {
      if (props.i !== undefined) {
        props.onChange(props.i, value)
      } else {
        props.onChange(value)
      }
    }
  }

  const onBlur = (e) => {
    if (props.decimal) {
      onChange(e, null, true)
    }
  }

  const onChange = (e, value='', display=false) => {
    if (e) {
      value = e.target.value
    }

    if (value) {
      value = value.toString()
      value = value.replaceAll(',', '')
    }

    if (props.notMinus && value) {
      value = value.replaceAll('-', '')
    }

    if (value && value.length !== 0) {
      const pattern = /^(-)?([0-9]*)([.])?([0-9]*)$/g

      if (value === '-') {
        setValueFn('-', display)
      } else {
        if (pattern.test(value)) {
          setValueFn(value, display)
        }
      }
    } else {
      if (props.valueForSplace) {
        setValueFn(props.valueForSplace, display)
      } else {
        setValueFn('', display)
      }
    }
  }

  const setValueFn = (value, display) => {
    if (value === '') {
      setValue('')
      setValueFormat('')

      if (!display) {
        callbackValue('')
      }

    } else if (value === '-') {
      setValue('')
      setValueFormat('-')

    } else if (!isNaN(value)) {
      value = checkMinMax(value)
      const valueFormat = getValueFormat(value, display)

      setValue(value)
      setValueFormat(valueFormat)

      if (!display) {
        value = checkDecimal(value)
        callbackValue(value)
      }
    } else {
      if (!display) {
        callbackValue('')
      }
    }
  }

  const getValueFormat = (value, display) => {
    if (value) {
      if (props.decimal) {
        return getFloat({
          value,
          display,
          decimal: props.decimal,
          decimalFull: props.decimalFull
        })
      } else {
        value = parseFloat(value)

        return value.toLocaleString('en-US')
      }
    } else {
      return value
    }
  }

  const checkMinMax = (value) => {
    if (props.maxValue) {
      const maxValue = parseFloat(props.maxValue)

      if (maxValue) {
        value = value <= maxValue ? value : maxValue
      }
    }

    /*if (props.minValue) {
      const minValue = parseFloat(props.minValue)

      if (minValue) {
        value = value >= minValue ? value : minValue
      }
    }*/

    return value
  }

  const checkDecimal = (value) => {
    if (props.decimal) {
      value = value.toString()

      const split = value.split('.')
      let valueInt = split[0]
      let valueDecimal = split[1]

      if (valueInt && valueDecimal) {
        const decimal = parseInt(props.decimal)

        let decimalLength

        if (valueDecimal.length < decimal) {
          decimalLength = valueDecimal.length
        } else {
          decimalLength = decimal
        }

        value = parseFloat(value)
        value = value.toFixed(decimalLength)
      }
    }

    return value
  }

  const { styleName, styleJsx, styleGlobal, inputForSite, test } = props

  return (
    <Fragment>
      {!props.disabled ?
        <Required
          {...props}
          id={id}
          classId={id}
          className={`${props.requiredClassName} mg-top--1`}
          labelClassName={props.labelClassName}
          label={props.label}
          unitName={props.unitName}
          type="text"
          value={!props.multi ? value : props.multiValue}
          required={props.required}
          iconValid={props.iconValid}>

          <Fragment>
            <input
              type="text"
              id={id}
              value={valueFormat}
              data-value={value}
              className={`${inputForSite ? 'input-site' : 'input'} ${styleGlobal ? ' input-global-jsx' : ''} input-jsx ${props.required ? `required` : ''} ${props.inputClassName || ''}`}
              placeholder={props.placeholder}
              onChange={onChange}
              onBlur={onBlur}
              data-not-save={true}
              style={props.inputStyle}
              focus={props.focus}
            />

            <input
              type="hidden"
              value={value}
              data-id={props.id}
              data-not-save={props.notSave}
            />
          </Fragment>
        </Required>
      :
        <InputDisabled {...props} value={value} valueFormat={valueFormat} />
      }
    </Fragment>
  )
}

export default InputNumeral

import React from 'react'

// Values
import { conversationChannelValue } from 'core/redux/ConversationChannels/ConversationChannelsValues'


export const ConversationChannelItem = (props) => {
  const { business, history, location, match, openSidebar } = props

  const { conversation_channels_id, status } = match.params

  let channel = props.data
  channel = conversationChannelValue({ conversationChannel: channel })

  const { platform } = channel


  const goto = () => {
    let path = `/${business.username}/conversations/settings/channels/${channel._id}`

    if (location.search) {
      path = `${path}${location.search}`
    }

    openSidebar()
    history.push(path)
  }

  return (
    <tr
      style={{ userSelect: 'none' }}
      onClick={() => goto()}
      className={conversation_channels_id === channel._id ? 'trActive' : ''}>

      <td className="width-50 pst-relative">
        <div className="overflow-hidden border-ddd border-1 height-40 width-40 bg-fff border-radius-5 dp-flex flex-direction-column justify-content-center align-items-center">
          <i className={`${platform.icon} font-2-0`} style={{ color: platform.color }}></i>
        </div>

        <div className="pst-absolute right-0 top-0">
          { channel.channelVerify ?
            <i className='material-icons color-success'>check_circle</i>
          :
            <i className='material-icons color-error'>info</i>
          }
        </div>
      </td>
      <td>
        <div className="font-0-9">{channel.displayName}</div>

        <div className="font-0-7">
          { channel.channelVerify ?
            <span className='color-success'>เชื่อมต่อแล้ว</span>
          :
            <span className='color-error'>พบปัญหาการเชื่อมต่อ</span>
          }
        </div>
      </td>
      { conversation_channels_id === 'all' &&
        <td className="text-right">
          <a className="btn btn-shadow-none height-30 line-height-30">
            ดูรายละเอียด
          </a>
        </td>
      }
    </tr>
  )
}

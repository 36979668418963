import React from 'react'

const PrivacyPolicy = (props) => {
  return (
    <div className="pd-30">
      <div className="border-efefef border-radius-5 pd-20 mg-center">
        <h3 className="center">นโยบายความเป็นส่วนตัว</h3>

        <div>

        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy

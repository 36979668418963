import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Components Global
import PageItems from '../PageItems'

// Components Local
import ItemsList from './components/ItemsList'


class FetchListItems extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      business,
      setting,
      id,
      title,
      icon,
      match,
      fetchAction,
      foldersManage,
      componentTitle,
      componentHeader,
      componentHeaderList,
      componentList,
      layoutFull,
      tableClassName,
      attribute,
      groupId,
      columnTitle,
      openSidebar,
      openModal,
      fnConfigItems,
    } = this.props


    return (
      <Fragment>
        { this.props.children }

        <PageItems
          id={id}
          match={match}
          className={`${this.props.className || 'pd-15'}`}
          foldersManage={foldersManage}
          location={this.props.location}
          history={this.props.history}
          title={title}
          icon={icon}
          fetchAction={fetchAction}
          items={this.props.datas}
          searchData={this.props.datasSearch}
          componentTitle={componentTitle}
          addItemModal={this.props.addItemModal}
          btnAddMore={this.props.btnAddMore}
          layoutFull={layoutFull}
          hiddenTitle={this.props.hiddenTitle}
          hiddenSearch={this.props.hiddenSearch}
          hiddenSearchCamera={this.props.hiddenSearchCamera}
          useFilter={this.props.useFilter}
          useSelector={this.props.useSelector}
          hiddenHeader={this.props.hiddenHeader}
          hiddenPaginationNumber={this.props.hiddenPaginationNumber}
          notReloadForUrlChange={this.props.notReloadForUrlChange}
          notReloadForUrlSearchChange={this.props.notReloadForUrlSearchChange}
          dataReloadForUrlChange={this.props.dataReloadForUrlChange}
          columnTitle={columnTitle}
          componentHeader={componentHeader}
          componentItemsNone={this.props.componentItemsNone}
          currentPageName={this.props.currentPageName}>

          <ItemsList
            getMapIdKey={this.props.getMapIdKey}
            unwind={this.props.unwind}
            location={this.props.location}
            history={this.props.history}
            match={match}
            tableClassName={tableClassName}
            dispatch={this.props.dispatch}
            itemId={this.state.itemId}
            componentHeaderList={componentHeaderList}
            component={componentList}
            attribute={attribute}
            business={business}
            setting={setting}
            groupId={groupId}
            openSidebar={openSidebar}
            openModal={openModal}
            fnConfigItems={fnConfigItems}
            useSelector={this.props.useSelector}
            notItemListTable={this.props.notItemListTable}
            itemParams={this.props.itemParams}
          />
        </PageItems>
      </Fragment>
    )
  }
}


const mapStateToProps = (state, props) => {
  if (props.storeName) {
    let datas = state[props.storeName].data
    let datasSearch = state[props.storeName].search

    if (props.datasFilter) {
      datas = props.datasFilter(datas) || []

      if (datasSearch && datasSearch.status === true) {
        datasSearch.data = props.datasFilter(datasSearch.data)
      }

      if (props.foldersManage) {
        const folders_id = props.match.params.folders_id || null

        datas = datas.filter(folder => folder.folders_id === folders_id)
      }
    }

    return {
      datas,
      datasSearch,
      folders: state.folders && state.folders.data
    }
  } else {
    return {}
  }
}

FetchListItems.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  //storeName: PropTypes.string.isRequired
}

export default connect(mapStateToProps)(FetchListItems)

import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Footer, ListNo, BtnConfrimConnect } from '../Ui'
import { LineChannelId, LineChannelSecret, LineChannelAccessToken, LineChannelIdDisabled } from '../../../ConversationChannelForms'

import FormValidation from 'components/Form'
import BtnSubmit from 'components/button/BtnSubmit'
import getFormValue from 'utils/getFormValue'
import { createQueryConversationChannelOne, updateQueryConversationChannelOne } from 'core/redux/ConversationChannels/ConversationChannelsActions'
import ShowErrorModal from '../ShowErrorModal'
import { openModal } from 'core/redux/App/AppActions'

const CopyLineToken = (props) => {
  const formRef = useRef()
  const [data, setData] = useState()
  const { update, platform, channel, business, modalId, loadingId } = props
  const showErrorModalId = 'showErrorModal'

  const handleCreate = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(formRef.current)

    if (params) {
      const query = {
        authName: 'admin',
        businesses_id: business._id
      }

      props.dispatch(createQueryConversationChannelOne(query, {
        body: {
          create: {
            value: {
              platformId: platform.id,
              ...params
            }
          }
        },
        loadId: formName,
        modalId: formName,
        notShowErrorAlert: true,
        toastMsg: 'ยืนยันการเชื่อมต่อแล้ว'
      })).then(res => {
        if (res.success && res.data) {
          if (props.onSelect) {
            props.onSelect(res.data)
          }
        } else if (res.success === false && res.code) {
          props.dispatch(openModal(showErrorModalId, { errorCode: res.code }))
        } else {
          props.dispatch(openModal(showErrorModalId, { errorCode: '' }))
        }
      })
    }
  }

  const handleUpdate = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(formRef.current)

    if (params) {
      const query = {
        authName: 'admin',
        businesses_id: business._id,
        conversation_channels_id: channel._id
      }

      props.dispatch(updateQueryConversationChannelOne(query, {
        body: {
          scenario: 'update-line-oa-token',
          update: {
            value: {
              platformId: platform.id,
              ...params
            }
          }
        },
        loadId: formName,
        modalId: formName,
        notShowErrorAlert: true,
        toastMsg: 'ยืนยันการเชื่อมต่อแล้ว'
      })).then(res => {
        if (res.success && res.data) {
          if (props.onSelect) {
            props.onSelect(res.data)
          }
        } else if (res.success === false && res.code) {
          props.dispatch(openModal(showErrorModalId, { errorCode: res.code }))
        } else {
          props.dispatch(openModal(showErrorModalId, { errorCode: '' }))
        }
      })
    }
  }

  const handleSubmit = (e, formName) => {
    if (update && channel) {
      handleUpdate(e, formName)
    } else {
      handleCreate(e, formName)
    }
  }

  return (
    <FormValidation
      ref={formRef}
      name={modalId || loadingId}
      dataChange={JSON.stringify(data)}
      submit={(e) => handleSubmit(e, modalId || loadingId)}>

      <div>
        <ListNo no={1}>
          เปิด <a href="https://developers.line.biz/console" target="_blank">https://developers.line.biz/console</a>
        </ListNo>
        <ListNo no={2}>
          เลือก <b>โพรไวเดอร์ (Provider)</b> และบัญชีที่สร้างไว้
        </ListNo>
        <ListNo no={3}>
          เลือกแท็บ <b>Basic Settings</b> คัดลอกข้อมูล นำมาใส่ในช่องด้านล่าง

          <div className="mg-top-20">
            { channel ?
              <LineChannelIdDisabled value={channel.lineChannelId} />
            :
              <LineChannelId business={business} />
            }
          </div>
          <div>
            <LineChannelSecret value={channel && channel.lineChannelSecret} />
          </div>
        </ListNo>
        <ListNo no={4}>
          เลือกแท็บ <b>Messaging API</b> คัดลอกข้อมูล นำมาใส่ในช่องด้านล่าง

          <div className="mg-top-20">
            <LineChannelAccessToken value={channel && channel.lineChannelAccessToken} />
            <div className="font-0-7">(ในกรณีที่คุณยังไม่ได้รับ Channel access token ให้กด issue)</div>
          </div>
        </ListNo>
      </div>
      <div>
        { props.useFooter &&
          <Footer {...props}>
            <BtnSubmit
              loadingId={modalId}
              icon="send"
              iconAlign="left"
              text="ยืนยันการเชื่อมต่อ"
              className="btn btn-success btn-shadow-none height-40 line-height-40"
              btnDisabled
            />
          </Footer>
        }

        { props.update &&
          <BtnSubmit
            loadingId={modalId || loadingId}
            icon="send"
            iconAlign="left"
            text="ยืนยันการเชื่อมต่อ"
            className="btn btn-success btn-shadow-none height-40 line-height-40 width-full mg-top-20"
            btnDisabled
          />
        }

        <ShowErrorModal id={showErrorModalId} />
      </div>
    </FormValidation>
  )
}

export default connect()(CopyLineToken)

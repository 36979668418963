import React from 'react'

// Components Global
import ColorPicker from '../../../../ColorPicker'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import cssgl from '../css/styleSettings.css'


const LinkColorSetting = (props) => {
  const { _this, styleName, } = props
  const { style } = _this.state

  const colorName = `${styleName}-color`
  const hoverName = `${styleName}-hover-color`
  const activeName = `${styleName}-active-color`
  const hoverTextDecorationName = `${styleName}-hover-text-decoration`

  const color = style[colorName]
  const hover = style[hoverName]
  const active = style[activeName]
  const hoverTextDecoration = style[hoverTextDecorationName]


  const colorChange = (color) => {
    addStyle(_this, {
      style: colorName,
      value: color,
    })
  }

  const hoverColorChange = (color) => {
    addStyle(_this, {
      style: hoverName,
      value: color,
    })
  }

  const activeColorChange = (color) => {
    addStyle(_this, {
      style: activeName,
      value: color,
    })
  }

  const textDecorationChange = (e) => {
    const value = e.target.value

    if (value === "") {
      removeStyle(_this, hoverTextDecorationName)
    } else {
      addStyle(_this, {
        style: hoverTextDecorationName,
        value
      })
    }
  }

  const resetLink = () => {
    removeStyle(_this, null, {
      styleMultiple: [
        { style: colorName },
        { style: hoverName },
        { style: activeName },
        { style: hoverTextDecorationName }
      ]
    })
  }

  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td className="pd-0 font-0-7">สีลิงก์</td>
            <td className="pd-0 font-0-7 text-right">
              <a className={cssgl.btn} onClick={resetLink}>ค่าเดิม</a>
            </td>
          </tr>
        </tbody>
      </table>

      <div className={cssgl.boxInput}>
        <table>
          <tbody>
            <tr>
              <td width="23%" className="pd-0 font-0 center">
                <ColorPicker
                  left
                  hiddenColorAuto
                  label="สีลิงก์"
                  onChange={colorChange}
                  styleDropdown={{ top: '37px', left: '0', }}
                  style={{ width: '100%' }}>

                  <div className={cssgl.boxColorSeleted}>
                    <div
                      className={`${cssgl.colorSeleted} ${cssgl.inputFirst}`}
                      style={{ background: color }}
                    />
                  </div>
                </ColorPicker>
              </td>
              <td width="23%" className="pd-0 font-0 center border-left-eee">
                <ColorPicker
                  left="50px"
                  hiddenColorAuto
                  label="สีเมื่อเมาส์ชี้"
                  onChange={hoverColorChange}
                  styleDropdown={{ top: '37px', left: undefined, right: '-150px' }}
                  style={{ width: '100%' }}>

                  <div className={cssgl.boxColorSeleted}>
                    <div
                      className={`${cssgl.colorSeleted}`}
                      style={{ background: hover }}
                    />
                  </div>
                </ColorPicker>
              </td>
              <td width="23%" className="pd-0 font-0 center border-left-eee">
                <ColorPicker
                  right="70px"
                  hiddenColorAuto
                  label="สีเมื่อกด"
                  onChange={activeColorChange}
                  styleDropdown={{ top: '37px', left: undefined, right: '-50px' }}
                  style={{ width: '100%' }}>

                  <div className={cssgl.boxColorSeleted}>
                    <div
                      className={`${cssgl.colorSeleted}`}
                      style={{ background: active }}
                    />
                  </div>
                </ColorPicker>
              </td>
              <td width="31%" className="pd-0 center border-left-eee">
                <select
                  className={`browser-default ${cssgl.input} ${cssgl.select} ${cssgl.inputBorderNone}`}
                  onChange={textDecorationChange}
                  value={hoverTextDecoration || ''}>

                  <option value="">ไมมี</option>
                  <option value="underline">มี</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <table>
        <tbody>
          <tr>
            <td width="23%" className="pd-0 font-0-7 center">สีลิงก์</td>
            <td width="23%" className="pd-0 font-0-7 center">สีเมื่อเมาส์ชี้</td>
            <td width="23%" className="pd-0 font-0-7 center">สีเมื่อกด</td>
            <td width="31%" className="pd-0 font-0-7 center">ขีดเส้นใต้เมื่อเมาส์ชี้</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default LinkColorSetting

import React from 'react'
import renderRoutes from '../../../../../routes/renderRoutes'
import { connect } from 'react-redux'

// Components Global
import Window from '../../../../../components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../../components/MenuSidebarResponsive'


const menuItems = (params) => {
  const { username } = params
  const path = `/${username}/rubber-receipts`
  let items = []
  const loops = [
    { title: 'ทั้งหมด', saleType: 'all' },
    { title: 'ขายสด', saleType: 'sell-on-cash' },
    { title: 'ทำยางแผ่น', saleType: 'make' },
    { title: 'ขายล่วงหน้า', saleType: 'contract' },
    { title: 'ขายฝากยาง', saleType: 'deposit' },
  ]

  loops.map(loop => {
    const single = [
      {
        name: loop.title,
        title: true
      },
      {
        name: 'ทั้งหมด',
        nameShort: 'ทั้งหมด',
        icon: 'receipt',
        link: `${path}/${loop.saleType}/all/all`,
        searchLinkActive: `${path}/${loop.saleType}/all/`,
      },
      {
        name: 'ยังไม่ได้รับเงิน',
        nameShort: 'ยังไม่ได้รับเงิน',
        icon: 'credit_card_off',
        link: `${path}/${loop.saleType}/unpaid/all`,
        searchLinkActive: `${path}/${loop.saleType}/unpaid/`,
      },
      {
        name: 'รับเงินแล้ว',
        nameShort: 'รับเงินแล้ว',
        icon: 'credit_score',
        link: `${path}/${loop.saleType}/paid/all`,
        searchLinkActive: `${path}/${loop.saleType}/paid/`,
      },
    ]

    items.push(...single)
  })

  return items
}

const RubberReceiptsMenuContainer = (props) => {
  const { business, setting, location, route, match, windowModal, closeWindowModal, history } = props
  const titleIcon = require(`../../../Businesses/resources/icons/rubber-receipt.png`)

  return (
    <Window
      title="ใบสำคัญรับเงิน"
      titleIcon={titleIcon}
      linkClose={`/${match.params.username}`}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkHelp="">

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive
            items={menuItems(match.params)}
          />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, setting },
            location
          }) }
        </Layouts2>
      </LayoutsTwo>
    </Window>
  )
}



export default connect()(RubberReceiptsMenuContainer)

import React from "react"

// Components Global
import { LayoutsDesign } from '../../../LayoutsDesign'


const ContentPageGroupManage = (props) => {
  const { datas, params, actionSite, actionSinglePage } = props
  const { business, setting, site, pages, pageCurrent, } = datas

  let action

  if (params.siteManage) {
    action = actionSite

  } else if (params.singlePageManage) {
    action = actionSinglePage
  }

  if (action && action.useQuery) {
    action.useQuery._id = action.data._id
  }

  return (
    <div style={{ padding: '20px 20px 20px 50px' }}>
      <LayoutsDesign
        {...props}
        action={{
          authName: action.authName,
          useQuery: action.useQuery,
          fetch: action.fetch,
          update: action.update,
          uploadImage: action.uploadImage,
          params: {
            business,
            setting,
            site,
            siteManage: params.siteManage,
            pages,
            pageCurrent,
            data: action.data,
            _id: action.data._id,
            imagePrefix: action.imagePrefix
          }
        }}
      />
    </div>
  )
}

export default ContentPageGroupManage

// Containers
import SalesContainer from '../../../../../containers/dashboard/Sales/containers/SalesContainer'

// Routes
//import PosOnlineRoutes from './routes/PosOnlineRoutes'
import SalesMenuRoutes from './routes/SalesMenuRoutes'
//import SaleUpdateBillRoutes from './routes/SaleUpdateBillRoutes'


const SalesRoutes = [
  {
    path: '/:username/sales',
    component: SalesContainer,
    routes: [
      //...PosOnlineRoutes,
      //...SaleUpdateBillRoutes,
      //...SaleUpdateOrderRoutes,
      ...SalesMenuRoutes,
    ]
  }
]

export default SalesRoutes

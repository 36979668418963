import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../../routes/renderRoutes'

// Components Global
import LayoutSidebarManage from '../../../../../components/LayoutSidebarManage'

// Component Local
import BillsListItems from './components/BillsListItems'

// Actions Redux
import { fetchBill } from '../../../../../redux/Bills/BillsActions'

// Values
import { billValue } from '../../../../../redux/Bills/BillsValues'



const BillsListContainer = (props) => {
  const layoutSidebar = useRef()
  const { match } = props
  const { bills_id, type } = match.params
  const { business, location, route, bill, dispatch } = props
  const openSidebar = () => layoutSidebar.current.open()

  useEffect(() => {
    if (!bill) {
      layoutSidebar.current.close()
    } else {
      layoutSidebar.current.open()
    }
  }, [bill])


  useEffect(() => {
    if (!bill && bills_id) {
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businesses_id: business._id,
        businessType: business.type,
        bills_id
      }

      if (bills_id !== 'all') {
        setTimeout(() => {
          dispatch(fetchBill(query))
        }, 500)
      }
    }
  }, [])

  return (
    <LayoutSidebarManage
      {...props}
      fullscreen
      urlMain={`/${business.username}/bills/${type}/all`}
      ref={layoutSidebar}
      dataId={bills_id}>

      <div>
        <BillsListItems {...props} openSidebar={openSidebar} />
      </div>

      <div>
        { renderRoutes({
          routes: route.routes,
          extraProps: {
            ...props,
            bill: billValue({ bill })
          },
          location
        }) }
      </div>
    </LayoutSidebarManage>
  )
}

const mapStateToProps = (state, props) => {
  const { match } = props
  const { bills_id } = match.params
  const { bills } = state

  let bill = bills.data.filter(bill => bill._id === bills_id)[0]

  if (!bill && bills.search && bills.search.status === true) {
    bill = bills.search.data.filter(bill => bill._id === bills_id)[0]
  }

  return {
    bill
  }
}

export default connect(mapStateToProps)(BillsListContainer)

import React from 'react'

// Compoents Local
import SectionTitle from '../SectionTitle'


import { PagePartsManage, PagePartsDisplay } from '../../../../../PageParts'



const PagePartsFullManage = (props) => {
  const { business, setting, site, page, getLoggedIn, title } = props

  return (
    <div style={{ margin: '0 auto' }}>
      <SectionTitle title={title} mode="view" getLoggedIn={getLoggedIn}>
        <PagePartsDisplay
          {...props}
          mode="view"
          datas={{
            business,
            setting,
            site,
            page
          }}
        />

        <PagePartsManage
          {...props}
          mode="edit"
          datas={{
            business,
            setting,
            site,
            page
          }}
        />
      </SectionTitle>
    </div>
  )
}

export default PagePartsFullManage

import React, { Fragment } from 'react'

// Components Global
import { Row, Column } from '../../../../../../../components/Columns'
import { BtnModalOpen } from '../../../../../../../components/Modals/BtnModal'
import BtnManager from '../../../../../../../components/BtnManager'

// Components Pages
import ContentGroupPageCreateModal from '../../../ContentGroupPageCreateModal'
import ContentGroupPageUpdateModal from '../../../ContentGroupPageUpdateModal'

// Actions Redux
import { closeModal } from '../../../../../../../redux/App/AppActions'

// Fuctions
import { onDeleteContentGroupPage } from '../../../../../../../redux/Sites/SitesFunctions'

//css
import css from './css/templateLists.css'


const ContentGroupPageLists = (props) => {
  const lang = props.lang || 'local'
  const { history, urlMainManage, actionSinglePage } = props
  const { data } = actionSinglePage
  const contentGroupPages = data.contentGroupPages || []

  const goto = (id) => {
    props.dispatch(closeModal(`contentGroupPagesManageModal`))

    history.push(`${urlMainManage}/content-group-page/${id}`)
  }

  const gotoHome = () => {
    props.dispatch(closeModal(`contentGroupPagesManageModal`))

    history.push(`${urlMainManage}`)
  }

  return (
    <div>
      <ContentGroupPageCreateModal {...props} />

      <div style={{ borderBottom: '1px solid #e6e6e6' }}>
        <Row className="pd-bottom-10 pd-top-10">
          <Column col="column col-xxs-12 col-xs-6">
            <div className="pd-right-10 pd-top-3">
              <span>หน้าเพจ</span>
            </div>
          </Column>
          <Column col="column col-xxs-12 col-xs-6 text-right">
            <BtnModalOpen id={`contentGroupPageCreateModal`}>
              <button className="waves-effect btn-shadow-none waves-light btn btn-xs pd-right-10">
                <i className="material-icons left">add</i> เพิ่มหน้าเพจ
              </button>
            </BtnModalOpen>
          </Column>
        </Row>
      </div>

      <div className={css.boxLists}>


        {/******  Index ******/}
        <div className={css.list}>
          <div className={css.name}>หน้าแรก</div>
          <div className={css.manage}>
            <a onClick={() => gotoHome()} className="btn btn-shadow-none btn-xxs mg-right-5">ออกแบบ</a>

            <BtnModalOpen id={`${data._id}-contentGroupPageUpdateModal`}>
              <a className="btn btn-shadow-none btn-xxs btn-edit mg-right-5">
                <i className="material-icons">edit</i>
              </a>
            </BtnModalOpen>

            <button disabled title="ลบ" className="btn-delete btn btn-xxs btn-shadow-none ">
              <i className="material-icons undefined false">delete</i>
            </button>
          </div>

          <ContentGroupPageUpdateModal {...props} main contentGroupPage={data} />
        </div>
        {/******  Index ******/}


        { contentGroupPages.map(contentGroupPage => {
          return (
            <div key={contentGroupPage._id} className={css.list}>
              <div className={css.name}>{contentGroupPage.name[lang]}</div>
              <div className={css.manage}>
                <a onClick={() => goto(contentGroupPage._id)} className="btn btn-shadow-none btn-xxs mg-right-5">ออกแบบ</a>

                <BtnModalOpen id={`${contentGroupPage._id}-contentGroupPageUpdateModal`}>
                  <a className="btn btn-shadow-none btn-xxs btn-edit mg-right-5">
                    <i className="material-icons">edit</i>
                  </a>
                </BtnModalOpen>

                <BtnManager
                  iconOnly
                  id={contentGroupPage._id}
                  btnSize="btn-xxs"
                  className="dp-inline-block"
                  manager={[
                    {
                      type: 'delete',
                      show: true,
                      label: 'ลบ',
                      title: `ลบ "หน้านี้" อย่างถาวร`,
                      msg: `กดปุ่ม "ลบหน้านี้" ถ้าคุณต้องการลบหน้า "ลบหน้านี้" อย่างถาวร`,
                      labelConfirm: 'ลบหน้านี้',
                      handle: onDeleteContentGroupPage(props),
                    }
                  ]}
                />
              </div>

              <ContentGroupPageUpdateModal {...props} contentGroupPage={contentGroupPage} />
            </div>
          )
        }) }
      </div>
    </div>
  )
}

export default ContentGroupPageLists

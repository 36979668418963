import React from 'react'
import renderRoutes from '../../../../../routes/renderRoutes'

// Components Global
import Window from '../../../../../components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../../components/MenuSidebarResponsive'

// Components Products
import RubberProductCreateModal from '../components/RubberProductCreateModal'

// imgs
import titleIcon from '../resources/imgs/Products-96.png'


const menuItems = (params) => {
  const { username } = params
  const path = `/${username}/rubber-products`

  return [
    {
      name: 'ประกาศราคา',
      title: true
    },
    {
      name: 'ประกาศราคา',
      nameShort: 'ประกาศราคา',
      icon: 'assignment',
      link: `${path}/all`,
      searchLinkActive: `${path}/`
    },
  ]
}

const RubberProductsMenuContainer = (props) => {
  const { business, location, route, match, windowModal, closeWindowModal, history } = props

  return (
    <Window
      title="ประกาศราคายาง"
      titleIcon={titleIcon}
      linkClose={`/${match.params.username}`}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkHelp="">

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive
            btnAddModal={{
              modalId: 'productCreateModal',
              text: 'เพิ่มราคายาง',
            }}
            items={menuItems(match.params)}
          />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business },
            location
          }) }
        </Layouts2>
      </LayoutsTwo>

      <RubberProductCreateModal match={match} business={business} />
    </Window>
  )
}

export default RubberProductsMenuContainer

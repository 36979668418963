import cuid from 'cuid'
import React, { useEffect, useState } from 'react'

// Components Forms
import {
  SaleIncomePrice,
  SaleIncomePricePerUnit,
  SaleIncomeTitle,
  SaleTotalIncomes,
} from './RubberSaleForms'


const IncomesForms = (props) => {
  const [incomes, setIncomes] = useState([])
  const [netWeight, setNetWeight] = useState(0)
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    const incomeDefault = [{ key: cuid(), title: '', price: '' }]

    if (props.itemSale && props.itemSale.incomes) {
      if (props.itemSale.incomes.length === 0) {
        setIncomes(incomeDefault)
      } else {
        setIncomes(props.itemSale.incomes)
      }
    } else {
      setIncomes(incomeDefault)
    }
  }, [])

  useEffect(() => {
    if (props.rubberType && props.event === 'create') {
      let cloneIncomes = [] //[...incomes]
      const costs = getSettingTypeCosts()
      const incomeFixed = []

      costs.map(item => {
        const checkDuplicate = cloneIncomes.filter(income => income.title === item.title && income.useSetting === true)[0]

        if (!checkDuplicate) {
          const newItem = {
            key: cuid(),
            title: item.title,
            useSetting: true
          }

          let price = parseFloat(item.price)

          if (item.type === 'pricePerUnit') {
            newItem.pricePerUnit = price
            newItem.price = 0
          } else if (item.type === 'price') {
            newItem.price = -price
          }

          incomeFixed.push(newItem)
        }
      })

      cloneIncomes = [...cloneIncomes, ...incomeFixed]
      cloneIncomes = [...cloneIncomes.filter(i => i.useSetting === true), ...cloneIncomes.filter(i => i.useSetting !== true)]

      setIncomes(cloneIncomes)
    }

  }, [props.rubberType])


  useEffect(() => {
    let cloneIncomes = [...incomes]

    if (cloneIncomes.length !== 0) {
      cloneIncomes.map(item => {
        if (item.pricePerUnit !== undefined) {
          let price = parseFloat(item.pricePerUnit) * netWeight
          item.price = -price
        }
      })

      setIncomes(cloneIncomes)
    }
  }, [netWeight])

  useEffect(() => {
    setNetWeight(props.netWeight || 0)
  }, [props.netWeight])


  useEffect(() => {
    props.onChange(incomes)
  }, [incomes])


  const add = () => {
    const array = [...incomes]
    array.push({ key: cuid(), title: '', price: '' })
    setIncomes(array)
  }

  const remove = (i) => {
    const array = [...incomes]
    array.splice(i, 1)
    setIncomes(array)
  }

  const update = (v, i, element) => {
    if (v.target) v = v.target.value
    const array = [...incomes]

    if (element === 'price') {
      array[i][element] = parseFloat(v)
    } else if (element === 'pricePerUnit') {
      array[i][element] = parseFloat(v || 0)
      array[i].price = - parseFloat(v || 0) * netWeight
    } else {
      array[i][element] = v
    }

    setIncomes(array)
  }

  const getTotalIncomes = () => {
    let totalIncomes = 0
    incomes.map(income => totalIncomes += income.price || 0)

    return totalIncomes
  }

  const totalIncomes = getTotalIncomes()

  const getSettingTypeCosts = () => {
    let costs

    if (props.type.code === 'rubber-sheet') {
      if (props.rubberType) {
        const rubberSheetMake = props.setting.types.filter(type => type.code === 'rubber-sheet-make')[0]

        if (props.rubberType === 'rubber-sheet') {
          costs = rubberSheetMake.saleIncomeRSPrices
        } else if (props.rubberType === 'ribbed-smoked-sheet') {
          costs = rubberSheetMake.saleIncomeRSSPrices
        }

        costs = costs && JSON.parse(costs)
      }
    }

    return costs || []
  }

  return (
    <div>
      <table className="mg-bottom-20">
        <tbody>
          <tr>
            <td className="pd-0">
              <div className="font-1-1">รายได้/ค่าใช้จ่าย อื่นๆ</div>
            </td>

            { incomes.filter(income => income.useSetting === true)[0] &&
              <td className="pd-0 width-50 text-right">
                <a onClick={() => setEdit(!edit)} className="btn btn-shadow-none btn-white width-30 height-30 line-height-30 border-c0c0c0 pd-0 center">
                  <i className="material-icons font-1-3">{edit ? 'close' : 'edit' }</i>
                </a>
              </td>
            }
          </tr>
        </tbody>
      </table>

      { incomes.map((income, i) => {
        return (
          <table key={income.key}>
            <tbody>
              <tr>
                <td className="pd-0 pd-right-20 vertical-top">
                  <SaleIncomeTitle
                    type={props.type}
                    value={income.title}
                    onChange={(v) => update(v, i, 'title')}
                    disabled={income.useSetting && !edit}
                  />
                </td>

                { income.pricePerUnit !== undefined &&
                  <td className="pd-0 width-100 pd-right-20 vertical-top">
                    <SaleIncomePricePerUnit
                      type={props.type}
                      value={income.pricePerUnit}
                      onChange={(v) => update(v, i, 'pricePerUnit')}
                      disabled={!edit}
                    />
                  </td>
                }

                <td className="pd-0 width-130 vertical-top">
                  <SaleIncomePrice
                    type={props.type}
                    value={income.price}
                    onChange={(v) => update(v, i, 'price')}
                    disabled={income.useSetting && (income.pricePerUnit !== undefined ? true : !edit)}
                  />
                </td>
                <td className="pd-0 width-40 text-right vertical-top">
                  { income.useSetting ?
                    <a disabled className="btn btn-shadow-none btn-white width-30 height-30 line-height-30 mg-top-15 pd-0 center">
                      <i className="material-icons color-c0c0c0">close</i>
                    </a>
                  :
                    <a onClick={() => remove(i)} className="btn btn-shadow-none btn-white width-30 height-30 line-height-30 mg-top-15 border-c0c0c0 pd-0 center">
                      <i className="material-icons">close</i>
                    </a>
                  }
                </td>
              </tr>
            </tbody>
          </table>
        )
      }) }

      <div className="height-45 mg-top-1 mg-bottom-20 center">
        <a onClick={add} className="btn btn-shadow-none btn-white height-30 line-height-30 pd-left-8 pd-right-10 font-0-8 border-c0c0c0 pd-0 center">
          <i className="material-icons left">add</i> เพิ่มรายการ
        </a>
      </div>

      <div>
        <SaleTotalIncomes type={props.type} value={totalIncomes} />
      </div>
    </div>
  )
}

export default IncomesForms

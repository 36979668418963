import React from 'react'

// css
import css from './css/notes.css'

const getDateNotes = (date, datesNotes) => {
  let notes

  if (date && datesNotes) {
    notes = datesNotes.filter(value => value.date === date)[0]
  }

  return notes
}

const stopOnClick = (e) => {
  if (e.stopPropagation) {
    e.stopPropagation()
  } else {
    e.cancelBubble = true
  }
}

const DatesNotes = (props) => {
  const notes = getDateNotes(props.fullDate, props.datesNotes)
  const { bg, msg }= props.notesBgColor(props.fullDate, notes)
  const bgActive = 'rgba(0, 0, 0, 0.42)'

  return (
    <div>
      { notes && 
        <div>
          { !props.showNotesColor ?
            <p style={{ fontSize: '0.7rem', marginTop: '10px', color: 'green', textAlign: 'center', lineHeight: '12px' }}>
              กำหนดแล้ว
            </p>
          :
            <div>
              <div className={`${css.boxNoteColor} ${bg}`}>
                <div className={`${css.boxDate}`} style={{ background: props.selectDate && bgActive }}>
                  <div className={`color-fff ${props.css.textDate} ${props.type === 'datePicker' && props.css.textDateCenter}`} style={{ border: '2px solid #fff', lineHeight: '24px', fontWeight: 'bold' }}>
                    { props.date }
                  </div>
                  
                  { props.type !== 'datePicker' &&
                    <p className="center text-ellipsis hidden-xxs hidden-xs hidden-sm hidden-md" style={{ position: 'absolute', top: '50%', left: '0', right: '0', marginTop: '-10px'}}>
                      <span className="color-fff font-0-8 pd-left-5 pd-right-5 border-fff">
                        {msg}
                      </span>
                    </p>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      }

      { !notes && props.noneNotesIsDisabled &&
        <div onClick={(e) => stopOnClick(e) } className={css.boxNoteDisable}>
          <div className={css.boxDate}>
            <div className={css.date}>
              { props.date }
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default DatesNotes
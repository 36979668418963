// Containers
import AttributesContainer from '../../../../../containers/dashboard/Attributes/containers/AttributesContainer'
import AttributeContainer from '../../../../../containers/dashboard/Attributes/containers/AttributeContainer'

// Routes
import AttributesMenuRoutes from './routes/AttributesMenuRoutes'
import AttributeUpdateRoutes from './routes/AttributeUpdateRoutes'


const AttributesRoutes = [
  {
    path: '/:username/attributes',
    component: AttributesContainer,
    routes: [
      {
        path: '/:username/attributes/:type(product|article|note|customer)/:attributes_id',
        component: AttributeContainer,
        auth: { reqAuthBiz: true, reqExpDate: true },
        routes: [
          ...AttributeUpdateRoutes,
        ]
      },
      ...AttributesMenuRoutes
    ]
  }
]

export default AttributesRoutes

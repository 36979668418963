import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../../components/Modals/Modal'
import { Row, Column } from '../../../../../../components/Columns'

// Components forms
import Form from '../../../../../../components/Form'
import BtnSubmit from '../../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../../util/getFormValue'

// Components Contracts
import ContractDetailsForm from '../ContractDetailsForm'

// Actions Redux
import { createContract, updateContract } from '../../../../../../redux/Contracts/ContractsActions'

// Components Customers
import RubberCustomerCreateAndUpdateModal from '../../../RubberCustomerBuyers/components/RubberCustomerCreateAndUpdateModal'

// Values
import { getRubberSettingTypeValue } from '../../../../../../redux/RubberSettings/RubberSettingsValue'


const RubberContractCreateAndUpdateModal = (props) => {
  const [data, setData] = useState('')
  const [customer, setCustomer] = useState()

  const formRef = useRef()
  const { id, event, match, business, setting, dispatch } = props
  const { username, rubberType } = match.params

  const productType = 'rubber-sheet'
  const type = getRubberSettingTypeValue({ setting, code: productType })

  let title

  if (event === 'create') {
    title = `เพิ่มสัญญาขายล่วงหน้า`
  } else if (event === 'update') {
    title = `แก้ไขสัญญาขายล่วงหน้า`
  }

  useEffect(() => {
    if (props.contract) {
      setCustomer(props.contract.customer)
    }
  }, [props.contract])


  const handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(formRef.current)

    if (params) {
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id
      }

      params.productType = productType
      params.customer = customer
      params.customers_id = customer && customer._id
      params.netWeightDiff = params.netWeight

      if (event === 'create') {
        dispatch(createContract(query, {
          body: params,
          loadId: formName,
          modalId: formName,
          toastMsg: title,
          props,
          dataRedirect: {
            url: `/${username}/rubber-contracts/all/:_id`,
            resName: 'data'
          }
        }))

      } else if (event === 'update') {
        query.contracts_id = props.contract._id

        dispatch(updateContract(query, {
          body: params,
          loadId: formName,
          modalId: formName,
          toastMsg: title
        }))
      }
    }
  }

  const handleSelectCustomer = (customer) => {
    if (customer) {
      setCustomer(customer)
    } else {
      setCustomer(undefined)
    }
  }

  const handleCodeChange = (data) => {
    setData(data)
  }

  const col_1 = "column col-xxs-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 pd-right-20-lg pd-right-20-xlg pd-bottom-20"
  const col_2 = "column col-xxs-12 col-xs-12 col-sm-12 col-md-12 col-lg-6  pd-left-20-lg  pd-left-20-xlg pd-bottom-20"

  return (
    <Modal
      id={id}
      headerTitle={title}
      headerLeft
      blockBgModal
      width="600px"
      padding="0">

      <div className="pd-20">
        <RubberCustomerCreateAndUpdateModal
          {...props}
          id="customerCreateModal"
          event="create"
          match={props.match}
          business={props.business}
          onSelect={handleSelectCustomer}
        />

        <Form
          recheckForm
          ref={formRef}
          name={id}
          dataChange={JSON.stringify(data)}
          submit={(e) => handleSubmit(e, id)}>

          <ContractDetailsForm
            {...props}
            data={props.contract}
            customer={customer}
            type={type}
            handleSelectCustomer={handleSelectCustomer}
            handleCodeChange={handleCodeChange}
          />

          <Row>
            <Column col="column col-xxs-12">
              <BtnSubmit
                loadingId={id}
                icon="send"
                iconAlign="left"
                text={title}
                className="btn btn-submit btn-shadow-none height-45 line-height-45 font-0-9 width-full"
                btnDisabled
              />
            </Column>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}


export default connect()(RubberContractCreateAndUpdateModal)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

// Components Global
import { Row, Column } from '../../../../../components/Columns'
import DatasList from '../../../../../components/DatasList'
import BtnManager from '../../../../../components/BtnManager'

// Redux
import { onDelete } from '../../../../../redux/Orders/OrdersFunctions'
import { closeModal } from '../../../../../redux/App/AppActions'
import { getTotalPriceAll } from '../../../../../redux/Orders/OrdersValues'

// utity
import { getDate } from '../../../../../util/getDateTime/getDateTime'
import { getNumeral } from '../../../../../util/getNumeral'




class OrderDraftsList extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  selectOrder = (orders_id) => {
    if (this.props.match.params.orders_id) {
      this.props.dispatch(closeModal('draftOrdersListModal'))
    }

    this.props.history.push(`/${this.props.match.params.username}/sales/pos-online/${orders_id}/main`)
  }

  render() {
    let orders = this.props.orders || []
    let orders_id = this.props.match.params.orders_id

    return (
      <Row>
        <ReactCSSTransitionGroup
          transitionName="example"
          transitionEnterTimeout={1000}
          transitionLeaveTimeout={1000}>

          { orders.map((order, i) => {
            return (
              <Column key={order._id} col="column col-xxs-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xlg-3 pd-5">
                <div
                  className={`${orders_id ? '' : 'border-eee'} pd-10 border-radius-3 bg-fff`}
                  style={{
                    border: orders_id && orders_id === order._id ? '1px solid red' : orders_id ? '1px solid #efefef' : '0px solid #efefef'
                  }}>

                  <DatasList
                    style={{ border: '0' }}
                    items={[
                      {
                        label: 'หมายเลขบิล',
                        value: `${order.code}`,
                        icon: 'receipt'
                      },
                      {
                        label: 'วันที่เปิดบิล',
                        value: getDate(order.created, 'DD MMM YYYY, H:mm น.', 'th'),
                        icon: 'event'
                      },
                      {
                        label: 'ยอดชำระ',
                        value: `฿${ getNumeral(getTotalPriceAll(order), 2) }`,
                        icon: 'account_balance_wallet'
                      }
                    ]} />
                  <table>
                    <tbody>
                      <tr>
                        <td className="pd-0 pd-right-10">
                          <button onClick={() => this.selectOrder(order._id)} className="btn btn-xs dp-block width-full waves-effect waves-light light-green darken-1 btn-shadow-none">
                            <i className="material-icons right">keyboard_arrow_right</i>

                            { orders_id ? 'เรียกบิลนี้' : 'ดำเนินการต่อ' }
                          </button>
                        </td>
                        <td className="pd-0" width="60">
                          <BtnManager
                            id={order._id}
                            manager={[
                              {
                                label: 'ลบ',
                                type: 'delete',
                                show: true,
                                handle: onDelete(this.props),
                              }
                            ]}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Column>
            )
          })}
        </ReactCSSTransitionGroup>
      </Row>
    )
  }
}

export default connect()(OrderDraftsList)

import React, { Fragment, useEffect, useState, forwardRef, useImperativeHandle } from 'react'


const LayoutSidebarManage = forwardRef((props, ref) => {
  const { children, dataId, history, location, sidebarStyle, fullscreen, urlMain } = props
  const [open, setOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    open() {
      setOpen(true)
    },

    close() {
      setOpen(false)
    },

    isOpen() {
      return open
    }
  }))

  const close = () => {
    setOpen(false)

    if (props.setData) {
      props.setData('')
    }
  }

  const gotoMain = () => {
    close()

    setTimeout(() => {
      history.push(`${urlMain}${location.search}`)
    }, 500)
  }

  return (
    <Fragment>
      <div className={`sidebarContainer`}>
        <div className="content">
          { children[0] }
        </div>

        { open && !fullscreen &&
          <div className="bg" onClick={close}></div>
        }

        <div className="sidebar border-left-1 border-left-e0e0e0">
          <div
            className="bg-fff"
            style={{
              position: fullscreen ? 'fixed' : 'absolute',
              top: '0',
              left: '0',
              right: '0',
              bottom: '0',
              opacity: open ? '1' : '0',
              visibility: open ? 'visible' : 'hidden',
              transition: 'all 0.5s',
              zIndex: '1'
            }}>

            <div className="sidebar-content-title height-50 pd-top-7 pd-left-10 border-bottom-1 border-bottom-eee">
              <a onClick={() => close()} className="btn btn-shadow-none red btnClose height-35 line-height-35">
                <i className="material-icons">close</i>
              </a>

              <a onClick={() => gotoMain()} className="btn btn-shadow-none red btnGotoMain height-35 line-height-35">
                <i className="material-icons">close</i>
              </a>

              <span className="font-1-1 mg-left-15 mg-top-2">รายละเอียด</span>
            </div>
            <div className="sidebar-content-body top-50">
              { children[1] }
            </div>
          </div>

          <div
            className="bg-fff"
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              right: '0',
              bottom: '0',
              opacity: !open ? '1' : '0',
              visibility: !open ? 'visible' : 'hidden',
              transition: !open && 'all 1s'
            }}>

            <div className="box-middle center height-full font-0-9 none-content-jsx">
              <div>
                <i className="material-icons mg-bottom-10 font-7-5 dp-block">info_outline</i>
                <span>เลือกรายการทางด้านซ้ายมือ<br />เพื่อดูรายละเอียด</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      { children[2] }

      <style jsx>{`
        .sidebar-content-title {
          background: #fff;
        }
        .sidebar-content-body {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 0;
        }
        .none-content-jsx {
          max-width: 350px;
        }

        .sidebarContainer {
          position: ${props.relative ? 'relative' : 'absolute'};
          height: ${props.relative && '100%'};
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 0;
        }
        .sidebarContainer .content {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: ${fullscreen ? 'calc(100% - 350px)' : `${!open ? 'calc(100% - 350px)' : '400px'}`};
          background: #f5f5f5;
          z-index: 1;
          transition: all 0.5s;
        }

        .sidebarContainer .sidebar {
          position: absolute;
          top: 0;
          bottom: 0;
          width: calc(100% - 400px);
          right: ${fullscreen ? 'calc(-100% + 400px + 350px)' : `${!open ? 'calc(-100% + 400px + 350px)' : '0'}`};
          z-index: ${open ? '3' : '0'};
          background: #f5f5f5;
          transition: all 0.5s;
        }

        .sidebarContainer .bg {
          visibility: hidden;
          z-index: ${open ? '3' : '0'};
          opacity: 0;
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: #000;
        }

        @media (min-width: 1500px) {
          .sidebarContainer .content {
            width: ${fullscreen ? 'calc(100% - 23.333vw)' : `${!open ? 'calc(100% - 23.333vw)' : '26.666vw'}`};
          }

          .sidebarContainer .sidebar {
            width: calc(100% - 26.666vw);
            right: ${fullscreen ? 'calc(-100% + 26.666vw + 23.333vw)' : `${!open ? 'calc(-100% + 26.666vw + 23.333vw)' : '0'}`};
          }

          .none-content-jsx {
            max-width: 23.333vw;
          }
        }


        @media (min-width: 1025px) {
          .btnClose {
            display: none;
          }
          .sidebarContainer .bg {
            visibility: hidden;
          }
        }

        @media (max-width: 1024px) {
          .btnGotoMain {
            display: none;
          }
          .sidebarContainer .content {
            right: 0;
            width: 100%;
          }
          .sidebarContainer .sidebar {
            position: fixed;
            right: ${fullscreen ? '-100%' : `${open ? '0px' : '-600px'}`};
            width: 600px;
          }

          .sidebarContainer .bg {
            visibility: ${open ? 'visible' : 'hidden'};
            z-index: ${open ? '2' : '-1'};
            opacity: ${open ? '0.7' : '0'};
          }
        }

        @media (max-width: 600px) {
          .sidebarContainer .sidebar {
            width: 100%;
            border-left: none;
          }
        }
      `}</style>
    </Fragment>
  )
})

export default LayoutSidebarManage

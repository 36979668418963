import { lazy } from 'react'
import suspenseComponent from '../../../../../../util/suspenseComponent'

// Containers
import NotesMenuContainer from '../../../../../../containers/dashboard/Notes/containers/NotesMenuContainer'

// Components
const NotesListPage = lazy(() => import('../../../../../../containers/dashboard/Notes/pages/NotesListPage'))


const NotesMenuRoutes = [
  {
    path: '/:username/notes/:attributes_path',
    component: NotesMenuContainer,
    routes: [
      {
        path: '/:username/notes/:attributes_path',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'open', title: 'บันทึก', icon: 'assignment_late' },
        component: suspenseComponent(NotesListPage)
      },
      {
        path: '/:username/notes/:attributes_path/open',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'open', title: 'บันทึก', icon: 'assignment_late' },
        component: suspenseComponent(NotesListPage)
      },
      {
        path: '/:username/notes/:attributes_path/close',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'close', title: 'บันทึก', icon: 'assignment_late' },
        component: suspenseComponent(NotesListPage)
      },
      {
        path: '/:username/notes/:attributes_path/trash',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'trash', title: 'บันทึก', icon: 'assignment_late' },
        component: suspenseComponent(NotesListPage)
      }
    ]
  }
]

export default NotesMenuRoutes

import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react'


// Components local
import OrderItemsList from './components/OrderItemsList'
import OrderItemOneManage from './components/OrderItemOneManage'

// Actions
import { updateOrder } from '../../../../../../../redux/OrdersDrafts/OrdersDraftsActions'

// img
import barcodeReader from './img/barcode-reader.png'

const OrderItemsAllManage = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    addItem(item) {
      addItem(item)
    },
    removeItem(item) {
      removeItem(item)
    },
    removeManyItems(items) {
      removeManyItems(items)
    }
  }))

  const [itemSelected, setItemSelected] = useState()
  const { orderDraft, business } = props
  const query = {
    authName: 'admin',
    dbNo: business.dbNo,
    businesses_id: business._id,
    orders_id: orderDraft._id
  }

  useEffect(() => {
    if (!itemSelected && orderDraft) {
      const item = orderDraft.items[orderDraft.items.length-1]

      if (item) {
        setItemSelected(item)
      }
    }
  }, [orderDraft && JSON.stringify(orderDraft.items)])


  const addItem = (item) => {
    if (item) {
      let params = item
      params.updateType = 'push'
      params.updateElement = 'items'
      params.scenario = 'addItem'
      params.netAmountProduct = params.qty * params.price

      props.dispatch(updateOrder(query, {
        body: params,
        loadId: 'formName',
        useDB: 'client'
      })).then(res => {
        if (res.success && res.data) {
          const orderDraft = res.data
          const items = orderDraft.items
          const itemSelected = items[items.length-1]
          setItemSelected(itemSelected)
        }
      })
    }
  }

  const removeItem = async (item) => {
    if (!item) {
      item = itemSelected
    }

    if (item) {
      const index = orderDraft.items.findIndex(v => v._id === item._id)

      let params = {}
      params.updateType = 'pull'
      params.updateElement = 'items'
      params.scenario = 'removeItem'
      params.deleteId = item._id

      props.dispatch(updateOrder(query, {
        body: params,
        loadId: 'formName',
        useDB: 'client'
      })).then(res => {
        if (res.success && res.data) {
          const orderDraft = res.data
          const items = orderDraft.items

          if (index > -1) {
            let itemSelected = items[index]

            if (!itemSelected) {
              itemSelected = items[items.length-1]
            }

            setItemSelected(itemSelected)
          }
        }
      })
    }
  }

  const removeManyItems = (manyItems) => {
    manyItems.map(async (item) => {
      await removeItem(item)
    })
  }

  const updateItem = (params={}) => {
    if (itemSelected) {
      params.updateType = 'set'
      params.updateElement = 'items'
      params.updateId = itemSelected._id

      props.dispatch(updateOrder(query, {
        body: params,
        loadId: 'formName',
        useDB: 'client'
      }))
    }
  }


  return (
    <div className="height-full width-full" style={{ display: 'flex', flexDirection: 'row' }}>

      { orderDraft && orderDraft.items && orderDraft.items.length > 0 ?
        <Fragment>
          <div
            className="pst-relative"
            style={{ width: 'calc(100% - 300px)' }}>

            <OrderItemsList
              orderDraft={props.orderDraft}
              itemSelected={itemSelected}
              setItemSelected={setItemSelected}
            />
          </div>

          <div style={{ width: '300px' }}>
            <div className="height-full bg-fff border-left-1 border-left-ddd overflow-auto">
              <OrderItemOneManage
                itemSelected={itemSelected}
                addItem={addItem}
                updateItem={updateItem}
                removeItem={removeItem}
                keyboard={props.keyboard}
                keyboardValues={props.keyboardValues}
              />
            </div>
          </div>
        </Fragment>
      :
        <div
          className="font-1-2 width-full"
          style={{
            color: '#979797',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <div>
            <img src={barcodeReader} className="width-full" />
          </div>
          <div>กรุณาเลือกหรือสแกนบาร์โค้ดสินค้า</div>
          <div>เพื่อขายสินค้า</div>
        </div>
      }
    </div>
  )
})

export default OrderItemsAllManage

import React from 'react'

// Components POS
import KeyboardManage from '../../../../components/KeyboardManage'
import DraftOrdersListModal from '../DraftOrdersListModal/DraftOrdersListModal'

// Actions
import { openConfirm, openModal } from '../../../../../../../redux/App/AppActions'
import { removeOrder } from '../../../../../../../redux/OrdersDrafts/OrdersDraftsActions'
import { connect } from 'react-redux'


const MenuAndKeyboard = (props) => {
  const { handleKeyboardChange, keyboardValues, ordersDrafts, business, orderDraft } = props

  const handleholdOrder = () => {
    props.dispatch(openConfirm('createDraftOrder', {
      title: `คุณต้องการพักบิล เพื่อสร้างบิลใหม่`,
      message: `กดปุ่ม "พักบิล" ถ้าคุณต้องการพักบิล เพื่อสร้างบิลใหม่`,
      icon: 'assignment',
      type: 'warning',
      confirmIcon: 'send',
      confirmLabel: "พักบิล",
      cancelLabel: 'ยกเลิก',
      onConfirm: () => {
        return props.createDraftOrder({ toastMsg: 'พักบิลแล้ว', confirmId: 'createDraftOrder' })
      }
    }))
  }


  const handleDeleteOrder = (orders_id) => {
    const onDelete = () => {
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businesses_id: business._id,
        orders_id
      }

      props.dispatch(removeOrder(query, {
        toastMsg: 'ลบบิลนี้แล้ว',
        loadId: orders_id,
        confirmId: 'deleteOrder',
        useDB: 'client',
        props
      }))
    }

    props.dispatch(openConfirm('deleteOrder', {
      title: `ลบบิลนี้`,
      message: `กดปุ่ม "ลบบิลนี้" ถ้าคุณต้องการลบบิลนี้`,
      icon: 'delete',
      type: 'delete',
      confirmIcon: 'send',
      confirmLabel: "ลบบิลนี้",
      cancelLabel: 'ยกเลิก',
      onConfirm: () => {
        return onDelete()
      }
    }))
  }

  const haddleOpenHoldOrdersList = () => {
    props.dispatch(openModal('draftOrdersListModal'))
  }

  const gotoPayment = () => {
    props.history.push(`/${business.username}/pos/sales/${orderDraft._id}/payment`)
  }

  const gotoHistories = () => {
    props.history.push(`/${business.username}/pos/sales/${orderDraft._id}/histories/all`)
  }

  return (
    <div>
      <DraftOrdersListModal
        {...props}
        id="draftOrdersListModal"
        handleDeleteOrder={handleDeleteOrder}
      />

      <div className='pd-bottom-10'>
        { orderDraft && orderDraft.items && orderDraft.items.length > 0 ?
          <a onClick={gotoPayment} className="btn btn-success btn-shadow-none width-full height-60 line-height-60 pd-left-20 pd-right-20">
            <i className="material-icons-outlined left font-2-0">payments</i><span className="font-1-3">จ่ายเงิน</span>
          </a>
        :
          <a disabled className="btn btn-success btn-shadow-none width-full height-60 line-height-60 pd-left-20 pd-right-20">
            <i className="material-icons-outlined left font-2-0">payments</i><span className="font-1-3">จ่ายเงิน</span>
          </a>
        }
      </div>

      <div className='pd-bottom-10'>
        <KeyboardManage
          use={false}
          ref={props.keyboardRef}
          handleKeyboardChange={handleKeyboardChange}
          values={keyboardValues}
          startFocus="barcode"
          inputs={[
            { name: 'barcode', type: 'string', focus: true },
            { name: 'qty', type: 'number', useSpace: true },
            { name: 'price', type: 'number', minValue: 0 },
            { name: 'discount-percent', type: 'number', minValue: 0, maxValue: 100, useSpace: true },
            { name: 'discount-amount', type: 'number', minValue: 0, useSpace: true },
          ]}
        />
      </div>

      <div className='pd-bottom-5'>
        <a className="btn btn-white btn-shadow-none width-full height-45 line-height-45 border-ddd">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="width-30 center">
              <i className="material-icons-outlined font-1-5">phonelink_erase</i>
            </div>
            <div className="width-full center">ล้างหน้าจอ</div>
            <div className="width-30 center"></div>
          </div>
        </a>
      </div>

      <div className='pd-bottom-5'>
        <a onClick={handleholdOrder} className="btn btn-white btn-shadow-none width-full height-45 line-height-45 border-ddd">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="width-30 center">
              <i className="material-icons-outlined font-1-5">file_copy</i>
            </div>
            <div className="width-full center">พักบิล</div>
            <div className="width-30 center"></div>
          </div>
        </a>
      </div>

      <div className='pd-bottom-5'>
        <a onClick={haddleOpenHoldOrdersList} className="btn btn-white btn-shadow-none width-full height-45 line-height-45 border-ddd">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="width-30 center">
              <i className="material-icons-outlined font-1-5">recent_actors</i>
            </div>
            <div className="width-full center">เรียกบิล</div>
            <div className="width-30 center">
              { ordersDrafts.length > 1 &&
                <div className="dp-inline-block bg-error height-25 line-height-25 width-25 font-0-8 border-radius-10 color-fff">{ordersDrafts.length}</div>
              }
            </div>
          </div>
        </a>
      </div>

      <div className='pd-bottom-5'>
        <a onClick={gotoHistories} className="btn btn-white btn-shadow-none width-full height-45 line-height-45 border-ddd">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="width-30 center">
              <i className="material-icons-outlined font-1-5">history</i>
            </div>
            <div className="width-full center">ประวัติการขาย</div>
          </div>
        </a>
      </div>
    </div>
  )
}

export default connect()(MenuAndKeyboard)

import React, { Component } from 'react'
import { connect } from 'react-redux'

// coponents
import { Tooltip, TooltipIcon } from '../../tooltip/Tooltip'

// css
import css from './css/ratingStars.css'


class RatingStars extends Component {
  constructor(props) {
    super(props)
    this.state = {
      maxStars: this.props.maxStars ? parseInt(this.props.maxStars) : 5,
      value: this.props.value ? parseInt(this.props.value) : null,
      starsHover: 0
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value })
    }
  }

  handleActive = (star, type) => {
    if (this.state.starsHover === 0) {
      if (star <= this.state.value) {
        var icon = 'star'
        var color = "#ff6000"
      } else {
        var icon = 'star_border'
        var color = undefined
      }
    } else {
      if (star <= this.state.starsHover) {
        var icon = 'star'
        var color = "#ff6000"
      } else {
        var icon = 'star_border'
        var color = undefined
      }
    }

    return (type === 'icon' ? icon : color)
  }

  handleClick = (star) => {
    this.setState({ value: star })
  }

  handleMouseEnter = (star) => {
    this.setState({ starsHover: star })
  }

  handleMouseLeave = () => {
    this.setState({ starsHover: 0 })
  }

  handleReset = () => {
    this.setState({ value: null })
  }

  render () {
    return (
      <div className={css.ratingStars}>

        {this.props.label && !this.props.labelHide &&
          <label>{this.props.label}</label>
        }

        <div className={css.box} style={this.props.style}>
          { Array(this.state.maxStars).fill(1).map((el, star) => {
            if (!this.props.readOnly) {
              return (
                <i
                  id="recheck-form"
                  onClick={ () => this.handleClick(star+1) }
                  onMouseEnter={ () => this.handleMouseEnter(star+1) }
                  onMouseLeave={ () => this.handleMouseLeave(star+1)}
                  key={star} 
                  style={{ fontSize: this.props.size, color: this.handleActive(star+1, 'color') }} 
                  className={`material-icons ${css.star}`}>
                  
                  { this.handleActive(star+1, 'icon') }
                </i>
              )

            } else {
              if (this.state.value !== null) {
                return (
                  <i
                    key={star} 
                    style={{ fontSize: this.props.size, color: this.handleActive(star+1, 'color'), cursor: 'text' }} 
                    className={`material-icons ${css.star}`}>
                    
                    { this.handleActive(star+1, 'icon') }
                  </i>
                )
              }
            }
          }) }

          { !this.props.readOnly && this.state.value !== null &&
            <TooltipIcon
              id="recheck-form"
              onClick={this.handleReset} 
              style={{ float: 'left', lineHeight: '53px' }} 
              icon="remove_circle_outline" 
              msg="ลบดาวออก" />
          }
          
          { this.state.value !== null && !this.props.hideScore &&
            <span className={css.result}>( {this.state.value } {this.props.unit} )</span>
          }
        </div>

        { !this.props.readOnly &&
          <input
            id={this.props.name}
            ref={this.props.name}
            value={this.state.value ? this.state.value : ''}
            data-value-array-name={this.props.valueArrayName}
            data-not-save={this.props.notSave}
            data-child={this.props.child}
            name="rating-stars"
            className={this.state.value === null && this.props.required ? 'required invalid' : '' }
            type="hidden"
          />
        }

        { this.state.value === null && this.props.required &&
          <div className={css.msgError}>{this.props.label} ต้องไม่ว่าง</div>
        }
      </div>
    )
  }
}

export default connect()(RatingStars)
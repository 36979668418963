import React, { Component } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../routes/renderRoutes'

// Actions Redux
import { fetchPlace } from '../../../../redux/Places/PlacesActions'

// Values
import { placeValue } from '../../../../redux/Places/PlacesValues'


class PlaceContainer extends Component {
  componentDidMount() {
    const { business, match } = this.props
    const { params } = match

    this.props.dispatch(fetchPlace(business._id, params.places_id))
  }

  render() {
    const { route, place, business, location } = this.props

    return (
      <div>
        { place ?
          <div>
            { renderRoutes({
              routes: route.routes,
              extraProps: { business, place: placeValue(place) },
              location
            }) }
          </div>
        :
          <h1>404 Not Found</h1>
        }
      </div>
    )
  }
}


const mapStateToProps = (store, props) => {
  const { business, match } = props
  const { params } = match

  return {
    place: store.places.data.filter((place => place._id === params.places_id))[0]
  }
}

export default connect(mapStateToProps)(PlaceContainer)

import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../components/Modals/Modal'
import { Row, Column } from '../../../../../components/Columns'

// Components forms
import FormValidation from '../../../../../components/FormValidation'
import BtnSubmit from '../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../util/getFormValue'

// Components Local
import { FolderName } from '../FolderForms'

// Actions Redux
import { createFolder } from '../../../../../redux/Folders/FoldersActions'


class FolderCreateModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: '',
      path: '',
      status: ''
    }
  }

  handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(this.refs[formName])
    const { business, foldersManage, match } = this.props
    const { type } = foldersManage

    const { folders_id } = match.params

    params.type = 'product'
    params.subtype = 'product'
    params.folders_id = folders_id

    if (params) {
      params.type = type

      this.props.dispatch(createFolder(business._id, {
        body: params,
        loadId: formName,
        modalId: formName,
        toastMsg: 'สร้างหมวดหมู่แล้ว'
      }))
    }
  }

  handleChange = (data) => {
    this.setState({
      data,
      path: data.value,
      status: data.status
    })
  }

  render() {
    const id = "folderCreateModal"

    let { folders } = this.props
    folders = folders || []

    return (
      <Modal
        id={id}
        headerTitle="สร้างหมวดหมู่"
        headerLeft
        width="450px">

        <Row className="pd-20 pd-top-25">
          <FormValidation
            recheckForm
            ref={id}
            name={id}
            submit={(e) => this.handleSubmit(e, id)}>

            <Column col="column col-xs-12">
              <FolderName />
            </Column>

            <Column col="column col-xs-12 text-align-right">
              <BtnSubmit
                loadingId={id}
                icon="send"
                iconAlign="left"
                text="สร้างหมวดหมู่"
                className="waves-effect waves-light btn btn-submit btn-shadow-none"
                btnDisabled
              />
            </Column>
          </FormValidation>
        </Row>
      </Modal>
    )
  }
}

export default connect()(FolderCreateModal)

import React from 'react'

// Components Customers
import { CustomerShort } from '../../../../Customers/components/CustomersUserInterface'

// Utils
import { getDate } from '../../../../../../util/getDateTime/getDateTime'
import { getNumeral } from '../../../../../../util/getNumeral'

// Values
import { orderValueECommerce } from '../../../../../../redux/Orders/OrdersValues'


export const OrderItem = (props) => {
  const { business, data, history, location, match, openModal } = props
  const { orders_id, status } = match.params
  let order = data
  order = orderValueECommerce({ order })

  const goto = () => {
    let path = `/${business.username}/sell-online/${status}/${order._id}`

    if (location.search) {
      path = `${path}${location.search}`
    }

    openModal()
    history.push(path)
  }

  return (
    <tr
      style={{ userSelect: 'none' }}
      onClick={() => goto()}
      className={orders_id === order._id ? 'trActive' : ''}>

      <td width="5" height="65" className="pd-0" style={{ background: order.useStatus && order.useStatus.color }}></td>

      <td width="50" className="center hidden-xxs hidden-xs hidden-sm">
        <i className="material-icons i-middle" style={{ color: order.useStatus && order.useStatus.color }}>{ order.useStatus && order.useStatus.icon }</i>
      </td>

      <td className="font-0-9 pd-10 hidden-xlg">
        <div>#{ order.code }</div>
        <div>วันที่ : { getDate(order.created, 'DD/MM/YYYY', 'th') }</div>
        <div>ยอดชำระ : ฿{ getNumeral(order.totalNetAmount, 'auto') }</div>
      </td>

      <td className="font-0-9 pd-10 hidden-lg hidden-md hidden-sm hidden-xs hidden-xxs">
        <div>#{ order.code }</div>
        <div className="font-0-8 color-sub">วันที่ { getDate(order.created, 'DD/MM/YYYY', 'th') }</div>
      </td>

      <td className="pd-0">
        <span className={`badge-border`} style={{ color: order.useStatus && order.useStatus.color, border: `1px solid ${order.useStatus && order.useStatus.color}` }}>
          { order.useStatus && order.useStatus.name }
        </span>
      </td>


      <td className="pd-5 center hidden-xxs hidden-xs hidden-sm">
        <CustomerShort customer={order.customer} order={order} />
      </td>

      <td className="pd-0">
        { order.channelSales }
      </td>


      <td className="text-right pd-0 hidden-lg hidden-md hidden-sm hidden-xs hidden-xxs">
        <span className="color-price font-1-1">฿{ getNumeral(order.totalNetAmount, 'auto') }</span>
        <div className="font-0-8 color-sub">ราคา</div>
      </td>
      <td className="pd-3"></td>
    </tr>
  )
}

import React from "react"

// Components Global
import { LayoutsDesign } from '../../../LayoutsDesign'
import { fetchPage, updatePage, uploadImagesPage } from '../../../../redux/Pages/PagesActions'


const PagePartsManage = (props) => {
  const { datas, groupId } = props
  const { business, setting, site, page } = datas

  return (
    <div style={{ padding: '20px 20px 20px 50px' }}>
      <LayoutsDesign
        {...props}
        groupId={groupId}
        action={{
          fetch: fetchPage,
          update: updatePage,
          uploadImage: uploadImagesPage,
          params: {
            business,
            setting,
            site,
            data: page,
            _id: page._id,
            imagePrefix: 'pages'
          }
        }}
      />
    </div>
  )
}

export default PagePartsManage

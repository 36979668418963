import React from 'react'
import renderRoutes from '../../../../routes/renderRoutes'

// Components Global
import Window from '../../../../components/Windows/Window'

// imgs
import titleIcon from '../resources/imgs/sites-title-icon.png'


const SitesMenuContainer = (props) => {
  const { business, setting, location, route, match, windowModal, closeWindowModal, history } = props

  return (
    <Window
      title="จัดการเว็บไซต์"
      titleIcon={titleIcon}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkClose={`/${match.params.username}`}>

      <div>
        { renderRoutes({
          routes: route.routes,
          extraProps: { business, setting },
          location,
        }) }
      </div>
    </Window>
  )
}

export default SitesMenuContainer

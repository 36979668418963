// Containers
import RubberContractsContainer from '../../../../../../containers/dashboard/SystemRubber/RubberContracts/containers/RubberContractsContainer'


// Routes
import RubberContractsMenuRoutes from './routes/RubberContractsMenuRoutes'



const RubberContractsRoutes = [
  {
    path: '/:username/rubber-contracts',
    component: RubberContractsContainer,
    routes: [
      ...RubberContractsMenuRoutes,
    ]
  }
]

export default RubberContractsRoutes

import React, { Component } from 'react'

// Config
import config from '../../../../config'

// Components Global
import TextInput from '../../../../components/input/TextInput'
import SocialSignIn from '../../../../components/SocialSignIn'
import BtnSubmit from '../../../../components/button/BtnSubmit'

// Redux Actions
import { socialSignIn } from '../../../../redux/Auth/AuthActions'


export const FirstName = (props) => {
  return (
    <TextInput
      label="ชื่อ"
      id="firstName.local"
      value={props.value}
      active
      placeholder="ชื่อ"
      inputType="text"
      type="text"
      required="true"
      iconValid />
  )
}

export const LastName = (props) => {
  return (
    <TextInput
      label="นามสกุล"
      id="lastName.local"
      value={props.value}
      active
      placeholder="นามสกุล"
      inputType="text"
      type="text"
      required="true"
      iconValid />
  )
}

export const Email = (props) => {
  return (
    <TextInput
      label="อีเมล"
      id="email"
      value={props.value}
      active
      placeholder="อีเมล"
      inputType="email"
      type="email"
      required="true"
      msgError="รูปแบบอีเมลไม่ถูกต้อง"
      iconValid />
  )
}


export const Password = (props) => {
  return (
    <TextInput
      label="รหัสผ่าน"
      id="password"
      value={props.value}
      active
      placeholder="รหัสผ่าน"
      inputType="password"
      type="text-min-max"
      min="6" max="30"
      msgError="ต้องเป็นตัวอักษร ที่มี 6 หลักขึ้นไป และไม่เกิน 30 หลัก"
      required="true"
      iconValid />
  )
}

export const SocialSignInAll = (props) => {
  const { facebook, google } = config.app

  const handleSocialSignIn = (user, err) => {
    if (user) {
      let loadId
      let redirectAfterLogin = props.location.search.split("&")[0].replace("?", "").split("next=")[1]
      let url = redirectAfterLogin ? redirectAfterLogin : '/'

      if (user.providerFacebook) loadId = "facebook"
      else if (user.providerGoogle) loadId = "google"

      props.dispatch(socialSignIn({
        body: user,
        loadId: loadId,
        props: props,
        redirect: { url },
        toastMsg: 'เข้าสู่ระบบเรียบร้อยแล้ว'
      }))
    }
  }

  return (
    <div>
      <div className="center">
        <SocialSignIn provider="Facebook" appId={facebook.appId} callback={handleSocialSignIn}>
          <BtnSubmit
            loadingId="facebook"
            iconFa="fab fa-facebook-square"
            iconAlign="left"
            text="เข้าสู่ระบบด้วย FACEBOOK"
            className="waves-effect waves-light blue darken-4 btn width-full"
          />
        </SocialSignIn>
      </div>
      <div className="center">
        <SocialSignIn provider="Google" appId={google.appId} callback={handleSocialSignIn}>
          <BtnSubmit
            loadingId="google"
            iconFa="fab fa-google"
            iconAlign="left"
            text="เข้าสู่ระบบด้วย GOOGLE"
            className="waves-effect waves-light red accent-4 btn width-full mg-top-10"
          />
        </SocialSignIn>
      </div>
    </div>
  )
}

export const NewPassword = (props) => {
  return (
    <TextInput
      label="รหัสผ่านใหม่"
      placeholder="รหัสผ่านใหม่"
      active
      iconValid
      id="password"
      inputType="password"
      type="text-min-max"
      min="6" max="30"
      msgError="ต้องเป็นตัวอักษร ที่มี 6 หลักขึ้นไป และไม่เกิน 30 หลัก"
      required="true"
      matchLastId="rePassword"
      msgError="ต้องเป็นตัวเลข ที่มี 6 หลักขึ้นไป และไม่เกิน 30 หลัก"
    />
  )
}

export const NewRePassword = (props) => {
  return (
    <TextInput
      label="รหัสผ่านใหม่อีกครั้ง"
      placeholder="รหัสผ่านใหม่อีกครั้ง"
      active
      iconValid
      id="rePassword"
      inputType="password"
      type="text"
      matchFirstId="password"
      required="true"
      msgError="ไม่ตรงกัน"
    />
  )
}

import React from 'react'
import { Link } from 'react-router-dom'

// Components Global
import Tooltip from '../../../../../../TooltipNew'
import Loading from '../../../../../../Loading'
import Breadcrumbs from '../../../../../../Breadcrumbs'

// Components
import PagesDropdown from './components/PagesDropdown'

// js
import { getTitle } from '../ContentGroupPage/js/contentGroupPage'

// css
import css from './css/header.css'
import LangSetting from '../../../../../../LangSetting'


const Header = (props) => {
  const lang = props.lang || 'local'
  const { params, match, template, contentGroupPage, urlMainManage } = props
  const pages = props.pages || []
  const { subtitle } = getTitle(props)

  let linkBack, breadcrumbs

  if (params && !params.page) {
    linkBack = urlMainManage

    if (params.header) {
      breadcrumbs = [
        { label: 'จัดการเว็บ', url: `${urlMainManage}` },
        { label: 'จัดการส่วนหัว', url: `${urlMainManage}/header-manage` }
      ]
    }

    if (params.footer) {
      breadcrumbs = [
        { label: 'จัดการเว็บ', url: `${urlMainManage}` },
        { label: 'จัดการส่วนท้าย', url: `${urlMainManage}/footer-manage` }
      ]
    }

    if (params.widget) {
      breadcrumbs = [
        { label: 'จัดการเว็บ', url: `${urlMainManage}` },
        { label: 'จัดการวิดเจ็ต', url: `${urlMainManage}/widget-manage` }
      ]
    }

  } else {
    linkBack = props.linkBack
  }

  const { path_1 } = match.params

  let pageTemplate

  if (params.siteManage) {
    pageTemplate = pages.filter(page => page._id === path_1 && page.type === 'template')[0]
  }

  if (pageTemplate) {
    breadcrumbs=[
      { label: 'จัดการเว็บ', url: `${urlMainManage}` },
      { label: 'จัดการรูปแบบ', modalId: 'pageTemplateManageModal' },
      { label: `${pageTemplate.name[lang]}`, url: `${urlMainManage}/page-template-manage/${pageTemplate._id}` }
    ]
  }

  if (template) {
    breadcrumbs=[
      { label: 'จัดการเว็บ', url: `${urlMainManage}` },
      { label: 'เทมเพลต', modalId: 'templatesManageModal' },
      { label: `${template.name[lang]}`, url: `${urlMainManage}/template-manage/${template._id}` }
    ]
  }

  if (contentGroupPage) {
    const { content_group_page_id } = match.params

    breadcrumbs=[
      { label: 'จัดการเว็บ', url: `${urlMainManage}` },
      { label: 'หน้าเพจ', modalId: 'contentGroupPagesManageModal' },
      { label: contentGroupPage.name[lang], url: `${urlMainManage}/content-group-page/${content_group_page_id}` }
    ]

    if (params.contentGroupPageHeader) {
      breadcrumbs.push({
        label: subtitle, url: `${urlMainManage}/content-group-page/${content_group_page_id}/header`
      })

      linkBack = `${urlMainManage}/content-group-page/${content_group_page_id}`
    }

    if (params.contentGroupPageFooter) {
      breadcrumbs.push({
        label: subtitle, url: `${urlMainManage}/content-group-page/${content_group_page_id}/footer`
      })

      linkBack = `${urlMainManage}/content-group-page/${content_group_page_id}`
    }
  }

  return (
    <div className={`${css.header}`} >
      <div className={`${css.back} width-70-xxs width-70-xs width-70-sm`}>
        <Link className={css.btnBack} to={linkBack}>
          <i className="material-icons left i-middle mg-left-10-xxs mg-left-10-xs mg-left-10-sm">arrow_back</i>
          <span className="hidden-xxs hidden-xs hidden-sm"> กลับ</span>
        </Link>
      </div>

      { params.siteManage && pages.length !== 0 && params.page && !breadcrumbs &&
        <div className={`${css.boxTitle} left-70-xxs left-70-xs left-70-sm`}>
          <PagesDropdown {...props} />
        </div>
      }

      { params.singlePageManage && !breadcrumbs &&
        <div className={`${css.boxTitle} left-70-xxs left-70-xs left-70-sm`}>
          <div className={css.title}>
            <span>{props.title && props.title[lang]}</span>
          </div>
        </div>
      }

      { breadcrumbs &&
        <div className={`${css.boxTitle} left-70-xxs left-70-xs left-70-sm`}>
          <div className={css.title}>
            <div className="pd-top-4">
              <Breadcrumbs items={breadcrumbs} />
            </div>
          </div>
        </div>
      }


      <div className={`${css.boxLang} mg-top-4`}>
        <LangSetting />
      </div>


      <div className={`${css.boxLoading}`}>
        <Loading id="site-update-loading" style={{ display: 'inline-block' }}>
          <div className="color-success">
            <i className="material-icons i-middle left" style={{ marginTop: '-3px', marginRight: '5px' }}>check_circle_outline</i>
            บันทึกแล้ว
          </div>

          <div className="color-error">
            <i className="material-icons i-middle left icon-loading" style={{ marginTop: '-3px', marginRight: '5px' }}>cached</i>
            กำลังบันทึก...
          </div>
        </Loading>
      </div>


      { (pages.length !== 0 || params.singlePageManage) &&
        <div className={`${css.boxManager}`}>
          <Tooltip
            left
            width={130}
            msg="ลิงก์เว็บไซต์ที่เผยแพร่"
            unit="px"
            childHeight="40px"
            className="mg-right-8">

            <button className="waves-effect waves-light btn-shadow-none btn-floating btn-xxs">
              <i className="material-icons">link</i>
            </button>
          </Tooltip>

          <Link to={`${urlMainManage}/site-display`} className="waves-effect waves-light btn btn-shadow-none btn-xxs">
            แสดงตัวอย่าง
          </Link>
        </div>
      }
    </div>
  )
}

export default Header

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from '../Modals/Modal'
import { Btn } from '../button/Button'
import AvatarEditor from 'react-avatar-editor'
import Compressor from 'compressorjs'

// redux
import { closeModal } from '../../redux/App/AppActions'



class ImageCrop extends Component {
  constructor(props) {
    super(props)
    this.state = { scale: 1 }
  }

  componentDidMount() {
    this.handleSelect(this.props.file)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.file !== this.props.file) {
      this.handleSelect(this.props.file)
    }
  }

  handleSelect = (file) => {
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = (e) => {
        if (file.type === "image/jpeg" || file.type === "image/png") {
          this.setState({
            img: reader.result,
            file: file
          })
        } else {
          alert(`ไม่สามารถอัพโหลด "${file.name}" เนื่องจาก ประเภทของไฟล์ไม่ถูกต้อง (รองรับเฉพาะ jpeg, png, jpg)`)
        }
      }
    }
  }

  dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  handleSave = (data) => {
    const img = this.refs.avatar.getImageScaledToCanvas().toDataURL()
    const rect = this.refs.avatar.getCroppingRect()

    this.setState({ preview: img, croppingRect: rect })
    this.setState({ modal: false })

    let base64Url

    if (this.state.file.type !== 'image/png') {
      base64Url = img.replace('data:image/png;', `data:${this.state.file.type};`)
    } else {
      base64Url = img
    }

    const file = this.dataURLtoFile(base64Url, this.state.file.type)
    const reader = new FileReader()
    reader.readAsDataURL(file)

    new Compressor(file, {
      quality: 0.3,
      maxWidth: 2500,
      maxHeight: 2500,

      success: (result) => {
        const formData = new FormData()
        formData.append('file', result, result.name)

        reader.onloadend = (e) => {
          if (file.type === "image/jpeg" || file.type === "image/png") {
            this.props.getFiles(result, reader.result)
            this.handleModalClose(false)
          } else {
            alert(`ไม่สามารถอัพโหลด "${file.name}" เนื่องจาก ประเภทของไฟล์ไม่ถูกต้อง (รองรับเฉพาะ jpeg, png, jpg)`)
          }
        }

        reader.readAsDataURL(result)
      },

      error: (err) => {
        console.log(err.message)
      }
    })
  }

  handleScale = () => {
    const scale = parseFloat(this.refs.scale.value)

    this.setState({ scale })
  }

  handleModalClose = (removeFile=true) => {
    if (removeFile && this.props.removeFile) {
      this.props.removeFile()
    }

    this.props.dispatch(closeModal(this.props.id))
  }

  render() {
    let style = {
      borderRadius: this.props.crop.circle && '50%',
      border: '4px solid #d1d1d1',
      cursor: 'move',
      width: '100%',
      height: 'auto',
    }

    return (
      <Modal
        headerTitle="ครอปรูปภาพ"
        headerLeft
        id={this.props.id}
        width="500px"
        btnModalClose={this.props.removeFile}
        blockBgModal>

        { this.state.img && this.state.file &&
          <div className="pd-20">
            <div className="center">
              <AvatarEditor
                ref="avatar"
                image={this.state.img}
                width={this.props.crop.width}
                height={this.props.crop.height}
                border={0}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={parseFloat(this.state.scale)}
                style={style}
              />

              <table width="100%">
                <tbody>
                  <tr>
                    <td width="40">
                      <i className="material-icons">zoom_out</i>
                    </td>
                    <td>
                      <p className="range-field" style={{ paddingTop: '10px' }}>
                        <input name="scale" type="range" ref="scale" onChange={this.handleScale} min="1" max="2" step="0.01" defaultValue="1" />
                      </p>
                    </td>
                    <td width="40">
                      <i className="material-icons">zoom_in</i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="right-align">
              <Btn
                icon="close"
                iconAlign="left"
                className="btn red darken-2 btn-shadow-none"
                onClick={this.handleModalClose}
                text="ยกเลิก"
                style={{ marginRight: '10px'}}
              />

              <Btn
                icon="check"
                iconAlign="left"
                className="btn light-green darken-1 btn-shadow-none"
                onClick={this.handleSave}
                text="เลือกรูปนี้"
              />
            </div>
          </div>
        }
      </Modal>
    )
  }
}

export default connect()(ImageCrop)

import React from 'react'

// Components Global
import ProfileAvatar from '../../../../../../components/ProfileAvatar'

// Funtions Product
import { getItemImage } from '../../../../../../redux/Products/ProductsFunctions'


const ProductItemProfileAvater = (props) => {
  const { product, item, fontSize, imageSize } = props
  const { avatarName } = product
  const images = getItemImage({ product, item })

  return (
    <ProfileAvatar
      circle={props.circle}
      images={images}
      imageSize={imageSize || 'm'}
      color={product.avatarColor}
      texts={avatarName}
      textSize={fontSize || '1.1rem'}
    />
  )
}

export default ProductItemProfileAvater

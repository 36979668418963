import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import AppDashboard from './AppDashboard'
import { configureStore } from '../../redux/store'


// Initialize store
const store = configureStore(window.__INITIAL_STATE__)
const mountApp = document.getElementById('root')
const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate

renderMethod(
  <AppContainer>
    <AppDashboard store={store} />
  </AppContainer>,
  mountApp
)

// For hot reloading of react components
if (module.hot) {
  module.hot.accept('./AppDashboard', () => {
    // If you use Webpack 2 in ES modules mode, you can
    // use <App /> here rather than require() a <NextApp />.
    const NextApp = require('./AppDashboard').default // eslint-disable-line global-require
    renderMethod(
      <AppContainer>
        <NextApp store={store} />
      </AppContainer>,
      mountApp
    )
  })
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import cuid from 'cuid'
import config from '../../../config'

// css
import css from './css/inputPhoneNumber.css'

// Components Global
import InputSelectData from '../InputSelectData'
import Required from '../Required'

// Redux Actions
import { fetchCountries } from '../../../redux/Geo/GeoActions'

const CDN_URL = config.app.cdnUrl

class SelectCountry extends Component {
  constructor(props) {
    super(props)

    const id = this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()

    this.state = {
      id: randomId+id,
      value: '',
      continentNameEn: '',
      continentNameTh: '',
      countryNameEn: '',
      countryNameTh: ''
    }
  }

  componentDidMount() {
    if (this.props.countries.length === 0) {
      this.props.dispatch(fetchCountries())
    }

    if (this.props.value) {
      this.setValue(this.props.value)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setValue(this.props.value)
    }
  }

  setValue = (value) => {
    const countrySelected = this.props.countries.filter(country => country.countryCode === value)[0]

    this.setState({
      value,
      continentNameEn: countrySelected ? countrySelected.continentNameEn : '',
      continentNameTh: countrySelected ? countrySelected.continentNameTh : '',
      countryNameEn: countrySelected ? countrySelected.countryNameEn : '',
      countryNameTh: countrySelected ? countrySelected.countryNameTh : ''
    })

    this.props.getValue(value)

    if (this.props.getValue) {
      this.props.getValue(value)
    }
  }

  getValue = (value) => {
    this.setValue(value)
  }

  render() {
    const datas = []
    const countries = this.props.countries

    countries.map(country => {
      datas.push({
        img: `${CDN_URL}/common/imgs/icons/flags/${country.countryCode}.png`,
        label: country.countryNameTh,
        value: country.countryCode,
        obj: country
      })
    })

    return (
      <div className={css.inputPhoneNumber}>
        <Required
          id={this.state.id}
          classId={this.state.id}
          label={this.props.label}
          type="text"
          value={this.state.value}
          required={this.props.required}
          iconValid={this.props.iconValid}>

          <div>
            <InputSelectData
              avatar
              showLabel
              id={`${this.props.id}.countryCode`}
              classId={this.state.id}
              placeholder="ประเทศ"
              value={this.state.value}
              datas={datas}
              getValue={this.getValue}
              required={this.props.required}
              search={[
                { name: 'countryNameEn' },
                { name: 'countryNameTh' },
              ]}
            />

            <input type="hidden" data-id={`${this.props.id}.continentNameEn`} value={this.state.continentNameEn} />
            <input type="hidden" data-id={`${this.props.id}.continentNameTh`} value={this.state.continentNameTh} />
            <input type="hidden" data-id={`${this.props.id}.countryNameEn`} value={this.state.countryNameEn} />
            <input type="hidden" data-id={`${this.props.id}.countryNameTh`} value={this.state.countryNameTh} />
          </div>
        </Required>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    countries: state.geo.countries
  }
}

export default connect(mapStateToProps)(SelectCountry)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../routes/renderRoutes'

// Components Global
import WindowBack from '../../../../components/Windows/WindowBack'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../components/MenuSidebarResponsive'

// Functions
import {
  ordersGroupMenu,
  countOrdersGroup,
  countOrdersGroupDefault
}  from '../../../../redux/Orders/OrdersFunctions'


const menuItems = (params, customer, state) => {
  const linkStart = `/${params.username}/customers/advanced/${params.customers_id}/update`

  return [
    {
      name: 'ข้อมูล',
      title: true
    },
    {
      name: 'รายละเอียด',
      nameShort: 'รายละเอียด',
      icon: 'description',
      link: `${linkStart}`,
      exact: true
    },
    {
      name: 'รูปภาพ',
      nameShort: 'รูปภาพ',
      icon: 'photo_library',
      link: `${linkStart}/photos`
    },
    {
      name: 'ที่อยู่',
      nameShort: 'ที่อยู่',
      icon: 'room',
      link: `${linkStart}/addresses`
    }
  ]
}


class CustomerUpdateContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { route, match, customer, business, location } = this.props
    const { params } = match
    const { username, customers_id } = params
    const pathStatus = customer.status === 'open' ? '' : `${customer.status}`

    return (
      <WindowBack
        title={`แก้ไข : ${customer.nameLang}`}
        linkBack={`/${params.username}/customers/${customers_id}`}
        breadcrumbs={[
          { label: 'ลูกค้า', url: `/${username}/customers/all` },
          { label: customer.nameLang, url: `/${username}/customers/${customers_id}` },
          { label: 'แก้ไขเพิ่มเติม', url: `/${username}/customers/${customers_id}/advanced/update` },
        ]}>

        <LayoutsTwo>
          <Layouts1>
            <MenuSidebarResponsive items={menuItems(match.params, customer, this.state)} />
          </Layouts1>

          <Layouts2 hideScrollbars>
            { renderRoutes({
              routes: route.routes,
              extraProps: { business, customer },
              location
            }) }
          </Layouts2>

        </LayoutsTwo>
      </WindowBack>
    )
  }
}

export default connect()(CustomerUpdateContainer)

import React, { Component } from 'react'
import ReactDOM from 'react-dom'

// Components Global
import ProfileAvatar from '../../ProfileAvatar'

import css from './css/inputSelect.css'

class InputSelectData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, ''),
      open: false,
      value: this.props.value,
      items: this.props.datas,
      datas: this.props.datas
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.datas !== prevProps.datas) {
      this.setState({
        datas: this.props.datas,
        items: this.props.datas,
        value: this.props.value
      }, () => {
        this.defaultValue()
      })
    }

    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value }, () => {
        this.defaultValue()
      })
    }

    this.checkItem()
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick)

    this.setState({ value: this.props.value }, () => {
      this.defaultValue()
    })
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick)
  }

  handleDocumentClick = (e) => {
    if (!ReactDOM.findDOMNode(this).contains(e.target)) {
      this.setState({ open: false, items: this.props.datas })

      /*if (this.props.getValue) {
        this.props.getValue(this.state.value)
      }*/
    }
  }

  openDropdown = () => {
    this.setState({ open: true })
  }

  onSelect = (value, label, src=undefined, iconBank=undefined, profileAvatar=undefined) => {
    this.setState({
      open: false,
      label: label,
      value: value,
      img: src,
      profileAvatar: profileAvatar,
      iconBank: iconBank,
      items: this.props.datas
    }, () => {
      if (this.props.getValue) {
        this.props.getValue(this.state.value)
      }
    })
  }

  defaultValue = () => {
    let defaultValue = this.state.items.filter(item => item.value === this.state.value)[0]

    if (defaultValue) {
      this.setState({
        label: defaultValue.label,
        img: defaultValue.img ? defaultValue.img : undefined,
        profileAvatar: defaultValue.profileAvatar ? defaultValue.profileAvatar : undefined,
        iconBank: defaultValue.iconBank ? defaultValue.iconBank : undefined
      })
    }
  }

  onSearch = (e) => {
    this.search(e.target.value)
  }

  search = (value) => {
    let filter = []
    let config = this.state.config

    if (value.length >= 0) {
      this.state.datas.map((data, i)=> {
        let val = data.label.toLowerCase().indexOf(value.toLowerCase())

        if (val > -1) {
          let start = val
          let label = data.label

          if (start === val) {
            let re = new RegExp( "[" + value + "]", "gi" )
            label = data.label.replace(re, "<b>" + "$&" + "</b>")
          }

          filter.push({
            label: data.label,
            highlight: label,
            value: data.value,
            img: data.img ? data.img : undefined,
            profileAvatar: data.profileAvatar ? data.profileAvatar : undefined,
            iconBank: data.iconBank ? data.iconBank : undefined
          })
        }
      })
    }

    this.setState({ items: filter })
  }

  checkItem = () => {
    if (this.props.recheck) {
      let item = this.state.items.filter(item => item.value === this.state.value)[0]

      if (!item) {
        this.setState({ value: '' })
      }
    }
  }

  checkItemsFiler = () => {
    let item = this.state.items.filter(item => item.value === this.state.value)[0]

    return item
  }

  clearValue = () => {
    this.setState({ value: '' })

    if (this.props.getValue) {
      this.props.getValue('')
    }
  }

  render() {
    return (
      <div className={css.inputSelect}>

        { !this.props.disabled ?

          <div>
            { this.state.value && !this.props.notUseValue ?
              <div id={this.props.classId} className={`${css.showValue}`} style={this.props.selectStyle}>

                { this.state.value && this.state.img &&
                  <img src={this.state.img} width="30" />
                }

                { this.state.value && this.state.value !== '' && this.state.profileAvatar &&
                  <div className="width-30 height-30 left mg-top-5 mg-right-10">
                    <ProfileAvatar
                      circle
                      images={this.state.profileAvatar.images}
                      imageSize="m"
                      color={this.state.profileAvatar.color}
                      texts={this.state.profileAvatar.texts}
                      textSize="0.8rem"
                    />
                  </div>
                }

                { this.state.value && this.state.iconBank &&
                  <i className={`thbanks ${this.state.iconBank} left color-fff mg-top-5 dp-inline-block line-height-0 pd-3 font-1-5`} aria-hidden="true"></i>
                }

                { this.state.value &&
                  <span className={css.nameValue}>
                    { this.props.showLabel ? this.state.label : this.state.value }
                  </span>
                }


                { this.state.value &&
                  <a onClick={this.clearValue} className={`${css.clear} waves-effect right waves-teal btn-flat btn-floating btn-floating-md mg-right-25`}>
                    <i className="material-icons color-000">close</i>
                  </a>
                }
              </div>
            :
              <div>
                <input
                  type="text"
                  id={this.props.classId}
                  className={`${this.props.required ? 'required' : ''}`}
                  placeholder={this.props.placeholder}
                  onChange={this.onSearch}
                  onFocus={this.openDropdown}
                  onKeyUp={this.onSearch}
                />
              </div>
            }
          </div>

        :
          <div>
            <input placeholder={this.props.placeholder} type="text" disabled />
          </div>
        }

        <input
          type="hidden"
          id={this.state.id}
          data-id={this.props.id}
          value={this.state.value}
          data-not-save={this.props.notSave}
        />


        { this.state.open &&
          <div className={css.boxDropdown} style={{ left: !this.props.right && '0', right: this.props.right && '0' }}>
            { !this.props.hiddenSearch &&
              <div className={css.search}>
                <input onChange={this.onSearch} placeholder="ค้นหา" />
              </div>
            }

            <ul className={css.dropdown}>
              { this.state.items.map((item, i) => {
                return (
                  <li key={i} className={`waves-effect waves-light ${item.value === this.state.value && css.selected}`}>
                    { !item.divider &&
                      <a onClick={() => this.onSelect(item.value, item.label, item.img, item.iconBank, item.profileAvatar)}>

                        { item.img &&
                          <img src={item.img} width="30" />
                        }

                        { item.profileAvatar &&
                          <div style={{ marginTop: '-4px', zIndex: '1' }} className="width-30 height-30 left  mg-right-10">
                            <ProfileAvatar
                              circle
                              images={item.profileAvatar.images}
                              imageSize="m"
                              color={item.profileAvatar.color}
                              texts={item.profileAvatar.texts}
                              textSize="0.8rem"
                            />
                          </div>
                        }

                        { item.iconBank &&
                          <i style={{ marginTop: '-3px' }} className={`thbanks ${item.iconBank} left color-fff dp-inline-block line-height-0 pd-3 font-1-5`} aria-hidden="true"></i>
                        }

                        <span dangerouslySetInnerHTML={{ __html: item.highlight ? item.highlight : item.label }} />
                      </a>
                    }
                  </li>
                )
              }) }

              { this.state.items.length === 0 &&
                <li className={css.search}>
                  <span className={css.noSearch}>ไม่มีรายการ</span>
                </li>
              }
            </ul>
          </div>
        }
      </div>
    )
  }
}

export default InputSelectData

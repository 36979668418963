import React from 'react'
import _JSXStyle from 'styled-jsx/style'

// Components Global
import { Row, Column } from '../../../../../../../../Columns'
import TextInput from '../../../../../../../../Inputs/TextInput'

// Components Text Editor
import InputElementManage from '../../../../../../../../TextEditor/components/ElementStylesManage/components/InputElementManage'
import DivElementManage from '../../../../../../../../TextEditor/components/ElementStylesManage/components/DivElementManage'

// Components Local
import BoxContent from '../BoxContent'


const InputStyle = (props) => {
  let { _this, style } = props
  style = style || {}

  return (
    <div className="pd-bottom-20">
      <Row className="row-dp-flex">
        <Column col="column col-xxs-12 col-lg-6 pd-5">
          <BoxContent>
            <div>
              <TextInput id="test" label="อินพุต" styleJsx={style} styleName="input" />
            </div>

            <div className="pd-10 bg-fafafa">
              <DivElementManage
                {...props}
                name="box-input"
                title="กล่องอินพุต"
                icon="crop_16_9"
                hidden={{
                  opacity: true,
                  lineHeight: true,
                  font: true,
                  iconSize: true,
                  iconColor: true,
                  iconMargin: true,
                  position: true,
                  textAlign: true,
                  float: true,
                  lineClamp: true,
                  boxShadow: true,
                  justifyContent: true,
                  heightFull: true,
                  display: true
                }}
              />

              <InputElementManage
                {...props}
                label
                name="input-label"
                title="ชื่ออินพุด"
                icon="text_fields"
              />

              <InputElementManage
                {...props}
                name="input"
                title="อินพุด"
                icon="input"
              />
            </div>
          </BoxContent>
        </Column>
      </Row>
    </div>
  )
}


export default InputStyle

import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Components Global
import { Row, Column } from '../../../../../components/Columns'
import PreLoading from '../../../../../components/PreLoading'
import FetchData from '../../../../../components/FetchData'
import { BtnModalOpen } from '../../../../../components/Modals/BtnModal'
import Breadcrumbs from '../../../../../components/Breadcrumbs'

// Actions Redux
import { getFoldersAndParents } from '../../../../../redux/Folders/FoldersFunctions'

// css
import css from './css/foldersManage.css'


class FoldersManage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      parents: []
    }
  }

  componentDidMount() {
    this.fetchFolders()

    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.folders_id !== this.props.match.params.folders_id) {
      this.fetchFolders()
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) this.props.onRef(null)
  }

  fetchFolders = () => {
    getFoldersAndParents({ _this: this, notSaveStore: false })
  }

  render() {
    const lang = this.props.lang || 'local'
    const { folders, foldersManage, folderId, selectFolder } = this.props
    const { startUrl } = foldersManage
    const { parents } = this.state
    const navigates = []

    parents.map(parent => {
      navigates.push({
        label: parent.name[lang],
        url: `${startUrl}/${parent._id}`
      })
    })

    return (
      <div className="pd-15">

        <div className="bg-fff border-radius-3 pd-left-5 border-eee mg-bottom-10">
          <Breadcrumbs
            items={[
              { label: 'หมวดหมู่', url: `${startUrl}` },
              ...navigates
            ]}
          />
        </div>

        <div style={{ borderBottom: '1px solid #e6e6e6' }}>
          <Row className="pd-bottom-10 pd-top-10">
            <Column col="column col-xxs-12 col-xs-6">
              <div className="pd-right-10 pd-top-3">
                <span>รายการหมวดหมู่</span>
              </div>
            </Column>
            <Column col="column col-xxs-12 col-xs-6 text-right">
              <BtnModalOpen id="folderCreateModal">
                <button className="waves-effect btn-shadow-none waves-light btn btn-xs pd-right-10">
                  <i className="material-icons left">add</i> เพิ่มหมวดหมู่
                </button>
              </BtnModalOpen>
            </Column>
          </Row>
        </div>


        <div className="pd-top-10">
          <PreLoading id="fetch-folders-loading" style={{ position: 'relative' }}>
            <FetchData
              length={folders.length}
              style={{ position: 'relative' }}
              textClassName="font-1-0"
              msg="ไม่มีหมวดหมู่">

              <div id="foldersManage" className={css.folders}>
                <Row>
                  { folders.map(folder => {
                    return (
                      <Column key={folder._id} className="pd-5" col="column col-xxs-12 col-xs-12 col-sm-6 col-md-4 col-lg-6 col-xlg-4">
                        <div
                          onClick={() => selectFolder(folder._id)}
                          className={`${css.list} ${folderId === folder._id ? css.active : ''}`}>

                          <div className={css.icon}>
                            <i className="material-icons i-middle">folder</i>
                          </div>
                          <div className={css.name}>
                            { folder.name[lang] }
                          </div>
                        </div>
                      </Column>
                    )
                  }) }
                </Row>
              </div>

            </FetchData>
          </PreLoading>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const folders_id = props.match.params.folders_id || null

  return {
    folders: state.folders.data.filter(folder => folder.folders_id === folders_id)
  }
}

FoldersManage.propTypes = {
  business: PropTypes.object.isRequired,
  foldersManage: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(FoldersManage)


import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import cuid from 'cuid'

// Component Global
import BarcodeScannerModal from '../../BarcodeScannerModal'

import Required from '../Required'
import InputDisabled from '../InputDisabled'

// Redux Actions
import { openModal } from '../../../redux/App/AppActions'



class InputRemote extends Component {
  constructor(props) {
    super(props)
    const id = this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()

    this.state = {
      id: randomId+id,
      value: this.props.value || '',
      remoteStatus: ''
    }

    this.timer = null
  }

  componentDidMount() {
    this.getDataValue(this.props.value)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.datas !== this.props.datas) {
      this.setState({ datas: this.props.datas })
    }
  }

  onChange = (e) => {
    let value = e.target.value

    /*if (this.props.type !== 'text') {
      value = value.replace(/\s/g, '')
    }*/

    if (value.length === 1) {
      value = value.replace(/\s/g, '')
      //value = value.trim()
    }


    this.getDataValue(value)
  }

  getValueAll = (value) => {
    value = value || this.state.value

    if (this.props.onChange) {
      this.props.onChange({
        value,
        status: this.state.remoteStatus
      })
    }
  }

  getDataValue = (value) => {
    if (value !== undefined && value.length >= 0) {
      this.setState({ value: value }, () => {
        if (this.checkType(value, this.props.type) === true) {
          this.remote(value, true)
        } else {
          this.remote(value, false)
        }
      })
    }
  }

  remote = (value, status) => {
    if (value && status === true) {
      this.setState({ remoteStatus: 'loading' }, () => {
        this.getValueAll()
        this.fetchTime(value)
      })
    } else {
      this.setState({ remoteStatus: 'error' }, () => {
        this.getValueAll()
      })
    }
  }

  fetchTime = (value) => {
    clearTimeout(this.timer)

    this.timer = setTimeout(() =>  {
      this.fetch(value)
    }, 500)
  }

  fetch = (value) => {
    const { remote } = this.props

    let fetch

    if (remote.fnQuery) {
      fetch = () => remote.fnQuery(value)
    } else {
      fetch = () => remote.action({
        query: remote.query + value,
        notStore: true
      })

      if (remote.params_business_id) {
        if (remote.useQuery) {
          fetch = () => remote.action(remote.useQuery, {
            query: remote.query + value,
            notStore: true
          })
        } else {
          fetch = () => remote.action(remote.params_business_id, {
            query: remote.query + value,
            notStore: true
          })
        }
      }
    }

    if (fetch) {
      this.props.dispatch(fetch()).then(data => {
        if (data.success) {
          if (data.data) {
            if (data.data === 'duplicate') {
              this.setState({ remoteStatus: 'res-duplicate', data: data.data }, () => {
                this.getValueAll()
              })

            } else {
              this.setState({
                remoteStatus: remote.dataSuccess === true ? 'res-success' : 'res-duplicate',
                data: data.data
              }, () => {
                this.getValueAll()

                if (remote.getData) {
                  remote.getData(data.data)
                }
              })
            }

          } else if (data.data === null) {
            this.setState({
              remoteStatus: remote.dataSuccess === null ? 'res-success' : 'res-none',
              data: data.data
            }, () => {
              this.getValueAll()
            })

            if (remote.getData) {
              remote.getData(null)
            }

          } else {
            this.setState({ remoteStatus: 'res-none', data: data.data }, () => {
              this.getValueAll()
            })

            if (remote.getData) {
              remote.getData(null)
            }
          }

        } else {

          if (remote.getData) {
            remote.getData(null)
          }

          if (data.data === null) {
            this.setState({
              remoteStatus: remote.dataSuccess === null ? 'res-success' : 'res-none',
              data: data.data
            }, () => {
              this.getValueAll()
            })
          } else {
            this.setState({ remoteStatus: 'error', data: data.data }, () => {
              this.getValueAll()
            })
          }
        }

      }).catch(error => {
        this.setState({ remoteStatus: 'catch' }, () => {
          this.getValueAll()
        })

        if (remote.getData) {
          remote.getData(null)
        }
      })
    }
  }

  checkType = (value, type) => {
    const regex = this.props.regex
    let pattern

    if (type === 'phoneNumber') {
      pattern = new RegExp("^[0-9]{" + 9 + ","+ 13 +"}$")

    } else if (type === 'email') {
      pattern = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

    } else if (type === 'regex' && regex) {
      pattern = regex.pattern

    } else {
      pattern = /^./
    }

    if (pattern.test(value)) return true
    else return false
  }

  getBarcode = (value) => {
    value = value.replace(/\s/g, '')

    this.getDataValue(value)
  }

  render() {
    const { barcodeScanner } = this.props
    const input = (
      <Fragment>
        <Required
          id={this.state.id}
          value={this.state.value}
          label={this.props.label}
          unitName={this.props.unitName}
          type={this.props.type}
          regex={this.props.regex}
          remoteStatus={this.state.remoteStatus}
          remote={this.props.remote}
          required={this.props.required}
          iconValid={this.props.iconValid}
          labelHidden={this.props.labelHidden}
          className="mg-bottom-0"
          statusStyle={this.props.statusStyle}>

          <input
            type="text"
            id={this.state.id}
            data-id={this.props.id}
            ref={this.state.id}
            style={this.props.inputStyle}
            value={this.state.value}
            placeholder={this.props.placeholder}
            onChange={this.onChange}
            className={`input ${this.props.required && 'required'} font-0-9`}
          />
        </Required>

        { this.state.remoteStatus === 'res-success' && this.props.children &&
          <div>
            { this.props.children }
          </div>
        }
      </Fragment>
    )

    if (!this.props.disabled) {
      return (
        <Fragment>
          { barcodeScanner ?
            <div>
              <BarcodeScannerModal
                id={barcodeScanner.id}
                onChange={this.getBarcode}
              />

              <table>
                <tbody>
                  <tr>
                    <td className="pd-0 vertical-top">
                      { input }
                    </td>
                    <td width="40" className="pd-0 pd-left-5 pd-top-20 vertical-top">
                      <a
                        onClick={() => this.props.dispatch(openModal(barcodeScanner.id))}
                        className="btn btn-mini height-40 line-height-40 btn-shadow-none border-radius-3">

                        <i className="material-icons">photo_camera</i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          :
            input
          }
        </Fragment>
      )
    } else {
      return (
        <InputDisabled {...this.props} />
      )
    }
  }
}

export default connect()(InputRemote)

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

// Components Global
import ModalAlert from '../../components/modal/ModalAlert'
import ConfirmAlert from '../../components/Modals/ConfirmAlert'
import Toast from '../../components/toast/Toast'
import ModalUploadImages from '../../components/Modals/ModalUploadImages'
//import ProgressesList from '../../components/ProgressesList'

// Import Actions
import { closeAlert } from '../../redux/App/AppActions'


export class App extends Component {
  closeAlert = () => {
    this.props.dispatch(closeAlert())
  }

  render() {
    return (
      <Fragment>
        { this.props.children }

        <Toast />

        <ModalAlert
          width="450px"
          data={this.props.app.alert}
          open={this.props.app.alert.show}
          close={this.closeAlert}
          buttonText="ตกลง"
        />

        { this.props.progresses.map((progress, i) => {
          return <ModalUploadImages key={i} id={progress.id} upload={progress.upload} />
        } )}


        {/* this.props.progresses.map((progress, i) => {
          return <ProgressesList key={i} id={progress.id} progress={progress} />
        } )*/}


        { this.props.confirms.map((confirm, i) => {
          return <ConfirmAlert key={i} id={confirm.id} confirm={confirm} />
        } )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    host: state.host,
    app: state.app,
    progresses: state.progresses.data,
    confirms: state.app.confirms
  }
}

export default connect(mapStateToProps)(App)

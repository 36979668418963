import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import ModalFixed from '../../../../../components/Modals/ModalFixed'
import PreLoading from '../../../../../components/PreLoading'
import FetchData from '../../../../../components/FetchData'
import Breadcrumbs from '../../../../../components/Breadcrumbs'
import BtnLoading from '../../../../../components/button/BtnLoading'

// Actions Redux
import { updateFolder } from '../../../../../redux/Folders/FoldersActions'
import { updateProduct } from '../../../../../redux/Products/ProductsActions'
import { updateArticle } from '../../../../../redux/Articles/ArticlesActions'

// Fuctions
import { getFoldersAndParents } from '../../../../../redux/Folders/FoldersFunctions'

// css
import css from './css/folderMoveModal.css'


class FolderMoveModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      folders: [],
      parents: []
    }
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  handleSubmit = () => {
    const { folderIdSeleted, parents } = this.state
    const { business, foldersManage, folderId, itemId } = this.props
    const { type } = foldersManage

    let params, updateAction, updateItemId, authName

    if (folderId) {
      updateAction = updateFolder
      updateItemId = folderId
      params = {
        folders_id: folderIdSeleted || null
      }
    }

    if (itemId) {
      if (type === 'product') {
        updateAction = updateProduct
      } else if (type === 'article') {
        authName = "admin"
        updateAction = updateArticle
      }

      const folders = []

      parents.map(parent => {
        folders.push({ folders_id: parent._id })
      })

      updateItemId = itemId
      params = {
        folders_id: folderIdSeleted || null,
        folders
      }
    }

    if (authName) {
      this.props.dispatch(updateAction(authName, business._id, updateItemId, {
        body: params,
        loadId: 'move-update-loading',
        modalId: 'folderMoveModal',
        toastMsg: 'ย้ายแล้ว'
      }))

    } else if (type === 'product') {
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businesses_id: business._id,
        products_id: updateItemId
      }

      this.props.dispatch(updateAction(query, {
        body: params,
        loadId: 'move-update-loading',
        modalId: 'folderMoveModal',
        toastMsg: 'ย้ายแล้ว'
      }))

    } else {
      this.props.dispatch(updateAction(business._id, updateItemId, {
        body: params,
        loadId: 'move-update-loading',
        modalId: 'folderMoveModal',
        toastMsg: 'ย้ายแล้ว'
      }))
    }
  }

  fnStartModal = () => {
    const { match } = this.props
    const { folders_id } = match.params

    this.selectFolder(folders_id)
  }

  fnCloseModal = () => {
    this.mounted && this.setState({
      folderIdSeleted: undefined,
      folders: [],
      parents: []
    })
  }

  fetchFolders = (parent_folders_id) => {
    getFoldersAndParents({ _this: this, notSaveStore: true, parent_folders_id })
  }

  selectFolder = (folderIdSeleted) => {
    setTimeout(() => {
      this.setState({ folderIdSeleted }, () => {
        this.fetchFolders(folderIdSeleted)
      })
    }, 0)
  }


  render() {
    const id = "folderMoveModal"
    const lang = this.props.lang || 'local'
    const { match, business, foldersManage, selectedData, folderId } = this.props
    let { folders, parents, folderIdSeleted } = this.state
    const navigates = []

    folders = folders.filter(folder => folder._id !== folderId)

    parents.map(parent => {
      navigates.push({
        label: parent.name[lang],
        onClick: () => this.selectFolder(parent._id)
      })
    })

    return (
      <ModalFixed
        id={id}
        blockBgModal
        fnStartModal={this.fnStartModal}
        fnCloseModal={this.fnCloseModal}
        title="ย้าย 1 รายการไปยัง ..."
        width="500px"
        headerClassName="height-50"
        bodyClassName="top-50 bg-f5f5f5 pd-0"
        padding="10px">

        <div className="pd-10">

          { navigates.length > 0 &&
            <div className="bg-fff border-radius-3 pd-left-5 border-eee mg-bottom-10">
              <Breadcrumbs
                click
                items={[
                  { label: 'หน้าหลัก', onClick: () => this.selectFolder() },
                  ...navigates
                ]}
              />
            </div>
          }

          <PreLoading id="fetch-folders-modal-loading"  style={{ position: 'relative' }}>
            <FetchData
              length={folders.length}
              style={{ position: 'relative', paddingTop: '100px' }}
              icon="folder"
              msg="">

              <div className={css.folders}>
                { folders.map(folder => {
                  return (
                    <div key={folder._id} className="pd-5">
                      <div
                        onClick={() => this.selectFolder(folder._id)}
                        className={`${css.list} ${folderIdSeleted === folder._id ? css.active : ''}`}>

                        <div className={css.icon}>
                          <i className="material-icons i-middle">folder</i>
                        </div>
                        <div className={css.name}>
                          { folder.name[lang] }
                        </div>
                      </div>
                    </div>
                  )
                }) }
              </div>

            </FetchData>
          </PreLoading>
        </div>

        <div className="text-right">
          <BtnLoading
            onClick={this.handleSubmit}
            loadingId="move-update-loading"
            icon="check"
            text="ย้ายมาที่นี้"
            className="waves-effect waves-light btn btn-shadow-none btn-submit pd-right-20"
          />
        </div>
      </ModalFixed>
    )
  }
}

export default connect()(FolderMoveModal)

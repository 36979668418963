import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../components/Modals/Modal'
import FetchData from '../../../../../components/FetchData'
import PreLoading from '../../../../../components/PreLoading'
import BtnLoading from '../../../../../components/button/BtnLoading'

// Components OrdersList
import OrderDraftsList from '../OrderDraftsList'

// Actions Redux
import { fetchOrders } from '../../../../../redux/Orders/OrdersActions'
import { createOrderDraft } from '../../../../../redux/Orders/OrdersFunctions'



class OrderDraftsListModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  fnStartModal = () => {
    const { business } = this.props
    const query = {
      authName: 'admin',
      businesses_id: business._id,
      dbNo: business.dbNo
    }

    this.props.dispatch(fetchOrders(query, {
      body: {
        where: 'status=draft',
        sort: '-_id'
      },
      loadId: 'draftOrdersListModal'
    }))
  }

  render() {
    let orders = this.props.orders

    return (
      <Modal
        id="draftOrdersListModal"
        fnStartModal={this.fnStartModal}
        headerTitle="รายการพักบิล"
        headerLeft
        width="1000px"
        padding="0px">

        <PreLoading id="draftOrdersListModal" style={{ position: 'relative' }}>
          <FetchData
            length={orders.length}
            className="position-relative"
            style={{ position: 'relative' }}
            icon="shopping_basket"
            msg="ไม่มีรายการ">

            { orders &&
              <div className="dp-inline-block width-full pd-15">
                <OrderDraftsList {...this.props} orders={orders} />

                <div className="mg-top-15 pd-top-15 center border-top-efefef mg-left--15 mg-right--15">
                  <BtnLoading
                    onClick={() => createOrderDraft(this.props)}
                    loadingId="createOrderDraft"
                    icon="add_circle_outline"
                    iconAlign="left font-1-7"
                    text="พักบิลและสร้างบิลใหม่"
                    className="btn btn-shadow-none"
                  />
                </div>
              </div>
            }
          </FetchData>
        </PreLoading>
      </Modal>
    )
  }
}

const mapStateToProps = (store, props) => {
  return {
    orders: store.orders.data
  }
}

export default connect(mapStateToProps)(OrderDraftsListModal)

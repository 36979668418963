import React from 'react'

const POSManage = (props) => {
  return (
    <div className="font-0-8 height-full" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
      <div className="pd-left-15 pd-right-15 color-error border-left-ddd" style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="pd-right-5" style={{ display: 'flex', alignItems: 'center' }}>
          <i className="material-icons-outlined font-1-3 left">wifi_off</i> ออฟไลน์
        </div>
      </div>

      <div className="pd-left-15 pd-right-15 border-left-ddd" style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="pd-right-5" style={{ display: 'flex', alignItems: 'center' }}>
          <i className="material-icons-outlined i-middle font-1-3 left">desktop_mac</i> เครื่อง: 01
        </div>
      </div>

      <div className="pd-left-15 pd-right-15 border-left-ddd border-right-ddd" style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="pd-right-5" style={{ display: 'flex', alignItems: 'center' }}>
          <i className="material-icons-outlined i-middle font-1-3 left">assignment_ind</i> ผู้ขาย: สมชาย
        </div>
      </div>
    </div>
  )
}

export default POSManage

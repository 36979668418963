import React, { Fragment, useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import config from '../../../../../config'
import renderRoutes from '../../../../../routes/renderRoutes'
import "regenerator-runtime/runtime"


// Components Global
import { Row, Column } from 'components/Columns'
import ProfileAvatar from 'components/ProfileAvatar'

// Components Atrributes
import { AttributeProfileAvater } from '../../../Attributes/components/AttributeUserInterface'

// utity
import { dateDiff, getDate } from '../../../../../util/getDateTime/getDateTime'

// css
import css from './css/businessDashboard.css'

// Value
import { expirationDate, businessValue, systemsList } from 'client/redux/Businesses/BusinessesValues'
import { getRoleSystemsAll } from 'core/functions/getAdminRole'


const CDN_URL = config.app.cdnUrl
const s3Url = config.app.s3.url

const BusinessDashboardContainer = (props) => {
  const [open, setOpen] = useState(true)
  const { business, setting, attributes, match, route, location, auth } = props
  const businessLocal = businessValue({ business })
  const pack = business.package

  const closeWindowModal = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (location.pathname === `/${business.username}`) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }, [location.pathname])




  const durations = (
    <div className="font-0-9">
      { pack && pack.name !== null ?
        <div>
          { business.username !== 'nokthai' ?
            <div>ชื่อแพ็กเกจ : มาตรฐาน</div>
          :
            <div>@{business.username}</div>
          }

          <Fragment>
            <div>
              วันหมดอายุ :
              { expirationDate(pack) ?
                <span className="color-error">หมดอายุแล้ว</span>
              :
                <span> { getDate(pack.expirationDate, 'D MMMM YYYY', 'th') } ({ dateDiff(new Date(), pack.expirationDate, 'days') } วัน)</span>
              }
            </div>
          </Fragment>


        </div>
      :
        <div>
          ชื่อแพ็กเกจ : <span className="color-error"> ไม่มี</span> <br />
          วันหมดแพ็กเกจ : <span className="color-error"> หมดอายุแล้ว</span>
        </div>
      }
    </div>
  )

  let image = `${CDN_URL}/common/imgs/cover.png`
  const cover = business && business.images.filter(image => image.album === 'cover')[0]

  if (cover && cover.paths && cover.paths.o) {
    image = `${s3Url}/${cover.paths.o}`
  }

  let systems = []

  if (business.type == 'rubber') {
    systems = systemsList({ business, attributes })
    systems = systems.filter(system => system.status === true)
  } else {
    systems = getRoleSystemsAll({ business, attributes, setting, auth })
  }

  return (
    <Fragment>
      <Helmet title={businessLocal.nameLang} />

      <div className={`z-depth-1 ${css.cover} min-height-230`}>
        <div
          className={css.bg}
          style={{
            background: `url('${image}') no-repeat center center`,
            backgroundSize: '100% 100%'
          }}>

          <div className={css.content}>
            <table>
              <tbody>
                <tr>
                  <td className="width-160 center height-230">
                    <div className={`dp-inline-block width-100 height-100 border-3 border-fff border-radius-circle`}>
                      <ProfileAvatar
                        circle
                        images={business.images}
                        imageSize="m"
                        color={business.avatarColor}
                        texts={[businessLocal.nameLang]}
                        textSize="2rem"
                      />
                    </div>

                    <div className="center hidden-md hidden-lg hidden-xlg">
                      <div className="font-1-5 color-fff">{businessLocal.nameLang}</div>
                      { durations }
                    </div>
                  </td>
                  <td className="hidden-xs hidden-sm">
                    <div>
                      <div className="font-1-5 color-fff">{businessLocal.nameLang}</div>
                      { durations }
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>


      <div className={`${css.boxSystem} mg-top-50`}>
        <div className="mg-bottom-20 color-fff center">
          <span className="font-1-5">ระบบจัดการธุรกิจ</span>
          <p className="font-0-9">เริ่มต้นใช้งานโดยการเลือกระบบที่ต้องการจากด้านล่าง</p>
        </div>

        <Row>
          { systems.map((system, key) => {
              return (
                <Column key={key} col="column col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xlg-2">
                  <div className={`z-depth-1 mg-5 pd-10 ${css.itemSystem}`}>
                    <Link to={`/${match.params.username + system.link}`}>
                      <div className={`${css.boxIcon} width-120 height-120 border-2 border-fff pd-inline-block circle`}>
                        { system.attribute ?
                          <div className="width-60 mg-center">
                            <AttributeProfileAvater attribute={system.attribute} />
                          </div>
                        :
                          <img src={require(`../../resources/icons/${system.id}.png`)} className="width-60" />
                        }
                      </div>
                      <div className="mg-top-10 color-fff font-1-0">{system.name}</div>
                    </Link>
                  </div>
                </Column>
              )
            })
          }
        </Row>

        <div className="modal-jsx" style={{ visibility: open && 'visible', opacity: open && '1', top: open && '0' }}>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, setting, windowModal: open, closeWindowModal },
            location
          }) }
        </div>
      </div>

      <style jsx>{`
        .modal-jsx {
          position: fixed;
          top: 100%;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 9999;
          transition: all 0.8s;
          opacity: 1;
          visibility: hidden;
        }
      `}</style>
    </Fragment>
  )
}

function mapStateToProps(store, props) {
  return {
    app: store.app,
    auth: store.auth,
    attributes: store.attributes.data
  }
}

export default connect(mapStateToProps)(BusinessDashboardContainer)

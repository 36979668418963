import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../../routes/renderRoutes'

// Components Global
import LayoutModalManage from '../../../../../components/LayoutModalManage'

// Component Local
import OrdersListItems from './components/OrdersListItems'

// Values
import { orderValueECommerce } from '../../../../../redux/Orders/OrdersValues'

// Actions Redux
import { fetchOrder } from '../../../../../redux/Orders/OrdersActions'



const OrdersListContainer = (props) => {
  const layoutModal = useRef()
  const [orderFetch, setOrderFetch] = useState()
  const { match } = props
  const { orders_id, status } = match.params
  const { business, location, route, order, dispatch } = props

  const openModal = () => layoutModal.current.open()
  const closeModal = () => layoutModal.current.close()


  useEffect(() => {
    if (!order) {
      //layoutModal.current.close()
    } else {
      layoutModal.current.open()
    }
  }, [
    order && order._id,
    orderFetch && orderFetch._id
  ])

  useEffect(() => {
    if (!order && orders_id) {
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businesses_id: business._id,
        orders_id
      }

      if (orders_id !== 'all') {
        dispatch(fetchOrder(query, { notShowErrorAlert: true })).then(res => {
          if (res.success) {
            setOrderFetch(res.data)
          }
        })
      }
    }
  }, [])

  return (
    <LayoutModalManage
      {...props}
      urlMain={`/${business.username}/sell-online/${status}/all`}
      modalClassName="border-radius-5 overflow-hidden"
      ref={layoutModal}
      dataId={orders_id}>

      <div>
        <OrdersListItems {...props} openModal={openModal} />
      </div>

      <div>
        { renderRoutes({
          routes: route.routes,
          extraProps: {
            ...props,
            order: order && orderValueECommerce({ order }),
            closeModal
          },
          location
        }) }
      </div>
    </LayoutModalManage>
  )
}

const mapStateToProps = (state, props) => {
  const { match } = props
  const { orders_id } = match.params
  const { orders } = state

  let order = orders.data.filter(order => order._id === orders_id)[0]

  if (!order && orders.search && orders.search.status === true) {
    order = orders.search.data.filter(order => order._id === orders_id)[0]
  }

  return {
    order
  }
}

export default connect(mapStateToProps)(OrdersListContainer)

import React, { Component } from 'react'

// css
import css from './css/checkBox.css'


class CheckBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, ''),
      checked: this.props.value || false
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ checked: nextProps.value })
    }
  }

  handleChange = (checked, id) => {
    if (checked) {
      this.setState({ checked: false })

      if (this.props.onChange) this.props.onChange(false, id)
    } else {
      this.setState({ checked: true })

      if (this.props.onChange) this.props.onChange(true, id)
    }
  }

  render() {
    let size


    return (
      <div className={`${css.box} ${this.props.className}`} style={this.props.style}>
        <input
          type="checkbox"
          className="filled-in"
          id={this.state.id}
          data-id={this.props.id}
          name={this.state.id}
          checked={ this.state.checked }
          value={ this.state.checked }
          onChange={ () => this.handleChange(this.state.checked, this.props.id) }
        />

        <label htmlFor={this.state.id} className={`${this.props.size}`}>
          { this.props.img && <img src={this.props.img} width="40" /> }
          { this.props.label && <div>{this.props.label}</div> }
        </label>
      </div>
    );
  }
}



export default CheckBox;

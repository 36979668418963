import React from 'react'
import renderRoutes from '../../../../routes/renderRoutes'


const ArticleUpdateContainer = (props) => {
  const { route, article, attribute, business, location, closeWindowModal, lang } = props

  return (
    <div className="pst-absolute top-0 bottom-0 left-0 right-0">
      { renderRoutes({
        routes: route.routes,
        extraProps: { business, article, attribute, closeWindowModal, lang },
        location
      }) }
    </div>
  )
}

export default ArticleUpdateContainer

import React, { Fragment } from 'react'

// Components Global
import { Row, Column } from '../../../../../../../components/Columns'
import { BtnModalOpen } from '../../../../../../../components/Modals/BtnModal'
import BtnManager from '../../../../../../../components/BtnManager'

// Components Pages
import TemplateCreateModal from '../../../TemplateCreateModal'
import TemplateUpdateModal from '../../../TemplateUpdateModal'

// Actions Redux
import { closeModal } from '../../../../../../../redux/App/AppActions'

// Fuctions
import { onDeleteTemplate } from '../../../../../../../redux/Sites/SitesFunctions'

//css
import css from './css/templateLists.css'


const TemplateLists = (props) => {
  const lang = props.lang || 'local'
  const { history, business, site, urlMainManage } = props
  const templates = site.templates || []

  const goto = (templates_id) => {
    props.dispatch(closeModal(`templatesManageModal`))

    history.push(`${urlMainManage}/template-manage/${templates_id}`)
  }

  return (
    <div>
      <TemplateCreateModal {...props} />

      <div style={{ borderBottom: '1px solid #e6e6e6' }}>
        <Row className="pd-bottom-10 pd-top-10">
          <Column col="column col-xxs-12 col-xs-6">
            <div className="pd-right-10 pd-top-3">
              <span>เทมเพลต</span>
            </div>
          </Column>
          <Column col="column col-xxs-12 col-xs-6 text-right">
            <BtnModalOpen id={`templateCreateModal`}>
              <button className="waves-effect btn-shadow-none waves-light btn btn-xs pd-right-10">
                <i className="material-icons left">add</i> เพิ่มเทมเพลต
              </button>
            </BtnModalOpen>
          </Column>
        </Row>
      </div>

      <div className={css.boxLists}>
        { templates.length !== 0 ?
          <div>
            { templates.map(template => {
              return (
                <div key={template._id} className={css.list}>
                  <div className={css.name}>
                    {template.name[lang]}
                  </div>

                  <div className={css.type}>
                    { template.type  &&
                      <span className="font-0-9 badge-border success dp-inline">
                        {template.type}
                      </span>
                    }
                  </div>

                  <div className={css.manage}>
                    <a onClick={() => goto(template._id)} className="btn btn-shadow-none btn-xxs mg-right-5">ออกแบบ</a>

                    {/*<a onClick={() => goto(template._id)} className="btn btn-success btn-shadow-none btn-xxs mg-right-5">
                      <i className="material-icons">content_copy</i>
                  </a>*/}

                    <BtnModalOpen id={`${template._id}-templateUpdateModal`}>
                      <a className="btn btn-shadow-none btn-xxs btn-edit mg-right-5">
                        <i className="material-icons">edit</i>
                      </a>
                    </BtnModalOpen>

                    <BtnManager
                      iconOnly
                      id={template._id}
                      btnSize="btn-xxs"
                      className="dp-inline-block"
                      manager={[
                        {
                          type: 'delete',
                          show: true,
                          label: 'ลบ',
                          title: `ลบ "เทมเพลตนี้" อย่างถาวร`,
                          msg: `กดปุ่ม "ลบเทมเพลตนี้" ถ้าคุณต้องการลบหน้า "ลบเทมเพลตนี้" อย่างถาวร`,
                          labelConfirm: 'ลบเทมเพลตนี้',
                          handle: onDeleteTemplate(props),
                        }
                      ]}
                    />
                  </div>

                  <TemplateUpdateModal {...props} template={template} />
                </div>
              )
            }) }
          </div>
        :
          <div className="pd-top-50 text-center">ไม่มีเทมเพลต</div>
        }
      </div>
    </div>
  )
}

export default TemplateLists

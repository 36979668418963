import React from 'react'

// Components Customers
import { CustomerItemProfileAvater } from '../../../RubberCustomerBuyers/components/CustomersUserInterface'

// Values
import { customerMemberValue } from '../../../../../../redux/RubberCustomers/RubberCustomersValue'

// Utils
import { getNumeral } from '../../../../../../util/getNumeral'


const PurchaseIncomes = (props) => {
  let { customer, incomes, partner } = props

  if (partner) {
    incomes = incomes.filter(income => income.type === 'partner')
  } else {
    incomes = incomes.filter(income => income.type !== 'partner')
  }

  return (
    <div>
      <table>
        <tbody>
          { incomes.map((income, i) => {
            let typeName
            const member = customerMemberValue({ customer, member: income.data })

            if (income.type == 'owner') {
              typeName = 'เจ้าของสวน'

              if (customer.carTank) {
                typeName = 'เจ้าของรถ'
              }
            } else if (income.type == 'partner') {
              typeName = 'หุ้นส่วน'
            } else if (income.type == 'employee') {
              typeName = 'คนกรีดยาง'
            }

            if (member) {
              return (
                <tr key={i} className={i > 0 ? 'border-top-0-5 border-top-eee' : ''}>
                  <td className="pd-0 width-55">
                    <div className="width-55 height-55">
                      <CustomerItemProfileAvater circle item={member} />
                    </div>
                  </td>
                  <td className="pd-10">
                    <div className="font-0-8">
                      { typeName }
                    </div>
                    <div>ชื่อ: { member.nameLang || '-' }</div>
                    <div className="font-0-8 color-success">ส่วนแบ่ง {income.percent}%</div>
                  </td>

                  { (income.reserveAmount > 0) &&
                    <td className="
                      pd-0 width-100 text-center
                      border-left-0-5 border-left-eee">

                      <div className="font-0-8">
                        <div>สำรองจ่าย</div>
                        <div className="color-success">฿{getNumeral(income.reserveAmount,'auto') }</div>
                      </div>
                    </td>
                  }

                  <td className="pd-0 width-100 text-center font-0-8 border-left-0-5 border-left-eee">
                    <div>ยอดชำระ</div>
                    <div className="color-price">฿{getNumeral(income.amount,'auto') }</div>
                  </td>
                </tr>
              )
            }
          }) }
        </tbody>
      </table>
    </div>
  )
}

export default PurchaseIncomes


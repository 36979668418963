import React, { Component } from 'react'
import cuid from 'cuid'

import Required from '../Required'
import InputDisabled from '../InputDisabled'

import { setStyleValue, getStyle } from '../../TextEditor/components/ElementStyles/components/js/setDivStyle'



class Select extends Component {
  constructor(props) {
    super(props)
    const id = this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()

    this.state = {
      id: randomId+id,
      value: this.props.value !== undefined ? this.props.value : ''
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value })
    }
  }

  handleChange = () => {
    let value = document.getElementById(`${this.state.id}`).value

    if (this.props.onChange) {
      this.props.onChange(value)
    }

    this.setState({ value: value })
  }

  render() {
    const { placeholder, placeholderDisabled, disabled, options, required } = this.props
    const { styleName, styleJsx, styleGlobal, inputForSite } = this.props
    const name = styleName

    const setEvent = (event, important=false) => {
      return (
        `
          ${getStyle('box-shadow', styleJsx[`${name}-${event}-box-shadow`], important)}
          ${getStyle('border-style', styleJsx[`${name}-${event}-border-style`], important)}
          ${getStyle('border-width', styleJsx[`${name}-${event}-border-width`], important)}
          ${getStyle('border-color', styleJsx[`${name}-${event}-border-color`], important)}
        `
      )
    }

    return (
      <div className={`${styleGlobal ? 'box-input-global-jsx' : ''}`}>
        {!this.props.disabled ?
          <Required
            id={this.state.id}
            classId={this.state.id}
            value={this.state.value}
            label={this.props.label}
            labelHidden={this.props.labelHidden}
            className={`${this.props.requiredClassName}`}
            labelClassName={this.props.labelClassName}
            notColorValid={this.props.notColorValid}
            unitName={this.props.unitName}
            type="text"
            required={required}>

            <div className={`mg-top-1 ${this.props.className}`}>
              <select
                id={`${this.state.id}`}
                data-id={this.props.id}
                data-label={this.props.useLabel && this.props.label}
                data-use-attr={this.props.useAttr}
                disabled={disabled}
                onChange={this.handleChange}
                value={this.state.value || ''}
                className={`browser-default select-default ${inputForSite ? 'input-site' : 'select'} ${styleGlobal ? ' input-global-jsx' : ''} input-jsx ${required ? 'required' : ''} ${this.props.inputClassName || ''}`}>

                { placeholder &&
                  <option
                    value=""
                    style={{ color: '#2f2f2f' }}
                    disabled={placeholderDisabled}>
                    { this.props.placeholder }
                  </option>
                }

                { options.map((option, key) => {
                  return (
                    <option key={key} value={option.value}>{option.option || option.name}</option>
                  )
                }) }

              </select>
            </div>
          </Required>
        :
          <InputDisabled {...this.props} />
        }

        <style jsx>{`
          .input-jsx {
            ${styleJsx && setStyleValue({ name, style: styleJsx })}

            outline: none;
            -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
                    box-sizing: border-box;
          }
          .input-jsx:focus:not([readonly]) {
            ${styleJsx && setEvent('focus')}
          }
          .input-jsx.valid,
          .input-jsx:focus.valid {
            ${styleJsx && setEvent('valid', true)}
          }
          .input-jsx.invalid,
          .input-jsx:focus.invalid {
            ${styleJsx && setEvent('invalid', true)}
          }

          @media (min-width: 1500px) {
            .input-jsx {
              ${styleJsx && setStyleValue({ name, style: styleJsx, vw: true })}
            }
            .input-jsx:focus:not([readonly]) {
              ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-focus-box-shadow-vw`])}
              ${styleJsx && getStyle('border-width', styleJsx[`${name}-focus-border-width-vw`])}
            }
            .input-jsx.valid {
              ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-valid-box-shadow-vw`], true)}
              ${styleJsx && getStyle('border-width', styleJsx[`${name}-valid-border-width-vw`], true)}
            }
            .input-jsx.invalid {
              ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-invalid-box-shadow-vw`], true)}
              ${styleJsx && getStyle('border-width', styleJsx[`${name}-invalid-border-width-vw`], true)}
            }
          }

          @media (min-width: 601px) and (max-width: 1024px) {
            .input-jsx {
              ${styleJsx && setStyleValue({ device: 'tablet', name, style: styleJsx })}
            }
          }

          @media (min-width: 50px) and (max-width: 600px) {
            .input-jsx {
              ${styleJsx && setStyleValue({ device: 'mobile', name, style: styleJsx })}
            }
          }
        `}</style>
      </div>
    )
  }
}


export default Select

import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Components Global
import Modal from '../../../../../components/Modals/Modal'

// Components Local
import ShippingManager from '../OrderManager/components/ShippingManager'


class OrderShippingManageModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { order } = this.props

    return (
      <Modal
        id="orderShippingManageModal"
        headerTitle="การจัดส่ง"
        headerLeft
        width="700px"
        padding="0px">

        <div className="pd-20">
          <div>
            <ShippingManager {...this.props} hiddenTitle />
          </div>

          <div className="font-0-9 color-error pd-top-20">
            { !order.customers_id && <div>*** กรุณาเพิ่มรายชื่อลูกค้า</div> }
            { !order.shippingAddress && <div>*** กรุณาเพิ่มที่อยู่จัดส่ง</div> }
            { (order.shippingFee && !order.shippingFee._id) && <div>*** กรุณาเพิ่มช่องทางจัดส่ง</div> }
          </div>
        </div>
      </Modal>
    )
  }
}

OrderShippingManageModal.propTypes = {
  order: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired
}

export default connect()(OrderShippingManageModal)

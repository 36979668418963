import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

// Components Global
import { Row, Column } from '../../../../../../../components/Columns'
import PreLoading from '../../../../../../../components/PreLoading'
import FetchData from '../../../../../../../components/FetchData'
import Ellipsis from '../../../../../../../components/Ellipsis'
import Pagination from '../../../../../../../components/Pagination'
import InputSearch from '../../../../../../../components/InputSearch'
import { BtnModalOpen } from '../../../../../../../components/Modals/BtnModal'

// Components Products
import { ProductProfileAvater } from '../../../ProductsUserInterface'

// Components Sales
import OrderItemCreateModal from '../../../../../Sales/components/OrderItemCreateModal'

// Actions Redux
import { openModal, closeModal } from '../../../../../../../redux/App/AppActions'
import { fetchProducts } from '../../../../../../../redux/Products/ProductsActions'
import { updateOrder } from '../../../../../../../redux/Orders/OrdersActions'

// Values
import { productValue } from '../../../../../../../redux/Products/ProductsValues'

// Utils
import { getNumeral } from '../../../../../../../util/getNumeral'


class ProductsList extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  selectProduct = (products_id, orderItem) => {
    this.setState({ products_id, orderItem }, () => {
      this.props.dispatch(openModal('productModal', {
        products_id
      }))
    })
  }

  addToItems = (params, formName) => {
    if (params) {
      const { business, order } = this.props
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businesses_id: business._id,
        orders_id: order._id
      }


      this.props.dispatch(updateOrder(query, {
        body: params,
        loadId: formName,
        modalId: `productsListModal-${params.products_id}`,
        toastMsg: 'เพิ่มสินค้าในตะกร้าแล้ว'
      })).then(res => {
        if (res.success) {
          this.props.dispatch(closeModal(`productsListModal`))
        }
      })
    }
  }

  getItemsList = () => {
    let items

    if (this.props.itemsSearch && this.props.itemsSearch.status) {
      items = this.props.itemsSearch.data
    } else {
      items = this.props.items
    }

    return items
  }

  render() {
    const { title, type, subtype, modal, business, order } = this.props
    let customer = order && order.customer

    let items = this.getItemsList()
    let limit = 20
    let columnTitle, columnTitle2, columnProduct
    let fetchAction = {
      action: fetchProducts,
      actionQuery: {
        authName: 'admin',
        dbNo: business.dbNo,
        businesses_id: business._id
      },
      id: business._id,
      where: `status=open,type=${type},subtype=${subtype}`,
      countWhere: `status=open,type=${type},subtype=${subtype}`,
      limit: limit,
      sort: '_id',
      searchAttributes: [
        { name: 'name.local', options: 'i' },
        { name: 'code', options: 'i' },
        { name: 'items.name.local', options: 'i' },
        { name: 'items.barcode', options: 'i' },
      ]
    }

    if (this.props.modal) {
      columnTitle = 'column col-xxs-12'
      columnTitle2 = 'column col-xxs-12'
      columnProduct = 'column col-xxs-12 col-xs-12 col-sm-6 col-md-3 col-lg-2 col-xlg-2'
    } else {
      columnTitle = 'column col-xxs-12'
      columnTitle2 = 'column col-xxs-12'
      columnProduct = 'column col-xxs-12 col-xs-6 col-sm-4 col-md-4 col-lg-4 col-xlg-2-4'
    }

    let titleName = title

    return (
      <div className="pd-20">
        { this.props.items.length !== 0 &&
          <Row className="pd-bottom-10">
            <Column col={columnTitle}>
              <div className="pd-right-10 pd-top-3">
                <span className="font-1-2">{titleName}</span>
              </div>
            </Column>
            <Column col={columnTitle2}>
              <div className="pd-right-10 pd-top-10">
                <InputSearch {...this.props}
                  id="search-products-list"
                  placeholder={`ค้นหา${titleName}`}
                  getValueFromUrl
                  fetchAction={fetchAction}
                />
              </div>
            </Column>
          </Row>
        }

        <Pagination {...this.props}
          id="listProducts"
          notReloadForUrlChange
          fetchAction={fetchAction}
          itemsData={this.props.items}
          searchData={this.props.itemsSearch}
          itemsPerPage={limit}
          showPage={5}>

          <PreLoading id="listProducts">
            <FetchData
              length={items.length}
              className="pd-100"
              style={{ position: this.props.items.length !== 0 && 'relative' }}
              icon="archive"
              msg="ไม่มีรายการ">

              <Row>
                <ReactCSSTransitionGroup
                  transitionName="example"
                  transitionEnterTimeout={1000}
                  transitionLeaveTimeout={1000}>

                  { items.map((product, i) => {
                    product = productValue({ product, customer })

                    let price, itemSingle

                    if (product.itemChoice === 'single') {
                      price = product.item.priceCondition
                    } else {
                      itemSingle = product.items.filter(item => item.itemChoice === 'single')[0]
                      price = product.item.priceCondition
                    }

                    return (
                      <Column key={product._id} col={columnProduct}>
                        <div className="border-radius-3 border-efefef mg-bottom-10 mg-right-10 bg-fff">
                          <div className="pd-8">
                            <div className="width-full pd-top-full position-relative">
                              <div className="position-absolute top-0 left-0 right-0 bottom-0">
                                <ProductProfileAvater product={product} item={itemSingle} fontSize="2rem" />
                              </div>
                            </div>

                            <div className="height-40 mg-top-8">
                              <div className="height-40 line-height-20 color-000 font-0-9 text-ellipsis-2">
                                { product.nameLang }
                              </div>
                            </div>

                            <div className="height-30 line-height-30 mg-top-8 text-right">
                              <div className="font-1-1 color-price">
                                ฿{ getNumeral(price, 'auto') }
                              </div>
                            </div>

                            <div className="pd-top-8">
                              <button onClick={() => this.props.dispatch(openModal(`productsListModal-${product._id}`))} className="btn height-35 font-0-9 pd-left-10 pd-right-10 line-height-35 btn-shadow-none width-full">
                                <i className="material-icons font-1-5 left">add</i> เลือก
                              </button>
                            </div>

                            <OrderItemCreateModal
                              {...this.props}
                              id={`productsListModal-${product._id}`}
                              addToItems={this.addToItems}
                              product={product}
                              productId={product._id}
                            />
                          </div>
                        </div>
                      </Column>
                    )
                  }) }
                </ReactCSSTransitionGroup>
              </Row>
            </FetchData>
          </PreLoading>
        </Pagination>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    items: state.products.data,
    itemsSearch: state.products.search
  }
}

export default connect(mapStateToProps)(ProductsList)

import React, { Component } from 'react'
import { Switch, Route, RouteProps, RouteComponentProps,  } from 'react-router-dom'

// Auth
import Auth from 'client/core/modules/Auth'


export interface CustomRouteProps extends RouteProps {
  path: string;
  key: any,
  auth: any,
  params: any,
  component: any
}

const mapRouter = (routes:CustomRouteProps[], extraProps:Record<any, any>) => {
  return routes.map((route:CustomRouteProps, i) => (
    <Route
      key={route.key || i}
      path={route.path}
      exact={route.exact}
      strict={route.strict}
      render={props => {
        if (route.auth) {
          return (
            <Auth
              reqAuth={route.auth.reqAuth}
              reqAuthBiz={route.auth.reqAuthBiz}
              reqAuthActive={route.auth.active !== undefined ? route.auth.active : true }
              reqExpDate={route.auth.reqExpDate}
              unAuth={route.auth.unAuth}>

              <route.component
                {...props}
                {...extraProps}
                params={route.params}
                route={route}
              />
            </Auth>
          )
        } else {
          return <route.component
            {...props}
            {...extraProps}
            params={route.params}
            route={route}
          />
        }
      }
    } />
  ))
}

export type RenderProps = {
  routes?: any,
  extraProps: Record<any, any>,
  switchProps?: Record<any, any>,
  location: Record<any, any>,
  modal?: Record<any, any>,
}

const defaultProps: RenderProps = {
  routes: undefined,
  extraProps: {},
  switchProps: {},
  location: {},
  modal: {},
}

const renderRoutes = (params=defaultProps) => {
  const { routes, extraProps, switchProps, location, modal } = params

  let newLocation

  if (modal && modal.isModal && modal.previousLocation) {
    newLocation = modal.previousLocation
  } else {
    newLocation = location
  }

  if (routes) {
    return (
      <Switch {...switchProps} location={newLocation}>
        { mapRouter(routes, extraProps) }
      </Switch>
    )
  } else {
    return null
  }
}

export default renderRoutes

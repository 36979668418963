import React, { Fragment, useEffect, useState } from 'react'
import cuid from 'cuid'

// Components Global
import { Row, Column } from '../../../../../../components/Columns'

// Component rubbber customer
import { MemberMobile, MemberName, PercentOwner, PercentPartner, PlantationName, PercentEmployee, WithdrawMoney } from '../MemberForms'
import SectionCircle from '../../../../../../components/SectionCircle/SectionCircle'


const MembersCreateAndUpdate = (props) => {
  const lang = 'local'
  const [members, setMembers] = useState([])

  useEffect(() => {
    let members = props.members || []
    members = members.filter(member => member.type === props.type)
    const array = []

    members.map(member => {
      array.push({ key: cuid(), event: 'read', value: member })
    })

    if (props.type === 'partner') {
      if (array.length === 0) {
        array.push({ key: cuid(), event: 'create', value: { type: props.type, owner: true } })
        array.push({ key: cuid(), event: 'create', value: { type: props.type } })
      }

      if (array.length === 1) {
        array.push({ key: cuid(), event: 'create', value: { type: props.type } })
      }
    }

    if (props.type === 'employee') {
      if (array.length === 0) {
        array.push({ key: cuid(), event: 'create', value: { type: props.type } })
      }
    }

    setMembers(array)
  }, [])


  useEffect(() => {
    props.onChange(members)
  }, [members])


  const addMember = () => {
    const array = [...members]
    array.push({ key: cuid(), event: 'create', value: { type: props.type } })
    setMembers(array)
  }


  const removeMember = (i) => {
    const array = [...members]
    const member = array[i]

    if (member.event === 'create') {
      array.splice(i, 1)
    } else if (member.event === 'read' || member.event === 'update') {
      array[i].event = 'delete'
    }

    setMembers(array)
  }


  const updateMember = (v, i, element, lang) => {
    if (v.target) {
      v = v.target.value
    }

    const array = [...members]

    if (array[i].event === 'read') {
      array[i].event = 'update'
    }

    if (lang) {
      array[i].value[element] = { [lang]: v }
    } else {
      array[i].value[element] = v

      if (element === 'percentOwner') {
        array[i].value['percentEmployee'] = 100 - parseFloat(v)
      }
    }

    setMembers(array)
  }

  let percentTotal = 0

  return (
    <div>
      { props.type === 'partner' &&
        <SectionCircle title="รายชื่อหุ้นส่วน" className="mg-bottom-20" bodyClassName="bg-efefef">
          <div className="pd-20">
            { members.map((member, i) => {
              const value = member.value || {}

              if (member.event !== 'delete') {
                percentTotal += value.percentPartner ? parseFloat(value.percentPartner) : 0

                return (
                  <div key={member.key} className="border-ddd bg-fff border-radius-5 mg-bottom-20">
                    <div className="pst-relative">
                      { i > 1 ?
                        <a onClick={() => removeMember(i)} className="btn btn-delete border-radius-circle pd-0 height-35 width-35 line-height-35 pst-absolute top--10 right--10">
                          <i className="material-icons">close</i>
                        </a>
                      :
                        <a className="btn disabled btn-delete border-radius-circle pd-0 height-35 width-35 line-height-35 pst-absolute top--10 right--10">
                          <i className="material-icons">close</i>
                        </a>
                      }
                    </div>

                    <div className="pd-top-20 pd-left-15 pd-right-15">
                      <Row>
                        <Column col="column col-xs-12 col-md-5 pd-right-20-md pd-right-20-lg pd-right-20-xlg">
                          <MemberName
                            label="ชื่อหุ้นสวน"
                            value={value.name}
                            index={i}
                            onChange={(v) => updateMember(v, i, 'name', lang)}
                            notSave
                            required
                          />
                        </Column>
                        <Column col="column col-xs-12 col-md-4 pd-right-20-md pd-right-20-lg pd-right-20-xlg">
                          <MemberMobile
                            value={value.mobile}
                            index={i}
                            onChange={(v) => updateMember(v, i, 'mobile')}
                            notSave
                          />
                        </Column>
                        <Column col="column col-xs-12 col-md-3">
                          <PercentPartner
                            value={value.percentPartner}
                            index={i}
                            onChange={(v) => updateMember(v, i, 'percentPartner')}
                            notSave
                          />
                        </Column>
                      </Row>

                      <Row>
                        <Column col="column col-xxs-12">
                          <WithdrawMoney
                            value={value.withdrawMoney}
                            labelType="หุ้นส่วน"
                            index={i}
                            onChange={(v) => updateMember(v, i, 'withdrawMoney')}
                            notSave
                          />
                        </Column>
                      </Row>
                    </div>
                  </div>
                )
              }
            }) }

            { percentTotal !== 100 &&
              <div>
                <b className="color-error font-0-9 text-shadow">*** % ส่วนแบ่ง รวมกันไม่ถูกต้อง ต้องรวมกันได้ 100% เท่านั้น</b>
                <input type="hidden" className="required invalid" />
              </div>
            }

            <div className="text-right">
              <a onClick={addMember} className="btn btn-shadow-none height-30 line-height-30 font-0-8 pd-left-5">
                <i className="material-icons left font-1-0">add</i> เพิ่มหุ้นส่วน
              </a>
            </div>
          </div>
        </SectionCircle>
      }

      { props.type === 'employee' &&
        <SectionCircle title="รายชื่อคนกรีดยาง" className="mg-bottom-20" bodyClassName="bg-efefef">
          <div className="pd-20">
            { members.map((member, i) => {
              const value = member.value || {}

              if (member.event !== 'delete') {
                return (
                  <div key={member.key} className="border-ddd bg-fff border-radius-5 mg-bottom-20">
                    <div className="pst-relative">
                      { i !== 0 ?
                        <a onClick={() => removeMember(i)} className="btn btn-delete border-radius-circle pd-0 height-35 width-35 line-height-35 pst-absolute top--10 right--10">
                          <i className="material-icons">close</i>
                        </a>
                      :
                        <a className="btn disabled btn-delete border-radius-circle pd-0 height-35 width-35 line-height-35 pst-absolute top--10 right--10">
                          <i className="material-icons">close</i>
                        </a>
                      }
                    </div>

                    <div className="pd-top-20 pd-left-15 pd-right-15">
                      <Row>
                        <Column col="column col-xs-12 col-md-6 pd-right-10-md pd-right-10-lg pd-right-10-xlg">
                          <PlantationName
                            required={members.length > 1 ? true : false }
                            value={value.plantationName}
                            index={i}
                            onChange={(v) => updateMember(v, i, 'plantationName', lang)}
                            notSave
                          />
                        </Column>
                        <Column col="column col-xs-12 col-md-6 pd-left-10-md pd-left-10-lg pd-left-10-xlg">
                          <MemberName
                            label="ชื่อคนกรีด (ถ้ามี)"
                            value={value.name}
                            index={i}
                            onChange={(v) => updateMember(v, i, 'name', lang)}
                            notSave
                          />
                        </Column>
                      </Row>

                      <Row>
                        <Column col="column col-xs-6 pd-right-10">
                          <PercentOwner
                            value={value.percentOwner}
                            index={i}
                            onChange={(v) => updateMember(v, i, 'percentOwner')}
                            notSave
                          />
                        </Column>
                        <Column col="column col-xs-6 pd-left-10">
                          <PercentEmployee
                            value={value.percentEmployee}
                            index={i}
                            notSave
                          />
                        </Column>
                      </Row>

                      <Row>
                        <Column col="column col-xxs-12">
                          <WithdrawMoney
                            value={value.withdrawMoney}
                            labelType="คนกรีด"
                            index={i}
                            onChange={(v) => updateMember(v, i, 'withdrawMoney')}
                            notSave
                          />
                        </Column>
                      </Row>
                    </div>
                  </div>
                )
              }
            }) }


            <div className="text-right">
              <a onClick={addMember} className="btn btn-shadow-none height-30 line-height-30 font-0-8 pd-left-5">
                <i className="material-icons left font-1-0">add</i> เพิ่มคนกรีดยาง
              </a>
            </div>
          </div>
        </SectionCircle>
      }
    </div>
  )
}

export default MembersCreateAndUpdate

import React, { Component, Fragment, useEffect, useMemo, useState } from 'react'
import cuid from 'cuid'

// Components Global
import InputDisabled from '../InputDisabled'
import Required from '../Required'
import InputNumeral from '../InputNumeral'

//maxValue="100"
//notMinus
//decimal="3"
//valueForSplace="0"
//decimalFull
//minValue="1"
//notMinus
//decimal="3"

const InputMultiNumberals = (props) => {
  const [id, setId] = useState()
  const [value, setValue] = useState('')
  const [items, setItems] = useState([''])

  useEffect(() => {
    const id = props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()

    setId(randomId+id)
  }, [])

  useEffect(() => {
    if (Array.isArray(props.value)) {
      if (props.value.length !== 0) {
        setItems(props.value)
      }
    }
  }, [props.value])

  useEffect(() => {
    const sum = calulate(items)

    if (props.onChange) {
      props.onChange(value, sum)
    }

    checkValue()
  }, [items])


  const checkValue = () => {
    const checkNull = items.filter(n => n === '')[0]

    if (checkNull === undefined) {
      let value = ''
      items.map(n => value = `${value}${n}`)
      setValue(value)
    } else {
      setValue('')
    }
  }

  const calulate = (items) => {
    let sum = 0

    items.map(v => {
      if (v) {
        sum += parseFloat(v)
      }
    })

    return sum
  }

  const handleAddItem = () => {
    const newItems = [...items]
    newItems.push("")

    setItems(newItems)
  }

  const handleUpdateItem = (i, value) => {
    const newItems = [...items]
    newItems[i] = value

    setItems(newItems)
  }

  const handleRemoveItem = (i) => {
    if (items.length > 1) {
      const newItems = [...items]
      newItems.splice(i, 1)

      setItems(newItems)
    }
  }

  const handleOnChange = (value, i) => {
    handleUpdateItem(i, value)
  }

  const getCol = (col) => {
    const num = items.length

    if (num < col) {
      col = num
    }

    return col
  }

  const getWidth = (col) => {
    const num = items.length
    let width

    if (num <= col) {
      width = 0
    } else {
      width = 30
    }

    return width
  }

  const { styleName, styleJsx, styleGlobal, inputForSite, responsive } = props
  const { xxs, xs, sm, md, lg, xlg } = responsive
  const xxsCol = getCol(xxs)
  const xsCol = getCol(xs)
  const smCol = getCol(sm)
  const mdCol = getCol(md)
  const lgCol = getCol(lg)
  const xlgCol = getCol(xlg)

  const xxsWidth = getWidth(xxs)
  const xsWidth = getWidth(xs)
  const smWidth = getWidth(sm)
  const mdWidth = getWidth(md)
  const lgWidth = getWidth(lg)
  const xlgWidth = getWidth(xlg)

  const size = props.iconSize || 40

  return (
    <div>
      {!props.disabled ?
        <Fragment>
          <Required
            {...props}
            id={id}
            classId={id}
            className={`mg-bottom-5`}
            label={props.label}
            unitName={props.unitName}
            type="text"
            value={value}
            required={props.required}
            iconValid={props.iconValid}>

            <Fragment>
              <table>
                <tbody>
                  <tr>
                    <td className="pd-0">
                      { items.map((num, i) => {
                        return (
                          <div key={i} style={{ float: 'left' }} className={`xxs-column-${xxsCol} xs-column-${xsCol} sm-column-${smCol} md-column-${mdCol} lg-column-${lgCol} xlg-column-${xlgCol}`}>
                            <table>
                              <tbody>
                                <tr>
                                  <td className="pd-0 vertical-bottom">
                                    <InputNumeral
                                      id={`${props.id}-${i}`}
                                      notSave
                                      labelHidden
                                      msgHidden
                                      multi
                                      multiValue={value}
                                      value={num}
                                      onChange={(value) => handleOnChange(value, i)}
                                      requiredClassName="mg-bottom-0"
                                      valueForSplace={props.valueForSplace}
                                      decimal={props.decimal}
                                      required={props.required}
                                      labelClassName={props.labelClassName}
                                      inputClassName={`${props.inputClassName || ''} ${items.length > 1 ? `text-center` : ''}`}
                                    />
                                  </td>

                                  { items.length !== 1 &&
                                    <td className={`pd-0 text-center width-${xxsWidth}-xxs width-${xsWidth}-xs width-${smWidth}-sm width-${mdWidth}-md width-${lgWidth}-lg width-${xlgWidth}-xlg`}>
                                      { items.length !== i+1 &&
                                        <i className="material-icons font-1-0">add</i>
                                      }
                                    </td>
                                  }
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        )
                      }) }

                      <div style={{ clear: 'both' }} />
                    </td>

                    <td className={`pd-0 ${items.length > 1 ? `width-${size+size+15}` : `width-${size+10}` } vertical-top`}>
                      <div className="text-right pd-top-5">
                        { items.length > 1 &&
                          <a style={{ userSelect: 'none' }} onClick={() => handleRemoveItem(items.length - 1)} className={`btn btn-shadow-none btn-white width-${size} height-${size} line-height-${size} border-c0c0c0 pd-0 center`}>
                            <i className="material-icons">remove</i>
                          </a>
                        }

                        <a style={{ userSelect: 'none' }} onClick={handleAddItem} className={`btn btn-shadow-none btn-white width-${size} height-${size} line-height-${size} border-c0c0c0 pd-0 center mg-left-10`}>
                          <i className="material-icons">add</i>
                        </a>
                      </div>
                    </td>

                    {/* items.length === 1 &&
                      <td className="pd-0 width-50 text-right">
                        <a style={{ userSelect: 'none' }} onClick={handleAddItem} className="btn btn-shadow-none btn-white width-40 height-40 line-height-40 border-c0c0c0 pd-0 center mg-left-10">
                          <i className="material-icons">add</i>
                        </a>
                      </td>
                    */}
                  </tr>
                </tbody>
              </table>

              <input
                type="hidden"
                id={id}
                value={items && JSON.stringify(items)}
                data-id={props.id}
                data-not-save={props.notSave}
              />
            </Fragment>
          </Required>
        </Fragment>
      :
        <InputDisabled {...props} />
      }
    </div>
  )
}

export default InputMultiNumberals

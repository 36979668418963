import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import ModalFixed from '../../../../../components/Modals/ModalFixed'
import { BtnModalOpen } from '../../../../../components/Modals/BtnModal'
import DragDropMenu from '../../../../../components/DragDropMenu'

// Components Local
import PageUpdateModal from '../PageUpdateModal'


// Redux Actions
import { updatePagesAll } from '../../../../../redux/Pages/PagesActions'


class PagesManageModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pages_id: ''
    }
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  fnStartModal = () => {
    this.mounted && this.setState({ pages_id: undefined })
  }

  fnCloseModal = () => {
    this.mounted && this.setState({ pages_id: undefined })
  }

  getPageSelected = (page) => {
    this.mounted && this.setState({ pages_id: page._id })
  }

  getPages = (pages) => {
    this.handleSubmit(pages)
  }

  handleSubmit = (params) => {
    const { business, site } = this.props

    if (params) {
      this.props.dispatch(updatePagesAll(business._id, site._id, {
        body: params,
        loadId: 'site-update-loading'
      }))
    }
  }

  render() {
    const id = "pagesManageModal"
    let { pages } = this.props
    const { pages_id } = this.state

    pages = pages.filter(page => page.type === 'page')

    return (
      <ModalFixed
        id={id}
        title="จัดการหน้า"
        width="800px"
        headerClassName="height-50 pd-top-2 pd-left-5"
        bodyClassName="top-50 bottom-50 bg-f5f5f5 pd-10"
        footerClassName="pd-9 height-50"
        fnStartModal={this.fnStartModal}
        eventModalClose={this.fnCloseModal}>

        <div>
          <PageUpdateModal
            {...this.props}
            id="pageUpdateModal"
            pages_id={pages_id}
          />

          <DragDropMenu
            {...this.props}
            getItemSelect={this.getPageSelected}
            getItems={this.getPages}
            pages={pages}
          />
        </div>

        <div>
          <table>
            <tbody>
              <tr>
                <td className="pd-0">
                  <BtnModalOpen id="pageCreateModal">
                    <a className="btn btn-xlg btn-shadow-none dp-block mg-left-0">
                      <i className="material-icons left">add</i> เพิ่มหน้าใหม่
                    </a>
                  </BtnModalOpen>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </ModalFixed>
    )
  }
}

export default connect()(PagesManageModal)

import React, { createRef, useEffect, useState } from 'react'


const KeyboardNumber = (props) => {
  const [value, setValue] = useState()
  const [refs, setRefs] = useState([])
  const buttons = [
    { key: '7', label: '7', width: 1, type: 'number' },
    { key: '8', label: '8', width: 1, type: 'number' },
    { key: '9', label: '9', width: 1, type: 'number' },
    { key: '4', label: '4', width: 1, type: 'number' },
    { key: '5', label: '5', width: 1, type: 'number' },
    { key: '6', label: '6', width: 1, type: 'number' },
    { key: '1', label: '1', width: 1, type: 'number' },
    { key: '2', label: '2', width: 1, type: 'number' },
    { key: '3', label: '3', width: 1, type: 'number' },
    { key: '0', label: '0', width: 2, type: 'number' },
    { key: '.', label: '.', width: 1, type: 'number' },

    { key: 'Backspace', icon: 'backspace', height: 1, type: 'key' },
    { key: 'Clear', label: 'Clear', height: 1, type: 'key' },
    { key: 'Enter', label: 'Enter', height: 2, type: 'key' },

    { key: '10', label: '10', width: 1, type: 'cash' },
    { key: '20', label: '20', width: 1, type: 'cash' },
    { key: '50', label: '50', width: 1, type: 'cash' },
    { key: '100', label: '100', width: 1, type: 'cash' },
    { key: '500', label: '500', width: 1, type: 'cash' },
    { key: '1000', label: '1,000', width: 1, type: 'cash' },
  ]

  const numberButtons = buttons.filter(btn => btn.type === 'number')
  const moreButtons = buttons.filter(btn => btn.type === 'key')
  const cashButtons = buttons.filter(btn => btn.type === 'cash')

  useEffect(() => {
    setRefs(refs => (
      buttons.map((value, i) => {
        return refs[i] || createRef()
      })
    ))
  }, [])

  useEffect(() => {
    setValue(`${props.value || ''}`)
  }, [props.value])

  useEffect(() => {
    if (props.use) {
      window.addEventListener("keydown", (e) => {
        //e.preventDefault()
        let key = e.key

        if (key) {
          const indexRef = buttons.findIndex(v => v.key === key)

          if (refs[indexRef]) {
            const element = refs[indexRef].current

            if (element) {
              element.click && element.click()
              element.classList.add("active-jsx")
              setTimeout(() => {
                element.classList.remove("active-jsx")
              }, 100)
            }
          }
        } else {
          console.log('key  is undefined !!!')
        }

      }, true)
    } else {
      window.removeEventListener('keydown', () => console.log('remove event keydown !!!'))
    }

    return () => {
      window.removeEventListener('keydown', () => console.log('remove event keydown !!!'))
    }
  }, [refs && refs.length, props.use])


  const handlePlusNumber = (key) => {
    const value = props.valueFloat

    if (value) {
      let newValue = parseFloat(value)

      if (newValue) {
        newValue = newValue + parseFloat(key)
      } else {
        newValue = parseFloat(key)
      }

      setValue(`${newValue}`)
    } else {
      setValue(key)
    }
  }

  const handleClickNumber = (key) => {
    let value = value

    if (props.value !== undefined) {
      value = props.value
    }

    let newValue = ''

    if (key === 'Backspace') {
      if (value) {
        newValue = value.slice(0, -1)
      } else {
        newValue = value
      }

    } else if (key === 'Clear') {
      newValue = ''
    } else if (key === 'Enter') {
      newValue = value
    } else if (key === '.') {
      if (value) {
        if (value.indexOf('.') === -1) {
          newValue = `${value}.`
        } else {
          newValue = `${value}`
        }
      } else {
        newValue = '0.'
      }
    } else {
      if (value) {
        newValue = `${value}${key}`
      } else {
        newValue = key
      }
    }

    setValue(newValue)
  }

  useEffect(() => {
    if (value !== undefined) {
      props.onChange(value)
    }
  }, [value])


  return (
    <div>
      {/*<div>props: { props.value }</div>
      <div> Text: { value }</div>*/}

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '75%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          { numberButtons.map(btn => {
            const indexRef = buttons.findIndex(v => v.key === btn.key)

            return (
              <div
                key={indexRef}
                className="pst-relative"
                style={{ width: `${33.33*btn.width}%`, paddingTop: `33.33%` }}>

                <div
                  ref={refs[indexRef]}
                  onClick={() => handleClickNumber(btn.key)}
                  className="btn-jsx pst-absolute top-1 left-1 right-1 bottom-1 border-ddd"
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                  <span className="font-2-0 color-000">{btn.label}</span>
                </div>
              </div>
            )
          }) }
        </div>

        <div style={{ width: '25%' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>

            { moreButtons.map(btn => {
              const indexRef = buttons.findIndex(v => v.key === btn.key)

              return (
                <div
                  key={indexRef}
                  className="pst-relative"
                  style={{ width: '100%', paddingTop: `${100*btn.height}%` }}>

                  <div
                    ref={refs[indexRef]}
                    onClick={() => handleClickNumber(btn.key)}
                    className="btn-jsx pst-absolute top-1 left-1 right-1 bottom-1 border-ddd"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                      { btn.icon ?
                        <i className="material-icons-outlined color-000">{btn.icon}</i>
                      :
                        <span className="color-000">{btn.label}</span>
                      }
                  </div>
                </div>
              )
            }) }
          </div>
        </div>

      </div>

      { props.useCash &&
        <div className="mg-top-10">
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            { cashButtons.map(btn => {
              const indexRef = buttons.findIndex(v => v.key === btn.key)

              return (
                <div
                  key={indexRef}
                  className="pst-relative"
                  style={{ width: `${33.33*btn.width}%`, paddingTop: `23%` }}>

                  <div
                    ref={refs[indexRef]}
                    onClick={() => handlePlusNumber(btn.key)}
                    className="btn-jsx pst-absolute top-1 left-1 right-1 bottom-2 border-ddd"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                    <span className="font-1-2 color-000">{btn.label}</span>
                  </div>
                </div>
              )
            }) }
          </div>
        </div>
      }

      <style jsx>{`
        .box-number-jsx {

        }
        .btn-jsx {
          cursor: pointer;
          background: #fff;
          user-select: none;
        }
        .btn-jsx:hover {
          box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
        }
        .btn-jsx:active, .active-jsx {
          box-shadow: unset;
          background: #a4a3a3;
        }
      `}</style>
    </div>
  )
}

export default KeyboardNumber

import React, { Component, Fragment } from 'react'
import moment from 'moment'

// components global
import Hammer from '../hammerjs'

// components
import SelectionButton from './components/SelectionButton'
import DatesNotes from './components/DatesNotes'
import CalendarHeader from './components/CalenderHeader'
import BoxDescriptions from './components/BoxDescriptions'

// css file
import css from './css/calendar.css'

// langs
import {
  generateCalendar,
  getLang,
  getYearsAll,
  getConvertFullDate,
  getCurrentDate,
  getDatesOfMonthFirst,
  getDatesOfMonth,
  getDatesOfMonthLast ,
  handlePanEnd,
  handlePanLeft,
  handlePanRight,
  handlePanLeftAdd,
  handlePanRightAdd,
  handleSelectMutiDates,
  handleSelectDate,
  nextMonth,
  nextYear,
  nextMonthAndYear,
  dateNow,
  monthChange,
  yearChange,
  selectDateFilter
} from './js/calendar'
import { toBBYear } from '../../util/getDateTime'

class Calendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      datesNotes: this.props.datesNotes,
      showNotes: this.props.showNotes ? true : false,
      lang: {
        monthsFull: [],
        months: [],
        daysFull: [],
        days: []
      },
      yearsAll: [],
      currentDate: {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        date: new Date().getDate()
      },
      today: moment().format('YYYY-MM-DD'),
      datesOfMonth: [],
      datesOfMonthFirst: [],
      datesOfMonthLast: [],
      datesSelect: [],
      datesSelectValue: [],
      panLeftOff: true,
      panRightOff: true,
      panRightAddOff: true
    }
  }

  componentDidMount() {
    getLang(this)
    getYearsAll(this)

    this.getStyle()
    this.handleResize()

    generateCalendar(this)

    this.datesSelected(this.props.datesSelect)
    this.datePickerActive(this.props.datesSelect)
    this.calendarActive(this.props.datesCalendarActive)
    this.setDate(this.props)

    /*let startCalendar = this.props.datesSelect.startDate.split('-')

    this.setState({
      currentDate: {
        year: startCalendar[0],
        month: parseInt(startCalendar[1]) - 1,
        date: 1
      }
    })*/
  }

  /*componentWillReceiveProps(nextProps) {
    this.getStyle()

    if (nextProps.setDate && this.props.setDate) {
      if (nextProps.setDate.date !== this.props.setDate.date) {
        this.setDate(nextProps)
      }
    }

    if (nextProps.datesSelect !== this.props.datesSelect) {
      this.datesSelected(nextProps.datesSelect)
    }

    if (nextProps.datesCalendarActive !== this.props.datesCalendarActive) {
      this.calendarActive(nextProps.datesCalendarActive)
    }
  }*/

  componentDidUpdate(prevProps) {
    this.getStyle()

    if (prevProps.setDate && this.props.setDate) {
      if (prevProps.setDate.date !== this.props.setDate.date) {
        this.setDate(this.props)
      }
    }

    if (prevProps.datesSelect !== this.props.datesSelect) {
      this.datesSelected(this.props.datesSelect)
    }

    if (prevProps.datesCalendarActive !== this.props.datesCalendarActive) {
      this.calendarActive(this.props.datesCalendarActive)
    }
  }

  calendarActive = (datesSelect) => {
    if (datesSelect && this.props.type !== 'datePicker') {
      if (datesSelect.startDate && datesSelect.endDate) {
        let monthNew = moment(datesSelect.startDate)
        let monthCurrent = moment(getConvertFullDate(this.state.currentDate.year, this.state.currentDate.month + 1, 1))
        let diffMonth = monthNew.diff(monthCurrent, 'month')

        if (diffMonth !== 0) {
          let currentDate = getCurrentDate(this)
          currentDate.setMonth(currentDate.getMonth() + diffMonth)

          this.setState({
            currentDate: {
              year: currentDate.getFullYear(),
              month: currentDate.getMonth(),
              date: currentDate.getDate()
            },
          }, () => generateCalendar(this))
        }
      }
    }
  }

  datePickerActive = (datesSelect) => {
    if (datesSelect && this.props.type === 'datePicker') {
      if (datesSelect.startDate && datesSelect.endDate) {

        let monthNew = moment(datesSelect.startDate)
        let monthCurrent = moment(getConvertFullDate(this.state.currentDate.year, this.state.currentDate.month + 1, 1))
        let diffMonth = monthNew.diff(monthCurrent, 'month')

        let currentDate = getCurrentDate(this)
        currentDate.setMonth(currentDate.getMonth() + diffMonth)

        this.setState({
          currentDate: {
            year: currentDate.getFullYear(),
            month: currentDate.getMonth(),
            date: currentDate.getDate()
          },
        }, () => generateCalendar(this))
      }

      let startCalendar

      if (typeof datesSelect.startDate === 'string') {
        startCalendar = datesSelect && datesSelect.startDate && datesSelect.startDate.split('-')
      }

      if (startCalendar) {
        this.setState({
          currentDate: {
            year: startCalendar[0],
            month: parseInt(startCalendar[1]) - 1,
            date: 1
          }
        })
      }
    }
  }

  datesSelected = (datesSelect) => {
    if (datesSelect) {
      if (datesSelect.startDate && datesSelect.endDate) {
        let datesSelectNew = []
        let startDate = moment(datesSelect.startDate)
        let endDate = moment(datesSelect.endDate)
        let diffDate = endDate.diff(startDate, 'days')

        for (var i = 0; i <= diffDate; i++) {
          startDate = moment(datesSelect.startDate)
          let betweenDates = startDate.add(i, 'days').format('YYYY-MM-DD')
          datesSelectNew.push(betweenDates)
        }

        this.setState({ datesSelect: datesSelectNew })
      }
    }
  }

  getDatesSelect = () => {
    this.state.datesSelect.sort()
  }

  getStyle = () => {
    if (this.props.type !== 'datePicker' && this.props.type !== 'showMonthOfNotes') {
      setTimeout(() => {
        let calendar = document.getElementById(`calendar-full-${this.props.id}`)
        let header = document.getElementById(`calendar-header-${this.props.id}`)
        let days = document.getElementById(`calendar-day-${this.props.id}`)

        let boxDaysHeight = days.clientHeight
        let boxDateHeight = calendar.clientHeight - header.clientHeight - days.clientHeight - 1
        let calendarRows = (this.state.datesOfMonthFirst.length + this.state.datesOfMonth.length + this.state.datesOfMonthLast.length) / 7
        let rowHeight = boxDateHeight / calendarRows

        this.setState({
          styleBoxDate: {
            height: rowHeight+2+'px',
            paddingTop: '0',
            minHeight: '20px',
            maxHeight: days.clientWidth+'px'
            //maxHeight: days.clientWidth > 60 ? 100+'px' : days.clientWidth+'px'
          }
        })
      }, 0)
    }
  }

  setDate = (props) => {
    const setDate = props.setDate

    if (setDate) {
      const date = setDate.date

      if (setDate.month && setDate.year) {
        const month = moment(date).format('M') - 1
        const year = moment(date).format('YYYY')

        nextMonthAndYear(this, month, year)
      }
    }
  }

  handleResize = () => {
    window.onresize = (event) => {
      this.getStyle()
    }
  }

  render() {
    this.getDatesSelect()

    let styleContentDate = {
      textAlign: this.props.type === 'datePicker' || this.props.type === 'showMonthOfNotes' && 'center'
    }

    return (
      <div id={`calendar-full-${this.props.id}`} className={`${this.props.className} ${css.calendar}`} style={{ height: '100%' }}>
        <div id="box-calendar" className={`${css.boxCalendar}`}>

          { this.props.type !== 'showMonthOfNotes' ?
            <div>
              { this.props.type !== 'datePicker' ?
                <div id={`calendar-header-${this.props.id}`} className={`${css.header}`}>
                  <div style={{ float: 'left' }}>
                    <a onClick={() => dateNow(this)} className={`btn btn-flat border-efefef mg-right-10 border-radius-10`}>
                      วันนี้
                    </a>

                    {this.state.datesSelectValue[this.state.datesSelectValue.length - 1]}

                    { !this.props.btnNextHidden &&
                      <a onClick={() => nextMonth(this, -1)} className={`btn btn-flat border-efefef border-right-radius-0 border-left-radius-10`}>
                        <i className={`material-icons`}>keyboard_arrow_left</i>
                      </a>
                    }

                    { !this.props.btnNextHidden &&
                      <a onClick={() => nextMonth(this, 1)} className={`btn btn-flat border-efefef mg-left-3 border-left-radius-0 border-right-radius-10`}>
                        <i className={`material-icons`}>keyboard_arrow_right</i>
                      </a>
                    }
                  </div>

                  <div className={`${this.props.id}`} style={{ float: 'right', marginLeft: '10px' }}>
                    <select className={`browser-default ${css.selectYear}`} value={this.state.currentDate.year} onChange={(e) => yearChange(e, this)}>
                      <option className={`${this.props.id}`} value="" disabled>เลือกปี</option>
                      {this.state.yearsAll.map((year, key) => {
                        return (
                          <option className={`${this.props.id}`} key={key} value={year}>{year}</option>
                        )
                      })}
                    </select>
                  </div>

                  <div className={`${this.props.id}`} style={{ float: 'right', marginLeft: '10px' }}>
                    <select className={`browser-default ${css.selectMonth}`} value={this.state.currentDate.month} onChange={(e) => monthChange(e, this)}>
                      <option className={`${this.props.id}`} value="" disabled>เลือกเดือน</option>
                      {this.state.lang.monthsFull.map((month, key) => {
                        return (
                          <option className={`${this.props.id}`} key={key} value={key}>{month}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              :
                <div className={`${css.headerDatePicker}`}>
                  { !this.props.btnNextHidden &&
                    <div className={`${css.previousMonth}`}>
                      <a onClick={() => nextMonth(this, -1)} className={`btn btn-white btn-shadow-none height-30 line-height-30 width-40`}>
                        <i className={`material-icons`}>keyboard_arrow_left</i>
                      </a>
                    </div>
                  }

                  <div className={`${css.month}`}>
                    { this.props.bbYear ?
                      <span>{ this.state.lang.monthsFull[this.state.currentDate.month] + ' ' + toBBYear(moment(`01-01-${this.state.currentDate.year}`), 'YYYY') }</span>
                    :
                      <span>{ this.state.lang.monthsFull[this.state.currentDate.month] + ' ' + this.state.currentDate.year }</span>
                    }
                  </div>

                  { !this.props.btnNextHidden &&
                    <div className={`${css.nextMonth}`}>
                      <a onClick={() => nextMonth(this, 1)} className={`btn btn-white btn-shadow-none height-30 line-height-30 width-40`}>
                        <i className={`material-icons`}>keyboard_arrow_right</i>
                      </a>
                    </div>
                  }
                </div>
              }
            </div>
          :
            <div id={`calendar-header-${this.props.id}`} className={`${css.header}`}>
              <div className={`${css.month}`}>
                {this.state.lang.monthsFull[this.state.currentDate.month] + ' ' + this.state.currentDate.year}
              </div>
            </div>
          }

          <div style={{ marginTop: '-7px' }} className={`${this.props.id}`}>
            {this.state.lang.days.map((value, key) => {
              return (
                <div id={`calendar-day-${this.props.id}`} key={key} className={`${css.boxDay}`}>
                  {value}
                </div>
              )
            })}

            { /*------------ dates Of Month First ------------*/ }
            {this.state.datesOfMonthFirst.map((value, key) => {
              let dateDayFirst = (value.day === 0 ? css.dateDayFirst : '')

              return (
                <div key={key} className={`${css.boxDate} ${css.boxDateFirst} ${dateDayFirst}`} style={this.state.styleBoxDate}>
                  <div className={`${css.contentDate}`} style={styleContentDate}>
                    <div className={`${css.textDate} ${this.props.type === 'datePicker' && css.textDateCenter}`}>
                      {value.date}
                    </div>
                  </div>
                </div>
              )
            })}


            { /*------------ dates Of Month ------------*/ }
            {this.state.datesOfMonth.map((value, key) => {
              let selectDate = selectDateFilter(this, value)
              let today = (value.fullDate === this.state.today ? true : false)
              let dateDayFirst = (value.day === 0 ? css.dateDayFirst : '')
              let disabled

              if (!this.props.showAllDate) {
                disabled = (value.fullDate < this.props.startDate ? true : false)
              }

              return (
                <div className={`${this.props.id}`} key={key}>
                  {!disabled ?
                    <Hammer
                      id={value.fullDate}
                      onClick={() => handleSelectDate(this, value)}
                      onMouseEnter={() => handleSelectMutiDates(this, value)}
                      onPanEnd={() => handlePanEnd(this)}
                      onPanRight={() => handlePanRight(this, value)}
                      onPanLeft={() => handlePanLeft(this, value)}
                      onPanUp={() => handlePanLeft(this, value)}
                      onPanDown={() => handlePanRight(this, value)}
                      className={`${css.boxDate} ${css.boxDateMonth} ${dateDayFirst}`}
                      style={this.state.styleBoxDate}>

                      <div className={`${css.contentDate} ${selectDate && css.seleted}`} style={styleContentDate}>
                        <div className={`${css.textDate} ${today && css.textDateToday} ${this.props.type === 'datePicker' && css.textDateCenter}`}>
                          {value.date}
                        </div>

                        {today && this.props.type !== 'datePicker' && this.props.type !== 'showMonthOfNotes' &&
                          <span style={{ fontSize: '0.7rem' }} className={`${this.props.id} hidden-xxs hidden-xs hidden-xxs hidden-sm`}> วันนี้</span>
                        }

                        { this.props.datesNotes && this.props.showNotes &&
                          <DatesNotes
                            className={`${this.props.id}`}
                            selectDate={selectDate}
                            date={value.date}
                            fullDate={value.fullDate}
                            showNotesColor={this.props.showNotesColor}
                            noneNotesIsDisabled={this.props.noneNotesIsDisabled}
                            datesNotes={this.props.datesNotes}
                            notesBgColor={this.props.notesBgColor}
                            type={this.props.type}
                            css={css}
                          />
                        }


                        {selectDate && this.props.mutipleSelect &&
                          <SelectionButton
                            className={`${this.props.id}`}
                            date={value.fullDate}
                            startDate={this.state.datesSelect[0]}
                            endDate={this.state.datesSelect[this.state.datesSelect.length - 1]}
                            onPanLeft={() => handlePanLeftAdd(this, value)}
                            onPanRight={() => handlePanRightAdd(this, value)}
                            onPanRightRemove={() => handlePanRightRemove(this, value)}
                            onPanEnd={() => handlePanEnd(this)}
                          />
                        }

                      </div>
                    </Hammer>
                    :
                    <div className={`${css.disabled} ${css.boxDate}`} style={this.state.styleBoxDate}>
                      <div className={`${css.contentDate}`}>
                        <div className={`${css.textDate} ${today && css.textDateToday}`}>
                          {value.date}
                        </div>

                        { today && this.props.type !== 'datePicker' && this.props.type !== 'showMonthOfNotes' &&
                          <span className={`${this.props.id} hidden-xxs hidden-xs hidden-sm`} style={{ marginLeft: '5px', fontSize: '0.7rem' }}> วันนี้</span>
                        }

                        { this.props.datesNotes && this.props.showNotes &&
                          <DatesNotes className={`${this.props.id}`} date={value.fullDate} datesNotes={this.props.datesNotes} />
                        }
                      </div>
                    </div>
                  }
                </div>
              )
            })}


            { /*------------ dates Of Month Last ------------*/ }
            {this.state.datesOfMonthLast.map((value, key) => {
              return (
                <div key={key} className={`${css.boxDate} ${css.boxDateLast}`}  style={this.state.styleBoxDate}>
                  <div className={`${css.contentDate}`} style={styleContentDate}>
                    <div className={`${css.textDate} ${this.props.type === 'datePicker' && css.textDateCenter}`}>
                      {value.date}
                    </div>
                  </div>
                </div>
              )
            })}

          </div>
        </div>
      </div>
    )
  }
}

export default Calendar

// Containers
import RubberPaymentsContainer from '../../../../../../containers/dashboard/SystemRubber/RubberPayments/containers/RubberPaymentsContainer'

// Routes
import RubberPaymentsMenuRoutes from './routes/RubberPaymentsMenuRoutes'



const RubberPaymentsRoutes = [
  {
    path: '/:username/rubber-payments',
    component: RubberPaymentsContainer,
    routes: [
      ...RubberPaymentsMenuRoutes,
    ]
  }
]

export default RubberPaymentsRoutes

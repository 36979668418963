import React, { Component } from 'react'
import { connect } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar'

// Components Global
import BtnManager from '../BtnManager'
import Switch from '../Inputs/Switch'

// css
import css from './css/layoutsDescriptions.css'


export const LayoutsDescriptions = (props) => {
  return (
    <div className={css.layout} {...props}>
      {props.children}
    </div>
  )
}

export const Layouts1 = (props) => {
  return (
    <div
      className={`${css.layout1} ${css.DefaultLayout1} ${css.paddingBottom}`}
      style={props.style}>

      <PerfectScrollbar>
        { props.children }
      </PerfectScrollbar>
    </div>
  )
}


class Descriptions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedData: null,
      open: false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedData !== this.props.selectedData) {

      if (this.props.selectedData) {
        this.setState({ selectedData: this.props.selectedData }, () => {
          setTimeout(() => {
            this.setState({ open: true }, () => {
              document.body.style.overflow = 'hidden'
            })
          }, 0);
        })
      } else {
        if (this.state.selectedData !== null) {
          this.setState({ open: false }, () => {
            document.body.style.overflow = null

            setTimeout(() => {
              this.setState({ selectedData: null })
            }, 500);
          })
        }
      }
    }
  }

  handleStay = (e) => e.stopPropagation()

  render() {
    const { selectedData, open } = this.state
    const {
      id,
      folderId,
      manager,
      unSelect,
      unSelectFolder,
      foldersManage,
      layoutFull
    } = this.props


    return (
      <div
        id={id}
        onClick={this.handleStay}
        className={`${css.layout2} ${open ? css.show : css.hidden }`}
        style={{
          width: layoutFull && 0,
          top: layoutFull && 0
        }}>

        <div className={css.boxDescriptions}>
          <div className={css.descriptions}>


              { selectedData &&
                <div
                  className={`${css.details} ${open ? css.detailsShow : css.detailsHidden }` }
                  style={{
                    borderLeft: layoutFull && '1px solid #eee'
                  }}>

                  <div className={css.boxTitle}>
                    <table>
                      <tbody>
                        <tr>
                          <td className="pd-0" width="40">
                            <a
                              onClick={() => folderId ? unSelectFolder() : unSelect() }
                              className="btn btn-xs mg-right-10 btn-shadow-none waves-effect waves-light red">

                              <i className="material-icons">close</i>
                            </a>
                          </td>
                          <td className="pd-0">
                            รายะเอียด
                          </td>

                          { !folderId &&
                            <td className="pd-0 text-right">
                              { manager && manager.onSwitchOpen &&
                                <Switch
                                  id={selectedData._id}
                                  value={false}
                                  confirm={{
                                    label: manager.onSwitchOpen.label,
                                    type: "success",
                                    icon: "public",
                                    onConfirm: manager.onSwitchOpen.onConfirm
                                  }}
                                />
                              }

                              { manager && manager.onSwitchClose &&
                                <Switch
                                  id={selectedData._id}
                                  value={true}
                                  confirm={{
                                    label: manager.onSwitchClose.label,
                                    type: "warning",
                                    icon: "lock",
                                    onConfirm: manager.onSwitchClose.onConfirm
                                  }}
                                />
                              }
                            </td>
                          }
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div style={{ position: 'absolute', zIndex: '1', right: '0', left: '0', top: '39px', bottom: '40px' }}>
                    <PerfectScrollbar>
                      { React.cloneElement(this.props.children, { data: selectedData }) }
                    </PerfectScrollbar>
                  </div>
                  <div style={{ position: 'absolute', background: '#fafafa', zIndex: '1', borderTop: '1px solid #eee', padding: '5px', height: '40px', right: '0', left: '0', bottom: '0' }}>
                    { manager &&
                      <BtnManager
                        {...this.props}
                        id={selectedData._id}
                        listTable
                        setOverflowNull
                        manager={[
                          {
                            type: 'modal-move',
                            show: manager.modalMove ? true : false,
                            handle: manager.modalMove && {
                              modal: { id: 'folderMoveModal' }
                            }
                          },
                          {
                            type: selectedData && selectedData.isFolder ? 'link-view-folder' : 'link-view',
                            show: manager.onView ? true : false,
                            handle: manager.onView && manager.onView,
                          },
                          {
                            type: 'link-edit',
                            show: manager.onEdit ? true : false,
                            handle: manager.onEdit && manager.onEdit,
                          },
                          {
                            type: 'modal-view',
                            show: manager.modalView ? true : false,
                            handle: manager.modalView && manager.modalView(selectedData._id),
                          },
                          {
                            type: 'modal-edit',
                            show: manager.modalEdit ? true : false,
                            handle: manager.modalEdit && manager.modalEdit(selectedData._id),
                          },
                          {
                            type: 'trash',
                            show: manager.onTrash ? true : false,
                            handle: manager.onTrash && manager.onTrash,
                          },
                          {
                            type: 'restore',
                            show: manager.onRestore ? true : false,
                            handle: manager.onRestore && manager.onRestore,
                          },
                          {
                            type: 'delete',
                            show: manager.onDelete ? true : false,
                            handle: manager.onDelete && manager.onDelete,
                          }
                        ]}
                      />
                    }
                  </div>
                </div>
              }

              { (!open && !layoutFull) &&
                <div className={css.boxNotFound}>
                  <div className="box-middle center height-full">
                    <div>
                      <i className="material-icons mg-bottom-10 font-75 dp-block" style={{ color: '#c0c0c0' }}>info_outline</i>
                      <span style={{ color: '#8c8c8c' }}>เลือกรายการเพื่อดูรายละเอียด</span>
                    </div>
                  </div>
                </div>
              }

          </div>
        </div>
      </div>
    )
  }
}

export const Layouts2 = connect()(Descriptions)

import React, { Component } from 'react'

// Components Global
import TextInput from '../../../../../components/Inputs/TextInput'
import Select from '../../../../../components/Inputs/Select'
import InputRemote from '../../../../../components/Inputs/InputRemote'
import Switch from '../../../../../components/Inputs/Switch'
import Textarea from '../../../../../components/Inputs/Textarea'

// Actions
import {
  checkUsernameDuplicateSite,
  checkDomianDuplicateSite
} from '../../../../../redux/Sites/SitesActions'


export const TemplateName = (props) => {
  const lang = props.lang || 'local'
  const label = props.label || 'ชื่อเทมเพลต'

  return (
    <TextInput
      label={label}
      id={`name.${lang}`}
      value={props.value && props.value[lang]}
      placeholder={label}
      type="text"
      required
      iconValid
    />
  )
}

export const TemplateType = (props) => {
  return (
    <Select
      active
      id="type"
      label="ประเภทเทมเพลต"
      placeholderDisabled
      value={props.value}
      options={[
        { value: '', name: 'ไม่มี' },
        { value: 'form', name: 'ฟอร์ม' },
      ]}
    />
  )
}

export const ContentGroupPageName = (props) => {
  const lang = props.lang || 'local'
  const label = props.label || 'ชื่อหน้าเพจ'

  return (
    <TextInput
      label={label}
      id={`name.${lang}`}
      value={props.value && props.value[lang]}
      placeholder={label}
      type="text"
      required
      iconValid
    />
  )
}

export const ContentGroupPagePath = (props) => {
  const label = props.label || 'ชื่อ URL'

  return (
    <TextInput
      label={label}
      id={`path`}
      value={props.value && props.value}
      placeholder={label}
      type="text"
      required
      iconValid
    />
  )
}



export const TemplateWidth = (props) => {
  const label = props.label || 'ความกว้างเทมเพลต'

  return (
    <TextInput
      label={label}
      id={`width`}
      value={props.value}
      placeholder={label}
      type="text"
      iconValid
    />
  )
}


export const SiteInputString = ({ id, label, placeholder, value }) => {
  return (
    <TextInput
      label={label}
      id={id}
      value={value}
      placeholder={placeholder || label}
      inputType="text"
      type="text"
      required
      iconValid
    />
  )
}


export const SiteGoogleAnalyticsCode = (props) => {
  return (
    <TextInput
      label="รหัสติดตามของ Google Analytics"
      id="googleAnalyticsCode"
      value={props.value}
      placeholder="รหัสติดตามของ Google Analytics"
      inputType="text"
      type="text"
      iconValid
    />
  )
}

export const SiteGoogleAnalyticsCodeNew = (props) => {
  return (
    <TextInput
      label="รหัสติดตามของ Google Analytics (เวอร์ชั่นใหม่)"
      id="googleAnalyticsCodeNew"
      value={props.value}
      placeholder="รหัสติดตามของ Google Analytics"
      inputType="text"
      type="text"
      iconValid
    />
  )
}

export const SiteGoogleSiteVerificationCode = (props) => {
  return (
    <TextInput
      label="รหัส google-site-verification"
      id="googleSiteVerificationCode"
      value={props.value}
      placeholder="รหัส google-site-verification"
      inputType="text"
      type="text"
      iconValid
    />
  )
}

export const SiteFbCustomerChatPageId = (props) => {
  return (
    <TextInput
      label="เฟซบุ๊คเพจไอดี"
      id="fbCustomerChat.page_id"
      value={props.value}
      placeholder="เฟซบุ๊คเพจไอดี"
      inputType="text"
      type="text"
      iconValid
    />
  )
}

export const SiteFbCustomerChatStatus = (props) => {
  return (
    <div className="border-eee border-radius-5 pd-10 pd-left-15">
      <Switch
        id="fbCustomerChat.status"
        value={props.value}
        valueOpen="open"
        valueOff="close"
        label="สถานะ"
      />
    </div>
  )
}

export const SiteEcommerceStatus = (props) => {
  return (
    <div className="border-eee border-radius-5 pd-10 pd-left-15">
      <Switch
        id="ecommerce.status"
        value={props.value}
        valueOpen="open"
        valueOff="close"
        label="สถานะ"
      />
    </div>
  )
}



export class SiteUsername extends Component {
  getUser = (data) => {
    this.setState({ user: data })
  }

  render() {
    const { site } = this.props
    let query

    if (site && site._id) {
      query =`sites_id=${site._id}&username=`
    } else {
      query =`username=`
    }

    return (
      <InputRemote
        required
        iconValid
        labelHidden
        id="username"
        value={this.props.value}
        label="ชื่อเว็บไซต์"
        placeholder="ชื่อเว็บไซต์"
        type="regex"
        onChange={this.props.onChange}
        inputStyle={this.props.style}
        statusStyle={this.props.statusStyle}
        regex={{
          pattern: /^([\A-Za-z0-9]+((?:\-[\A-Za-z0-9]+)*)){3}$/i,
          msg: 'ไม่ถูกต้อง ต้องเป็น ภาษาอังกฤษหรือตัวเลข มีอย่างน้อย 3 ตัวอักษร'
        }}
        remote={{
          action: checkUsernameDuplicateSite,
          query,
          msgLoading: 'กำลังตรวจสอบ...',
          msgDuplicate: 'ชื่อเว็บไซต์นี้ มีผู้อื่นใช้งานแล้ว ไม่สามารถใช้งานได้',
          msgNone: 'ชื่อเว็บไซต์นี้ สามารถใช้งานได้',
          dataSuccess: null
        }}
      />
    )
  }
}


export class SiteDomain extends Component {
  getUser = (data) => {
    this.setState({ user: data })
  }

  render() {
    const { site } = this.props
    let query

    if (site && site._id) {
      query =`sites_id=${site._id}&domain=`
    } else {
      query =`domain=`
    }

    return (
      <InputRemote
        required
        iconValid
        labelHidden
        id="domain"
        value={this.props.value}
        label="โดเมน"
        placeholder="โดเมน"
        type="regex"
        onChange={this.props.onChange}
        inputStyle={this.props.style}
        statusStyle={this.props.statusStyle}
        regex={{
          pattern: /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z\.a-zA-Z]{2,}$/,
          //pattern: /^([\A-Za-z0-9]+((?:\-[\A-Za-z0-9]+)*)){3}$/i,
          msg: 'ไม่ถูกต้อง ตัวอย่าง เช่น example.com เป็นต้น'
        }}
        remote={{
          action: checkDomianDuplicateSite,
          query,
          msgLoading: 'กำลังตรวจสอบ...',
          msgDuplicate: 'โดเมนนี้ มีผู้อื่นใช้งานแล้ว ไม่สามารถใช้งานได้',
          msgNone: 'โดเมนนี้ สามารถใช้งานได้',
          dataSuccess: null
        }}
      />
    )
  }
}


import moment from 'moment'

export const rubberProductValue = ({ product }) => {
  if (product) {
    const types = getUseRubberProductTypes()
    const priceItems = getRubberProductItems({ types, productItems: product.items })
    const date = moment(product.date).format('YYYY-MM-DD')

    return {
      ...product,
      date,
      priceItems
    }
  }
}

export const rubberProductTypes = () => {
  return [
    { code: 'rubber-sheet', name: 'ยางแผ่นดิบ', icon: 'content_copy' },
    { code: 'ribbed-smoked-sheet-3', name: 'ยางแผ่นรมควันชั้น 3', icon: 'filter_3' },
    { code: 'field-latex', name: 'น้ำยางสด', icon: 'local_drink' },
    { code: 'cup-lump', name: 'เศษยาง', icon: 'grain' }
  ]
}

export const getRubberProductType = (productType) => {
  const types = rubberProductTypes()

  return types.filter(type => type.code === productType)[0]
}

export const getUseRubberProductTypes = () => {
  const types = rubberProductTypes()

  return types.filter(type => type.code === 'field-latex')
}

export const getRubberProductItems = ({ types, productItems }) => {
  const newItems = []

  types.map(type => {
    const item = productItems && productItems.filter(item => item.code === type.code)[0]

    newItems.push({
      code: type.code,
      name: type.name,
      icon: type.icon,
      price: item && item.price || ''
    })
  })

  return newItems
}

import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from 'components/Modals/Modal'
import { closeModal } from 'core/redux/App/AppActions'

const ShowErrorModal = (props) => {
  const { modal } = props
  const { errorCode } = modal && modal.params || {}

  return (
    <Modal
      id={props.id}
      headerTitle="เกิดข้อผิดพลาด"
      headerLeft
      width="400px">

      <div className="pd-15 center">
        <i className="material-icons font-4-0 color-error">error_outline</i>
        <div className="pd-top-20 font-1-0">
          { errorCode ?
            <Fragment>
              { errorCode === 'channelId-duplicate' &&
                <div>
                  ไม่สามารถใช้งาน <b className='color-error'>Channel ID</b> ได้ เนื่องจาก <b className='color-error'>Channel ID</b> ถูกใช้งานในระบบนี้แล้ว
                </div>
              }

              { errorCode === 'line-oa-unauthorized' &&
                <div>
                  ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบ <b className='color-error'>Channel ID, Channel Secret</b> หรือ <b className='color-error'>Channel access token</b> อีกครั้ง
                </div>
              }

              { errorCode === 'line-oa-verify-error' &&
                <div>
                  พบข้อผิดพลาด กรุณาตรวจสอบการเปิด-ปิดรายการต่าง ๆ ใน <b className='color-error'>LINE Developer</b> และ <b className='color-error'>LINE Official Account Manager</b> ให้ตรงตามที่ระบุไว้ด้านล่างอีกครั้ง
                </div>
              }
            </Fragment>
          :
            <div>
              ไม่สามารถเชื่อมต่อได้ กรุณาลองใหม่อีกครั้ง
            </div>
          }
        </div>

        <a
          onClick={() => props.dispatch(closeModal(props.id))}
          className="btn red accent-4 btn-shadow-none mg-top-30 pd-left-20 pd-right-20">
          ลองใหม่อีกครั้ง
        </a>
      </div>
    </Modal>
  )
}

function mapStateToProps(store, props) {
  return {
    modal: store.app.modals.filter(modal => modal.id === props.id)[0]
  }
}

export default connect(mapStateToProps)(ShowErrorModal)

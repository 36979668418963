import { lazy } from 'react'
import suspenseComponent from '../../../../../../../util/suspenseComponent'

// Containers
import RubberReportsMenuContainer from '../../../../../../../containers/dashboard/SystemRubber/RubberReports/containers/RubberReportsMenuContainer'

// Pages
const ReportPurchasesPage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberReports/pages/ReportPurchasesPage'))
const ReportPurchasePayments = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberReports/pages/ReportPurchasePayments'))
const ReportBillsPage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberReports/pages/ReportBillsPage'))
const ReportReceivablesPage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberReports/pages/ReportReceivablesPage'))
const ReportReceivablePaymentsPage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberReports/pages/ReportReceivablePaymentsPage'))
const ReportSalesPage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberReports/pages/ReportSalesPage'))
const ReportContractsPage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberReports/pages/ReportContractsPage'))
const ReportDepositsPage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberReports/pages/ReportDepositsPage'))
const ReportProductsPage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberReports/pages/ReportProductsPage'))
const ReportReceiptsPage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberReports/pages/ReportReceiptsPage'))
const ReportInventoriesPage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberReports/pages/ReportInventoriesPage'))
const ReportCustomersSellersPage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberReports/pages/ReportCustomersSellersPage'))
const ReportCustomersBuyersPage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberReports/pages/ReportCustomersBuyersPage'))


// Types
const RubberReportsMenuRoutes = [
  {
    path: `/:username/rubber-reports`,
    component: RubberReportsMenuContainer,
    routes: [
      {
        path: `/:username/rubber-reports/purchases`,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ReportPurchasesPage)
      },
      {
        path: `/:username/rubber-reports/purchase-payments/:paymentStatus(paid|unpaid)`,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ReportPurchasePayments)
      },
      {
        path: `/:username/rubber-reports/bills`,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ReportBillsPage)
      },
      {
        path: `/:username/rubber-reports/products`,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ReportProductsPage)
      },

      {
        path: `/:username/rubber-reports/receivables`,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ReportReceivablesPage)
      },
      {
        path: `/:username/rubber-reports/receivable-payments`,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ReportReceivablePaymentsPage)
      },

      {
        path: `/:username/rubber-reports/sales`,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ReportSalesPage)
      },
      {
        path: `/:username/rubber-reports/contracts`,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ReportContractsPage)
      },
      {
        path: `/:username/rubber-reports/deposits`,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ReportDepositsPage)
      },

      {
        path: `/:username/rubber-reports/receipts`,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ReportReceiptsPage)
      },

      {
        path: `/:username/rubber-reports/inventories/:inventoryType(inventory|contract|deposit)/:rubberType(field-latex|rubber-sheet|ribbed-smoked-sheet)`,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ReportInventoriesPage)
      },

      {
        path: `/:username/rubber-reports/customers-sellers`,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ReportCustomersSellersPage)
      },
      {
        path: `/:username/rubber-reports/customers-buyers`,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ReportCustomersBuyersPage)
      },
    ]
  },
]

export default RubberReportsMenuRoutes

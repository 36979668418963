import React, { Fragment } from 'react'
import AvatarIcon from '../../../../../../components/AvatarIcon'

// Components Global
import InputDatePicker from "../../../../../../components/Inputs/InputDatePicker"
import InputNumeral from '../../../../../../components/Inputs/InputNumeral'
import InputSelectDropdown from '../../../../../../components/Inputs/InputSelectDropdown'
import Select from '../../../../../../components/Inputs/Select'
import Textarea from '../../../../../../components/Inputs/Textarea'
import { customerMemberValue } from '../../../../../../redux/RubberCustomers/RubberCustomersValue'
import { CustomerItemProfileAvater } from '../../../RubberCustomerSellers/components/CustomersUserInterface'


// Components RubberCustomerSellers
import RubberCustomerInputSelect from '../../../RubberCustomerSellers/components/RubberCustomerInputSelect'


export const ReceivableDate = (props) => {
  return (
    <InputDatePicker
      id="date"
      required
      label="วันที่เบิกเงินล่วงหน้า"
      dateNow
      value={props.value}
      onChange={props.onChange}
      inputClassName="font-0-8"
      labelClassName="font-0-8"
      bbYear
    />
  )
}

export const ReceivableCustomerSeller = (props) => {
  return (
    <RubberCustomerInputSelect
      {...props}
      label="เจ้าของสวน"
      onChange={props.onChange}
      customer={props.customer}
      where="status=open,type=seller"
      required={props.required}
      notUpdateDataSelected={props.notUpdateDataSelected}
      inputClassName="font-0-8"
      labelClassName="font-0-8"
    />
  )
}

export const ReceivableNote = (props) => {
  return (
    <Textarea
      id="note"
      label="หมายเหตุ"
      placeholder="หมายเหตุ"
      inputClassName="font-0-8 line-height-25 pd-top-10"
      inputClassNameModify="min-height-45 pd-top-0 pd-bottom-0"
      labelClassName="font-0-8"
      value={props.value}
      onChange={props.onChange}
    />
  )
}

export const ReceivableAmount = (props) => {
  const inputClassName = "font-0-8"
  const labelClassName =" font-0-8"

  return (
    <InputNumeral
      id="amount"
      label="จำนวนเงินที่เบิกล่วงหน้า"
      placeholder="จำนวนเงินที่เบิกล่วงหน้า"
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      onChange={props.onChange}
      value={props.value}
      required
      decimal={2}
    />
  )
}

export const ReceivableEmployee = (props) => {
  const inputClassName = "font-0-8"

  const lang = props.lang || 'local'
  const id = `customers_employees_id`
  const { customer } = props
  const employee = customerMemberValue({ customer, member: props.employee, lang })
  const employees = customer && customer.members.filter(member => member.type === 'employee')

  const item = (employee) => {
    employee = customerMemberValue({ customer, member: employee, lang })

    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td width="40" className="pd-0 text-left">
                <div className="width-30 height-30 line-height-30">
                  <AvatarIcon
                    bgColor="#efefef"
                    circle
                    iconClassName={`font-1-5 color-333`}
                    iconFa="fas fa-tree"
                  />
                </div>
              </td>
              <td className="pd-5 line-height-0">
                <div className="font-0-8">{ employee.newPlantationNameLang }</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  let avatarIcon, value

  if (employee) {
    value = employee.newPlantationNameLang

    avatarIcon = {
      iconFa: 'fas fa-tree',
      color: '#333',
      bgColor: '#fff',
    }
  }

  let disabled

  if (!customer || (customer && customer.useEmployee == false)) {
    disabled = true
  }

  let label = 'ชื่อแปลง'

  return (
    <InputSelectDropdown
      item={item}
      datas={employees}
      select={props.onChange}
      label={label}
      placeholder={label}
      id={id}
      disabled={disabled}
      dataSeleted={props.employee}
      value={value}
      type="text"
      chipValue={value}
      avatarIcon={avatarIcon}
      selectId="customers_employees_id"
      inputClassName={inputClassName}
      labelClassName="font-0-8"
      required={(customer && customer.useEmployee && !props.unrequired)}
    />
  )
}


export const ReceivableCustomerMember = (props) => {
  const inputClassName = "font-0-8"
  const labelClassName =" font-0-8"

  const lang = props.lang || 'local'
  const id = `customers_members_id`
  const { customer } = props
  let members = []

  const getMemberValue = ({ customer, member }) => {
    let name

    if (customer && member) {
      if (customer.usePartner === true) {
        if (member.type === 'partner') {
          name = `${member.nameLang} (หุ้นส่วน)`
        } else if (member.type === 'employee') {
          name = 'คนกรีด'
        }

      } else {
        if (member.owner === true) {
          name = 'เจ้าของ'
        } else {
          name = 'คนกรีด'
        }
      }
    }

    return name
  }


  if (customer) {
    const owner = customer.members.filter(member => member.owner === true)[0]

    // for partner
    if (customer.usePartner === true) {
      const partners = customer.members.filter(member => member.type === 'partner')

      if (customer.useEmployee === true) {
        members = partners
        props.employee && members.push(props.employee)
      } else {
        members = partners
      }

    // not partner
    } else {
      // employee
      if (customer.useEmployee === true) {
        members.push(owner)
        props.employee && members.push(props.employee)

      // not employee
      } else {
        members.push(owner)
      }
    }
  }

  const item = (data) => {
    const member = customerMemberValue({ customer, member: data, lang })
    const name = getMemberValue({ customer, member })

    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td width="40" className="pd-0 text-left">
                <div className="width-30 height-30 line-height-30">
                  <CustomerItemProfileAvater circle item={member} />
                </div>
              </td>
              <td className="pd-5 line-height-0">
                <div className="font-0-8">{ name }</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }



  const member = customer && props.member && customerMemberValue({ customer, member: props.member, lang })
  let avatarIcon, value

  if (customer && member) {
    value = getMemberValue({ customer, member })

    avatarIcon = {
      iconFa: member.typeIcon,
      color: '#333',
      bgColor: '#fff',
    }
  }

  let disabled

  if (props.customer) {
    if (props.customer.useEmployee === true) {
      if (!props.employee) {
        disabled = true
      }
    }
  } else {
    disabled = true
  }

  let label = 'ผู้เบิกเงิน'

  return (
    <InputSelectDropdown
      item={item}
      datas={members}
      select={props.onChange}
      label={label}
      placeholder={label}
      id={id}
      disabled={disabled}
      dataSeleted={member}
      value={value}
      type="text"
      chipValue={value}
      avatarIcon={avatarIcon}
      selectId="customers_members_id"
      inputClassName={inputClassName}
      labelClassName="font-0-8"
      required={!props.unrequired}
    />
  )
}

import { fetchDatas, fetchData, createData, updateData, removeData, fetchApi } from 'core/redux/reduxActions'
import { fetchDatasMany } from 'core/redux/reduxActionsV2'
import { generateTypeName, generateTypeFunctions } from 'core/redux/reduxTypesV2'

// Export Constants
const NAME_TYPE = ['ADMINS', 'ADMIN']
const name = "admins"

// Type Names and Type Functions
export const {
  ADDS, ADDS_MORE, ADD, UPDATE, REMOVE,
  ADDS_MANY, ADDS_MANY_MORE,
} = generateTypeName(NAME_TYPE)

export const {
  adds, adds_more, add, update, remove,
  adds_many, adds_many_more,
} = generateTypeFunctions(NAME_TYPE)


export const fetchQueryAdminOne = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return fetchData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}`,
    add: add
  })
}

export const fetchQueryAdminsMany = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return fetchDatasMany({
    dispatch,
    params,
    api: `${name}-many/${authName}/${businesses_id}`,
    adds_many,
    adds_many_more
  })
}

export const updateQueryOneAdmin = (query, params) => dispatch => {
  const { authName, businesses_id, _id } = query

  return updateData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/${_id}`,
    update: update,
    updateId: _id
  })
}

export const createQueryOneAdmin = (query, params) => dispatch => {
  const { authName, businesses_id } = query

  return createData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/create`,
    add: add
  })
}


// Export Actions
export const fetchAdmins = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return fetchDatas({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchAdmin = (businesses_id, _id, params = {}) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `${name}/${businesses_id}/${_id}`,
    add: add
  })
}


export const checkUserCreateAdmin = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return fetchApi({
    dispatch,
    params,
    api: `admins-query/${businesses_id}/check-user-create-admin`
  })
}

export const createAdmin = (query, params) => dispatch => {
  const { authName, businesses_id } = query

  return createData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/create`,
    add: add
  })
}

export const updateAdmin = (query, params) => dispatch => {
  const { authName, businesses_id, _id } = query

  return updateData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/${_id}`,
    update: update,
    updateId: _id
  })
}

export const removeAdmin = (query, params) => dispatch => {
  const { authName, businesses_id, _id } = query

  return removeData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/${_id}`,
    remove: remove,
    deleteId: _id
  })
}

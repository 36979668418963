import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

// components
import Form from '../../components/Form'
import BtnSubmit from '../../components/button/BtnSubmit'

// utility
import getFormValue from '../../util/getFormValue'

// actions redux
import { openField, closeField } from '../../redux/App/AppActions'
import { openConfirm, openModal } from '../../redux/App/AppActions'

class EditData extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    this.handleClose()
  }

  handleEdit = () => {
    if (this.props.modalId) {
      this.props.dispatch(openModal(this.props.modalId))
    } else {
      setTimeout(() => {
        this.props.dispatch(openField(this.props.name))
      }, 0)
    }

    setTimeout(() => {
      if (this.props.fnEdit) {
        this.props.fnEdit()
      }
    }, 0)
  }

  handleClose = () => {
    setTimeout(() => {
      this.props.dispatch(closeField(this.props.name))

      if (this.props.fnClose) {
        this.props.fnClose()
      }
    }, 0)
  }

  handleSubmit = (e, formName, id=null) => {
    e.preventDefault()
    const params = getFormValue(this.refs[formName])

    if (params) {
      this.props.submit(params, formName, id)
    }
  }

  handleDelete = () => {
    const { id, title, label, confirmLabel, onConfirm } = this.props.delete

    this.props.dispatch(openConfirm(id, {
      title: `${title}`,
      message: `กดปุ่ม "${label}" ถ้าคุณต้องการ${title}`,
      type: 'delete',
      icon: 'delete_forever',
      confirmLabel: confirmLabel || label,
      cancelLabel: 'ยกเลิก',
      onConfirm: onConfirm,
    }))
  }

  render() {
    const styleFooter = {
      textAlign: 'right',
      width: '100%',
      marginTop: '15px',
      ...this.props.styleFooter
    }

    const styleEditFooter = {
      textAlign: 'right',
      width: '100%',
      marginTop: '15px',
      ...this.props.styleEditFooter
    }

    const btnSize = this.props.btnSize ? this.props.btnSize : 'btn-xs'
    const { btnEdit, btnDelete } = this.props

    return (
      <div style={this.props.style}>
        { !this.props.field ?
          <div>
            <div style={{ margin: '5px 0 10px 0' }}>
              { this.props.children[0] }
            </div>

            { !this.props.hideBtnEdit &&
              <div style={styleFooter}>
                { this.props.fnCloseView &&
                  <a onClick={this.props.fnCloseView} className={`left btn ${btnSize} mg-right-10 waves-effect btn-flat btn-border waves-light`}>
                    <i className="material-icons left">close</i> ออก
                  </a>
                }

                { btnEdit ?
                  <div className={`${btnEdit.align}`}>
                    <a onClick={this.handleEdit} className={`btn ${btnSize} ${btnEdit.className} btn-shadow-none`}>
                      <i className={`material-icons ${btnEdit.label && 'left'}`}> { btnEdit.icon || 'mode_edit' }</i>
                      { btnEdit.label }
                    </a>
                  </div>
                :
                  <a onClick={this.handleEdit} className={`btn ${btnSize} btn-shadow-none`}>
                    <i className="material-icons left">mode_edit</i> แก้ไข
                  </a>
                }

                { this.props.delete &&
                  <Fragment>
                    { btnDelete ?
                      <a onClick={this.handleDelete} className={`btn ${btnSize} ${btnDelete.className} red darken-1 btn-shadow-none`}>
                        <i className={`material-icons ${btnDelete.label && 'left'}`}>{ btnDelete.icon || 'delete' }</i> { btnDelete.label }
                      </a>
                    :
                      <a onClick={this.handleDelete} className={`btn ${btnSize} mg-left-10 red darken-1 btn-shadow-none`}>
                        <i className="material-icons left">delete</i> ลบ
                      </a>
                    }
                  </Fragment>
                }
              </div>
            }
          </div>
        :
          <div>
            <Form
              formChild={this.props.formChild}
              recheckForm={this.props.recheckForm}
              dataChange={this.props.dataChange}
              ref={this.props.name}
              name={this.props.name}
              submit={(e) => this.handleSubmit(e, this.props.name, this.props.updateId)}>

              {this.props.children[1]}

              <div style={styleEditFooter}>
                <a onClick={this.handleClose} className={`btn ${btnSize} red darken-1 btn-shadow-none`}>
                  <i className="material-icons left">close</i> ยกเลิก
                </a>

                { this.props.btnSave ?
                  <BtnSubmit
                    icon="send"
                    size="xs"
                    iconAlign="left"
                    text={this.props.btnSave.label}
                    className={`btn ${btnSize} mg-left-10 light-green darken-1 btn-shadow-none`}
                    loadingId={this.props.name}
                    btnDisabled
                  />
                :
                  <BtnSubmit
                    icon="send"
                    size="xs"
                    iconAlign="left"
                    text="บันทึก"
                    className={`btn ${btnSize} mg-left-10 light-green darken-1 btn-shadow-none`}
                    loadingId={this.props.name}
                    btnDisabled
                  />
                }
              </div>
            </Form>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (store, props) => {
  return {
    field: store.app.fields.filter(field => field === props.name)[0]
  }
}

export default connect(mapStateToProps)(EditData)

import { lazy } from 'react'
import suspenseComponent from '../../../../../util/suspenseComponent'

// Containers
import BillsContainer from '../../../../../containers/dashboard/Bills/containers/BillsContainer'

// Components Pages
//const BillDetailsPage = lazy(() => import('../../../../../containers/dashboard/Bills/pages/BillDetailsPage'))

// Routes
import BillsMenuRoutes from './routes/BillsMenuRoutes'


const BillsRoutes = [
  {
    path: '/:username/bills',
    component: BillsContainer,
    routes: [
      /*{
        path: '/:username/bills/:type/:bills_id',
        exact: true,
        component: suspenseComponent(BillDetailsPage),
      },*/
      ...BillsMenuRoutes
    ]
  }
]

export default BillsRoutes

export const conversationChannelPlatforms = [
  { id: 'line-oa', color: '#008f18', icon: 'fa-brands fa-line', name: 'LINE Official Account' },
  { id: 'facebook-page', color: '#005eff', icon: 'fa-brands fa-facebook', name: 'Facebook Page' },
  { id: 'instagram', color: '#df1681', icon: 'fa-brands fa-instagram', name: 'Instagram' },
]

export const conversationChannelValue = ({ conversationChannel }) => {
  if (conversationChannel) {
    const { platformId, lineChannelId, line } = conversationChannel
    const platform = conversationPlatformValue({ platformId })

    let channelId, displayNameOrg, linkSocialProfile, channelVerify

    if (platformId === 'line-oa') {
      channelId = lineChannelId
      channelVerify = line && line.channelVerify || false

      if (line && line.channelInfo) {
        const channelInfo = line.channelInfo

        displayNameOrg = channelInfo && channelInfo.displayName
        linkSocialProfile = `https://manager.line.biz/account/${channelInfo && channelInfo.basicId}`
      }

    } else if (platformId === 'facebook-page') {

    } else if (platformId === 'instargram') {

    }

    return {
      ...conversationChannel,
      platform,
      channelId,
      displayNameOrg,
      linkSocialProfile,
      channelVerify
    }
  }
}

export const conversationPlatformValue = ({ platformId }) => {
  const platform = conversationChannelPlatforms.filter(platform => platform.id === platformId)[0]
  return platform
}

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// Actions Redux
import { openModal } from '../../redux/App/AppActions'

// css
import css from './css/breadcrumbs.css'


const Breadcrumbs = (props) => {
  const { items, click } = props

  return (
    <div className={`${css.boxBreadcrumb}`}>
      { click ?
        <Fragment>
          { items.map((breadcrumb, i) => {
            return (
              <a key={i} onClick={breadcrumb.onClick} className={css.breadcrumb}>
                <label className={css.label}>{breadcrumb.label}</label>
              </a>
            )
          }) }
        </Fragment>
      :
        <Fragment>
          { items.map((breadcrumb, i) => {
            return (
              <Fragment key={i}>
                { breadcrumb.url &&
                  <Link to={breadcrumb.url} className={css.breadcrumb}>
                    <label className={css.label}>{breadcrumb.label}</label>
                  </Link>
                }

                { breadcrumb.modalId &&
                  <a
                    onClick={() => props.dispatch(openModal(breadcrumb.modalId))}
                    className={css.breadcrumb}>

                    <label className={css.label}>{breadcrumb.label}</label>
                  </a>
                }
              </Fragment>

            )
          }) }
        </Fragment>
      }
    </div>
  )
}

export default connect()(Breadcrumbs)

import React, { Fragment, useRef, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

// Components Global
import Modal from '../../../../../../components/Modals/Modal'
import { Row, Column } from '../../../../../../components/Columns'

// Components forms
import Form from '../../../../../../components/Form'
import BtnSubmit from '../../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../../util/getFormValue'

// Components Local
import {
  ProductDate,
  ProductItems
} from '../RubberProductForms'

// Actions Redux
import { createProduct } from '../../../../../../redux/Products/ProductsActions'


const RubberProductCreateModal = (props) => {
  const id = "productCreateModal"
  const [data, setData] = useState('')
  const formRef = useRef()
  const { match, business, dispatch, products } = props
  const product = products[0]
  const date = product && product.date
  const dayPlus = date && moment(date, "YYYY-MM-DD").add(1, 'days')
  const nextDate = dayPlus && dayPlus.format("YYYY-MM-DD")

  const handleCodeChange = (data) => {
    setData(data)
  }

  const handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(formRef.current)

    if (params) {
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id
      }

      params.items = JSON.parse(params.items)

      dispatch(createProduct(query, {
        body: params,
        loadId: formName,
        modalId: formName,
        props,
        dataRedirect: {
          url: `/${match.params.username}/rubber-products/:_id`,
          resName: 'data'
        },
        toastMsg: 'เพิ่มราคาแล้ว'
      }))
    }
  }

  return (
    <Modal
      id={id}
      headerTitle="เพิ่มราคายาง"
      headerLeft
      width="450px"
      padding="0">

      <div className="pd-20">
        <Form
          recheckForm
          ref={formRef}
          name={id}
          dataChange={JSON.stringify(data)}
          submit={(e) => handleSubmit(e, id)}>

          <Row>
            <Column col="column col-xs-12">
              <ProductDate {...props} onChange={handleCodeChange} value={nextDate} />
            </Column>

            <Column col="column col-xs-12">
              <ProductItems {...props} />
            </Column>
          </Row>

          <div className="text-align-right">
            <BtnSubmit
              loadingId={id}
              icon="send"
              iconAlign="left"
              text="เพิ่มราคายาง"
              className="btn btn-submit btn-shadow-none height-35 line-height-35 font-0-9"
              btnDisabled
            />
          </div>
        </Form>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    products: state.products.data
  }
}

export default connect(mapStateToProps)(RubberProductCreateModal)

import React from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'


const ItemsList = (props) => {
  const { items, component, itemId, selectItem, componentHeaderList, tableClassName } = props
  const CompHeaderList = componentHeaderList
  const CompList = component

  return (
    <table className={`table-list ${tableClassName || ''}`}>

      { items.lenght !== 0 && CompHeaderList && <CompHeaderList /> }

      <ReactCSSTransitionGroup
        id="blockUnSelectItems"
        component="tbody"
        transitionName="example"
        transitionEnterTimeout={1000}
        transitionLeaveTimeout={1000}>

        { items && items.map((data, i) => {
          return (
            <CompList
              {...props}
              key={data._id}
              data={data}
              selectedId={itemId}
              onSelect={selectItem}
            />
          )
        }) }

      </ReactCSSTransitionGroup>
    </table>
  )
}

export default ItemsList

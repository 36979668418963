// Containers
import NotesContainer from '../../../../../containers/dashboard/Notes/containers/NotesContainer'
import NoteContainer from '../../../../../containers/dashboard/Notes/containers/NoteContainer'

// Routes
import NotesMenuRoutes from './routes/NotesMenuRoutes'
import NoteUpdateRoutes from './routes/NoteUpdateRoutes'


const NotesRoutes = [
  {
    path: '/:username/notes/:attributes_path',
    component: NotesContainer,
    routes: [
      {
        path: '/:username/notes/:attributes_path/:status(open|close)/:notes_id',
        component: NoteContainer,
        auth: { reqAuthBiz: true, reqExpDate: true },
        routes: [
          ...NoteUpdateRoutes,
        ]
      },
      ...NotesMenuRoutes
    ]
  }
]

export default NotesRoutes

export const customerValue = ({ customer, lang='local' }) => {
  let statusName, statusIcon, statusColor

  // gernaral
  if (customer) {
    let owner, nameLang, avatarName, addressLang, mobileFull, mobileShort

    nameLang = customer.name ? customer.name[lang] : null
    addressLang = customer.address ? customer.address[lang] : null
    mobileFull = customer.mobile && customer.mobile.number && `${customer.mobile.number}`
    mobileShort = customer.mobile && customer.mobile.number && `${customer.mobile.number}`

    if (customer.usePartner) {
      if (nameLang) {
        avatarName = nameLang.split(' ')

        if (avatarName.length > 2) {
          avatarName = [ avatarName[0], avatarName[1] ]
        }
      }

      owner = {
        ...customer,
        nameLang,
        addressLang,
        mobileFull,
        mobileShort,
        avatarName
      }

    } else {
      owner = customer.members.filter(member => member.owner === true)[0]


      if (owner) {
        owner = customerMemberValue({ customer, member: owner, lang })
      }
    }

    let employees = customer.members.filter(member => member.type === 'employee')


    // Status
    if (customer.status === 'open') {
      if (lang === 'local') statusName = 'เปิด'
      statusIcon = "public"
      statusColor = "success"

    } else if (customer.status === 'close') {
      if (lang === 'local') statusName = 'ปิด'
      statusIcon = "lock"
      statusColor = "warning"

    } else if (customer.status === 'trash') {
      if (lang === 'local') statusName = 'ถังขยะ'
      statusIcon = "delete"
      statusColor = "error"
    }

    let singleEmployee = false

    if (employees.length === 1) {
      singleEmployee = employees[0]
      singleEmployee = customerMemberValue({ customer, member: singleEmployee, lang })
    }

    let typeIcon

    if (customer.type === 'seller') {
      if (customer.usePartner) {
        typeIcon = 'fas fa-users'
      } else {
        if (customer.carTank) {
          typeIcon = 'fas fa-truck-moving'
        } else {
          typeIcon = 'fa-crown'
        }
      }
    } else if (customer.type === 'buyer') {
      typeIcon = 'fa-city'
    }


    return {
      nameLang,
      avatarName,
      ...owner,
      ...customer,
      owner,
      typeIcon,
      singleEmployee,
      statusName, statusIcon, statusColor
    }
  } else {
    return {}
  }
}


const getWithdrawMoneyName = (withdrawMoney, lang) => {
  if (withdrawMoney) {
    let { type, day, date } = withdrawMoney
    let typeName, dayName

    if (type === 'day') {
      typeName = 'วัน'
    } else if (type === 'week') {
      typeName = 'สัปดาห์'
    } else if (type === 'month') {
      typeName = 'เดือน'
    } else if (type === 'year') {
      typeName = 'ปี'
    }

    if (day === 'SUN') {
      dayName = 'อาทิตย์'
    } else if (day === 'MON') {
      dayName = 'จันทร์'
    } else if (day === 'TUE') {
      dayName = 'อังคาร'
    } else if (day === 'WED') {
      dayName = 'พุธ'
    } else if (day === 'THU') {
      dayName = 'พฤหัสบดี'
    } else if (day === 'FRI') {
      dayName = 'ศุกร์'
    } else if (day === 'SAT') {
      dayName = 'เสาร์'
    }

    return {
      typeName,
      dayName,
      date
    }
  }
}

export const customerMemberValue = ({ customer, member, employee, lang='local'}) => {
  let nameLang, plantationNameLang, newPlantationNameLang, ownerNameLang, addressLang, mobileFull, mobileShort, avatarName, typeNameLang, typeIcon, typeCode, typeNameShortLang, typeColor
  let employeeFullNameLang

  if (member) {
    nameLang = member.name ? member.name[lang] : null
    plantationNameLang = member.plantationName ? member.plantationName[lang] : null
    newPlantationNameLang = member.plantationName ? member.plantationName[lang] : null
    addressLang = member.address ? member.address[lang] : null
    mobileFull = member.mobile && member.mobile.number && `(${member.mobile.code}) ${member.mobile.number}`
    mobileShort = member.mobile && member.mobile.number && `${member.mobile.number}`

    member.withdrawMoneyName = getWithdrawMoneyName(member.withdrawMoney)

    if (customer) {
      if (member.type == 'employee') {
        let owner

        if (!customer.owner) {
          owner = customer.members.filter(member => member.owner === true)[0]
          owner = customerMemberValue({ customer, member: owner, lang})
        } else {
          owner = customer.owner
        }


        if (customer.usePartner) {
          ownerNameLang = customer.name ? customer.name[lang] : null
        } else {
          ownerNameLang = owner && owner.nameLang
        }

        if (plantationNameLang && !nameLang) {
          employeeFullNameLang = `ชื่อแปลง: ${plantationNameLang}`
        }

        if (!plantationNameLang && nameLang) {
          employeeFullNameLang = `ชื่อคนกรีด: ${nameLang}`
        }

        if (plantationNameLang && nameLang) {
          employeeFullNameLang = `ชื่อแปลง: ${plantationNameLang}, ชื่อคนกรีด: ${nameLang}`
        }

        if (!plantationNameLang && !nameLang) {
          employeeFullNameLang = `คนกรีด`
        }
      }
    }

    if (nameLang) {
      avatarName = nameLang.split(' ')

      if (avatarName.length > 2) {
        avatarName = [ avatarName[0], avatarName[1] ]
      }
    }

    if (member.type == 'partner') {
      if (member.percentPartner === 100 && member.owner) {
        typeIcon = 'fa-crown'
        typeCode = 'owner'
        typeNameLang = 'เจ้าของสวน'
        typeNameShortLang = "เจ้าของ"
        typeColor = ''

        if (customer.carTank) {
          typeIcon = 'fas fa-truck-moving'
          typeCode = 'car-tank'
          typeNameLang = 'เจ้าของรถ'
          typeNameShortLang = "เจ้าของรถ"
        }

      } else {
        typeCode = 'partner'
        typeNameLang = 'หุ้นส่วนเจ้าของสวน'
        typeNameShortLang = 'หุ้นส่วน'
        typeColor = ''
        typeIcon = 'fa-handshake'
      }
    } else if (member.type == 'employee') {
      typeCode = 'employee'
      typeNameLang = 'คนกรีดยาง'
      typeNameShortLang = 'คนกรีด'
      typeColor = ''
      typeIcon = 'fa-user'
    }

    let positionNameLang = `${typeNameLang}`
    newPlantationNameLang = newPlantationNameLang || ownerNameLang

    return {
      ...member,
      nameLang,
      plantationNameLang,
      newPlantationNameLang,
      ownerNameLang,
      addressLang,
      mobileFull,
      mobileShort,
      avatarName,
      typeCode,
      typeColor,
      typeIcon,
      typeNameLang,
      typeNameShortLang,
      positionNameLang,
      employeeFullNameLang
    }
  }
}

// Containers
import PlaceUpdateContainer from '../../../../../../containers/dashboard/Places/containers/PlaceUpdateContainer'

// Components Pages
import PlaceDescriptionsPage from '../../../../../../containers/dashboard/Places/pages/PlaceDescriptionsPage'
import PlacePhotosPage from '../../../../../../containers/dashboard/Places/pages/PlacePhotosPage'
import PlaceSitePage from '../../../../../../containers/dashboard/Places/pages/PlaceSitePage'
import PlaceSiteManagerPage from '../../../../../../containers/dashboard/Places/pages/PlaceSiteManagerPage'
import PlaceSiteManagerDisplayPage from '../../../../../../containers/dashboard/Places/pages/PlaceSiteManagerDisplayPage'

const PlaceUpdateRoutes = [
  {
    path: '/:username/places/:status/:places_id/update',
    component: PlaceUpdateContainer,
    routes: [
      {
        path: '/:username/places/:status/:places_id/update',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: PlaceDescriptionsPage
      },
      {
        path: '/:username/places/:status/:places_id/update/photos',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: PlacePhotosPage
      },
      {
        path: '/:username/places/:status/:places_id/update/site',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: PlaceSitePage
      },
      {
        path: '/:username/places/:status/:places_id/update/site-manage',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: PlaceSiteManagerPage
      },
      {
        path: '/:username/places/:status/:places_id/update/site-manage-display',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: PlaceSiteManagerDisplayPage
      }
    ]
  }
]

export default PlaceUpdateRoutes

import React, { Component } from 'react'
import { connect } from 'react-redux'
import cuid from 'cuid'

// css
import css from './css/inputSelectFetchData.css'

// Components Global
import InputSelectData from '../InputSelectData'
import Required from '../Required'


class InputSelectFetchData extends Component {
  constructor(props) {
    super(props)

    const id = this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()

    this.state = {
      id: randomId+id,
      value: ''
    }
  }

  componentDidMount() {
    this.setState({
      value: this.props.value || '',
    })

    this.fecthDatas()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value ? this.props.value : ''
      })
    }
  }

  fecthDatas = () => {
    const fetch = this.props.fetchAction

    if (fetch.authName) {
      this.props.dispatch(fetch.action(fetch.authName, fetch.id, {
        body: {
          where: fetch.where,
          sort: fetch.sort,
          countWhere: fetch.countWhere
        },
        loadId: `InputSelectFetchData-${this.state.id}`
      }))

    } else {
      this.props.dispatch(fetch.action(fetch.id, {
        body: {
          where: fetch.where,
          sort: fetch.sort,
          countWhere: fetch.countWhere
        },
        loadId: `InputSelectFetchData-${this.state.id}`
      }))

    }
  }

  getValue = (value) => {
    this.setState({ value }, () => {
      if (this.props.onChange) {
        this.props.onChange(value)
      }
    })
  }

  render() {
    const { attribute, search } = this.props
    const datas = []
    const datasFetch = this.props.datas || []

    datasFetch.map(data => {
      let label

      if (attribute.labelLang) {
        label = data[`${attribute.label}`][`${attribute.labelLang}`]
      } else {
        label = data[`${attribute.label}`]
      }

      datas.push({
        label,
        value: data[`${attribute.value}`],
        profileAvatar: {
          images: data.images,
          color: data.avatarColor,
          texts: [label]
        },
        obj: data
      })
    })

    return (
      <div className={css.inputPhoneNumber}>
        <Required
          id={this.state.id}
          label={this.props.label}
          type="text"
          value={this.state.value}
          required={this.props.required}
          iconValid={this.props.iconValid}>

          <div>
            <InputSelectData
              avatar
              showLabel
              id={`${this.props.id}`}
              classId={this.state.id}
              placeholder={this.props.placeholder}
              value={this.state.value}
              datas={datas}
              getValue={this.getValue}
              required={this.props.required}
              search={search}
            />

            <input type="hidden" data-id={`${this.props.id}`} value={this.state.value} data-not-save={this.props.notSave} />

          </div>
        </Required>
      </div>
    )
  }
}

const mapStateToProps = (store, props) => {
  const datas = store[props.storeName].data || []

  return {
    datas: datas
  }
}

export default connect(mapStateToProps)(InputSelectFetchData)

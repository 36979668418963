import React from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../routes/renderRoutes'

// Components Global
import Window from '../../../../components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../components/MenuSidebarResponsive'

// Components Bills
import BillCreateTypeModal from '../components/BillCreateTypeModal'

// imgs
import titleIcon from '../resources/imgs/bills-96.png'


const menuItems = (params) => {
  return [
    {
      name: 'ขาย',
      title: true
    },
    /*{
      name: 'ทั้งหมด',
      iconFa: 'fas fa-file-alt',
      link: `/${params.username}/bills/all/all`,
      searchLinkActive: `/${params.username}/bills/all/`
    },*/
    {
      name: 'ใบเสนอราคา',
      iconFa: 'fas fa-file-invoice',
      link: `/${params.username}/bills/quotation/all`,
      searchLinkActive: `/${params.username}/bills/quotation/`
    },
    {
      name: 'ใบวางบิล',
      iconFa: 'fas fa-file-alt',
      link: `/${params.username}/bills/invoice/all`,
      searchLinkActive: `/${params.username}/bills/invoice/`,
    },
    {
      name: 'ใบเสร็จรับเงิน',
      iconFa: 'fas fa-receipt',
      link: `/${params.username}/bills/receipt/all`,
      searchLinkActive: `/${params.username}/bills/receipt/`,
    },

    {
      name: 'ซื้อ',
      title: true
    },
    {
      name: 'ใบสั่งซื้อ',
      iconFa: 'fas fa-file-powerpoint',
      link: `/${params.username}/bills/purchase-order/all`,
      searchLinkActive: `/${params.username}/bills/purchase-order/`
    },
    {
      name: 'ใบรับสินค้า',
      iconFa: 'fas fa-file-download',
      link: `/${params.username}/bills/purchases/all`,
      searchLinkActive: `/${params.username}/bills/purchases/`
    },

    {
      name: 'ค่าใช้จ่าย',
      title: true
    },
    {
      name: 'ค่าใช้จ่าย',
      iconFa: 'fas fa-file-invoice-dollar',
      link: `/${params.username}/bills/expenses/all`,
      searchLinkActive: `/${params.username}/bills/expenses/`
    },
    {
      name: 'หัก ณ ที่จ่าย',
      iconFa: 'fas fa-file-contract',
      link: `/${params.username}/bills/withholding-tax/all`,
      searchLinkActive: `/${params.username}/bills/withholding-tax/`
    },

    /*{
      name: 'ใบเสร็จรับเงิน/ใบกำกับภาษี',
      icon: 'receipt',
      link: `/${params.username}/bills/tax-invoice`,
      exact: true,
    },*/
  ]
}

const BillsMenuContainer = (props) => {
  const { match, route, business, location, windowModal, closeWindowModal, history } = props

  return (
    <Window
      title="บัญชี"
      titleIcon={titleIcon}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkClose={`/${match.params.username}`}>

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive
            btnAddModal={{
              modalId: 'create-type-bill-modal',
              text: 'สร้างเอกสาร',
            }}
            items={menuItems(match.params)}
          />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business },
            location
          }) }
        </Layouts2>
      </LayoutsTwo>

      <BillCreateTypeModal {...props} id="create-type-bill-modal" />
    </Window>
  )
}

export default connect()(BillsMenuContainer)

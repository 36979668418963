import React from 'react'
import renderRoutes from '../../../../routes/renderRoutes'

// Components Global
import Window from '../../../../components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../components/MenuSidebarResponsive'

// Components Folders
import FoldersMenu from '../../Folders/components/FoldersMenu'
import TagsMenu from '../../Tags/components/TagsMenu'

// imgs
import titleIcon from '../../Businesses/resources/icons/articles.png'
import ArticleCreateModal from '../components/ArticleCreateModal'


const menuItems = (params) => {
  const { username, attributes_path } = params

  let path = `/${username}/articles`

  if (attributes_path) {
    path = `/${username}/articles-attr/${attributes_path}`
  }

  return [
    {
      name: 'บทความ',
      title: true
    },
    {
      name: 'ทั้งหมด',
      nameShort: 'ทั้งหมด',
      icon: 'public',
      link: `${path}/all/all`,
      searchLinkActive: `${path}/all`
    },
    {
      name: 'ปิดใช้งาน',
      nameShort: 'ปิดใช้งาน',
      icon: 'lock',
      link: `${path}/close/all`,
      searchLinkActive: `${path}/close`
    },
    {
      name: 'ที่ถูกลบ',
      nameShort: 'ลบแล้ว',
      icon: 'delete',
      link: `${path}/trash/all`,
      searchLinkActive: `${path}/trash`
    },

    //...FoldersMenu({ path }),
    ...TagsMenu({ path })
  ]
}

const ArticlesMenuContainer = (props) => {
  let { attribute, business, match, route, location, windowModal, closeWindowModal, history, lang } = props
  const { username, attributes_path } = match.params

  let title = "บทความ", avatar

  if (attribute && attributes_path) {
    title = attribute.nameLang
    avatar = {
      images: attribute.images,
      color: attribute.avatarColor,
      texts: attribute.avatarName
    }
  }


  return (
    <Window
      title={title}
      titleIcon={titleIcon}
      avatar={avatar}
      linkClose={`/${username}`}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      langSetting
      linkHelp="https://www.youtube.com/playlist?list=PLHuj62tDAxzP837HaBgfO75eL3TUbL95H">

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive
            btnAddModal={{
              modalId: 'articleCreateModal',
              text: `เพิ่ม${title}`,
            }}
            items={menuItems(props.match.params, attribute)}
          />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, attribute, lang },
            location,
          }) }
        </Layouts2>
      </LayoutsTwo>

      <ArticleCreateModal {...props} business={business} attribute={attribute} title={title} />
    </Window>
  )

}

export default ArticlesMenuContainer

import { lazy } from 'react'
import suspenseComponent from '../../../../../../util/suspenseComponent'

// Containers
import BillsMenuContainer from '../../../../../../containers/dashboard/Bills/containers/BillsMenuContainer'
import BillsListContainer from '../../../../../../containers/dashboard/Bills/containers/BillsListContainer'

// Components Pages
const BillManagePage = lazy(() => import('../../../../../../containers/dashboard/Bills/pages/BillManagePage'))

// NotFound
const NotFound = lazy(() => import('../../../../../../common/Pages/NotFound'))


const type = ':type(all|quotation|invoice|receipt|tax-invoice)'

const BillsMenuRoutes = [
  {
    path: '/:username/bills',
    component: BillsMenuContainer,
    routes: [
      {
        path: `/:username/bills/${type}/:bills_id`,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(BillsListContainer),
        routes: [
          {
            path: `/:username/bills/${type}/:bills_id`,
            exact: true,
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(BillManagePage)
          }
        ]
      },
      {
        component: suspenseComponent(NotFound)
      }
    ]
  }
]

export default BillsMenuRoutes

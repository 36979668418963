import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Helmet from 'react-helmet'


// Components Global
import { Box, Row, ButtonClose, Column } from '../../../components/UserInterface'
import ModalDefault from '../../../components/modal/ModalDefault'
import EditData from '../../../components/EditData'

// Component Local
import { Email } from './components/AuthForms'

// Import Actions
import { sendEmailConfirmAccount, signOut } from '../../../redux/Auth/AuthActions'

// Import styles
import styles from './css/ConfirmAccount.css'

// Import file
import readMailSuccess from './imgs/read-mail-success.png'


class ConfirmAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleSubmit = (params, formName) => {
    if (params) {
      this.props.dispatch(sendEmailConfirmAccount({
        body: params,
        loadId: formName,
        fieldId: formName,
        errorAlert: true,
        toastMsg: 'ส่งลิงก์ยืนยัน ไปยังอีเมลของคุณแล้ว'
      }))
    }
  }

  handleSignOut = () => {
    this.props.dispatch(signOut({
      loadId: 'signout',
      toastMsg: 'ออกจากระบบแล้ว',
      props: this.props,
      redirect: { url: '/signin' }
    }))
  }

  render() {

    console.log(this.props.auth)

    return (
      <div>
        <Helmet title="ยืนยันบัญชีของคุณ" />

        <ModalDefault showOnly={true} bgOverlay="#7cb342" width="470px">
          <Box padding="30px 20px" background="#fff">
            <ButtonClose click={this.handleSignOut} />

            <EditData
              v
              name="confirm-account"
              btnEdit={{ label: 'แก้ไขและส่งอีเมลใหม่', align: 'center' }}
              btnSave={{ label: 'บันทึกและส่งอีเมล' }}
              submit={this.handleSubmit}>

              <div>
                <div className="center">
                  <img src={readMailSuccess} />
                  <p className="mg-top-10">
                    เราได้ส่งลิงก์ยืนยันบัญชี ไปยังอีเมลของคุณแล้ว<br />โปรดตรวจสอบกล่องจดหมายของคุณ จากอีเมลด้านล่างนี้
                  </p>

                  <div className={styles.mail}>
                    <p>{this.props.auth.user.email}</p>
                  </div>

                  <p>ถ้าหากคุณยังไม่ได้รับอีเมลการยืนยันบัญชีจากเรา กรุณากด</p>
                </div>
              </div>

              <div>
                <p className="center mg-top-20 mg-bottom-50">
                  กรุณาตรวจสอบที่อยู่อีเมลให้ถูกต้องก่อนส่ง<br />เราจะส่งลิงก์การยืนยันบัญชี ไปยังอีเมลที่คุณกรอกจากด้านล่างนี้
                </p>

                <Email value={this.props.auth.user.email} />
              </div>
            </EditData>
          </Box>
        </ModalDefault>

      </div>
    )
  }
}

// Retrieve data from store as props
function mapStateToProps(state) {
  return {
    app: state.app,
    auth: state.auth
  }
}

export default withRouter(connect(mapStateToProps)(ConfirmAccount))

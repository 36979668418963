import React from 'react'
import Scrollbar from 'react-perfect-scrollbar'

// css
import css from './css/layoutsTwoResponsive.css'


export const LayoutSingle = (props) => {
  return (
    <div className="pst-absolute top-0 left-0 right-0 bottom-0">
      <Scrollbar>
        <div className="pst-absolute top-0 left-0 right-0 bottom-0">
          { props.children }
        </div>
      </Scrollbar>
    </div>
  )
}

export const LayoutsTwo = (props) => {
  return (
    <div className={css.layout} {...props}>
      {props.children}
    </div>
  )
}

export const Layouts1 = (props) => {
  return (
    <div className={`${css.layout1} ${css.DefaultLayout1} ${props.className}`} {...props} style={{ background: props.bgColor }}>
      { !props.hideScrollbars && !props.default &&
        <Scrollbar>
          {props.children}
        </Scrollbar>
      }

      { !props.hideScrollbars && props.default &&
        <div className="container-scroll">
          {props.children}
        </div>
      }

      { props.hideScrollbars && props.children }
    </div>
  )
}

export const Layouts2 = (props) => {
  return (
    <div className={`${css.layout2} ${css.DefaultLayout2} ${css.layout2Bottom} ${props.className}`}>
      { !props.hideScrollbars &&
        <Scrollbar>
          <div style={{ height: '100%' }}>{props.children}</div>
        </Scrollbar>
      }

      { props.hideScrollbars && props.children }
    </div>
  )
}

import React, { Fragment, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

// Components Global
import { Row, Column } from 'components/Columns'
import Modal from 'components/Modals/Modal'
import FormValidation from 'components/FormValidation'
import BtnSubmit from 'components/button/BtnSubmit'

// Components Admins
import { AdminCheckEmail, AdminFirstName, AdminLastName, AdminRole } from '../AdminForms'

// Util
import getFormValue from 'utils/getFormValue'

// Actions Redux
import { createQueryOneAdmin } from 'core/redux/Admins/AdminsActions'
import { createUser } from 'core/redux/Users/UsersActions'
import { UserPassword } from 'core/modules/Users/components/UserForms'


const AdminAndUserCreateModal = (props) => {
  const formRef = useRef()
  const [user, setUser] = useState()

  const id = "adminAndUserCreateModal"
  const { business, setting } = props

  useEffect(() => {
    return () => {
      setUser()
    }
  }, [])


  const handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(formRef.current)
    const query = {
      authName: 'admin',
      businesses_id: business._id
    }

    if (params) {
      if (params.scenario === 'create-admin') {
        props.dispatch(createQueryOneAdmin(query, {
          body: {
            create: {
              value: params
            },
          },
          loadId: formName,
          modalId: formName,
          toastMsg: 'เพิ่มผู้ดูแลระบบแล้ว'
        }))

      } else if (params.scenario === 'create-user-admin') {
        const userParams = {
          isAdmin: true,
          active: true,
          ...params,
        }

        delete userParams.role

        props.dispatch(createUser(query, {
          body: userParams,
          loadId: formName,
          modalId: formName,
          toastMsg: 'เพิ่มสมาชิกแล้ว'
        })).then(res => {
          if (res.success && res.data) {
            const user = res.data
            params.users_id = user._id

            props.dispatch(createQueryOneAdmin(query, {
              body: {
                create: {
                  value: params
                },
              },
              loadId: formName,
              modalId: formName,
              toastMsg: 'เพิ่มผู้ดูแลระบบแล้ว'
            }))
          }
        })
      }
    }
  }

  const handleChangeEmail = (res) => {
    if (res && res.user) {
      setUser(res.user)
    } else {
      setUser()
    }
  }

  return (
    <Modal
      id={id}
      headerTitle="เพิ่มผู้ดูแลระบบ"
      headerLeft
      fnStartModal={() => setUser()}
      width="500px">

      <FormValidation
        ref={formRef}
        name={id}
        dataChange={JSON.stringify(user)}
        submit={(e) => handleSubmit(e, id)}>

        <Row className="pd-20">
          <Column col="column col-xs-12">
            <AdminCheckEmail
              business={business}
              getFetchData={handleChangeEmail}
            />

            <input type="hidden" data-id="businesses_id" value={business._id} />

            <AdminFirstName value={user && user.firstName.local} />
            <AdminLastName value={user && user.lastName.local} />
            <AdminRole setting={setting} />

            { user ?
              <Fragment>
                <input type="hidden" data-id="users_id" value={user._id} />
                <input type="hidden" data-id="scenario" value="create-admin" />
              </Fragment>
            :
              <Fragment>
                <UserPassword required />
                <input type="hidden" data-id="scenario" value="create-user-admin" />
              </Fragment>
            }
          </Column>

          <Column className="column col-xs-12 text-right">
            <BtnSubmit
              loadingId={id}
              icon="send"
              iconAlign="left"
              text="เพิ่มผู้ดูแลระบบ"
              className="waves-effect waves-light btn btn-submit btn-shadow-none"
              btnDisabled
            />
          </Column>
        </Row>
      </FormValidation>
    </Modal>
  )
}

function mapStateToProps(store, props) {
  return {
    setting: store.settings.data[0],
  }
}

export default connect(mapStateToProps)(AdminAndUserCreateModal)

import { lazy } from 'react'
import suspenseComponent from '../../../../../../../util/suspenseComponent'

// Containers
import RubberProductsMenuContainer from '../../../../../../../containers/dashboard/SystemRubber/RubberProducts/containers/RubberProductsMenuContainer'

// Components
const ProductsListContainer = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberProducts/containers/RubberProductsListContainer'))
const RubberProductManagePage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberProducts/pages/RubberProductManagePage'))

const RubberProductsMenuRoutes = [
  {
    path: '/:username/rubber-products',
    component: RubberProductsMenuContainer,
    routes: [
      {
        path: '/:username/rubber-products/:products_id',
        component: suspenseComponent(ProductsListContainer),
        routes: [
          {
            path: '/:username/rubber-products/:products_id',
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(RubberProductManagePage)
          },
        ]
      },
    ]
  }
]

export default RubberProductsMenuRoutes

import React, { Component, Fragment } from 'react'

// Components Global
import EditFieldDefault from '../../../../../components/EditFieldDefault'
import RadioGroup from '../../../../../components/Inputs/RadioGroup'
import BtnSubmitJsx from '../../../../../components/button/BtnSubmitJsx'

// Actions
import { openField, closeField } from '../../../../../redux/App/AppActions'

// Actions Redux
import { updateOrder } from '../../../../../redux/Orders/OrdersActions'

// Values
import { businessShippingFeeValue } from '../../../../../redux/Businesses/BusinessesValues'
import { getPriceShippingFee } from '../../../../../redux/Carts/CartsValues'


class BusinessShippingSelected extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  openField = (id) => {
    this.props.dispatch(openField(id))
  }

  closeField = (id) => {
    this.props.dispatch(closeField(id))
  }

  selectShipping = (shipping_fees_id) => {
    this.setState({ shipping_fees_id })
  }

  handleSubmit = (id) => {
    const { business } = this.props
    const { shipping_fees_id } = this.state
    const shippingFee = business.shippingFees.filter(shipping => shipping._id === shipping_fees_id)[0]

    if (shippingFee) {
      this.submitSelectShipping({
        shippingFee: shippingFee,
        formName: id
      })
    } else {
      alert('เลือกการจัดส่ง !!!')
    }
  }


  submitSelectShipping = ({ shippingFee, formName }) => {
    const { business, order } = this.props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id,
      orders_id: order._id
    }

    if (shippingFee) {
      this.props.dispatch(updateOrder(query, {
        body: {
          scenario: 'updateShippingMethod',
          shippingMethod: shippingFee,
          priceShippingFee: getPriceShippingFee(order, shippingFee)
        },
        loadId: formName,
        fieldId: formName,
        toastMsg: 'เลือกการจัดส่งแล้ว'
      }))
    }
  }

  render() {
    const id = 'shipping'
    const lang = this.props.lang || 'local'
    const { order, business, disabled } = this.props
    const radios = []

    if (order && business) {
      const shippingFees = business.shippingFees

      shippingFees.map(shippingFee => {
        if (shippingFee) {
          shippingFee = businessShippingFeeValue({ shippingFee, lang })

          radios.push({
            value: shippingFee._id,
            label:  <div className="pd-bottom-10 dp-inline-block width-full border-bottom-efefef border-bottom-1" style={{ width: '100%' }}>
                      <ShippingFeeDetails order={order} shippingFee={shippingFee} />
                    </div>
          })
        }
      })
    }

    return (
      <EditFieldDefault name={id}>
        <div>
          <div className="border-bottom-efefef bg-fafafa pd-5 border-bottom-1">
            <table>
              <tbody>
                <tr>
                  <td className="pd-0">ช่องทางการจัดส่ง</td>
                  <td width="50" className="pd-0 text-right">
                    { order.shippingMethod && !disabled &&
                      <a onClick={() => this.openField(id)} className="btn-floating btn-sm btn-edit btn-shadow-none waves-effect waves-light">
                        <i className="material-icons i-middle">edit</i>
                      </a>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="pd-10">
            <Fragment>
              { (order.shippingFee && order.shippingFee._id) ?
                <ShippingFeeDetails order={order} shippingFee={order.shippingFee} />
              :
                <div className="center">
                  <div className="color-error">กรุณาเลือกการจัดส่ง</div>

                  <a
                    onClick={() => this.openField(id)}
                    className="btn btn-white btn-shadow-none mg-top-10">

                    เลือกการจัดส่ง
                  </a>
                </div>
              }
            </Fragment>
          </div>
        </div>

        <div>
          <div className="pd-5 bg-fafafa border-bottom-efefef border-bottom-1">
            <table>
              <tbody>
                <tr>
                  <td className="pd-5 pd-left-0">ช่องทางการจัดส่ง</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="pd-10">
            <div>
              <RadioGroup
                id="customers_addresses_id"
                notSave
                value={order.shippingFee && order.shippingFee._id}
                radios={radios}
                getValue={this.selectShipping}
                styleLabel={{ width: '100%' }}
              />
            </div>


            <div className="pd-top-10 text-right">
              <a
                onClick={() => this.closeField(id)}
                className="btn btn-white btn-shadow-none">
                  ยกเลิก
              </a>

              <BtnSubmitJsx
                loadingId={id}
                onClick={() => this.handleSubmit(id)}
                icon="send"
                iconAlign="left"
                text="บันทึก"
                className="btn btn-submit btn-shadow-none mg-left-10"
                btnDisabled
              />
            </div>

          </div>
        </div>

      </EditFieldDefault>
    )
  }
}

const ShippingFeeDetails = (props) => {
  const { order, shippingFee } = props

  let priceShippingFee, cashOnDelivery

  if (order) {
    cashOnDelivery = shippingFee.cashOnDelivery
    priceShippingFee = getPriceShippingFee(order, shippingFee)
  }

  return (
    <table>
      <tbody>
        <tr>
          <td className="pd-0">
            <Fragment>
              <b>{shippingFee.nameLang}</b>
              <div>- ระยะเวลาจัดส่ง 2-3 วัน</div>
            </Fragment>
          </td>
          <td width="60" className="pd-0 text-right">
            <div className="color-price">฿{priceShippingFee}</div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default BusinessShippingSelected

import React from 'react'

// Components Global
import Modal from '../../../../../../../components/Modals/Modal'
import FetchData from '../../../../../../../components/FetchData'
import { Column, Row } from '../../../../../../../components/Columns'
import DatasList from '../../../../../../../components/DatasList'
import BtnManager from '../../../../../../../components/BtnManager'

// Utils
import { getDate } from '../../../../../../../util/getDateTime/getDateTime'
import { closeModal } from '../../../../../../../redux/App/AppActions'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { getNumeral } from '../../../../../../../util/getNumeral'


const DraftOrdersListModal = (props) => {
  const { ordersDrafts, id, match } = props
  const { username, orders_drafts_id } = match.params

  const selectOrder = (orders_drafts_id) => {
    if (match.params.orders_drafts_id) {
      props.dispatch(closeModal(id))
    }

    props.history.push(`/${username}/pos/sales/${orders_drafts_id}`)
  }

  return (
    <Modal
      id={id}
      headerTitle="รายการบิลที่พักไว้"
      headerLeft
      width="1000px"
      padding="0px">

      <FetchData
        length={ordersDrafts.length}
        className="position-relative"
        style={{ position: 'relative' }}
        icon="shopping_basket"
        msg="ไม่มีรายการ">

        { ordersDrafts &&
          <div className="dp-inline-block width-full pd-15">
            <Row>
              <ReactCSSTransitionGroup
                transitionName="example"
                transitionEnterTimeout={1000}
                transitionLeaveTimeout={1000}>

                { ordersDrafts.map((order, i) => {
                  return (
                    <Column key={order._id} col="column col-xxs-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xlg-3 pd-5">
                      <div
                        className={`${orders_drafts_id ? '' : 'border-eee'} pd-10 border-radius-3 bg-fff`}
                        style={{
                          border: orders_drafts_id && orders_drafts_id === order._id ? '1px solid red' : orders_drafts_id ? '1px solid #efefef' : '0px solid #efefef'
                        }}>

                        <DatasList
                          style={{ border: '0' }}
                          items={[
                            {
                              label: 'หมายเลขบิล',
                              value: `${order.code}`,
                              icon: 'receipt'
                            },
                            {
                              label: 'วันที่เปิดบิล',
                              value: getDate(order.created, 'DD MMM YYYY, H:mm น.', 'th'),
                              icon: 'event'
                            },
                            {
                              label: 'ยอดชำระ',
                              value: `฿${getNumeral(order.totalNetAmount, 2)}`,
                              icon: 'account_balance_wallet'
                            }
                          ]}
                        />

                        <table>
                          <tbody>
                            <tr>
                              <td className="pd-0 pd-right-10">
                                <button onClick={() => selectOrder(order._id)} className="btn height-30 line-height-30 btn-submit btn-shadow-none width-full">
                                  <i className="material-icons right">keyboard_arrow_right</i>

                                  { orders_drafts_id ? 'เรียกบิลนี้' : 'ดำเนินการต่อ' }
                                </button>
                              </td>
                              <td className="pd-0 width-70">
                                <button onClick={() => props.handleDeleteOrder(order._id)} className="btn height-30 line-height-30 btn-delete btn-shadow-none width-full">
                                  <i className="material-icons left">delete</i> ลบ
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Column>
                  )
                }) }
              </ReactCSSTransitionGroup>
            </Row>
          </div>
        }

      </FetchData>
    </Modal>
  )
}

export default DraftOrdersListModal

import React, { Component } from 'react'

// Compoents Local
import SectionTitle from '../SectionTitle'

import { HeaderManage, HeaderDisplay } from '../../../../../Header'
import { HeaderFixedManage, HeaderFixedDisplay } from '../../../../../HeaderFixed'
import { SideNavbarManage, SideNavbarDisplay } from '../../../../../SideNavbar'


class HeaderFullManage extends Component {
  render() {
    const { business, setting, params, pages, site, pageCurrent, singlePageData, singlePageType } = this.props

    return (
      <div>
        <SectionTitle title="ส่วนหัว (Header)" mode="view">
          <HeaderDisplay
            {...this.props}
            mode="view"
            datas={{
              business,
              setting,
              site,
              pages,
              pageCurrent,
              singlePageData,
              singlePageType
            }}
          />

          <HeaderManage
            {...this.props}
            mode="edit"
            datas={{
              business,
              setting,
              site,
              pages,
              pageCurrent,
              singlePageData,
              singlePageType
            }}
          />
        </SectionTitle>

        <SectionTitle title="ส่วนหัวแบบคงที่ (Header Fixed)" mode="view">
          <HeaderFixedDisplay
            {...this.props}
            mode="view"
            datas={{
              business,
              setting,
              site,
              pages,
              pageCurrent,
              singlePageData,
              singlePageType
            }}
          />

          <HeaderFixedManage
            {...this.props}
            mode="edit"
            datas={{
              business,
              setting,
              site,
              pages,
              pageCurrent,
              singlePageData,
              singlePageType
            }}
          />
        </SectionTitle>

        { params.siteManage &&
          <div style={{ maxWidth: '600px', margin: '0 auto' }}>
            <SectionTitle title="เมนูด้านข้าง (Side Navbar)" mode="view">

              <SideNavbarDisplay
                {...this.props}
                mode="view"
                datas={{
                  business,
                  setting,
                  site,
                  pages,
                  pageCurrent
                }}
              />

              <SideNavbarManage
                {...this.props}
                mode="edit"
                datas={{
                  business,
                  setting,
                  site,
                  pages,
                  pageCurrent
                }}
              />
            </SectionTitle>
          </div>
        }
      </div>
    )
  }
}

export default HeaderFullManage

import React, { Fragment, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

// Componets Global
import { Row, Column } from '../../../../../../components/Columns'
import Modal from '../../../../../../components/Modals/Modal'
import FormValidation from '../../../../../../components/Form'
import BtnSubmit from '../../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../../util/getFormValue'

// Components Local
import PartnersCreateAndUpdate from '../PartnersCreateAndUpdate'
import {
  CustomerUsePartner,
  CustomerUseEmployee,
  CustomerName,
  CustomerMobile,
  CustomerAddress,
  CustomerMemberName,
  CustomerMemberMobile,
  CustomerMemberAddress,
} from '../RubberCustomerForms'
import { WithdrawMoney } from '../MemberForms'


// Actions Redux
import { createCustomer, updateCustomer } from '../../../../../../redux/Customers/CustomersActions'
import { closeModal, addLoad, removeLoad, toastShow } from '../../../../../../redux/App/AppActions'

// Values
import { customerValue } from '../../../../../../redux/RubberCustomers/RubberCustomersValue'



const RubberCustomerCreateAndUpdateModal = (props) => {
  const formRef = useRef()
  const [data, setData] = useState('')
  const [usePartner, setUsePartner] = useState(false)
  const [useEmployee, setUseEmployee] = useState(false)
  const [carTank, setCarTank] = useState(false)
  const [partners, setPartners] = useState([])
  const [employees, setEmployees] = useState([])
  const { id, business, dispatch, customer, event } = props
  let owner = customer && customer.members.filter(member => member.owner === true)[0]

  if (!owner) {
    owner =  customer && customer.members[0]
  }

  let title

  if (event === 'create') {
    title = 'เพิ่มเจ้าของสวน'
  } else {
    title = 'แก้ไขเจ้าของสวน'
  }

  const startModal = () => {
    if (customer) {
      setUsePartner(customer.usePartner)
      setUseEmployee(customer.useEmployee)
      setCarTank(customer.carTank)
    } else {
      setUsePartner(false)
      setUseEmployee(false)
      setCarTank(false)
    }
  }

  const updateMember = async (customer) => {
    const members = [...partners, ...employees]
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businessType: business.type,
      businesses_id: business._id,
      customers_id: customer._id
    }

    const results = await Promise.all(members.map(async (member) => {
      const newParams = member.value
      newParams.updateElement = 'members'

      // create
      if (member.event === 'create') {
        newParams.updateType = 'push'

      // update
      } else if (member.event === 'update') {
        newParams.updateType = 'set'
        newParams.updateId = newParams._id

      // delete
      } else if (member.event === 'delete') {
        newParams.updateType = 'pull'
        newParams.deleteId = newParams._id
      }

      if (member.event !== 'read') {
        return await props.dispatch(updateCustomer(query, { body: newParams }))
      } else {
        return null
      }
    }))

    props.dispatch(closeModal(props.id))
    props.dispatch(removeLoad(props.id))
    props.dispatch(toastShow(`${title}แล้ว`))

    // on select
    if (props.onSelect) {
      if (results.length === 0) {
        //customer = customerValue({ customer })
        props.onSelect(customer)

      } else {
        let res = results.filter(res => res && res.success === true)
        res = res[res.length - 1]

        if (res && res.success) {
          //customer = customerValue({ customer: res.data })
          props.onSelect(res.data)
        }
      }

    // normal
    } else {
      if (!props.notRedirect) {
        props.history.push(`/${business.username}/rubber-customer-sellers/${customer._id}`)
      }
    }
  }

  const handleSubmit = async (e, formName) => {
    e.preventDefault()
    const params = getFormValue(formRef.current)
    props.dispatch(addLoad(props.id))

    if (params) {
      if (params.usePartner === 'car-tank') {
        params.usePartner = 'false'
        params.useEmployee = 'false'
        params.carTank = true
      } else {
        params.carTank = false
      }

      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id,
        customers_id: customer && customer._id
      }

      // for partner
      if (usePartner) {

        // carete
        if (event === 'create') {
          dispatch(createCustomer(query, { body: params })).then(async res => {
            if (res.success) {
              await updateMember(res.data)
            }
          })

        // update
        } else if (event === 'update') {
          dispatch(updateCustomer(query, { body: params })).then(async res => {
            if (res.success) {
              await updateMember(res.data)
            }
          })
        }

      // for owner
      } else {

        // create
        if (event === 'create') {
          dispatch(createCustomer(query, { body: params })).then(async res => {
            if (res.success) {
              await updateMember(res.data)
            }
          })

        // update
        } else if (event === 'update') {

          // update customer
          const customer = await props.dispatch(updateCustomer(query, {
            body: { usePartner, useEmployee, carTank }
          }))

          // set owner
          if (customer.success && owner) {
            params.updateId = owner._id
            params.updateType = 'set'
            params.updateElement = 'members'
            params.owner = true

            props.dispatch(updateCustomer(query, { body: params })).then(async res => {
              if (res.success) {
                await updateMember(res.data)
              }
            })
          }
        }
      }
    }
  }

  const handleUsePartnerChange = (value) => {
    if (value === 'car-tank') {
      setUsePartner(false)
      setUseEmployee(false)
      setCarTank(true)
    } else {
      setUsePartner(value === 'true')
      setCarTank(false)
    }
  }

  const handleUseEmployeeChange = (value) => {
    setUseEmployee(value === 'true')
  }

  const handlePartnerChange = (partners) => {
    setPartners(partners)
  }

  const handleEmployeeChange = (employees) => {
    setEmployees(employees)
  }

  return (
    <Modal
      id={id}
      headerTitle={title}
      headerLeft
      fnStartModal={startModal}
      width="650px">

      <div className="pd-20">
        <FormValidation
          ref={formRef}
          name={props.id}
          recheckForm
          dataChange={{
            data: data && JSON.stringify(data),
            employees: employees && JSON.stringify(employees),
            partners: partners && JSON.stringify(partners)
          }}
          submit={(e) => handleSubmit(e, props.id)}>

          <Row>
            <Column col="column col-xxs-12 col-xs-6 col-sm-6 col-md-3 pd-right-10-xs pd-right-10-sm pd-right-10-md pd-right-10-lg pd-right-10-xlg">
              <CustomerUsePartner onChange={handleUsePartnerChange} value={usePartner} carTank={carTank} />
            </Column>

            { !carTank &&
              <Column col="column col-xxs-12 col-xs-6 col-sm-6 col-md-3 pd-left-10-xs pd-left-10-sm pd-left-10-md pd-left-10-lg pd-left-10-xlg">
                <CustomerUseEmployee onChange={handleUseEmployeeChange} value={useEmployee} />
              </Column>
            }

            <Column col="column col-xxs-12 col-xs-12 col-sm-12 col-md-6 pd-left-20-md pd-left-20-lg pd-left-20-xlg">
              { usePartner ?
                <CustomerName
                  event={event}
                  customer={customer}
                  value={customer && customer.name}
                  business={business}
                  onChange={(value) => setData(value)}
                />
              :
                <CustomerMemberName
                  label={carTank ? "ชื่อเจ้าของรถ" : "ชื่อเจ้าของสวน"}
                  event={event}
                  value={owner && owner.name}
                  business={business}
                  member={owner}
                  onChange={(value) => setData(value)}
                />
              }
            </Column>

            { usePartner ?
              <Fragment>
                <Column col="column col-xs-12 col-md-4">
                  <CustomerMobile value={customer && customer.mobile} />
                </Column>

                <Column col="column col-xs-12 col-md-8 pd-left-20-md pd-left-20-lg pd-left-20-xlg">
                  <CustomerAddress value={customer && customer.address} />
                </Column>
              </Fragment>
            :
              <Fragment>
                <Column col="column col-xxs-12">
                  <WithdrawMoney
                    event={event}
                    value={owner && owner.withdrawMoney}
                    labelType={carTank ? "เจ้าของรถ" : "เจ้าของสวน"}
                  />
                </Column>
                <Column col="column col-xs-12 col-md-4">
                  <CustomerMemberMobile event={event} value={owner && owner.mobile} />
                </Column>
                <Column col="column col-xs-12 col-md-8 pd-left-20-md pd-left-20-lg pd-left-20-xlg">
                  <CustomerMemberAddress event={event} value={owner && owner.address} />

                  { event === 'create' ?
                    <Fragment>
                      <input type="hidden" data-id="members[0].owner" value="true" />
                      <input type="hidden" data-id="members[0].type" value="partner" />
                      <input type="hidden" data-id="members[0].percentPartner" value="100" />
                    </Fragment>
                  :
                    <Fragment>
                      <input type="hidden" data-id="owner" value="true" />
                      <input type="hidden" data-id="type" value="partner" />
                      <input type="hidden" data-id="percentPartner" value="100" />
                    </Fragment>
                  }
                </Column>
              </Fragment>
            }
          </Row>


          { usePartner &&
            <Row>
              <Column col="column col-xxs-12 pd-top-20">
                <PartnersCreateAndUpdate
                  type="partner"
                  members={customer && customer.members}
                  onChange={handlePartnerChange}
                />
              </Column>
            </Row>
          }

          { useEmployee &&
            <Row>
              <Column col="column col-xxs-12 pd-top-20">
                <PartnersCreateAndUpdate
                  type="employee"
                  members={customer && customer.members}
                  onChange={handleEmployeeChange}
                />
              </Column>
            </Row>
          }

          <Row>
            <Column col="column col-xs-12 text-align-right">
              <BtnSubmit
                loadingId={props.id}
                icon="send"
                iconAlign="left"
                text={title}
                className="btn btn-success btn-shadow-none height-35 line-height-35"
                btnDisabled
              />
            </Column>
          </Row>
        </FormValidation>
      </div>
    </Modal>
  )
}

export default connect()(RubberCustomerCreateAndUpdateModal)


export const onSearchData = (_this, value, fetch, options) => {
  const { loadId, pageNumber, addMore, addsManyMore } = options

  const status = value !== '' ? true : false
  const body = { ...fetch.body }

  body.search.value = value
  body.pageNumber = pageNumber || 1

  const fecthParams = {
    body,
    store: fetch.store,
    loadId,
    addsManyMore,
    addMore: addMore || false,
    search: {
      status,
      value,
      params: body.search
    }
  }

  return _this.props.dispatch(fetch.action(fetch.query, fecthParams))
}


export const onSearchDataHook = (dispatch, value, fetch, options) => {
  const { loadId, pageNumber, addMore, addsManyMore } = options

  const status = value !== '' ? true : false
  const body = { ...fetch.body }

  body.search.value = value
  body.pageNumber = pageNumber || 1

  const fecthParams = {
    body,
    store: fetch.store,
    loadId,
    addsManyMore,
    addMore: addMore || false,
    search: {
      status,
      value,
      params: body.search
    }
  }

  return dispatch(fetch.action(fetch.query, fecthParams))
}

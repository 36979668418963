/**
 * Root Reducer
 */
import { combineReducers } from 'redux'

// Import Reducers
//import admins from './Admins/AdminsReducer'

// commons
import admins from 'core/redux/Admins/AdminsReducer'
import users from 'core/redux/Users/UsersReducer'
import conversationChannels from 'core/redux/ConversationChannels/ConversationChannelsReducer'
import conversations from 'core/redux/Conversations/ConversationsReducer'

import app from './App/AppReducer'
import articles from './Articles/ArticlesReducer'
import attributes from './Attributes/AttributesReducer'
import auth from './Auth/AuthReducer'

import bills from './Bills/BillsReducer'
import businesses from './Businesses/BusinessesReducer'

import carts from './Carts/CartsReducer'
import contracts from './Contracts/ContractsReducer'
import customers from './Customers/CustomersReducer'

import datas from './Datas/DatasReducer'
import deposits from './Deposits/DepositsReducer'

import folders from './Folders/FoldersReducer'

import geo from './Geo/GeoReducer'

import host from './Host/HostReducer'
import hotels from './Hotels/HotelsReducer'
import holdOrders from './HoldOrders/HoldOrdersReducer'

import intl from './Intl/IntlReducer'
import inventories from './Inventories/InventoriesReducer'

import makeOneDatas from './MakeOneDatas/MakeOneDatasReducer'
import makeTwoDatas from './MakeTwoDatas/MakeTwoDatasReducer'

import notes from './Notes/NotesReducer'

import orders from './Orders/OrdersReducer'
import ordersDrafts from './OrdersDrafts/OrdersDraftsReducer'

import packages from './Packages/PackagesReducer'
import pages from './Pages/PagesReducer'
import places from './Places/PlacesReducer'
import products from './Products/ProductsReducer'
import profile from './Profile/ProfileReducer'
import progresses from './Progresses/ProgressesReducer'
import purchases from './Purchases/PurchasesReducer'

import receivablePayments from './ReceivablePayments/ReceivablePaymentsReducer'
import receivables from './Receivables/ReceivablesReducer'
import reserveTours from './ReserveTours/ReserveToursReducer'

import sales from './Sales/SalesReducer'
import services from './Services/ServicesReducer'
import settings from './Settings/SettingsReducer'
import sites from './Sites/SitesReducer'

import tags from './Tags/TagsReducer'
import tours from './Tours/ToursReducer'

import vouchers from './Vouchers/VouchersReducer'


// Combine all reducers into one root reducer
export default combineReducers({
  admins,
  app,
  articles,
  attributes,
  auth,

  bills,
  businesses,

  customers,
  carts,
  contracts,

  conversationChannels,
  conversations,

  datas,
  deposits,

  folders,

  geo,

  host,
  hotels,
  holdOrders,

  intl,
  inventories,

  makeOneDatas,
  makeTwoDatas,

  notes,

  orders,
  ordersDrafts,

  products,
  places,
  pages,
  profile,
  progresses,
  packages,
  purchases,

  receivablePayments,
  receivables,
  reserveTours,

  sales,
  sites,
  services,

  settings,
  tours,
  tags,
  users,
  vouchers
})

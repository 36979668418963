import React, { Component } from 'react'

// Components Global
import FormValidation from '../../../../../components/FormValidation'
import BtnSubmit from '../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../util/getFormValue'

// Components Salse
import { ProductQty  } from '../OrderForms'
import ProductItemDescriptions from '../ProductItemDescriptions'


class ProductItemQtyForm extends Component {
  handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(this.refs[formName])

    if (params) {
      if (this.props.orderItem) {
        params.qty = params.qty - this.props.orderItem.qty
      }

      this.props.submit(params, formName)
    }
  }


  render() {
    const { orderItem, product, order, productItem } = this.props

    return (
      <div>
        <div>
          <ProductItemDescriptions product={product} order={order} productItem={productItem} />
        </div>

        <div className="pd-top-10 text-right">
          <FormValidation
            recheckForm
            ref={productItem._id}
            name={productItem._id}
            submit={(e) => this.handleSubmit(e, productItem._id)}>

            <table>
              <tbody>
                <tr>
                  <td className="pd-0 pd-right-10">
                    <ProductQty
                      product={product}
                      productItem={productItem}
                      value={{
                        qty: orderItem && orderItem.qty
                      }}
                    />
                  </td>
                  <td className="pd-0 text-right width-130">
                    <BtnSubmit
                      loadingId={productItem._id}
                      icon="check"
                      iconAlign="left"
                      text="หยิบใส่ตะกร้า"
                      className="btn-shadow-none waves-light btn btn-submit height-40 line-height-40 width-full font-0-9"
                      btnDisabled
                    />
                  </td>
                </tr>
              </tbody>
            </table>

          </FormValidation>
        </div>
      </div>
    )
  }
}

export default ProductItemQtyForm

import React from 'react'
import css from './css/window.css'
import { Link } from 'react-router-dom'


// components local
import WindowContainer from '../WindowContainer'
import ProfileAvatar from '../../ProfileAvatar'


const Window = (props) => {
  const { avatar, closeWindowModal, history } = props

  const closeWindow = () => {
    closeWindowModal()

    setTimeout(() => {
      history.push(props.linkClose)
    }, 800)
  }


  return (
    <WindowContainer id="window-container" className={`${props.className || ''}`} style={props.style}>
      <div className={`${css.header}`} style={props.style}>
        <div className={css.content}>
          <div className={css.titleIcon}>
            { avatar ?
              <ProfileAvatar
                images={avatar.images}
                imageSize="o"
                color={avatar.color}
                texts={avatar.texts}
                textSize="1.1rem"
              />
            :
              <img src={props.titleIcon} className="height-40" />
            }
          </div>
          <div className={css.title}>
            <span className="font-1-0">{props.title}</span>
          </div>

          { !props.hideBtnClose &&
            <div className={css.close}>

              { props.linkHelp &&
                <a href={props.linkHelp} target="_blank">
                  <i className="material-icons line-height-50 mg-left-20">help_outline</i>
                </a>
              }

              { closeWindowModal ?
                <a onClick={closeWindow} className={`btn pd-0 width-40 height-40 line-height-40 mg-5 red darken-1 ${css.btnClose}`}>
                  <i className="material-icons font-1-5">close</i>
                </a>
              :
                <Link to={props.linkClose} className={`btn pd-0 width-40 height-40 line-height-40 mg-5 red darken-1 ${css.btnClose}`}>
                  <i className="material-icons font-1-5">close</i>
                </Link>
              }
            </div>
          }

        </div>
      </div>

      <div className={css.children}>
        {props.children}
      </div>
    </WindowContainer>
  )
}

export default Window

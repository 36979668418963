import React from 'react'

// Components Global
import TextInput from '../../../../../../components/Inputs/TextInput'
import InputPhoneNumber from '../../../../../../components/Inputs/InputPhoneNumber'
import InputCheckDuplicate from '../../../../../../components/Inputs/InputCheckDuplicate'
import SelectDefault from '../../../../../../components/Inputs/SelectDefault'

// Actions
import { checkCustomerDuplicate } from '../../../../../../redux/Customers/CustomersActions'


export const CustomerUsePartner = (props) => {
  let value

  if (props.carTank) {
    value = 'car-tank'
  } else {
    value = props.value ? 'true' : 'false'
  }

  return (
    <SelectDefault
      required
      id="usePartner"
      label="ประเภท"
      value={value}
      onChange={props.onChange}
      options={[
        { name: 'เจ้าของคนเดียว', value: 'false' },
        { name: 'มีหุ้นส่วน', value: 'true' },
        { name: 'รถส่งน้ำยางแทงค์', value: 'car-tank' },
      ]}
    />
  )
}

export const CustomerUseEmployee = (props) => {
  return (
    <SelectDefault
      required
      id="useEmployee"
      label="คนกรีดยาง"
      value={props.value ? 'true' : 'false'}
      onChange={props.onChange}
      options={[
        { name: 'เจ้าของกรีดเอง', value: 'false' },
        { name: 'มีคนกรีดยาง', value: 'true' },
      ]}
    />
  )
}

export const CustomerCode = (props) => {
  const { business, value, customer, onChange } = props
  const query =`businesses_id=${business._id}`

  let unselectId

  if (customer && customer._id) {
    unselectId = customer._id
  }

  return (
    <InputCheckDuplicate
      id="code"
      label="รหัสลูกค้า"
      type="code"
      value={value}
      params_business_id={business._id}
      action={checkCustomerDuplicate}
      useQuery={{
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id
      }}
      query={query}
      unselectId={unselectId}
      onChange={onChange}
    />
  )
}


export const CustomerName = (props) => {
  const { business, customer, onChange } = props
  const lang = props.lang || 'local'
  const label = props.label || 'ชื่อกลุ่ม'
  const query =`businesses_id=${business._id}&type=seller`

  let unselectId

  if (customer && customer._id) {
    unselectId = customer._id
  }

  let id = `name.${lang}`
  let value = props.value ? props.value[lang] : ''

  if (props.event === 'create') {
    value = undefined
  }

  return (
    <InputCheckDuplicate
      id={id}
      element="name.local"
      label={label}
      type="text"
      value={value}
      params_business_id={business._id}
      action={checkCustomerDuplicate}
      useQuery={{
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id
      }}
      query={query}
      unselectId={unselectId}
      onChange={onChange}
      required
    />
  )
}

export const CustomerMemberName = (props) => {
  const { business, member, onChange } = props
  const lang = props.lang || 'local'

  const label = props.label || 'ชื่อ - นามสกุล'
  const query =`businesses_id=${business._id}&members.owner=true`

  let unselectId

  if (member && member._id) {
    unselectId = member._id
  }

  let id = `name.${lang}`
  let value = props.value ? props.value[lang] : ''

  if (props.event === 'create') {
    id = `members[0].${id}`
    value = undefined
  }

  return (
    <InputCheckDuplicate
      id={id}
      element="members.name.local"
      label={label}
      type="text"
      value={value}
      params_business_id={business._id}
      action={checkCustomerDuplicate}
      useQuery={{
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id
      }}
      query={query}
      unselectId={unselectId}
      onChange={onChange}
      required
    />
  )
}

export const CustomerMobile = (props) => {
  const id = `mobile`

  return (
    <InputPhoneNumber
     label="มือถือ"
     id={id}
     hiddenCode
     value={props.value}
     required={props.required}
     iconValid />
  )
}

export const CustomerAddress = (props) => {
  const lang = props.lang || 'local'
  const value = props.value ? props.value[lang] : ''
  const id = `address.${lang}`

  return (
    <TextInput
      label="ที่อยู่"
      placeholder="ที่อยู่"
      id={id}
      name={id}
      value={value}
      required={props.required}
      iconValid
    />
  )
}

export const CustomerMemberMobile = (props) => {
  let id = `mobile`

  if (props.event === 'create') {
    id = `members[0].${id}`
  }

  return (
    <InputPhoneNumber
     label="มือถือ"
     id={id}
     hiddenCode
     value={props.value}
     required={props.required}
     iconValid />
  )
}

export const CustomerMemberAddress = (props) => {
  const lang = props.lang || 'local'
  const value = props.value ? props.value[lang] : ''

  let id = `address.${lang}`

  if (props.event === 'create') {
    id = `members[0].${id}`
  }

  return (
    <TextInput
      label="ที่อยู่"
      placeholder="ที่อยู่"
      id={id}
      name={id}
      value={value}
      required={props.required}
      iconValid
    />
  )
}

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import cuid from 'cuid'
import config from '../../../config'

// css
import css from './css/inputPhoneNumber.css'

// Components Global
import InputSelect from '../InputSelect'
import Required from '../Required'

// Redux Actions
import { fetchCountries } from '../../../redux/Geo/GeoActions'

const CDN_URL = config.app.cdnUrl

class InputPhoneNumber extends Component {
  constructor(props) {
    super(props)

    const id = this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()

    this.state = {
      id: randomId+id,
      code: '',
      number: ''
    }
  }

  componentDidMount() {
    this.setState({
      code: this.props.value ? this.props.value.code : '+66',
      number: this.props.value ? this.props.value.number : ''
    })

    if (this.props.countries.length === 0) {
      this.props.dispatch(fetchCountries())
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        code: this.props.value ? this.props.value.code : '+66',
        number: this.props.value ? this.props.value.number : ''
      })
    }
  }

  onChangePhoneNumber = (e) => {
    let value = e.target.value.replace(/\(/g, '').replace(/\)/g, '').replace(/\-/g, '')
    let pattern = /^[0-9]+$/

    let number

    if (value === "") {
      this.setState({ number: "" })

      if (this.props.onChange) {
        this.props.onChange('')
      }

    } else if (pattern.test(value)) {
      this.setState({ number: value }, () => {
        const pattern = new RegExp("^[0-9]{" + 9 + ","+ 13 +"}$")

        if (this.props.onChange) {
          if (pattern.test(this.state.number)) {
            this.props.onChange({ code: this.state.code, number: this.state.number })
          } else {
            this.props.onChange('')
          }
        }
      })
    }
  }

  render() {
    const datas = []
    const countries = this.props.countries.filter(country => country.dialingCode)

    countries.map(country => {
      datas.push({
        img: `${CDN_URL}/common/imgs/icons/flags/${country.countryCode}.png`,
        label: country.countryNameTh,
        value: country.dialingCode
      })
    })

    return (
      <div className={css.inputPhoneNumber}>
        <Required
          id={this.state.id}
          label={this.props.label}
          type="phoneNumber"
          value={this.state.number}
          required={this.props.required}
          labelClassName={this.props.labelClassName}
          className={`${this.props.requiredClassName}`}
          notColorValid={this.props.notColorValid}
          iconValid={this.props.iconValid}>

          { !this.props.hiddenCode ?
            <table>
              <tbody>
                <tr>
                  <td className={css.code} >
                    <InputSelect
                      avatar
                      id={`${this.props.id}.code`}
                      placeholder="รหัสโทรศัพท์"
                      value={this.state.code}
                      className={`${this.props.inputClassName || ''}`}
                      datas={datas}
                    />
                  </td>
                  <td className={css.number}>
                    <input
                      id={this.state.id}
                      data-id={`${this.props.id}.number`}
                      type="text"
                      placeholder="เบอร์โทร"
                      className={`input input-jsx ${this.props.required ? 'required' : ''} ${this.props.inputClassName || ''}`}
                      onChange={this.onChangePhoneNumber}
                      value={this.state.number}
                      style={{ marginBottom: '0' }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          :
            <Fragment>
              <input data-not-save={this.props.notSave} data-id={`${this.props.id}.code`} type="hidden" defaultValue="+66" />

              <input
                id={this.state.id}
                data-id={`${this.props.id}.number`}
                data-not-save={this.props.notSave}
                type="text"
                placeholder="เบอร์โทร"
                className={`input input-jsx ${this.props.required ? 'required' : ''} ${this.props.inputClassName || ''}`}
                onChange={this.onChangePhoneNumber}
                value={this.state.number}
                style={{ marginBottom: '0' }}
              />
            </Fragment>
          }
        </Required>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    countries: state.geo.countries
  }
}

export default connect(mapStateToProps)(InputPhoneNumber)

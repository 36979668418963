import React from 'react'
import { Footer, ListNo } from '../Ui'

const OpenMessagingApi = (props) => {
  const { platform, footerComp } = props

  return (
    <div>
      <div>
        <ListNo no={1}>
          เปิด <a href="https://manager.line.biz" target="_blank">https://manager.line.biz</a>
        </ListNo>
        <ListNo no={2}>
          เลือกบัญชีและไปที่หน้าตั้งค่า
        </ListNo>
        <ListNo no={3}>
          เลือก Messaging API ในเมนูด้านซ้ายมือ
        </ListNo>
        <ListNo no={4}>
          กดปุ่ม "ใช้ Messaging API"
        </ListNo>
        <ListNo no={5}>
          สร้าง โพรไวเดอร์ (Provider) และตั้งชื่อตามความเหมาะสม
        </ListNo>
        <ListNo no={6}>
          เพิ่ม Privacy policy หรือ Term of Use (กดข้ามได้)
        </ListNo>
        <ListNo no={7}>
          ตรวจสอบข้อมูล เมื่อมั่นใจแล้วจึงกด ยอมรับ
        </ListNo>
      </div>
      <div>
        { props.useFooter &&
          <Footer {...props} next />
        }
      </div>
    </div>
  )
}

export default OpenMessagingApi

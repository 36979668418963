import React from 'react'


const AvatarIcon = (props) => {
  return (
    <div
      className={`center width-full height-full line-height-0 ${props.className} ${props.circle ? 'border-radius-circle' : ''}`}
      style={{ background: props.bgColor || '#efefef' }}>

      <div className="box-middle height-full">
        <div>
          { props.iconFa &&
            <i style={{ float: 'none', marginLeft: '0' }} className={`fas ${props.iconFa} ${props.iconClassName || 'font-1-5'} ${props.iconColor || 'color-333'}`}></i>
          }

          { props.icon &&
            <i style={{ float: 'none', marginLeft: '0' }} className={`material-icons ${props.iconClassName || 'font-1-5'} ${props.iconColor || 'color-333'}`}>{props.icon}</i>
          }
        </div>
      </div>
    </div>
  )
}

export default AvatarIcon

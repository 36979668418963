import React, { forwardRef, useImperativeHandle } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"


const DragDropMenu = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    moveUp(index) {
      onDragEnd({
        source: {
          index,
        },
        destination: {
          index: index-1
        }
      })
    },
    moveDown(index) {
      onDragEnd({
        source: {
          index,
        },
        destination: {
          index: index+1
        }
      })
    }
  }))

  const items = props.children

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)

      return result
    }

    const items = reorder(
      props.children,
      result.source.index,
      result.destination.index
    )

    const itemsOrg = props.items || []
    const reorderItems = []

    items.map(item => {
      const itemFilter = itemsOrg.filter(org => org._id === item.key)[0]

      if (itemFilter) {
        reorderItems.push(itemFilter)
      }
    })

    props.onChange(reorderItems)
  }


  // item style
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle
  })

  // container style
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : props.bg || '#ddd',
    padding: '5px 10px',
    width: '100%'
  })

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}>

            { items.map((item, index) => (
              <Draggable key={item.key} draggableId={item.key} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    { item }
                  </div>
                )}
              </Draggable>
            )) }

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
})

export default DragDropMenu

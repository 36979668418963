import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import Compressor from 'compressorjs'
import cuid from 'cuid'
import Required from '../Required'
import ImageCrop from '../../ImageCrop'

import { setStyleValue, getStyle } from '../../TextEditor/components/ElementStyles/components/js/setDivStyle'

// redux
import { openModal } from '../../../redux/App/AppActions'

const InputFile = (props) => {
  const { styleName, styleJsx, styleGlobal, inputForSite, required } = props
  const randomId = cuid() + props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
  const [ id ] = useState(randomId)
  const [ maxFile ] = useState(1)
  const [ maxSize ] = useState(12)
  const [ value, setValue ] = useState('')
  const [ file, setFile ] = useState('')
  const [ imgUrl, setImgUrl ] = useState('')
  const inputFile = useRef(null)

  useEffect(() => {
    if (props.value) {
      setValue(`file_${props.label}.JPG`)
      setImgUrl(props.value)
    }
  }, [props.value])

  const getFileSize = (bytes) => {
    let kb = bytes / 1024
    let mb = kb / 1024

    if (bytes < 1024) return `${bytes} B`
    if (kb < 1024) return `${kb.toFixed(1)} KB`
    if (kb >= 1024) return `${mb.toFixed(1)} MB`
  }

  const getFiles = (file, imgUrl) => {
    setValue(`${file.name} (${getFileSize(file.size)})`)
    setImgUrl(imgUrl)

    if (props.onChange) {
      props.onChange(imgUrl)
    }
  }

  const removeFile = () => {
    inputFile.current.value = ""
    setFile('')
    setValue('')
    setImgUrl('')

    if (props.onChange) {
      props.onChange('')
    }
  }

  const onChange = (e) => {
    const files = e.target.files

    if (files.length <= maxFile) {
      for (let i = 0; i < files.length; i++) {
        ((file) => {
          const reader = new FileReader()
          const maxSizeValue = 1024*1024*maxSize
          file = files[i]

          setFile(file)

          if (props.crop) {
            props.dispatch(openModal(id))
          } else {
            new Compressor(file, {
              quality: props.useBase64 ? 0.1 : 0.3,
              maxWidth: props.useBase64 ? 500 : 2500,
              maxHeight: props.useBase64 ? 500 : 2500,

              success: (result) => {
                const formData = new FormData()
                formData.append('file', result, result.name)

                reader.onload = (e) => {
                  if (file.type === "image/jpeg" || file.type === "image/png") {
                    if (file.size <= maxSizeValue.toFixed(0)) {
                      getFiles(result, reader.result)
                    } else {
                      alert(`ไม่สามารถอัพโหลด "${file.name}" เนื่องจาก ไฟล์ของคุณมีขนาดเกิน ${maxSizeValue.toFixed(1)} MB`)
                    }

                  } else {
                    alert(`ไม่สามารถอัพโหลด "${file.name}" เนื่องจาก ประเภทของไฟล์ไม่ถูกต้อง (รองรับเฉพาะ jpeg, png, jpg)`)
                  }
                }

                reader.readAsDataURL(result)
              },
              error: (err) => {
                console.log(err.message)
              }
            })
          }
        })(files[i])
      }
    } else {
      alert(`คุณสามารถอัพโหลดไฟล์ได้ไม่เกิน ${maxFile} ไฟล์`)
    }
  }

  const selectImg = () => {
    if (props.crop) {
      if (file) {
        props.dispatch(openModal(id))
      } else {
        inputFile.current.click()
      }
    } else {
      inputFile.current.click()
    }
  }

  const name = styleName
  const setEvent = (event, important=false) => {
    return (
      `
        ${getStyle('box-shadow', styleJsx[`${name}-${event}-box-shadow`], important)}
        ${getStyle('border-style', styleJsx[`${name}-${event}-border-style`], important)}
        ${getStyle('border-width', styleJsx[`${name}-${event}-border-width`], important)}
        ${getStyle('border-color', styleJsx[`${name}-${event}-border-color`], important)}
      `
    )
  }

  return (
    <div className={`${props.styleGlobal ? 'box-input-global-jsx' : ''}`}>
      <Required
        id={id}
        classId={id}
        label={props.label}
        type="text"
        value={imgUrl}
        required={required}
        iconValid={props.iconValid}>

        <div>
          <input
            style={{ display: 'none' }}
            ref={inputFile}
            data-not-save={true}
            accept="image/*"
            type="file"
            onChange={onChange}
          />

          { props.crop &&
            <ImageCrop
              id={id}
              crop={props.crop}
              style={{ width: '100%' }}
              file={file}
              getFiles={getFiles}
              removeFile={removeFile}
            />
          }

          <table>
            <tbody>
              <tr>
                { imgUrl ?
                  <td className="pd-0 width-50">
                    <img src={imgUrl} style={{ width: '45px', height: '45px', marginTop: '10px' }} />
                  </td>
                : null}

                <td className="pd-0">
                  <input
                    id={id}
                    data-not-save={true}
                    className={`${inputForSite ? 'input-site' : 'input'} ${props.inputClassName} ${styleGlobal ? ' input-global-jsx' : ''} input-jsx ${required ? 'required' : ''}`}
                    placeholder={props.placeholder}
                    onClick={selectImg}
                    value={value || ''}
                  />
                </td>


                <td className="pd-0 width-40">
                  { imgUrl ?
                    <a className="btn-floating btn-flat" onClick={removeFile}>
                      <i className="material-icons black-text">close</i>
                    </a>
                  :
                    <a className="btn-floating btn-flat" onClick={selectImg}>
                      <i className="material-icons black-text">photo</i>
                    </a>
                  }
                </td>
              </tr>
            </tbody>
          </table>

          { props.useBase64 ?
            <input
              type="hidden"
              name="img-file-base-64"
              value={imgUrl || ''}
              data-id={props.id}
              data-label={props.useLabel && props.label}
              data-type="text"
              data-use-attr={props.useAttr}
            />
          :
            <input
              type="hidden"
              name="img-file"
              data-file-type={file && file.type}
              data-file-prefix={props.prefix}
              value={imgUrl || ''}
              data-id={props.id}
              data-label={props.useLabel && props.label}
              data-type="text"
              data-use-attr={props.useAttr}
            />
          }

          <style jsx>{`
            .input-jsx {
              ${styleJsx && setStyleValue({ name, style: styleJsx })}

              outline: none;
              -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                      box-sizing: border-box;
            }
            .input-jsx:focus:not([readonly]) {
              ${styleJsx && setEvent('focus')}
            }
            .input-jsx.valid,
            .input-jsx:focus.valid {
              ${styleJsx && setEvent('valid', true)}
            }
            .input-jsx.invalid,
            .input-jsx:focus.invalid {
              ${styleJsx && setEvent('invalid', true)}
            }

            @media (min-width: 1500px) {
              .input-jsx {
                ${styleJsx && setStyleValue({ name, style: styleJsx, vw: true })}
              }
              .input-jsx:focus:not([readonly]) {
                ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-focus-box-shadow-vw`])}
                ${styleJsx && getStyle('border-width', styleJsx[`${name}-focus-border-width-vw`])}
              }
              .input-jsx.valid {
                ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-valid-box-shadow-vw`], true)}
                ${styleJsx && getStyle('border-width', styleJsx[`${name}-valid-border-width-vw`], true)}
              }
              .input-jsx.invalid {
                ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-invalid-box-shadow-vw`], true)}
                ${styleJsx && getStyle('border-width', styleJsx[`${name}-invalid-border-width-vw`], true)}
              }
            }

            @media (min-width: 601px) and (max-width: 1024px) {
              .input-jsx {
                ${styleJsx && setStyleValue({ device: 'tablet', name, style: styleJsx })}
              }
            }

            @media (min-width: 50px) and (max-width: 600px) {
              .input-jsx {
                ${styleJsx && setStyleValue({ device: 'mobile', name, style: styleJsx })}
              }
            }
          `}</style>
        </div>
      </Required>
    </div>
  )
}


export default connect()(InputFile)

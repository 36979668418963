
import React, { Fragment, useEffect, useState } from 'react'
import { openModal } from '../../../redux/App/AppActions'
import { GoogleMapGeo, GoogleMapGeoDisplay } from '../../GoogleMapGeo'
import TextInput from '../TextInput'
import ModalFixedDefault from '../../Modals/ModalFixedDefault'


const InputGoogleMapGeo = (props) => {
  const [location, setLocation] = useState()

  const handleSelectGeoLocation = (geo, location) => {
    const address = geo.address
    const new_geo = {
      d: address.district, a: address.amphoe,  p: address.province, z: address.zipcode
    }

    setLocation(location)

    if (props.onChange) {
      props.onChange(new_geo)
    }
  }

  const openGoogleGeo = () => {
    props.dispatch(openModal(`${props.modalId}`))
  }

  const getParamsMap = (location) => {
    let params  = ''

    if (location) {
      for (let key in location) {
        params = `${params}${location[key]},`
      }
    }

    return params.slice(0, -1)
  }

  useEffect(() => {
    if (props.value) {
      setLocation(props.value)
    } else {
      //setTimeout(() =>  openGoogleGeo(), 500)
    }
  }, [])

  return (
    <Fragment>
      <div className="height-200 width-full mg-bottom-20 pst-relative">
        <Fragment>
          { (location && location.lat && location.lng) ?
            <div className="width-full height-full">
              <GoogleMapGeoDisplay
                zoom={17}
                location={location}
                btn={{
                  edit: openGoogleGeo
                }}
              />
            </div>
          :
            <div className="border-efefef center box-middle height-full">
              <div>
                <a onClick={openGoogleGeo} className="btn btn-shadow-none pd-left-15 pd-right-20 border-radius-20 btn-white height-40 line-height-40">
                  <i className="material-icons left">my_location</i> เลือกที่อยู่จากแผนที่
                </a>
              </div>
            </div>
          }
        </Fragment>
      </div>

      <div style={{ visibility: 'hidden', height: '0' }}>
        <TextInput
          label={'ละติจูด'}
          placeholder="ละติจูด"
          id="location.lat"
          value={location && location.lat}
          type="text"
          required={props.required}
        />

        <TextInput
          label={'ลองจิจูด'}
          placeholder="ลองจิจูด"
          id="location.lng"
          value={location && location.lng}
          type="text"
          required={props.required}
        />
      </div>

      <ModalFixedDefault
        id={props.modalId}
        width="100%"
        className="pd-0">

        <GoogleMapGeo {...props} onChange={handleSelectGeoLocation} location={location}  />
      </ModalFixedDefault>
    </Fragment>
  )
}

export default InputGoogleMapGeo

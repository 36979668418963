import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { getNumeralFn } from '../../../../../util/getNumeral'
import KeyboardNumber from '../KeyboardNumber'


const KeyboardManage = forwardRef((props, ref) => {
  const [name, setName] = useState()
  const [value, setValue] = useState()
  const [valueFloat, setValueFloat] = useState()
  const [inputs, setInputs] = useState([])

  useImperativeHandle(ref, () => ({
    getValue(name) {
      return getValue(name)
    },
    getValues(name) {
      return getValues(name)
    },
    focus(name) {
      return focus(name)
    },
    handleFocus(name) {
      return handleFocus(name)
    },
    setInputValue(name, value) {
      return setInputValue(name, value)
    },
    setInputElement(name, element, value) {
      return setInputElement(name, element, value)
    }
  }))

  useEffect(() => {
    setInputs(props.inputs)
    setName(props.startFocus)
  }, [])


  const getValue = (name) => {
    const input = inputs.filter(input => input.name === name)[0]
    return input && input.value
  }

  const getValues = (name) => {
    const input = inputs.filter(input => input.name === name)[0]

    if (input) {
      return {
        value: input.value,
        valueFormat: input.valueFormat
      }
    }
  }

  const setInputValue = (name, value) => {
    const newInput = [...inputs]
    const index = inputs.findIndex(input => input.name === name)

    if (index > -1) {
      const valueOfType = setValueOfType(newInput[index], value)
      newInput[index].value = valueOfType.value
      newInput[index].valueFormat = valueOfType.valueFormat
      setInputs(newInput)
    }
  }

  const setInputElement = (name, element, value) => {
    const newInput = [...inputs]
    const index = inputs.findIndex(input => input.name === name)

    if (index > -1) {
      newInput[index][`${element}`] = value
      setInputs(newInput)
    }
  }

  const focus = (name) => {
    const input = inputs.filter(input => input.name === name)[0]
    return input && input.focus
  }

  const handleFocus = (name) => {
    const newInput = [...inputs]
    const index = inputs.findIndex(input => input.name === name)

    setName(name)

    newInput.map((input, i) => {
      if (index === i) {
        input.focus = true
        setValue(input.value)
        setValueFloat(input.value)
      } else {
        input.focus = false
      }
    })

    setInputs(newInput)
  }

  const handleKeyboardChange = (value) => {
    const newInput = [...inputs]
    const index = inputs.findIndex(input => input.name === name)

    if (index > -1) {
      const valueOfType = setValueOfType(newInput[index], value)
      newInput[index].value = valueOfType.value
      newInput[index].valueFormat = valueOfType.valueFormat
      setValue(valueOfType.valueFormat)
      setValueFloat(valueOfType.value)
    }

    setInputs(newInput)
  }

  const setValueOfType = (input, value) => {
    const { type, minValue, maxValue, useSpace, maxDigit } = input
    const valueOrg = value
    let valueFormat

    if (type === 'number') {
      const format = getNumeralFn({
        value: valueOrg,
        decimal: 2,
        decimalFull: true,
        convert: false,
        maxDigit,
        minValue,
        maxValue,
      })

      value = format && format.value
      valueFormat = format && format.valueFormat

      if (isNaN(value)) {
        if (useSpace === true) {
          value = ''
          valueFormat = ''
        } else {
          value = 0
          valueFormat = '0'
        }
      }
    } else if (type === 'string') {
      valueFormat = value
    }

    return { value, valueFormat }
  }

  useEffect(() => {
    props.handleKeyboardChange(inputs)
    const values = getValues(name)

    if (values) {
      setValue(values.valueFormat)
      setValueFloat(values.value)
    }

  }, [name, JSON.stringify(inputs)])


  return (
    <KeyboardNumber
      use={props.use}
      useCash={props.useCash}
      onChange={handleKeyboardChange}
      value={value}
      valueFloat={valueFloat}
    />
  )
})

export default KeyboardManage

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Components Global
import { Row, Column } from '../Columns'
import Pagination from '../Pagination'
import InputSearch from '../InputSearch'
import FetchData from '../FetchData'
import PreLoading from '../PreLoading'
import ItemSelected from '../ItemSelected'
import { BtnModalOpen } from '../Modals/BtnModal'
import DropdownDefault from '../DropdownDefaultV2'

// css
import css from './css/pageItems.css'
import UseSelector from './components/UseSelector'


class PageItems extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getItemsList = () => {
    let items

    if (this.props.items && this.props.searchData && this.props.searchData.status) {
      items = this.props.searchData.data
    } else {
      items = this.props.items
    }

    return items
  }

  render() {
    const { columnTitle, useFilter, foldersManage, layoutFull, match, btnAddMore, addItemModal, title, icon, fetchAction, componentTitle, componentHeader, modal, btnAdd } = this.props
    const CompTitle = componentTitle
    const CompHeader = componentHeader
    const CompForm = useFilter && useFilter.compForm
    const items = this.getItemsList()
    const { column1, column2 } = columnTitle || {}
    const columnTitle1 = column1 || 'column col-xxs-12 col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xlg-7'
    const columnTitle2 = column2 || 'column col-xxs-12 col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xlg-5'

    let btnModal

    if (modal && btnAdd) {
      btnModal = (
        <BtnModalOpen id={modal.id}>
          <a className={`mg-bottom-10 mg-top--15 waves-effect waves-light btn btn-shadow-none ${modal.className}`}>
            <i className="material-icons left">add</i> {modal.text}
          </a>
        </BtnModalOpen>
      )
    }

    return (
      <div className={`${this.props.className}`}>

        { (btnAddMore && this.props.items.length !== 0) &&
          <div className={css.boxBtnAdd}>
            <BtnModalOpen id={addItemModal.id}>
              <a className="btn-floating btn-large waves-effect waves-light">
                <i className="material-icons font-2-0">add</i>
              </a>
            </BtnModalOpen>
          </div>
        }

        { !this.props.hiddenHeader &&
          <Fragment>
            { !foldersManage &&
              <Row className="pd-bottom-10">

                { !this.props.hiddenTitle &&
                  <Column col={columnTitle1}>
                    <div className="pd-right-10 pd-top-3">
                      <span className="font-1-4">{title}</span>
                    </div>
                  </Column>
                }

                { !this.props.hiddenSearch &&
                  <Column col={columnTitle2}>
                    <table>
                      <tbody>
                        <tr>
                          { this.props.useSelector &&
                            <td className="pd-0 width-50 text-left">
                              <UseSelector {...this.props} />
                            </td>
                          }

                          <td className="pd-0">
                            <InputSearch
                              {...this.props}
                              id={`search-${this.props.id}`}
                              placeholder={`ค้นหา`}
                              getValueFromUrl
                              fetchAction={fetchAction}
                              hiddenSearchCamera={this.props.hiddenSearchCamera}
                            />
                          </td>

                          { this.props.useFilter &&
                            <td className="pd-0 width-50 text-right">
                              <DropdownDefault
                                right
                                className="width-full"
                                contentClassName="min-width-250 mg-bottom-50"
                                dropdownClassName="border-radius-5">

                                <a
                                  className="btn pd-0 btn-mini width-40 height-40 line-height-40 btn-shadow-none border-radius-3">

                                  <i className="material-icons font-1-5">filter_list</i>
                                </a>

                                <div className="text-left">
                                  <div className="bg-fafafa height-35 line-height-35 pd-left-10 font-0-8 border-bottom- border-bottom-eee border-top-radius-3">กรองข้อมูล</div>
                                  { this.props.useFilter.compForm}
                                </div>
                              </DropdownDefault>
                            </td>
                          }
                        </tr>
                      </tbody>
                    </table>

                    { this.props.useFilter && this.props.useFilter.compLists }
                  </Column>
                }
              </Row>
            }

            { componentHeader && <CompHeader /> }
          </Fragment>
        }

        { foldersManage &&
          <div style={{ borderBottom: '1px solid #e6e6e6', marginBottom: '5px' }}>
            <Row className="pd-bottom-10">
              <Column col="column col-xxs-12 col-xs-6">
                <div className="pd-right-10 pd-top-3">
                  <span>{title}</span>
                </div>
              </Column>
              <Column col="column col-xxs-12 col-xs-6 text-right">
                <BtnModalOpen id={addItemModal.id}>
                  <button className="waves-effect btn-shadow-none waves-light btn btn-xs pd-right-10">
                    <i className="material-icons left">add</i> {addItemModal.name}
                  </button>
                </BtnModalOpen>
              </Column>
            </Row>
          </div>
        }

        { fetchAction ?
          <Pagination
            id={this.props.id}
            history={this.props.history}
            location={this.props.location}
            fetchAction={fetchAction}
            itemsData={this.props.items}
            searchData={this.props.searchData}
            itemsPerPage={fetchAction.limit}
            match={match}
            foldersManage={foldersManage}
            showPage={5}
            hiddenPaginationNumber={this.props.hiddenPaginationNumber}
            notReloadForUrlChange={this.props.notReloadForUrlChange}
            dataReloadForUrlChange={this.props.dataReloadForUrlChange}
            notReloadForUrlSearchChange={this.props.notReloadForUrlSearchChange}
            currentPageName={this.props.currentPageName}
            onRef={this.props.onRef}>

            <PreLoading
              id={this.props.id}
              className="pd-top-100"
              style={{ position: (foldersManage || layoutFull) && 'relative' }}>

              <FetchData
                length={items.length}
                textClassName={foldersManage && 'font-1-0'}
                style={{ position: (this.props.items.length !== 0 || foldersManage || layoutFull) && 'relative' }}
                icon={!foldersManage && icon}
                className="pd-top-100"
                componentItemsNone={this.props.componentItemsNone}
                modal={!foldersManage && addItemModal}
                msg={`ไม่มี${title}`}>

                  { componentTitle &&
                    <CompTitle />
                  }

                  { this.props.unSelect ?
                    <ItemSelected location={this.props.location} unSelect={this.props.unSelect}>
                      <div>
                        <div className="text-right">
                          {btnModal}
                        </div>

                        { React.cloneElement(this.props.children, { items }) }
                      </div>
                    </ItemSelected>
                  :
                    <div>
                      <div className="text-right">
                        {btnModal}
                      </div>

                      { React.cloneElement(this.props.children, { items })}
                    </div>
                  }
              </FetchData>
            </PreLoading>
          </Pagination>
        :
          <PreLoading
            id={this.props.id}
            className="pd-top-100"
            style={{ position: (foldersManage || layoutFull) && 'relative' }}>

            <FetchData
              length={items.length}
              textClassName={foldersManage && 'font-1-0'}
              style={{ position: (this.props.items.length !== 0 || foldersManage || layoutFull) && 'relative' }}
              icon={!foldersManage && icon}
              className="pd-top-100"
              modal={!foldersManage && addItemModal}
              componentItemsNone={this.props.componentItemsNone}
              msg={`ไม่มี${title}`}>

                { componentTitle &&
                  <CompTitle />
                }

                { this.props.unSelect ?
                  <ItemSelected location={this.props.location} unSelect={this.props.unSelect}>
                    <div>
                      <div className="text-right">
                        {btnModal}
                      </div>

                      { React.cloneElement(this.props.children, { items }) }
                    </div>
                  </ItemSelected>
                :
                  <div>
                    <div className="text-right">
                      {btnModal}
                    </div>

                    { React.cloneElement(this.props.children, { items })}
                  </div>
                }
            </FetchData>
          </PreLoading>
        }
      </div>
    )
  }
}

PageItems.propTypes = {
  children: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default connect()(PageItems)

import React, { Fragment } from 'react'
import { connect } from 'react-redux'

// Components Customers
import CustomerAddressSelected from '../../../../Customers/components/CustomerAddressSelected'
import BusinessShippingSelected from '../../../../Businesses/components/BusinessShippingSelected'


const ShippingManager = (props) => {
  const { order, hiddenTitle } = props
  const { shippingAddress, customers_id } = order

  return (
    <div>
      { !hiddenTitle &&
        <div className="pd-bottom-10">การจัดส่งสินค้า</div>
      }

      <div className="border-efefef border-radius-5 mg-bottom-10">
        { customers_id ?
          <CustomerAddressSelected {...props} />
        :
          <div className="center pd-10">กรุณาเพิ่มรายชื่อลูกค้าก่อน</div>
        }
      </div>

      { customers_id &&
        <div className="border-efefef border-radius-5">
          { shippingAddress ?
            <BusinessShippingSelected {...props} />
          :
            <div className="center pd-10">กรุณาเพิ่มที่อยู่จัดส่งของลูกค้าก่อน</div>
          }
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const { customers_id } = props.order

  return {
    customer: state.customers.data.filter(customer => customer._id === customers_id)[0]
  }
}

export default connect(mapStateToProps)(ShippingManager)

import React, { Component } from 'react'
import { connect } from 'react-redux'

// css
import css from './css/modalTabs.css'

// Components Global
import ModalDefault from '../../../components/Modals/ModalDefault'
import { Tabs, TabsContent } from '../../../components/Tabs'
import { BtnModalClose } from '../../../components/Modals/BtnModal'
import MenuNavMobile from '../../../components/MenuNavMobile'


class ModalTabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeId: this.props.activeId ? parseInt(this.props.activeId) : 1
    }
  }

  getActiveId = (id) => {
    this.setState({ activeId: id })
  }

  render() {
    return (
      <ModalDefault
        fixed
        id={this.props.id}
        width={this.props.width}
        fnStartModal={this.props.fnStartModal}
        fnCloseModal={this.props.fnStartModal}
        blockBgModal={this.props.blockBgModal}>

        <div className={css.fullLayout}>
          <div className={css.header}>
            <div className={css.title}>
              {this.props.title}
            </div>
            <div className={css.manager}>
              <BtnModalClose id={this.props.id}>
                <button onClick={this.props.eventModalClose} className="waves-effect right waves-teal btn-flat btn-floating btn-floating-md">
                  <i className="material-icons font-2-0 color-333">close</i>
                </button>
              </BtnModalClose>
            </div>
          </div>

          <div className={css.body}>
            <MenuNavMobile
              style={{ background: '#fff' }}
              activeId={this.state.activeId}
              getActiveId={this.getActiveId}
              items={this.props.items}
            />

            <div className={css.layout1}>
              <Tabs
                activeId={this.state.activeId}
                getActiveId={this.getActiveId}
                items={this.props.items} />
            </div>
            <div className={css.layout2}>
              <TabsContent activeId={this.state.activeId}>
                { this.props.children }
              </TabsContent>
            </div>
          </div>
        </div>
      </ModalDefault>
    )
  }
}

export default ModalTabs

import moment from 'moment'
import { getNumeral } from '../../util/getNumeral'

export const pageValue = ({ page, lang="local" }) => {
  let nameLang, avatarName, descriptionsLang, descriptionsFullLang
  let statusName, statusIcon, statusColor, groupPageTypeName

  if (page) {
    nameLang = page.name ? page.name[lang] : null
    avatarName = nameLang ? [nameLang] : null
    descriptionsLang = page.descriptions ? page.descriptions[lang] : null
    descriptionsFullLang = page.descriptionsFull ? page.descriptionsFull[lang] : null

    // Status
    if (page.status === 'open') {
      if (lang === 'local') statusName = 'เปิด'
      statusIcon = "public"
      statusColor = "success"

    } else if (page.status === 'close') {
      if (lang === 'local') statusName = 'ปิด'
      statusIcon = "lock"
      statusColor = "warning"

    } else if (page.status === 'trash') {
      if (lang === 'local') statusName = 'ถังขยะ'
      statusIcon = "delete"
      statusColor = "error"
    }


    // groupPageType
    if (page.groupPageType === 'main') {
      if (lang === 'local') groupPageTypeName = 'หน้าหลัก (main)'
    } else if (page.groupPageType === 'profile') {
      if (lang === 'local') groupPageTypeName = 'หน้าโปรไฟล์ (profile)'
    }
  }

  return {
    ...page,
    nameLang,
    avatarName,
    descriptionsLang,
    descriptionsFullLang,
    statusName, statusIcon, statusColor,
    groupPageTypeName
  }
}

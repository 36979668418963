import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../components/Modals/Modal'
import FetchData from '../../../../../components/FetchData'
import PreLoading from '../../../../../components/PreLoading'

// Components Local
import ProductItemCreate from './components/ProductItemCreate'

// Actions Redux
import { fetchProductUpdate } from '../../../../../redux/Products/ProductsActions'


class OrderItemCreateModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  fnStartModal = () => {
    const { business, productId } = this.props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id,
      products_id: productId
    }

    this.props.dispatch(fetchProductUpdate(query, {
      loadId: 'productModal',
      update: true
    }))
  }

  handleSubmit = (params, formName) => {
    if (params) {
      this.props.addToItems(params, formName)
    }
  }

  render() {
    const { product, id } = this.props

    return (
      <Modal
        id={id}
        fnStartModal={this.fnStartModal}
        headerTitle="เพิ่มรายการสินค้าในตะกร้า"
        headerLeft
        width="600px"
        padding="0">

        <PreLoading id="productModal" style={{ position: 'relative' }}>
          <FetchData
            length={product ? 1 : 0}
            className="position-relative"
            style={{ position: 'relative' }}
            icon="shopping_basket"
            msg="ไม่มีรายการ">

            { product &&
              <div>
                { (product.subtype === 'product' || product.subtype === 'service') &&
                  <ProductItemCreate
                    {...this.props}
                    submit={this.handleSubmit}
                  />
                }
              </div>
            }
          </FetchData>
        </PreLoading>
      </Modal>
    )
  }
}

const mapStateToProps = (store, props) => {
  return {
    product: store.products.data.filter((product => product._id === props.productId))[0]
  }
}

export default connect(mapStateToProps)(OrderItemCreateModal)

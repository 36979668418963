import React from 'react'
import MenuTabs from '../MenuTabs'

import css from './css/menuNavMobile.css'

const MenuNavMobile = (props) => {
  let className

  if (props.maxWidth === '900') {
    className = css.menu900
  } else {
    className = css.menu
  }

  return(
    <div className={className} style={{ marginTop: '0', width: '100%' }}>
      <MenuTabs
        styleLink={props.styleLink}
        style={props.style}
        items={props.items}
        activeId={props.activeId}
        getActiveId={props.getActiveId}
      />
    </div>
  )
}

export default MenuNavMobile

import React from 'react'
import { connect } from 'react-redux'

// Componets Global
import Modal from '../../../../../components/Modals/Modal'
import { Row, Column } from '../../../../../components/Columns'
import BtnLoading from '../../../../../components/button/BtnLoading'


// Functions
import { createNewBill } from '../../../../../redux/Bills/BillsFunctions'


const BillCreateTypeModal = (props) => {
  const { id } = props
  const types = [
    { name: 'ใบเสนอราคา', type: 'quotation', icon: 'fas fa-file-invoice' },
    { name: 'ใบวางบิล', type: 'invoice', icon: 'fas fa-file-invoice-dollar' },
    { name: 'ใบเสร็จรับเงิน', type: 'receipt', icon: 'fas fa-receipt' }
  ]

  return (
    <Modal
      id={id}
      headerTitle={`สร้างเอกสารที่คุณต้องการ`}
      headerLeft
      width="800px">

      <div className="pd-15">
        <Row>
          { types.map(data => {
            return (
              <Column key={data.type} col="column col-xxs-12 col-xs-12 col-sm-6 col-md-4 pd-5">
                <div className="pd-15 center border-radius-3 border-efefef">
                  <div>
                    <i className={`${data.icon} font-3-0 mg-top-20`}></i>
                  </div>

                  <div className="mg-bottom-20 mg-top-20 font-1-3">
                    {data.name}
                  </div>

                  <BtnLoading
                    onClick={() => createNewBill({ data, props })}
                    loadingId={`create-bill-${data.type}`}
                    icon="add"
                    iconAlign="left font-1-7"
                    text={`เปิด${data.name}`}
                    className="btn height-35 line-height-35 btn-shadow-none btn-full pd-right-20"
                  />
                </div>
              </Column>
            )
          }) }
        </Row>
      </div>
    </Modal>
  )
}

export default connect()(BillCreateTypeModal)

import React from 'react'
import { Layouts1, Layouts2, LayoutsTwo } from 'components/LayoutsTwoResponsive'
import renderRoutesTs, { RenderProps } from 'core/routes/renderRoutesTs'
import Menu, { Item } from 'components/Menu'


const menuItems = (params:any): Item[] => {
  let path = `/${params.username}/conversations/settings`

  return [
    {
      name: 'ตั้งค่าแชท',
      nameShort: 'ตั้งค่า',
      title: true
    },
    {
      name: 'ช่องทาง',
      icon: 'integration_instructions',
      link: `${path}/channels/all`,
      linksActive: [
        { url: `${path}/channels/all` },
        { url: `${path}/channels/:objectId` }
      ],
    },
    {
      name: 'แอดมิน',
      icon: 'manage_accounts',
      link: `${path}/admins`,
    },
    {
      name: 'ติดต่อ',
      icon: 'phone',
      link: `${path}/contact`,
    },
  ]
}

const ConversationSettingsMenuContainer = (props:any) => {
  const { business, location, route, match } = props
  const routesParams:RenderProps = {
    routes: route.routes,
    extraProps: { business },
    location
  }

  return (
    <LayoutsTwo>
        <Layouts1>
          <div className="pd-15">
            <Menu
              items={menuItems(match.params)}
            />
          </div>
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutesTs(routesParams) }
        </Layouts2>
      </LayoutsTwo>
  )
}

export default ConversationSettingsMenuContainer

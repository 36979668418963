import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../../routes/renderRoutes'

// Components Global
import LayoutSidebarManage from '../../../../../components/LayoutSidebarManage'

// Component Local
import OrdersListItems from './components/OrdersListItems'

// Values
import { orderValue } from '../../../../../redux/Orders/OrdersValues'

// Actions Redux
import { fetchOrder } from '../../../../../redux/Orders/OrdersActions'



const OrdersListContainer = (props) => {
  const layoutSidebar = useRef()
  const { match } = props
  const { orders_id, status } = match.params
  const { business, location, route, order, dispatch } = props
  const openSidebar = () => layoutSidebar.current.open()

  useEffect(() => {
    if (!order) {
      layoutSidebar.current.close()
    } else {
      layoutSidebar.current.open()
    }
  }, [order])

  useEffect(() => {
    if (!order && orders_id) {
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businesses_id: business._id,
        orders_id
      }

      if (orders_id !== 'all') {
        setTimeout(() => {
          dispatch(fetchOrder(query))
        }, 500)
      }
    }
  }, [])

  return (
    <LayoutSidebarManage {...props} urlMain={`/${business.username}/sales/orders/${status}/all`} ref={layoutSidebar} dataId={orders_id}>
      <div>
        <OrdersListItems {...props} openSidebar={openSidebar} />
      </div>

      <div>
        { renderRoutes({
          routes: route.routes,
          extraProps: { ...props, order: orderValue({ order }) },
          location
        }) }
      </div>
    </LayoutSidebarManage>
  )
}

const mapStateToProps = (state, props) => {
  const { match } = props
  const { orders_id } = match.params
  const { orders } = state

  let order = orders.data.filter(order => order._id === orders_id)[0]

  if (!order && orders.search && orders.search.status === true) {
    order = orders.search.data.filter(order => order._id === orders_id)[0]
  }

  return {
    order
  }
}

export default connect(mapStateToProps)(OrdersListContainer)

import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import ModalFixed from '../../../../../components/Modals/ModalFixed'
import PreLoading from '../../../../../components/PreLoading'
import FetchData from '../../../../../components/FetchData'
import Breadcrumbs from '../../../../../components/Breadcrumbs'

// Fuctions
import { getFoldersAndParents } from '../../../../../redux/Folders/FoldersFunctions'

// redux
import { closeModal } from '../../../../../redux/App/AppActions'

// css
import css from './css/folderMoveModal.css'


class FolderSelectModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      folders: [],
      parents: []
    }
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  handleSubmit = () => {
    const { folderIdSeleted, parents } = this.state
    const folders = []
    let text = ''

    parents.map((parent, i) => {
      folders.push({
        folders_id: parent._id,
        name: parent.name
      })

      if (i === 0) {
        text = `${parent.name.local}`
      } else {
        text = `${text} > ${parent.name.local}`
      }
    })

    const params = {
      folders_id: folderIdSeleted || null,
      folders_text: text,
      folders
    }

    this.props.onSelect(params)
    this.props.dispatch(closeModal(this.props.id))
  }

  fnStartModal = () => {
    const { folders_id } = this.props

    this.fetchFolders()

    if (folders_id) {
      this.selectFolder(folders_id)
    }
  }

  fnCloseModal = () => {
    this.mounted && this.setState({
      folderIdSeleted: undefined,
      folders: [],
      parents: []
    })
  }

  fetchFolders = (parent_folders_id) => {
    getFoldersAndParents({
      _this: this,
      notSaveStore: true,
      parent_folders_id
    })
  }

  selectFolder = (folderIdSeleted) => {
    setTimeout(() => {
      this.setState({ folderIdSeleted }, () => {
        this.fetchFolders(folderIdSeleted)
      })
    }, 0)
  }


  render() {
    const { id, label } = this.props
    const lang = this.props.lang || 'local'
    const { folderId } = this.props
    let { folders, parents, folderIdSeleted } = this.state
    const navigates = []

    folders = folders.filter(folder => folder._id !== folderId)

    parents.map(parent => {
      navigates.push({
        label: parent.name[lang],
        onClick: () => this.selectFolder(parent._id)
      })
    })

    return (
      <ModalFixed
        id={id}
        blockBgModal
        fnStartModal={this.fnStartModal}
        fnCloseModal={this.fnCloseModal}
        title="เลือกหมวดหมู่"
        width="500px"
        headerClassName="height-50"
        bodyClassName="top-50 bg-f5f5f5 pd-0"
        padding="10px">

        <div className="pd-10">

          { navigates.length > 0 &&
            <div className="bg-fff border-radius-3 pd-left-5 border-eee mg-bottom-10">
              <Breadcrumbs
                click
                items={[
                  { label: 'หมวดหมู่', onClick: () => this.selectFolder() },
                  ...navigates
                ]}
              />
            </div>
          }

          <PreLoading id="fetch-folders-modal-loading"  style={{ position: 'relative' }}>
            <FetchData
              length={folders.length}
              style={{ position: 'relative', paddingTop: '100px' }}
              icon="folder"
              msg="">

              <div className={css.folders}>
                { folders.map(folder => {
                  return (
                    <div key={folder._id} className="pd-5">
                      <div
                        onClick={() => this.selectFolder(folder._id)}
                        className={`${css.list} ${folderIdSeleted === folder._id ? css.active : ''}`}>

                        <div className={css.icon}>
                          <i className="material-icons i-middle">folder</i>
                        </div>
                        <div className={css.name}>
                          { folder.name[lang] }
                        </div>
                      </div>
                    </div>
                  )
                }) }
              </div>

            </FetchData>
          </PreLoading>
        </div>

        <div className="text-right">

          { folderIdSeleted &&
            <a onClick={this.handleSubmit} className="btn btn-shadow-none btn-submit pd-right-20">
              <i className="material-icons left">check</i> เลือกหมวดหมู่
            </a>
          }
        </div>
      </ModalFixed>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    folders: state.folders.data
  }
}

export default connect(mapStateToProps)(FolderSelectModal)

import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

// Components
import ModalFixed from 'components/Modals/ModalFixed'
import FetchData from 'components/FetchData'
import PreLoading from 'components/PreLoading'
import InfiniteScrollV2 from 'components/InfiniteScrollV2'

// utils
import { findStoreDatasByGroupId } from 'core/utils/storeDatasUtils'
import DragDropListsV2 from 'components/DragDropListsV2'
import BtnLoading from 'components/button/BtnLoading'


const ReorderModal = (props) => {
  const [items, setItems] = useState([])
  const dragDropRef = useRef()
  const { useReorderNo, paginationRef } = props
  const { id, fetchAction, updateAction, componentList, listParams } = useReorderNo
  const CompList = componentList

  useEffect(() => {
    setItems(props.datas)
  }, [
    props.datas && JSON.stringify(props.datas)
  ])

  const dragDropChange = (items) => {
    setItems(items)
  }

  const handleSave = () => {
    const newItems = []

    items.map((item, index) => {
      newItems.push({
        _id: item._id,
        no: index+1
      })
    })

    props.dispatch(updateAction.action(updateAction.query, {
      body: {
        items: newItems
      },
      loadId: `loading-${props.id}`,
      modalId: props.id,
      toastMsg: `จัดลำดับใหม่แล้ว`
    })).then(res => {
      if (paginationRef) {
        paginationRef.current.refresh()
      }
    })
  }

  return (
    <ModalFixed
      blockBgModal
      id={props.id}
      title="จัดลำดับใหม่"
      width="500px"
      headerClassName="height-50"
      bodyClassName="top-50 bottom-50 pd-0 bg-f5f5f5"
      footerClassName="height-50"
      padding="0">

      <div>
        <InfiniteScrollV2
          { ...props }
          id={id}
          scrollId={`scrollId-${id}`}
          fetchAction={fetchAction}
          itemsData={props.datas}
          searchData={props.datasSearch}>

          <PreLoading id={`preload-customers-list-${id}`}>
            <FetchData
              length={props.datas.length}
              icon="widgets"
              msg={`ไม่มีราย`}>

              <DragDropListsV2
                ref={dragDropRef}
                bg="#f5f5f5"
                items={items}
                onChange={dragDropChange}>

                { items.map((data, index) => {
                  let no = index + 1
                  let upDisabled = false
                  let downDisabled = false

                  if (index == 0) upDisabled = true
                  else if (index == items.length - 1) downDisabled = true

                  return (
                    <div key={data._id} className="mg-bottom-5 bg-fff border-1 border-efefef border-radius-5" style={{ display: 'flex', flexDirection: 'row'}}>
                      <div
                        className="width-20 font-0-8"
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                        {no}
                      </div>

                      <div
                        className="width-20 font-0-8"
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                        <i className="material-icons font-1-8 color-333 mg-top-0">drag_indicator</i>
                      </div>

                      <div className="width-full">
                        <CompList data={data} listParams={listParams} />
                      </div>

                      <div
                        className="width-50 border-left-1 border-left-efefef"
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                        <div>
                          <a
                            onClick={upDisabled == true ? undefined : () => dragDropRef.current.moveUp(index)}
                            disabled={upDisabled}
                            className=" btn btn-white btn-shadow-none border-ddd border-radius-circle width-30 height-30 center line-height-30 pd-0">

                            <i className={`material-icons font-1-5 ${upDisabled ? 'color-c0c0c0' : 'color-333'}  mg-top-0`}>expand_less</i>
                          </a>
                        </div>
                        <div className="height-5">

                        </div>
                        <div>
                          <a
                            onClick={downDisabled == true ? undefined : () => dragDropRef.current.moveDown(index)}
                            disabled={downDisabled}
                            className="btn btn-white btn-shadow-none border-ddd border-radius-circle width-30 height-30 center line-height-30 pd-0">

                            <i className={`material-icons font-1-5 ${downDisabled ? 'color-c0c0c0' : 'color-333'} mg-top-0`}>expand_more</i>
                          </a>
                        </div>
                      </div>
                    </div>
                  )
                }) }
              </DragDropListsV2>
            </FetchData>
          </PreLoading>
        </InfiniteScrollV2>
      </div>

      <div>
        <div className="text-right">
          <BtnLoading
            onClick={handleSave}
            loadingId={`loading-${props.id}`}
            icon="send"
            text="บันทึกการจัดเรียง"
            className="btn btn-shadow-none btn-submit pd-right-20"
          />
        </div>
      </div>
    </ModalFixed>
  )
}

const mapStateToProps = (state, props) => {
  const { fetchAction } = props.useReorderNo
  const store = fetchAction.store
  const { datas, datasSearch } = findStoreDatasByGroupId({ state, store })

  return {
    datas,
    datasSearch,
  }
}

export default connect(mapStateToProps)(ReorderModal)

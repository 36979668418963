import React, { Component } from 'react'
import cuid from 'cuid'

// css
import css from './css/selectData.css'

// Components Global
import InputSelectData from '../InputSelectData'
import Required from '../Required'


class SelectMultiDatas extends Component {
  constructor(props) {
    super(props)

    const id = this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()

    this.state = {
      id: randomId+id,
      chips: [],
      value: ''
    }
  }

  componentDidMount() {
    this.convertValueToChips()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.convertValueToChips()
    }
  }

  convertValueToChips = () => {
    const { value, elem } = this.props

    if (value) {
      const chips = []

      value.map(val => {
        const id = val[elem]
        const chip = this.getData(id)

        chips.push(chip)
      })

      this.setState({ chips })
    }
  }

  getData = (value) => {
    const chips = this.state.chips.slice()
    const datas = this.props.datas || []
    const check = chips.filter(chip => chip.value === value)[0]
    let chip = datas.filter(data => data.value === value)[0]

    if (!check && chip) {
      return chip
    } else {
      return false
    }
  }

  getValue = (value) => {
    const chips = this.state.chips.slice()
    const datas = this.props.datas || []
    const check = chips.filter(chip => chip.value === value)[0]
    let chip = datas.filter(data => data.value === value)[0]

    if (!check && chip) {
      chips.push(chip)
    }

    this.setState({ chips })
  }

  handleRemoveChip = (value) => {
    const chips = this.state.chips.slice()

    chips.map((chip, key) => {
      if (chip === value) chips.splice(key, 1)
    })

    this.setState({ chips })
  }

  render() {
    const datas = this.props.datas.slice()
    const { chips } = this.state
    const values = []

    chips.map(chip => {
      const index = datas.findIndex(data => data.value === chip.value)

      if (index !== -1) {
        datas.splice(index, 1)
      }

      values.push(chip.useValue)
    })

    return (
      <div className={css.inputPhoneNumber}>
        <Required
          id={this.state.id}
          label={this.props.label}
          type="text"
          value={chips}
          required={this.props.required}
          iconValid={this.props.iconValid}>

          <div>
            <div className="mg-top-5">
              { chips.map((chip, key) => {
                return (
                  <div key={key} className={css.itemChip}>

                    { chip.label }

                    <i id="recheck-form" onClick={ () => this.handleRemoveChip(chip) } className="close material-icons">close</i>
                  </div>
                )
              }) }
            </div>

            <InputSelectData
              avatar
              showLabel
              notSave
              notUseValue
              id={`${this.props.id}`}
              classId={this.state.id}
              placeholder={this.props.placeholder}
              value={this.state.value}
              datas={datas}
              getValue={this.getValue}
              required={this.props.required}
            />

            <input
              type="hidden"
              name="array"
              data-id={`${this.props.id}`}
              data-label={this.props.useLabel && this.props.label}
              data-use-attr={this.props.useAttr}
              data-use-attr-id={this.props.useAttrId}
              value={values ? JSON.stringify(values) : ''}
              data-not-save={this.props.notSave}
            />
          </div>
        </Required>
      </div>
    )
  }
}

export default SelectMultiDatas

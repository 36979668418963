import React, { useEffect, useState, Fragment } from 'react'

// Components Global
import { Row, Column } from '../../../../../../components/Columns'
import Checkbox from '../../../../../../components/Inputs/Checkbox'

// Redux
import { convertDecimal } from '../../../../../../redux/RubberSettings/RubberSettingsValue'


// Components Forms
import {
  SaleAddPrice,
  SaleDryLatexWeight,
  SaleLatexPercent,
  SaleLatexPercentBuyer,
  SaleLatexRSPercent,
  SaleNetPrice,
  SaleNetWeight,
  SalePrice,
  SaleAddTotalPrice,
  SaleTotalPrice,
  SaleNetWeightMake,
  TitleShow
} from './RubberSaleForms'


const SaleForms = (props) => {
  const [netWeightMake, setNetWeightMake] = useState(0)

  const [fillAuto, setFillAuto] = useState(false)
  const [netWeight, setNetWeight] = useState(0)
  const [latexPercent, setLatexPercent] = useState(0)
  const [latexPercentBuyer, setLatexPercentBuyer] = useState(0)
  const [latexRSPercent, setLatexRSPercent] = useState(0)
  const [price, setPrice] = useState(0)
  const [addPrice, setAddPrice] = useState(0)
  const [addTotalPrice, setAddTotalPrice] = useState(0)
  const { type } = props

  const onChangeNetWeight = (value) => {
    setNetWeight(parseFloat(value || 0))
  }

  const onChangeLatexPercent = (value) => {
    setLatexPercent(parseFloat(value || 0))
  }

  const onChangeLatexPercentBuyer = (value) => {
    setLatexPercentBuyer(parseFloat(value || 0))
  }

  const onChangeLatexRSPercent = (value) => {
    setLatexRSPercent(parseFloat(value || 0))
  }

  const onChangePrice = (value) => {
    setPrice(parseFloat(value || 0))
  }

  const onChangeAddPrice = (value) => {
    setAddPrice(parseFloat(value || 0))
  }

  const onChangeAddTotalPrice = (value) => {
    setAddTotalPrice(parseFloat(value || 0))
  }

  const onChangeNetWeightMake = (value) => {
    value = parseFloat(value || 0)
    setNetWeightMake(value)
  }

  useEffect(() => {
    if (props.itemSale) {
      setNetWeight(props.itemSale.netWeight)
      setNetWeightMake(props.itemSale.netWeightMake)
      setLatexPercent(props.itemSale.latexPercent)
      setPrice(props.itemSale.price)
      setAddPrice(props.itemSale.addPrice)

      setAddTotalPrice(props.itemSale.addTotalPrice)
      setLatexPercentBuyer(props.itemSale.latexPercentBuyer)

      if (props.itemSale.costMake && props.itemSale.costMake.latexRSPercent) {
        setLatexRSPercent(props.itemSale.costMake.latexRSPercent)
      }

    } else {
      if (props.forDeposit) {
        setPrice(0)
        setAddPrice(0)
      } else if (props.forContract) {
        setPrice(props.forContract.netPrice)
      }
    }

    if (props.event === 'create' && type.code === 'rubber-sheet-make' && type.useFillAutoLatex && type.fillAutoLatexSelected) {
      handleChangeFillAuto(true)
    }
  }, [])



  useEffect(() => {
    const netPrice = getNetPrice()
    const dryLatexWeight = getDryLatexWeight()
    const totalPrice = getTotalPrice()
    const costMake = getCostMake()

    const itemSale = {
      addPrice,
      dryLatexWeight,
      latexPercent,
      latexPercentBuyer,
      netPrice,
      netWeight,
      netWeightMake,
      price,
      addTotalPrice,
      totalPrice,
      costMake
    }

    props.onChange(itemSale)

  }, [props.rubberType, netWeight, netWeightMake, latexPercent, latexRSPercent, latexPercentBuyer, price, addPrice, addTotalPrice])


  const getNetPrice = () => {
    let netPrice = price + addPrice
    netPrice = convertDecimal({ number: netPrice, name: 'salePriceDCM', type })

    return netPrice
  }

  const getDryLatexWeight = () => {
    let dryLatexWeight = netWeight * latexPercent / 100
    dryLatexWeight = convertDecimal({ number: dryLatexWeight, name: 'saleDryLatexWeightDCM', type })

    return dryLatexWeight
  }

  const getTotalPrice = () => {
    let netPrice = getNetPrice()
    let dryLatexWeight = getDryLatexWeight()

    let totalPrice = netPrice * dryLatexWeight
    totalPrice = totalPrice + addTotalPrice
    totalPrice = convertDecimal({ number: totalPrice, name: 'saleTotalPriceDCM', type })

    return totalPrice
  }

  const getCostMake = () => {
    const totalPrice = getTotalPrice()

    if (netWeightMake) {
      return {
        netWeightOrg: netWeightMake,
        netWeightSale: netWeightMake,
        netPriceAvg: totalPrice/netWeightMake,
        latexPercentAvg: 100,
        dryLatexWeight: (netWeightMake*100)/100,
        totalPrice,
        latexRSPercent
      }
    }
  }

  useEffect(() => {
    if (props.cost) {
      handleChangeFillAuto(fillAuto, false)
    }
  }, [props.cost])

  useEffect(() => {
    handleChangeFillAuto(fillAuto, false)
  }, [props.rubberType])

  const handleChangeFillAuto = (value, setValue=true) => {
    if (type.code === 'rubber-sheet-make') {
      if (type.useFillAutoLatex) {
        const auto = type.fillAutoLatex
        const cost = props.cost

        if (setValue) {
          setFillAuto(value)
        }

        if (value === true) {
          if (cost) {
            let netWeight = (cost.netWeightSale * auto.netWeight / 100) + cost.netWeightSale
            let latexPercent = (cost.latexPercentAvg * auto.latexPercent / 100) + cost.latexPercentAvg
            let dryLatexWeight = netWeight * latexPercent / 100
            dryLatexWeight = convertDecimal({ number: dryLatexWeight, name: 'saleDryLatexWeightDCM', type })

            let netWeightMake = (dryLatexWeight * auto.netWeightMake / 100) + dryLatexWeight
            netWeightMake = convertDecimal({ number: netWeightMake, name: 'buyWeightDCM', type })


            let latexRSPercent

            if (props.rubberType === 'ribbed-smoked-sheet') {
              latexRSPercent = auto.latexRSSPercent
            } else if (props.rubberType === 'rubber-sheet') {
              latexRSPercent = auto.latexRSPercent
            }

            setNetWeight(netWeight)
            setLatexPercent(latexPercent)
            setNetWeightMake(netWeightMake)
            setLatexRSPercent(latexRSPercent)
          }
        }
      }
    }
  }

  const netPrice = getNetPrice()
  const dryLatexWeight = getDryLatexWeight()
  const totalPrice = getTotalPrice()
  const costMake = getCostMake()
  const col = 'column col-xxs-12 col-xs-6 col-sm-6 col-md-3 col-lg-4 pd-left-10 pd-right-10'

  let rubberTypeName

  if (props.rubberType === 'ribbed-smoked-sheet') {
    rubberTypeName = 'ยางแผ่นรมควัน'
  } else if (props.rubberType === 'rubber-sheet') {
    rubberTypeName = 'ยางแผ่นดิบ'
  }



  return (
    <Row className="row-dp-flex mg-left--10 mg-right--10">

      { props.event === 'create' && type.code === 'rubber-sheet-make' && type.useFillAutoLatex &&
        <Column col="column col-xxs-12 pd-left-10 pd-right-10">
          <Checkbox
            notSave
            id="export-all"
            label="คำนวณอัตโนมัติ"
            value={fillAuto}
            className="mg-top--15 mg-bottom-20"
            onChange={handleChangeFillAuto}
          />
        </Column>
      }

      <Column col={col}>
        <SaleNetWeight disabled={fillAuto} type={type} value={netWeight} type={props.type} element={props.element} onChange={onChangeNetWeight} />
      </Column>
      <Column col={col}>
        <SaleLatexPercent disabled={fillAuto} type={type} value={latexPercent} type={props.type} element={props.element} onChange={onChangeLatexPercent} />
      </Column>
      <Column col={col}>
        <SaleDryLatexWeight type={type} value={dryLatexWeight} type={props.type} element={props.element} disabled />
      </Column>

      <Column col={col}>
        <SalePrice
          value={price}
          type={props.type}
          element={props.element}
          onChange={onChangePrice}
          disabled={props.forDeposit || props.forContract}
        />
      </Column>
      <Column col={col}>
        <SaleAddPrice
          value={addPrice}
          type={props.type}
          element={props.element}
          onChange={onChangeAddPrice}
          disabled={props.forDeposit}
        />
      </Column>
      <Column col={col}>
        <SaleNetPrice value={netPrice} type={props.type} element={props.element} disabled />
      </Column>

      <Column col={`${col}`}>
        { type.code === 'field-latex' ?
          <SaleLatexPercentBuyer value={latexPercentBuyer} type={props.type} element={props.element} onChange={onChangeLatexPercentBuyer} />
        :
          <Fragment>
            { type.code === 'rubber-sheet-make' ?
              <SaleNetWeightMake
                required
                value={netWeightMake}
                type={props.type}
                element={props.element}
                onChange={onChangeNetWeightMake}
                disabled={fillAuto}
              />
            :
              <div className="width-1 height-1"></div>
            }
          </Fragment>
        }
      </Column>
      <Column col={`${col}`}>
        <SaleAddTotalPrice value={addTotalPrice} type={props.type} element={props.element} onChange={onChangeAddTotalPrice} />
      </Column>
      <Column col={`${col} col-xs-12 col-sm-12 col-md-12`}>
        <SaleTotalPrice value={totalPrice} type={props.type} element={props.element} />
      </Column>



      { type.code === 'rubber-sheet-make' &&
        <Fragment>
          <Column col={`column col-xxs-12 pd-top-10 pd-left-10 pd-right-10 pd-bottom-10`}>
            <div className="font-1-1">สรุปต้นทุนอย่างแผ่น {rubberTypeName ? `(${rubberTypeName})` : ''}</div>
          </Column>

          <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
            <TitleShow value={costMake && costMake.netWeightOrg} type="number" label="นน.ยางแผ่น" />
          </Column>

          <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
            <SaleLatexRSPercent
              //disabled={fillAuto}
              type={type}
              value={latexRSPercent}
              onChange={onChangeLatexRSPercent}
            />
          </Column>

          <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
            <TitleShow value={costMake && costMake.dryLatexWeight} type="number" label="นน.ยางแห้ง" />
          </Column>

          <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
            <TitleShow value={costMake && costMake.netPriceAvg} type="number" label="ราคา/กก." />
          </Column>

          <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
            <TitleShow value={costMake && costMake.totalPrice} type="number" label="รวมเป็นเงิน" />
          </Column>
        </Fragment>
      }
    </Row>
  )
}

export default SaleForms

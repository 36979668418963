import React from 'react'
import renderRoutes from '../../../../../routes/renderRoutes'
import { connect } from 'react-redux'

// Components Global
import Window from '../../../../../components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../../components/MenuSidebarResponsive'

// Components Receivables
import RubberReceivableCreateAndUpdateModal from '../components/RubberReceivableCreateAndUpdateModal'

// img
import titleIcon from '../../../Businesses/resources/icons/rubber-receivables.png'



const menuItems = (params) => {
  const { username } = params
  const path = `/${username}/rubber-receivables`
  const items = [
    {
      name: 'เบิกล่วงหน้า',
      title: true
    },
    {
      name: 'ทั้งหมด',
      nameShort: 'ทั้งหมด',
      icon: 'receipt',
      link: `${path}/all/all`,
      searchLinkActive: `${path}/all/`,
    },
    {
      name: 'ค้างชำระ',
      nameShort: 'ค้างชำระ',
      icon: 'credit_card_off',
      link: `${path}/unpaid/all`,
      searchLinkActive: `${path}/unpaid/`,
    },
    {
      name: 'ชำระแล้ว',
      nameShort: 'ชำระแล้ว',
      icon: 'credit_score',
      link: `${path}/paid/all`,
      searchLinkActive: `${path}/paid/`,
    },

    {
      name: 'บัญชีลูกหนี้',
      title: true
    },
    {
      name: 'ลูกหนี้ค้างชำระ',
      nameShort: 'ค้างชำระ',
      icon: 'person_off',
      link: `${path}/members/unpaid/all/unpaid`,
      searchLinkActive: `${path}/members/unpaid/`,
    },
    {
      name: 'ลูกหนี้ชำระแล้ว',
      nameShort: 'ชำระแล้ว',
      icon: 'people',
      link: `${path}/members/paid/all/paid`,
      searchLinkActive: `${path}/members/paid/`,
    },
    {
      name: 'ประวัติลูกหนี้',
      nameShort: 'ประวัติลูกหนี้',
      icon: 'auto_stories',
      link: `${path}/members/all/all/all`,
      searchLinkActive: `${path}/members/all/`,
    },
    {
      name: 'ใบสำคัญรับเงิน',
      title: true
    },
    {
      name: 'ใบสำคัญรับเงิน',
      nameShort: 'ใบสำคัญรับเงิน',
      icon: 'receipt',
      link: `${path}/payments/all`,
      searchLinkActive: `${path}/payments/`,
    }
  ]

  return items
}

const RubberReceivablesMenuContainer = (props) => {
  const { business, setting, location, route, match, windowModal, closeWindowModal, history } = props


  return (
    <Window
      title="บัญชีลูกหนี้"
      titleIcon={titleIcon}
      linkClose={`/${match.params.username}`}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkHelp="">

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive
            btnAddModal={{
              modalId: 'receivableCreateModal',
              text: 'เพิ่มเบิกล่วงหน้า',
            }}
            items={menuItems(match.params)}
          />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, setting },
            location
          }) }
        </Layouts2>
      </LayoutsTwo>

      <RubberReceivableCreateAndUpdateModal {...props} event="create" id="receivableCreateModal" />
    </Window>
  )
}



export default connect()(RubberReceivablesMenuContainer)

import React, { Component } from 'react'
import jquery from 'jquery'
import cuid from 'cuid'
import ReactDOM from 'react-dom'

class FormValidation extends Component {
  constructor(props) {
    super(props)

    const name = this.props.name
    const randomId = cuid()

    this.state = {
      name: randomId+name,
    }
  }

  checkForm = () => {
    const { notRequired } = this.props

    jquery(document).ready(function(){

      // Function hidden message error
      function invalid (thisForm, inputId) {
        thisForm.find('#'+inputId).addClass('invalid')
        thisForm.find('span[for='+inputId+'-required-valid]').removeClass('hide')
      }

      // Function input value change for hidden message error
      jquery('input').bind("keyup change", function() {
        var inputId = jquery(this).attr("id")
        var value = jquery(this).val()

        if (value.length !== 0) {
          jquery('span[for='+inputId+'-required-valid]').addClass('hide')
        }
      })

      // Function submit show errors message
      jquery('form').submit(function() {
        var formName = jquery(this).attr("name")
        var thisForm = jquery('form[name='+formName+']')
        var requiredLength = thisForm.find('.required').length


        thisForm.find('.required').each(function (i) {
          var inputId = $(this).attr("id")
          var value = thisForm.find(`#${inputId}`).val()

          if (value) {
            if (value.length === 0) {
              invalid(thisForm, inputId)
            }
          }
        })
      })



      // Function delay
      var delay = (function(){
        var timer = 0

        return function(callback, ms){
          clearTimeout (timer)
          timer = setTimeout(callback, ms)
        }
      })()



      // Function check forms all valid
      jquery('form').each(function (i) {
        var formName = jquery(this).attr("name")
        var requiredLength = jquery(this).find('.required').length
        var validLength = jquery(this).find('.required.valid').length



        if (requiredLength === validLength) {
          jquery(this).find('button[data-disabled-show=true]').attr('disabled', false)
          jquery(this).find('button[data-hidden=true]').css('display', 'block')
          jquery(this).find('input[id=valid]').val(true)
        } else {
          jquery(this).find('button[data-disabled=true]').attr('disabled', true);
          jquery(this).find('button[data-hidden=true]').css('display', 'none')
          jquery(this).find('input[id=valid]').val(notRequired ? true : false)
        }

        //change btn input add and review input
        if (jquery(this).find('input[id=valid]').val() === 'true') {
          jquery('button[data-disabled=true]', this).attr('disabled', false)
          jquery('button[data-hidden=true]', this).css('display', 'block')
        }
      })




      // Function check form valid
      jquery('form').on("keyup change", function() {
          var formName = jquery(this).attr("name")
          var thisForm = jquery('form[name='+formName+']')

          delay(function(){
            var requiredLength = thisForm.find('.required').length
            var validLength = thisForm.find('.required.valid').length
            var invalidLength = thisForm.find('.invalid').length

            if (requiredLength === validLength && invalidLength === 0) {
              thisForm.find('button[type=submit]').attr('disabled', false)
              thisForm.find('button[data-hidden=true]').css('display', 'block')
              thisForm.find('input[id=valid]').val(true)
            } else {
              thisForm.find('button[type=submit]').attr('disabled', true)
              thisForm.find('button[data-hidden=true]').css('display', 'none')
              thisForm.find('input[id=valid]').val(notRequired ? true : false)
            }
          }, 0)
      })

      jquery('.recheck-form').on("click", function() {
        setTimeout(() => {
          jquery('form').each(function (i) {
            var formName = jquery(this).attr("name")
            var requiredLength = jquery(this).find('.required').length
            var validLength = jquery(this).find('.required.valid').length

            if (requiredLength === validLength) {
              jquery(this).find('button[data-disabled-show=true]').attr('disabled', false)
              jquery(this).find('button[data-hidden=true]').css('display', 'block')
              jquery(this).find('input[id=valid]').val(true)
            } else {
              jquery(this).find('button[data-disabled=true]').attr('disabled', true);
              jquery(this).find('button[data-hidden=true]').css('display', 'none')
              jquery(this).find('input[id=valid]').val(notRequired ? true : false)
            }

            //change btn input add and review input
            if (jquery(this).find('input[id=valid]').val() === 'true') {
              jquery('button[data-disabled=true]', this).attr('disabled', false)
              jquery('button[data-hidden=true]', this).css('display', 'block')
            }
          })
        }, 1000);
      })




      // Function recheck-form
      jquery('[id="recheck-form"]').on("click", function() {
        delay(function(){
          jquery('form').each(function (i) {
            var formName = jquery(this).attr("name")
            var requiredLength = jquery(this).find('.required').length
            var validLength = jquery(this).find('.required.valid').length

            if (requiredLength === validLength) {
              jquery(this).find('button[data-disabled-show=true]').attr('disabled', false)
              jquery(this).find('button[data-hidden=true]').css('display', 'block')
              jquery(this).find('input[id=valid]').val(true)
            } else {
              jquery(this).find('button[data-disabled=true]').attr('disabled', true)
              jquery(this).find('button[data-hidden=true]').css('display', 'none')
              jquery(this).find('input[id=valid]').val(notRequired ? true : false)
            }

            //change btn input add and review input
            if (jquery(this).find('input[id=valid]').val() === 'true') {
              jquery('button[data-disabled=true]', this).attr('disabled', false)
              jquery('button[data-hidden=true]', this).css('display', 'block')
            }

          })
        }, 0)
      })

    })
  }

  componentDidMount() {
    this.mounted = true

    this.checkForm()
    if (this.props.recheckForm)
      document.addEventListener('click', this.handleDocumentClick)
  }

  componentWillUnmount() {
    this.mounted = false
    if (this.props.recheckForm) document.removeEventListener('click', this.handleDocumentClick)
  }

  componentDidUpdate() {
    this.checkForm()
  }

  handleDocumentClick = (e) => {
    if (this.mounted) this.setState({ recheckForm: true })
  }

  render() {
    return(
      <form
        data-formchid={this.props.formChild}
        name={this.state.name}
        onSubmit={this.props.submit}
        onChange={this.props.onChange}>

        <input id="valid" data-id="valid" type="hidden" ref="valid" />

        { this.props.children }
      </form>
    )
  }
}

export default FormValidation;

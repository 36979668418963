import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import PageItemsDescriptions from '../../../../../components/PageItemsDescriptions'

// Component Local
import { PlaceHeaderList, PlaceList } from '../../components/PlaceList'
import PlaceDescriptions from '../../components/PlaceDescriptions'

// Actions
import { fetchPlaces } from '../../../../../redux/Places/PlacesActions'

// Funtions
import {
  onView,
  onEdit,
  onTrash,
  onDelete,
  onRestore,
  onSwitchOpen,
  onSwitchClose
} from '../../../../../redux/Places/PlacesFunctions'


class PlacesListPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getItem = (item) => {
    this.setState({ item })
  }

  datasFilter = (status) => {
    return (datas) => {
      return datas.filter(data => data.status === status)
    }
  }

  render() {
    const { params, business } = this.props
    const { status, title, icon } = params
    const product = this.state.item
    const urlUpdate = product && `/${business.username}/places/${product.status}/${product._id}/update`

    const fetchAction = {
      action: fetchPlaces,
      id: business._id,
      where: `status=${status}`,
      countWhere: `status=${status}`,
      limit: 25,
      sort: '-_id',
      searchAttributes: [
        { name: 'name.local', options: 'i' },
        { name: 'province.nameTh', options: 'i' },
        { name: 'country.countryNameTh', options: 'i' }
      ]
    }

    const manager = {
      onView: (status === 'open' || status === 'close') && onView(urlUpdate),
      onEdit: (status === 'open' || status === 'close') && onEdit(urlUpdate),
      onTrash: (status === 'open' || status === 'close') && onTrash(this.props),
      onRestore: status === 'trash' && onRestore(this.props),
      onDelete: status === 'trash' && onDelete(this.props),
      onSwitchOpen: status === 'close' && onSwitchOpen(this.props),
      onSwitchClose: status === 'open' && onSwitchClose(this.props)
    }

    return (
      <PageItemsDescriptions
        {...this.props}
        id="placesList"
        storeName="places"
        title={title}
        icon={icon}
        manager={manager}
        fetchAction={fetchAction}
        getItem={this.getItem}
        componentHeaderList={PlaceHeaderList}
        componentList={PlaceList}
        componentDescriptions={PlaceDescriptions}
        datasFilter={this.datasFilter(status)}
      />
    )
  }
}

export default connect()(PlacesListPage)

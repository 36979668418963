import React from 'react'
import { connect } from 'react-redux'

import renderRoutes from '../../../../routes/renderRoutes'
import WindowDefault from '../../../../components/Windows/WindowDefault'

// Values
import { attributeValue } from '../../../../redux/Attributes/AttributesValues'

// Utils
import { getLang } from '../../../../util/getLang'


const ArticlesContainer = (props) => {
  let { attribute, business, setting, route, location, windowModal, closeWindowModal, lang } = props
  attribute = attributeValue({ attribute, lang })

  return (
    <WindowDefault windowModal={windowModal}>
      { renderRoutes({
        routes: route.routes,
        extraProps: { business, setting, attribute, windowModal, closeWindowModal },
        location
      }) }
    </WindowDefault>
  )
}

const mapStateToProps = (state, props) => {
  const { business, match } = props
  const { attributes_path } = match.params

  return {
    lang: getLang({ state, props }).lang,
    attribute: state.attributes.data.filter((attribute => attribute.businesses_id === business._id && attribute.path === attributes_path))[0]
  }
}

export default connect(mapStateToProps)(ArticlesContainer)


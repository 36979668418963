import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../routes/renderRoutes'

// Actions Redux
import { fetchNote } from '../../../../redux/Notes/NotesActions'

// Values
import { noteValue } from '../../../../redux/Notes/NotesValues'


class NoteContainer extends Component {
  componentDidMount() {
    this.props.dispatch(fetchNote('admin', this.props.business._id, this.props.match.params.notes_id))
  }

  render() {
    const { route, note, business, location, attribute } = this.props

    return (
      <Fragment>
        { note ?
          <Fragment>
            { renderRoutes({
              routes: route.routes,
              extraProps: { business, attribute, note: noteValue({ note, attribute }) },
              location
            }) }
          </Fragment>
        :
          <h1>404 Not Found</h1>
        }
      </Fragment>
    )
  }
}


const mapStateToProps = (store, props) => {
  const { notes_id } = props.match.params

  return {
    note: store.notes.data.filter((note => note._id === notes_id))[0]
  }
}

export default connect(mapStateToProps)(NoteContainer)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

// Components Global
import FetchData from '../../../../../components/FetchData'

// Components Local
import ProductItemList from './components/ProductItemList'

// Actions
import { openModal } from '../../../../../redux/App/AppActions'

// Values
import { productValue, productItemValue } from '../../../../../redux/Products/ProductsValues'



class OrderItemsListManager extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  editItemQty = (products_id, orderItem) => {
    this.setState({ products_id: products_id, orderItem: orderItem }, () => {
      this.props.dispatch(openModal('editItemQtyModal'))
    })
  }

  openOrderItemUpdateModal = (products_id, orderItem) => {
    this.setState({ products_id: products_id, orderItem: orderItem }, () => {
      this.props.dispatch(openModal('orderItemUpdateModal'))
    })
  }

  render() {
    let { order } = this.props
    let orderItems = order.items

    return (
      <div>
        <FetchData
          length={orderItems.length}
          icon="shopping_basket"
          msg="ไม่มีรายการสินค้า"
          modalId="createTour"
          btnText="">

          <div>
            <ReactCSSTransitionGroup
              transitionName="example"
              transitionEnterTimeout={1000}
              transitionLeaveTimeout={1000}>

              { orderItems.map(orderItem => {
                const product = orderItem && productValue({ product: orderItem.product })
                const productItem = product && productItemValue({ product, item: orderItem.productItem })

                if (orderItem) {
                  return (
                    <div key={orderItem._id} >
                      { (product.subtype === 'product' || product.subtype === 'service') &&
                        <div className="box border-f5f5f5 pd-5 mg-bottom-5">
                          <ProductItemList
                            {...this.props}
                            orderItem={orderItem}
                            product={product}
                            productItem={productItem}
                            editItemQty={this.editItemQty}
                            openOrderItemUpdateModal={this.openOrderItemUpdateModal}
                          />
                        </div>
                      }
                    </div>
                  )
                }
              }) }
            </ReactCSSTransitionGroup>
          </div>
        </FetchData>
      </div>
    )
  }
}

export default connect()(OrderItemsListManager)

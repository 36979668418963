import React from 'react'

// Component Global
import { BtnModalOpen } from '../../../../../../components/Modals/BtnModal'
import Loading from '../../../../../../components/Loading'
import IconLoading from '../../../../../../components/IconLoading/'

// css
import css from './css/customer.css'


const BtnModalSelectCustomer = (props) => {
  return (
    <BtnModalOpen id="selectCustomer">
      <div className={`border-radius-5 waves-effect ${css.boxAdd}`}>
        <table>
          <tbody>
            <tr>
              <td className="pd-0 pd-left-3" width="55" height="40">
                <Loading id={props.id}>
                  <i className="material-icons left font-3-0 color-red">account_circle</i>
                  <IconLoading className="pd-top-5 mg-left-5" width="30px" height="30px" />
                </Loading>
              </td>
              <td className="pd-0">
                <span className="font-1-0 color-red">เพิ่มรายชื่อลูกค้า</span>
              </td>
              <td className="pd-0 pd-right-5">
                <a className="btn-floating btn-md waves-effect waves-light btn-shadow-none right">
                  <i className="material-icons i-middle">add</i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </BtnModalOpen>
  )
}

export default BtnModalSelectCustomer

import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from '../../../../../../components/Modals/Modal'
import { Row, Column } from '../../../../../../components/Columns'

// Components forms
import Form from '../../../../../../components/Form'
import BtnSubmit from '../../../../../../components/button/BtnSubmit'
import getFormValue from '../../../../../../util/getFormValue'

// Components Receivables
import ReceivableDetailsForm from '../ReceivableDetailsForm'

// Actions Redux
import { createReceivable, updateReceivable } from '../../../../../../redux/Receivables/ReceivablesActions'


const RubberReceivableCreateAndUpdateModal = (props) => {
  const [data, setData] = useState('')
  const [customer, setCustomer] = useState()
  const [employee, setEmployee] = useState()
  const [member, setMember] = useState()

  const formRef = useRef()
  const { id, event, match, business, dispatch } = props
  const { username } = match.params

  let title

  if (event === 'create') {
    title = `เพิ่มบันทึกเบิกล่วงหน้า`
  } else if (event === 'update') {
    title = `แก้ไขบันทึกเบิกล่วงหน้า`
  }

  useEffect(() => {
    if (props.receivable) {
      setCustomer(props.receivable.customer)
      setEmployee(props.receivable.employee)
      setMember(props.receivable.member)
    }
  }, [props.receivable])

  const fnStartModal = () => {
    if (event === 'create') {
      setCustomer()
      setEmployee()
      setMember()
    }
  }

  const handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(formRef.current)

    if (params) {
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id
      }

      params.customer = customer
      params.customers_id = customer && customer._id
      params.employee = employee
      params.customers_employees_id = employee && employee._id
      params.member = member
      params.customers_members_id = member && member._id

      if (event === 'create') {
        dispatch(createReceivable(query, {
          body: params,
          loadId: formName,
          modalId: formName,
          toastMsg: title,
          props,
          dataRedirect: {
            url: `/${username}/rubber-receivables/all/:_id`,
            resName: 'data'
          }
        }))

      } else if (event === 'update') {
        query.receivables_id = props.receivable._id
        params.balance = params.amount

        dispatch(updateReceivable(query, {
          body: params,
          loadId: formName,
          modalId: formName,
          toastMsg: title
        }))
      }
    }
  }

  const handleSelectCustomer = (customer) => {
    if (customer) {
      setCustomer(customer)

      if (customer.useEmployee) {
        const employees = customer.members.filter(member => member.type === 'employee')

        if (employees.length === 1) {
          setEmployee(employees[0])
        }

      } else {
        const owner = customer.members.filter(member => member.owner === true)[0]
        setMember(owner)
      }

    } else {
      setCustomer(undefined)
      setEmployee(undefined)
      setMember(undefined)
    }
  }

  const handleSelectEmployee = (employee) => {
    if (employee) {
      setEmployee(employee)
      setMember(undefined)
    } else {
      setEmployee(undefined)
      setMember(undefined)
    }
  }

  const handleSelectMember = (member) => {
    if (member) {
      setMember(member)
    } else {
      setMember(undefined)
    }
  }


  useEffect(() => {
    setData({ customer, employee })
  }, [customer, employee])


  useEffect(() => {
    const { receivable } = props

    if (receivable) {
      const { customer, employee } = receivable

      setCustomer(customer)
      setEmployee(employee)
    }
  }, [props.receivable])

  const col_1 = "column col-xxs-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 pd-right-20-lg pd-right-20-xlg pd-bottom-20"
  const col_2 = "column col-xxs-12 col-xs-12 col-sm-12 col-md-12 col-lg-6  pd-left-20-lg  pd-left-20-xlg pd-bottom-20"

  //console.log(customer)

  return (
    <Modal
      id={id}
      headerTitle={title}
      headerLeft
      blockBgModal
      fnStartModal={fnStartModal}
      width="600px"
      padding="0">

      <div className="pd-20">
        <Form
          recheckForm
          ref={formRef}
          name={id}
          dataChange={JSON.stringify(data)}
          submit={(e) => handleSubmit(e, id)}>

          <ReceivableDetailsForm
            {...props}
            data={props.receivable}
            customer={customer}
            employee={employee}
            member={member}
            handleSelectCustomer={handleSelectCustomer}
            handleSelectEmployee={handleSelectEmployee}
            handleSelectMember={handleSelectMember}
          />

          <Row>
            <Column col="column col-xxs-12">
              <BtnSubmit
                loadingId={id}
                icon="send"
                iconAlign="left"
                text={title}
                className="btn btn-submit btn-shadow-none height-45 line-height-45 font-0-9 width-full"
                btnDisabled
              />
            </Column>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}

export default connect()(RubberReceivableCreateAndUpdateModal)

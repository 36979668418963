import React from 'react'

//css
import css from './css/boxContent.css'

const BoxContent = (props) => {
  return (
    <div className={css.box}>
      <div className={css.preview}>
        {props.children[0]}
      </div>

      <div className={`${css.manage} ${props.boxClassName || ''}`}>
        {props.children[1]}
      </div>
    </div>
  )
}

export default BoxContent

import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'

// components glogal
import Calendar from '../../Calendar'
import Required from '../../Inputs/Required'

// utity
import { getDate } from '../../../util/getDateTime/getDateTime'

// css
import css from './css/inputDate.css'

class DatePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, ''),
      openAuto: this.props.open,
      open: false,
      errorMsg: false,
      value: this.props.value,
      dateFormat: this.props.dateFormat || 'YYYY-MM-DD'
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick)
  }

  handleDocumentClick = (e) => {
    if (!ReactDOM.findDOMNode(this).contains(e.target)) {
      this.handleOpenDatePicker(false)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value })
    }

    if (this.props.open !== nextProps.open) {
      this.handleOpenDatePicker(nextProps.open)
    }
  }

  getDatesCalendar = (dates, close = true) => {
    this.setState({ value: dates.startDate }, () => {

      if (this.props.onChange) {
        this.props.onChange(dates.startDate)
      }

      // return value dates use is selected for calendar
      if (this.props.getStartDatePicker)
        this.props.getStartDatePicker(dates)

      // return value dates use is selected for calendar
      if (this.props.getEndDatePicker)
        this.props.getEndDatePicker(dates)

      // return value dates use is active for calendar
      if (this.props.getDatesCalendarActive)
        this.props.getDatesCalendarActive(dates)

      // close date picker value true is close and value false is open
      if (close)
        this.setState({ open: false })

      if (this.state.openAuto)
        this.setState({ openAuto: false })

      if (this.props.openPickerEndDate)
        this.props.openPickerEndDate()
    })
  }

  handleOpenDatePicker = (status) => {
    this.setState({ open: status })
  }

  handleResetDate = () => {
    this.setState({ value: '', open: false })
  }

  render() {
    const value = this.state.value !== '' ? this.state.value : moment().format('YYYY-MM-DD')

    return (
      <div className={css.boxDatePicker}>
        <Required
          {...this.props}
          id={this.state.id}
          classId={this.state.id}
          label={this.props.label}
          unitName={this.props.unitName}
          type={this.props.type}
          value={this.state.value}
          required={this.props.required}
          iconValid={this.props.iconValid}
        >
          <input
            id={this.state.id}
            data-id={this.props.id}
            ref={this.state.id}
            data-not-save={this.props.notSave}
            value={this.state.value ? this.state.value : ''}
            className={this.props.required ? 'required' : ''}
            type="hidden"
          />

          <div className={`${css.inputDate}`}>
            <div onClick={() => this.handleOpenDatePicker(true)} className={`${this.state.id} ${css.input} ${this.props.inputClassName || ''}`}>
              { this.state.value === "" || !this.state.value  ?
                <span style={{ color: '#aaaaaa' }}>{this.props.label}</span>
                :
                <span>{ getDate(this.state.value, this.state.dateFormat, 'th') }</span>
              }

              { this.state.value &&
                <a onClick={this.handleResetDate} className={`waves-effect right waves-teal btn-flat btn-floating btn-floating-md`}>
                  <i className="material-icons color-000">close</i>
                </a>
              }
            </div>

            {this.state.open &&
              <div className={`${css.boxDate} dropdown`} style={{ right: this.props.right ? 0 : undefined }}>
                <Calendar
                  id={this.state.id}
                  type="datePicker"
                  datesNotes={this.props.datesNotes}
                  datesSelect={{ startDate: value, endDate: value }}
                  startDate={this.props.startDate}
                  getDatesCalendar={this.getDatesCalendar} />

                <div className={`${css.boxBtn}`}>
                  <div className={`${css.boxBtnRemove}`}>
                    <a onClick={this.handleResetDate} className={`waves-effect waves-teal btn-flat btn-sm`}>ลบวันที่</a>
                  </div>
                  <div className={`${this.state.id} ${css.boxBtnClose}`}>
                    <a onClick={() => this.handleOpenDatePicker(false)} className={`waves-effect waves-teal btn-flat btn-sm`}>ออกจากปฎิทิน</a>
                  </div>
                </div>
              </div>
            }
          </div>
        </Required>
      </div>
    )

    /*return (
      <div className={css.boxDatePicker}>

        <input
          id={this.state.id}
          data-id={this.props.id}
          ref={this.state.id}
          data-not-save={this.props.notSave}
          value={this.state.value ? this.state.value : ''}
          className={this.state.value === '' || !this.state.value && this.props.required ? 'required invalid' : 'required valid'}
          type="hidden"
        />

        { !this.props.labelHide &&
          <label className={`${this.state.errorMsg && css.labelError} ${css.label} ${this.state.value !== "" && css.labelValid}`}>
            {this.props.label}
          </label>
        }

        <div className={`${css.inputDate}`}>
          <div onClick={() => this.handleOpenDatePicker(true)} className={`${css.input} ${this.state.errorMsg && css.error} ${this.state.value !== "" && css.valid}`}>


            {this.state.value === "" || !this.state.value  ?
              <span style={{ color: '#aaaaaa' }}>{this.props.label}</span>
              :
              <span>{ getDate(this.state.value, this.state.dateFormat, 'th') }</span>
            }
          </div>


          { this.state.errorMsg &&
            <span className={css.errorMessage}>{this.props.label} ต้องไม่ว่าง</span>
          }


          {this.state.open &&

            <div className={`${css.boxDate} dropdown`} style={{ right: this.props.right ? 0 : undefined }}>
              <Calendar
                id={this.state.id}
                type="datePicker"
                datesNotes={this.props.datesNotes}
                datesSelect={{ startDate: value, endDate: value }}
                startDate={this.props.startDate}
                getDatesCalendar={this.getDatesCalendar} />

              <div className={`${css.boxBtn}`}>
                <div className={`${css.boxBtnRemove}`}>
                  <a onClick={this.handleResetDate} className={`waves-effect waves-teal btn-flat btn-sm`}>ลบวันที่</a>
                </div>
                <div className={`${this.state.id} ${css.boxBtnClose}`}>
                  <a onClick={() => this.handleOpenDatePicker(false)} className={`waves-effect waves-teal btn-flat btn-sm`}>ออกจากปฎิทิน</a>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    )*/
  }
}

export default DatePicker

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Helmet from 'react-helmet'

// Utils
import getFormValue from '../../../util/getFormValue'

// Component Global
import { NewPassword, NewRePassword } from './components/AuthForms'

// Import Components
import { Box, ButtonLink, ContainerFull, IconTitle, Row, ButtonClose } from '../../../components/UserInterface'
import ModalDefault from '../../../components/modal/ModalDefault'
import TextInput from '../../../components/input/TextInput'
import FormValidation from '../../../components/FormValidation'
import BtnSubmit from '../../../components/button/BtnSubmit'

// Import Actions
import { checkResetPasswordToken, resetPassword } from '../../../redux/Auth/AuthActions'

// Import file
import forgotPasswordSetting from './imgs/forgot-password-setting.png'
import forgotPasswordSuccess from './imgs/forgot-password-success.png'

class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const params = this.props.match.params
    const resetPasswordToken = params.token

    this.props.dispatch(checkResetPasswordToken({
      body: { resetPasswordToken: resetPasswordToken },
      errorAlert: true

    })).then(res => {
      if (res.success) {
        this.setState({ token: 'valid' })
      } else {
        if (res.code === 'token-is-invalid') {
          this.setState({ token: 'invalid' })
        }
      }
    })
  }

  handleSubmit = (e, formName) => {
    e.preventDefault();
    const params = getFormValue(this.refs[formName])

    if (params) {
      params.resetPasswordToken = this.props.match.params.token

      this.props.dispatch(resetPassword({
        body: params,
        loadId: formName,
        errorAlert: true,
        props: this.props,
        redirect: { url: '/' },
        toastMsg: 'กำหนดรหัสผ่านใหม่เรียบร้อยแล้ว'
  
      })).then(res => {
        if (res.success) {
          this.setState({ token: 'valid' })
        } else {
          if (res.code === 'token-is-invalid') {
            this.setState({ token: 'invalid' })
          }
        }
      })
    }
  }

  render() {
    return (
      <div>
        <Helmet title="กำหนดรหัสผ่านของคุณใหม่" />
        <ModalDefault showOnly={true} bgOverlay="#7cb342" width="450px">
          <Box padding="30px" center="true" background="#fff">
            <ButtonClose to={`/`} />
            
            { !this.state.token &&
              <div className="center">
                <p>กรุณารอสักครู่กำลังตรวจสอบ Token ...</p>
              </div>
            }

            { this.state.token === 'invalid' &&
              <div className="center">
                <i className="material-icons font-4-0 red-text text-accent-4">error_outline</i>
                <p className="red-text text-accent-4">
                  ไม่สามารถเปลี่ยนรหัสผ่านใหม่ได้<br />เนื่องจาก Token เปลี่ยนรหัสผ่านใหม่ ของคุณไม่ถูกต้อง
                </p>

                <ButtonLink
                  to={`/forgot-password`}
                  iconAlign="left"
                  icon="reply"
                  text="กลับไปยังหน้ากำหนดรหัสผ่านใหม่"
                  margin="30px 0 0 0"
                  className="waves-effect waves-light btn light-green darken-1" 
                />
              </div>
            }

            { this.state.token === 'valid' &&
              <div>
                <div className="center mg-bottom-50">
                  <img src={forgotPasswordSetting} />
                  <p className="dp-block font-1-2 mg-top-10">กำหนดรหัสผ่านของคุณใหม่</p>
                </div>

                <FormValidation ref="resetPassword" name="resetPassword" submit={(e) => this.handleSubmit(e, 'resetPassword')} >
                  <Row>
                    <NewPassword />
                  </Row>
                  <Row>
                    <NewRePassword />
                  </Row>
                  <Row align="center">
                    <BtnSubmit
                      loadingId="resetPassword"
                      icon="vpn_key"
                      iconAlign="left"
                      text="กำหนดรหัสผ่านใหม่"
                      className="waves-effect waves-light btn light-green darken-1 width-full-xs"
                      btnDisabled
                    />
                  </Row>
                </FormValidation>
              </div>
            }
          </Box>
        </ModalDefault>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    app: state.app,
    auth: state.auth
  }
}

export default withRouter(connect(mapStateToProps)(ResetPassword))

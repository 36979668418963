import React from 'react'
import renderRoutes from '../../../../routes/renderRoutes'

// Components Global
import WindowBack from '../../../../components/Windows/WindowBack'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../components/MenuSidebarResponsive'


const menuItems = (params, note) => {
  const { username, notes_id, attributes_path } = params

  return [
    {
      name: 'รายการ',
      title: true
    },
    {
      name: 'รายละเอียด',
      nameShort: 'รายละเอียด',
      icon: 'description',
      link: `/${username}/notes/${attributes_path}/${note.status}/${notes_id}/update`,
      exact: true
    },
    /*{
      name: 'เนื้อหา',
      nameShort: 'เนื้อหา',
      icon: 'create',
      link: `/${username}/notes/${attributes_path}/${note.status}/${notes_id}/update/content`,
      exact: true
    },
    {
      name: 'รูปภาพ',
      nameShort: 'รูปภาพ',
      icon: 'photo_library',
      link: `/${username}/notes/${attributes_path}/${note.status}/${notes_id}/update/photos`
    },
    {
      name: 'SEO',
      nameShort: 'SEO',
      icon: 'description',
      link: `/${username}/notes/${attributes_path}/${note.status}/${notes_id}/update/seo`,
      exact: true
    },*/
  ]
}


const NoteUpdateContainer = (props) => {
  const { route, match, attribute, business, note, location } = props
  const { params } = match
  const { username, notes_id, attributes_path } = params

  return (
    <WindowBack
      title={`แก้ไข : ${note.titleLang}`}
      linkBack={`/${params.username}/notes/${attributes_path}/${note.status}`}
      breadcrumbs={[
        { label: attribute.nameLang, url: `/${username}/notes/${attributes_path}` },
        { label: note.statusName, url: `/${username}/notes/${attributes_path}/${note.status}` },
        { label: note.titleLang, url: `/${username}/notes/${attributes_path}/${note.status}/${notes_id}/update/content` },
      ]}>

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive items={menuItems(params, note)} />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, attribute, note },
            location
          }) }
        </Layouts2>

      </LayoutsTwo>
    </WindowBack>
  )
}

export default NoteUpdateContainer

import React, { Fragment } from 'react'

// Components Global
import BtnManager from '../../../../../../../components/BtnManager'

// Components Products
import { ProductItemProfileAvater } from '../../../../../Products/components/ProductsUserInterface'

// Values
import { getPriceProduct } from '../../../../../../../redux/Orders/OrdersValues'

// Functions
import { onDeleteItem } from '../../../../../../../redux/Orders/OrdersFunctions'

// Util
import { getNumeral } from '../../../../../../../util/getNumeral'

// css
import css from './css/productItemList.css'

// Components Sales
import OrderItemUpdateModal from '../../../OrderItemUpdateModal'
import { openModal } from '../../../../../../../redux/App/AppActions'

const ProductItemList = (props) => {
  const {
    orderItem,
    product,
    productItem
  } = props

  return (
    <div className={css.itemList}>

      <OrderItemUpdateModal
        {...props}
        id={`orderItemUpdateModal-${product._id}`}
        productId={product._id}
        orderItem={orderItem}
      />

      <div className={css.title}>
        <table>
          <tbody>
            <tr>
              <td className="pd-5">
                <div className="font-0-9">{ product.nameLang }</div>
              </td>
              <td width="50" className="pd-5">
                <BtnManager
                  listTable
                  id={orderItem._id}
                  style={{ marginTop: '-5px' }}
                  manager={[
                    {
                      type: 'modal-edit',
                      show: true,
                      onClick: () => props.dispatch(openModal(`orderItemUpdateModal-${product._id}`)),
                      btnCircle: true
                    },
                    {
                      type: 'delete',
                      show: true,
                      handle: onDeleteItem(props),
                      btnCircle: true
                    }
                  ]}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={css.details}>
        <table>
          <tbody>
            <tr>
              <td width="60" className="pd-0 pd-top-5 pd-bottom-0 pd-right-5">
                <div className="width-60 height-60">
                  <ProductItemProfileAvater product={product} item={productItem} textSize="1.1rem" />
                </div>
              </td>
              <td className="pd-5 font-0-8">
                { productItem.choiceFullNameLang &&
                  <div className="font-0-8">
                    { productItem.choiceFullNameLang }
                  </div>
                }
                <div>
                  ราคา : ฿{ getNumeral(orderItem.price, 'auto') }
                </div>
              </td>
              <td width="50" className="pd-5">
                <span className="badge-bg font-0-8 success">{ getNumeral(orderItem.qty, 'auto') }</span>
              </td>
              <td width="150" className="pd-5 text-right">
                <span className="font-1-1 color-price">
                  ฿{ getNumeral(getPriceProduct(orderItem), 'auto') }
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ProductItemList

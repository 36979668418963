import React, { Component } from 'react'

// Compoents Local
import SectionTitle from '../SectionTitle'

import { WidgetsManage, WidgetsDisplay } from '../../../../../Widgets'


class WidgetFullManage extends Component {
  render() {
    const { business, setting, params, pages, site, pageCurrent, singlePageData, singlePageType } = this.props

    return (
      <div style={{ maxWidth: '900px', margin: '0 auto' }}>
        <SectionTitle title="วิดเจ็ตอื่นๆ" mode="view">

          <WidgetsDisplay
            {...this.props}
            mode="view"
            datas={{
              business,
              setting,
              site,
              pages,
              pageCurrent,
              singlePageData,
              singlePageType
            }}
          />

          <WidgetsManage
            {...this.props}
            mode="edit"
            datas={{
              business,
              setting,
              site,
              pages,
              pageCurrent,
              singlePageData,
              singlePageType
            }}
          />

        </SectionTitle>
      </div>
    )
  }
}

export default WidgetFullManage

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { onSearchData } from './../InputSearch/js/InputSearchFunction'

class InfiniteScroll extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      pageSearch: 1,
      load: false,
      loadSearch: false
    }
  }

  componentDidMount() {
    if (this.props.startLoad === undefined) {
      this.loadFirst()
      this.addEventscroll()
    }
  }

  reset = () => {
    this.setState({
      page: 1,
      pageSearch: 1,
      load: false,
      loadSearch: false
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchData !== this.props.searchData) {
      if (nextProps.searchData.value !== this.props.searchData.value) {
        this.setState({ searchData: nextProps.searchData, hasMoreSearch: true })
      }
    }

    if (nextProps.startLoad !== this.props.startLoad) {
      if (nextProps.startLoad) {
        this.loadFirst()
        this.addEventscroll()
      } else {
        this.reset()
      }
    }
  }

  loadFirst = () => {
    this.setState({ load: true }, () => {
      let fetch = this.props.fetchAction
      let fecthFn

      const body = {
        where: fetch.where,
        or: fetch.or,
        sort: fetch.sort,
        limit: fetch.limit,
        skip: fetch.skip,
        countWhere: fetch.countWhere,
        countOr: fetch.countOr,
        payload: fetch.payload,
        between: fetch.between,
        groupBy: fetch.groupBy,
        unwind: fetch.unwind,
        aggregate: fetch.aggregate
      }

      if (!body.where) delete body.where
      if (!body.countWhere) delete body.countWhere
      if (!body.or) delete body.or
      if (!body.countOr) delete body.countOr
      if (!body.payload) delete body.payload
      if (!body.between) delete body.between
      if (!body.groupBy) delete body.groupBy
      if (!body.unwind) delete body.unwind
      if (!body.aggregate) delete body.aggregate

      let fecthParams = {
        body,
        /*body: {
          where: fetch.where,
          sort: fetch.sort,
          limit: fetch.limit,
          skip: fetch.skip,
          countWhere: fetch.countWhere
        },*/
        addGroupId: fetch.addGroupId,
        addGroupIdReplace: fetch.addGroupId && true,
        loadId: `preload-${this.props.id}`
      }

      if (fetch.groupBy) {
        fecthParams.body.groupBy = fetch.groupBy
      }

      if (fetch.actionQuery) {
        fecthFn = () => fetch.action(fetch.actionQuery, fecthParams)

      } else if (fetch.authName) {
        fecthFn = () => fetch.action(fetch.authName, fetch.id, fecthParams)

      } else if (fetch.dbNo) {
        fecthFn = () => fetch.action(fetch.dbNo, fetch.id, fecthParams)

      } else {
        fecthFn = () => fetch.action(fetch.id, fecthParams)
      }

      this.props.dispatch(fecthFn()).then(res => {
        if (res.success) {
          let data = res.data

          if (data.length >= fetch.limit) this.setState({ hasMore: true, load: false })
          else this.setState({ hasMore: false, load: false })
        }
      })
    })
  }

  loadMore = () => {
    if (this.state.hasMore) {
      this.setState({ load: true }, () => {
        let fetch = this.props.fetchAction
        let skip = fetch.limit * this.state.page
        let fecthFn

        const body = {
          where: fetch.where,
          or: fetch.or,
          sort: fetch.sort,
          limit: fetch.limit,
          skip,
          countWhere: fetch.countWhere,
          countOr: fetch.countOr,
          payload: fetch.payload,
          between: fetch.between,
          groupBy: fetch.groupBy,
          unwind: fetch.unwind,
          aggregate: fetch.aggregate
        }

        if (!body.where) delete body.where
        if (!body.countWhere) delete body.countWhere
        if (!body.or) delete body.or
        if (!body.countOr) delete body.countOr
        if (!body.payload) delete body.payload
        if (!body.between) delete body.between
        if (!body.groupBy) delete body.groupBy
        if (!body.unwind) delete body.unwind
        if (!body.aggregate) delete body.aggregate


        let fecthParams = {
          body,
          /*body: {
            where: fetch.where,
            sort: fetch.sort,
            limit: fetch.limit,
            skip: skip,
            countWhere: fetch.countWhere
          },*/
          addGroupId: fetch.addGroupId,
          addGroupIdReplace: fetch.addGroupId && true,
          loadId: 'more-' + this.props.id,
          addMore: true
        }

        if (fetch.groupBy) {
          fecthParams.body.groupBy = fetch.groupBy
        }

        if (fetch.actionQuery) {
          fecthFn = () => fetch.action(fetch.actionQuery, fecthParams)

        } else if (fetch.authName) {
          fecthFn = () => fetch.action(fetch.authName, fetch.id, fecthParams)

        } else if (fetch.dbNo) {
          fecthFn = () => fetch.action(fetch.dbNo, fetch.id, fecthParams)

        } else {
          fecthFn = () => fetch.action(fetch.id, fecthParams)
        }

        this.props.dispatch(fecthFn()).then(res => {
          if (res.success) {
            let data = res.data

            if (data.length >= fetch.limit) {
              this.setState({ hasMore: true, load: false, page: this.state.page + 1 })
            } else {
              this.setState({ hasMore: false, load: false })
            }
          }
        })
      })
    }
  }

  loadMoreSearch = (searchData) => {
    if (this.state.hasMoreSearch) {

      this.setState({ loadSearch: true }, () => {
        let fetch = this.props.fetchAction
        let skip = fetch.limit * this.state.pageSearch
        let loadId = 'more-' + this.props.id
        let options = { loadId: loadId, skip: skip, addMore: true }


        onSearchData(this, searchData.value, fetch, options).then(res => {
          if (res.success) {
            let data = res.data

            if (data.length >= fetch.limit) {
              this.setState({ hasMoreSearch: true, loadSearch: false, pageSearch: this.state.pageSearch + 1 })
            } else {
              this.setState({ hasMoreSearch: false, loadSearch: false, pageSearch: 1 })
            }
          }
        })
      })
    }
  }


  addEventscroll = () => {
    setTimeout(() => {
      let listElm = document.getElementById(this.props.scrollId)

      if (listElm) {
        listElm.addEventListener('scroll', () => {
          if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {

            // normal
            if (this.state.load === false) {
              let searchData = this.state.searchData

              if (searchData && !searchData.status || !searchData) {
                this.loadMore()
              }
            }

            // for search
            if (this.state.loadSearch === false) {
              let searchData = this.state.searchData

              if (searchData && searchData.status) {
                this.loadMoreSearch(searchData)
              }
            }
          }
        })
      }
    }, 0)
  }

  render() {
    return (
      <div>
        { this.props.children }

        { this.props.load &&
          <div className="center">
            <table style={{ width: '170px', margin: '0 auto' }}>
              <tbody>
                <tr>
                  <td width="30">
                      <div className="la-ball-clip-rotate la-sm" style={{width: '20px', height: '20px', color: '#7cb342'}}>
                        <div style={{width: '20px', height: '20px', borderWidth: '2px'}}></div>
                      </div>
                  </td>
                  <td width="140">กำลังโหลดข้อมูล...</td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (store, props) => {
  return {
    load: store.app.loads.filter(load => load === 'more-' + props.id)[0]
  }
}

export default connect(mapStateToProps)(InfiniteScroll)

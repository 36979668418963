import { lazy } from 'react'
import suspenseComponent from '../../../../../../../util/suspenseComponent'

// Containers
import RubberInventoriesMenuContainer from '../../../../../../../containers/dashboard/SystemRubber/RubberInventories/containers/RubberInventoriesMenuContainer'

// Components
const RubberInventoriesListContainer = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberInventories/containers/RubberInventoriesListContainer'))
const RubberWarehousesInventoriesListContainer = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberInventories/containers/RubberWarehousesInventoriesListContainer'))
//const RubberSaleManagePage = lazy(() => import('../../../../../../../containers/dashboard/SystemRubber/RubberSales/pages/RubberSaleManagePage'))

// Types
const inventoryType = ":inventoryType(inventory|contract|deposit)"
const rubberType = ":rubberType(field-latex|rubber-sheet|ribbed-smoked-sheet)"


const RubberInventoriesMenuRoutes = [
  {
    path: `/:username/rubber-inventories`,
    component: RubberInventoriesMenuContainer,
    routes: [
      {
        path: `/:username/rubber-inventories/warehouses/inventory/field-latex/:warehouses_id/:inventories_id`,
        component: suspenseComponent(RubberWarehousesInventoriesListContainer),
        routes: [
          /*{
            path: `/:username/rubber-inventories/${rubberType}/:inventories_id`,
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(RubberSaleManagePage)
          },*/
        ]
      },
      {
        path: `/:username/rubber-inventories/${inventoryType}/${rubberType}/:inventories_id`,
        component: suspenseComponent(RubberInventoriesListContainer),
        routes: [
          /*{
            path: `/:username/rubber-inventories/${rubberType}/:inventories_id`,
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(RubberSaleManagePage)
          },*/
        ]
      },

    ]
  }
]

export default RubberInventoriesMenuRoutes

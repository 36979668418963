import moment from 'moment'
import { getNumeral } from '../../util/getNumeral'

export const placeValue = (place, lang="local") => {
  let nameLang, avatarName, descriptionsLang, descriptionsFullLang
  let statusName, statusIcon, statusColor

  if (place) {
    nameLang = place.name ? place.name[lang] : null
    avatarName = nameLang ? [nameLang] : null
    descriptionsLang = place.descriptions ? place.descriptions[lang] : null
    descriptionsFullLang = place.descriptionsFull ? place.descriptionsFull[lang] : null

    // Status
    if (place.status === 'open') {
      if (lang === 'local') statusName = 'เปิด'
      statusIcon = "public"
      statusColor = "success"

    } else if (place.status === 'close') {
      if (lang === 'local') statusName = 'ปิด'
      statusIcon = "lock"
      statusColor = "warning"

    } else if (place.status === 'trash') {
      if (lang === 'local') statusName = 'ถังขยะ'
      statusIcon = "delete"
      statusColor = "error"
    }
  }

  return {
    ...place,
    nameLang,
    avatarName,
    descriptionsLang,
    descriptionsFullLang,
    statusName, statusIcon, statusColor,
  }
}

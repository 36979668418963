import React from 'react'
import { NavLink } from 'react-router-dom'
import Bowser from 'bowser'

import css from './css/menuTabs.css'

class MenuTabs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollbarShow: false
    }
  }

  componentDidMount() {
    this.getStyle()
    this.handleResize()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.event !== this.props.event) {
      this.getStyle()
    }
  }

  getStyle = () => {
    setTimeout(() => {
      let elem = document.getElementById('scrollbar')

      if (elem && elem.clientWidth !== 0 && elem.clientWidth !== 0) {
        if (elem.clientWidth < elem.scrollWidth) {
          this.setState({ scrollbarShow: true })
        } else {
          this.setState({ scrollbarShow: false })
        }
      }
    }, 0)
  }

  handleResize = () => {
    window.onresize = (event) => {
      this.getStyle()
    }
  }

  render() {
    const width = 100 / this.props.items.length
    let style


    if (Bowser.mobile || Bowser.tablet) {
      style = {}
    } else {
      if (this.props.items.length > 1) {
        style = { paddingBottom: this.state.scrollbarShow ? '60px' : '0' }
      } else {
        style = {}
      }
    }

    let joinStyle = Object.assign(style, this.props.style)

    const items = this.props.items.filter(item => !item.title)

    return (
      <ul id="scrollbar" className={`${css.tabs} ${this.props.className}`} style={joinStyle}>
        { items.map((item, i) => {
          return (
            <li key={i} className={css.tab} style={{ width: `${width}%`, minWidth: '140px' }}>

              { item.link ?
                item.disabled ?
                  <NavLink style={this.props.styleLink} activeClassName={css.active} className={css.disabled}>
                    <i className="material-icons left i-middle">{item.icon}</i>
                    {item.nameShort ? item.nameShort : item.name}
                  </NavLink>
                :
                  <NavLink exact={item.exact} to={item.link} style={this.props.styleLink} activeClassName={css.active} className={`${item.disabled ? css.disabled : 'waves-effect'}`}>
                    <i className="material-icons left i-middle">{item.icon}</i>
                    {item.nameShort ? item.nameShort : item.name}
                  </NavLink>
              :
                <a onClick={() => this.props.getActiveId(i+1)} className={`waves-effect ${this.props.activeId === i+1 && css.active}`}>
                  <i className="material-icons left i-middle">{item.icon}</i>
                  {item.nameShort ? item.nameShort : item.name}
                </a>
              }
            </li>
          )
        }) }
      </ul>
    )
  }
}

export default MenuTabs

import { lazy } from 'react'
import suspenseComponent from '../../../../../../util/suspenseComponent'

// Containers
import VouchersMenuContainer from '../../../../../../containers/dashboard/Vouchers/containers/VouchersMenuContainer'

// Components
const VouchersListPage = lazy(() => import('../../../../../../containers/dashboard/Vouchers/pages/VouchersListPage'))



const VouchersMenuRoutes = [
  {
    path: '/:username/vouchers',
    component: VouchersMenuContainer,
    routes: [
      {
        path: '/:username/vouchers',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'open', title: 'คูปองส่วนลด', icon: 'assignment_late' },
        component: suspenseComponent(VouchersListPage)
      },
      {
        path: '/:username/vouchers/open',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'open', title: 'คูปองส่วนลด', icon: 'assignment_late' },
        component: suspenseComponent(VouchersListPage)
      },
      {
        path: '/:username/vouchers/close',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'close', title: 'คูปองส่วนลด', icon: 'assignment_late' },
        component: suspenseComponent(VouchersListPage)
      },
      {
        path: '/:username/vouchers/trash',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'trash', title: 'คูปองส่วนลด', icon: 'assignment_late' },
        component: suspenseComponent(VouchersListPage)
      }
    ]
  }
]

export default VouchersMenuRoutes
